//External
import React, { useEffect, useRef, useState } from "react";

//internal
import styles from "./Register.module.scss";
import Button from "components/Button/Button";
import { useNavigate } from "react-router-dom";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import reactionLogo from "assets/images/reactionlogonew.png";
import { TextFieldSimple } from "components/inputs";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import { useValidateEmail } from "api/resources/authentication";
import { useValidateJoinCodeOrganization } from "api/resources/authentication/register";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { Loading } from "components/Loading/Loading";
import ImageEditor from "components/Image Editor/ImageEditor";
import ReCAPTCHA from "react-google-recaptcha";
import { useJoinOrgainizationExternal } from "api/resources/organization/organization";
import { PrivacyPolicy } from "pages/Policies/PrivacyPolicy";
import { ToSComponent } from "pages/Policies/TermsAndConditions";
import { CodeInput } from "./VerifyEmail";
import { Player } from "@lottiefiles/react-lottie-player";

export function Register() {
  let navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);

  const [org, setOrg] = useState({});
  const [registerType, setRegisterType] = useState("Trial");
  const registerUserRequest = useJoinOrgainizationExternal();
  const [steps, setSteps] = useState(initSteps());
  const [invalidPasswordMessage, setInvalidPasswordMessage] = useState(
    "Password must be at least 8 characters. Password must contain at least 1 uppercase letter, 1 lowercase letter, and 1 special character."
  );
  const [validationRules, setValidationRules] = useState([]);
  const [validCode, setValidCode] = useState();
  const [joinCode, setJoinCode] = useState("");
  const [user, setUser] = useState({});
  const [validFirstName, setValidFirstName] = useState(true);
  const [validLastName, setValidLastName] = useState(true);
  const [validEmail, setValidEmail] = useState([]);
  const [validPassword, setValidPassword] = useState();
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [validConfirmPassword, setValidConfirmPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [working, setWorking] = useState(false);
  const [showToS, setShowToS] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [loadingOrg, setLoadingOrg] = useState(false);
  const [codeSuccess, setCodeSuccess] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [codeRules, setCodeRules] = useState(false);

  const [passwordType, setPasswordType] = useState({
    icon: "eye-slash",
    type: "password",
  });

  const [confirmPasswordType, setConfirmPasswordType] = useState({
    icon: "eye-slash",
    type: "password",
  });
  function handleChangePassword(password) {
    setUser({ ...user, password: password });
    validateUserPassword(password);
  }

  function handleChangeEmail(email) {
    setUser({ ...user, email: email.toLowerCase() });
    validateUserEmail(email);
  }

  function handleChangeValidatePassword(p) {
    if (p === user?.password) {
      setValidConfirmPassword(true);
    } else {
      setValidConfirmPassword(false);
    }
    setConfirmPassword(p);
  }

  useEffect(() => {
    setSteps(initSteps());
  }, [registerType]);

  const validateEmail = useValidateEmail();

  function validateUserPassword(p) {
    //   check if meets length requirement
    //   check if contains number
    //   check if contains capital letter
    //   check if contains lower case letter
    //   check if contains special character
    //   If doesn't meet requirments display message with what is missing
    let message = "";
    let valid = false;
    const rules = [
      {
        rule: "Password must be at least 8 characters and less than 64 characters.",
        valid: p.length >= 8 && p.length <= 64,
      },
      {
        rule: "Password must contain at least 1 uppercase letter.",
        valid: /[A-Z]/.test(p),
      },
      {
        rule: "Password must contain at least 1 lowercase letter.",
        valid: /[a-z]/.test(p),
      },
      {
        rule: "Password must contain at least 1 number.",
        valid: /[0-9]/.test(p),
      },
      {
        rule: "Password must contain at least 1 special character.",
        valid: /[" !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~"]/.test(p),
      },
    ];

    valid = rules.every((rule) => rule.valid);
    setValidationRules(rules);

    setInvalidPasswordMessage(message);
    setValidPassword(valid);
  }

  function validateUserEmail(email) {
    let rules = [
      {
        rule: "Email must not be empty.",
        valid: email.trim().length > 0,
      },
      {
        rule: "Email must contain '@' symbol.",
        valid: email.includes("@"),
      },
      {
        rule: "Email must have a domain name after '@'.",
        valid: email.split("@")[1]?.length > 0,
      },
      {
        rule: "Email must contain a top-level domain (e.g., .com, .org).",
        valid: /\.[a-zA-Z]{2,}$/.test(email),
      },
    ];
    let valid = rules.every((rule) => rule.valid);
    if (!valid) {
      setValidEmail(rules);
      return;
    }
    validateEmail.mutate(
      {
        email: email,
      },
      {
        onSuccess: (data) => {
          if (!data.validateEmail) {
            setValidEmail([
              {
                ...{ rule: "Email is assigned to an account", valid: false },
                ...rules,
              },
            ]);
          } else {
            setValidEmail([]);
          }
        },
      }
    );
  }

  function initSteps() {
    if (registerType === "Trial") {
      return [
        {
          id: 0,
          name: "Get Started",
          description: "Start your account creation",
          icon: "question-circle",
          done: false,
        },

        {
          id: 1,
          name: "Your details",
          description: "Provide an email and password",
          icon: "person",
          done: false,
        },
        {
          id: 2,
          name: "Verify your email",
          description: "Enter your verification code",
          icon: "envelope",
          done: false,
        },
        {
          id: 3,
          name: "Your organization",
          description: "About your organization",
          icon: "building",
          done: false,
        },
        // {
        //   id: 4,
        //   name: "Payment Information",
        //   description: "Provide a payment type",
        //   icon: "cash",
        //   done: false,
        // },
        {
          id: 4,
          name: "Welcome to your new account",
          description: "Get up and running in 5 minutes",
          icon: "rocket",
          done: false,
        },
      ];
    }

    if (registerType === "Join") {
      return [
        {
          id: 1,
          name: "Join Organization",
          description: "Provide a join code, create or use existing account",
          icon: "person",
          done: activeStep === 1,
        },
        {
          id: 2,
          name: "Verify your email",
          description: "Enter your verification code",
          icon: "envelope",
          done: activeStep === 2,
        },
        // {
        //   id: 3,
        //   name: "Your organization",
        //   description: "About your organization",
        //   icon: "building",
        //   done: false,
        // },
        // {
        //   id: 4,
        //   name: "Payment Information",
        //   description: "Provide a payment type",
        //   icon: "cash",
        //   done: false,
        // },
        {
          id: 4,
          name: "Welcome to your new account",
          description: "Get up and running in 5 minutes",
          icon: "rocket",
          done: activeStep === 4,
        },
      ];
    }
  }

  const validateCode = useValidateJoinCodeOrganization();
  function validateJoinCode(code) {
    if (code && code != "" && code?.length > 0) {
      setLoadingOrg(true);
      validateCode.mutate(
        {
          id: code,
        },
        {
          onSuccess: (data) => {
            setLoadingOrg(false);
        
            if (data?.data?.success) {
              setCodeSuccess(true);
              setTimeout(() => {
                setCodeSuccess(false);
                setValidCode(data?.data?.success);
                setOrg({
                  name: data?.data?.organization?.name,
                  image: data?.data?.image,
                });
              }, 2000);
            }
            if (!data?.data?.success) {
              setCodeError(true);
              setTimeout(() => {
                setValidCode(data?.data?.success);
                setCodeError(false);
                setOrg({
                  name: undefined,
                  image: undefined,
                });
              }, 3000);
            }
          },
        }
      );
    } else {
      setLoadingOrg(false);
      setOrg({
        name: undefined,
        image: undefined,
      });
      setCodeRules(undefined);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaToken) {
      alert("Please complete the reCAPTCHA!");
      return;
    }
    setWorking(true);
    if (user?.firstName === "") {
      setValidFirstName(false);
    }
    if (user?.lastName === "") {
      setValidLastName(false);
    }
    if (user?.password === "") {
      setValidPassword(false);
    }
    if (user?.email === "" || !user?.email.includes("@")) {
      setValidEmail(false);
    }
    if (
      // validCode &&
      validFirstName &&
      validLastName &&
      validEmail &&
      validPassword &&
      validConfirmPassword &&
      captchaToken
    ) {
      registerUserRequest.mutate(
        {
          joinCode: joinCode,
          captchaToken: captchaToken,
          newAccount: true,
          data: {
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            password: user?.password,
            joinCode: joinCode,
            organizationName: org?.name,
            organizationDomain: org?.domain,
            organizationTimezone: org?.timezone?.timezone,
            phone: "",
            loggedIn: false,
          },
        },
        {
          onSuccess: (data) => {
            navigate("/register/verify-email/" + data?.data?.token);
            setWorking(false);
          },
          onSettled: (data) => {
            setWorking(false);
          },
        }
      );
    }
  };

  const player3 = useRef(null);

  return (
    <div className={styles.page}>
      <form className={styles.form}>
        <img
          src={reactionLogo}
          width={"200px"}
          style={{ marginLeft: "-.25rem", marginBottom: "1rem" }}
        />

        {!validCode && (
          <>
            <h2>Create an Account</h2>
            You must have a valid join code in order to create an account. Enter
            your code below.
            {/* <TextFieldSimple
              type="text"
              onChange={(code) => {
                setJoinCode(code);
                validateJoinCode(code);
              }}
              value={joinCode}
              valid={validCode}
              // onBlur={() => validateJoinCode(joinCode)}
              label="Join Code*"
              validationRules={codeRules}
              // icon="hash"
            /> */}
            <div style={{ margin: "1rem 0rem", marginTop: "2rem" }}>
              {" "}
              <CodeInput
                length={5}
                onComplete={(code) => {
                  setJoinCode(code);
                  validateJoinCode(code);
                }}
              ></CodeInput>
            </div>
            {/* <HorizontalBar
              height={1}
              style={{ margin: "1rem 0", marginBottom: "2rem" }}
            ></HorizontalBar> */}
          </>
        )}
        {!loadingOrg && org?.image && org?.name && (
          <FlexRow justify={"center"}>
            <span>You are going to join </span>
            <ImageEditor
              src={org?.image?.imageURL}
              alt={org?.image?.name}
              canEdit={false}
              isLogo={true}
              position={
                org?.image?.position && typeof org?.image?.position === "string"
                  ? JSON.parse(org?.image?.position)
                  : org?.image?.position
              }
              image={org?.image}
              height={150}
              width={206}
              noShadow
              customScale={org?.image?.scale - 0.05}
            ></ImageEditor>
          </FlexRow>
        )}
        {codeSuccess && (
          <FlexCol gap="1rem" align={"center"}>
            <Player
              autoplay
              onEvent={(e) => {
                if (e === "play") {
                  setTimeout(() => {
                    player3?.current?.pause();
                  }, 2000);
                }
              }}
              ref={player3}
              src={require("assets/animations/checkmarkgreen.json")}
              style={{
                height: "100px",
                width: "100px",
              }}
            />
            <FlexRow fit gap="0">
              Success!
            </FlexRow>
          </FlexCol>
        )}
        {codeError && (
          <FlexCol gap="1rem" align={"center"}>
            <span className={styles.red}> Error: Not a valid code</span>
          </FlexCol>
        )}
        {loadingOrg && (
          <FlexCol gap="1rem" align={"center"}>
            <Loading></Loading>
            <FlexRow fit gap="0">
              Verifying code
              <span className={styles.dots}>
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </span>
            </FlexRow>
          </FlexCol>
        )}
        {validCode && !loadingOrg && (
          <>
            <h2>Create an Account</h2>
            <FlexRow>
              <TextFieldSimple
                type="text"
                onChange={(name) => {
                  setUser({ ...user, firstName: name });
                }}
                label="First Name*"
                valid={validFirstName}
                value={user?.firstName}
                invalidMessage="Require a First Name"
                placeholder="John"
              />
              <TextFieldSimple
                type="text"
                onChange={(name) => {
                  setUser({ ...user, lastName: name });
                }}
                label="Last Name*"
                value={user?.lastName}
                valid={validLastName}
                invalidMessage="Require a Last Name"
                placeholder="Doe"
              />
            </FlexRow>
            <TextFieldSimple
              id="emailField"
              type="text"
              onChange={handleChangeEmail}
              value={user?.email}
              valid={validEmail?.every((rule) => rule?.valid) || !user?.email}
              // invalidMessage={"Invalid Email"}
              label="Email*"
              validationRules={validEmail}
              placeholder="john.doe@exmaple.com"
            />
            <TextFieldSimple
              type={passwordType?.type}
              onChange={handleChangePassword}
              value={user?.password}
              valid={user?.password && validPassword}
              finalIcon={passwordType?.icon}
              onClickFinalIcon={() => {
                if (passwordType?.type === "password") {
                  setPasswordType({
                    icon: "eye",
                    type: "text",
                  });
                } else {
                  setPasswordType({
                    icon: "eye-slash",
                    type: "password",
                  });
                }
              }}
              invalidMessage={invalidPasswordMessage}
              password
              label="Password*"
              validationRules={validationRules}
              validationRulesTitle="Password Requirements"
              placeholder={
                passwordType?.type === "password"
                  ? "•••••••••"
                  : "ExamplePassword123!"
              }
            />
            <TextFieldSimple
              type={confirmPasswordType?.type}
              onChange={handleChangeValidatePassword}
              value={confirmPassword}
              finalIcon={confirmPasswordType?.icon}
              onClickFinalIcon={() => {
                if (confirmPasswordType?.type === "password") {
                  setConfirmPasswordType({
                    icon: "eye",
                    type: "text",
                  });
                } else {
                  setConfirmPasswordType({
                    icon: "eye-slash",
                    type: "password",
                  });
                }
              }}
              // placeholder="Confirm Password"
              valid={validConfirmPassword}
              invalidMessage={"Passwords must match"}
              password
              label="Confirm Password*"
              placeholder={
                confirmPasswordType?.type === "password"
                  ? "•••••••••"
                  : "ExamplePassword123!"
              }
            />
            <FlexRow style={{ marginLeft: "1rem", marginTop: ".5rem" }}>
              <Checkbox
                checked={acceptPrivacy}
                onChange={(e) => setAcceptPrivacy(e.target.checked)}
              ></Checkbox>{" "}
              <div className={styles.text_2}>
                I agree to ReactionData's{" "}
                <a
                  className={styles.link}
                  onClick={() => setShowPrivacyPolicy(true)}
                >
                  Privacy
                </a>{" "}
                Statement
              </div>
            </FlexRow>
            <FlexRow style={{ marginLeft: "1rem" }}>
              <Checkbox
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)}
              ></Checkbox>{" "}
              <div className={styles.text_2}>
                I agree to ReactionData's{" "}
                <a className={styles.link} onClick={() => setShowToS(true)}>
                  Terms & Conditions
                </a>
              </div>
            </FlexRow>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={(token) => setCaptchaToken(token)}
            />
          </>
        )}
        {validCode && (
          <Button
            blue
            width={"100%"}
            height={2.5}
            shadow
            style={{ marginTop: "1rem", fontSize: "1rem" }}
            disable={
              !validCode ||
              !user?.email ||
              !validEmail?.every((rule) => rule?.valid) ||
              !user?.firstName ||
              !user?.lastName ||
              !validPassword ||
              user?.password != confirmPassword ||
              working ||
              !acceptPrivacy ||
              !acceptTerms ||
              !captchaToken
            }
            onClick={handleSubmit}
          >
            {!working ? (
              "Create account"
            ) : (
              <>
                Working
                <span className={styles.dots}>
                  <span>.</span>
                  <span>.</span>
                  <span>.</span>
                </span>
              </>
            )}
          </Button>
        )}
        <FlexRow justify={"center"} style={{ marginTop: "1rem" }}>
          <span>
            Already have an account?{" "}
            <a className={styles.link} onClick={() => navigate("/login")}>
              Log in
            </a>
          </span>
        </FlexRow>
      </form>

      <div className={styles.example}>
        <FlexCol gap="1rem">
          <h2 style={{ marginLeft: "-.1rem" }}>
            Get all the feedback you need with Reaction
          </h2>
          Use Reaction to get feedback from any audience, analyze your results,
          and take action on your findings.
        </FlexCol>

        <img src={require("assets/images/ReactionResults.png")}></img>
      </div>
      {showToS && <ToSComponent show={showToS} setShow={setShowToS} />}
      {showPrivacyPolicy && (
        <PrivacyPolicy
          show={showPrivacyPolicy}
          setShow={setShowPrivacyPolicy}
        />
      )}
      {/* <div className={styles.pageContent}>
        {window.innerWidth > 800 && (
          <div className={styles.navProcess}>
            <FlexCol gap={"0rem"}>
              <div
                className={styles.imageContainer}
                style={{ marginBottom: "4rem" }}
              >
                <img
                  src={require("assets/images/reactionlogonew.png")}
                  width={"200px"}
                />
              </div>

              {steps.map((step, i) => (
                <FlexRow align={"flex-start"} key={i}>
                  <FlexCol fit>
                    <Button
                      style={{ border: "1px solid lightgray" }}
                      height={"40px"}
                      width={"40px"}
                    >
                      <i
                        className={`bi-${step?.icon}`}
                        style={{
                          fontSize: "1rem",
                          color: activeStep >= step?.id ? "" : "gray",
                        }}
                      ></i>
                    </Button>
                    {i < steps?.length - 1 && (
                      <HorizontalBar
                        rotate
                        style={{ margin: "0", marginLeft: "1.15rem" }}
                        height={"50px"}
                        width={1}
                        backgroundColor={"lightgray"}
                      ></HorizontalBar>
                    )}
                  </FlexCol>
                  <FlexCol fit>
                    <div
                      className={styles.header_6}
                      style={{
                        margin: "0",
                        color: activeStep >= step?.id ? "" : "gray",
                      }}
                    >
                      {step?.name}
                    </div>
                    <div
                      className={styles.text_3}
                      style={{ color: activeStep >= step?.id ? "" : "gray" }}
                    >
                      {step?.description}
                    </div>
                  </FlexCol>
                </FlexRow>
              ))}
            </FlexCol>
            <div>
              <div
                style={{
                  display: "flex",
                  gap: ".5rem",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                Already have an account?
                <div
                  style={{
                    display: "flex",
                    gap: ".5rem",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    onClick={() => navigate("/login")}
                    type="submit"
                    shadow
                    blue
                  >
                    Sign In
                  </Button>
                  or{" "}
                  <Button
                    onClick={() => {
                      navigate("/register/join-organization"),
                        location.reload();
                    }}
                    type="submit"
                    shadow
                    blue
                  >
                    Join Organization
                  </Button>
                </div>
              </div>
              <div className={styles.register}>
                Request a demo or learn more at{" "}
                <a href="https://reactiondata.com" className={styles.aLink}>
                  {" "}
                  reactiondata.com
                </a>
                .
              </div>
            </div>
          </div>
        )}
        <RegisterRoutes
          setActiveStep={setActiveStep}
          org={org}
          setOrg={setOrg}
          type={registerType}
          setType={setRegisterType}
        ></RegisterRoutes>
      </div> */}
    </div>
  );
}

export function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem("reaction_token");
    if (tokenString != undefined) {
      return JSON.parse(tokenString);
    }
    return null;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem("reaction_token", JSON.stringify(userToken));
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
}
