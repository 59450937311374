import React from "react";
import styles from "./EmailWidgets.module.scss";
import EmailWidget from "./EmailWidget";
import { PaginationContainer } from "../../Pagination/PaginationContainer";
import { Pagination } from "../../Pagination/Pagination";

function EmailWidgets({
  surveys,
  onClick,
  copyClick,
  survey,
  iteration,
  user,
  currPage,
  setCurrPage,
  length,
  count,
  searchString,
  perPage,
  setPerPage,
  setPageSkip,
}) {
  return (
    <div className={styles.surveys}>
      {surveys.map((surv) => (
        <EmailWidget
          key={surv.id}
          email={surv}
          onClick={onClick}
          copyClick={copyClick}
          isProgram={survey?.isProgram}
          iteration={iteration}
          user={user}
        />
      ))}{" "}
      {count > 3 && (
        <PaginationContainer
          style={{
            maxWidth: "1000px",
            padding: "0",
          }}
        >
          <Pagination
            pageNum={currPage}
            setPageNum={setCurrPage}
            maxPage={count / perPage < 1 ? 1 : Math.ceil(count / perPage)}
            length={length}
            searchString={searchString}
            perPage={perPage}
            setPerPage={setPerPage}
            setPageSkip={setPageSkip}
          ></Pagination>
        </PaginationContainer>
      )}
    </div>
  );
}

export default EmailWidgets;
