import React from "react";

import Modal from "components/ReactModal/ReactModal";
import styles from "./AddContact.module.scss";
import { ContactLayout } from "components/Popout/ContactLayout";

export function AddContact({ onClose, onSave }) {
  return (
    <>
      <Modal
        show
        onClose={onClose}
        hideX
        modalStyle={{
          borderRadius: "7px",
          height: "100%",
          width: "100%",
        }}
        dark
      >
        <div className={styles.container} id="New Contact Input">
          <ContactLayout
            newContact
            cancel={onClose}
            contact={{
              id: "",
              email: "",
              phone: "",
              firstName: "",
              lastName: "",
              attribute: [],
            }}
            onSave={onSave}
          ></ContactLayout>

          <div id="bottom of container"></div>
        </div>
      </Modal>
    </>
  );
}
