/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson

*/
import styles from "./SurveyComponent.module.scss";
import Section from "components/QuestionConstruction/Section";
import Question from "components/QuestionConstruction/Question";
import { useState, useRef } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useHasQuestionBeenAnswered } from "api/resources/projects/questions";
import { Loading } from "components/Loading/Loading";
import { Add } from "../QuestionConstruction";

export default function SurveyComponent({
  component,
  active,
  setActive,
  status,
  canEdit,
  onEditQ,
  deleteQ,
  designSettings,
  onCopy,
  onAnswer,
  onRetract,
  answers,
  dependents,
  setUpLogicResolver,
  survey,
  calculateQuestionHealth,
  questionNumber,
  onAddQ,
  onAddSection,
  onAddImage,
  onPaste,
  globalCounter,
}) {
  const [makeACopy, setMakeACopy] = useState(false);
  // const [health, setHealth] = useState(calculateQuestionHealth(component));

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: component.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    opacity: isDragging ? 0.4 : undefined,
  };

  if ('sectionSettings' in component) {
    style.padding = '.5em'
  }

  function onClick() {
    if (!active) {
      setActive();
    }
  }

  function onEditQuestion(copy) {
    onEditQ(copy);
    // setHealth({ ...calculateQuestionHealth(copy) });
  }

  const [showItems, setShowItems] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const isASection = component.type === "Description";

  function clickOutside(e) {
    if (!ref.current || !ref.current?.contains(e.target)) {
      setShowItems(false);
      setMakeACopy(false);
      document.removeEventListener("click", clickOutside, true);
    }
  }

  const ref = useRef();

  function setUpSettings() {
    if (showItems) {
      setShowItems(false);
    } else {
      setShowItems(true);
      document.addEventListener("click", clickOutside, true);
    }
  }

  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "column" }}
        id={"survey component: " + component.id}
      >
        {canEdit && (
          <div
            style={{ position: "relative" }}
            onMouseEnter={() => setShowDelete(true)}
            onMouseLeave={() => setShowDelete(false)}
          >
            <Add
              show={active}
              onAddQ={() => onAddQ(-1)}
              onAddSection={() => onAddSection(-1)}
              onPaste={onPaste}
              onAddImage={() => onAddImage(-1)}
            />
          </div>
        )}
        {component.new && <Loading height={80} width={80}></Loading>}
        {!component.new && (
          <div
            className={`${styles.questionContainer} ${
              active ? styles.activeQuestionContainer : ""
            } ${isDragging ? styles.dragging : ""}`}
            ref={setNodeRef}
            style={{
              ...style,
              backgroundColor:
                "sectionSettings" in component &&
                component.sectionSettings?.hasBackgroundColor
                  ? component.sectionSettings?.backgroundColor
                  : "questionText" in component
                  ? ""
                  : "transparent",
              cursor: active ? "default" : "pointer",
              boxShadow:
                "sectionSettings" in component
                  ? component.sectionSettings?.sectionShadow
                    ? component.sectionSettings?.sectionShadow
                    : ""
                  : "boxShadow" in designSettings
                  ? designSettings?.boxShadow
                  : "",
            }}
            {...attributes}
            onClick={onClick}
            onMouseEnter={() => setShowDelete(true)}
            onMouseLeave={() => setShowDelete(false)}
          >
            {canEdit && (showDelete || active) && (
              <>
                <div className={`${styles.grip} ${styles.item}`} {...listeners}>
                  {" "}
                  <i className={`bi-grip-horizontal`}></i>
                </div>

                <div
                  className={`${styles.dots}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    // setActive();

                    setUpSettings();
                  }}
                >
                  <i
                    className={`bi-three-dots ${styles.item} ${
                      showItems && styles.activeItem
                    }`}
                  ></i>
                  {showItems && (
                    <div className={styles.settingsBox} ref={ref}>
                      {!component.new && (
                        <div
                          className={`${styles.menuItem} ${
                            makeACopy && styles.copyView
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setMakeACopy(!makeACopy);
                          }}
                        >
                          {" "}
                          <i
                            className="bi-layers"
                            style={{ color: "#F2C85C" }}
                          ></i>
                          Make a Copy
                        </div>
                      )}

                      {makeACopy && (
                        <>
                          {" "}
                          <div
                            className={styles.menuItem}
                            onClick={(e) => {
                              e.stopPropagation();
                              onCopy(component, e, "abovepage");
                            }}
                          >
                            {" "}
                            <i
                              className="bi-align-top"
                              style={{ color: "#2A627C" }}
                            ></i>
                            Above Page
                          </div>
                          <div
                            className={styles.menuItem}
                            onClick={(e) => {
                              e.stopPropagation();
                              onCopy(component, e, "above");
                            }}
                          >
                            {" "}
                            <i
                              className="bi-caret-up"
                              style={{ color: "#2A627C" }}
                            ></i>
                            Above
                          </div>
                          <div
                            className={styles.menuItem}
                            onClick={(e) => {
                              e.stopPropagation();
                              onCopy(component, e, "below");
                            }}
                          >
                            {" "}
                            <i
                              className="bi-caret-down"
                              style={{ color: "#2A627C" }}
                            ></i>
                            Below
                          </div>
                          <div
                            className={styles.menuItem}
                            onClick={(e) => {
                              e.stopPropagation();
                              onCopy(component, e, "belowpage");
                            }}
                          >
                            {" "}
                            <i
                              className="bi-align-bottom"
                              style={{ color: "#2A627C" }}
                            ></i>
                            Below Page
                          </div>
                        </>
                      )}

                      {status !== "Closed" && (
                        <div
                          className={`${styles.menuItem}  ${
                            makeACopy && styles.disabled
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteQ(component, e);
                          }}
                        >
                          <i
                            className="bi bi-trash3"
                            style={{ color: !makeACopy ? "#FF8878" : "" }}
                          ></i>
                          Delete
                        </div>
                      )}
                      {status === "Closed" && (
                        <DeleteClosed
                          onDelete={(e) => deleteQ(component, e)}
                          question={component}
                          makeACopy={makeACopy}
                        />
                      )}
                    </div>
                  )}
                </div>
              </>
            )}

            {(component?.questionText || component?.questionText === "") && (
              <Question
                key={component.id}
                question={component}
                active={canEdit && active}
                saveQuestion={onEditQuestion}
                designSettings={designSettings}
                onAnswer={onAnswer}
                onRetract={onRetract}
                answerMap={answers}
                status={status}
                dependents={dependents}
                setUpLogicResolver={setUpLogicResolver}
                questionNumber={questionNumber}
                audienceType={survey?.audienceType}
                checkHealth={survey?.useGPS}
                globalCounter={globalCounter}
              ></Question>
            )}
            {!component.questionText && component?.questionText != "" && (
              <Section
                key={component.id}
                section={component}
                active={active}
                saveSection={onEditQ}
              ></Section>
            )}
          </div>
        )}
        {canEdit && (
          <div
            style={{ position: "relative" }}
            onMouseEnter={() => setShowDelete(true)}
            onMouseLeave={() => setShowDelete(false)}
          >
            <Add
              show={active}
              onAddQ={() => onAddQ(+1)}
              onAddSection={() => onAddSection(+1)}
              onAddImage={() => onAddImage(+1)}
              onPaste={onPaste}
            />
          </div>
        )}
      </div>
    </>
  );
}

function DeleteClosed({ question, onDelete, makeACopy }) {
  const hasAnswers = useHasQuestionBeenAnswered(question.id);
  function onWantToDelete(e) {
    if (hasAnswers.data.doesQuestionHaveAnswers) {
      window.alert("People have answered this question. It cannot be deleted");
    } else {
      onDelete(e);
    }
  }

  return (
    <>
      <div
        className={`${styles.menuItem} ${
          !hasAnswers.isSuccess && styles.disabled
        } ${makeACopy && styles.disabled}`}
        onClick={hasAnswers.isSuccess ? onWantToDelete : null}
      >
        {" "}
        <i
          className="bi bi-trash3"
          style={{ color: !makeACopy ? "#FF8878" : "" }}
        ></i>
        Delete
      </div>

      {/* <Modal
            show={show}
            onClose={() => setShow(false)}
            modalStyle={{
              padding: "1em",
              borderRadius: "1em",
              overflow: "visible",
            }}
          >
            <div className={styles.deleteModalPrompt}>
              People have answered this question. Deleting this question will
              delete all answers to this question.
            </div>
            <div className={styles.confirmDelete}>
              Would you still like to delete this question?
            </div>
          </Modal> */}
    </>
  );
}
