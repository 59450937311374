import React, { useState } from "react";
import styles from "./CreateAudience.module.scss";
import { SelectField, TextFieldSimple } from "components/inputs";
import Button from "components/Button/Button";
import { Loading } from "components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { maintimezones } from "assets/functions/Variables";
import { Label } from "components/layouts/Label/Label";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import IconShopperModal from "pages/results/Charts/Settings/AccordianItems/MetricSettings/IconShopper/IconShopperModal";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import {
  useCreateAudienceFolder,
  useCreateAudienceGql,
  useDeleteAudienceFolder,
  useUpdateAudienceFolder,
} from "api/resources/contacts/audiences";
import Icon from "components/Icon/Icon";
import { Header } from "components/layouts";

function CreateAudience({ audience, create, filters, folder, close }) {
  const [name, setName] = useState(audience?.name);
  const [icon, setIcon] = useState(
    audience?.icon ? audience?.icon : !folder ? "people-fill" : "folder-fill"
  );
  const [iconColor, setIconColor] = useState(
    audience?.iconColor ? audience?.iconColor : "#15bcc7"
  );
  const [changeIcon, setChangeIcon] = useState(false);
  const [description, setDescription] = useState(audience?.description);
  const [saveState, setSaveState] = useState(create ? "Create" : "Save");
  const [timezone, setTimezone] = useState(
    maintimezones.find((t) => t.value === audience?.timeZone)
  );

  let navigate = useNavigate();

  const createAudienceQuery = useCreateAudienceGql();
  const createAudienceFolder = useCreateAudienceFolder();
  const updateAudienceFolder = useUpdateAudienceFolder();

  function saveChanges() {
    setSaveState(
      <div style={{ display: "flex", gap: ".5em", width: "fit-content" }}>
        Saving... <Loading height={20} width={20}></Loading>
      </div>
    );
    if (create) {
      if (!folder) {
        const data = {
          name: name,
          description: description,
          timeZone: timezone?.timezone,
          icon: icon,
          iconColor: iconColor,
          filters: filters ? JSON.stringify(filters) : undefined,
          dynamic: filters ? true : false,
        };

        createAudienceQuery.mutate(
          {
            data: data,
          },
          {
            onSuccess: (data) => {
              setSaveState(
                <div>
                  Saved <i className="bi bi-check"></i>
                </div>
              );
              setTimeout(() => {
                setSaveState("Save Changes");
              }, 2000);

              navigate("/ContactManagement/Audiences/" + data?.audience?.id);
            },
          }
        );
      } else {
        const data = {
          name: name,
          icon: icon,
          iconColor: iconColor,
        };

        createAudienceFolder.mutate(
          {
            data: data,
          },
          {
            onSuccess: (data) => {
              setSaveState(
                <div>
                  Saved <i className="bi bi-check"></i>
                </div>
              );
              setTimeout(() => {
                setSaveState("Save");
                close();
              }, 1000);
            },
          }
        );
      }
    }

    if (!create) {
      if (!folder) {
        //update audience
      } else {
        //update folder
        const data = {
          name: name,
          icon: icon,
          iconColor: iconColor,
          id: audience?.id,
        };

        updateAudienceFolder.mutate(
          {
            data: data,
          },
          {
            onSuccess: (data) => {
              setSaveState(
                <div>
                  Saved <i className="bi bi-check"></i>
                </div>
              );
              setTimeout(() => {
                setSaveState("Save");
                close();
              }, 1000);
            },
          }
        );
      }
    }
  }

  const deleteFolder = useDeleteAudienceFolder();
  function handleDeleteFolder(folder) {
    if (
      !create &&
      folder &&
      window.confirm("Are you sure you want to delete this folder?")
    ) {
      deleteFolder.mutate(
        {
          id: folder?.id,
        },
        {
          onSuccess: () => {
            location.reload();
          },
        }
      );
    }
  }

  return (
    <div className={styles.settingsPage}>
      {!create && folder && (
        <div className={styles.delete}>
          <Button
            style={{ gap: ".5rem" }}
            shadow
            onClick={() => handleDeleteFolder(audience)}
          >
            <Icon red iconName={"trash"}></Icon>Delete Folder
          </Button>
        </div>
      )}
      <Header>
        <FlexCol gap=".5rem">
          <FlexRow justify={"space-between"}>
            <h2 style={{ whiteSpace: "nowrap" }}>
              {create ? "Create" : "Edit"} {folder ? "Folder" : "Audience"}
            </h2>{" "}
            <FlexRow fit>
              <Button onClick={() => (close ? close() : undefined)}>
                Cancel
              </Button>
              <Button
                shadow
                onClick={saveChanges}
                disable={!name}
                blue
                width={120}
              >
                {saveState}
              </Button>
            </FlexRow>
          </FlexRow>

          {filters?.length > 0 && (
            <FlexRow start style={{ width: "fit-content" }}>
              <span className={styles.label5}>Current View:</span>
              {filters?.length === 0 && (
                <div
                  className={styles.filter}
                  onClick={() => setShowFilters(true)}
                >
                  Contacts to be added:
                </div>
              )}
              {filters?.length > 0 &&
                filters?.map((filter) => (
                  <div
                    className={styles.filter}
                    onClick={() => setShowFilters(true)}
                  >
                    {filter?.label}:
                    {filter?.properties?.map((prop, i) => (
                      <div className={styles.prop}>
                        {filter?.operator === 0 && "equals"}
                        {filter?.operator === 1 && "does not equal"}
                        {filter?.operator === 2 && "contains "}
                        {filter?.operator === 3 && "does not contain "}"
                        {prop?.label ? prop?.label : prop}"
                        {i != filter?.properties?.length - 1 ? "," : ""}
                      </div>
                    ))}
                  </div>
                ))}{" "}
            </FlexRow>
          )}
        </FlexCol>
      </Header>
      <FlexRow style={{ padding: "2rem", gap: "4rem" }} wrap="wrap">
        <FlexCol style={{ width: "500px" }} gap={"1rem"}>
          <h2 style={{ whiteSpace: "nowrap" }}>
            Set {folder ? "Folder" : "Audience"} Details
          </h2>
          <TextFieldSimple
            value={name}
            label={`${folder ? "Folder" : "Audience"} Name *`}
            onChange={(val) => setName(val)}
            placeholder="Name"
          ></TextFieldSimple>
          {!folder && <TextFieldSimple
            value={description}
            label={`${folder ? "Folder" : "Audience"} Description`}
            onChange={(val) => setDescription(val)}
            placeholder="Description"
          ></TextFieldSimple>}
          {!folder && (
            <SelectField
              value={timezone}
              label="Time Zone"
              options={maintimezones}
              onChange={(val) => setTimezone(val)}
            ></SelectField>
          )}
          <FlexRow fit>
            <div className={styles.icon}>
              <Label>Icon: </Label>
              <i
                className={"bi-" + icon}
                style={{ color: iconColor }}
                onClick={() => setChangeIcon(true)}
              ></i>
              <ColorPicker
                defaultColor={iconColor}
                onChange={setIconColor}
              ></ColorPicker>
            </div>

            {changeIcon && (
              <IconShopperModal
                onPick={(i) => {
                  setIcon(i);
                }}
                onClose={() => setChangeIcon(false)}
              ></IconShopperModal>
            )}
          </FlexRow>

          <FlexRow justify={"center"}>
            <Button onClick={() => (close ? close() : undefined)}>
              Cancel
            </Button>
            <Button
              shadow
              onClick={saveChanges}
              disable={!name}
              blue
              width={120}
            >
              {saveState}
            </Button>
          </FlexRow>
        </FlexCol>
        <img
          src={
            folder
              ? require("assets/images/folders.png")
              : require("assets/images/settings.png")
          }
          style={{ margin: "0 auto" }}
          width={300}
        ></img>
      </FlexRow>{" "}
      {/* <HorizontalBar height="2px" backgroundColor="lightgray"></HorizontalBar> */}
    </div>
  );
}

export default CreateAudience;
