import React, { useState } from "react";
import { TextFieldSimple } from "components/inputs";
import styles from "./Roles.module.scss";
import {
  useCreateRoleGql,
  useDeleteRoleGql,
  useFetchOrgRoles,
  useUpdateRoleGql,
} from "api/resources/organization/roles";
import PermissionsCategories from "./permissions";
import Button from "components/Button/Button";
import SettingToggle from "./SettingToggle";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { Loading } from "components/Loading/Loading";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { HeaderContainer } from "components/layouts/Header/HeaderContainer";
import Status from "components/Status/Status";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import { TextArea } from "components/inputs/input_fields/TextArea/TextArea";
import Modal from "components/ReactModal/ReactModal";
import CreateRole from "./CreateRole";
import { ErrorBanner } from "pages/error_page/ErrorPage";

export const Roles = ({ usersRole, user, organization }) => {
  const fetchRolesQuery = useFetchOrgRoles();

  return (
    <>
      {fetchRolesQuery.isError && (
        <ErrorBanner
          error={fetchRolesQuery.error}
          message={"Error loading roles"}
        />
      )}
      {fetchRolesQuery.isLoading && <Loading></Loading>}
      {fetchRolesQuery.isSuccess && (
        <EditRoles
          usersRole={usersRole}
          user={user}
          organization={organization}
          roles={fetchRolesQuery.data.roles}
          refetch={fetchRolesQuery.refetch}
        />
      )}
    </>
  );
};
function EditRoles({ usersRole, user, organization, roles, refetch }) {
  const update = useUpdateRoleGql();
  const deleteRole = useDeleteRoleGql();
  const [currRole, setCurrRole] = useState(
    roles.find((r) => r.id === usersRole.id)
  );
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [active, setActive] = useState(currRole.id);
  const [newRole, setNewRole] = useState(false);

  const blankRole = {
    name: "",
    description: "",
    isAdmin: false,

    //Contacts
    canViewContacts: true,
    canEditContacts: true,
    canSeeContactInfo: true,
    canViewOrgAudiences: true,
    canCreateEditOrgAudiences: true,

    //Surveys
    canCreateEditOrgSurveys: true,
    canViewOrgSurveys: true,

    //Analyze
    canViewOrgReports: true,
    canCreateEditOrgReports: true,
    canShareOrgReports: true,

    //Dashboard
    canEditPersonalDashboard: true,
    canViewPersonalDashboard: true,
    canViewOrgDashboard: true,
  };

  const handleActiveUpdate = (id) => {
    setActive(id);
    for (let i = 0; i < roles.length; i++) {
      if (roles[i].id === id) {
        setCurrRole(roles[i]);
      }
    }
    setSaved(false);
    if (error) setError(false);
  };

  const updateRole = (role) => {
    setCurrRole(role);

    let data = { ...role };
    delete data.id;
    delete data.createdAt;

    update.mutate(
      {
        updateRoleId: currRole.id,
        data: data,
      },
      {
        onSuccess: (data) => {
          setCurrRole({ ...data.role });
          setSaving(false);
          setSaved(true);
        },
        onError: (data) => {
          setError(true);
          setSaving(false);
          setSaved(false);
        },
      }
    );

    setSaving(true);
    if (error) setError(false);
  };

  const onDelete = () => {
    deleteRole.mutate(
      {
        deleteRoleId: currRole.id,
      },
      {
        onSuccess: (data) => {
          console.log("Role Deleted", data);
          setCurrRole(null);
        },
        onError: (data) => {
          setError(true);
        },
      }
    );
  };

  function changePermission(permission, e) {
    if (!currRole.isAdmin) {
      let role = { ...currRole };
      role[permission] = e.target.checked;
      updateRole(role);
    } else {
      alert("Admin Role Cannot be changed");
    }
  }

  const handleDelete = () => {
    if (
      window.confirm(
        "Are you sure you want to delete the " + currRole.name + " role?"
      )
    ) {
      onDelete();
    }
  };

  const updateRoleField = (field, val) => {
    let role = { ...currRole };
    role[field] = val;
    updateRole(role);
    setCurrRole(role);
  };

  return (
    <>
      {update.isError && (
        <ErrorBanner error={update.error} message={"Error updating role"} />
      )}

      {deleteRole.isError && (
        <ErrorBanner error={deleteRole.error} message={"Error deleting role"} />
      )}
      <div className={styles.page}>
        {newRole && (
          <Modal
            dark
            show={newRole}
            onClose={() => setNewRole(false)}
            hideX
            modalStyle={{ overflow: "hidden", minHeight: "fit-content" }}
          >
            <CreateRole onClose={() => setNewRole(false)} refetch={refetch} />
          </Modal>
        )}
        <HeaderContainer
          path={"User Roles"}
          user={user}
          roles={roles}
          organization={organization}
        ></HeaderContainer>
        <div className={styles.content}>
          <div className={styles.sidebar}>
            <FlexRow
              align={"center"}
              style={{ padding: "1rem" }}
              justify={"space-between"}
            >
              <h3 style={{ paddingLeft: ".5rem" }}>Roles</h3>
              <Button height={2} width={33} onClick={() => setNewRole(true)}>
                <i className="bi-plus" style={{ fontSize: "1.3rem" }}></i>
              </Button>
            </FlexRow>
            <HorizontalBar height={1} style={{ margin: 0 }}></HorizontalBar>
            {roles?.map((role, i) => (
              <React.Fragment key={i}>
                <div
                  className={`${styles.roleItem} ${
                    active === role?.id ? styles.highlighted : ""
                  }`}
                  onClick={() => handleActiveUpdate(role.id)}
                >
                  <FlexRow justify={"space-between"}>
                    {role.name} <Status color={role?.color}>{role.name}</Status>
                  </FlexRow>
                  {active === role?.id && (
                    <HorizontalBar
                      rotate
                      width={2}
                      style={{
                        margin: 0,
                        position: "absolute",
                        zIndex: "1",
                        right: "0px",
                      }}
                      backgroundColor={"#15bcc7"}
                    ></HorizontalBar>
                  )}
                </div>
                <HorizontalBar height={1} style={{ margin: 0 }}></HorizontalBar>
              </React.Fragment>
            ))}
          </div>
          {currRole && (
            <div className={styles.roleSettings}>
              {!currRole?.isAdmin && (
                <div className={styles.textBox}>
                  <FlexCol className={styles.setting} gap=".5rem">
                    <FlexRow>
                      {" "}
                      <h4>Role Information</h4>{" "}
                      {(saving || saved || error) && (
                        <FlexRow
                          className={`${styles.saving} ${
                            error ? styles.error : ""
                          }`}
                          gap={10}
                          fit
                          style={{ height: "30px" }}
                        >
                          {saving ? (
                            <>
                              Saving <Loading height={30} width={30}></Loading>
                            </>
                          ) : error ? (
                            <>
                              Error
                              <i className="bi-slash-circle"></i>
                            </>
                          ) : (
                            <>
                              Saved <i className="bi-check2"></i>
                            </>
                          )}
                        </FlexRow>
                      )}
                    </FlexRow>
                    <HorizontalBar
                      height={1}
                      style={{ marginTop: "0", marginBottom: ".5rem" }}
                    ></HorizontalBar>

                    <FlexRow align={"flex-end"} wrap="wrap">
                      <TextFieldSimple
                        label="Name"
                        placeholder="Role Name"
                        value={currRole?.name}
                        onBlur={(val) => updateRoleField("name", val)}
                        onEnter={(val) => updateRoleField("name", val)}
                        containerStyle={{ width: "300px" }}
                      ></TextFieldSimple>
                      <FlexRow fit style={{ paddingBottom: ".25rem" }}>
                        <Status
                          style={{
                            width: "fit-content",
                            fontSize: "1rem",
                            padding: ".25rem 1rem",
                            whiteSpace: "nowrap",
                          }}
                          color={currRole?.color}
                        >
                          {currRole?.name}
                        </Status>
                        <ColorPicker
                          height={20}
                          width={20}
                          defaultColor={currRole?.color}
                          onChange={(val) => updateRoleField("color", val)}
                        ></ColorPicker>
                      </FlexRow>
                    </FlexRow>
                    <TextArea
                      label="Description"
                      placeholder="Description"
                      value={currRole?.description}
                      onSave={(val) => updateRoleField("description", val)}
                      onEnter={(val) => updateRoleField("description", val)}
                      className={styles.area}
                    />
                  </FlexCol>
                </div>
              )}
              {currRole?.isAdmin && (
                <div className={styles.textBox}>
                  <FlexCol>
                    <div
                      className={styles.header_5}
                      style={{ margin: "0", fontWeight: "600" }}
                    >
                      Admin (Owner)
                    </div>
                    <FlexRow gap={".5rem"} align={"flex-start"}>
                      <div
                        className={styles.text_3}
                        style={{ margin: "0", maxWidth: "700px" }}
                      >
                        Admins can edit and view organization settings and
                        users. They can also edit user roles and invite new
                        users to join their organization. All functionality is
                        available to Admins.
                      </div>
                    </FlexRow>
                  </FlexCol>
                </div>
              )}
              <div className={styles.accordianContainer}>
                {PermissionsCategories.map((set, i) => (
                  <React.Fragment key={i}>
                    {((currRole?.isAdmin && set?.org_permissions.length > 0) ||
                      set?.permissions.length > 0) && (
                      <div className={styles.setting}>
                        <div
                          className={styles.header_5}
                          style={{ margin: "0" }}
                        >
                          {set.set_name}
                        </div>
                        <HorizontalBar height={1}></HorizontalBar>
                        <div className={styles.allPermissions}>
                          {set?.permissions.length > 0 && (
                            <div className={styles.permissions}>
                              {set.permissions.map((permission) => (
                                <SettingToggle
                                  text={permission.label}
                                  key={permission.db_field}
                                  checked={currRole[permission.db_field]}
                                  onCheck={(e) =>
                                    changePermission(permission.db_field, e)
                                  }
                                />
                              ))}
                            </div>
                          )}
                          {currRole?.isAdmin &&
                            set?.org_permissions.length > 0 && (
                              <div className={styles.permissions}>
                                {set.org_permissions.map((permission, j) => (
                                  <SettingToggle
                                    text={permission.label}
                                    key={j}
                                    checked={true}
                                    onCheck={(e) =>
                                      changePermission(permission.db_field, e)
                                    }
                                  />
                                ))}
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>

              {(saving || saved || error) && (
                <FlexRow
                  className={`${styles.saving} ${error ? styles.error : ""}`}
                  gap={10}
                  fit
                  style={{ height: "30px" }}
                >
                  {saving ? (
                    <>
                      Saving <Loading height={30} width={30}></Loading>
                    </>
                  ) : error ? (
                    <>
                      Error
                      <i className="bi-slash-circle"></i>
                    </>
                  ) : (
                    <>
                      Saved <i className="bi-check2"></i>
                    </>
                  )}
                </FlexRow>
              )}
              {!currRole.isAdmin && (
                <FlexRow justify={"flex-end"} style={{ paddingTop: "40px" }}>
                  <Button
                    onClick={handleDelete}
                    shadow
                    red
                    width={"fit-content"}
                    style={{ gap: ".5rem" }}
                  >
                    <i className={"bi-trash"}></i> Delete Role
                  </Button>
                </FlexRow>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
