import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  useAddContactToAudiences,
  useAudienceQuery,
  useRemoveContactsFromAudience,
  useUpdateAudienceGql,
} from "api/resources/contacts/audiences";
import styles from "./Audience.module.scss";
import { Loading } from "components/Loading/Loading";
import AudienceSettings from "./AudienceSettings";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";
import { useSearchContacts } from "api/resources/contacts/contacts";
import Button from "components/Button/Button";
import Modal from "components/ReactModal/ReactModal";
import ContactProfile from "components/Popout/ContactProfile";
import UnifinishedUpload from "../all_contacts/NewContacts/upload/UnifinishedUpload";
import { trimDate } from "assets/functions/DateFunctions";
import ObjectTable from "components/tables/BasicTable/ObjectTable";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";
import { parseCustomFields } from "assets/functions/ObjectFunctions";
import FieldAdjuster from "../all_contacts/FieldAdjuster";
import ColumnsToDisplay from "../all_contacts/ColumnsToDisplay";
import ImportHow from "../all_contacts/ImportHow";
import EditSingleCustomField from "components/CustomFieldsEditor/EditSingle";
import { AddContact } from "../all_contacts/NewContacts/AddContact/AddContact";
import FilterSimple from "../all_contacts/FilterSimple";
import { Export } from "../all_contacts/export/Export";
import { SearchToAdd } from "./SearchContactsToAdd/SearchContactsToAdd";
import { useFetchUploadByAudience } from "api/resources/contacts/uploads";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { HeaderContainer } from "components/layouts/Header/HeaderContainer";
import { ErrorBanner } from "pages/error_page/ErrorPage";

function Audience({
  user,
  organization,
  audienceId,
  preview,
  roles,
  onExport,
  updateRoute,
}) {
  const { id } = useParams();
  const audienceQuery = useAudienceQuery(audienceId ? audienceId : id);
  const orgFields = useFetchCurrOrgsFields();
  useMemo(() => {
    if (updateRoute) {
      updateRoute(1);
    }
  }, []);

  return (
    <>
      {audienceQuery.isError && (
        <ErrorBanner
          error={audienceQuery.error}
          message={"Error loading audience"}
        />
      )}
      {orgFields.isError && (
        <ErrorBanner
          error={orgFields.error}
          message={"Error loading organization fields"}
        />
      )}
      {(audienceQuery.isLoading || orgFields.isLoading) && <Loading></Loading>}
      {audienceQuery.isSuccess && orgFields.isSuccess && (
        <AudienceContainer
          audience={audienceQuery.data?.audience}
          user={user}
          customFields={orgFields?.data?.getCurrOrgFields}
          organization={organization}
          preview={preview}
          roles={roles}
          onExport={onExport}
        ></AudienceContainer>
      )}
    </>
  );
}

export default Audience;

const menuItems = [
  {
    id: 0,
    name: "Contacts",
    icon: `people`,
  },
  {
    id: 1,
    name: "Settings",
    icon: `people`,
  },
  // {
  //   id: 2,
  //   name: "File Uploads",
  //   icon: `people`,
  // },
];

function AudienceContainer({
  audience,
  user,
  customFields,
  organization,
  preview,
  roles,
  onExport,
}) {
  const [settings, setSettings] = useState(false);
  const [upload, setUpload] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [pageSkip, setPageSkip] = useState(0);
  const [sort, setSort] = useState({
    descend: false,
    item: "email",
  });
  const [searchString, setSearchString] = useState("");
  const [search, setSearch] = useState("");
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [currentContact, setCurrentContact] = useState(null);
  const [active, setActive] = useState(0);
  const [chosenFilters, setChosenFilters] = useState([]);
  const [audienceState, setAudienceState] = useState({
    ...audience,
    filters: audience?.filters ? JSON.parse(audience?.filters) : [],
  });
  const [selected, setSelected] = useState([]);
  const [adjustFields, setAdjustFields] = useState(false);
  const [displayColumns, setDisplayColumns] = useState(false);
  const [importer, setImporter] = useState(false);
  const [newColumn, setNewColumn] = useState(false);
  const [addContact, setAddContact] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [first, setFirst] = useState(true);
  const [changed, setChanged] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [searchToAdd, setSearchToAdd] = useState(false);
  const obscure = !roles?.canSeeContactInfo;

  const contactsQuery = useSearchContacts(
    search,
    perPage,
    sort,
    audience?.id,
    audienceState?.filters ? JSON.stringify(audienceState?.filters) : "",
    JSON.stringify(chosenFilters)
  );
  const updateAudience = useUpdateAudienceGql();
  const removeContacts = useRemoveContactsFromAudience();
  const addNewContact = useAddContactToAudiences();

  // When item changes, trigger visibility change
  useEffect(() => {
    if (showFilters) {
      setIsVisible(true);
      setFirst(false);
    } else {
      setIsVisible(false);
    }
  }, [showFilters]);

  // When the animation ends, stop rendering the component
  const handleAnimationEnd = () => {
    if (!isVisible) {
      setShowFilters(false);
    }
  };

  const seeContact = (contact) => {
    setShowContactDetails(true);
    setCurrentContact(contact);
  };

  function initHeaders() {
    let heads = [];
    let order = undefined;
    if (audience?.columnOrder) {
      order = JSON.parse(audience?.columnOrder);
    }

    console.log(order)
    customFields.sort((a, b) => {
      if (a.name == "firstName" && a.generic) {
        return -1;
      } else if (b.name == "firstName" && b.generic) {
        return 1;
      }

      if (a.name == "lastName" && a.generic) {
        return -1;
      } else if (b.name == "lastName" && b.generic) {
        return 1;
      }

      if (a.name == "email" && a.generic) {
        return -1;
      } else if (b.name == "email" && b.generic) {
        return 1;
      }
      return 0;
    });

    for (let field of customFields) {
      let index = 1000;
      let enabled = field?.enabled;
      if (order) {
        index = order?.findIndex((c) => c?.id === field?.id);
        enabled = order[index]?.enabled;
      }

      if (field?.dataType != "lookup") {
        heads.push({
          // width: 200,
          id: field?.id,
          value: field?.generic ? field?.name : field?.id,
          label: field?.displayName ? field?.displayName : field?.name,
          name: field?.name,
          dataType: field?.dataType,
          enabled: enabled,
          editable: field?.editable,
          custom: true,
          sort: false,
          canSort: field?.generic,
          obscure: obscure,
          salesforceColumn: field?.salesforceColumn,
          icon: field?.icon ? field?.icon : "person",
          multiple: field?.multiple,
          delimiter: field?.delimiter,
          generic: field?.generic,
          index: index,
          min: field?.min ? field?.min : 0,
          max: field?.max ? field?.max : 100,
          trueValue: field?.trueValue,
          falseValue: field?.falseValue,
          unique: field?.unique ? true : false,
          required: field?.required ? true : false,
          cell_style: (name, id, i, contact) =>
            checkStyle(name, id, i, contact, field),
        });
      }
    }

    if (order) {
      const sortedArray = heads.sort((a, b) => a.index - b.index);

      return sortedArray;
    } else {
      return heads;
    }
  }

  function checkStyle(name, id, i, contact, field) {
    if (field?.name === "contactName" && contact) {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
          <div
            className={`${styles.link} ${styles.blueDark}`}
            style={{ fontWeight: "500" }}
            onClick={() => {
              seeContact(contact);
            }}
          >
            {contact && `${contact?.firstName} ${contact?.lastName}`}
          </div>
        </div>
      );
    }

    if (field?.dataType === "boolean") {
      if (name === true) {
        return field?.trueValue ? field?.trueValue : "true";
      }
      if (!name || name === false) {
        return field?.falseValue ? field?.falseValue : "false";
      }
    }

    return name;
  }

  const [headers, setHeaders] = useState(initHeaders());

  function saveDynamicChanges() {
    updateAudience.mutate(
      {
        data: {
          dynamic:
            audienceState?.filters.length === 0
              ? false
              : audienceState?.dynamic,
          filters: audienceState?.filters
            ? JSON.stringify(audienceState?.filters)
            : undefined,
        },
        id: audience?.id,
      },
      {
        onSuccess: (data) => {
          setChanged(false);
          setAudienceState({
            ...audienceState,
            updatedAt: data?.audience?.updatedAt,
          });
        },
      }
    );
  }

  const newOptions = [
    {
      onClick: () => setAddContact(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"person-fill"}></Icon> Create a new contact
          </FlexRow>{" "}
          <div className={styles.description2}>
            Create a new contact and add it to this audience.
          </div>
        </div>
      ),
    },

    {
      onClick: () => setImporter(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"people-fill"}></Icon> Upload Contacts
          </FlexRow>{" "}
          <div className={styles.description2}>
            Import in bulk to the audience with a file or an external account.
          </div>
        </div>
      ),
    },
    {
      onClick: () => setShowFilters(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"funnel"}></Icon> Add from filter
          </FlexRow>{" "}
          <div className={styles.description2}>
            Add contacts to this audinece from a dynamic filter.
          </div>
        </div>
      ),
    },
    {
      onClick: () => setSearchToAdd(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"search"}></Icon> Search Contacts
          </FlexRow>{" "}
          <div className={styles.description2}>
            Search contacts to add to this audience.
          </div>
        </div>
      ),
    },
  ];
  const dotOptions = [
    {
      onClick: () => console.log("Import"),
      data: (
        <>
          <Icon sapphire iconName={"cloud-plus"}></Icon> Import
        </>
      ),
    },
    {
      onClick: () => console.log("Export View"),
      data: (
        <>
          <Icon sapphire iconName={"cloud-download"}></Icon> Export View
        </>
      ),
    },
  ];

  const columnOptions = [
    {
      onClick: () => setAdjustFields(!adjustFields),
      data: (
        <>
          <Icon sapphire iconName={"pencil"}></Icon> Edit columns
        </>
      ),
    },
    {
      onClick: () => setDisplayColumns(true),
      data: (
        <>
          <Icon sapphire iconName={"columns"}></Icon> Select columns to display
        </>
      ),
    },
  ];

  const data = parseCustomFields(
    contactsQuery?.data?.pages.flatMap((page) => page?.response?.contacts) ||
      [],
    headers
  );

  const count =
    contactsQuery?.data?.pages?.length > 0
      ? contactsQuery?.data?.pages[0]?.response?.count
      : 0;

  function handleFilterChange(val) {
    setChosenFilters(val);
  }

  function handleSetHeaders(vals) {
    setHeaders(vals);
  }

  function handleSearch(val) {
    setSearch(searchString);
    contactsQuery?.remove();
    contactsQuery?.refetch();
  }

  function handlePageNum(val) {
    console.log("Contacts");
    if (contactsQuery?.hasNextPage) {
      contactsQuery?.fetchNextPage();
      setPageNumber(val);
    }
  }

  const selectedOptions = [
    { onClick: () => handleSelectPage(), data: "Select Page" },
    { onClick: () => setSelected([]), data: "Remove Selected" },
  ];

  function handleAudienceFilterChange(val) {
    setChanged(true);
    setAudienceState({
      ...audienceState,
      filters: val,
    });
  }

  useEffect(() => {
    contactsQuery.remove();
    contactsQuery.refetch();
  }, [audienceState, chosenFilters]);

  useEffect(() => {
    if (changed) {
      saveDynamicChanges();
    }
  }, [audienceState]);

  const navigate = useNavigate();

  const [removing, setRemoving] = useState(false);

  function handleRemoveSelectedFromAudience() {
    if (selected.length > 0) {
      setRemoving(true);
      const contactIds = selected.map((c) => {
        return c?.id;
      });

      removeContacts.mutate(
        {
          id: audience?.id,
          contactArray: {
            contactIds: contactIds,
          },
        },
        {
          onSuccess: () => {
            handleSearch("");
            setSelected([]);
            setRemoving(false);
          },
        }
      );
    }
  }

  function onSaveColumnOrder(columns) {
    const final = JSON.stringify(columns);

    updateAudience.mutate(
      {
        data: {
          columnOrder: final,
        },
        id: audience?.id,
      },
      {
        onSuccess: (data) => {
          setChanged(false);
          setAudienceState({
            ...audienceState,
            updatedAt: data?.audience?.updatedAt,
          });
        },
      }
    );
  }

  function onRemoveContact(contact) {
    setRemoving(true);

    removeContacts.mutate(
      {
        id: audience?.id,
        contactArray: {
          contactIds: [contact?.id],
        },
      },
      {
        onSuccess: () => {
          setRemoving(false);
          contactsQuery.refetch();
        },
      }
    );
  }

  function onAddNewContact(contact) {
    setAddContact(false);
    if (audience.id) {
      addNewContact.mutate(
        {
          contactId: contact.id,
          audienceIds: {
            ids: [audience?.id],
          },
        },
        {
          onSuccess: () => {
            contactsQuery.refetch();
          },
        }
      );
    }
  }

  const timeRef = useRef(0);

  function editSearch(val) {
    setSearchString(val);
    if (timeRef.current) clearTimeout(timeRef.current);
    timeRef.current = setTimeout(() => {
      setSearch(val);
      timeRef.current = 0;
    }, 400);
  }

  return (
    <div className={styles.page}>
      {contactsQuery.isError && (
        <ErrorBanner
          error={contactsQuery.error}
          message={"Error loading contacts"}
        />
      )}
      {updateAudience.isError && (
        <ErrorBanner
          error={updateAudience.error}
          message={"Error updating audience"}
        />
      )}
      {removeContacts.isError && (
        <ErrorBanner
          error={removeContacts.error}
          message={"Error removing contact from audience"}
        />
      )}
      {addNewContact.isError && (
        <ErrorBanner
          error={addNewContact.error}
          message={"Error adding new contact to audience"}
        />
      )}
      {showContactDetails && (
        <>
          <div
            className={styles.backArrow}
            onClick={() => setShowContactDetails(false)}
          >
            <i className="bi bi-arrow-left"></i>
          </div>
          <ContactProfile
            onClose={() => setShowContactDetails(false)}
            contact={currentContact}
            refetch={contactsQuery.refetch}
          ></ContactProfile>
        </>
      )}
      <HeaderContainer
        path={"Contact Management/Audiences/" + audience?.name}
        user={user}
        onNavigate={(path) =>
          path === "Contact Management"
            ? navigate("/ContactManagement")
            : navigate("/ContactManagement/Audiences")
        }
        organization={organization}
      >
        <FlexRow fit>
          {menuItems.map((item) => (
            <Button
              active={item.id === active}
              onClick={() => setActive(item.id)}
            >
              {item.name}
            </Button>
          ))}
        </FlexRow>
      </HeaderContainer>
      {!upload && (
        <>
          {audience && (
            <>
              {/*Members Page */}
              {active === 0 && !upload && (
                <FlexRow
                  style={{ height: "100%", gap: "0", overflow: "hidden" }}
                >
                  {isVisible && (
                    <div
                      className={`${styles.showFilters} ${
                        isVisible ? styles.enter : styles.exit
                      }`}
                      style={{
                        display: first ? "none" : "",
                        borderRight: isVisible ? "" : "none",
                        width: "100%",
                        height: "100%",
                        maxHeight: "fit-content",
                      }}
                      onAnimationEnd={handleAnimationEnd}
                    >
                      {showFilters && (
                        <FilterSimple
                          filters={
                            audienceState?.filters ? audienceState?.filters : []
                          }
                          setFilters={handleAudienceFilterChange}
                          allColumns={headers}
                          title="Addition Filters"
                          setShow={setShowFilters}
                          left
                        ></FilterSimple>
                      )}
                    </div>
                  )}
                  <FlexCol
                    style={{
                      height: "100%",
                      width: "100%",
                      overflow: "hidden",
                      // border: "2px solid black"
                    }}
                  >
                    {" "}
                    {audienceState?.filters?.length > 0 && (
                      <FlexRow
                        start
                        fit
                        style={{
                          height: "100%",
                          maxHeight: "fit-content",
                          padding: "1rem 1rem",
                          paddingBottom: "0",
                        }}
                      >
                        <span className={styles.label5}>Contacts Added:</span>{" "}
                        {audienceState?.filters?.length > 0 &&
                          audienceState?.filters?.map((filter, i) => (
                            <div
                              className={styles.filter}
                              onClick={() => setShowFilters(true)}
                              key={i}
                            >
                              {filter?.label}:
                              {filter?.properties?.map((prop, i) => (
                                <div className={styles.prop}>
                                  {filter?.operator === 0 && "equals"}
                                  {filter?.operator === 1 && "does not equal"}
                                  {filter?.operator === 2 && "contains "}
                                  {filter?.operator === 3 &&
                                    "does not contain "}{" "}
                                  "{prop?.label ? prop?.label : prop}"
                                  {i != filter?.properties?.length - 1
                                    ? ","
                                    : ""}
                                </div>
                              ))}
                            </div>
                          ))}{" "}
                        <Icon
                          iconName={"plus-circle"}
                          onClick={() => setShowFilters(true)}
                        ></Icon>
                      </FlexRow>
                    )}
                    <ObjectTable
                      data={data}
                      headers={headers}
                      setHeaders={setHeaders}
                      maxPage={
                        count / perPage < 1 ? 1 : Math.ceil(count / perPage)
                      }
                      maxItems={count}
                      pageNum={pageNumber}
                      setPageNum={handlePageNum}
                      perPage={perPage}
                      setPerPage={setPerPage}
                      sort={sort}
                      setSort={setSort}
                      pageSkip={pageSkip}
                      setPageSkip={setPageSkip}
                      customFields={customFields}
                      user={user}
                      roles={roles}
                      searchString={searchString}
                      setSearchString={editSearch}
                      onSearch={handleSearch}
                      searchPlaceholder={"Search Contacts..."}
                      emptyMessage={"No Contacts"}
                      addOptions={!preview ? newOptions : undefined}
                      addOptionsHeight={100}
                      filters={chosenFilters}
                      setFilters={handleFilterChange}
                      loading={contactsQuery?.isLoading}
                      fullLoading={
                        !contactsQuery?.isLoading &&
                        contactsQuery?.isFetchingNextPage
                      }
                      // select
                      // selected={selected}
                      // setSelected={setSelected}
                      selectedOptions={selectedOptions}
                      selectedOptionsHeight={50}
                      allColumns={customFields}
                      columnOptions={columnOptions}
                      columnOptionsHeight={50}
                      onClickEditAction={(contact) => {
                        setCurrentContact(contact);
                        setShowContactDetails(true);
                      }}
                      onClickDeleteAction={onRemoveContact}
                      actionCell
                      exportClick={
                        roles?.canSeeContactInfo
                          ? () => setShowExport(true)
                          : undefined
                      }
                      bottomButton={
                        <div
                          className={styles.link}
                          onClick={() => {
                            navigate("/previous-imports");
                          }}
                        >
                          View Import Jobs
                        </div>
                      }
                      onCreateAudienceClick={() => setCreateAudience(true)}
                      refetch={() => {
                        contactsQuery.remove();
                        contactsQuery.refetch();
                      }}
                      setDisplayColumns={setDisplayColumns}
                    ></ObjectTable>
                  </FlexCol>
                </FlexRow>
              )}
              {active === 1 && (
                <AudienceSettings
                  audience={audience}
                  setSettings={setSettings}
                ></AudienceSettings>
              )}
              {active === 2 && (
                <Uploads
                  audience={audience}
                  upload={upload}
                  setUpload={setUpload}
                ></Uploads>
              )}
            </>
          )}

          {adjustFields && (
            <FieldAdjuster
              show={adjustFields}
              setShow={setAdjustFields}
              salesforce={organization?.linkedAccounts?.find(
                (a) => a?.accountType === "Salesforce"
              )}
            ></FieldAdjuster>
          )}

          {displayColumns && (
            <ColumnsToDisplay
              show={displayColumns}
              setShow={setDisplayColumns}
              columns={headers}
              setColumns={handleSetHeaders}
              onSaveColumnOrder={onSaveColumnOrder}
              audience
            />
          )}

          {importer && (
            <Modal show={importer} dark modalStyle={{ width: "1000px" }}>
              <ImportHow
                cancel={() => setImporter(false)}
                user={user}
                organization={organization}
                objectColumns={customFields?.map((v) => {
                  return { ...v, value: v?.name, label: v?.displayName };
                })}
                audienceId={audience?.id}
              ></ImportHow>
            </Modal>
          )}

          {newColumn && (
            <EditSingleCustomField
              onClose={() => setNewColumn(false)}
              field={{}}
              // onSaved={handleSaved}
              brandNew={true}
              // noModal
              // deleteField={handleDeleteField}
            />
          )}
          {addContact && (
            <AddContact
              onClose={() => setAddContact(false)}
              onSave={onAddNewContact}
            />
          )}

          {showExport && (
            <Export
              onClose={() => setShowExport(false)}
              org={organization}
              filters={chosenFilters}
              search={searchString}
              sort={sort}
              headers={headers}
              onExport={onExport}
              audienceId={audienceState?.id}
              audienceFilters={audienceState?.filters}
              audienceName={audienceState.name}
            />
          )}

          {searchToAdd && (
            <SearchToAdd
              onClose={() => setSearchToAdd(false)}
              audienceId={audienceState.id}
              audienceName={audienceState.name}
              onAdded={contactsQuery.refetch}
            />
          )}
        </>
      )}
      {upload && (
        <UnifinishedUpload
          upload={upload}
          setUpload={setUpload}
          audience={audience}
        />
      )}
    </div>
  );
}

function Uploads({ audience, upload, setUpload }) {
  const getUploads = useFetchUploadByAudience(audience?.id);

  return (
    <>
      {getUploads.isError && (
        <ErrorBanner
          error={getUploads.error}
          message={"Error loading uploads"}
        />
      )}
      <div className={styles.uploads}>
        {getUploads.isSuccess &&
          getUploads?.data?.upload?.length > 0 &&
          !upload && (
            <div className={styles.recentUploads}>
              <h2>Previous uploads</h2>
              {getUploads?.data?.upload?.map((upload, i) => (
                <div
                  key={i}
                  className={styles.upload}
                  onClick={() => {
                    setUpload(upload);
                  }}
                >
                  {trimDate(upload?.uploadDate, true)}
                  <div className={styles.view}>View</div>
                </div>
              ))}
            </div>
          )}
        {getUploads.isSuccess && getUploads?.data?.upload?.length === 0 && (
          <div
            className={styles.recentUploads}
            style={{ paddingBottom: "1em" }}
          >
            {" "}
            <span>
              No Upload Attempts
              <i className="bi-x-lg" onClick={() => setViewPrev(false)}></i>
            </span>
          </div>
        )}
      </div>
    </>
  );
}
