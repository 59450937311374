import styles from "./Upload.module.scss";
import {
  useCreateImage,
  useGetOrgImagesGql,
  useGetSignedURL,
} from "api/resources/projects/images";
import { useState } from "react";
import axios from "axios";
import { ErrorBanner } from "pages/error_page/ErrorPage";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Button from "components/Button/Button";

export function Upload({ onImagePick, onCancel }) {
  const fetchImages = useGetOrgImagesGql();
  const getSignedURL = useGetSignedURL();
  const createImage = useCreateImage();

  const [creating, setCreating] = useState(false);

  const [image, setImage] = useState("");
  const [file, setFile] = useState("");
  const [seeAll, setSeeAll] = useState(false);

  const handleChange = (event) => {
    let selectedFile = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
      setFile(selectedFile);
    }
  };

  function onUpload() {
    let img = document.getElementById("uploadedImage");
    let dim = getSignedURL.mutate(
      {
        filename: file.name,
        filetype: file.type,
      },
      {
        onSuccess: async (data) => {
          await uploadToS3(file, data.getSignedURL.signedRequest);
          createImage.mutate(
            {
              url: data.getSignedURL.url,
              name: file.name,
            },
            {
              onSuccess: async (data) => {
                console.log("image created!");
                onImagePick(
                  data.createImage,
                  img?.clientWidth,
                  img?.clientHeight
                );
              },
            }
          );
        },
      }
    );
    setCreating(true);
  }

  async function uploadToS3(file, signedRequest) {
    const options = {
      headers: {
        "Content-Type": file.type,
      },
    };
    const instance = axios.create({
      baseURL: "https://reaction.health/",
      headers: { "Access-Control-Allow-Origin": "*" },
    });
    instance.defaults.withCredentials = true;
    let res = await instance.put(signedRequest, file, options);
  }

  return (
    <>
      {getSignedURL.isError && (
        <ErrorBanner
          error={getSignedURL.error}
          message="Error creating image"
        />
      )}
      {fetchImages.isError && (
        <ErrorBanner
          error={fetchImages.error}
          message="Error pulling up images"
        />
      )}
      {createImage.isError && (
        <ErrorBanner error={createImage.error} message="Error creating image" />
      )}
      <div className={styles.imageImporter} id="image selection container">
        <div className={styles.chooseTitle}>Choose Image</div>
        {image && (
          <div className={styles.chosenImage}>
            <img
              style={{ maxHeight: "60px", maxWidth: "60px" }}
              src={image}
              className={styles.uploadedImage}
              id="uploadedImage"
            ></img>
          </div>
        )}
        <div className={styles.imageUpload}>
          <input
            type="file"
            accept="image/*"
            onChange={handleChange}
            style={{ display: "none" }}
            id="imageInput"
          />
          <label htmlFor="imageInput">
            <div className={styles.upload}>
              {image ? "Choose Another" : "Upload Image"}{" "}
              <i className="bi bi-upload"></i>
            </div>
          </label>

          {image && (
            <div
              className={`${styles.upload} ${styles.use}`}
              onClick={onUpload}
            >
              {creating ? (
                <>Creating...</>
              ) : (
                <>
                  Confirm <i className="bi bi-check-lg"></i>
                </>
              )}
            </div>
          )}
        </div>
        <div className={styles.past}>Org images</div>
        <div className={styles.imageCatalog}>
          {fetchImages.isSuccess && (
            <>
              {(seeAll
                ? fetchImages.data.imagesByOrgId
                : fetchImages.data.imagesByOrgId.slice(0, 9)
              ).map((img) => (
                <div
                  className={styles.catalogPick}
                  onClick={() => onImagePick(img)}
                >
                  <img
                    src={img.imageURL}
                    style={{ maxHeight: "60px", maxWidth: "60px" }}
                    id={img.id}
                  ></img>
                </div>
              ))}
              {!seeAll && fetchImages.data.imagesByOrgId.length > 9 && (
                <div
                  className={styles.seeOthers}
                  onClick={() => setSeeAll(true)}
                >
                  See all...
                </div>
              )}
            </>
          )}
        </div>

        {onCancel && (
          <FlexRow justify={"center"} style={{ paddingTop: "10px" }}>
            <Button shadow style={{ gap: "10px" }} onClick={onCancel}>
              <i className={"bi-x"}></i> Cancel
            </Button>
          </FlexRow>
        )}
      </div>
    </>
  );
}
