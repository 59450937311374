import React from "react";
import styles from "./Breadcrumb.module.scss";
import Button from "components/Button/Button";

export default function Breadcrumb({ path, onNavigate }) {
  const parts = path.split("/").filter(Boolean);

  return (
    <nav className={styles.breadcrumb}>
      {parts.map((part, index) => {
        const subPath = parts.slice(0, index + 1).join("/");
        const isLast = index === parts.length - 1;

        return (
          <span key={subPath} className={styles.breadcrumbItem}>
            {!isLast ? (
              <Button onClick={() => onNavigate(subPath)} style={{fontSize: "1rem"}}>
                {part}
              </Button>
            ) : (
              <Button className={styles.current} style={{fontSize: "1rem"}}>{part}</Button>
            )}
            {!isLast && <span className={styles.separator}> / </span>}
          </span>
        );
      })}
    </nav>
  );
}