import React, { useEffect, useRef } from "react";

import Button from "components/Button/Button";

// import CloseIcon from "../CloseIcon";
import styles from "./ReactModal.module.scss";

/**
 * A modal that is displayed on top of everything else in the page
 * @param {Function} modalStyle something
 * @param children anything inside the <ReactModal></ReactModal> will be displayed with the modal is shown/activated
 * @param {Boolean} show if this is true the modal will show
 * @param {Function} onClose a function that is called when the button is clicked. Button only shows if function is given as a parameter
 * @param {Function} onSave a function that is called when the button is clicked. Button only shows if function is given as a parameter
 * @param {String} backdropStyle something
 * @param {String} saveID something
 * @returns {React.ReactElement} a ReactModal
 */

const Modal = ({
  modalStyle,
  children,
  show,
  onClose,
  onSave,
  backdropStyle,
  saveID,
  rightStyle,
  dark,
  ref,
  height, // Will do the grow animation to match it if defined, else just opacity animation
  hideX,
}) => {
  return (
    <>
      {show && (
        <ShowModal
          children={children}
          modalStyle={modalStyle}
          onClose={onClose}
          onSave={onSave}
          backdropStyle={backdropStyle}
          saveID={saveID}
          rightStyle={rightStyle}
          dark={dark}
          ref={ref}
          height={height}
          hideX={hideX}
        />
      )}
    </>
  );
};

function ShowModal({
  modalStyle,
  children,
  onClose,
  onSave,
  backdropStyle,
  saveID,
  dark,
  rightStyle,
  ref,
  height,
  hideX,
}) {
  // const modalRef = useRef(null);

  // function handleClickOutside(event) {
  //   if (modalRef?.current && event.target === modalRef.current) {
  //     document.removeEventListener("click", handleClickOutside, true);
  //     if (onClose) {
  //       onClose();
  //     }
  //   }
  // }

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside, true);
  //   };
  // }, []);

  return (
    <React.Fragment ref={ref}>
      <div
        style={backdropStyle}
        className={`${styles.modal} ${styles.visible} ${
          rightStyle && styles.rightAlign
        } ${dark && styles.dark}`}
        onMouseDown={() => {
          if (onClose) {
            onClose();
          }
        }}
      >
        <div
          style={{
            height: height ? height : undefined,
            ...(modalStyle ? modalStyle : {}),
          }}
          className={`${styles.modal__wrap} ${
            rightStyle && styles.modal_fixed
          } ${height ? styles.modal_grow : ""}`}
          onMouseDown={(e) => e.stopPropagation()}
        >
          {onClose && !hideX && (
            <div className={styles.buttonClose} onClick={onClose}>
              <Button>
                <i className={`bi bi-x-lg `}></i>
              </Button>
            </div>
          )}
          {children}
          {onSave && (
            <div className={styles.buttons}>
              <div className={styles.buttonsave} id={saveID}>
                <Button onClick={onSave}>Save</Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Modal;
