import Modal from "components/ReactModal/ReactModal";
import styles from "./Preview.module.scss";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { Loading } from "components/Loading/Loading";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { usePreviewAlert } from "api/resources/projects/alerts";
import { Header } from "components/layouts";
import FlexRow from "components/layouts/FlexRow/FlexRow";

export function Preview({ alert, onClose }) {
  const [content, setContent] = useState(<Loading />);
  const [broken, setBroken] = useState(false);

  const fetchPreview = usePreviewAlert(alert.id);

  function onSuccess(data) {
    let html = data.html;

    if (html) {
      setContent(parse(html));
      if (broken) setBroken(false);
    } else {
      setContent("");
      setBroken(true);
    }
  }

  useEffect(() => {
    fetchPreview.mutate(
      {
        id: alert.id,
      },
      {
        onSuccess: onSuccess,
        onError: () => {
          setContent("");
          setBroken(true);
        },
      }
    );
  }, []);

  return (
    <>
      <Modal
        show
        onClose={onClose}
        modalStyle={{
          // width: "100%",
          // maxWidth: "600px",
          borderRadius: "1rem",
        }}
        dark
      >
        <FlexCol>
          <Header>
            <h3>Preview</h3>
          </Header>
          <FlexRow className={styles.backdrop}>
            <FlexCol className={styles.preview}>
              <div className={styles.meta}>
                <div className={styles.label}>From:</div>{" "}
                <div className={styles.black}>{alert.from}</div>
              </div>

              <div className={styles.meta}>
                <div className={styles.label}>Subject:</div>{" "}
                <div className={styles.black}>{alert.subject}</div>
              </div>
              <div className={styles.email}>
                {broken ? (
                  <div className={styles.noData}>
                    Would not send this email. Something's incomplete.
                  </div>
                ) : (
                  <>{content}</>
                )}
              </div>
            </FlexCol>
          </FlexRow>
        </FlexCol>
      </Modal>
    </>
  );
}
