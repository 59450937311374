// External
import React, { useEffect, useRef, useState } from "react";

// Internal
import styles from "./SelectFieldCustom.module.scss";
import { Label } from "components/layouts/Label/Label";
import Icon from "components/Icon/Icon";
import Button from "components/Button/Button";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

export const SelectFieldCustom = ({
  options,
  value,
  icon,
  values,
  selectMultiple,
  searchable,
  onChange,
  newOptionText,
  onNewOptionClick,
  placeholder,
  label,
  disable,
  containerStyle,
  itemStyle,
  onItemClick,
  searchString,
  setSearchString,
  className,
  style,
  dropdownStyle,
  onClick,
  menuPortalTarget,
  outside,
  formatOptionLabel,
  create,
  onCreateOption,
  onSave,
  saveLabel,
  noCreate,
  top,
}) => {
  // const [show, setShow] = useState(false);
  // const [search, setSearch] = useState(value?.label ? value?.label : "");
  // const [chosenValues, setChosenValues] = useState(values ? values : []);
  // const listRef = useRef(null);
  // const ref = useRef(null);
  // const searchRef = useRef(null);

  // useEffect(() => {
  //   if (setSearchString) {
  //     setSearchString(search);
  //   }
  // }, [search]);

  // useEffect(() => {
  //   if (selectMultiple && searchable && searchRef.current) {
  //     const currentDiv = searchRef.current;
  //     // Add the 'input' event listener for content changes
  //     currentDiv.addEventListener("input", searchOptions);

  //     return () => {
  //       // Cleanup the event listener on unmount
  //       currentDiv.removeEventListener("input", searchOptions);
  //     };
  //   }
  // }, []);

  // const [topOfList, setTopOfList] = useState();
  // // options && options?.length > 0
  // //   ? options?.length > 100
  // //     ? options?.slice(0, 100)
  // //     : options
  // //   : []

  // useEffect(() => {
  //   setTopOfList(
  //     options && options?.length > 0
  //       ? options?.length > 100
  //         ? options?.slice(0, 100)
  //         : options
  //       : []
  //   );
  // }, [options]);

  // function onScroll(e) {
  //   if (topOfList.length < options.length && !search) {
  //     var scrollableContainer = e.target;

  //     let distanceToBottom =
  //       scrollableContainer.scrollHeight -
  //       (scrollableContainer.scrollTop + scrollableContainer.clientHeight);

  //     if (distanceToBottom <= 50) {
  //       let nextlength = topOfList.length + 100;
  //       setTopOfList(options.slice(0, nextlength));
  //     }
  //   }
  // }

  // function handleSearch(userInput) {
  //   let results = [];
  //   if (userInput || userInput === "") {
  //     options?.map((option) => {
  //       if (
  //         typeof option === "string" &&
  //         option?.toLowerCase()?.includes(userInput?.toLowerCase())
  //       ) {
  //         results.push(option);
  //       } else if (
  //         typeof option != "string" &&
  //         typeof option?.value === "string" &&
  //         option.value?.toLowerCase()?.includes(userInput?.toLowerCase())
  //       ) {
  //         results.push(option);
  //       } else if (
  //         typeof option != "string" &&
  //         typeof option?.label === "string" &&
  //         option.label?.toLowerCase()?.includes(userInput?.toLowerCase())
  //       ) {
  //         results.push(option);
  //       }
  //     });
  //   } else {
  //     results = options;
  //   }
  //   setTopOfList(results ? results.slice(0, 100) : []);
  // }

  // const handleClickOutside = (event) => {
  //   if (
  //     ref.current &&
  //     listRef.current &&
  //     !ref.current.contains(event.target) &&
  //     !listRef.current.contains(event.target)
  //   ) {
  //     setShow(false);
  //     if (selectMultiple) {
  //       setSearch("");
  //     } else {
  //       setSearch(value?.label);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside, true);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (values) {
  //     setChosenValues(values);
  //   }
  // }, [values]);

  // const handleChange = (option) => {
  //   // debugger;
  //   if (!selectMultiple) {
  //     if (!value || option.value !== value.value) {
  //       //Checking value.value, but displaying just 'value' below
  //       setSearch(option?.label);
  //       if (onChange) {
  //         onChange(option);
  //       }
  //     }
  //   } else {
  //     setSearch("");
  //     if (searchRef.current) {
  //       searchRef.current.innerText = "";
  //     }
  //     let newList = [...chosenValues];
  //     newList.push(option);
  //     setChosenValues(newList);
  //     if (onChange) {
  //       onChange(newList);
  //     }
  //     setTopOfList(
  //       options && options?.length > 0
  //         ? options?.length > 100
  //           ? options?.slice(0, 100)
  //           : options
  //         : []
  //     );
  //   }
  //   setShow(false);
  // };

  // function searchOptions() {
  //   //this will be replaced with a search to the database  i.e. searchFunction
  //   setSearch(searchRef.current.innerText);
  //   handleSearch(searchRef.current.innerText);
  // }

  // function searchValue(val) {
  //   //this will be replaced with a search to the database  i.e. searchFunction
  //   setSearch(val);
  //   handleSearch(val);
  // }

  // function handleRemoveOption(item, e) {
  //   //remove chosen option
  //   e.stopPropagation();

  //   let newValues = [...chosenValues];
  //   let index = newValues.indexOf(item);
  //   newValues.splice(index, 1);
  //   if (onChange) {
  //     onChange(newValues, true);
  //   }
  //   setChosenValues(newValues);
  // }

  // function handleOnSearchClick(e) {
  //   if (!search || !show) {
  //     setShow(true);
  //     setSearch(true);
  //   }
  // }

  // function commaList(array) {
  //   if (array && array?.length && array?.length > 0) {
  //     return array?.map((item, i) => (
  //       <div key={i} className={styles.chosenOption} style={itemStyle}>
  //         <span onClick={onItemClick ? () => onItemClick(item) : undefined}>
  //           {item?.label}{" "}
  //         </span>
  //         <span
  //           className={`bi bi-x ${styles.close}`}
  //           onClick={(e) => handleRemoveOption(item, e)}
  //         ></span>
  //       </div>
  //     ));
  //   }
  // }

  // function getHeight(num) {
  //   if (noCreate) {
  //     if (
  //       search &&
  //       !topOfList?.find((o) => o?.value === search || o?.label === search) &&
  //       num <= 6
  //     ) {
  //       return num * 2.46 + "rem";
  //     }
  //     if (num <= 6) {
  //       return num * 2.46 + "rem";
  //     } else {
  //       return "";
  //     }
  //   }
  //   if (
  //     search &&
  //     !topOfList?.find((o) => o?.value === search || o?.label === search) &&
  //     num <= 6
  //   ) {
  //     return num * 2.46 + 2.46 + "rem";
  //   }
  //   if (num <= 6) {
  //     return num * 2.46 + "rem";
  //   } else {
  //     return "";
  //   }
  // }

  // function handleKeyDown(e) {
  //   if (e.key === "Enter" || e.keyCode === 13) {
  //     e.preventDefault();
  //     if (!noCreate && search) {
  //       handleChange({ value: search, label: search });
  //     }
  //   }
  // }

  return (
    <div
      className={styles.selectContainer}
      onClick={onClick ? onClick : undefined}
      style={style}
    >
      {label && <div className={styles.label6}>{label}</div>}
      <div
        style={{
          position: "absolute",
          left: ".75rem",
          bottom: ".5rem",
          zIndex: "1",
        }}
      >
        {icon}
      </div>
      {!create && (
        <Select
          className={className ? className : "audienceSelect"} // Apply SCSS module class
          classNamePrefix={className ? className : "audienceSelect"} // Ensure correct className mapping
          value={selectMultiple ? values : value}
          options={options}
          onChange={onChange}
          styles={{
            control: (base) => ({
              ...base,
              paddingLeft: icon ? "1.75rem" : "",
              fontSize: '.8rem',
            }),
            menuPortal: (base) => ({
              ...base,
              zIndex: 2,
              fontSize: '.8rem',
            }),
          }}
          isDisabled={disable}
          menuPortalTarget={outside ? document.body : menuPortalTarget}
          isMulti={selectMultiple}
          placeholder={placeholder}
          formatOptionLabel={formatOptionLabel}
          menuPlacement={top ? "top" : undefined}
        />
      )}
      {create && (
        <CreatableSelect
          className={className ? className : "audienceSelect"} // Apply SCSS module class
          classNamePrefix={className ? className : "audienceSelect"} // Ensure correct className mapping
          value={selectMultiple ? values : value}
          options={options}
          onChange={onChange}
          styles={{
            control: (base) => ({
              ...base,
              paddingLeft: icon ? "1.75rem" : "",
            }),
            menuPortal: (base) => ({
              ...base,
              zIndex: 2,
            }),
          }}
          isDisabled={disable}
          menuPortalTarget={outside ? document.body : menuPortalTarget}
          isMulti={selectMultiple}
          placeholder={placeholder}
          formatOptionLabel={formatOptionLabel}
          onCreateOption={onCreateOption}
          menuPlacement={top ? "top" : undefined}
        />
      )}
    </div>
  );
};
