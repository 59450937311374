/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/
// External
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation } from "api/Api";
import { useQueryClient } from "react-query";

// GRAPHQL API

export const useGetSignedURL = () => {
  const mutation = gql`
    mutation GetSignedURL($filetype: String!, $filename: String!) {
      getSignedURL(filetype: $filetype, filename: $filename) {
        signedRequest
        url
      }
    }
  `;
  return useGqlMutation(mutation, null);
};

export const useGetSignedURLUser = () => {
  const mutation = gql`
    mutation GetSignedURLUser($filetype: String!, $filename: String!) {
      getSignedURLUser(filetype: $filetype, filename: $filename) {
        signedRequest
        url
      }
    }
  `;
  return useGqlMutation(mutation, null);
};

export const useCreateImage = () => {
  const mutation = gql`
    mutation CreateImage($name: String!, $url: String!) {
      createImage(name: $name, url: $url) {
        id
        imageURL
      }
    }
  `;
  return useGqlMutation(mutation, null);
};

export const useDeleteImage = () => {
  const mutation = gql`
    mutation Mutation($filetype: String!, $filename: String!, $id: String!) {
      deleteImageFromS3(filetype: $filetype, filename: $filename, id: $id)
    }
  `;
  return useGqlMutation(mutation, null);
};

export const useUpdateImage = () => {
  const mutation = gql`
    mutation Mutation($data: createImageUpload!, $id: String!) {
      updateImage(data: $data, id: $id)
    }
  `;
  return useGqlMutation(mutation, null);
};

export const useCreateOrgLogo = () => {
  const mutation = gql`
    mutation CreateOrgLogo(
      $about: String!
      $name: String!
      $url: String!
      $isLogo: Boolean!
      $position: String!
      $scale: Float!
      $orgId: String!
    ) {
      createOrgLogo(
        about: $about
        name: $name
        url: $url
        isLogo: $isLogo
        position: $position
        scale: $scale
        orgId: $orgId
      ) {
        imageURL
        id
      }
    }
  `;

  return useGqlMutation(mutation, null);
};

export const useCreateUserLogo = () => {
  const mutation = gql`
    mutation CreateUserImage(
      $about: String!
      $name: String!
      $url: String!
      $userId: String!
      $position: String!
      $scale: Float!
    ) {
      createUserImage(
        about: $about
        name: $name
        url: $url
        userId: $userId
        position: $position
        scale: $scale
      ) {
        id
        imageURL
        scale
        position
      }
    }
  `;

  return useGqlMutation(mutation, null);
};

export const useCreateProjectImage = () => {
  const mutation = gql`
    mutation CreateProjectImage($url: String!, $projectId: String!) {
      createProjectImage(url: $url, id: $projectId) {
        imageURL
        id
        projectId
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: (data) => {
      if (data.createProjectImage.projectId)
        queryClient.invalidateQueries(
          "project image " + data.createProjectImage.projectId
        );
    },
  };

  return useGqlMutation(mutation, options);
};

export const useGetOrgImagesGql = () => {
  const query = gql`
    query ImagesByOrgId {
      imagesByOrgId {
        imageURL
        name
        about
        id
        createdDate
        updatedDate
        isLogo
        position
        scale
      }
    }
  `;

  return useGqlQuery(["Images"], query, {});
};

export const useGetProjectLogo = (id) => {
  const query = gql`
    query {
      getProjectLogo(id: "${id}") {
        imageURL
        id
      }
    }
    `;
  return useGqlQuery(["project image " + id], query, {});
};
