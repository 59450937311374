// External
import React, { useEffect, useMemo, useState } from "react";
import { motion } from "framer-motion";

// Internal
import styles from "./SurveyBuild.module.scss";
import { Loading } from "components/Loading/Loading";
import { useParams } from "react-router-dom";
import { useGetQsAndSectionsForSurveyBuilder } from "api/resources/projects/questions";
import {
  useFetchProjectAndImage,
  useFetchProjectGql,
} from "api/resources/projects/projects";
import { QuestionConstruction } from "components/QuestionConstruction/QuestionConstruction";
import { ErrorBanner } from "pages/error_page/ErrorPage";
import { routeVariants } from "assets/functions/Variables";
import Modal from "components/ReactModal/ReactModal";

export const SurveyBuild = ({ roles, direction, updateRoute, collapse }) => {
  const { id } = useParams();
  const [maxPage, setMaxPage] = useState(0); //maximum page
  const [pages, setPages] = useState([]);

  const [currPage, setCurrPage] = useState(1);
  const [settings, setSettings] = useState();

  const [allQuestions, setAllQuestions] = useState();
  const [questions, setQuestions] = useState();
  const [fullscreen, setFullscreen] = useState(false);

  const fetchQsAndSections = useGetQsAndSectionsForSurveyBuilder();
  const fetchDesignSettings = useFetchProjectAndImage();
  const fetchProjectQuery = useFetchProjectGql(id);
  const [showLoading, setShowLoading] = useState(false);

  useMemo(() => {
    updateRoute(1);
  }, []);

  function setQsAndSections(questions, sections, newPageNum) {
    for (let i = 0; i < sections.length; i++) {
      if (typeof sections[i].sectionSettings === "string") {
        sections[i].sectionSettings = JSON.parse(sections[i].sectionSettings);
      }
      if (
        sections[i]?.conditionGroups &&
        typeof sections[i].conditionGroups === "string"
      ) {
        sections[i].conditionGroups = JSON.parse(sections[i].conditionGroups);
      }
    }

    for (let i = 0; i < questions.length; i++) {
      if (
        questions[i]?.conditionGroups &&
        typeof questions[i].conditionGroups === "string"
      ) {
        questions[i].conditionGroups = JSON.parse(questions[i].conditionGroups);
      }
      // if (questions[i].triggers && typeof questions[i].triggers === "string") {
      //   questions[i].triggers = JSON.parse(questions[i].triggers);
      // }
      for (let j = 0; j < questions[i].alerts.length; j++) {
        if (questions[i].alerts[j].condition) {
          questions[i].alerts[j].condition = JSON.parse(
            questions[i].alerts[j].condition
          );
        }
      }

      if (
        questions[i].matrixQuestion &&
        typeof questions[i].matrixQuestion.settings === "string"
      ) {
        questions[i].matrixQuestion.settings = JSON.parse(
          questions[i].matrixQuestion.settings
        );
      }
    }

    let all = [...questions, ...sections].toSorted((a, b) => {
      if (a.pageNumber === b.pageNumber) {
        return a.pageOrderIndex > b.pageOrderIndex ? 1 : -1;
      } else {
        return a.pageNumber > b.pageNumber ? 1 : -1;
      }
    });

    let pages2 = [];
    for (let i = 0; i < maxPage; i++) {
      pages2.push([]);
    }

    for (let q of all) {
      if (pages2[q?.pageNumber - 1]) {
        pages2[q?.pageNumber - 1].push(q);
      }
    }

    setPages(pages2);

    // let questionsOnly = [...questions].toSorted((a, b) => {
    //   if (a.pageNumber === b.pageNumber) {
    //     return a.pageOrderIndex > b.pageOrderIndex ? 1 : -1;
    //   } else {
    //     return a.pageNumber > b.pageNumber ? 1 : -1;
    //   }
    // });

    setAllQuestions(all);
    // setJustQuestions(questionsOnly);

    if (newPageNum) {
      setCurrPage(newPageNum);
    }
    let currentPage = newPageNum ? newPageNum : currPage;

    let thisPage = [];
    for (let q of all) {
      if (q.pageNumber === currentPage) {
        thisPage.push(q);
      }
    }
    setQuestions(thisPage);
    setShowLoading(false);
    // collapseSide();
  }

  // function collapseSide() {
  //   collapse(true);
  // }

  function fetchDSettings() {
    fetchDesignSettings.mutate(
      {
        id: id,
      },
      {
        onSuccess: (data) => {
          let dSettings = JSON.parse(
            data.getProjectAndImageUrl?.project.designSettings
          );

          setMaxPage(data.getProjectAndImageUrl?.project.numPages);
          setSettings(dSettings);
        },
      }
    );
  }

  // Optional newPageNum to set to once queried
  function fetchQs(newPageNum) {
    fetchQsAndSections.mutate(
      {
        projectId: id,
      },
      {
        onSuccess: (data) => {
          let qs = data.stuff.questions;
          let sections = data.stuff.sections;

          setQsAndSections(qs, sections, newPageNum);
        },
      }
    );
  }

  useEffect(() => {
    fetchQs();
  }, [maxPage]);

  useEffect(() => {
    fetchDSettings();
  }, []);

  function onPageChange(number) {
    setCurrPage(number);
    let thisPage = [];
    for (let q of allQuestions) {
      if (q.pageNumber === number) {
        thisPage.push(q);
      }
    }
    setQuestions(thisPage);
  }

  return (
    <motion.div
      variants={routeVariants}
      custom={{ direction, animate: true }}
      initial="enter"
      animate="center"
      exit="exit"
      className={styles.page}
    >
      {(!questions ||
        !allQuestions ||
        !settings ||
        fetchProjectQuery.isLoading) && <Loading></Loading>}
      {fetchQsAndSections.isError && (
        <ErrorBanner
          error={fetchQsAndSections.error}
          message="Error loading survey questions"
        />
      )}

      {fetchDesignSettings.isError && (
        <ErrorBanner
          error={fetchDesignSettings.error}
          message="Error loading survey settings"
        />
      )}
      {fetchProjectQuery.isError && (
        <ErrorBanner
          error={fetchDesignSettings.error}
          message="Error loading survey"
        />
      )}

      {questions && allQuestions && settings && fetchProjectQuery.isSuccess && (
        <>
          {fullscreen ? (
            <>
              <Modal
                dark
                show={fullscreen}
                modalStyle={{
                  height: "100%",
                  width: "100%",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              >
                <QuestionConstruction
                  initFinishedMessage={
                    fetchProjectQuery.data.survey?.finishedMessage
                  }
                  canEdit={roles.canCreateEditOrgSurveys}
                  maxPage={maxPage}
                  setMaxPage={setMaxPage}
                  designSettings={settings}
                  setDesignSettings={setSettings}
                  status={fetchProjectQuery.data.survey.status}
                  survey={fetchProjectQuery.data.survey}
                  currPage={currPage}
                  setCurrPage={onPageChange}
                  questions={questions}
                  setQuestions={setQuestions}
                  allQuestions={allQuestions}
                  setAllQuestions={setAllQuestions}
                  refetchQs={fetchQs}
                  refetchDSettings={fetchDSettings}
                  showLoading={showLoading}
                  setShowLoading={setShowLoading}
                  projectId={id}
                  setFullscreen={setFullscreen}
                  fullscreen={fullscreen}
                  pages={pages}
                  setPages={setPages}
                  collapse={collapse}
                ></QuestionConstruction>
              </Modal>
            </>
          ) : (
            <>
              <QuestionConstruction
                initFinishedMessage={
                  fetchProjectQuery.data.survey?.finishedMessage
                }
                canEdit={roles.canCreateEditOrgSurveys}
                maxPage={maxPage}
                setMaxPage={setMaxPage}
                designSettings={settings}
                setDesignSettings={setSettings}
                status={fetchProjectQuery.data.survey.status}
                survey={fetchProjectQuery.data.survey}
                currPage={currPage}
                setCurrPage={onPageChange}
                questions={questions}
                setQuestions={setQuestions}
                allQuestions={allQuestions}
                setAllQuestions={setAllQuestions}
                refetchQs={fetchQs}
                refetchDSettings={fetchDSettings}
                showLoading={showLoading}
                setShowLoading={setShowLoading}
                projectId={id}
                setFullscreen={setFullscreen}
                fullscreen={fullscreen}
                pages={pages}
                setPages={setPages}
                collapse={collapse}
              ></QuestionConstruction>
            </>
          )}
        </>
      )}
    </motion.div>
  );
};
