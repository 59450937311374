import React, { useRef, useState } from "react";

import styles from "./NavTextField.module.scss";

/**
 * An auto saveable textField
 * @param {String} value value that is passed in as initial value and/or changeable value for the search text field
 *
 *
 *
 *
 * @returns {React.ReactElement} a numberscale clickable component
 */

export const NavTextField = ({
  value,
  setValue,
  onDelayedChange,
  placeholder,
  inputID,
  handlePressEnter,
  handleFocusOut,
  disabled,
  style,
  className,
  shadow,
}) => {
  const [string, setString] = useState(value);

  const timeoutRef = useRef(0);

  const handleChange = (event) => {
    if (setValue) {
      setValue(event.target.value);
    }

    if (onDelayedChange) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        onDelayedChange(event.target.value);
        timeoutRef.current = 0;
      }, 350);
    }

    setString(event.target.value);
  };

  const handleEnter = (event) => {
    // handleFocusOut(event);
    if (event.key === "Enter") {
      if (handlePressEnter) {
        handlePressEnter(string);
      }
    }
  };

  return (
    <div
      className={`${styles.searchField} ${className} ${
        shadow && styles.shadow
      }`}
    >
      <i className="bi bi-search"></i>
      <input
        className={styles.textField}
        style={style}
        type="text"
        value={string}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleFocusOut}
        disabled={disabled}
        id={inputID}
        onKeyDown={handleEnter}
      />
    </div>
  );
};
