import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation } from "api/Api";

// GRAPHQL API

/**
 * Query to fetch user details by ID.
 *
 * This function uses the `user` GraphQL query to retrieve detailed information about a user,
 * including their attributes, linked accounts, and associated image data.
 *
 * @function useFetchUser
 * @param {String} id - The unique identifier of the user to fetch.
 * @returns {Object} - Returns the result of the query, including data, error, and loading state.
 *
 * @example
 * const { data, error, isLoading } = useFetchUser("userId123");
 * if (data) {
 *   console.log("User Details:", data.user);
 * }
 */
export const useFetchUser = (id) => {
  const query = gql`
    query {
      user(id: "${id}") {
        id
        firstName
        lastName
        email
        customField
        requireMFA
        createdAt
        updatedAt
        image {
          id
          name
          about
          imageURL
          position
          scale
        }
        activeDistConfigId
        linkedAccounts {
          id
          accountType
        }
        attribute {
          id
          customFieldId
          name
        }
      }
    }
  `;

  return useGqlQuery(["fetchUser" + id], query, {});
};

/**
 * Query to fetch user activity.
 *
 * This function uses the `getUserActivity` GraphQL query to retrieve a user's activity log
 * based on search criteria, pagination, sorting, and organization ID.
 *
 * @function useFetchUserActivity
 * @param {String} searchString - The search term to filter activities.
 * @param {Number} page - The current page number for pagination.
 * @param {Number} perPage - The number of activities to return per page.
 * @param {Object} sort - Sorting criteria for activities (e.g., field and order).
 * @param {String} id - The user ID whose activities are being fetched.
 * @param {String} orgId - The organization ID associated with the activities.
 * @param {String} one - An additional parameter to distinguish queries (if needed).
 * @returns {Object} - Returns the result of the query, including `activities` and `count`.
 *
 * @example
 * const { data, error, isLoading } = useFetchUserActivity(
 *   "login",
 *   1,
 *   10,
 *   { field: "createdAt", order: "DESC" },
 *   "userId123",
 *   "orgId456",
 *   "uniqueIdentifier"
 * );
 * if (data) {
 *   console.log("User Activities:", data.object.activities);
 *   console.log("Total Count:", data.object.count);
 * }
 */
export const useFetchUserActivity = (
  searchString,
  page,
  perPage,
  sort,
  id,
  orgId,
  one
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortUser!
      $id: String!
      $orgId: String!
    ) {
      object: getUserActivity(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
        id: $id
        orgId: $orgId
      ) {
        activities {
          id
          message
          createdAt
        }
        count
      }
    }
  `;

  return useGqlQuery(
    ["getActivity:" + id + ":" + one + ":" + orgId, searchString],
    query,
    {
      search: searchString,
      page: page,
      perPage: perPage,
      sort: sort,
      id: id,
      orgId: orgId,
    }
  );
};

/**
 * Query to fetch all users.
 *
 * This function uses the `allUsers` GraphQL query to retrieve a list of users based on a
 * search string. It returns basic user details such as ID, name, email, and profile image.
 *
 * @function useFetchAllUsers
 * @param {String} searchString - The search term to filter users.
 * @returns {Object} - Returns the result of the query, including `allUsers` data, error, and loading state.
 *
 * @example
 * const { data, error, isLoading } = useFetchAllUsers("John");
 * if (data) {
 *   console.log("All Users:", data.allUsers);
 * }
 */
export const useFetchAllUsers = (searchString) => {
  const query = gql`
    query Query($search: String!) {
      allUsers(search: $search) {
        id
        firstName
        lastName
        email
        image {
          imageURL
        }
      }
    }
  `;
  return useGqlQuery(["allusers"], query, {
    search: searchString,
  });
};

/**
 * Query to fetch users available for sharing a report.
 *
 * This function uses the `usersForShare` GraphQL query to retrieve a list of users or roles
 * that can be shared with a specific report, based on a search term.
 *
 * @function useFetchUsersShare
 * @param {String} reportId - The unique identifier of the report to share.
 * @param {String} searchString - The search term to filter users or roles for sharing.
 * @returns {Object} - Returns the result of the query, including `usersForShare` data, error, and loading state.
 *
 * @example
 * const { data, error, isLoading } = useFetchUsersShare("reportId123", "Admin");
 * if (data) {
 *   console.log("Users for sharing:", data.usersForShare);
 * }
 */
export const useFetchUsersShare = (reportId, searchString) => {
  const query = gql`
    query UsersForShare($reportId: String!, $search: String!) {
      usersForShare(reportId: $reportId, search: $search) {
        label
        value
        isOrg
        isRole
      }
    }
  `;
  return useGqlQuery(["users"], query, {
    reportId: reportId,
    search: searchString,
  });
};

/**
 * Query to fetch the current logged-in user's details.
 *
 * This function uses the `me` GraphQL query to retrieve information about the current user,
 * including their personal details, organization, roles, linked accounts, and preferences.
 *
 * @function useGetCurrentUser
 * @returns {Object} - Returns the result of the query, including `me` data, error, and loading state.
 *
 * @example
 * const { data, error, isLoading } = useGetCurrentUser();
 * if (data) {
 *   console.log("Current User:", data.me);
 * }
 */
export const useGetCurrentUser = (key = "me") => {
  const query = gql`
    query {
      me {
        id
        firstName
        lastName
        email
        prefix
        organizationId
        isSuperAdmin
        customField
        favoriteReports
        dashboardId
        phone
        organization {
          id
          name
          resultAnalysisId
          customFields
          usersAllowed
          adminsAllowed
        }
        roleId
        image {
          id
          imageURL
          position
          scale
          name
          about
        }
        roles {
          id
          name
          description
          organizationId
          color
        }
        linkedAccounts {
          accountType
          id
        }
      }
    }
  `;

  return useGqlQuery([key], query, {});
};

export const useFetchIsSuperAdmin = () => {
  const query = gql`
    query Query {
      response: isUserSuperAdmin
    }
  `;

  return useGqlQuery("isSuperAdmin", query, {});
};

/**
 * Mutation to remove a user from an organization.
 *
 * This function uses the `removeUserFromOrg` GraphQL mutation to remove a user from
 * the specified organization. It also invalidates the "updateUser" query cache after the mutation.
 *
 * @function useRemoveUserFromOrg
 * @returns {Function} - Returns a mutation function to remove a user from an organization.
 *
 * @example
 * const removeUser = useRemoveUserFromOrg();
 * removeUser.mutateAsync({ orgId: "orgId123", userId: "userId456" }).then(() => {
 *   console.log("User successfully removed from organization.");
 * });
 */
export const useRemoveUserFromOrg = () => {
  const mutation = gql`
    mutation Mutation($orgId: String!, $userId: String!) {
      removeUserFromOrg(orgId: $orgId, userId: $userId)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("updateUser");
    },
  };

  return useGqlMutation(mutation, options);
};

/**
 * Query to search for users based on a search term.
 *
 * This function uses the `searchUser` GraphQL query to retrieve a list of users
 * that match the provided search term. It returns basic user details, such as
 * ID, name, prefix, and email.
 *
 * @function useSearchUser
 * @param {String} search - The search term used to filter users.
 * @returns {Object} - Returns the result of the query, including `searchUser` data, error, and loading state.
 *
 * @example
 * const { data, error, isLoading } = useSearchUser("John");
 * if (data) {
 *   console.log("Matching Users:", data.searchUser);
 * }
 */
export const useSearchUser = (search) => {
  const query = gql`
    query Query($search: String!) {
      searchUser(search: $search) {
        id
        firstName
        lastName
        prefix
        email
        phone
      }
    }
  `;

  return useGqlQuery(["userSearch " + search], query, { search: search });
};

/**
 * Query to search for users with pagination and sorting.
 *
 * This function uses the `searchUsers` GraphQL query to retrieve a paginated list of users
 * based on a search term, sorting criteria, and the specified page and perPage values.
 *
 * @function useSearchUsersPages
 * @param {String} searchString - The search term used to filter users.
 * @param {Number} page - The current page number for pagination.
 * @param {Number} perPage - The number of users to return per page.
 * @param {Object} sort - Sorting criteria, specifying the field and order (e.g., `item` and `descend`).
 * @returns {Object} - Returns the result of the query, including `users` data, error, and loading state.
 *
 * @example
 * const { data, error, isLoading } = useSearchUsersPages("John", 1, 10, { item: "firstName", descend: false });
 * if (data) {
 *   console.log("Paginated Users:", data.users);
 * }
 */
export const useSearchUsersPages = (searchString, page, perPage, sort) => {
  const query = gql`
    query SearchUsers(
      $sort: sortUser!
      $perPage: Float!
      $page: Float!
      $search: String!
    ) {
      users: searchUsers(
        sort: $sort
        perPage: $perPage
        page: $page
        search: $search
      ) {
        firstName
        lastName
        email
        id
        customField
        dashboardId
        image {
          id
          imageURL
          position
          scale
        }
        roles {
          organizationId
          id
          name
        }
      }
    }
  `;

  return useGqlQuery([`search ${perPage} users: ` + searchString], query, {
    search: searchString,
    page: page,
    perPage: perPage,
    sort: sort,
  });
};

export const useFetchEmailAddresses = () => {
  const query = gql`
    query Query {
      emails: orgEmails
    }
  `;

  return useGqlQuery(["org emails"], query, {});
};

/**
 * Query to fetch users by organization ID with pagination and sorting.
 *
 * This function uses the `userByOrgId` GraphQL query to retrieve a paginated list of users
 * within a specific organization based on a search term, sorting criteria, and pagination parameters.
 * It also provides additional statistics about the organization’s users.
 *
 * @function useFetchUsersByOrgId
 * @param {String} searchString - The search term used to filter users.
 * @param {Number} page - The current page number for pagination.
 * @param {Number} perPage - The number of users to return per page.
 * @param {Object} sort - Sorting criteria, specifying the field and order (e.g., `item` and `descend`).
 * @param {String} orgId - The ID of the organization to fetch users for.
 * @returns {Object} - Returns the result of the query, including `users` data, `count`, and additional organization statistics.
 *
 * @example
 * const { data, error, isLoading } = useFetchUsersByOrgId("John", 1, 10, { item: "lastName", descend: true }, "org123");
 * if (data) {
 *   console.log("Users:", data.users.users);
 *   console.log("Total Users:", data.users.count);
 *   console.log("Admins:", data.users.admins);
 * }
 */
export const useFetchUsersByOrgId = (
  searchString,
  page,
  perPage,
  sort,
  orgId
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortUser!
      $orgId: String!
    ) {
      users: userByOrgId(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
        orgId: $orgId
      ) {
        users {
          firstName
          lastName
          email
          id
          customField
          dashboardId
          position
          source
          expired
          roles {
            id
            name
            description
            organizationId
            color
          }
          image {
            id
            imageURL
            position
            scale
          }
          createdAt
          updatedAt
        }
        count
        loggedIn
        loggedOut
        admins
        userCount
      }
    }
  `;

  return useGqlQuery(["usersbyorgids", searchString], query, {
    search: searchString,
    page: page,
    perPage: perPage,
    sort: sort,
    orgId: orgId,
  });
};
//supporting count for the above function
export const useFetchUsersByOrgIdCount = (
  searchString,
  page,
  perPage,
  sort,
  orgId
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortUser!
      $orgId: String!
    ) {
      count: userByOrgIdCount(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
        orgId: $orgId
      )
    }
  `;

  return useGqlQuery(["usersbyorgCount", searchString], query, {
    search: searchString,
    page: page,
    perPage: perPage,
    sort: sort,
    orgId: orgId,
  });
};

/**
 * Query to fetch a user's details by their ID.
 *
 * This function uses the `getUserById` GraphQL query to retrieve details of a specific user
 * based on their unique ID. The returned data includes basic information and MFA-related fields.
 *
 * @function useGetUserById
 * @param {String} id - The unique identifier of the user to fetch.
 * @returns {Object} - Returns the result of the query, including `getUserById` data, error, and loading state.
 *
 * @example
 * const { data, error, isLoading } = useGetUserById("userId123");
 * if (data) {
 *   console.log("User Details:", data.getUserById);
 * }
 */
export const useGetUserById = (id) => {
  const query = gql`
    query GetUserById($userId: String!) {
      getUserById(userId: $userId) {
        id
        firstName
        lastName
        prefix
        email
        verified
        phone
        phoneCode
        MFAVerified
        MFAVerifiedDate
      }
    }
  `;

  return useGqlQuery(["usersName " + id], query, { userId: id });
};

/**
 * Mutation to update current user details.
 *
 * This function uses the `updateUser` GraphQL mutation to update the details of a user.
 * After the mutation is executed, it invalidates the `me` query cache to ensure updated
 * data is reflected.
 *
 * @function useUpdateUser
 * @returns {Function} - Returns a mutation function to update a user's details.
 *
 * @example
 * const updateUser = useUpdateUser();
 * updateUser.mutateAsync({
 *   data: {
 *     firstName: "John",
 *     lastName: "Doe",
 *     email: "john.doe@example.com",
 *   }
 * }).then(() => {
 *   console.log("User updated successfully.");
 * });
 */
export const useUpdateUser = () => {
  const mutation = gql`
    mutation UpdateUser($data: UserInput!) {
      updateUser(data: $data)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("me");
    },
  };

  return useGqlMutation(mutation, options);
};

/**
 * Mutation to update a user's details and attributes by their ID.
 *
 * This function uses the `updateUserById` GraphQL mutation to update a user's basic information
 * and optionally updates their associated attributes. After the mutation is executed, the
 * `updateUser` query cache is invalidated to ensure updated data is reflected.
 *
 * @function useUpdateUserById
 * @returns {Function} - Returns a mutation function to update a user's details by ID.
 *
 * @example
 * const updateUserById = useUpdateUserById();
 * updateUserById.mutateAsync({
 *   data: {
 *     firstName: "John",
 *     lastName: "Doe",
 *     email: "john.doe@example.com",
 *   },
 *   id: "userId123",
 *   attributeData: {
 *     attributes: [
 *       { customFieldId: "fieldId1", name: "Attribute1" },
 *       { customFieldId: "fieldId2", name: "Attribute2" },
 *     ],
 *   },
 * }).then(() => {
 *   console.log("User updated successfully.");
 * });
 */
export const useUpdateUserById = () => {
  const mutation = gql`
    mutation UpdateUser(
      $data: UserInput!
      $id: String!
      $attributeData: AttributeUpdate
    ) {
      updateUserById(data: $data, id: $id, attributeData: $attributeData)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("updateUser");
    },
  };

  return useGqlMutation(mutation, options);
};

/**
 * Mutation to invite a user to the application.
 *
 * This function uses the `InviteUser` GraphQL mutation to send an invitation to a user
 * by their email and assign them a specific role. The response includes a unique invitation code.
 *
 * @function useInviteUser
 * @returns {Function} - Returns a mutation function to invite a user.
 *
 * @example
 * const inviteUser = useInviteUser();
 * inviteUser.mutateAsync({
 *   email: "user@example.com",
 *   roleId: "role123",
 * }).then((response) => {
 *   console.log("Invitation sent with code:", response.InviteUser.code);
 * });
 */
export const useInviteUser = () => {
  const mutation = gql`
    mutation InviteUser($email: String!, $roleId: String!) {
      InviteUser(email: $email, roleId: $roleId) {
        code
      }
    }
  `;
  return useGqlMutation(mutation, {});
};

export const useRevokeInvite = () => {
  const mutation = gql`
    mutation revokeInvite($email: String!) {
      RevokeInvite(email: $email)
    }
  `;
  return useGqlMutation(mutation, {});
};

/**
 * Query to fetch users by shared report ID.
 *
 * This function uses the `userBySharedReport` GraphQL query to retrieve a list of users
 * associated with a specific shared report. The response includes user details and the
 * total user count for the shared report.
 *
 * @function useGetUsersBySharedReport
 * @param {String} reportId - The unique identifier of the shared report.
 * @returns {Object} - Returns the result of the query, including `users` and `userCount`.
 *
 * @example
 * const { data, error, isLoading } = useGetUsersBySharedReport("report123");
 * if (data) {
 *   console.log("Users:", data.response.users);
 *   console.log("Total User Count:", data.response.userCount);
 * }
 */
export const useGetUsersBySharedReport = (reportId) => {
  const query = gql`
    query ($reportId: String!) {
      response: userBySharedReport(reportId: $reportId) {
        users {
          id
          email
          image {
            imageURL
          }
        }
        userCount
      }
    }
  `;

  return useGqlQuery(["usersBySharedReport: " + reportId], query, {
    reportId: reportId,
  });
};
