//External
import React, { useEffect, useRef, useState } from "react";

//Internal
import styles from "./HeaderContainer.module.scss";
// import { useGetCurrentUser } from "api/resources/organization/users";
import Breadcrumb from "../BreadCrumb/Breadcrumb";
import ImageEditor from "components/Image Editor/ImageEditor";
import FlexRow from "../FlexRow/FlexRow";
import Button from "components/Button/Button";
import { HorizontalBar } from "../HorizontalBar/HorizontalBar";
import FlexCol from "../FlexColumn/FlexCol";
import Modal from "components/ReactModal/ReactModal";
import { useNavigate } from "react-router-dom";
import { useLogoutRequest } from "api/resources/authentication/logout";
import SwitchOrg, { AddOrg } from "pages/organization/SwitchOrg";
import { Notifications } from "pages";
import { QuickNotifications } from "pages/account/notifications/QuickNotifications";
import { Tasks } from "pages/account/Tasks/Tasks";

/**
 * A headercontainer component that can hold your header with other content
 * @param {String} title string to be displayed
 * @param {String} backPath the path to go back to
 * @returns {React.ReactElement} a header component
 */

export function HeaderContainer({
  style,
  path,
  user,
  roles,
  onNavigate,
  organization,
  children,
}) {
  const [show, setShow] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showNotifs, setShowNotifs] = useState(false);
  const [showJobs, setShowJobs] = useState(false);
  const [switchOrg, setSwitchOrg] = useState(false);
  const [showAddOrg, setShowAddOrg] = useState(false);
  const navigate = useNavigate();
  const logoutRequest = useLogoutRequest();

  function logout() {
    setTimeout(() => {
      logoutRequest.mutate(
        {},
        {
          onSuccess: () => {
            localStorage.removeItem("reaction_token");
            navigate("/login");
            window.location.reload();
          },
        }
      );
    }, 500);
  }

  const showRef = useRef(null);

  function clickOutside(e) {
    if (showRef.current && !showRef.current?.contains(e.target)) {
      setShow(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  return (
    <>
      <div className={styles.headerContainer} style={style}>
        <FlexRow justify={"space-between"}>
          <FlexRow fit gap=".25rem">
            {/* <img
              src={require("assets/images/circlelogo.png")}
              height={"25px"}
            ></img> */}
            {path && (
              <Breadcrumb
                path={path}
                onNavigate={onNavigate ? onNavigate : () => undefined}
              ></Breadcrumb>
            )}
          </FlexRow>
          <FlexRow fit gap=".5rem">
            <div className={styles.signout}>
              {/* <span className={styles.signButton} style={{borderTopLeftRadius: ".42rem", borderBottomLeftRadius: ".42rem"}}>Sign Out</span>
            <HorizontalBar
              rotate
              style={{ margin: "0" }}
              width={1}
            ></HorizontalBar> */}
              <span
                className={styles.signButton}
                onClick={() => setShow(!show)}
              >
                {" "}
                <i className="bi-caret-down-fill"></i>
              </span>

              {show && (
                <div className={styles.switch} ref={showRef}>
                  <FlexCol>
                    <small className={styles.into}>Current Organization</small>
                    <span className={styles.into} style={{ color: "black" }}>
                      {organization?.name}
                    </span>
                  </FlexCol>
                  <Button
                    style={{ border: "1px solid lightgray", gap: ".5rem" }}
                    padding={"0 .5rem"}
                    height={"35px"}
                    width={"100%"}
                    onClick={() => setSwitchOrg(true)}
                  >
                    <i className="bi-arrow-repeat"></i> Switch Organization
                  </Button>
                </div>
              )}
            </div>

            <Button
              style={{ border: "1px solid lightgray" }}
              padding={"0"}
              height={"35px"}
              width={"36px"}
              tooltip="Jobs"
              tooltipStyle={{ right: "0px" }}
              onClick={() => setShowJobs(true)}
            >
              <i className="bi-boxes" style={{ fontSize: "1rem" }}></i>
            </Button>
            <Button
              style={{ border: "1px solid lightgray" }}
              padding={"0"}
              height={"35px"}
              width={"36px"}
              tooltip="Notifications"
              tooltipStyle={{ right: "0px" }}
              onClick={() => setShowNotifs(true)}
            >
              <i className="bi-bell" style={{ fontSize: "1rem" }}></i>
            </Button>
            {user && (
              <div
                onClick={() => setShowOptions(true)}
                style={{ cursor: "pointer" }}
              >
                <ImageEditor
                  src={user?.image?.imageURL}
                  isUser
                  canEdit={false}
                  position={
                    user?.image?.position
                      ? JSON.parse(user?.image?.position)
                      : { x: 0.5, y: 0.5 }
                  }
                  image={user?.image}
                  height={35}
                  width={35}
                ></ImageEditor>
              </div>
            )}
          </FlexRow>
        </FlexRow>
        {children}
      </div>

      {showNotifs && (
        <Modal
          show={showNotifs}
          onClose={() => setShowNotifs(false)}
          modalStyle={{ height: "100%" }}
          rightStyle
          dark
        >
          <QuickNotifications />
        </Modal>
      )}
      {showJobs && (
        <Modal
          show={showJobs}
          onClose={() => setShowJobs(false)}
          modalStyle={{ height: "100%" }}
          rightStyle
          dark
        >
          <Tasks />
        </Modal>
      )}

      <Modal
        show={switchOrg}
        onClose={() => setSwitchOrg(false)}
        modalStyle={{ height: "100%" }}
        rightStyle
        dark
      >
        <SwitchOrg
          show={switchOrg}
          userRoles={roles}
          currentUser={user}
          setShow={setSwitchOrg}
          setShowAddOrg={setShowAddOrg}
        ></SwitchOrg>
      </Modal>
      <AddOrg setShow={setShowAddOrg} show={showAddOrg} join></AddOrg>

      <Modal
        dark
        rightStyle
        show={showOptions}
        onClose={() => setShowOptions(false)}
        modalStyle={{ height: "100%", width: "300px", padding: "1rem" }}
      >
        <FlexRow gap={".5rem"}>
          <div>
            <ImageEditor
              src={user?.image?.imageURL}
              isUser
              canEdit={false}
              position={
                user?.image?.position
                  ? JSON.parse(user?.image?.position)
                  : { x: 0.5, y: 0.5 }
              }
              image={user?.image}
              height={35}
              width={35}
            ></ImageEditor>
          </div>
          <FlexCol>
            <span style={{ fontSize: ".8rem", fontWeight: "500" }}>
              {user?.firstName} {user?.lastName}
            </span>
            <span
              style={{
                fontSize: ".7rem",
                fontWeight: "500",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "75%",
              }}
              className={styles.gray}
            >
              {user?.email}
            </span>
          </FlexCol>
        </FlexRow>
        <FlexCol
          justify={"space-between"}
          gap="3rem"
          style={{ height: "100%" }}
        >
          <FlexCol style={{ marginTop: "2rem" }} gap=".5rem">
            <div
              className={styles.menu_item}
              onClick={() => navigate("/Account")}
            >
              <i className="bi-person-circle"></i> My Profile
            </div>
            <div className={styles.menu_item} onClick={logout}>
              <i className="bi-box-arrow-right"></i> Sign Out
            </div>
          </FlexCol>
          <FlexCol gap=".5rem">
            <small>© 2025 Reaction Data, Inc.</small>
            <FlexRow wrap="wrap" style={{ rowGap: "0rem", columnGap: ".5rem" }}>
              <a
                className={styles.link}
                href={"https://reactiondata.com/about-us-2/"}
                target="_blank"
              >
                About Us
              </a>
              <a
                className={styles.link}
                href={"https://reactiondata.com"}
                target="_blank"
              >
                Contact Us
              </a>
              <a
                className={styles.link}
                // href={"https://reactiondata.com/about-us-2/"}
                target="_blank"
                onClick={() => navigate("/privacy-policy")}
              >
                Privacy
              </a>
              <a
                className={styles.link}
                // href={"https://reactiondata.com/about-us-2/"}
                onClick={() => navigate("/terms-and-services-agreement")}
                target="_blank"
              >
                Terms of Service
              </a>
            </FlexRow>
          </FlexCol>
        </FlexCol>
      </Modal>
    </>
  );
}
