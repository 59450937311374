import TextEditorTry from "components/inputs/input_fields/TextEditorTry/TextEditorTry";
import styles from "./ChartStyles.module.scss";
import { useEffect, useState } from "react";
import {
  MetricType,
  ValueType,
} from "pages/results/Charts/QChart/QuestionChart";
import {
  useGetProjectMetric,
  useGetQMetric,
} from "api/resources/projects/answers";
import {
  combinedProjIds,
  combinedQs,
} from "pages/results/Charts/Visualization";
import { ErrorBanner } from "pages";

export default function TextBox({
  viz,
  box,
  index,
  setOffsetData,
  filters,
  inEdit,
}) {
  const [update, setUpdate] = useState(false);

  function drag_start(event) {
    var style = window.getComputedStyle(event.target, null);
    let offset_data =
      parseInt(style.getPropertyValue("left"), 10) -
      event.clientX +
      "," +
      (parseInt(style.getPropertyValue("top"), 10) - event.clientY);

    offset_data += "," + index;
    setOffsetData(offset_data);
    event.dataTransfer.setData("text/plain", offset_data);
  }

  useEffect(() => {
    var dm = document.getElementById("textbox:" + index + ":" + viz.id);
    dm.addEventListener("dragstart", drag_start, false);
  }, []);

  useEffect(() => {
    setUpdate(true);
    setTimeout(() => {
      setUpdate(false);
    }, 500);
  }, [box.valuetype, box.state]);

  const Score = viz.type === MetricType || viz.type === ValueType;

  return (
    <div
      style={{
        top: box.top + "%",
        right: box.stickRight ? "0px" : "",
        left: box.stickLeft ? "0px" : !box.stickRight ? box.left + "%" : "",
        color: box.color,
        backgroundColor: box.hasBackground
          ? box.backgroundColor
          : "transparent",
        boxShadow: !box.boxShadow ? "none" : "",
        width: box.width ? box.width : "",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: box.padding ? box.padding : "",
        marginRight: box.stickRight
          ? box.marginRight
            ? box.marginRight
            : 45
          : "",
        marginLeft: box.stickLeft ? (box.marginLeft ? box.marginLeft : 45) : "",
      }}
      id={"textbox:" + index + ":" + viz.id}
      draggable={inEdit}
      className={styles.textBox}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          cursor: inEdit ? "grab" : "",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {!update && (
          <>
            {box.valuetype === "static" && (
              <TextEditorTry
                encoding={box.state}
                editable={false}
                placeholder="Enter text..."
                defaultStyle={{
                  fontSize: "16px",
                  color: box.color,
                }}
                circle
              ></TextEditorTry>
            )}

            {box.valuetype !== "static" && (
              <>
                {Score && (
                  <ScoreTextBox box={box} viz={viz} filters={filters} />
                )}

                {!Score && (
                  <ChartTextBox box={box} viz={viz} filters={filters} />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function ScoreTextBox({ box, viz, filters }) {
  const getValue = useGetProjectMetric(
    { projectIds: combinedProjIds(viz) },
    filters,
    viz.designSettings?.dynamic
      ? viz.designSettings.userConstraints
      : undefined,
    box.valuetype
  );

  function parseValue() {
    if (getValue.data.value == null) {
      return "Unknown";
    }

    let val = getValue.data.value;

    if (box.valuetype === "responseRate") {
      let sigFigs = viz.designSettings?.sigFigs
        ? viz.designSettings?.sigFigs
        : 0;
      val = val.toFixed(sigFigs) + "%";
      return `Response Rate = ${val}`;
    }

    if (box.valuetype === "audienceSize") {
      return `Audience Size = ${val}`;
    }

    if (box.valuetype === "responseCount") {
      return `Responses = ${val}`;
    }
  }

  return (
    <>
      {getValue.isError && (
        <ErrorBanner
          error={getValue.error}
          message="Error fetching text box metric"
        />
      )}

      {getValue.isSuccess && <>{parseValue()}</>}
    </>
  );
}

function ChartTextBox({ box, viz, filters }) {
  const getValue = useGetQMetric(
    { questionIds: [] },
    filters,
    viz.designSettings?.dynamic
      ? viz.designSettings.userConstraints
      : undefined,
    box.valuetype
  );

  function parseValue() {
    if (getValue.data.value == null) {
      return "Unknown";
    }

    let val = getValue.data.value;

    if (box.valuetype === "nps" || box.valuetype === "average") {
      let sigFigs = viz.designSettings?.sigFigs
        ? viz.designSettings?.sigFigs
        : 0;
      val = val.toFixed(sigFigs);
    }

    if (box.valuetype === "nps") {
      return `NPS: ${val}`;
    }
    if (box.valuetype === "average") {
      return `Average = ${val}`;
    }

    if (box.valuetype === "answercount") {
      return `N = ${val}`;
    }
  }

  return (
    <>
      {getValue.isError && (
        <ErrorBanner
          error={getValue.error}
          message="Error fetching text box metric"
        />
      )}
      {getValue.isSuccess && <>{parseValue()}</>}
    </>
  );
}
