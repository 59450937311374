import React from "react";
import styles from "./Loading.module.scss";

export function NewLoading({height, width, style}) {
  return (
    <div className={styles.loadingAnimation} style={{ ...style}}>
      <div className={styles.spinner} style={{height: height, width: width}}></div>
    </div>
  );
};

