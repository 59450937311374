import React, { useEffect, useState } from "react";
import styles from "./WelcomeToReaction.module.scss";
import Button from "components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useCheckUserToken } from "api/resources/authentication/register";
import { Loading } from "components/Loading/Loading";
import TokenExpired from "pages/error_page/TokenExpired";
import FlexCol from "components/layouts/FlexColumn/FlexCol";

function WelcomeToReaction({
  steps,
  setSteps,
  activeStep,
  setActiveStep,
  setOrg,
  org,
  join,
  setType,
}) {
  const { token } = useParams();
  const checkTokenService = useCheckUserToken();
  const navigate = useNavigate();
  const [valid, setValid] = useState(true);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkTokenService.mutate(
      {
        token: token,
      },
      {
        onSuccess: (data) => {
          setLoading(false);
          if (data?.status?.join && !join) {
            setValid(false);
            setMessage("Page not valid");
          } else if (data?.status?.status === 1 || data?.status?.status === 2) {
            setEmail(data?.status?.email);
          } else {
            setValid(false);
            setMessage(data?.status?.description);
          }
        },
      }
    );
  }, []);

  return valid ? (
    <>
      {loading && <Loading></Loading>}
      {!loading && (
        <WelcomeContent
          setSteps={setSteps}
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          org={org}
          setOrg={setOrg}
          email={email}
          token={token}
          join={join}
          setType={setType}
        />
      )}
    </>
  ) : (
    <TokenExpired message={message}>
      <Button blue width={200} shadow onClick={() => navigate("/login")}>
        Sign In
      </Button>
    </TokenExpired>
  );
}

export default WelcomeToReaction;

function WelcomeContent({ join, setType, setSteps, setActiveStep, steps }) {
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (join) {
  //     setActiveStep(4);
  //     setType("Join");
  //   } else {
  //     let temp = [...steps];
  //     temp[4].done = true;
  //     temp[3].done = false;
  //     setSteps(temp);
  //     setActiveStep(4);
  //   }
  // }, []);
  return (
    <div className={styles.content}>
      <FlexCol className={styles.inner} align={"center"}>
      <img
          src={require("assets/images/Welcome.png")}
          width={"300px"}
          style={{ marginBottom: "3rem" }}
        ></img>
      <div style={{textAlign: "center"}}>
        <h1
          className={` ${styles.sapphire}`}
          
        >
          Welcome to ReactionData!
        </h1>
        <span>
          Your partner in data-driven decision making.
        </span>
      </div>

      <section style={{marginTop: "2rem"}}>
        <h2 className={`${styles.header_3}`} style={{ margin: "0" }}>
          Next Steps
        </h2>
        <p className={styles.text_1} style={{ paddingBottom: "1rem" }}>
          You're all set! Click "Sign In" below to get started with our platform
          tools and gain actionable insights.
        </p>
        <Button shadow blue onClick={() => navigate("/login")} width={100}>
          Sign In
        </Button>
      </section>
      </FlexCol>
    </div>
  );
}
