import React, { useState, useEffect } from "react";
import styles from "./Organizations.module.scss";
import {
  useFetchAllOrganizations,
  useGetOrgLogoUnique,
} from "api/resources/organization/organization";
import { Pagination } from "components/tables/BasicTable/Pagination/Pagination";
import { Sorting } from "components/tables/BasicTable/InteractiveSurveysTable";
import { Loading } from "components/Loading/Loading";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Button from "components/Button/Button";
import ImageEditor from "components/Image Editor/ImageEditor";
import { useNavigate } from "react-router-dom";
import { useFetchContactCount } from "api/resources/organization/organization";

function Organizations() {
  const [searchString, setSearchString] = useState("");
  const [perPage, setPerpage] = useState(10);
  const [pageSkip, setPageSkip] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [create, setCreate] = useState(false);

  const [searchSort, setSearchSort] = useState({
    item: "name",
    descend: false,
  });

  const getOrganizations = useFetchAllOrganizations(
    searchString,
    pageSkip,
    perPage,
    searchSort
  );

  useEffect(() => {
    getOrganizations.refetch();
  }, [searchSort, pageSkip, pageNum, perPage, searchString]);

  const navigate = useNavigate();

  function onSearch(val) {
    setSearchString(val);
    if (pageNum !== 1) {
      setPageNum(1);
      setPageSkip(0);
    }
  }

  return (
    <div className={styles.page}>
      <FlexRow justify={"space-between"} style={{ padding: ".5rem 1.5rem" }}>
        <div className={styles.header_3}>All Organizations</div>
        <FlexRow style={{ width: "fit-content" }}>
          <Button shadow onClick={() => navigate("/organizations/create")}>
            + New Organization
          </Button>
          <NavTextField
            value={searchString}
            setValue={onSearch}
            placeholder={"Search..."}
            shadow
          />
        </FlexRow>
      </FlexRow>
      {getOrganizations.isLoading && <Loading></Loading>}
      {getOrganizations.isSuccess && (
        <div className={styles.orgs}>
          <div className={styles.sortBox}>
            <Sorting
              sort={searchSort}
              setSort={setSearchSort}
              audience
            ></Sorting>

            <Pagination
              pageNum={pageNum}
              setPageNum={setPageNum}
              maxPage={
                getOrganizations.data?.object?.count / perPage < 1
                  ? 1
                  : Math.ceil(getOrganizations.data?.object?.count / perPage)
              }
              length={getOrganizations.data?.object?.organizations?.length}
              perPage={perPage}
              setPerPage={setPerpage}
              setPageSkip={setPageSkip}
            ></Pagination>
          </div>
          {getOrganizations.isRefetching && <Loading></Loading>}
          {!getOrganizations.isRefetching &&
            getOrganizations.data?.object?.organizations?.map((org, i) => (
              <Org org={org} index={i} key={i}></Org>
            ))}
          <div
            className={styles.sortBox}
            style={{ justifyContent: "flex-end" }}
          >
            <Pagination
              pageNum={pageNum}
              setPageNum={setPageNum}
              maxPage={
                getOrganizations.data?.object?.count / perPage < 1
                  ? 1
                  : Math.ceil(getOrganizations.data?.object?.count / perPage)
              }
              length={getOrganizations.data?.object?.organizations?.length}
              searchString={searchString}
              perPage={perPage}
              setPerPage={setPerpage}
              setPageSkip={setPageSkip}
            ></Pagination>
          </div>
        </div>
      )}
    </div>
  );
}

export default Organizations;

function Org({ org, index }) {
  const fetchLogo = useGetOrgLogoUnique(org?.id, index);
  const fetchContactCount = useFetchContactCount(org?.id);
  const navigate = useNavigate();
  return (
    <div
      className={styles.org}
      onClick={() => navigate("/organizations/" + org?.id)}
    >
      {fetchLogo.isSuccess && fetchContactCount.isSuccess && (
        <>
          <ImageEditor
            src={fetchLogo.data?.getLogoLinkForOrg?.imageURL}
            alt={org?.name}
            // canEdit
            isLogo={true}
            position={
              fetchLogo.data?.getLogoLinkForOrg?.position &&
              typeof fetchLogo.data?.getLogoLinkForOrg?.position === "string"
                ? JSON.parse(fetchLogo.data?.getLogoLinkForOrg?.position)
                : fetchLogo.data?.getLogoLinkForOrg?.position
            }
            image={fetchLogo.data?.getLogoLinkForOrg}
            refetch={fetchLogo.refetch}
            // show={show}
            // setShow={setShow}
            height={20}
            width={350}
            noShadow
            noBack
          ></ImageEditor>

          <FlexRow
            style={{
              flexDirection: "column",
              width: "100%",
              alignItems: "flex-start",
            }}
            start
            gap={"0"}
          >
            <span className={styles.header_4} style={{ margin: "0" }}>
              {org?.name}
            </span>
            <div className={styles.pills}>
              <div className={styles.startedText}>
                <span>Users:</span>
                <div>{fetchContactCount.data.userCountByOrgId}</div>
              </div>
              |
              <div className={styles.startedText}>
                <span>Projects:</span>
                {fetchContactCount.data.projectCountByOrgId}
              </div>
              |
              <div className={styles.startedText}>
                <span>Audiences:</span>
                {fetchContactCount.data.audienceCountByOrgId}
              </div>
              |
              <div className={styles.startedText}>
                <span>Contacts:</span>
                {fetchContactCount.data.contactCountByOrgId}
              </div>
            </div>
          </FlexRow>
        </>
      )}
    </div>
  );
}

{
  /* <StatBox
title="Contacts"
value={fetchContactCount.data.contactCountByOrgId}
description="Number of Contacts"
></StatBox>
<StatBox
title="Audiences"
value={fetchContactCount.data.audienceCountByOrgId}
description="Number of Audiences"
></StatBox>
</FlexRow>
<FlexRow style={{ flexWrap: "wrap" }} gap={"1rem"}>
<StatBox
title="Projects"
value={fetchContactCount.data.projectCountByOrgId}
description="Number of projects"
details="View Details"
onClickDetails
></StatBox>
<StatBox
title="Users"
value={fetchContactCount.data.userCountByOrgId}
description="Number of Users"
details="View Details"
onClickDetails
></StatBox> */
}
