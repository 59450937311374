//External
import React, { useState } from "react";

//Internal
import styles from "./AccountDetails.module.scss";
import {
  useGetCurrentUser,
  useUpdateUser,
} from "api/resources/organization/users";
import { TextFieldSimple } from "components/inputs/input_fields/TextFieldSimple/TextFieldSimple";
import Button from "components/Button/Button";
import { useUpdatePswdLogin } from "api/resources/authentication/register";
import { useCheckPassword } from "api/resources/authentication/login";
import UserProfile from "components/Popout/UserProfile";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { Header } from "components/layouts";
import Icon from "components/Icon/Icon";

/**
 * A component for the Account Details page. Click on the user in the sidemenu to get here.
 * @returns {AccountDetails} an html account details component
 */
export function AccountDetails({ organization, role, user }) {

  return (
    <div className={styles.mainPage}>
      <UserProfile
        userId={user?.id}
        organization={organization}
        roles={[role]}
        account
      ></UserProfile>
    </div>
  );
}

/**
 * A companion component for the Account Details page. ChangePassword is a modal that shows upto change the current users password
 * @param {Object} currUser the current user object
 * @param {Function} close a function that closes the modal when called.
 * @returns {AccountDetails} a change password modal html component
 */
export function ChangePassword({ currUser, close }) {
  // States
  const [validCurPswd, setValidCurPswd] = useState(false);

  const [showPswdConflict, setShowPswdConflict] = useState(false);
  const [currPswdIcon, setCurrPswdIcon] = useState();
  const [savedStatus, setSavedStatus] = useState();
  const [cPasswordType, setcPasswordType] = useState({
    icon: "eye-slash",
    type: "password",
  });
  const [passwordType, setPasswordType] = useState({
    icon: "eye-slash",
    type: "password",
  });

  const [confirmPasswordType, setConfirmPasswordType] = useState({
    icon: "eye-slash",
    type: "password",
  });
  const [invalidPasswordMessage, setInvalidPasswordMessage] = useState(
    "Password must be at least 8 characters. Password must contain at least 1 uppercase letter, 1 lowercase letter, and 1 special character."
  );
  const [validationRules, setValidationRules] = useState([]);
  const [user, setUser] = useState({});
  const [validPassword, setValidPassword] = useState();
  const [validConfirmPassword, setValidConfirmPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState("");

  // Queries
  const updatePswd = useUpdatePswdLogin();
  const updateUser = useUpdateUser();
  const checkPassword = useCheckPassword();

  // Functions
  function checkCurrentPassword(val) {
    checkPassword.mutate(
      {
        email: currUser.email,
        password: val,
      },
      {
        onSuccess: (data) => {
          setValidCurPswd(true);
          setCurrPswdIcon(
            <div style={{ marginBottom: ".5rem" }}>
              <Icon green iconName={`check-circle-fill`}></Icon>
            </div>
          );
        },
        onError: (err) => {
          setValidCurPswd(false);
          setCurrPswdIcon(
            <div style={{ marginBottom: ".5rem" }}>
              <Icon red iconName={`x-circle-fill`}></Icon>
            </div>
          );
        },
      }
    );
  }

  const handleUpdatePswd = () => {
    if (validCurPswd) {
      updateUser.mutate(
        {
          data: { requestedPassChange: true },
        },
        {
          onSuccess: () => {
            updatePswd.mutate(
              {
                userId: currUser.id,
                password: user?.password,
              },
              {
                onSuccess: (data) => {
                  if (data?.success) {
                    setSavedStatus(true);
                    setTimeout(() => close(), 1000);
                  }
                },
                onError: (data) => {},
              }
            );
          },
        }
      );
    }
  };

  function handleChangePassword(password) {
    setUser({ ...user, password: password });
    validateUserPassword(password);
  }

  function handleChangeValidatePassword(p) {
    if (p === user?.password) {
      setValidConfirmPassword(true);
    } else {
      setValidConfirmPassword(false);
    }
    setConfirmPassword(p);
  }

  function validateUserPassword(p) {
    //   check if meets length requirement
    //   check if contains number
    //   check if contains capital letter
    //   check if contains lower case letter
    //   check if contains special character
    //   If doesn't meet requirments display message with what is missing
    let message = "";
    let valid = false;
    const rules = [
      {
        rule: "Password must be at least 8 characters and less than 64 characters.",
        valid: p.length >= 8 && p.length <= 64,
      },
      {
        rule: "Password must contain at least 1 uppercase letter.",
        valid: /[A-Z]/.test(p),
      },
      {
        rule: "Password must contain at least 1 lowercase letter.",
        valid: /[a-z]/.test(p),
      },
      {
        rule: "Password must contain at least 1 number.",
        valid: /[0-9]/.test(p),
      },
      {
        rule: "Password must contain at least 1 special character.",
        valid: /[" !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~"]/.test(p),
      },
    ];

    valid = rules.every((rule) => rule.valid);
    setValidationRules(rules);

    setInvalidPasswordMessage(message);
    setValidPassword(valid);
  }

  // HTML Component
  return (
    <div className={styles.changePasswordContainer}>
      <Header>
        <h3>Change Password</h3>
      </Header>
      <FlexCol className={styles.page}>
        <div>
          <FlexRow align={"flex-end"}>
            <div style={{ width: "95%" }}>
              <TextFieldSimple
                type={cPasswordType?.type}
                finalIcon={cPasswordType?.icon}
                onClickFinalIcon={() =>
                  setcPasswordType({
                    type:
                      cPasswordType?.type === "password" ? "text" : "password",
                    icon:
                      cPasswordType?.type === "password" ? "eye" : "eye-slash",
                  })
                }
                onChange={checkCurrentPassword}
                placeholder={
                  cPasswordType?.type === "password"
                    ? "•••••••••"
                    : "ExamplePassword123!"
                }
                password
                label={"Enter your current password*"}
                icon="key"
              />
            </div>
            {currPswdIcon}
          </FlexRow>
        </div>
        <HorizontalBar height={1}></HorizontalBar>

        <FlexCol gap="1rem">
          <TextFieldSimple
            type={passwordType?.type}
            onChange={handleChangePassword}
            value={user?.password}
            valid={user?.password && validPassword}
            finalIcon={passwordType?.icon}
            onClickFinalIcon={() => {
              if (passwordType?.type === "password") {
                setPasswordType({
                  icon: "eye",
                  type: "text",
                });
              } else {
                setPasswordType({
                  icon: "eye-slash",
                  type: "password",
                });
              }
            }}
            invalidMessage={invalidPasswordMessage}
            password
            label="New Password*"
            validationRules={validationRules}
            validationRulesTitle="Password Requirements"
            placeholder={
              passwordType?.type === "password"
                ? "•••••••••"
                : "ExamplePassword123!"
            }
          />
          <TextFieldSimple
            type={confirmPasswordType?.type}
            onChange={handleChangeValidatePassword}
            value={confirmPassword}
            finalIcon={confirmPasswordType?.icon}
            onClickFinalIcon={() => {
              if (confirmPasswordType?.type === "password") {
                setConfirmPasswordType({
                  icon: "eye",
                  type: "text",
                });
              } else {
                setConfirmPasswordType({
                  icon: "eye-slash",
                  type: "password",
                });
              }
            }}
            // placeholder="Confirm Password"
            valid={validConfirmPassword}
            invalidMessage={"Passwords must match"}
            password
            label="Confirm New Password*"
            placeholder={
              confirmPasswordType?.type === "password"
                ? "•••••••••"
                : "ExamplePassword123!"
            }
          />
        </FlexCol>

        <span
          className={styles.entriesDontMatch}
          style={
            showPswdConflict
              ? { visibility: "visible" }
              : { visibility: "hidden" }
          }
        >
          Entries Don&apos;t Match
        </span>

        <FlexRow justify={"center"}>
          <Button onClick={close} width={100}>
            Cancel
          </Button>
          <FlexRow fit>
            <Button
              onClick={handleUpdatePswd}
              shadow
              width={100}
              blue
              disable={
                !validCurPswd ||
                !validPassword ||
                user?.password != confirmPassword
              }
            >
              Save
            </Button>
            {savedStatus && (
              <i className={`bi bi-check2 ${styles.saved}`}>Success!</i>
            )}
          </FlexRow>
        </FlexRow>
      </FlexCol>
    </div>
  );
}
