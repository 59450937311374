import Button from "components/Button/Button";
import styles from "./Image.module.scss";
import { Upload } from "./Upload";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { useEffect, useRef, useState } from "react";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import FlexCol from "components/layouts/FlexColumn/FlexCol";

export function Image({ section, active, saveSection }) {
  const [switchOut, setSwitchOut] = useState(false);

  function onImagePick(img) {
    let copy = { ...section };
    copy.imageId = img.id;
    copy.image = img;
    saveSection(copy);
    if (switchOut) setSwitchOut(false);
  }

  const settings = section.sectionSettings;

  useEffect(() => setSwitchOut(false), [active]);

  return (
    <>
      {active ? (
        section.image && !switchOut ? (
          <div className={styles.container}>
            <EditImage
              section={section}
              saveSection={saveSection}
              setSwitchOut={setSwitchOut}
            />
          </div>
        ) : (
          <Upload
            onImagePick={onImagePick}
            onCancel={switchOut ? () => setSwitchOut(false) : undefined}
          />
        )
      ) : (
        <>
          {section.image ? (
            <div
              className={styles.inactiveHolder}
              style={{
                alignItems:
                  settings.align == "left"
                    ? "flex-start"
                    : settings.align == "right"
                    ? "flex-end"
                    : "center",
              }}
            >
              <img
                src={section.image.imageURL}
                width={settings.fill ? undefined : settings.width ?? undefined}
              />
            </div>
          ) : (
            <div className={styles.noImage}>Empty Image</div>
          )}
        </>
      )}
    </>
  );
}

function EditImage({ section, saveSection, setSwitchOut }) {
  const initX = useRef(0);
  const initY = useRef(0);
  const leftGrowRef = useRef(false);
  const dragging = useRef(false);
  const editRef = useRef();

  const settings = section.sectionSettings;

  const onMouseMove = (e) => {
    if (!dragging.current || !editRef.current) {
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("mousemove", onMouseMove);
      return;
    }

    const deltaX = leftGrowRef.current
      ? initX.current - e.clientX
      : e.clientX - initX.current;

    let resultX = widthRef.current + deltaX;

    const aspectRatio =
      imageRef.current.clientWidth / imageRef.current.clientHeight;
    let resultY = resultX / aspectRatio;

    const fits = containerRef.current.clientWidth >= resultX;

    if (fits) {
      setHeight((old) => resultY);
      heightRef.current = resultY;
      widthRef.current = resultX;
      setWidth((old) => resultX);

      initY.current = e.clientY;
      initX.current = e.clientX;
    }

    const selection = window.getSelection();
    if (selection.rangeCount) {
      selection.removeAllRanges();
    }
  };

  const setUpDrag = (e, left = false) => {
    initY.current = e.clientY;
    initX.current = e.clientX;

    let h = height;
    let w = width;
    if (imageRef.current) {
      h = imageRef.current.clientHeight;
      w = imageRef.current.clientWidth;
    }

    heightRef.current = h;
    widthRef.current = w;
    setHeight(h);
    setWidth(w);

    leftGrowRef.current = left;
    dragging.current = true;

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);

    const selection = window.getSelection();
    selection.removeAllRanges();

    if (settings.fill) {
      let copy = { ...section };
      copy.sectionSettings.fill = false;
      saveSection(copy);
    }
  };

  const onMouseUp = (e) => {
    document.removeEventListener("mouseup", onMouseUp);
    document.removeEventListener("mousemove", onMouseMove);

    e.preventDefault();
    e.stopPropagation();

    // reset all
    dragging.current = false;
    initX.current = 0;
    initY.current = 0;
    leftGrowRef.current = false;

    let width = parseFloat(imageRef.current.clientWidth.toFixed(4));
    setWidth(width);
    let copy = { ...section };
    copy.sectionSettings.width = width;
  };

  const [height, setHeight] = useState();
  const [width, setWidth] = useState(settings.width);

  const heightRef = useRef();
  const widthRef = useRef();
  const imageRef = useRef();
  const containerRef = useRef();

  function switchAlignment(val) {
    let copy = { ...section };
    copy.sectionSettings.align = val;
    saveSection(copy);
  }

  function onFill(e) {
    let copy = { ...section };
    copy.sectionSettings.fill = e.target.checked;

    if (e.target.checked) {
      setWidth(undefined);
    } else {
      setWidth(copy.sectionSettings.width);
    }

    saveSection(copy);
  }

  return (
    <>
      <div
        className={styles.imageSpace}
        ref={containerRef}
        style={
          settings.align
            ? {
                alignItems:
                  settings.align == "left"
                    ? "flex-start"
                    : settings.align == "right"
                    ? "flex-end"
                    : "center",
              }
            : undefined
        }
      >
        <div
          className={styles.imageHolder}
          ref={editRef}
          style={settings.fill ? undefined : { width: width }}
        >
          <img src={section.image.imageURL} ref={imageRef}></img>

          {(settings.align == "center" || settings.align == "right") && (
            <div
              className={`${styles.adjuster} ${styles.bottomLeft}`}
              onMouseDown={(e) => setUpDrag(e, true)}
            ></div>
          )}
          {(settings.align == "center" || settings.align == "left") && (
            <div
              className={`${styles.adjuster} ${styles.bottomRight}`}
              onMouseDown={(e) => setUpDrag(e)}
            ></div>
          )}
        </div>
      </div>

      <FlexCol className={styles.settings}>
        <FlexRow justify={"center"}>
          <div
            className={`${styles.align} ${
              settings.align == "left" ? styles.aligned : ""
            }`}
            onClick={() => switchAlignment("left")}
          >
            <i className="bi-align-start"></i>
          </div>
          <div
            className={`${styles.align} ${
              !settings.align || settings.align == "center"
                ? styles.aligned
                : ""
            }`}
            onClick={() => switchAlignment("center")}
          >
            <i className="bi-align-center"></i>
          </div>
          <div
            className={`${styles.align} ${
              settings.align == "right" ? styles.aligned : ""
            }`}
            onClick={() => switchAlignment("right")}
          >
            <i className="bi-align-end"></i>
          </div>
        </FlexRow>

        <FlexRow>
          <Checkbox checked={settings.fill} onChange={onFill} />

          <div className={styles.label}>Fill Space</div>
        </FlexRow>

        <div className={styles.divider}></div>

        <FlexRow justify={"center"}>
          <Button
            seafoam
            onClick={() => setSwitchOut(true)}
            style={{ gap: "10px" }}
          >
            Switch Out <i className="bi-arrow-up-right-square"></i>
          </Button>
        </FlexRow>
      </FlexCol>
    </>
  );
}
