/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

// External
import { gql } from "graphql-request";

// Internal
import { useGqlInfiniteQuery, useGqlMutation, useGqlQuery } from "api/Api";

// GRAPHQL API

export const useFetchNotifications = (sort, id, unread) => {
  class notifSortInput {
    descending;
    all;
    unread;
    read;
    important;
  }

  const sort2 = new notifSortInput();
  sort2.descending = sort.sort.descending;
  sort2.all = sort.sort.all;
  sort2.unread = sort.sort.unread;
  sort2.read = sort.sort.read;
  sort2.important = sort.sort.important;

  const query = gql`
    query Query($sort: notifSortInput!) {
      notifications(sort: $sort) {
        body
        createdAt
        header
        id
        link
        read
        type
        important
        title
        sender
        senderTitle
      }
    }
  `;

  return useGqlQuery(
    ["notification" + (id ? id + (unread ? true : false) : "")],
    query,
    {
      sort: sort2,
    }
  );
};

export const useMarkAllRead = () => {
  const mutation = gql`
    mutation Mutation {
      markAllRead
    }
  `;
  return useGqlMutation(mutation);
};

export const useUpdateNotification = () => {
  const mutation = gql`
    mutation Mutation(
      $data: notifUpdateInput!
      $updateNotificationId: String!
    ) {
      updateNotification(data: $data, id: $updateNotificationId)
    }
  `;

  return useGqlMutation(mutation);
};

export const useDeleteNotification = () => {
  const mutation = gql`
    mutation Mutation($deleteNotificationId: String!) {
      deleteNotification(id: $deleteNotificationId)
    }
  `;

  return useGqlMutation(mutation);
};

export const useSearchNotifications = (sort, perPage, search) => {
  const query = gql`
    query Query(
      $sort: SortSelectionDelivery!
      $skip: Float!
      $perPage: Float!
      $search: String!
    ) {
      response: searchNotifications(
        search: $search
        sort: $sort
        skip: $skip
        perPage: $perPage
      ) {
        notifications {
          body
          createdAt
          header
          id
          link
          read
          type
          important
          title
          sender
          senderTitle
          itemId
          itemId2
        }
        count
        hasMore
      }
    }
  `;

  return useGqlInfiniteQuery(
    [
      "Notifications",
      { search, perPage, sort },
    ], // ✅ QueryKey now includes variables
    query,
    { search, perPage, sort},
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalLoaded = allPages.flatMap(
          (page) => page?.response?.notifications
        ).length;
        return lastPage?.response?.hasMore ? totalLoaded : undefined; // Use `totalLoaded` as new `skip`
      },
    }
  );
};

export const useSearchTasks = (
  sort,
  search,
  perPage,
  hidden
) => {
  const query = gql`
  query Query(
    $sort: SortSelectionDelivery!
    $skip: Float!
    $perPage: Float!
    $search: String!
    $hidden: Boolean!
  ) {
    response: searchTasks(
      search: $search
      sort: $sort
      skip: $skip
      perPage: $perPage
      hidden: $hidden
    ) {
      tasks {
        body
        createdAt
        header
        id
        link
        type
        important
        title
        sender
        senderTitle
        progress
        hide
      }
      count
      hasMore
    }
  }
`;

return useGqlInfiniteQuery(
  [
    "Search Tasks", { search, perPage, sort, hidden}
  ], // ✅ QueryKey now includes variables
  query,
  { search, perPage, sort, hidden},
  {
    getNextPageParam: (lastPage, allPages) => {
      const totalLoaded = allPages.flatMap(
        (page) => page?.response?.tasks
      ).length;
      return lastPage?.response?.hasMore ? totalLoaded : undefined; // Use `totalLoaded` as new `skip`
    },
  }
);
};

export const useCancelTask = () => {
  const mutation = gql`
    mutation Mutation($id: String!) {
      success: cancelTask(id: $id)
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useCancelAllTasks = () => {
  const mutation = gql`
    mutation {
      success: cancelAllTasks
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useUpdateTask = () => {
  const mutation = gql`
    mutation Mutation($id: String!, $data: TaskInput!) {
      success: updateTask(id: $id, data: $data)
    }
  `;

  return useGqlMutation(mutation, {});
};
