import React, { useState, useEffect, useRef } from "react";
import styles from "./FilterSimple.module.scss";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import Button from "components/Button/Button";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import { operators } from "assets/functions/Variables";
import { useFetchCustomField } from "api/resources/organization/organization";

function FilterSimple({
  filters,
  setFilters,
  allColumns,
  title,
  setShow,
  left,
  show,
}) {
  const [chosenFilters, setChosenFilters] = useState(filters ? filters : []);
  const [changed, setChanged] = useState(false);

  function changeFilter(filter, index) {
    let temp = [...chosenFilters];
    temp[index] = filter;
    setChosenFilters(temp);
  }

  function applyChanges() {
    setFilters([...chosenFilters]);
    setChanged(false);
  }

  function removeAll() {
    setChosenFilters([]);
    setChanged(true);
    // setFilters([]);
  }

  function addFilter() {
    let temp = [...chosenFilters];

    temp.push({
      column: undefined,
      id: "",
      label: "",
      name: "",
      properties: [],
      operator: 0,
      values: [],
      new: true,
      icon: "",
      generic: false,
      dataType: "text",
    });

    setChosenFilters(temp);
  }

  function removeFilter(index) {
    let temp = [...chosenFilters];
    temp.splice(index, 1);

    setChosenFilters(temp);
    setChanged(true);
  }

  return (
    <>
      <div className={styles.page}>
        <div
          className={`${styles.filterSimpleSticky} ${styles.label5}`}
          style={{ top: "0", borderTop: "none", padding: ".5rem" }}
        >
          <FlexRow>
            <FlexRow start>
              <i className="bi-funnel"></i>
              {title ? title : "Filters"}
            </FlexRow>
            <Button onClick={() => setShow(false)}>
              <i className="bi-x-lg"></i>
            </Button>
          </FlexRow>
          <FlexRow>
            <span
              className={styles.link}
              style={{ whiteSpace: "nowrap" }}
              onClick={() => addFilter()}
            >
              Add Filter
            </span>

            {chosenFilters?.length > 0 && (
              <span
                className={styles.link}
                style={{ whiteSpace: "nowrap" }}
                onClick={() => removeAll()}
              >
                Remove all
              </span>
            )}
            {changed && (
              <Button shadow blue height={2.1} onClick={() => applyChanges()}>
                Apply
              </Button>
            )}
          </FlexRow>
        </div>
        {chosenFilters && chosenFilters?.length > 0 && (
          <div
            className={`${styles.filterSimple} ${styles.label5}`}
            style={{
              height: "100%",
              padding: "0",
              paddingTop: "1rem",
              overflow: "auto",
            }}
          >
            {chosenFilters &&
              chosenFilters?.map((f, index) => (
                <AFilter
                  key={index}
                  filter={f}
                  changeFilter={(filter) => changeFilter(filter, index)}
                  removeFilter={() => removeFilter(index)}
                  allColumns={allColumns}
                  applyChanges={applyChanges}
                  setChanged={setChanged}
                  left={left}
                ></AFilter>
              ))}
          </div>
        )}
        {chosenFilters?.length === 0 && (
          <div
            className={`${styles.filterSimple} ${styles.label5}`}
            style={{ height: "100%" }}
          >
            No filters added
          </div>
        )}

        {/* <div className={`${styles.filterSimpleSticky} ${styles.label5}`}>
        <FlexRow>
          {chosenFilters?.length > 0 && (
            <Button shadow onClick={removeAll}>
              Remove all
            </Button>
          )}
          {chosenFilters?.length === 0 && <div></div>}
          <Button blue shadow onClick={applyChanges} disable={!done}>
            Apply
          </Button>
        </FlexRow>
      </div> */}
      </div>
    </>
  );
}

export default FilterSimple;

function AFilter({
  filter,
  changeFilter,
  removeFilter,
  allColumns,
  applyChanges,
  setChanged,
  left,
}) {
  const [view, setView] = useState(filter?.new);
  const [filterState, setFilterState] = useState(filter);
  const column =
    allColumns && allColumns?.length > 0
      ? allColumns.find((c) => c.id === filter.id)
      : {};
  const [done, setDone] = useState(false);

  const fetchField = useFetchCustomField();
  const [theField, setTheField] = useState();
  function pullField() {
    if (column && column?.id) {
      fetchField.mutate(
        {
          id: column ? column?.id : "",
        },
        {
          onSuccess: (data) => {
            setTheField(data?.field?.attribute);
          },
        }
      );
    }
  }

  useEffect(() => {
    pullField();
  }, [column]);

  useEffect(() => {
    if (done) {
      // applyChanges();
      setChanged(true);
      setDone(false);
    }
  }, [done]);

  function handleCancel(e) {
    // setFilterState(filter);
    setView(false);
  }

  function handleDone(e) {
    e.stopPropagation();
    let temp = { ...filterState };
    temp.new = false;
    temp.done = true;
    setFilterState(temp);
    changeFilter(temp);
    setView(false);
    setDone(true);
  }

  function handleChangeProp(props, index) {
    let temp = { ...filterState };
    temp.properties = props;
    temp.values = props?.map((p) => {
      if (typeof p === "string") {
        return p;
      } else {
        return p.value;
      }
    });
    setFilterState(temp);
    changeFilter(temp);
  }

  function handleChangeOperator(op) {
    let temp = { ...filterState };
    temp.operator = op?.value;
    setFilterState(temp);
    changeFilter(temp);
  }

  function handleChangeLabel(op) {
    let temp = { ...filterState };
    temp.label = op.label;
    temp.name = op.value;
    temp.id = op.id;
    temp.generic = op.generic;
    temp.dataType = op.dataType;
    let newc =
      allColumns && allColumns?.length > 0
        ? allColumns.find((c) => c.id === temp.id)
        : {};
    setFilterState(temp);
    changeFilter(temp);
  }

  function handleRemove(e) {
    if (e) {
      e.stopPropagation();
    }
    setView(false);
    setChanged(true);

    removeFilter();
  }

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handleCancel();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div
      className={`${styles.filterSimple} ${styles.label6}`}
      onClick={() => {
        if (!view) setView(true);
        let temp = { ...filter };
        temp.done = false;
        changeFilter(temp);
      }}
    >
      <div
        className={styles.header}
        style={{ flexDirection: "column", alignItems: "flex-start" }}
      >
        <FlexRow>
          <FlexRow start style={{ width: "fit-content" }}>
            {!filter?.new && column?.icon && (
              <Icon
                blue
                iconName={column?.icon}
                style={{ fontSize: "1.1rem" }}
              ></Icon>
            )}
            {!filter?.new ? column?.label : ""}
          </FlexRow>
          <Icon
            iconName={"x-lg"}
            onClick={(e) => {
              e.stopPropagation();
              handleRemove();
            }}
          ></Icon>
        </FlexRow>
        <FlexRow gap={"0rem"} style={{ alignItems: "flex-start" }}>
          {" "}
          {!filter?.new && (
            <div className={styles.operator}>
              {operators?.find((o) => o.value === filter?.operator)?.label}
            </div>
          )}
          <CombinedInput
            selectMultiple
            value={filter?.properties}
            noSearch
            options={[]}
            style={{ backgroundColor: "transparent" }}
            chosenStyle={{
              backgroundColor: "rgba(21, 187, 199, 0.14)",
              fontSize: ".7rem",
            }}
          />
        </FlexRow>
        {filter?.new && "New Filter"}
      </div>

      {view && (
        <div className={styles.editFilter} ref={ref}>
          <FlexRow>
            {" "}
            <FlexRow start style={{ width: "fit-content" }}>
              <Icon iconName="pencil" style={{ fontSize: "1.1rem" }}></Icon>
              Edit Filter
            </FlexRow>
            {view && (
              <span
                className={`${styles.link} ${styles.red}`}
                onClick={handleRemove}
              >
                Remove
              </span>
            )}
          </FlexRow>

          <div style={{ fontSize: ".8rem" }}>
            <SelectFieldCustom
              label="Column"
              onChange={handleChangeLabel}
              value={
                filter?.name
                  ? {
                      value: filter?.name,
                      label: filter?.label,
                      dataType: filter?.dataType,
                    }
                  : undefined
              }
              options={allColumns}
              noCreate
              formatOptionLabel={(data) => (
                <FlexRow>
                  {data.label} ({data.dataType})
                </FlexRow>
              )}
            ></SelectFieldCustom>
          </div>
          <div style={{ fontSize: ".8rem" }}>
            <SelectFieldCustom
              label="Operator"
              onChange={handleChangeOperator}
              value={operators.find((op) => op.value === filterState?.operator)}
              options={operators}
            ></SelectFieldCustom>{" "}
          </div>
          <ChangeValue
            handleChangeProp={handleChangeProp}
            options={
              theField
                ? theField.map((a) => {
                    return { value: a?.id, label: a?.name };
                  })
                : []
            }
            values={filter?.properties}
            column={column}
          />
          {/* <DataInput multiple={column.multiple} value={filterState?.properties}></DataInput> */}
          <FlexRow style={{ paddingTop: ".5rem" }}>
            <Button shadow link onClick={(e) => handleCancel(e)}>
              Cancel
            </Button>
            <Button blue shadow onClick={(e) => handleDone(e)}>
              Done
            </Button>
          </FlexRow>
        </div>
      )}
    </div>
  );
}

function ChangeValue({
  column,
  options,
  values,
  handleChangeProp,
  allColumns,
}) {
  const [ops, setops] = useState(options);
  function getOptions(column, options) {
    if (column?.dataType === "boolean") {
      return [
        { id: "true", value: "true", label: column?.trueValue },
        { id: "false", value: "false", label: column?.falseValue },
      ];
    }
    return options;
  }

  useEffect(() => {
    setops(getOptions(column, options));
  }, [column, options]);

  return (
    <CheckPickType
      column={column}
      handleChangeProp={handleChangeProp}
      values={values}
      options={ops}
    />
  );
}

function CheckPickType({
  column,
  options,
  values,
  handleChangeProp,
  allColumns,
}) {
  if (column?.dataType === "boolean") {
    return (
      <div>
        <SelectFieldCustom
          label="Value"
          onChange={handleChangeProp}
          options={options}
          values={values}
          noCreate
          selectMultiple
          placeholder={"Add value..."}
        ></SelectFieldCustom>
      </div>
    );
  } else if (
    column?.dataType === "text" ||
    column?.dataType === "email" ||
    column?.dataType === "date" ||
    column?.dataType === "date/time" ||
    column?.dataType === "time" ||
    column?.dataType === "url" ||
    column?.dataType === "phone" ||
    column?.dataType === "currency" ||
    column?.dataType === "number"
  ) {
    return (
      <div>
        <TextFieldSimple
          label="Value"
          onChange={(val) => handleChangeProp([val])}
          options={options}
          value={values[0]}
          noCreate
          selectMultiple
          placeholder={"Add value..."}
        ></TextFieldSimple>
      </div>
    );
  } else {
    return (
      <div>
        <SelectFieldCustom
          label="Value"
          onChange={handleChangeProp}
          options={options}
          values={values}
          noCreate
          selectMultiple
          placeholder={"Add value..."}
          create
        ></SelectFieldCustom>
      </div>
    );
  }
}
