import styles from "./ScoreChart.module.scss";
import {
  useGetProjectMetric,
  useGetQMetric,
} from "api/resources/projects/answers";
import { Loading } from "components/Loading/Loading";
import { ErrorBanner } from "pages/error_page/ErrorPage";
import { useState } from "react";

export const defaultMetricSettings = {
  metrics: [],
  titleSizes: 26,
  valueSizes: 48,
  titleAlignments: "center",
  valueAlignments: "center",
  titleColors: "#050606",
  valueColors: "#050606",
  titleBackgroundColors: "#ffffff",
  titleBorderRadii: "",
  titleBolds: 0,
  valueBolds: 0,
  titleWidths: 0,
  anchorTB: "center",
  anchorLR: "center",
  positions: "center",
  showIcons: false,
  iconOrders: "row",
  iconSizes: 30,
  iconColors: "#ffffff",
  iconBGColors: "#A4C6D0",
  textIconGaps: 5,
  gaps: 0,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  // hasTitle: true,
  // artificialTitle: true,
  display: "column",
  stacks: "center",
  sigFigs: 0,
  evenWidths: false,
  fillSpace: false,
  spacing: 10,
  showFilterSubtitle: true,
  containerShadow: true,
  showN: false,
  NLabel: "N",
  textBoxes: [],
  userConstraints: [],
};

export const singleMetricDefault = {
  code: "",
  titleSize: 26,
  valueSize: 48,
  titleAlignment: "center",
  valueAlignment: "center",
  title: "",
  titleColor: "#050606",
  valueColor: "#050606",
  titleBackgroundColor: "#ffffff",
  titleBorderRadius: "",
  titleBold: 0,
  titleWidth: 0,
  sigFigs: 0,
  valueBold: 0,
  stack: "center",
  showIcon: false,
  iconOrder: "row",
  iconSize: 30,
  iconColor: "#ffffff",
  iconBGColor: "#A4C6D0",
  textIconGap: 5,
  icon: "",
  gap: 0,
  type: "value",
};

export function getScoreChartSurveyPullData(viz) {
  let data = {
    projectIds: [],
    dynamics: [],
  };

  if (viz.designSettings.metrics) {
    for (let metric of viz.designSettings.metrics) {
      if (metric.questions) {
        for (let q of metric.questions) {
          if (q.projectId) {
            data.projectIds.push(q.projectId);
          }
        }
      }
      if (metric.projects) {
        data.projectIds = [
          ...data.projectIds,
          ...metric.projects.map((p) => p.id),
        ];
      }
      if (metric.dynamic) {
        for (let d of metric.dynamic) {
          data.dynamics.push(d);
        }
      }
    }
  }
  return data;
}

export default function ScoreChart({
  viz,
  filters,
  filterSubtitle,
  outsideData,
  setOutsideData,
  setUpOutsideDataCounter,
  width,
  height,
  preview,
}) {
  function setting(field, backup) {
    const global = viz.designSettings;
    if (field in global) {
      return global[field];
    }
    return backup;
  }

  return (
    <div
      className={styles.container}
      style={{
        alignItems: setting("anchorLR", "center"),
        justifyContent: setting("anchorTB", "center"),
        width: width,
      }}
    >
      {filterSubtitle && (
        <div className={styles.subtitle}>{filterSubtitle}</div>
      )}
      <div
        className={styles.metricStack}
        style={{
          flexDirection: setting("display", "column"),
          alignItems: setting("stacks", "center"),
          gap: setting("spacing", 10) + "px",
          maxWidth: width,
          maxHeight: height,
          width:
            viz.designSettings.display === "column" &&
            setting("fillSpace", false)
              ? "100%"
              : undefined,
        }}
      >
        {viz.designSettings.metrics &&
          viz.designSettings.metrics.map((metric, i) => (
            <Metric
              metric={metric}
              filters={filters}
              viz={viz}
              preview={preview}
              outsideData={outsideData}
              setOutsideData={setOutsideData}
              setUpOutsideDataCounter={setUpOutsideDataCounter}
              key={i}
            />
          ))}
      </div>
      {(!viz.designSettings.metrics || !viz.designSettings.metrics?.length) && (
        <>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                fontSize: viz.designSettings.titleSizes
                  ? viz.designSettings.titleSizes + "pt"
                  : "26pt",
              }}
            >
              Score Chart
            </div>
            {!preview && (
              <div
                style={{
                  fontSize: viz.designSettings.valueSizes
                    ? viz.designSettings.valueSizes + "pt"
                    : "48pt",
                  lineHeight: viz.designSettings.valueSizes
                    ? viz.designSettings.valueSizes + "pt"
                    : "48pt",
                  fontWheight: 500,
                }}
              >
                10
              </div>
            )}
          </div>
        </>
      )}
      {/* {viz.type === ValueType && (
        <ScoreDisplay
          viz={viz}
          // data={getValueData()}
          valueType={viz.designSettings.valueType}
          filterSubtitle={filterSubtitle}
          idAddOn={addOn}
          preview={preview}
          update={update}
          // saveData={saveData}
        />
      )}

      {viz.type === MetricType && (
        <MetricChart
          viz={viz}
          newChart={newChart}
          idAddOn={idAddOn}
          thumbnail={thumbnail}
          filters={filters}
          update={update}
        />
      )} */}
    </div>
  );
}

function Metric({
  metric,
  viz,
  filters,
  preview,
  outsideData,
  setOutsideData,
  setUpOutsideDataCounter,
}) {
  const global = viz.designSettings;

  function setting(field, backup) {
    if (field in metric) {
      return metric[field];
    }
    if (field + "s" in global) {
      return global[field + "s"];
    }
    return backup;
  }

  const evenColumns =
    global.display === "column" && (global.evenWidths || global.fillSpace);

  function getWidth() {
    let it = document.createElement("div");
    debugger;
    it.style = {
      fontSize: setting("titleSize", "12") + "pt",
      color: setting("titleColor", ""),
      backgroundColor: setting("titleBackgroundColor", ""),
      borderRadius: setting("titleBorderRadius", "0") + "px",
      textAlign: setting("titleAlignment", "center"),
      fontWeight: setting("titleBold", 0) * 100 + 400,

      // textWrap: "balance",
      // width: "100%",
      // padding: "0px 5px",
    };
    it.classList.add(styles.title);

    let textAndValue = document.getElementById(metric.id);
    if (textAndValue) {
      textAndValue.appendChild(it);

      let basic = it.clientWidth;
      if (basic) {
        let width = it.clientWidth;
        textAndValue.removeChild(it);
        return width + 20 + "px";
      }
    }
  }

  return (
    <div
      className={styles.metricContainer}
      style={{
        flexDirection: setting("iconOrder", "row"),
        gap: setting("textIconGap", 10) + "px",
        alignSelf: setting("stack", "center"),
        width: evenColumns ? "100%" : "fit-content",
        // height: metric.showIcon ? undefined : '100%',
        // width: metric.showIcon ? undefined : '100%',
      }}
    >
      {metric.showIcon && metric.icon && (
        <div
          className={styles.icon}
          style={{
            // height: setting('iconSize', 30) + 'px',
            // width: setting('iconSize', 30) + 'px',
            fontSize: setting("iconSize", 12) + "pt",
            height: setting("iconSize", 12) * 2 + "pt",
            width: setting("iconSize", 12) * 2 + "pt",
            backgroundColor: setting("iconBGColor", undefined),
            color: setting("iconColor", undefined),
          }}
        >
          <i className={`bi bi-${metric.icon}`}></i>
        </div>
      )}
      <div
        className={styles.textAndValue}
        style={{
          alignItems: setting("alignment", "center"),
          gap: setting("gap", 3) + "px",
          flexGrow: evenColumns ? 1 : 0,
        }}
        id={metric.id}
      >
        <div
          className={styles.title}
          style={{
            fontSize: setting("titleSize", "12") + "pt",
            color: setting("titleColor", ""),
            backgroundColor: setting("titleBackgroundColor", ""),
            borderRadius: setting("titleBorderRadius", "0") + "px",
            textAlign: setting("titleAlignment", "center"),
            fontWeight: setting("titleBold", 0) * 100 + 400,
            // width: getWidth(),
          }}
        >
          {metric.title}
        </div>

        {!preview && (
          <div
            className={styles.value}
            style={{
              fontSize: setting("valueSize", "12") + "pt",
              lineHeight: setting("valueSize", "12") + "pt",
              color: setting("valueColor", ""),
              textAlign: setting("valueAlignment", "center"),
              fontWeight: setting("valueBold", 0) * 100 + 400,
            }}
          >
            <>
              {metric.code === "custom" && <>{metric.value}</>}

              {metric.code !== "custom" &&
                (metric.projects?.length > 0 ||
                  (metric.dynamic && metric.dynamic?.length > 0)) && (
                  <>
                    {metric.questions && (
                      <QuestionMetric
                        metric={metric}
                        viz={viz}
                        filters={filters}
                      />
                    )}
                    {!metric.questions && (
                      <ProjectMetric
                        metric={metric}
                        viz={viz}
                        filters={filters}
                      />
                    )}
                  </>
                )}
            </>
          </div>
        )}
      </div>
    </div>
  );
}

function ProjectMetric({ metric, viz, filters }) {
  function getSurveyPullData() {
    let data = {
      projectIds: metric.projects.map((p) => p.id),
      dynamics: [],
    };
    if (metric.dynamic) {
      for (let d of metric.dynamic) {
        data.dynamics.push(d);
      }
    }

    return data;
  }

  const getValue = useGetProjectMetric(
    getSurveyPullData(metric),
    filters,
    viz.designSettings?.dynamic
      ? viz.designSettings.userConstraints
      : undefined,
    metric.code ? metric.code : ""
  );

  function parseValue() {
    if (getValue.data.value == null) {
      return "Unknown";
    }

    let val = getValue.data.value;

    if (metric.code === "responseRate") {
      let sigFigs =
        "sigFigs" in metric
          ? metric.sigFigs
          : "sigFigs" in viz.designSettings
          ? viz.designSettings.sigFigs
          : 0;
      val = val.toFixed(sigFigs) + "%";
    } else if (metric.code === "avgSurveyTime") {
      let seconds = Math.round(val) % 60;
      let minutes = Math.floor(val / 60);
      if (minutes < 1) {
        val = seconds + " sec";
      } else {
        val = minutes + " min " + seconds + " sec";
      }
    }

    return val;
  }

  return (
    <>
      {getValue.isError && (
        <ErrorBanner error={getValue.error} message="Error fetching metric" />
      )}
      {getValue.isSuccess && <>{parseValue()}</>}
      {getValue.isLoading && <Loading height={30} width={30} />}
    </>
  );
}

function QuestionMetric({ metric, viz, filters }) {
  function getQuestionMetricData() {
    let data = { questionIds: metric.questions.map((q) => q.id) };
    if (metric.dynamic) {
      data.dynamics = [];
      for (let d of metric.dynamic) {
        data.dynamics.push(d);
      }
    }

    return data;
  }

  const getValue = useGetQMetric(
    getQuestionMetricData(metric),
    filters,
    viz.designSettings?.dynamic
      ? viz.designSettings.userConstraints
      : undefined,
    metric.code ? metric.code : ""
  );
  function parseValue() {
    if (getValue.data.value == null) {
      return "Unknown";
    }

    let val = getValue.data.value;

    if (
      metric.code === "nps" ||
      metric.code === "npsAll" ||
      metric.code === "flywheel" ||
      metric.code === "average"
    ) {
      let sigFigs =
        "sigFigs" in metric
          ? metric.sigFigs
          : "sigFigs" in viz.designSettings
          ? viz.designSettings.sigFigs
          : 0;
      val = val.toFixed(sigFigs);
    }

    return val;
  }

  return (
    <>
      {getValue.isError && (
        <ErrorBanner error={getValue.error} message="Error fetching metric" />
      )}
      {getValue.isSuccess && <>{parseValue()}</>}
      {getValue.isLoading && <Loading height={30} width={30} />}
    </>
  );
}
