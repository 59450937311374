import { useState } from "react";
import styles from "./Dash.module.scss";
import { useCheckOrgDash } from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import {
  useFetchDashById,
  useFetchDashByUserId,
  useFetchMyDashboard,
  useFetchOrgDashboard,
} from "api/resources/account/dashboard";
import { PickDash } from "./PickDash/PickDash";
import Modal from "components/ReactModal/ReactModal";
import { DashReport } from "pages/results/Reports/ReportEditor";
import { ErrorBanner } from "pages/error_page/ErrorPage";

export const Dash = ({ user, role, collapse, organization }) => {
  const [activeTab, setActiveTab] = useState(
    role.canViewPersonalDashboard
      ? 0
      : role.canViewOrgDashboard
      ? 1
      : role.dashboardId && role.showDash
      ? 3
      : role.isAdmin
      ? 4
      : -1
  );

  const [showDashes, setShowDashes] = useState(false);
  const checkOrgDash = useCheckOrgDash();
  const [showTable, setShowTable] = useState(true);

  function getMenuItems() {
    let options = [];
    if (role.canViewPersonalDashboard) {
      options.push({
        id: 0,
        name: "My Dash",
      });
    }
    if (
      checkOrgDash.data.response.dashboard?.id && // Perhaps show either way if they're an admin?
      checkOrgDash.data.response.showDash &&
      role.canViewOrgDashboard
    ) {
      options.push({
        id: 1,
        name: "Org Dash",
      });
    }
    if (role?.isAdmin) {
      options.push({
        id: 2,
        name: "Others'",
      });
    }
    if (role?.dashboardId && role.showDash) {
      options.push({
        id: 3,
        name: `${role.name}'s Dash`,
      });
    }
    if (role?.isAdmin) {
      options.push({
        id: 4,
        name: "Other Roles'",
      });
    }

    return options;
  }

  function onSelectDash(id) {
    setActiveTab(id);
    setShowDashes(false);
    if (!showTable) setShowTable(true);
  }

  return (
    <div className={styles.page}>
      {checkOrgDash.isSuccess && (
        <>
          {!activeTab && (
            <MyDash
              user={user}
              role={role}
              setShowDashes={setShowDashes}
              collapse={collapse}
              organization={organization}
            />
          )}
          {activeTab == 1 && (
            <OrgDash
              user={user}
              role={role}
              setShowDashes={setShowDashes}
              collapse={collapse}
              organization={organization}
            />
          )}
          {activeTab == 2 && (
            <OthersDash
              user={user}
              role={role}
              setShowDashes={setShowDashes}
              collapse={collapse}
              showTable={showTable}
              setShowTable={setShowTable}
              organization={organization}
            />
          )}
          {activeTab == 3 && (
            <MyRolesDash
              user={user}
              role={role}
              setShowDashes={setShowDashes}
              collapse={collapse}
              organization={organization}
            />
          )}
          {activeTab == 4 && (
            <RoleDash
              user={user}
              role={role}
              setShowDashes={setShowDashes}
              collapse={collapse}
              showTable={showTable}
              setShowTable={setShowTable}
              organization={organization}
            />
          )}
          <Modal
            rightStyle
            show={showDashes}
            dark
            modalStyle={{ height: "100%", width: "20rem" }}
            onClose={() => setShowDashes(false)}
          >
            <div className={styles.changeDash}>
              {getMenuItems().map((item) => (
                <div
                  key={item.id}
                  onClick={() => onSelectDash(item.id)}
                  className={`${styles.menu_item} ${
                    activeTab === item.id ? styles.menu_item_active : ""
                  }`}
                  style={{ padding: ".5rem" }}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

function MyDash({ user, role, setShowDashes, collapse, organization }) {
  const getDashboard = useFetchMyDashboard();

  return (
    <>
      {getDashboard.isLoading && <Loading></Loading>}
      {getDashboard.isError && (
        <ErrorBanner
          error={getDashboard.error}
          message={"Error pulling up your dashboard"}
        />
      )}
      {getDashboard.isSuccess && (
        <DashReport
          dashboard={getDashboard.data.dashboard}
          user={user}
          canEdit={role.canEditPersonalDashboard}
          setShowDashes={setShowDashes}
          collapse={collapse}
          organization={organization}
          roles={role}
        />
      )}
    </>
  );
}

function OrgDash({ user, role, setShowDashes, collapse, organization }) {
  const getDashboard = useFetchOrgDashboard();

  return (
    <>
      {getDashboard.isLoading && <Loading></Loading>}
      {getDashboard.isError && (
        <ErrorBanner
          error={getDashboard.error}
          message={"Error pulling up the organization dashboard"}
        />
      )}
      {getDashboard.isSuccess && (
        <DashReport
          dashboard={getDashboard.data.dashboard}
          user={user}
          canEdit={role.isAdmin}
          setShowDashes={setShowDashes}
          collapse={collapse}
          organization={organization}
          roles={role}
        />
      )}
    </>
  );
}

function OthersDash({
  user,
  role,
  setShowDashes,
  collapse,
  showTable,
  setShowTable,
  organization,
}) {
  const [otherUser, setOtherUser] = useState(null);

  function onUserSelect(selectedUser) {
    setOtherUser({ ...selectedUser, organizationId: user.organizationId });
    setShowTable(false);
    setShowDashes(false);
  }

  return (
    <>
      {showTable && (
        <PickDash
          onPick={onUserSelect}
          orgId={user.organizationId}
          setShowDashes={setShowDashes}
          users
          organization={organization}
        />
      )}
      {!showTable && otherUser && (
        <UsersDash
          user={otherUser}
          role={role}
          setShowTable={setShowTable}
          setShowDashes={setShowDashes}
          collapse={collapse}
          organization={organization}
        />
      )}
    </>
  );
}

function UsersDash({
  user,
  role,
  setShowTable,
  setShowDashes,
  collapse,
  organization,
}) {
  const getDashboard = useFetchDashByUserId(user.id);

  return (
    <>
      {getDashboard.isLoading && <Loading></Loading>}
      {getDashboard.isError && (
        <ErrorBanner
          error={getDashboard.error}
          message={"Error pulling up this user's dashboard"}
        />
      )}
      {getDashboard.isSuccess && (
        <DashReport
          dashboard={getDashboard.data.dashboard}
          user={user}
          canEdit={role.isAdmin}
          setShowDashes={setShowDashes}
          collapse={collapse}
          setShowTable={setShowTable}
          organization={organization}
          roles={role}
        />
      )}
    </>
  );
}

function MyRolesDash({ role, user, setShowDashes, collapse, organization }) {
  const getDashboard = useFetchDashById(role.dashboardId);

  return (
    <>
      {getDashboard.isLoading && <Loading></Loading>}
      {getDashboard.isError && (
        <ErrorBanner
          error={getDashboard.error}
          message={"Error pulling up your role's dashboard"}
        />
      )}
      {getDashboard.isSuccess && (
        <DashReport
          dashboard={getDashboard.data.dashboard}
          user={user}
          canEdit={role.isAdmin}
          setShowDashes={setShowDashes}
          collapse={collapse}
          organization={organization}
          roles={role}
        />
      )}
    </>
  );
}

function RoleDash({
  user,
  role,
  setShowDashes,
  collapse,
  showTable,
  setShowTable,
  organization,
}) {
  const [otherRole, setOtherRole] = useState(null);

  function onSelectRole(selectedRole) {
    setOtherRole(selectedRole);
    setShowTable(false);
    setShowDashes(false);
  }

  return (
    <>
      {showTable && (
        <PickDash
          onPick={onSelectRole}
          orgId={user.organizationId}
          setShowDashes={setShowDashes}
          roles
        />
      )}
      {!showTable && otherRole && (
        <ARolesDash
          user={user}
          usersRole={role}
          otherRole={otherRole}
          setShowTable={setShowTable}
          setShowDashes={setShowDashes}
          collapse={collapse}
          organization={organization}
        />
      )}
    </>
  );
}

function ARolesDash({
  user,
  usersRole,
  otherRole,
  setShowTable,
  setShowDashes,
  collapse,
  organization,
}) {
  const getDashboard = useFetchDashById(otherRole.dashboardId);

  return (
    <>
      {getDashboard.isLoading && <Loading></Loading>}
      {getDashboard.isError && (
        <ErrorBanner
          error={getDashboard.error}
          message={"Error pulling up this role's dashboard"}
        />
      )}
      {getDashboard.isSuccess && (
        <DashReport
          dashboard={getDashboard.data.dashboard}
          user={user}
          canEdit={usersRole.isAdmin}
          setShowDashes={setShowDashes}
          setShowTable={setShowTable}
          collapse={collapse}
          organization={organization}
          roles={usersRole}
        />
      )}
    </>
  );
}
