import {
  optionColor,
  tagColor,
} from "pages/organization/calculations/Formula/Formula";
import { Accordian } from "../Accordian";
import styles from "./Variables.module.scss";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import FlexRow from "components/layouts/FlexRow/FlexRow";

export function Variables({ show, setShow, tags }) {
  return (
    <Accordian
      show={show}
      setShow={setShow}
      title={"Variables"}
      symbol={"< />"}
    >
      <Inside tags={tags} />
    </Accordian>
  );
}

function Inside({ tags }) {
  function getOptions(tag) {
    if (tag.question?.length) {
      return tag.question[0].matrixQuestion?.options;
    }
    return [];
  }

  return (
    <FlexCol>
      <div className={styles.title} style={{ paddingLeft: "5px" }}>
        Question Tags <i className={"bi-tag"}></i>
      </div>

      <div className={styles.note}>
        {
          "*Will substitute with each participant's latest answer, unless inside the 'every()' function."
        }
      </div>

      <FlexCol style={{ paddingLeft: "0px", gap: "10px" }}>
        {tags.map((tag) => (
          <div className={styles.tag}>
            <div className={styles.tagName} style={{ color: tagColor }}>
              {tag.name}

              <i
                style={{
                  color: tag.color === "#ffffff" ? "#888" : tag.color,
                }}
                className="bi-tag-fill"
              ></i>
            </div>

            <div className={styles.type}>{tag.type}</div>

            {tag.type.includes("Matrix") && (
              <FlexCol style={{ paddingLeft: "10px", paddingTop: "5px" }}>
                <div className={styles.matrix}>
                  {`*Requires a " . [option] " specification`}
                </div>
                {getOptions(tag).length > 0 && (
                  <div className={styles.matrix}>
                    <FlexRow align={"flex-start"} gap="7px">
                      Ex:
                      <FlexCol>
                        <div className={styles.matrixExample}>
                          <span style={{ color: tagColor }}>{tag.name}</span>
                          <span>.</span>
                          <span style={{ color: optionColor }}>
                            {getOptions(tag)[0]}
                          </span>
                        </div>
                        {getOptions(tag).length > 1 && (
                          <>
                            <div className={styles.matrixExample}>or</div>
                            <div className={styles.matrixExample}>
                              <span style={{ color: tagColor }}>
                                {tag.name}
                              </span>
                              <span>.</span>
                              <span style={{ color: optionColor }}>
                                {getOptions(tag)[1]}
                              </span>
                              {/* {`${
                                tag.name
                              }.${getOptions(tag)[1]}`} */}
                            </div>
                          </>
                        )}
                      </FlexCol>
                    </FlexRow>
                  </div>
                )}
              </FlexCol>
            )}
          </div>
        ))}
      </FlexCol>
    </FlexCol>
  );
}
