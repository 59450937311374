import React, { useState } from "react";
import styles from "./ColumnsToDisplay.module.scss";
import Modal from "components/ReactModal/ReactModal";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";
import Button from "components/Button/Button";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import { closestCenter, DndContext } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { moveToFirst } from "assets/functions/StringFunctions";
import { useUpdateAtts } from "api/resources/organization/organization";

function ColumnsToDisplay({
  show,
  setShow,
  columns,
  setColumns,
  onSaveColumnOrder,
  audience,
}) {
  const [columsState, setColumnsState] = useState(columns);

  const updateColumns = useUpdateAtts();
  function onSave() {
    let finalColumns = [];
    let adjustedColumns = [];
    let index = 0;
    for (let c of columsState) {
      finalColumns.push({ id: c?.id, enabled: c?.enabled, index: index });
      adjustedColumns.push({ ...c, index: index });

      index++;
    }
    if (onSaveColumnOrder) {
      onSaveColumnOrder(
        adjustedColumns.map((c) => {
          return { id: c?.id, enabled: c?.enabled };
        })
      );
    }
    setColumns(adjustedColumns);
    setColumnsState(adjustedColumns);
    // setShow(false);
    if (!audience) {
      updateColumns.mutate(
        {
          attributes: finalColumns,
        }
        // {
        //   onSuccess: () => {

        //   },
        // }
      );
    }
    setShow(false);
  }

  return (
    <Modal
      show={show}
      onClose={() => (setShow ? setShow(false) : undefined)}
      dark
      modalStyle={{ height: "600px", width: "1000px", borderRadius: ".5rem" }}
    >
      <div className={styles.headerContainer}>
        <div
          className={styles.header_3}
          style={{ margin: "0", color: "black" }}
        >
          {" "}
          Manage Columns
        </div>
        <div className={styles.text_2}>
          {" "}
          Select columns to show on this page and their order.
        </div>
      </div>
      <FlexRow style={{ justifyContent: "center" }}>
        <div className={styles.columnContainer}>
          <div className={styles.label5}>Not Visible Columns</div>
          <ChooseColumns
            columns={columsState}
            add={setColumnsState}
          ></ChooseColumns>
        </div>
        <div className={styles.arrow}>
          <i className="bi-arrow-right-circle"></i>
        </div>
        <HorizontalBar rotate height={"100%"} width={"5px"}></HorizontalBar>
        <div className={styles.columnContainer}>
          <div className={styles.label5}> Visible Columns</div>
          <ChooseColumns
            columns={columsState}
            remove={setColumnsState}
          ></ChooseColumns>
        </div>
      </FlexRow>
      <div className={styles.bottomContainer}>
        <Button
          shadow
          width={100}
          height={2.4}
          onClick={() => (setShow ? setShow(false) : undefined)}
        >
          Cancel
        </Button>
        <Button blue height={2.4} width={100} shadow onClick={onSave}>
          {updateColumns.isLoading ? "Saving..." : "Save"}
        </Button>
      </div>
    </Modal>
  );
}

export default ColumnsToDisplay;

function ChooseColumns({ columns, add, remove }) {
  const [gripped, setGripped] = useState(false);

  function adjuestColumn(index) {
    if (add) {
      let temp = [...columns];
      temp[index].enabled = true;
      temp[index].changed = true;
      temp = moveToFirst(temp, index);

      add(temp);
    }
    if (remove) {
      let temp = [...columns];
      temp[index].changed = true;
      temp[index].enabled = false;
      remove(temp);
    }
  }

  function handleDragOver({ active, over }) {
    if (active.id !== over?.id) {
      let indexOfActive = columns.findIndex((f) => f.id === active.id);
      let indexOfOver = columns.findIndex((f) => f.id === over.id);
      let newOrder = [...columns];
      newOrder.splice(indexOfActive, 1, columns[indexOfOver]);
      newOrder.splice(indexOfOver, 1, columns[indexOfActive]);
      newOrder[indexOfActive].index = indexOfActive;
      newOrder[indexOfActive].changed = true;
      newOrder[indexOfOver].index = indexOfOver;
      newOrder[indexOfOver].changed = true;
      remove(newOrder);
    }
  }

  return (
    <div className={styles.columns}>
      {add &&
        columns &&
        columns?.length > 0 &&
        columns?.map((column, index) =>
          add && !column.enabled ? (
            <TheColumn
              key={index}
              column={column}
              add={add}
              remove={remove}
              adjuestColumn={() => adjuestColumn(index)}
            />
          ) : undefined
        )}
      {remove && columns && columns?.length > 0 && (
        <DndContext
          // sensors={sensors}
          collisionDetection={closestCenter}
          onDragOver={handleDragOver}
          onDragEnd={() => setGripped(false)}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext
            // id={"Sortable Pages"}
            items={columns}
            strategy={verticalListSortingStrategy}
          >
            {columns?.map((column, index) =>
              remove && column.enabled ? (
                <TheColumn
                  key={index}
                  column={column}
                  add={add}
                  remove={remove}
                  adjuestColumn={() => adjuestColumn(index)}
                  gripped={gripped}
                  setGripped={setGripped}
                />
              ) : undefined
            )}{" "}
          </SortableContext>{" "}
        </DndContext>
      )}
    </div>
  );
}

function TheColumn({
  column,
  add,
  remove,
  adjuestColumn,
  setGripped,
  gripped,
}) {
  const [show, setShow] = useState(false);
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: column.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.9 : 1,
    zIndex: isDragging ? 10 : 0,
    // cursor: columnsOnly ? "pointer" : "",
    // backgroundColor: highlight ? "#efefef" : show ? "#F9F9F9" : undefined,
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      style={{ ...style }}
      className={styles.outSide}
    >
      {" "}
      {remove && (
        <i
          {...listeners}
          className={`bi-grip-vertical ${gripped ? styles.grab : styles.grip}`}
          onMouseDown={() => setGripped(true)}
        ></i>
      )}
      <div
        className={styles.column}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        onClick={adjuestColumn}
      >
        {" "}
        <FlexRow>
          {column?.label ? column?.label : column?.name}{" "}
          <div className={`${show && styles.icon} ${!show && styles.iconHide}`}>
            {add && (
              <Icon
                iconName={"plus"}
                style={{ fontSize: "1.2rem" }}
                blue
              ></Icon>
            )}
            {remove && (
              <Icon iconName={"x"} style={{ fontSize: "1.2rem" }} red></Icon>
            )}
          </div>
        </FlexRow>
      </div>
    </div>
  );
}
