import React, { useState, useEffect } from "react";
import styles from "./UserActivity.module.scss";
import { useFetchUserActivity } from "api/resources/organization/users";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Button from "components/Button/Button";
import { Sorting } from "components/tables/BasicTable/InteractiveSurveysTable";
import { Pagination } from "components/tables/BasicTable/Pagination/Pagination";
import { Loading } from "components/Loading/Loading";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import { trimDate } from "assets/functions/DateFunctions";

function UserActivity({ user, orgId }) {
  const [pageNumber, setPageNumber] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [pageSkip, setPageSkip] = useState(0);

  const [searchSort, setSearchSort] = useState({
    item: "createdAt",
    descend: true,
  });
  const getActivity = useFetchUserActivity(
    searchString,
    pageSkip,
    perPage,
    searchSort,
    user?.id,
    orgId,
    "activity"
  );

  useEffect(() => {
    getActivity.refetch();
  }, [searchString, pageNumber, perPage, pageSkip]);



  return (
    <div className={styles.page}>
      {/* <FlexRow style={{ padding: ".5rem 1.5rem" }}>
        <div className={styles.header_3}>activities</div>
        <FlexRow style={{ width: "fit-content" }}>
          <Button shadow >
            Manage Activities
          </Button>
          <NavTextField
            value={searchString}
            setValue={(val) => {
              setSearchString(val);
            }}
            placeholder={"Search..."}
            shadow
          />
        </FlexRow>
      </FlexRow> */}
      {getActivity.isLoading && <Loading></Loading>}
      {getActivity.isSuccess && (
        <div className={styles.activities}>
          <div className={styles.sortBox} >
            <Sorting
              sort={searchSort}
              setSort={setSearchSort}
              audience
            ></Sorting>

            <Pagination
              pageNum={pageNumber}
              setPageNum={setPageNumber}
              maxPage={
                getActivity.data?.object?.count / perPage < 1
                  ? 1
                  : Math.ceil(getActivity.data?.object?.count / perPage)
              }
              length={getActivity.data?.object?.activities?.length}
              searchString={searchString}
              perPage={perPage}
              setPerPage={setPerPage}
              setPageSkip={setPageSkip}
            ></Pagination>
          </div>
          {getActivity.isRefetching && <Loading></Loading>}
          {!getActivity.isRefetching &&
            getActivity.data?.object?.activities?.map((a, i) => (
              <Activity activity={a} index={i} key={i}></Activity>
            ))}
          <div
            className={styles.sortBox}
            style={{ justifyContent: "flex-end" }}
          >
            <Pagination
              pageNum={pageNumber}
              setPageNum={setPageNumber}
              maxPage={
                getActivity.data?.object?.count / perPage < 1
                  ? 1
                  : Math.ceil(getActivity.data?.object?.count / perPage)
              }
              length={getActivity.data?.object?.activities?.length}
              searchString={searchString}
              perPage={perPage}
              setPerPage={setPerPage}
              setPageSkip={setPageSkip}
            ></Pagination>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserActivity;

function Activity({ activity }) {
  return (
    <div className={styles.activity}>
      <span className={styles.header_5}>{activity.message}</span>
      <span className={styles.time} style={{ height: "fit-content" }}>
        {trimDate(activity.createdAt, true)}
      </span>
    </div>
  );
}
