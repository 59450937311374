import React, { useEffect, useState, useRef } from "react";

import styles from "./QuickNotifications.module.scss";
import {
  useUpdateNotification,
  useSearchNotifications,
  useMarkAllRead,
} from "api/resources/account/notification";
import { Loading } from "components/Loading/Loading";
import user from "assets/images/blank-profile-picture.png";
import uparrow from "assets/images/UpArrow.png";
import reactionlogo from "assets/images/ReactionCircleLogo.png";
import Button from "components/Button/Button";
import { useNavigate } from "react-router-dom";
import { ErrorBanner } from "pages/error_page/ErrorPage";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { smallDate, timeAgo } from "assets/functions/DateFunctions";
import Icon from "components/Icon/Icon";

const days = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};

const months = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

export function getTime(date) {
  date = new Date(date);
  let hr = date.getHours();
  let ext = "am";
  if (hr > 12) {
    hr -= 12;
    ext = "pm";
  }
  let min = date.getMinutes();
  if (min < 10) {
    min = "0" + min;
  }
  let day = date.getDay();
  day = days[day];
  let month = date.getMonth();
  month = months[month];
  let dayofmonth = date.getDate();

  return (
    day + ", " + month + " " + dayofmonth + " at " + hr + ":" + min + " " + ext
  );
}

export function QuickNotifications({}) {
  const [perPage, setPerpage] = useState(20);
  const [sort, setSort] = useState({
    item: "createdAt",
    descend: true,
  });

  const fetchNotifications = useSearchNotifications(sort, perPage, "");

  const data =
    fetchNotifications?.data?.pages.flatMap(
      (page) => page?.response?.notifications
    ) || [];

  // const count =
  //   fetchNotifications?.data?.pages?.length > 0
  //     ? fetchNotifications?.data?.pages[0]?.response?.count
  //     : 0;

  useEffect(() => {
    return () => {
      fetchNotifications.remove();
    };
  }, []);

  const markallRead = useMarkAllRead();

  function handleMarkAllRead() {
    markallRead.mutate(
      {},
      {
        onSuccess: (data) => {
          console.log(data);
          fetchNotifications.remove();
          fetchNotifications.refetch();
        },
      }
    );
  }

  const scrollableRef = useRef(null);

  const onBottomReached = () => {
    fetchNotifications.fetchNextPage();
  };

  useEffect(() => {
    const handleScroll = () => {
      const element = scrollableRef.current;
      if (!element) return;

      // Check if the user scrolled to the bottom
      if (
        element.scrollHeight - element.scrollTop <=
        element.clientHeight + 1
      ) {
        onBottomReached(); // Call function when bottom is reached
      }
    };

    const element = scrollableRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [onBottomReached]);

  return (
    <div className={styles.page} ref={scrollableRef}>
      <div className={styles.header}>
        <FlexRow gap=".5rem">
          <Icon iconName="bell" blue></Icon>
          <h3>Notificiations</h3>
        </FlexRow>
      </div>

      {fetchNotifications.isError && (
        <ErrorBanner
          error={fetchNotifications.error}
          message="Error pulling notifications"
        />
      )}

      <React.Fragment>
        {data?.map((notification) => (
          <Notification
            key={notification.id}
            notification={notification}
            read={notification.read}
          ></Notification>
        ))}
      </React.Fragment>

      {(fetchNotifications.isRefetching || fetchNotifications.isLoading) && (
        <div style={{ height: "100vh" }}>
          <Loading></Loading>
        </div>
      )}

      {fetchNotifications.isSuccess &&
        fetchNotifications?.data?.response?.length === 0 && (
          <div className={styles.none}>No notifications found</div>
        )}
      <div className={styles.footer}>
        <Button shadow onClick={handleMarkAllRead} blue>
          Mark all as read
        </Button>
        {/* <Button shadow blue onClick={() => navigate("/notifications")}>
          View all
        </Button> */}
      </div>
    </div>
  );
}

const Notification = ({ notification, read, onDelete }) => {
  const [readNotif, setReadNotif] = useState(read);
  const [deleted, setDeleted] = useState(false);
  const updateNotification = useUpdateNotification();
  const [options, setOptions] = useState(false);
  function getType(notif) {
    if (notif.type === "Import") {
      return uparrow;
    } else if (notif.type === "Survey Created") {
      return reactionlogo;
    } else if (notif.type === "Scheduler") {
      return require("assets/images/Calendar.png");
    } else {
      return user;
    }
  }

  const onNotifClick = (id) => {
    markRead();
  };

  function markRead() {
    if (readNotif === false) {
      setReadNotif(true);
      updateNotification.mutate(
        {
          data: {
            read: true,
          },
          updateNotificationId: notification.id,
        },
        {
          onSuccess: () => {
            notification.read = true;
          },
        }
      );
    }
  }

  const ref2 = useRef(null);

  function clickOutside(e) {
    if (ref2.current && !ref2.current?.contains(e.target)) {
      setOptions(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const navigate = useNavigate();

  function handleClick(notif) {
    markRead();
    if (notif.type === "Import" && notif?.itemId) {
      navigate(`/previous-imports/${notif?.itemId}`);
    } else if (notif.type === "Scheduler" && notif?.itemId2) {
      navigate(`/project/${notif?.itemId2}/delivery/messages/${notif?.itemId}`);
    } else if (notif.type === "Results" && notif?.itemId && notif?.itemId2) {
      navigate(`/project/${notif?.itemId2}/surveyresults/${notif?.itemId}`);
    } else {
      navigate(`/previous-imports/${notif?.itemId}`);
    }
  }

  if (deleted === false) {
    return (
      <div
        className={styles.notificationContainer}
        onClick={() => onNotifClick(notification.id)}
      >
        <div
          className={!notification.read ? styles.notread : styles.read}
        ></div>
        <FlexRow>
          {" "}
          <img
            src={getType(notification)}
            className={styles.avatar}
            alt="user profile image"
          />
          <FlexCol>
            <div className={styles.body}>{notification.body}</div>{" "}
            <FlexRow justify={"space-between"}>
              <small className={styles.gray}>
                {" "}
                {smallDate(notification.createdAt)}
              </small>
              <small className={styles.gray}>
                {timeAgo(notification.createdAt)}
              </small>
            </FlexRow>
          </FlexCol>
        </FlexRow>
        {/* <div className={styles.notification}>
          <div
            className={styles.navContainer}
            style={{ minHeight: "auto", padding: "0px", alignItems: "center" }}
          >
            <div className={styles.notifBody}>
              {timeAgo(notification.createdAt)}
            </div>
          </div>
        </div> */}
        {/* <i
          className={`bi-three-dots-vertical ${styles.threeDots}`}
          onClick={(e) => {
            e.stopPropagation();
            setOptions(true);
          }}
        ></i> */}
        {options && (
          <div className={styles.settingsBox} ref={ref2}>
            {/* <div
              className={styles.menuItem}
              onClick={(e) => {
                e.stopPropagation();
                handleClick(notification);
              }}
            >
              <i
                className="bi-layout-text-sidebar-reverse"
                style={{ color: "#A7D5C5" }}
              ></i>{" "}
              View Details
            </div> */}

            <div
              className={styles.menuItem}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(notification.id);
              }}
            >
              <i className="bi-trash" style={{ color: "#FF8878" }}></i> Delete
            </div>

            {!notification.read && (
              <div
                className={styles.menuItem}
                onClick={(e) => {
                  e.stopPropagation();
                  onNotifClick(notification.id);
                }}
              >
                <i className="bi-check-circle" style={{ color: "#15BCC7" }}></i>{" "}
                Mark as read
              </div>
            )}
            {/* <div
              className={styles.menuItem}
              onClick={(e) => {
                e.stopPropagation();
                // handleCancel(notification);
              }}
            >
              <i className="bi-person-dash" style={{ color: "#FBD575" }}></i>{" "}
              unsubscribe
            </div> */}
          </div>
        )}
      </div>
    );
  } else {
    return;
  }
};
