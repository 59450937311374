/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2024 ReactionData. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson

*/
import { useEffect, useState } from "react";
import styles from "./Surveys.module.scss";
import { useFetchSurveys } from "api/resources/projects/projects";
import { Loading } from "components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { ErrorBanner } from "pages/error_page/ErrorPage";
import { HeaderContainer } from "components/layouts/Header/HeaderContainer";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { Pagination } from "components/tables/BasicTable/Pagination/Pagination";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import { Sorting } from "components/tables/BasicTable/InteractiveSurveysTable";
import Button from "components/Button/Button";
import SurveyProject from "components/tables/BasicTable/SurveyWidgets/SurveyProjects/SurveyProject";
import SurveyFolders from "./SurveyFolders";

export function Surveys({ roles, user, org }) {
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [pageSkip, setPageSkip] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [sort, setSort] = useState({
    item: "updatedAt",
    descend: true,
  });
  const recentProjects = useFetchSurveys(search, perPage, sort, pageSkip);

  const surveys = recentProjects?.data?.response?.surveys;
  const count = recentProjects?.data?.response?.count;

  useEffect(() => {
    recentProjects.refetch();
  }, [pageNum, perPage]);

  let navigate = useNavigate();
  const routeChange = (row) => {
    let path = row.id;
    navigate("../surveys/" + path + "/details");
  };

  function onSearch(val) {
    setSearch(val);
    if (pageNum !== 1) {
      setPageNum(1);
      setPageSkip(0);
    }
  }

  return (
    <div className={styles.page}>
      <div className={styles.tableContainer}>
        <HeaderContainer
          path={"Surveys/"}
          user={user}
          organization={org}
        ></HeaderContainer>

        {/* <SurveyFolders
          initialFolders={[]}
          surveyCount={0}
          roles={roles}
        ></SurveyFolders> */}
        <div className={styles.surveys}>
          <FlexRow
            justify={"space-between"}
            wrap
            style={{ maxWidth: "1000px" }}
          >
            <Sorting sort={sort} setSort={setSort}></Sorting>

            <FlexRow fit wrap>
              <div className={styles.searchBox}>
                <NavTextField
                  value={search}
                  setValue={onSearch}
                  placeholder={"Search..."}
                  shadow
                />
              </div>

              <Button shadow onClick={() => navigate("/surveys/create")}>
                + New Survey
              </Button>

              <Pagination
                pageNum={pageNum}
                setPageNum={setPageNum}
                maxPage={count / perPage < 1 ? 1 : Math.ceil(count / perPage)}
                length={count}
                perPage={perPage}
                setPerPage={setPerPage}
                setPageSkip={setPageSkip}
              ></Pagination>
            </FlexRow>
          </FlexRow>

          {recentProjects.isSuccess && (
            <>
              {recentProjects.isRefetching && <Loading></Loading>}
              {!recentProjects.isRefetching &&
                surveys.map((survey) => (
                  <SurveyProject
                    key={survey.id}
                    survey={survey}
                    onClick={routeChange}
                    roles={roles}
                    refetch={recentProjects.refetch}
                  />
                ))}{" "}
              {surveys.length > 0 && (
                <FlexRow style={{ flexDirection: "row-reverse" }}>
                  <Pagination
                    pageNum={pageNum}
                    setPageNum={setPageNum}
                    maxPage={
                      count / perPage < 1 ? 1 : Math.ceil(count / perPage)
                    }
                    length={count}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    setPageSkip={setPageSkip}
                    dropUp
                  ></Pagination>
                </FlexRow>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
