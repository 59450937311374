import FlexCol from "components/layouts/FlexColumn/FlexCol";
import styles from "./DomainNames.module.scss";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Button from "components/Button/Button";
import {
  useCreateDomain,
  useDeleteDomain,
  useFetchDomains,
  useMakeDomainPrimary,
  useUpdateDomain,
} from "api/resources/organization/domains";
import { ErrorBanner } from "pages/error_page/ErrorPage";
import { Loading } from "components/Loading/Loading";
import { useState } from "react";
import Modal from "components/ReactModal/ReactModal";
import { TextFieldSimple } from "components/inputs";

export function DomainNames({}) {
  const fetchDomains = useFetchDomains();
  const createDomain = useCreateDomain();
  const deleteDomain = useDeleteDomain();
  const updateDomain = useUpdateDomain();
  const makeDomainPrimary = useMakeDomainPrimary();

  const [show, setShow] = useState(false);
  const [newName, setNewName] = useState("");
  const [adding, setAdding] = useState(false);

  function onAdd() {
    if (newName.length && !adding) {
      createDomain.mutate(
        { name: newName },
        {
          onSuccess: () => {
            setAdding(false);
            setShow(false);
          },
          onError: (err) => {
            setAdding(false);
          },
        }
      );
      setNewName("");
      setAdding(true);
    }
  }

  function onDelete(domain) {
    if (window.confirm("Are you sure you want to delete this domain name?")) {
      deleteDomain.mutate({ id: domain.id });
    }
  }

  function makePrimary(domain) {
    if (!domain.primary) {
      makeDomainPrimary.mutate({ id: domain.id });
    }
  }

  return (
    <>
      {fetchDomains.isError && (
        <ErrorBanner
          error={fetchDomains.error}
          message={"Error loading domains"}
        />
      )}
      {createDomain.isError && (
        <ErrorBanner
          error={createDomain.error}
          message={"Error creating domain"}
        />
      )}
      {deleteDomain.isError && (
        <ErrorBanner
          error={deleteDomain.error}
          message={"Error deleting domain"}
        />
      )}
      {updateDomain.isError && (
        <ErrorBanner
          error={updateDomain.error}
          message={"Error updating domain"}
        />
      )}
      {makeDomainPrimary.isError && (
        <ErrorBanner
          error={makeDomainPrimary.error}
          message={"Error making domain primary"}
        />
      )}
      {fetchDomains.isLoading && <Loading></Loading>}
      {fetchDomains.isSuccess && (
        <FlexCol gap={"1rem"}>
          <FlexRow>
            <FlexCol fit>
              <h3>Domain Authentication</h3>
              <div className={styles.text}>
                Improve deliverability by proving to inbox providers that you
                own the domain you're sending from.
              </div>
            </FlexCol>
            <Button
              blue
              style={{ whiteSpace: "nowrap" }}
              width={"fit-content"}
              padding={"1rem 2rem"}
              onClick={() => setShow(true)}
            >
              Add Domain
            </Button>
          </FlexRow>

          {fetchDomains.data.domains.map((domain) => (
            <div className={styles.domain}>
              <div
                className={`${styles.status} ${
                  domain.status == "Verified"
                    ? styles.green
                    : domain.status == "Pending"
                    ? styles.yellow
                    : styles.red
                }`}
              >
                <i
                  className={`${
                    domain.status == "Verified"
                      ? `${styles.green} bi-check2`
                      : domain.status == "Pending"
                      ? `${styles.yellow} bi-arrow-repeat`
                      : `${styles.red} bi-x-lg`
                  }`}
                ></i>
                {domain.status}
              </div>
              <div className={styles.name}>{`@${domain.name}`}</div>

              <div className={styles.options}>
                {fetchDomains.data.domains.length > 0 && (
                  <div
                    className={styles.star}
                    onClick={() => makePrimary(domain)}
                    style={{ cursor: domain.primary ? "default" : "pointer" }}
                  >
                    <div className={styles.makePrimary}>
                      {domain.primary ? "Primary" : "Make Primary"}
                    </div>
                    <i
                      className={`bi-star${domain.primary ? "-fill" : ""}`}
                    ></i>
                  </div>
                )}
                <div className={styles.remove} onClick={() => onDelete(domain)}>
                  <i className="bi-x"></i>
                </div>
              </div>
            </div>
          ))}
        </FlexCol>
      )}
      {show && (
        <Modal
          show
          onClose={() => setShow(false)}
          modalStyle={{ padding: "1.5rem" }}
          dark
        >
          <FlexCol gap={"1.5rem"} align={"center"} style={{ width: "300px" }}>
            <FlexRow align={"flex-end"} gap={10}>
              <div className={styles.at}>@</div>
              <TextFieldSimple
                label="New Domain Name"
                value={newName}
                onChange={setNewName}
              ></TextFieldSimple>
            </FlexRow>

            <Button blue onClick={onAdd}>
              {adding ? <Loading height={27} width={27} /> : <>{"Add"}</>}
            </Button>
          </FlexCol>
        </Modal>
      )}
    </>
  );
}
