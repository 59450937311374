// External
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useRef, useMemo } from "react";

// Internal
import styles from "./OrganizationDetails.module.scss";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import {
  useFetchOrganization,
  useGetOrgLogoUnique,
  useUpdateOrgSettings,
} from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import { useApplyColorContacts } from "api/resources/contacts/contacts";
import Button from "components/Button/Button";
import { useParams } from "react-router-dom";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import EditSingleCustomField from "components/CustomFieldsEditor/EditSingle";
import { dataTypes, maintimezones } from "assets/functions/Variables";
import OrgColorPalettes from "./OrgColorPalettes/OrgColorPalettes";
import ImageEditor from "components/Image Editor/ImageEditor";
import { useDeleteDistConfig, useSearchDistConfigByOrg } from "api/resources/projects/distributions";
import AddressInput from "components/inputs/input_fields/AddressInput/AddressInput";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { SigBuilder } from "../SigBuilder/SigBuilder";
import Icon from "components/Icon/Icon";
import { outputEncoding_noSubs } from "pages/projects/project/delivery/distributions/EmailBuilder/EncodingOutputer";
import parse from "html-react-parser";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import Dashboards from "./Dashboards";
import EditField from "components/inputs/input_fields/EditField/EditField";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";

export function GetOrganizationDetails({ role, updateRoute }) {
  const { id } = useParams();
  const MyOrg = useFetchOrganization(id);

  useMemo(() => {
    if (updateRoute) {
      updateRoute(0);
    }
  }, []);

  return (
    <>
      {MyOrg.isLoading && (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Loading></Loading>
        </div>
      )}
      {MyOrg.isSuccess && (
        <OrganizationDetails
          role={role}
          initOrganization={MyOrg?.data?.organization}
        ></OrganizationDetails>
      )}
    </>
  );
}

export const OrganizationDetails = ({ role, initOrganization }) => {
  const { id } = useParams();

  const [org, setOrg] = useState({});
  const [organization, setOrganization] = useState(initOrganization);
  const getOrgLogo = useGetOrgLogoUnique(id, 0);

  const [show, setShow] = useState(false);

  const [btnMsg, setBtnMsg] = useState("Save");

  // const MyOrg = useFetchOrganization(id);
  // const orgRoles = useFetchOrgRolesList(id);
  // const fetchContactCount = useFetchContactCount(id);

  function initZone(time) {
    let zone = maintimezones.find((t) => t.value === time);
    if (zone) {
      return zone;
    } else {
      return {
        timezone: "America/Denver",
        value: "America/Denver",
        offset: 25200,
        display: "GMT-07:00",
        label: "Mountain Time, US & Canada",
      };
    }
  }

  const updateOrg = useUpdateOrgSettings();

  function handleSave() {
    if (role?.isAdmin) {
      setBtnMsg(
        <div style={{ display: "flex", gap: "5px" }}>
          Saving <Loading height={20} width={20}></Loading>
        </div>
      );
      updateOrg.mutate(
        {
          updateOrganizationId: organization.id,
          data: org,
        },
        {
          onSuccess: (data) => {
            setBtnMsg(
              <div style={{ display: "flex", gap: "5px" }}>
                Saved <i className="bi bi-check-lg"></i>
              </div>
            );
            setTimeout(() => {
              setOrg({});
            }, 750);
          },
        }
      );
    }
  }

  function onChange(val, field) {
    let edits = { ...org };
    edits[field] = val;
    setOrg(edits);
    let theOrg = { ...organization };
    theOrg[field] = val;
    setOrganization(theOrg);
  }

  return (
    <div className={styles.page}>
      {getOrgLogo.isLoading && (
        <div style={{ height: "100vh" }}>
          <Loading></Loading>
        </div>
      )}
      {getOrgLogo.isError && <div>Error...</div>}
      {getOrgLogo.isSuccess && (
        <>
          <FlexCol>
            <FlexCol>
              <h3>General Settings</h3>
              <span>
                These settings determine default view and functionality
                throughout the site
              </span>
            </FlexCol>
            <HorizontalBar
              height={2}
              style={{ marginBottom: "2rem" }}
            ></HorizontalBar>

            <FlexCol
              style={{
                flex: 1,
                // maxWidth: "900px",
                minWidth: "400px",
                padding: "0 1rem",
                // margin: "0 auto",
              }}
              gap={"1rem"}
            >
              <FlexCol>
                <FlexRow justify={"space-between"} style={{ flexWrap: "wrap" }}>
                  <FlexRow fit>
                    <div className={styles.logo}>
                      <ImageEditor
                        src={getOrgLogo.data?.getLogoLinkForOrg?.imageURL}
                        alt={organization?.name}
                        canEdit
                        isLogo={true}
                        position={
                          getOrgLogo.data?.getLogoLinkForOrg?.position &&
                          typeof getOrgLogo.data?.getLogoLinkForOrg
                            ?.position === "string"
                            ? JSON.parse(
                                getOrgLogo.data?.getLogoLinkForOrg?.position
                              )
                            : getOrgLogo.data?.getLogoLinkForOrg?.position
                        }
                        image={getOrgLogo.data?.getLogoLinkForOrg}
                        refetch={getOrgLogo.refetch}
                        show={show}
                        setShow={setShow}
                        height={150}
                        width={250}
                        orgId={id}
                        noShadow
                      ></ImageEditor>
                    </div>
                    <FlexCol fit>
                      <h5>Organization Logo</h5>
                      <small>PNG or JPG, under 15MB</small>
                    </FlexCol>
                  </FlexRow>
                  <FlexRow fit>
                    <Button
                      onClick={() => setShow(true)}
                      // blue
                      shadow
                      style={{ gap: ".5rem" }}
                    >
                      <Icon blue className="bi-image"></Icon> <a>Edit Logo</a>
                    </Button>
                    {/* <Button
                      onClick={() => setShow(true)}
                      // blue
                      // shadow
                      style={{ gap: ".5rem" }}
                    >
                      <Icon red className="bi-trash"></Icon> <a>Delete</a>
                    </Button> */}
                  </FlexRow>
                </FlexRow>
              </FlexCol>
              <FlexRow>
                <TextFieldSimple
                  value={organization?.displayName}
                  placeholder="Organization Name..."
                  onChange={(val) => onChange(val, "displayName")}
                  label="Organization Name"
                  shadow
                  style={{ cursor: "normal" }}
                />
                <SelectFieldCustom
                  value={initZone(organization?.timezone)}
                  label="Time Zone"
                  shadow
                  options={maintimezones}
                  select
                  onChange={(v) => {
                    onChange(v.value, "timezone");
                  }}
                />
              </FlexRow>

              <FlexRow>
                <TextFieldSimple
                  value={organization?.subdomain}
                  label="Website"
                  shadow
                  onChange={(val) => onChange(val, "subdomain")}
                />
                <TextFieldSimple
                  value={organization?.id}
                  placeholder="Organization ID"
                  onChange={(val) => onChange(val, "displayName")}
                  label="Organization ID"
                  shadow
                  disable
                  style={{ cursor: "text" }}
                />
              </FlexRow>
              <FlexRow wrap="wrap">
                <EditField
                  style={{ margin: "1rem 0" }}
                  value={
                    <FlexRow gap={"10px"} fit>
                      <FlexRow className={styles.mfa} gap={"5px"}>
                        Two-Step Authentication
                        <i className="bi-shield-check"></i>
                      </FlexRow>
                      {organization.requireMFA ? (
                        <small
                          className={styles.green}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Required for all users
                        </small>
                      ) : (
                        <small
                          className={styles.red}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Not Required for users
                        </small>
                      )}
                    </FlexRow>
                  }
                />
                {!organization?.requireMFA && (
                  <Button
                    shadow
                    height={2.6}
                    style={{ gap: ".5rem", whiteSpace: "nowrap", flex: 1 }}
                    onClick={() => onChange(true, "requireMFA")}
                  >
                    {" "}
                    <Icon green iconName={"check-circle"} />
                    Make Required
                  </Button>
                )}
                {organization?.requireMFA && (
                  <Button
                    shadow
                    height={2.6}
                    style={{ gap: ".5rem", whiteSpace: "nowrap", flex: 1 }}
                    onClick={() => onChange(false, "requireMFA")}
                  >
                    {" "}
                    <Icon red iconName={"dash-circle"} />
                    Make Optional
                  </Button>
                )}
              </FlexRow>

              {Object.keys(org)?.length > 0 && (
                <FlexRow justify={"center"} style={{ marginTop: "2rem" }}>
                  <Button
                    shadow
                    disable={Object.keys(org)?.length === 0}
                    width={100}
                  >
                    Cancel
                  </Button>
                  <Button
                    blue
                    shadow
                    onClick={() => handleSave()}
                    disable={Object.keys(org)?.length === 0}
                    width={100}
                  >
                    {btnMsg}
                  </Button>
                </FlexRow>
              )}
            </FlexCol>
          </FlexCol>

          <FlexCol>
            {/* <div
                className={`${styles.header_4} `}
                style={{ fontWeight: "400", color: "black", margin: "0" }}
              >
                Themes & Colors
              </div> */}

            {/* <FlexRow style={{ justifyContent: "space-around" }}>
                <FlexRow start style={{ width: "fit-content" }}>
                  <div className={styles.colorBorder}>
                    <ColorPicker
                      defaultColor={organization?.primaryColor}
                    ></ColorPicker>
                  </div>{" "}
                  <span className={styles.header_6} style={{ margin: "0" }}>
                    Primary Color
                  </span>
                </FlexRow>
                <FlexRow start style={{ width: "fit-content" }}>
                  <ColorPicker
                    defaultColor={organization?.secondaryColor}
                  ></ColorPicker>{" "}
                  <span className={styles.header_6} style={{ margin: "0" }}>
                    Secondary Color
                  </span>
                </FlexRow>
              </FlexRow> */}
            <FlexCol>
              <h3>Colors Palettes</h3>
              <span>You can use color palettes throughout the site.</span>
            </FlexCol>
            <HorizontalBar
              height={2}
              style={{ marginBottom: "2rem" }}
            ></HorizontalBar>
            <FlexCol style={{ padding: "0 1rem" }}>
              <OrgColorPalettes />
            </FlexCol>
          </FlexCol>
          {/* <HorizontalBar height={2}></HorizontalBar> */}
          <FlexCol>
            <FlexCol>
              <h3>Dashboards</h3>
              <span>Control the visibility of role and organization dashboards.</span>
            </FlexCol>
            <HorizontalBar
              height={2}
              style={{ marginBottom: "2rem" }}
            ></HorizontalBar>
            <FlexCol style={{ padding: "0 1rem" }}>
              <Dashboards role={role} />
            </FlexCol>
          </FlexCol>

          {/* <div className={styles.pageSection}>
              <div className={styles.pageSection2}>
                <div className={styles.section}>
                  <FlexRow>
                    <div
                      className={styles.header_3}
                      style={{ fontWeight: "400" }}
                    >
                      Organization Information
                    </div>
                    <Button
                      shadow
                      style={{ gap: ".5rem" }}
                      onClick={() => setEdit(true)}
                    >
                      <i className="bi-pencil"></i> Edit
                    </Button>
                  </FlexRow>
                  

                  <div className={styles.infoTable}>
                    <div
                      className={`${styles.infoRow} ${styles.gray} ${styles.top}`}
                    >
                      <span>Organization Name</span>
                      <span>{organization?.name}</span>
                    </div>
                    <div className={`${styles.infoRow} ${styles.dark}`}>
                      <span>Time Zone</span>
                      <span>
                        {initZone(organization?.timezone)}
                      </span>
                    </div>

                    <div className={`${styles.infoRow} ${styles.gray}`}>
                      <span>Website</span>
                      <span>{organization?.subdomain}</span>
                    </div>
                    <div className={`${styles.infoRow} ${styles.dark}`}>
                      <span>Address</span>
                      <span>
                        {organization?.address1
                          ? `${organization?.address1}${
                              organization?.address2
                                ? "," + organization?.address2
                                : ""
                            } ${organization?.city}, ${
                              organization?.state
                            } ${organization?.postalCode} \n ${
                              organization?.country
                            }`
                          : "No Address"}
                      </span>
                    </div>
                    <div
                      className={`${styles.infoRow} ${styles.gray} ${styles.bottom}`}
                    >
                      <span>Account ID</span>
                      <span>{organization?.id}</span>
                    </div>
                  </div>
                </div>

                <FlexRow style={{ flexWrap: "wrap" }} gap={"1rem"}>
                  <StatBox
                    title="Contacts"
                    value={fetchContactCount.data.contactCountByOrgId}
                    description="Number of Contacts"
                  ></StatBox>
                  <StatBox
                    title="Audiences"
                    value={fetchContactCount.data.audienceCountByOrgId}
                    description="Number of Audiences"
                  ></StatBox>
                </FlexRow>
                <FlexRow style={{ flexWrap: "wrap" }} gap={"1rem"}>
                  <StatBox
                    title="Projects"
                    value={fetchContactCount.data.projectCountByOrgId}
                    description="Number of projects"
                    // details="View Details"
                    onClickDetails
                  ></StatBox>
                  <StatBox
                    title="Users"
                    value={fetchContactCount.data.userCountByOrgId}
                    description="Number of Users"
                    // details="View Details"
                    onClickDetails
                  ></StatBox>
                </FlexRow>

                <div className={styles.section}>
                  <FlexRow gap={"10px"}>
                    <ToggleSwitch
                      startChecked={MyOrg.data.organization.requireMFA}
                      handleCheck={toggleMFA}
                    />

                    <FlexRow className={styles.mfa} gap={"5px"}>
                      Require Two-Step Authentication
                      <i className="bi-shield-check"></i>
                    </FlexRow>
                  </FlexRow>
                </div>
              </div>

              <div className={styles.editImage}>
                <FlexRow>
                  <div
                    className={styles.header_3}
                    style={{ fontWeight: "400", margin: "0" }}
                  >
                    Themes and Colors
                  </div>
                </FlexRow>

                <FlexRow style={{ justifyContent: "space-around" }}>
                  <FlexRow start style={{ width: "fit-content" }}>
                    <ColorPicker
                      defaultColor={organization?.primaryColor}
                    ></ColorPicker>{" "}
                    <span className={styles.header_6} style={{ margin: "0" }}>
                      Primary Color
                    </span>
                  </FlexRow>
                  <FlexRow start style={{ width: "fit-content" }}>
                    <ColorPicker
                      defaultColor={organization?.secondaryColor}
                    ></ColorPicker>{" "}
                    <span className={styles.header_6} style={{ margin: "0" }}>
                      Secondary Color
                    </span>
                  </FlexRow>
                </FlexRow>

                <div className={styles.section_label5} style={{ margin: "0" }}>
                  Colors Palettes
                </div>

                <OrgColorPalettes orgId={id} />
              </div>
            </div> */}
        </>
      )}
    </div>
  );
};

export function OrgSignatures({}) {
  const [searchString, setSearchString] = useState("");
  const [perPage, setPerPage] = useState(0);
  const [pageSkip, setPageSkip] = useState(0);
  const [searchSort, setSearchSort] = useState({
    item: "createdAt",
    descend: true,
  });
  const [active, setActive] = useState();
  const searchsigs = useSearchDistConfigByOrg(
    searchString,
    pageSkip,
    perPage,
    searchSort
  );

  const deleteDistConfig = useDeleteDistConfig();

  function onDelete(sig) {
    if (
      window.confirm(
        "Are you sure you want to delete this signature? It will NOT affect emails with this signature already in it."
      )
    ) {
      deleteDistConfig.mutate(
        { id: sig.id },
        {
          onSuccess: (data) => {
            if (data.deleted) {
              searchsigs.refetch();
            }
          },
        }
      );
    }
  }

  return (
    <>
      <FlexRow justify={"space-between"}>
        <h3>Global Signatures</h3>
        <Button blue shadow onClick={() => setActive({ id: "" })} width={200}>
          Add Signature
        </Button>
      </FlexRow>
      <NavTextField
        shadow
        className={styles.search}
        placeholder="Search Signatures"
      ></NavTextField>
      <div className={styles.sigs}>
        {searchsigs.isSuccess &&
          searchsigs.data?.distconfigs?.map((sig, i) => (
            <div className={styles.orgSig} key={sig.id}>
              <div className={styles.sigTitle}>
                <div className={styles.sigName}>
                  {`<${sig?.aliasName}> ${sig?.fromAddress}`}
                </div>

                {sig.signature && <div className={styles.html}>HTML</div>}
                <Button
                  height={2.1}
                  options={[
                    {
                      data: (
                        <>
                          <Icon sapphire iconName={"pencil"}></Icon> Edit
                        </>
                      ),
                      onClick: () => setActive(sig),
                    },
                    {
                      data: (
                        <>
                          <Icon red iconName={"trash"}></Icon> Delete
                        </>
                      ),
                      onClick: () => onDelete(sig),
                    },
                  ]}
                  optionHeight={40}
                >
                  <Icon iconName={"three-dots-vertical"}></Icon>
                </Button>
              </div>

              <SigPreview sig={sig} />
            </div>
          ))}
      </div>

      {active && (
        <SigBuilder
          orig={active}
          onClose={() => setActive(null)}
          refetch={() => searchsigs.refetch()}
        />
      )}
    </>
  );
}

export function SigPreview({ sig }) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && sig.encoding) {
      let entry = outputEncoding_noSubs(sig.encoding);
      if (entry) {
        ref.current.innerHTML = "";
        ref.current.appendChild(entry);
      }
    }
    // if (div) {
    //   let entry = outputEncoding_noSubs(sig.encoding);
    //   if (entry) {
    //     div.innerHTML = "";
    //     div.appendChild(entry);
    //   }
    // }
  }, [sig]);

  return (
    <div className={styles.sigPreview} ref={ref}>
      {sig.signature && parse(`<div>${sig?.signature}</div>`)}
    </div>
  );
}

export function APISettings({ organization, role }) {
  const updateOrg = useUpdateOrgSettings();
  const [editApiKey, setEditApiKey] = useState(false);
  const [changeKey, setChangeKey] = useState(false);
  const [BtnMsg, setBtnMsg] = useState("Save Changes");
  const [org, setOrg] = useState(organization);
  const [edited, setEdited] = useState(false);
  const [newKey, setNewKey] = useState("");

  function handleSave() {
    if (role?.canUpdateOrganization) {
      setBtnMsg(
        <div style={{ display: "flex", gap: "5px" }}>
          Saving <Loading height={20} width={20}></Loading>
        </div>
      );
      updateOrg.mutate(
        {
          updateOrganizationId: org.id,
          data: {
            subdomain: org.subdomain,
            sendGridName: org.sendGridName,
          },
        },
        {
          onSuccess: (data) => {
            // console.log("Updated Org: ", data);
            setBtnMsg(
              <div style={{ display: "flex", gap: "5px" }}>
                Saved <i className="bi bi-check-lg"></i>
              </div>
            );
            setTimeout(() => {
              setEdited(false);
              setBtnMsg("Save Changes");
            }, 750);
          },
        }
      );
    }
  }

  function saveAPIKey() {
    if (role?.canUpdateOrganization) {
      setBtnMsg(
        <div style={{ display: "flex", gap: "5px" }}>
          Saving <Loading height={20} width={20}></Loading>
        </div>
      );
      updateOrg.mutate(
        {
          updateOrganizationId: org.id,
          data: {
            sendgridApiKey: newKey,
          },
        },
        {
          onSuccess: (data) => {
            // console.log("Updated Org: ", data);
            setEditApiKey(false);
            setNewKey(false);
          },
        }
      );
    }
  }

  function handleCancel() {
    setEdited(false);
    setOrg({ ...organization });
  }

  function onChange(val, field) {
    if (!edited) {
      setEdited(true);
    }
    let edits = { ...org };
    edits[field] = val;
    setOrg(edits);
  }

  return (
    <>
      <EditField
        value={"********"}
        label="Sendgrid API Key"
        onClickEdit={() => setEditApiKey(true)}
      />
      <ReactModal
        show={editApiKey}
        onClose={() => setEditApiKey(false)}
        modalStyle={{ borderRadius: ".5em", padding: "1em", width: "500px" }}
        dark
      >
        {!changeKey && (
          <TextFieldSimple
            value={"*****************"}
            password
            disable
            label="Current Sendgrid API Key"
          />
        )}
        {changeKey && (
          <TextFieldSimple
            value={newKey}
            onChange={(val) => setNewKey(val)}
            password
            label="New API Key"
            placeholder="Enter New Key..."
          />
        )}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {!changeKey && (
            <div
              className={styles.link}
              style={{ fontSize: ".8rem" }}
              onClick={() => setChangeKey(true)}
            >
              Change API Key?
            </div>
          )}
          {changeKey && (
            <div style={{ display: "flex", gap: "1em", paddingTop: ".5em" }}>
              <Button
                onClick={() => {
                  setChangeKey(false);
                  setNewKey("");
                }}
              >
                Cancel
              </Button>
              <Button onClick={saveAPIKey} blue shadow>
                Save
              </Button>
            </div>
          )}
        </div>
      </ReactModal>
    </>
  );
}

export function EditCustomFields({ customfields, salesforce }) {
  const [show, setShow] = useState(false);
  const [chosenField, setChosenField] = useState();
  const [newField, setNewField] = useState(false);

  const fixcolors = useApplyColorContacts();

  function handleEditField(field, index) {
    field.properties =
      typeof field.properties == "string"
        ? JSON.parse(field.properties)
        : field.properties;
    setChosenField(field);
    setShow(true);
  }

  function handleClose() {
    setChosenField(null);
    setNewField(false);
    setShow(false);
  }

  function applyColors() {
    fixcolors.mutate();
  }

  return (
    <>
      {!show && (
        <>
          {" "}
          <div className={styles.customFieldsContainer}>
            <FlexRow>
              <div className={styles.header_4} style={{ margin: "0" }}>
                Choose Column
              </div>
              <Button
                shadow
                onClick={() => {
                  setNewField(true);
                  setShow(true);
                }}
              >
                + New Column
              </Button>
            </FlexRow>
            {/* <FlexCol>
              <div className={styles.text_3}>Total: {customfields.length}</div>
              {customfields &&
                customfields.map((field, index) => (
                  <div className={styles.column}  onClick={() => handleEditField(field, index)}>
                    
                  </div>
                ))}
            </FlexCol> */}
            <table className={styles.customFieldsBox}>
              <thead className={`s ${styles.section_label5}`}>
                <tr>
                  <th style={{ width: "45px" }}>Icon</th>
                  <th>Name</th>
                  {/* <th>Attributes</th> */}
                  <th style={{ width: "135px" }}>Data Type</th>
                  <th style={{ width: "130px" }}>Required</th>
                  <th style={{ width: "80px" }}>Unique</th>
                </tr>
              </thead>
              <tbody>
                {customfields &&
                  customfields.map((field, index) => (
                    <tr
                      className={` ${
                        field.id === chosenField?.id && styles.menu_item_active
                      }`}
                      key={index}
                      onClick={() => handleEditField(field, index)}
                    >
                      <td style={{ width: "45px" }}>
                        <div className={styles.icon}>
                          <i className={`bi-${field?.icon}`}></i>
                        </div>
                      </td>
                      <td>{field.name}</td>

                      {/* <td>{field?.attributeCount}</td> */}
                      <td>
                        <div className={`${styles.status} ${styles.sapphire}`}>
                          {
                            dataTypes.find((d) => d.value === field?.dataType)
                              ?.label
                          }
                        </div>
                      </td>
                      <td>
                        {field?.required && (
                          <div className={`${styles.status} ${styles.orange}`}>
                            {field?.required ? "Required" : ""}
                          </div>
                        )}
                      </td>
                      <td>
                        {field?.unique && (
                          <div className={`${styles.status} ${styles.yellow}`}>
                            {field?.unique ? "Unique" : ""}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <Button
              shadow
              onClick={() => {
                setNewField(true);
                setShow(true);
              }}
            >
              + New Column
            </Button>
          </div>
        </>
      )}

      {show && (
        <EditSingleCustomField
          goback={() => {
            setShow(false);
            setChosenField(null);
          }}
          onClose={handleClose}
          field={chosenField}
          brandNew={newField}
        />
      )}
    </>
  );
}

function OrgFields({ role, organization }) {
  const [org, setOrg] = useState({ ...organization });
  const [edited, setEdited] = useState(false);
  const [BtnMsg, setBtnMsg] = useState("Save Changes");
  // const [editTime, setEditTime] = useState(false);
  // const [editWebsite, setEditWebsite] = useState(false);
  // const [editAddress, setEditAddress] = useState(false);
  // const [editName, setEditName] = useState(false);
  const updateOrg = useUpdateOrgSettings();
  const [timeZone, setTimeZone] = useState(initZone());
  const [defaultLocale, setDefaultLocale] = useState();

  function initZone() {
    let zone = maintimezones.find((t) => t.value === organization.timezone);
    if (zone) {
      return zone;
    } else {
      return {
        timezone: "America/Denver",
        value: "America/Denver",
        offset: 25200,
        display: "GMT-07:00",
        label: "Mountain Time, US & Canada",
      };
    }
  }

  // const languages = [
  //   { id: 0, name: "English", value: "en" },
  //   { id: 1, name: "Español", value: "sp" },
  //   { id: 2, name: "Português", value: "pt" },
  // ];

  const defaultLocales = [{ id: 0, name: "US", value: "US" }];

  function handleSave() {
    if (role?.canUpdateOrganization) {
      setBtnMsg(
        <div style={{ display: "flex", gap: "5px" }}>
          Saving <Loading height={20} width={20}></Loading>
        </div>
      );
      updateOrg.mutate(
        {
          updateOrganizationId: organization.id,
          data: {
            name: org.displayName,
            displayName: org.displayName,
            timezone: timeZone ? timeZone.value : undefined,
            defaultLocale: defaultLocale ? defaultLocale.value : undefined,
            subdomain: org.subdomain,
            sendGridName: org.sendGridName,
            address1: org?.address1,
            address2: org?.address2,
            city: org?.city,
            country: org?.country,
            postalCode: org?.postalCode,
            state: org?.state,
          },
        },
        {
          onSuccess: (data) => {
            // console.log("Updated Org: ", data);
            setBtnMsg(
              <div style={{ display: "flex", gap: "5px" }}>
                Saved <i className="bi bi-check-lg"></i>
              </div>
            );
            setTimeout(() => {
              location.reload();
            }, 750);
          },
        }
      );
    }
  }

  function handleCancel() {
    setOrg({ ...organization });
    // setEditName(false);
    // setEditWebsite(false);
    // setEditTime(false);
    // setEditAddress(false);
  }

  function onChange(val, field) {
    let edits = { ...org };
    edits[field] = val;
    setOrg(edits);
    setEdited(true);
  }

  return (
    <div className={styles.content} style={{ padding: "0rem", gap: "1rem" }}>
      {/* <div className={styles.settings}> */}
      <TextFieldSimple
        value={org.displayName}
        placeholder="Organization Name..."
        onChange={(val) => onChange(val, "displayName")}
        label="Organization Name"
        shadow
        style={{ cursor: "normal" }}
      />
      <SelectFieldCustom
        value={timeZone}
        label="Time Zone"
        shadow
        options={maintimezones}
        select
        onChange={(v) => {
          onChange(v.value, "timezone");
          setTimeZone(v);
        }}
      />
      <TextFieldSimple
        value={org.subdomain}
        label="Website"
        shadow
        onChange={(val) => onChange(val, "subdomain")}
      />
      {/* <CombinedInput
        value={
          org?.address1
            ? `${org?.address1}${org?.address2 ? "," + org?.address2 : ""} ${
                org?.city
              }, ${org?.state} ${org?.postalCode} \n ${org?.country}`
            : "No Address"
        }
        label="Address"
        shadow
        style={{ backgroundColor: "white" }}
      /> */}
      <AddressInput onChange={onChange} org={organization}></AddressInput>
      {/* <CombinedInput
        value={org.id}

        // onChange={(val) => onChange(val, "subdomain")}
        label="Account ID"
        // disable
        shadow
        // icon={"bi-pencil"}
        style={{ backgroundColor: "white" }}
      /> */}
      <div
        style={{
          display: "flex",
          gap: "1em",
          paddingTop: ".5em",
          justifyContent: "flex-end",
        }}
      >
        <Button red onClick={handleCancel} shadow link>
          Cancel
        </Button>
        <Button onClick={handleSave} shadow blue disable={!edited}>
          {BtnMsg}
        </Button>
      </div>
    </div>
  );
}
