import styles from "../SettingsAccordion.module.scss";
import {
  DoughnutType,
  PieType,
  TableType,
  VertBarType,
} from "../../QChart/QuestionChart";
import { useGetEmailsInSurveys } from "api/resources/projects/distributions";
import { StatComparison } from "./SurveyData/StatComparison";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { Switch, TextTool } from "../Tools";
import { ErrorBanner } from "pages/error_page/ErrorPage";

// I split these two components up because then the option for Pivot By can still appear in the settings, because
// if there have been no filters created for this chart yet, the Pivots component will break. Filter Settings creates
// and updates the filters, so PivotSettings waits for a moment for Filter Settings to create it, then Pivots will be
// able to be seen. Again, this is just a safeguard against the condition of no filters having been created yet. If the
// user clicks on Pivot By without any filters built in time, nothing will drop down, giving the appearance there are none.

export function AdvancedStatSettings({
  viz,
  updateViz,
  changeSettingsField,
  projects,
  visible,
  setVisible,
}) {
  return (
    <div
      key={viz.id}
      className={`${styles.header} ${visible ? styles.headervisible : ""}`}
      onClick={!visible ? setVisible : undefined}
    >
      <FlexRow
        onClick={visible ? setVisible : undefined}
        style={{ cursor: "pointer" }}
        className={styles.accordionTitle}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          Advanced Settings
          <i
            style={{ fontSize: "1.2em" }}
            className="bi bi-gear-wide-connected"
          ></i>
        </div>

        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </FlexRow>
      {visible && (
        <Settings
          viz={viz}
          updateViz={updateViz}
          projects={projects}
          changeSettingsField={changeSettingsField}
        />
      )}
    </div>
  );
}

function Settings({ viz, updateViz, changeSettingsField, projects }) {
  function toggleByPercentOfParticipants_StatChart(val) {
    let newViz = { ...viz };
    newViz.designSettings.byPercent = val;
    newViz.designSettings.byPercentOfReceived = false;
    newViz.designSettings.graphMax = null;
    newViz.designSettings.graphMin = null;
    updateViz(newViz);
  }

  function toggleByPercentOfReceived_StatChart(val) {
    let newViz = { ...viz };
    newViz.designSettings.byPercent = val;
    newViz.designSettings.byPercentOfReceived = val;
    newViz.designSettings.graphMax = null;
    newViz.designSettings.graphMin = null;
    updateViz(newViz);
  }

  function toggleComparison(val) {
    let copy = JSON.parse(JSON.stringify(viz)); // So it doesn't render comparison.jsx before getComparisonQs could be finished
    copy.designSettings.useComparison = val;
    if (val) {
      copy.designSettings.hasLegend = true;
      if (copy.type === DoughnutType || copy.type === PieType) {
        copy.type = VertBarType;
        copy.designSettings.legendPosition = "top";
        copy.designSettings.dataLabelValue.position = "end";
        copy.designSettings.dataLabelValue.alignment = "end";
      }
      if (!copy.comparison) {
        copy.comparison = {
          projectIds: [],
          dynamics: [],
        };
      }
    } else {
      copy.designSettings.hasLegend =
        copy.designSettings.split || copy.pivotString;
    }

    updateViz(copy);
  }

  function toggleUndefined(val) {
    let newViz = { ...viz };
    newViz.designSettings.showUndefined = val;

    if (newViz.type === TableType) {
      if (!val && viz.designSettings.split) {
        if ("Undefined" in viz.designSettings.tablePivotFields) {
          delete viz.designSettings.tablePivotFields.Undefined;
          let ind = viz.designSettings.tablePivotOrder.indexOf("Undefined");
          viz.designSettings.tablePivotOrder.splice(ind, 1);
        }
      }
    }

    updateViz(newViz);
  }

  function undefinedLabelChange(val) {
    if (
      viz.type === TableType &&
      "Undefined" in viz.designSettings.tablePivotFields
    ) {
      viz.designSettings.tablePivotFields.Undefined.name = val;
    }
    changeSettingsField("undefinedLabel", val);
  }

  function toggleEmpty(val) {
    if (
      viz.type === TableType &&
      val &&
      viz.pivotString &&
      viz.designSettings.split &&
      !viz.designSettings.split.includes("survey") &&
      viz.designSettings.split !== "nps" &&
      viz.designSettings.split !== "question"
    ) {
      let newViz = { ...viz };

      let columns = { ...newViz.designSettings.tablePivotFields };
      let order = [...newViz.designSettings.tablePivotOrder];

      let fields = custom_fields.find(
        (f) => f.name === viz.designSettings.split
      );
      if (fields?.properties) {
        let props = fields.properties;

        for (let i = 0; i < props.length; i++) {
          if (props[i] && !(props[i] in columns)) {
            let label = props[i];
            columns[label] = {
              name: label,
              show: true,
            };
            order.splice(i + 1, 0, label);
          }
        }

        newViz.designSettings.tablePivotFields = columns;
        newViz.designSettings.tablePivotOrder = order;
        newViz.designSettings.showNonParticipating = val;
        updateViz(newViz);
      }
    } else {
      changeSettingsField("showNonParticipating", val);
    }
  }

  const pivotOrSplit = viz.pivotString || viz.designSettings.split;

  const byEmail =
    (viz.pivotString === "email" || viz.designSettings.split === "email") &&
    !(viz.pivotString && viz.designSettings.split);

  return (
    <>
      <div
        style={{
          gap: "5px",
        }}
        className={styles.body}
      >
        <Switch
          checked={
            viz.designSettings.byPercent &&
            (pivotOrSplit ? true : !viz.designSettings.byPercentOfReceived)
          }
          onCheck={toggleByPercentOfParticipants_StatChart}
        >
          {" "}
          {pivotOrSplit ? "By percent" : "By percent of audience"}{" "}
        </Switch>

        {!pivotOrSplit && (
          <Switch
            checked={viz.designSettings.byPercentOfReceived}
            onCheck={toggleByPercentOfReceived_StatChart}
          >
            {" "}
            By percent of those who received an invite{" "}
            {viz.designSettings.showBars.find(
              (b) => b.value === "bounced" || b.value === "blocked"
            ) && (
              <span style={{ fontSize: ".9em", color: "#738c91" }}>
                {"(besides blocked and bounced)"}
              </span>
            )}{" "}
          </Switch>
        )}

        {(viz.designSettings.split || viz.pivotString) && !byEmail && (
          <>
            <Switch
              checked={viz.designSettings?.showUndefined}
              onCheck={toggleUndefined}
            >
              Show Undefined
            </Switch>

            {viz.designSettings?.showUndefined && (
              <TextTool
                value={viz.designSettings.undefinedLabel}
                onSave={undefinedLabelChange}
                style={{
                  paddingLeft: "10px",
                  paddingBottom: "5px",
                  paddingTop: "2px",
                }}
              >
                Undefined Label
              </TextTool>
            )}

            <Switch
              checked={viz.designSettings?.showNonParticipating}
              onCheck={toggleEmpty}
            >
              Include Empty Fields
            </Switch>
          </>
        )}

        <OnlyLinks
          viz={viz}
          changeSettingsField={changeSettingsField}
          projects={projects}
        />

        {viz.type !== TableType && (
          <>
            <div className={`${styles.title}`} style={{ marginTop: "1em" }}>
              Comparison
              <i
                style={{ fontSize: "1.2em" }}
                className="bi bi-graph-up-arrow"
              ></i>
            </div>

            <Switch
              checked={viz.designSettings.useComparison}
              onCheck={toggleComparison}
              tooltip={
                "Runs the average count or calculation over compared data."
              }
              tipStyle={{ width: "160px" }}
            >
              Run Comparison
            </Switch>

            {viz.designSettings.useComparison && projects.length > 0 && (
              <>
                {(viz.comparison.dynamics.length > 0 ||
                  viz.comparison.projectIds.length > 0) && (
                  <>
                    {!viz.pivotString && !viz.designSettings.split && (
                      <TextTool
                        value={viz.designSettings.chosenLabel}
                        placeholder="Chosen"
                        onSave={(val) =>
                          changeSettingsField("chosenLabel", val)
                        }
                      >
                        Chosen Label
                      </TextTool>
                    )}
                    <TextTool
                      value={viz.designSettings.comparisonLabel}
                      placeholder="Comparison"
                      onSave={(val) =>
                        changeSettingsField("comparisonLabel", val)
                      }
                    >
                      Comparison Label
                    </TextTool>
                  </>
                )}

                <StatComparison viz={viz} updateViz={updateViz} />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

function OnlyLinks({ viz, changeSettingsField, projects }) {
  const getEmails = useGetEmailsInSurveys(projects.map((p) => p.id));

  return (
    <>
      {getEmails.isError && <ErrorBanner error={getEmails.error} message="Error fetching survey emails"/>}
      {getEmails.isSuccess && getEmails.data.emails.some((d) => !d.hasLink) && (
        <Switch
          checked={viz.designSettings?.onlyLinks}
          onCheck={(val) => changeSettingsField("onlyLinks", val)}
        >
          Only emails with survey links
        </Switch>
      )}
    </>
  );
}
