import React, { useState } from "react";
import Button from "components/Button/Button";
import { Header } from "components/layouts";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import styles from "./CreateRole.module.scss";
import PermissionsCategories from "./permissions";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import SettingToggle from "./SettingToggle";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { TextFieldSimple } from "components/inputs";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import Status from "components/Status/Status";
import { TextArea } from "components/inputs/input_fields/TextArea/TextArea";
import { Player } from "@lottiefiles/react-lottie-player";
import { useCreateRoleGql } from "api/resources/organization/roles";

function CreateRole({ onClose, refetch }) {
  const [role, setRole] = useState(initRole());
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState(false);
  const createRole = useCreateRoleGql();

  function initRole() {
    let theRole = {};

    theRole.name = "New Role";
    theRole.color = "#15bcc7";
    for (let set of PermissionsCategories) {
      for (let permission of set?.permissions) {
        theRole[permission?.db_field] = true;
      }
    }
    return theRole;
  }

  const updateRoleField = (field, val) => {
    let roleTemp = { ...role };
    roleTemp[field] = val;
    setRole(roleTemp);
  };

  const createNewRole = () => {
    setCreating(true);
    createRole.mutate(
      {
        data: role,
      },
      {
        onSuccess: (data) => {
            refetch();
            setTimeout(() => {
                setCreating(false);
                onClose();
            }, 1000) 

        },
        onError: (data) => {
          setError(true);
          setCreating(false);
        },
      }
    );

    setCreating(true);
  };

  return (
    <div className={styles.page}>
      <Header>
        <FlexRow justify={"space-between"}>
          <h2>Create Role</h2>
          <FlexRow fit>
            {" "}
            <Button width={100} onClick={onClose}>
              Cancel
            </Button>{" "}
            <Button shadow blue width={100} onClick={createNewRole}>
              Create
            </Button>
          </FlexRow>
        </FlexRow>{" "}
      </Header>

      <div className={styles.container}>
        {!creating && !error && (
          <>
            <FlexCol gap="1rem">
              <FlexRow fit align={"flex-end"}>
                <TextFieldSimple
                  label="Name"
                  placeholder="Role Name"
                  value={role?.name}
                  onChange={(val) => updateRoleField("name", val)}
                  onEnter={(val) => updateRoleField("name", val)}
                  style={{ width: "300px" }}
                ></TextFieldSimple>
                <FlexRow fit style={{ paddingBottom: ".25rem" }}>
                  <Status
                    style={{
                      width: "fit-content",
                      fontSize: "1rem",
                      padding: ".25rem 1rem",
                      whiteSpace: "nowrap",
                    }}
                    color={role?.color}
                  >
                    {role?.name}
                  </Status>
                  <ColorPicker
                    height={20}
                    width={20}
                    defaultColor={role?.color}
                    onChange={(val) => updateRoleField("color", val)}
                  ></ColorPicker>
                </FlexRow>
              </FlexRow>
              <TextArea
                label="Description"
                placeholder="Description"
                value={role?.description}
                onSave={(val) => updateRoleField("description", val)}
                onEnter={(val) => updateRoleField("description", val)}
                className={styles.area}
              />
            </FlexCol>
            <div className={styles.accordianContainer}>
              {PermissionsCategories.map((set, i) => (
                <React.Fragment key={i}>
                  {((role?.isAdmin && set?.org_permissions.length > 0) ||
                    set?.permissions.length > 0) && (
                    <div className={styles.setting}>
                      <div className={styles.header_5} style={{ margin: "0" }}>
                        {set.set_name}
                      </div>
                      <HorizontalBar height={2}></HorizontalBar>
                      <div className={styles.allPermissions}>
                        {set?.permissions.length > 0 && (
                          <div className={styles.permissions}>
                            {set.permissions.map((permission) => (
                              <SettingToggle
                                text={permission.label}
                                key={permission.db_field}
                                checked={role[permission.db_field]}
                                onCheck={(e) =>
                                  updateRoleField(
                                    permission.db_field,
                                    e.target.checked
                                  )
                                }
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <FlexRow justify={"center"} style={{ marginTop: "2rem" }}>
              {" "}
              <Button width={100} onClick={onClose}>
                Cancel
              </Button>{" "}
              <Button shadow blue width={100} onClick={createNewRole}>
                Create
              </Button>
            </FlexRow>
          </>
        )}
        {creating && !error && (
          <FlexCol style={{ width: "900px" }} gap="2rem">
            <Player
              autoplay
              loop
              src={require("assets/animations/Gears.json")}
              style={{
                height: "120px",
                // width: "3.5rem",
              }}
            />
            Creating Role...
          </FlexCol>
        )}
        {error}
      </div>
    </div>
  );
}

export default CreateRole;
