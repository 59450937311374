import { useState } from "react";
import Content from "./Content/Content";
import styles from "./AlertEmailBuilder.module.scss";
import AlertEmailEditor from "./AlertEmailEditor";
import { From } from "./From/From";

export function AlertEmailBuilder({ alert, customFields, changeField }) {
  const [content, setContent] = useState(null);
  const [subject, setSubject] = useState(alert.subject);

  // TODO: Add text / SMS option too

  return (
    <div className={styles.container}>
      <div className={styles.config}>
        <div className={styles.label}>From:</div>
        <From alert={alert} changeField={changeField} />
      </div>
      <div className={styles.config}>
        <div className={styles.label}>Subject:</div>
        <input
          className={styles.subject}
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          onBlur={(e) => changeField("subject", e.target.value)}
          placeholder={"No Subject"}
        ></input>
      </div>
      <div className={styles.emailContent}>
        <Content content={content} setContent={setContent} />
        <AlertEmailEditor
          encoding={alert.encoding}
          customFields={customFields}
          onSave={(text, encoding) => changeField("encoding", encoding)}
          incoming={content}
        />
      </div>
    </div>
  );
}
