/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import { gql } from "graphql-request";

// Internal
import { useGqlMutation, useGqlQuery } from "api/Api";
import { useQueryClient } from "react-query";

export const useFetchMyLastSeenReports = (limit) => {
  const query = gql`
    query {
      getLastSeenReports(limit: ${limit}) {
        content
        id
        name
      }
    }
  `;

  return useGqlQuery([limit + "recentReports"], query, {});
};

export const useFetchUserReportRole = (reportId) => {
  const query = gql`
    query reportUserRole($reportId: String!) {
      canEdit: reportUserRole(reportId: $reportId)
    }
  `;

  return useGqlQuery(["userReportRole: " + reportId], query, {
    reportId: reportId,
  });
};

export const useFetchReportByOrg = (
  searchString,
  page,
  perPage,
  sort,
  viewing
) => {
  const query = gql`
    query ReportByOrgId(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortReport!
      $viewing: ViewingContainerR!
    ) {
      reports: reportByOrgId(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
        viewing: $viewing
      ) {
        id
        name
        status
        content
        createdAt
        type
        updatedAt
        numPages
        numVizualizations
        organizationId
        userId
        icon
        analysis {
          visualization {
            id
          }
        }
        user {
          firstName
          lastName
        }
      }
    }
  `;

  return useGqlQuery(["reportOrganization"], query, {
    search: searchString,
    page: page,
    perPage: perPage,
    sort: sort,
    viewing: { viewing: viewing },
  });
};

export const useFetchReportByExclusiveId = (
  searchString,
  page,
  perPage,
  sort,
  type
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortReport!
      $type: String!
    ) {
      reports: reportByExclusiveId(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
        type: $type
      ) {
        id
        name
        updatedAt
        type
        userId
        icon
        numVizualizations
        numPages
        pageOrder
        draft
        draftId
        sharedReport {
          organizationId
          roleId
          userId
        }
        user {
          firstName
          lastName
        }
        organization {
          name
          id
        }
      }
    }
  `;

  return useGqlQuery(
    ["reports", "reportExclusive:" + type],
    query,
    {
      search: searchString,
      page: page,
      perPage: perPage,
      sort: sort,
      type: type,
    }
  );
};

export const useFetchReportByExclusiveIdCount = (
  searchString,
  page,
  perPage,
  sort,
  type
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortReport!
      $type: String!
    ) {
      count: reportByOrgExclusiveIdCount(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
        type: $type
      )
    }
  `;

  return useGqlQuery(["reportExclusiveCount:" + type, searchString], query, {
    search: searchString,
    page: page,
    perPage: perPage,
    sort: sort,
    type: type,
  });
};

export const useFetchReportLinkInfo = (id) => {
  const query = gql`
    query  {
      report: reportLinkInfo(id: "${id}") {
        id
        name
        draft
        draftId
        updatedAt
        status
        type
        icon
        sharedReport {
          organizationId
        }
        analysis {
          id
          visualization {
            id
          }
        }
      }
    }
  `;

  return useGqlQuery(["report link info: " + id], query);
};

export const useSearchReports = (search) => {
  const query = gql`
    query SearchReportsForLinks($search: String!) {
      reports: searchReportsForLinks(search: $search) {
        id
        name
        draft
        draftId
        updatedAt
        status
        type
        icon
        sharedReport {
          organizationId
        }
        analysis {
          id
          visualization {
            id
          }
        }
      }
    }
  `;

  return useGqlQuery(["search reports: " + search], query, {
    search: search,
  });
};

export const useFetchReport = (id, doneLoading) => {
  const query = gql`
    query ReportById($id: String!) {
      report: reportById(id: $id) {
        id
        name
        status
        type
        pageOrder
        userId
        organizationId
        content
        numPages
        numVizualizations
        icon
        updatedAt
        draft
        draftId
        analysis {
          id
          name
        }
        project {
          id
          name
          startedAt
        }
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: (data) => {
      queryClient.invalidateQueries("analysis");
      // console.log(doneLoading);
      if (doneLoading) {
        doneLoading(data);
      }
    },
  };

  return useGqlQuery(["report", id], query, { id: id }, options);
};

export const useFetchReportDraft = (id) => {
  const query = gql`
    query {
      report: fetchReportDraft(id: "${id}") {
        id
        name
        status
        type
        pageOrder
        userId
        organizationId
        content
        numPages
        numVizualizations
        icon
        updatedAt
        draftId
        draft
        analysis {
          id
          name
          layout
        }
        project {
          id
          name
          startedAt
        }
      }
    }
  `;

  return useGqlQuery(["report-draft", id], query);
};

export const useFetchReportUserInfo = (id) => {
  const query = gql`
    query  {
      report: reportUserInfo(id: "${id}") {
        id
        name
        userId
        user {
          firstName
          lastName
          email
          image {
            imageURL
          }
        }
      }
    }
  `;

  return useGqlQuery(["report user info: " + id], query);
};

export const useGetUnIncludedReports = (except) => {
  const query = gql`
    query AllReportsNotInList($except: ReportIdList!) {
      allReportsNotInList(except: $except) {
        id
        name
        content
        updatedAt
        organizationId
        user {
          id
          firstName
          lastName
        }
      }
    }
  `;

  return useGqlQuery(["ReportsExcept " + except.list], query, {
    except: except,
  });
};

export const useCreateReport = () => {
  const mutation = gql`
    mutation CopyReport($projectIds: ProjectIds, $name: String!) {
      createReport(projectIds: $projectIds, name: $name) {
        id
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("analyze");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateReport = () => {
  const mutation = gql`
    mutation UpdateReport($id: String!, $data: UpdateReportInput!) {
      updateReport(id: $id, data: $data) {
        id
        name
        status
        pageOrder
        userId
        organizationId
        updatedAt
        content
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("report");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useCopyReport = () => {
  const mutation = gql`
    mutation CopyReport($id: String!, $name: String) {
      copyReport(id: $id, name: $name) {
        id
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("report");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDeleteReport = () => {
  const mutation = gql`
    mutation DeleteReport($id: String!) {
      deleteReport(id: $id)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.removeQueries("reports");
      queryClient.removeQueries("report-draft");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDeleteReportDraft = () => {
  const mutation = gql`
    mutation Mutation($id: String!) {
      discardDraft(id: $id)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.removeQueries("reports");
      queryClient.removeQueries("report-draft");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateTiedSurveys = () => {
  const mutation = gql`
    mutation UpdateTiedSurveys($projectIds: ProjectIds!, $reportId: String!) {
      updateTiedSurveys(projectIds: $projectIds, reportId: $reportId)
    }
  `;

  return useGqlMutation(mutation);
};

export const usePublishDraft = () => {
  const mutation = gql`
    mutation PublishReportDraft($id: String!) {
      publishReportDraft(id: $id)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: (data) => {
      queryClient.removeQueries("report");
      queryClient.removeQueries("report-draft");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateSharedReport = () => {
  const mutation = gql`
    mutation Mutation($id: String!, $data: updateSharedReportInput!) {
      updateSharedReport(id: $id, data: $data)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("report");
    },
  };

  return useGqlMutation(mutation, options);
};
