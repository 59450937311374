import React, { useEffect, useState, useRef } from "react";

import styles from "./Tasks.module.scss";
import {
  useSearchTasks,
  useCancelTask,
  useUpdateTask,
  useCancelAllTasks,
} from "api/resources/account/notification";
import { Loading } from "components/Loading/Loading";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { trimDate } from "assets/functions/DateFunctions";
import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import { useQueryClient } from "react-query";
import { TRIGGER } from "api/resources/subscription/subscribe";
import { useSubscription } from "@apollo/client";

export function Tasks({ user }) {
  const [perPage, setPerpage] = useState(20);
  const [sort, setSort] = useState({
    item: "createdAt",
    descend: true,
  });

  const taskSubscription = useSubscription(TRIGGER, {
    variables: { userId: user?.id },
  });

  const fetchTasks = useSearchTasks(sort, "", perPage, false);

  const data =
    fetchTasks?.data?.pages.flatMap((page) => page?.response?.tasks) || [];

  const count =
    fetchTasks?.data?.pages?.length > 0
      ? fetchTasks?.data?.pages[0]?.response?.count
      : 0;

  useEffect(() => {
    if (taskSubscription.data?.response) {
      fetchTasks.refetch();
    }
    return () => {
      fetchTasks.remove();
    };
  }, [taskSubscription.data]);

  function handleRemove() {
    fetchTasks.remove();
    fetchTasks.refetch();
  }

  const scrollableRef = useRef(null);

  const onBottomReached = () => {
    fetchTasks.fetchNextPage();
  };

  useEffect(() => {
    const handleScroll = () => {
      const element = scrollableRef.current;
      if (!element) return;

      // Check if the user scrolled to the bottom
      if (
        element.scrollHeight - element.scrollTop <=
        element.clientHeight + 1
      ) {
        onBottomReached(); // Call function when bottom is reached
      }
    };

    const element = scrollableRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [onBottomReached]);

  const cancelall = useCancelAllTasks();

  function handleCancelAll() {
    cancelall.mutate(
      {},
      {
        onSuccess: () => {
          fetchTasks.remove();
          fetchTasks.refetch();
          const queryClient = useQueryClient();
          queryClient.removeQueries(["Search Tasks"], { exact: true });
        },
      }
    );
  }

  console.log(fetchTasks)
  return (
    <div className={styles.page} ref={scrollableRef}>
      <div className={styles.header}>
        <FlexCol gap={".5rem"}>
          <FlexRow gap=".5rem">
            <Icon iconName="boxes" blue></Icon>
            <h3>Running Jobs</h3>{" "}
            {count > 0 && (
              <small>
                {count} job{count === 1 ? "" : "s"} running
              </small>
            )}
          </FlexRow>
          {count > 0 && (
            <div className={styles.runningprocess}>
              <div className={styles.runningbar}></div>
            </div>
          )}
        </FlexCol>
      </div>

      <div className={styles.notifBlock}>
        {data?.map((notification) => (
          <Notification
            key={notification.id}
            notification={notification}
            onRemove={handleRemove}
          ></Notification>
        ))}
        {fetchTasks.isLoading && (
          <div className={styles.pageNotifContainer}>
            <Loading></Loading>
          </div>
        )}

        {fetchTasks.isSuccess && data?.length === 0 && (
          <div className={styles.none}>No jobs running</div>
        )}
      </div>

      <div className={styles.footer}>
        <Button shadow onClick={handleCancelAll} blue>
          Cancel All Jobs
        </Button>
        {/* <Button shadow blue onClick={() => navigate("/notifications")}>
          View all
        </Button> */}
      </div>
    </div>
  );
}

const Notification = ({ notification, onRemove }) => {
  const [options, setOptions] = useState(false);

  const ref2 = useRef(null);

  function clickOutside(e) {
    if (ref2.current && !ref2.current?.contains(e.target)) {
      setOptions(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const cancelTask = useCancelTask();

  function handleCancel(notif) {
    cancelTask.mutate(
      {
        id: notif?.id,
      },
      {
        onSuccess: () => {
          onRemove();
        },
      }
    );
  }

  const updateTask = useUpdateTask();
  function hideTask(id) {
    updateTask.mutate({
      data: {
        hide: false,
      },
      id: id,
    });
  }

  return (
    <div
      className={styles.notificationContainer}
      // onClick={() => onNotifClick(notification.id)}
      style={{ backgroundColor: options ? "#E9E9E9" : "" }}
    >
      {/* <div className={styles.player}> */}
      {/* {notification.progress != 100 && (
          // <Player
          //   autoplay
          //   loop
          //   onEvent={(e) => {
          //     // if (e === "play") {
          //     //   setTimeout(() => {
          //     //     player1?.current?.pause();
          //     //   }, 2000);
          //     // }
          //   }}
          //   // ref={player1}
          //   src={getType(notification)}
          //   style={getStyle(notification)}
          // />
          <div className={styles.runningprocess}>
            <div className={styles.runningbar}></div>
          </div>
        )} */}
      {/* {notification.progress === 100 && (
          <Icon iconName="check" style={{ fontSize: "2rem" }} green></Icon>
        )} */}
      {/* </div> */}
      <div className={styles.notification}>
        <div
          className={styles.notifBody}
          style={{ fontSize: ".9em", fontWeight: "500" }}
        >
          {notification.body}
        </div>
        <div className={styles.notifBody}>
          <div>Progress:</div> {notification?.progress}%
        </div>
        <div className={styles.notifBody}>
          <div>Started:</div> {trimDate(notification.createdAt, true)}
        </div>
        <div
          className={styles.navContainer}
          style={{ minHeight: "auto", padding: "0px", alignItems: "center" }}
        ></div>
      </div>

      <i
        className={`bi-three-dots-vertical ${styles.threeDots}`}
        onClick={(e) => {
          e.stopPropagation();
          setOptions(true);
        }}
      ></i>

      {options && (
        <div className={styles.settingsBox} ref={ref2}>
          {/* <div
            className={styles.menuItem}
            onClick={(e) => {
              e.stopPropagation();
              handleClick(notification);
            }}
          >
            <i
              className="bi-layout-text-sidebar-reverse"
              style={{ color: "#A7D5C5" }}
            ></i>{" "}
            View Details
          </div> */}
          {notification.hide && (
            <div
              className={styles.menuItem}
              onClick={(e) => {
                e.stopPropagation();
                hideTask(notification?.id);
              }}
            >
              <i className="bi-app-indicator" style={{ color: "#A7D5C5" }}></i>{" "}
              Show as banner
            </div>
          )}

          <div
            className={styles.menuItem}
            onClick={(e) => {
              e.stopPropagation();
              handleCancel(notification);
            }}
          >
            <i className="bi-x-circle" style={{ color: "#FF8878" }}></i> Cancel
          </div>
        </div>
      )}
    </div>
  );
};
