/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson, brodyspencer

*/

import React, { useEffect, useState, useRef } from "react";
import styles from "./QuestionConstruction.module.scss";
import Button from "components/Button/Button";
import {
  useCreateQuestionGql,
  useDeletePageGql,
  useDeleteQuestion,
  useMovePages,
  useUpdateQuestionGql,
} from "api/resources/projects/questions";
import { shortId } from "components/tables/EditableTable/utils";
import { useParams } from "react-router-dom";
import { useUpdateProject } from "api/resources/projects/projects";
import { SurveyDesign } from "./SurveyDesign/SurveyDesign";
import Survey from "components/Survey/Survey";
import {
  useCreateSectionGql,
  useDeleteSectionGql,
  useUpdateSectionGql,
} from "api/resources/projects/sections";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  closestCorners,
  DragOverlay,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Loading } from "components/Loading/Loading";
import EditableNavigation from "./EditableNavigation/EditableNavigation";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import EditLogo from "./EditableLogo/EditLogo";
import EditableProgressBar from "./EditableProgressBar/EditableProgressBar";
import SettleLogic from "./QuestionLogic/SettleLogic";
import SurveyComponent from "./SurveyComponent/SurveyComponent";
import { forEach, getSortedArray } from "assets/functions/ArrayFunctions";
import { createEncodingCopy } from "components/inputs/input_fields/TextEditorTry/TextEditorTry";
import Stat from "components/tables/BasicTable/SurveyWidgets/Stat";
import { Pie } from "components/inputs/input_fields/CircleProgress/Circle";
import {
  calculateQuestionHealth,
  calculateSurveyHealth,
} from "assets/functions/SurveyHealth";
import SurveyCompletedInConstruction from "./Finished/SurveyCompleted";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";
import { trimDate } from "assets/functions/DateFunctions";
import SurveyLogic from "./QuestionLogic/SurveyLogic";
import { isConditionComplete } from "assets/functions/ObjectFunctions";
import { ErrorBanner } from "pages";

const sectionSettings = {
  bodySize: 14,
  bodyColor: "#616565",
  showBody: true,
  bodyItalics: true,
  headerSize: 22,
  headerColor: "#616565",
  showHeader: true,
  headerItalics: false,
  sectionShadow: "",
};

const imageSettings = {
  sectionShadow: "",
  fill: true,
  width: "",
  align: "center",
};

/**
 * A component that when passed a list of questions will display them and let you edit them
 * @param {Array} initQuestions used to handle clicking outside of the popout to close it
 * @returns {React.ReactElement} a QuestionConstruction component
 */
export function QuestionConstruction({
  initFinishedMessage,
  maxPage,
  setMaxPage,
  status,
  roles,
  canEdit,
  designSettings,
  setDesignSettings,
  currPage,
  setCurrPage,
  questions,
  setQuestions,
  allQuestions,
  setAllQuestions,
  refetchQs,
  refetchDSettings,
  showLoading,
  setShowLoading,
  projectId,
  survey,
  setFullscreen,
  fullscreen,
  pages,
  setPages,
  collapse,
}) {
  const [activeId, setActiveId] = useState("");
  const [activeQ, setActiveQ] = useState("");

  const { id } = useParams();
  const [savingStatus, setSavingStatus] = useState(null);
  const [finishedMsg, setFinishedMsg] = useState(initFinishedMessage);
  const [seeDesignSettings, setSeeDesignSettings] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(survey?.updatedAt);
  const [answers, setAnswers] = useState({});
  const [snapShot, setSnapShot] = useState();
  const [dependencies, setDependencies] = useState([]);
  const [dependencyResolver, setDependencyResolver] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const [active, setActive] = useState("");
  const [showLogic, setShowLogic] = useState(false);
  const [globalCounter, setGlobalCounter] = useState(0);

  const [previewMode, setPreviewMode] = useState("Desktop");
  const [save, setSave] = useState(false);

  useEffect(() => {
    if (save) {
      saveQuestions();
      setSave(false);
    }
  }, [save]);

  function updateActive(val) {
    setActive(val);
    collapse(val ? true : false);
  }

  const saveQuestion = useUpdateQuestionGql();
  const createQuestion = useCreateQuestionGql();
  const deleteQuestion = useDeleteQuestion();
  const deleteSection = useDeleteSectionGql();
  const deletePage = useDeletePageGql();
  const updateSettings = useUpdateProject();
  const createSection = useCreateSectionGql();
  const updateSection = useUpdateSectionGql();
  const movePage = useMovePages();

  const newRef = useRef(null);

  useEffect(() => {
    setUpDependencies();
  }, [allQuestions]);

  function setUpDependencies() {
    let dependentRecord = [];

    for (let q of allQuestions) {
      if (
        q.type !== "Description" &&
        q?.conditionGroups &&
        q.conditionGroups.length
      ) {
        for (let group of q.conditionGroups) {
          if (group?.operator && group?.conditions) {
            for (let condition of group.conditions) {
              if (!isConditionComplete(condition)) continue;

              let record = dependentRecord.find(
                (d) => d.questionId === condition.questionId
              );
              if (!record) {
                // Create a dependency record on this question
                record = {
                  questionId: condition.questionId,
                  dependencies: [],
                };
                dependentRecord.push(record);
              }

              // Keep the record of each dependent question contained in one, in other words every dependency is a seperate question
              let subRecord = record.dependencies.find(
                (d) => d.dependentId === q.id
              );
              if (!subRecord) {
                subRecord = {
                  dependentId: q.id,
                  dependentName: q.questionText,
                };
                record.dependencies.push(subRecord);
              }

              const dependsOn = allQuestions.find(
                (q) => q.id === condition.questionId
              );

              if (!dependsOn) {
                continue;
              }

              if (
                condition.operator === "answer" ||
                condition.operator === "do not answer"
              ) {
                if (subRecord.keep) {
                  subRecord.keep = [...subRecord.keep, ...condition.answer];
                } else {
                  subRecord.keep = condition.answer;
                }
              } else if (
                dependsOn.type === "NumberScale" ||
                dependsOn.type === "NumberSlider"
              ) {
                let keep = [];

                if (condition.operator === "between") {
                  keep = getSortedArray(condition.answer, (a, b) => a - b);
                } else if (
                  condition.operator === "above" ||
                  condition.operator === "below"
                ) {
                  keep.push(condition.answer);
                }

                if (subRecord.keep) {
                  subRecord.keep = [...subRecord.keep, ...keep];
                } else {
                  subRecord.keep = keep;
                }
              } else if (
                dependsOn.type === "Text" &&
                condition.operator === "is longer than"
              ) {
                if (
                  !("keepLimitAbove" in subRecord) ||
                  subRecord.keepLimitAbove < condition.answer
                ) {
                  subRecord.keepLimitAbove = condition.answer;
                }
              }

              if (dependsOn.type === "Matrix") {
                if (subRecord.keepOptions) {
                  subRecord.keepOptions = [
                    ...subRecord.keepOptions,
                    condition.option,
                  ];
                } else {
                  subRecord.keepOptions = [condition.option];
                }
              }
            }
          }
        }
      }
    }
    setDependencies(dependentRecord);
  }

  function clearsBaselineLogic(question) {
    // Checks to see if there are any dependencies. Returns false if so. For operations like delete, moving below, and changing the quesion type
    // Not to check specific changes in the question
    let dependents = dependencies.find((d) => d.questionId === question.id);
    if (dependents) {
      return false;
    }
    return true;
  }

  function editFinishMessage(msg) {
    setFinishedMsg(msg);
    setSavingStatus(<Loading height={50} width={50}></Loading>);

    updateSettings.mutate(
      {
        updateProjectId: id,
        data: {
          finishedMessage: msg,
        },
      },
      {
        onSuccess: (data) => {
          setUpdatedAt(data.response.updatedAt);
          fixSaveStatus();
        },
      }
    );
  }

  function setUpLogicResolver(areYouSure, action, question, onContinue) {
    setDependencyResolver(
      <SettleLogic
        areYouSure={areYouSure}
        action={action}
        question={question}
        onCarryThrough={() => {
          onContinue();
          setDependencyResolver(null);
          setSave(true);
        }}
        onNevermind={() => {
          setDependencyResolver(null);
        }}
        dependencies={dependencies}
        allQuestions={allQuestions}
        saveQuestions={onEditAllQs}
      />
    );
  }

  function onEditQ(question) {
    let index = questions.findIndex((item) => item.id === question.id);
    let allIndex = allQuestions.findIndex((q) => q.id === question.id);
    let pageInd = pages[currPage - 1].findIndex((q) => q.id == question.id);

    let tempQs = [...questions];
    let tempAll = [...allQuestions];
    let tempPages = [...pages];

    tempQs[index] = { ...question };
    tempAll[allIndex] = { ...question };
    tempPages[currPage - 1][pageInd] = { ...question };

    tempAll[allIndex].changed = true;

    setQuestions(tempQs);
    setAllQuestions(tempAll);
    setPages(tempPages);

    if (activeQ) {
      let q = tempQs.find((qst) => qst.id === activeQ.id);
      if (q) setActiveQ(q);
    }

    saveIndividualItem(tempAll[allIndex], allIndex);
  }

  function onUpdate() {
    setSavingStatus(<Loading height={50} width={50}></Loading>);

    updateSettings.mutate(
      {
        updateProjectId: id,
        data: {
          updatedAt: new Date(),
        },
      },
      {
        onSuccess: (data) => {
          setUpdatedAt(data.response.updatedAt);
          fixSaveStatus();
        },
      }
    );
  }

  function saveIndividualItem(item, quesitonNum) {
    if (item?.changed || item?.new) {
      setSavingStatus(<Loading height={50} width={50}></Loading>);

      updateSettings.mutate(
        {
          updateProjectId: id,
          data: {
            updatedAt: new Date(),
          },
        },
        {
          onSuccess: (data) => {
            setUpdatedAt(data.response.updatedAt);
          },
        }
      );
      delete item.changed;
      if ("questionText" in item) {
        let copy = { ...item, index: quesitonNum };
        copy.conditionGroups = item?.conditionGroups
          ? JSON.stringify(item.conditionGroups)
          : null;
        copy.triggers = item?.triggers
          ? JSON.stringify(item.triggers)
          : undefined;

        copy.buckets =
          item?.buckets && typeof item.buckets !== "string"
            ? JSON.stringify(item.buckets)
            : item.buckets;

        if (copy.choiceQuestion) {
          delete copy.choiceQuestion.id;
        }
        if (copy.textQuestion) {
          copy.textQuestion = { ...copy.textQuestion };
          delete copy.textQuestion.id;
          delete copy.textQuestion.bucket; // Taken care of in Buckets.jsx
        }
        if (copy.scaleQuestion) {
          delete copy.scaleQuestion.id;
        }
        if (copy.matrixQuestion) {
          copy.matrixQuestion = JSON.parse(JSON.stringify(copy.matrixQuestion)); // don't alter the current state
          copy.matrixQuestion.settings = JSON.stringify(
            copy.matrixQuestion.settings
          );
          delete copy.matrixQuestion.id;
        }

        delete copy.id;
        if (copy?.answer) {
          delete copy.answer;
        }
        delete copy.questionTag; // Taken care of in EditTags.jsx
        delete copy.alerts;

        if (copy?.new) {
          delete copy.new;
          createQuestion.mutate(
            {
              data: copy,
            },
            {
              onSuccess: (data) => {
                fixSaveStatus();
                refetchQs();
                onNewQ(data);
              },
            }
          );
        } else {
          saveQuestion.mutate(
            {
              id: item.id,
              data: copy,
            },
            {
              onSuccess: (data) => {
                fixSaveStatus();
              },
            }
          );
        }
      } else {
        // It's a section
        let copy = { ...item };

        copy.sectionSettings = JSON.stringify(item.sectionSettings);
        copy.conditionGroups = item?.conditionGroups
          ? JSON.stringify(item.conditionGroups)
          : undefined;
        delete copy.id;
        delete copy.image;

        if (copy?.new) {
          delete copy.new;

          createSection.mutate(
            {
              data: copy,
            },
            {
              onSuccess: (data) => {
                fixSaveStatus();
                refetchQs();
                onNewQ(data);
              },
            }
          );
        } else {
          updateSection.mutate(
            {
              id: item.id,
              data: copy,
            },
            {
              onSuccess: (data) => {
                fixSaveStatus();
              },
            }
          );
        }
      }
    }
  }

  function onEditAllQs(copyOfAll) {
    let page = [];
    for (let one of copyOfAll) {
      if (one.pageNumber == currPage) {
        page.push(one);
      }
    }
    setQuestions(page);
    setAllQuestions(copyOfAll);

    let pages = [[]];
    let pageInd = 1;
    for (let q of copyOfAll) {
      if (q.pageNumber !== pageInd) {
        pageInd++;
        pages.push([q]);
      } else {
        pages[pages.length - 1].push(q);
      }
    }
    setPages(pages);

    setSave(true);
  }

  function onEditQs(qArray) {
    let copyOfAll = [...allQuestions];
    for (let q of qArray) {
      q.changed = true;
      let ind = copyOfAll.findIndex((qst) => q.id === qst.id);
      if (ind > -1) copyOfAll[ind] = q;
    }

    onEditAllQs(copyOfAll);
  }

  function deleteQ(question, e) {
    if (
      window.confirm(
        `Are you sure you want to delete this ${
          "questionText" in question ? "question" : "text"
        }?`
      )
    ) {
      if (!("questionText" in question) || clearsBaselineLogic(question)) {
        finishDeleteQ(question, e);
      } else {
        setDependencyResolver(
          <SettleLogic
            areYouSure={"delete"}
            action={"delete"}
            question={question}
            onCarryThrough={() => {
              finishDeleteQ(question, e);
              setDependencyResolver(null);
            }}
            onNevermind={() => {
              setDependencyResolver(null);
            }}
            dependencies={dependencies}
            allQuestions={allQuestions}
            saveQuestions={onEditAllQs}
          />
        );

        if (!activeId || activeId !== question.id) {
          e.stopPropagation(); // don't run the onQuestionClick
        }
      }
    }
  }

  function finishDeleteQ(question, e, update) {
    setSavingStatus(<Loading height={50} width={50}></Loading>);
    let index = questions.findIndex((q) => q.id === question.id);
    let tempAll = [...allQuestions];
    let allInd = allQuestions.findIndex((q) => q.id === question.id);
    tempAll.splice(allInd, 1);

    let thisPage = [];
    let questionNumber = 1;
    for (let i = 0; i < tempAll.length; i++) {
      let q = tempAll[i];
      if ("questionText" in q) {
        q.index = questionNumber;
        q.changed = true;
        questionNumber += 1;
      }
      if (q.pageNumber === question.pageNumber) {
        if (q.pageOrderIndex > question.pageOrderIndex) {
          q.pageOrderIndex--;
          q.changed = true;
        }
        thisPage.push(q);
      }
    }
    let pages2 = [];
    for (let i = 0; i < maxPage; i++) {
      pages2.push([]);
    }
    for (let q of tempAll) {
      pages2[q?.pageNumber - 1].push(q);
    }

    setPages(pages2);
    setQuestions(thisPage);
    setAllQuestions(tempAll);

    if (activeId === question.id) {
      setActiveId("");
      updateActive("");
      setActiveQ(null);
    } else if (activeId) {
      // while active on a different Q
      e.stopPropagation(); // Don't let the onQuestionClick function run
    }

    if (!question?.new) {
      // It decrements the others' pageOrderIndex if needed in the backend as well.
      if (question.type === "Description" || question.type === "Image") {
        deleteSection.mutate(
          {
            id: question.id,
          },
          {
            onSuccess: () => {},
          }
        );
      } else {
        deleteQuestion.mutate(
          {
            id: question.id,
          },
          {
            onSuccess: () => {
              setSavingStatus(null);
            },
          }
        );
      }
    }

    setSave(true);
  }

  function getAppendIndeces() {
    let pageNumber = 0;
    let pageOrderIndex = 0;
    pageNumber = pages.length;
    pageOrderIndex = pages[pages.length - 1].length;

    return [pageOrderIndex, pageNumber];
  }

  function appendQ() {
    let [index, page] = getAppendIndeces();
    handleCreateQuestion(index, page);
  }

  function appendSection() {
    let [index, page] = getAppendIndeces();
    handleCreateText(index, page);
  }

  function handleCreateQuestion(index, page) {
    let tempId = "temporaryid:" + shortId();
    const question = {
      id: tempId,
      type: "MultipleChoice",
      projectId: id,
      pageOrderIndex: index,
      pageNumber: page ? page : currPage,
      isHidden: false,
      description: "",
      buckets: [],
      questionTag: [],
      alerts: [],
      hasBuckets: false,
      new: true,
      choiceQuestion: {
        isMultiSelect: false,
        isRandomized: false,
        hasOtherOption: false,
        otherOptionLabel: "Other",
        choices: [],
        isRanking: false,
      },
      questionText: "New Question",
      encoding: "",
      descriptionEncoding: "",
    };
    addQToState(question, page ? page : currPage);
  }

  function handleCreateText(index, page) {
    let tempId = "temporarySectionId:" + shortId();
    const section = {
      id: tempId,
      type: "Description",
      projectId: id,
      pageOrderIndex: index,
      new: true,
      pageNumber: page ? page : currPage,
      sectionSettings: sectionSettings,
      bodyText: null,
      encoding: null,
    };
    addQToState(section, page ? page : currPage);
  }

  function handleCreateImage(index, page) {
    let tempId = "temporarySectionId:" + shortId();
    const section = {
      id: tempId,
      type: "Image",
      projectId: id,
      pageOrderIndex: index,
      new: true,
      pageNumber: page ? page : currPage,
      sectionSettings: imageSettings,
      bodyText: null,
      encoding: null,
    };
    addQToState(section, page ? page : currPage);
  }

  function addQToState(question, page) {
    setSavingStatus(<Loading height={50} width={50}></Loading>);
    let tempAll = [...allQuestions];
    let ind = 0;

    for (let i = 0; i < allQuestions.length; i++) {
      if (allQuestions[i].pageNumber === page) {
        ind += question.pageOrderIndex;
        break;
      }
      ind++;
    }

    newRef.current = ind;

    tempAll.splice(ind, 0, question);

    let questionNumber = 1;
    let thisPage = [];
    let curPage = 1;
    let pageIndex = 1;
    for (let i = 0; i < tempAll.length; i++) {
      let q = tempAll[i];
      if ("questionText" in q || q?.questionText === "") {
        q.index = questionNumber;
        q.changed = true;
        questionNumber += 1;
      }
      if (curPage != q?.pageNumber) {
        curPage++;
        pageIndex = 1;
      }
      if (q.pageOrderIndex != pageIndex) {
        q.pageOrderIndex = pageIndex;
        q.changed = true;
      }
      pageIndex++;
    }

    let pages2 = [];
    for (let i = 0; i < maxPage; i++) {
      pages2.push([]);
    }
    for (let q of tempAll) {
      pages2[q?.pageNumber - 1].push(q);
    }

    setPages(pages2);
    setAllQuestions(tempAll);
    setQuestions(thisPage);
    setSave(true);
  }

  function addPage() {
    updateSettings.mutate(
      {
        updateProjectId: id,
        data: { numPages: maxPage + 1 },
      },
      {
        onSuccess: () => {
          setMaxPage((page) => page + 1);
          setCurrPage(maxPage + 1);
          setActiveId("");
        },
      }
    );
  }

  function saveQuestions() {
    let quesitonNum = 1;

    for (let item of allQuestions) {
      if (item?.changed || item?.new) {
        delete item.changed;
        if ("questionText" in item) {
          let copy = { ...item, index: quesitonNum };
          copy.conditionGroups = item?.conditionGroups
            ? JSON.stringify(item.conditionGroups)
            : null;
          copy.triggers = item?.triggers
            ? JSON.stringify(item.triggers)
            : undefined;

          copy.buckets =
            item?.buckets && typeof item.buckets !== "string"
              ? JSON.stringify(item.buckets)
              : item.buckets;

          if (copy.choiceQuestion) {
            delete copy.choiceQuestion.id;
          }
          if (copy.textQuestion) {
            copy.textQuestion = { ...copy.textQuestion };
            delete copy.textQuestion.id;
            delete copy.textQuestion.bucket; // Taken care of in Buckets.jsx
          }
          if (copy.scaleQuestion) {
            delete copy.scaleQuestion.id;
          }
          if (copy.matrixQuestion) {
            copy.matrixQuestion = JSON.parse(
              JSON.stringify(copy.matrixQuestion)
            ); // don't alter the current state
            copy.matrixQuestion.settings = JSON.stringify(
              copy.matrixQuestion.settings
            );
            delete copy.matrixQuestion.id;
          }

          delete copy.id;
          if (copy?.answer) {
            delete copy.answer;
          }
          delete copy.questionTag; // Taken care of in EditTags.jsx
          delete copy.alerts; // Taken care of in Triggers.jsx

          if (copy?.new) {
            delete copy.new;
            createQuestion.mutate(
              {
                data: copy,
              },
              {
                onSuccess: (data) => {
                  refetchQs();
                  onNewQ(data);
                  fixSaveStatus();
                },
              }
            );
          } else {
            saveQuestion.mutate(
              {
                id: item.id,
                data: copy,
              },
              {
                onSuccess: (data) => {
                  refetchQs();
                  fixSaveStatus();
                },
              }
            );
          }
        } else {
          // It's a section
          let copy = { ...item };

          copy.sectionSettings = JSON.stringify(item.sectionSettings);
          copy.conditionGroups = item?.conditionGroups
            ? JSON.stringify(item.conditionGroups)
            : undefined;
          delete copy.id;
          delete copy.image;

          if (copy?.new) {
            delete copy.new;

            createSection.mutate(
              {
                data: copy,
              },
              {
                onSuccess: (data) => {
                  refetchQs();
                  onNewQ(data);
                  fixSaveStatus();
                },
              }
            );
          } else {
            updateSection.mutate(
              {
                id: item.id,
                data: copy,
              },
              {
                onSuccess: (data) => {
                  refetchQs();
                  fixSaveStatus();
                },
              }
            );
          }
        }
      }
      if ("questionText" in item) {
        quesitonNum += 1;
      }
    }
  }

  const scrollableRef = useRef(null);

  function onNewQ(data) {
    setTimeout(() => {
      if (scrollableRef.current) {
        let el = scrollableRef.current;
        if (el.scrollHeight > el.clientHeight) {
          let newQ = document.getElementById(
            "survey component: " + data.response.id
          );
          if (newQ) {
            newQ.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }
      }

      let text = document.getElementById("text for " + data.response.id);
      if (text) {
        text.focus();
      }

      if (newRef.current !== null) {
        let question = allQuestions[newRef.current];
        if (question) {
          question.id = data.response.id;
          delete question.new;
          setUpActiveQ(question);
        }
      }
      newRef.current = null;
    }, 500);
  }

  function saveDesign(settings) {
    setSavingStatus(<Loading height={50} width={50}></Loading>);

    setDesignSettings(settings);
    let json =
      typeof settings === "string" ? settings : JSON.stringify(settings);

    updateSettings.mutate(
      {
        updateProjectId: id,
        data: {
          designSettings: json,
          surveyInstruction: "",
          finishedMessage: finishedMsg,
        },
      },
      {
        onSuccess: (data) => {
          setUpdatedAt(data.response.updatedAt);
          fixSaveStatus();
        },
      }
    );
  }

  function fixSaveStatus() {
    setSavingStatus(null);
  }

  function switchPages(activeInd, overInd) {
    if (dependencies.length) {
      let activeQs = [];
      forEach(allQuestions, (q) => {
        if (q.pageNumber == activeInd) {
          activeQs.push(q);
        }
      });

      if (activeInd < overInd) {
        // moving down
        // Can break other's
        // focus on active page's
        let dependedOn_WillBreak = [];
        for (let q of activeQs) {
          let dependents = dependencies.find((d) => d.questionId === q.id);
          if (dependents) {
            for (let dependent of dependents.dependencies) {
              let depQ = allQuestions.find(
                (que) => que.id === dependent.dependentId
              );
              if (depQ.pageNumber > activeInd && depQ.pageNumber <= overInd) {
                // they will now be behind it.
                dependedOn_WillBreak.push(q);
                break;
              }
            }
          }
        }

        if (dependedOn_WillBreak.length) {
          setDependencyResolver(
            <SettleLogic
              areYouSure={"move"}
              action={"move"}
              questions={dependedOn_WillBreak}
              onCarryThrough={() => {
                setDependencyResolver(null);
                setSave(true);
                setShowLoading(true);
                setTimeout(() => {
                  finishSwitchingPages(activeInd, overInd);
                }, 1000);
              }}
              onNevermind={() => {
                setDependencyResolver(null);
              }}
              dependencies={dependencies}
              allQuestions={allQuestions}
              saveQuestions={onEditAllQs}
            />
          );
          return;
        }
      } else {
        // moving up
        // Can break its own
        let shouldBeRemoved = [];
        for (let q of activeQs) {
          if (q.conditionGroups) {
            for (let g of q.conditionGroups) {
              for (let c of g.conditions) {
                let dependency = allQuestions.find(
                  (qst) => qst.id === c.questionId
                );
                if (
                  dependency.pageNumber < activeInd &&
                  dependency.pageNumber >= overInd
                ) {
                  shouldBeRemoved.push(q);
                  break;
                }
              }
            }
          }
        }

        if (shouldBeRemoved.length) {
          setDependencyResolver(
            <SettleLogic
              removeItsOwn
              areYouSure={"move"}
              action={"move"}
              questions={shouldBeRemoved}
              onCarryThrough={() => {
                setDependencyResolver(null);
                setSave(true);
                setShowLoading(true);
                setTimeout(() => {
                  finishSwitchingPages(activeInd, overInd);
                }, 1000);
              }}
              onNevermind={() => {
                setDependencyResolver(null);
              }}
              dependencies={dependencies}
              allQuestions={allQuestions}
              saveQuestions={onEditAllQs}
            />
          );
          return;
        }
      }
    }

    finishSwitchingPages(activeInd, overInd);
  }

  function finishSwitchingPages(activeInd, overInd) {
    setShowLoading(true);

    if (designSettings?.pageSettings) {
      let newSettings = JSON.parse(JSON.stringify(designSettings));
      let changed = false;
      let oldActive = newSettings.pageSettings[activeInd];

      if (activeInd > overInd) {
        // moving up
        for (let i = activeInd - 1; i >= overInd; i--) {
          if (newSettings.pageSettings[i]) {
            newSettings.pageSettings[i + 1] = newSettings.pageSettings[i];
            delete newSettings.pageSettings[i];
            changed = true;
          }
        }
      } else {
        // moving down
        for (let i = activeInd + 1; i <= overInd; i++) {
          if (newSettings.pageSettings[i]) {
            newSettings.pageSettings[i - 1] = newSettings.pageSettings[i];
            delete newSettings.pageSettings[i];
            changed = true;
          }
        }
      }
      if (oldActive) {
        newSettings.pageSettings[overInd] = oldActive;
        changed = true;
      }

      if (changed) {
        saveDesign(JSON.stringify(newSettings));
      }
    }

    movePage.mutate(
      {
        projectId: id,
        activePageNum: activeInd,
        overPageNum: overInd,
      },
      {
        onSuccess: (data) => {
          refetchQs();
        },
      }
    );
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragStart(event) {
    const { active } = event;
    setMovingId(active.id);
    setActiveId("");

    let copy = questions.map((q) => q.id);
    setSnapShot(copy);
  }

  function handleDragOver(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      //update allQuestions
      let oldIndex = allQuestions.findIndex((q) => q.id === active.id);
      let newIndex = allQuestions.findIndex((q) => q.id === over.id);
      let overQ = allQuestions.find((q) => q.id === over.id);
      let temparray = arrayMove(allQuestions, oldIndex, newIndex);
      temparray[newIndex].pageNumber = overQ.pageNumber;

      let currpage = 1;
      let index = 1;
      let questionNumber = 1;
      for (let i = 0; i < temparray.length; i++) {
        if ("questionText" in temparray[i]) {
          temparray[i].index = questionNumber;
          questionNumber += 1;
        }
        while (temparray[i].pageNumber != currpage) {
          currpage += 1;
          index = 1;
        }
        if (temparray[i].pageNumber === currpage) {
          temparray[i].pageOrderIndex = index;
          index += 1;
        }
        temparray[i].changed = true;
      }
      let tempall = [...temparray];

      let tempQArrays = [];
      currpage = 1;
      let tempQs = [];
      for (let q of tempall) {
        if (q.pageNumber === currpage) {
          tempQs.push(q);
        } else {
          currpage += 1;
          tempQArrays.push(tempQs);
          tempQs = [];
          tempQs.push(q);
        }
      }
      tempQArrays.push(tempQs);
      if (currpage < maxPage) {
        while (currpage < maxPage) {
          tempQArrays.push([]);
          currpage += 1;
        }
      }

      let pages2 = [];
      for (let i = 0; i < maxPage; i++) {
        pages2.push([]);
      }
      for (let q of temparray) {
        pages2[q?.pageNumber - 1].push(q);
      }

      setAllQuestions(temparray);
      setQuestions(tempQArrays);
      setPages(pages2);
    }
  }

  function handleDragEnd(event) {
    // checking for dependency issues.
    // moving down
    // by moving down it will only break other's logic
    let resolve = false;
    let dependents = dependencies.find((d) => d.questionId === movingId);
    if (dependents) {
      let movingInd = questions.findIndex((q) => q.id === movingId);
      for (let dependent of dependents.dependencies) {
        let dependentIndex = allQuestions.findIndex(
          (q) => q.id === dependent.dependentId
        );
        if (movingInd > dependentIndex) {
          setDependencyResolver(
            <SettleLogic
              areYouSure={"move"}
              action={"move"}
              question={questions.find((q) => q.id === movingId)}
              onCarryThrough={() => {
                setDependencyResolver(null);
                setSave(true);
              }}
              onNevermind={() => {
                setDependencyResolver(null);
                setBackToSnapShot();
              }}
              dependencies={dependencies}
              allQuestions={allQuestions}
              saveQuestions={onEditAllQs}
            />
          );
          resolve = true;
          break;
        }
      }
    }

    if (!resolve) {
      // moving Up
      // by moving up it will only break it's own
      let movingQ = questions.find((q) => q.id === movingId);
      if (movingQ?.conditionGroups && movingQ?.conditionGroups?.length) {
        let dependsOn = [];
        forEach(movingQ?.conditionGroups, (g) => {
          forEach(g.conditions, (c) => {
            if (!dependsOn.includes(c.questionId)) {
              dependsOn.push(c.questionId);
            }
          });
        });

        let movingInd = questions.findIndex((q) => q.id === movingId);
        for (let id of dependsOn) {
          let index = allQuestions.findIndex((q) => q.id === id);
          if (movingInd < index) {
            setDependencyResolver(
              <SettleLogic
                removeItsOwn
                areYouSure={"move"}
                action={"move"}
                question={questions.find((q) => q.id === movingId)}
                onCarryThrough={() => {
                  setDependencyResolver(null);
                  setSave(true);
                }}
                onNevermind={() => {
                  setDependencyResolver(null);
                  setBackToSnapShot();
                }}
                dependencies={dependencies}
                allQuestions={allQuestions}
                saveQuestions={onEditAllQs}
              />
            );
            resolve = true;
            break;
          }
        }
      }
    }

    setMovingId("");
    setSavingStatus(<Loading height={50} width={50}></Loading>);
    setSave(true);
  }

  function setBackToSnapShot() {
    let order = [];

    for (let i = 0; i < snapShot.length; i++) {
      let q = questions.find((q) => q.id === snapShot[i]);
      q.pageOrderIndex = i;
      order.push(q);
    }

    setSnapShot(undefined);
    setQuestions(order);
    // no need for all, since nothing should've updated
  }

  const [movingId, setMovingId] = useState("");

  function getActive() {
    let question = allQuestions.find((q) => q.id === movingId);

    return (
      <div>
        <SurveyComponent
          component={question}
          active={activeId === question.id}
          status={status}
          canEdit={canEdit}
          designSettings={designSettings}
          answers={answers}
          survey={survey}
          calculateQuestionHealth={calculateQuestionHealth}
          questionNumber={
            question?.index ? question?.index : getNumber(question)
          }
        ></SurveyComponent>
      </div>
    );
  }

  function onDeletePage(number) {
    let hadPageSettings =
      designSettings?.pageSettings && number in designSettings.pageSettings;
    let name = hadPageSettings ? designSettings.pageSettings[number] : number;
    if (
      window.confirm(
        "Are you sure you want to delete page " +
          name +
          "? All questions on it will also be deleted"
      )
    ) {
      let newSettings = JSON.parse(JSON.stringify(designSettings));
      if (hadPageSettings) {
        delete newSettings.pageSettings[number];
      }
      if (newSettings?.pageSettings) {
        for (let i = number + 1; i < maxPage + 1; i++) {
          if (i in newSettings.pageSettings) {
            let copy = newSettings.pageSettings[i];
            newSettings.pageSettings[i - 1] = copy;
            delete newSettings.pageSettings[i];
          }
        }
      }

      deletePage.mutate(
        {
          projectId: id,
          pageNumber: number,
          designSettings: JSON.stringify(newSettings),
        },
        {
          onSuccess: () => {
            let newPage = currPage;
            if (currPage >= number && currPage > 1) {
              newPage = currPage - 1;
            }
            refetchQs(newPage);
            refetchDSettings();
            setMaxPage((max) => max - 1);
          },
        }
      );
    }
  }

  function onCopy(question, e, placement) {
    let copy = {};
    let index = pages[question?.pageNumber - 1].length;
    if (placement === "abovepage") {
      index = 0;
    } else if (placement === "above") {
      let q = allQuestions.find((q) => q.id === question.id);
      if (q.pageOrderIndex <= 1) {
        index = 0;
      } else {
        index = q.pageOrderIndex - 1;
      }
    } else if (placement === "below") {
      let q = allQuestions.find((q) => q.id === question.id);
      index = q.pageOrderIndex;
    }
    if ("questionText" in question) {
      copy = {
        id: "temporaryid:" + shortId(),
        type: question.type,
        projectId: id,
        pageOrderIndex: index,
        pageNumber: question?.pageNumber,
        isHidden: false,
        description: "",
        // buckets: [],
        // hasBuckets: false,
        new: true,
        questionText: question.questionText,
        description: question.description,
        encoding: createEncodingCopy(question.encoding),
        descriptionEncoding: createEncodingCopy(question.descriptionEncoding),
        alerts: [],
        questionTag: [],
      };
      if (question.choiceQuestion) {
        copy.choiceQuestion = JSON.parse(
          JSON.stringify(question.choiceQuestion)
        ); // has second level of items
      }
      if (question.scaleQuestion) {
        copy.scaleQuestion = { ...question.scaleQuestion };
      }
      if (question.textQuestion) {
        copy.textQuestion = { ...question.textQuestion };
        copy.textQuestion.bucket = [];
      }
      if (question.matrixQuestion) {
        copy.matrixQuestion = JSON.parse(
          JSON.stringify(question.matrixQuestion)
        ); // has second level of items
      }
    } else {
      copy = {
        id: "temporarySectionId:" + shortId(),
        type: question.type,
        projectId: id,
        pageOrderIndex: index,
        new: true,
        pageNumber: question?.pageNumber,
        sectionSettings: JSON.parse(JSON.stringify(question.sectionSettings)),
      };

      if (question.type === "Description") {
        copy.bodyText = question.bodyText;
        copy.encoding = createEncodingCopy(question.encoding);
      } else if (question.type === "Image") {
        copy.image = question.image;
        copy.imageId = question.imageId;
      }
    }

    addQToState(copy, question?.pageNumber);
  }

  function onPaste(index) {
    if (copy) {
      let it = { ...copy };
      it.pageNumber = currPage;
      it.pageOrderIndex = index;
      addQToState(it);
      setCopy(null);
    }
  }

  function onAnswer(qId, answer) {
    let copy = { ...answers };
    copy[qId] = answer;
    setAnswers(copy);
  }

  function onRetract(qId) {
    let copy = { ...answers };
    delete copy[qId];
    setAnswers(copy);
  }

  const getNumQs = () => {
    let count = 0;
    forEach(allQuestions, (q) => {
      if (q.type !== "Description") {
        count++;
      }
    });
    return count;
  };
  const numQs = getNumQs();

  function setUpActiveQ(question) {
    if (canEdit) {
      if (activeId !== question.id) {
        setActiveId(question?.id);
        setActiveQ(question);
        if ("questionText" in question) {
          updateActive("question");
        } else {
          updateActive("section");
        }
      }
    }
  }

  function exitDesignEditing() {
    setActiveId("");
    setSave(true);
  }

  function surveyHealth() {
    let health = calculateSurveyHealth(survey, questions);

    return (
      <>
        {" "}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "1em",
          }}
        >
          <Stat
            title="Survey Health"
            value={
              <div>
                <Pie percentage={health?.health}></Pie>
              </div>
            }
            header
            titleStyle={{ fontSize: ".9em", fontWeight: "300" }}
            valueStyle={{
              fontSize: "1em",
            }}
            style={{ alignItems: "center", paddingTop: "1em" }}
          />

          {activeId && (
            <Stat
              title="Question Health"
              value={
                <div>
                  <Pie
                    percentage={
                      calculateQuestionHealth(
                        questions.find((q) => q.id === activeId)
                      )?.health
                    }
                  ></Pie>
                </div>
              }
              header
              titleStyle={{ fontSize: ".9em", fontWeight: "300" }}
              valueStyle={{
                fontSize: "1em",
              }}
              style={{ alignItems: "center", paddingTop: "1em" }}
            />
          )}
        </div>
        {health?.tips.length != 0 && (
          <div className={styles.tip}>
            <span>
              <a>Tip:</a>
              {health?.tips[0]}
            </span>
          </div>
        )}
      </>
    );
  }

  function getNumber(q) {
    let num = 1;
    for (let question of allQuestions) {
      if ("questionText" in question) {
        if (question?.id === q?.id) {
          return num;
        }
        num += 1;
      }
    }
  }

  function setUpPreview() {
    setSave(true);
    setTimeout(() => setShowPreview(!showPreview), 250);
  }

  function closeDownSettings() {
    setActiveId("");
    updateActive("");
    setActiveQ(null);
  }

  function setUpActiveSetting(setting) {
    updateActive(setting);
    setActiveId("");
    setActiveQ(null);
  }

  function onZoneClick(e) {
    if (activeQ || active) {
      for (let comp of allQuestions) {
        let container = document.getElementById("survey component: " + comp.id);
        if (container && container.contains(e.target)) {
          return;
        }
      }

      if (designSettings?.showHeader) {
        let header = document.getElementById("survey_header");
        if (header && header.contains(e.target)) {
          return;
        }
      }

      let finished = document.getElementById("finished_page");
      if (finished && finished.contains(e.target)) {
        return;
      }

      closeDownSettings();
    }
  }

  function toggleLogic() {
    setShowLogic(!showLogic);
    closeDownSettings();
  }

  function upCounter() {
    setGlobalCounter((c) => c + 1);
  }

  return (
    <>
      {saveQuestion.isError && (
        <ErrorBanner
          error={saveQuestion.error}
          message={"Error saving question"}
        />
      )}
      {createQuestion.isError && (
        <ErrorBanner
          error={createQuestion.error}
          message={"Error creating question"}
        />
      )}
      {deleteQuestion.isError && (
        <ErrorBanner
          error={deleteQuestion.error}
          message={"Error deleting question"}
        />
      )}
      {deleteSection.isError && (
        <ErrorBanner
          error={deleteSection.error}
          message={"Error deleting text"}
        />
      )}
      {deletePage.isError && (
        <ErrorBanner error={deletePage.error} message={"Error deleting page"} />
      )}
      {updateSettings.isError && (
        <ErrorBanner
          error={updateSettings.error}
          message={"Error updating survey"}
        />
      )}
      {createSection.isError && (
        <ErrorBanner
          error={createSection.error}
          message={"Error creating text"}
        />
      )}
      {updateSection.isError && (
        <ErrorBanner
          error={updateSection.error}
          message={"Error updating text"}
        />
      )}
      {movePage.isError && (
        <ErrorBanner error={movePage.error} message={"Error moving page"} />
      )}
      {dependencyResolver && (
        <div id="dependencyResolver">{dependencyResolver}</div>
      )}
      <div className={styles.pageContainer}>
        {canEdit && (window.innerWidth < 830 ? seeDesignSettings : true) && (
          <div
            className={styles.settingsContainer}
            style={{
              position: window.innerWidth < 830 ? "absolute" : "",
              right: "0",
              boxShadow:
                window.innerWidth < 830
                  ? "rgba(0, 0, 0, 0.15) 0px 5px 15px"
                  : "",
            }}
          >
            <div className={styles.settingsContent}>
              {window.innerWidth < 830 && (
                <i
                  className="bi bi-x-lg"
                  style={{
                    position: "absolute",
                    top: "1em",
                    right: "1em",
                    cursor: "pointer",
                  }}
                  onClick={() => setSeeDesignSettings(false)}
                ></i>
              )}
              <SurveyDesign
                settings={designSettings}
                setSettings={saveDesign}
                numPages={maxPage}
                currPage={currPage}
                setCurrPage={setCurrPage}
                allQuestions={allQuestions}
                onDeletePage={onDeletePage}
                addPage={addPage}
                switchPages={switchPages}
                status={status}
                active={active}
                setActive={updateActive}
                setActiveId={setActiveId}
                setActiveQ={setActiveQ}
                question={activeQ}
                dependents={dependencies.find((d) => d.questionId === activeId)}
                setUpLogicResolver={setUpLogicResolver}
                onRetract={onRetract}
                onEditQ={onEditQ}
                onEditQs={onEditQs}
                projId={id}
                showLogic={showLogic}
                setShowLogic={setShowLogic}
                allQs={allQuestions}
                saveQs={onEditQs}
                onUpdate={onUpdate}
                upCounter={upCounter}
              ></SurveyDesign>
            </div>
          </div>
        )}
        <div className={styles.container}>
          <div className={styles.nav}>
            {" "}
            <FlexRow
              style={{
                width: "100%",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <div
                className={styles.section_label6}
                style={{
                  whiteSpace: "nowrap",
                  margin: "0",
                  marginLeft: ".5rem",
                }}
              >
                {savingStatus ? (
                  <FlexRow>
                    Saving...
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "20px",
                        width: "20px",
                        overflow: "hidden",
                      }}
                    >
                      {savingStatus}
                    </div>{" "}
                  </FlexRow>
                ) : (
                  `Saved ${trimDate(updatedAt, true)}`
                )}
              </div>
              <FlexRow style={{ width: "fit-content" }}>
                {fullscreen && (
                  <Button
                    height="35px"
                    onClick={() => setFullscreen(!fullscreen)}
                    style={{ gap: ".5rem" }}
                    shadow
                  >
                    <Icon iconName={"arrows-angle-contract"}></Icon> Exit Full
                    Screen
                  </Button>
                )}
                {!fullscreen && (
                  <Button
                    height="35px"
                    onClick={() => setFullscreen(!fullscreen)}
                    style={{ gap: ".5rem" }}
                    shadow
                  >
                    <Icon iconName={"arrows-angle-expand"}></Icon> Full Screen
                  </Button>
                )}

                <Button
                  height="35px"
                  onClick={toggleLogic}
                  style={{
                    gap: ".5rem",
                    border: showLogic ? "" : "2px solid transparent",
                  }}
                  shadow
                  blue={showLogic}
                >
                  <Icon iconName={"signpost-split"} white={showLogic}></Icon>{" "}
                  Logic / Paths
                </Button>

                <Button
                  height="35px"
                  onClick={setUpPreview}
                  style={{ gap: ".5rem" }}
                  shadow
                >
                  <Icon iconName={"eye"}></Icon> Preview
                </Button>

                {/* <BlueAddButton onAddQ={appendQ} onAddSection={appendSection} /> */}
              </FlexRow>
            </FlexRow>
          </div>

          <div
            className={styles.editor}
            style={{
              backgroundColor: designSettings.backgroundColor
                ? designSettings.backgroundColor
                : "transparent",
            }}
            ref={scrollableRef}
            onClick={onZoneClick}
          >
            {showLoading && <Loading />}

            {!showLoading && (
              <>
                <div className={styles.constructionZone}>
                  <div className={styles.survey} id="survey">
                    {showLogic && (
                      <SurveyLogic
                        pages={pages}
                        designSettings={designSettings}
                        allQuestions={allQuestions}
                        activeQ={activeQ}
                        setActiveQ={setActiveQ}
                      />
                    )}
                    {!showLogic && (
                      <>
                        {/* {!seeAll &&
                      designSettings.showLogo &&
                      (designSettings.logoOnEveryPage || currPage == 1) && (
                        <EditLogo
                          settings={designSettings}
                          setSettings={changeSettings}
                          projId={id}
                          active={active === "editLogo"}
                          setActive={setActive}
                          close={exitDesignEditing}
                        />
                      )}

                    {!seeAll && !showPreview && designSettings.showProgBar && (
                      <EditableProgressBar
                        settings={designSettings}
                        setSettings={changeSettings}
                        active={active === "progress"}
                        setActive={setActive}
                        close={exitDesignEditing}
                        numQs={numQs}
                        numAnswered={Object.keys(answers).length}
                      />
                    )} */}

                        <DndContext
                          sensors={sensors}
                          collisionDetection={closestCorners}
                          onDragEnd={handleDragEnd}
                          onDragOver={handleDragOver}
                          onDragStart={handleDragStart}
                          modifiers={[
                            restrictToVerticalAxis,
                            // restrictToParentElement,
                          ]}
                        >
                          {pages && pages?.length > 0 && (
                            <SortableContext
                              id={"list"}
                              items={questions}
                              strategy={verticalListSortingStrategy}
                            >
                              {(!pages || pages?.length === 0) &&
                                allQuestions?.length > 0 && <Loading></Loading>}
                              {pages &&
                                pages?.length > 0 &&
                                pages?.map((pageQuestions, pageInd) => (
                                  <React.Fragment key={pageInd}>
                                    <PageBreak
                                      pageNum={pageInd + 1}
                                      canEdit={canEdit}
                                      onDelete={() => onDeletePage(pageInd + 1)}
                                      pageCount={pages.length}
                                    ></PageBreak>

                                    <div
                                      className={`${styles.apage}`}
                                      style={{
                                        gap: designSettings?.questionGap
                                          ? `${
                                              designSettings?.questionGap * 2
                                            }px`
                                          : "0px",

                                        backgroundColor:
                                          designSettings.backgroundColor
                                            ? designSettings.backgroundColor
                                            : "transparent",
                                        backgroundImage:
                                          designSettings?.backgroundImage
                                            ? designSettings?.backgroundImage
                                            : "",
                                      }}
                                      key={pageInd}
                                    >
                                      {designSettings.showHeader &&
                                        (designSettings.showLogo ||
                                          designSettings.showProgBar) && (
                                          <div
                                            className={`${styles.pageHeader} ${
                                              active === "header" &&
                                              styles.aactive
                                            }`}
                                            style={{
                                              backgroundColor:
                                                designSettings?.headerBackgroundColor
                                                  ? designSettings?.headerBackgroundColor
                                                  : "transparent",
                                              boxShadow:
                                                designSettings?.headerShadow
                                                  ? designSettings?.headerShadow
                                                  : "",
                                              borderRadius:
                                                designSettings?.headerRadius ||
                                                designSettings?.headerRadius ===
                                                  0
                                                  ? designSettings?.headerRadius +
                                                    "rem"
                                                  : ".5rem",
                                              gap:
                                                designSettings?.headerGap ||
                                                designSettings?.headerGap === 0
                                                  ? designSettings?.headerGap +
                                                    "rem"
                                                  : "",
                                            }}
                                            onClick={() =>
                                              setUpActiveSetting("header")
                                            }
                                            id="survey_header"
                                          >
                                            {designSettings.showLogo &&
                                              (designSettings.logoOnEveryPage ||
                                                pageInd + 1 == 1) && (
                                                <EditLogo
                                                  settings={designSettings}
                                                  setSettings={saveDesign}
                                                  projId={id}
                                                  active={active === "editLogo"}
                                                  setActive={updateActive}
                                                  close={exitDesignEditing}
                                                />
                                              )}

                                            {designSettings.showProgBar && (
                                              <EditableProgressBar
                                                settings={designSettings}
                                                setSettings={saveDesign}
                                                active={active === "progress"}
                                                setActive={updateActive}
                                                close={exitDesignEditing}
                                                numQs={numQs}
                                                numAnswered={
                                                  Object.keys(answers).length
                                                }
                                              />
                                            )}
                                          </div>
                                        )}
                                      {pageQuestions &&
                                        pageQuestions?.length === 0 && (
                                          <FlexRow>
                                            <div
                                              className={`${styles.addBtnBig} `}
                                              onClick={() =>
                                                handleCreateText(0, pageInd + 1)
                                              }
                                            >
                                              + Text
                                              <span>
                                                Good for cover pages or
                                                separating sections of your
                                                survey. Can also be used as a
                                                header.
                                              </span>
                                            </div>
                                            <div
                                              className={`${styles.addBtnBig} `}
                                              onClick={() =>
                                                handleCreateQuestion(
                                                  0,
                                                  pageInd + 1
                                                )
                                              }
                                            >
                                              + Question
                                              <span>
                                                Your survey question. Can be
                                                multiple choice, scale, free
                                                response, or ranking type
                                                questions.
                                              </span>
                                            </div>
                                          </FlexRow>
                                        )}
                                      {pageQuestions &&
                                        pageQuestions?.length > 0 &&
                                        pageQuestions?.map((question, ind) => (
                                          <React.Fragment key={ind}>
                                            <SurveyComponent
                                              key={question.id}
                                              component={question}
                                              active={activeId === question.id}
                                              setActive={() =>
                                                setUpActiveQ(question)
                                              }
                                              status={status}
                                              canEdit={canEdit}
                                              onEditQ={onEditQ}
                                              deleteQ={deleteQ}
                                              designSettings={designSettings}
                                              onCopy={onCopy}
                                              onAnswer={onAnswer}
                                              onRetract={onRetract}
                                              answers={answers}
                                              dependents={dependencies.find(
                                                (d) =>
                                                  d.questionId === question.id
                                              )}
                                              setUpLogicResolver={
                                                setUpLogicResolver
                                              }
                                              survey={survey}
                                              calculateQuestionHealth={
                                                calculateQuestionHealth
                                              }
                                              questionNumber={getNumber(
                                                question
                                              )}
                                              onAddQ={(spot) =>
                                                handleCreateQuestion(
                                                  spot > 0 ? ind + 1 : ind,
                                                  pageInd + 1
                                                )
                                              }
                                              onAddSection={(spot) =>
                                                handleCreateText(
                                                  spot > 0 ? ind + 1 : ind,
                                                  pageInd + 1
                                                )
                                              }
                                              onAddImage={(spot) => {
                                                handleCreateImage(
                                                  spot > 0 ? ind + 1 : ind,
                                                  pageInd + 1
                                                );
                                              }}
                                              onPaste={() => onPaste(ind + 1)}
                                              globalCounter={globalCounter}
                                            ></SurveyComponent>
                                          </React.Fragment>
                                        ))}

                                      {pageQuestions.length > 0 && (
                                        <FlexRow
                                          justify={"flex-start"}
                                          gap={20}
                                          style={{ paddingLeft: "40px" }}
                                        >
                                          <div
                                            className={`${styles.addBottomOfPage}`}
                                            onClick={() =>
                                              handleCreateQuestion(
                                                pageQuestions.length,
                                                pageInd + 1
                                              )
                                            }
                                          >
                                            + Question
                                          </div>

                                          <div
                                            className={styles.barDivider}
                                          ></div>

                                          <div
                                            className={`${styles.addBottomOfPage}`}
                                            onClick={() =>
                                              handleCreateText(
                                                pageQuestions.length,
                                                pageInd + 1
                                              )
                                            }
                                          >
                                            + Text
                                          </div>

                                          <div
                                            className={styles.barDivider}
                                          ></div>

                                          <div
                                            className={`${styles.addBottomOfPage}`}
                                            onClick={() =>
                                              handleCreateImage(
                                                pageQuestions.length,
                                                pageInd + 1
                                              )
                                            }
                                          >
                                            + Image
                                          </div>
                                        </FlexRow>
                                      )}

                                      <EditableNavigation
                                        settings={designSettings}
                                        page={pageInd + 1}
                                        maxPage={maxPage}
                                        active={active === "nav"}
                                        setActive={setUpActiveSetting}
                                      ></EditableNavigation>
                                    </div>
                                  </React.Fragment>
                                ))}

                              <PageBreak finishedPage addPage={addPage} />

                              <div
                                className={`${styles.apage}  ${
                                  styles.finishedPage
                                } ${active === "finished" && styles.aactive}`}
                                style={{
                                  gap: designSettings?.questionGap
                                    ? `${designSettings?.questionGap}px`
                                    : "20px",
                                  minHeight: "400px",
                                  backgroundColor:
                                    designSettings?.finishedBackgroundColor
                                      ? designSettings?.finishedBackgroundColor
                                      : designSettings.backgroundColor,
                                  backgroundImage:
                                    designSettings?.backgroundImage
                                      ? designSettings?.backgroundImage
                                      : "",
                                  cursor: "pointer",
                                }}
                                id="finished_page"
                                onClick={() => setUpActiveSetting("finished")}
                              >
                                <SurveyCompletedInConstruction
                                  message={finishedMsg}
                                  settings={designSettings}
                                  onEdit={editFinishMessage}
                                  noConfetti
                                  canEdit={canEdit}
                                ></SurveyCompletedInConstruction>
                              </div>

                              <PageBreak footer />

                              <div
                                className={styles.poweredBy}
                                style={{ gap: ".3em" }}
                              >
                                Powered By{" "}
                                <a
                                  href={"https://reactiondata.com"}
                                  target="_blank"
                                >
                                  Reaction
                                </a>{" "}
                                <img
                                  src={require("assets/images/circlelogo.png")}
                                  height={10}
                                />
                              </div>
                            </SortableContext>
                          )}

                          <DragOverlay
                            dropAnimation={{
                              duration: 500,
                              easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
                            }}
                          >
                            {movingId && <>{getActive()}</>}
                          </DragOverlay>
                        </DndContext>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {showPreview && (
          <div
            className={styles.expandedPreview}
            style={{
              backgroundColor: designSettings.backgroundColor,
              // position: !roles.editSurveyDesign && !roles.editSurveyQuestions ? "absolute" : ""
            }}
          >
            <div className={styles.close}>
              {previewMode === "Desktop" && (
                <Button
                  onClick={() => {
                    setPreviewMode("Mobile");
                  }}
                  style={{
                    display: "flex",
                    gap: ".5rem",
                    alignItems: "center",
                  }}
                  shadow
                >
                  <Icon iconName={"phone"}></Icon> Mobile
                </Button>
              )}
              {previewMode === "Mobile" && (
                <Button
                  onClick={() => {
                    setPreviewMode("Desktop");
                  }}
                  style={{
                    display: "flex",
                    gap: ".5rem",
                    alignItems: "center",
                  }}
                  shadow
                >
                  <Icon iconName={"display"}></Icon>
                  Desktop
                </Button>
              )}
              <Button
                onClick={() => {
                  // window.location.reload(false);
                  setShowPreview(false);
                }}
                style={{ display: "flex", gap: ".5rem", alignItems: "center" }}
                shadow
              >
                <div style={{ transform: "rotateX(180deg)" }}>
                  <i className="bi bi-arrow-return-left"></i>
                </div>{" "}
                Back to Build
              </Button>
            </div>

            {previewMode === "Desktop" && (
              <Survey projectId={projectId} preview></Survey>
            )}
            {previewMode === "Mobile" && (
              <>
                {window.innerWidth > 700 && (
                  <>
                    <FlexRow
                      style={{
                        justifyContent: "center",
                        height: "fit-content",
                        alignItems: "center",
                        marginTop: "2rem",
                      }}
                    >
                      <div className={styles.mobileImage}>
                        <img
                          src={require("assets/images/IphoneSolo.png")}
                          height={900}
                          width={480}
                        ></img>
                        <div className={styles.mobile}>
                          {" "}
                          <div className={styles.mobileC}>
                            <Survey projectId={projectId} preview></Survey>
                          </div>
                        </div>
                      </div>
                    </FlexRow>
                  </>
                )}
                {window.innerWidth <= 700 && (
                  <Survey projectId={projectId} preview></Survey>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export function Add({
  show,
  onAddQ,
  onAddSection,
  onAddImage,
  copy,
  onPaste,
  emptyPage,
}) {
  const [showItems, setShowItems] = useState(false);

  function clickOutside(e) {
    if (ref.current && !ref.current?.contains(e.target)) {
      setShowItems(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const ref = useRef();
  return (
    <>
      {show && (
        <div
          className={` ${styles.addContainer}`}
          style={
            emptyPage
              ? {
                  position: "static",
                  gap: "2em",
                }
              : undefined
          }
          ref={ref}
          onClick={() => setShowItems(!showItems)}
        >
          <div onClick={() => setShowItems(!showItems)} className={styles.add}>
            <i className="bi-plus" style={{ fontSize: "16pt" }}></i>
            {emptyPage && (
              <span>
                Your survey question. Can be multiple choice, scale, free
                response, or ranking type questions.
              </span>
            )}
          </div>
          {showItems && (
            <div
              className={styles.settingsBox}
              style={{ left: "-.2rem", top: "-1.2rem", width: "fit-content" }}
            >
              <div className={`${styles.menuItem} `} onClick={onAddQ}>
                <i className="bi bi-question-circle"></i>
                Add Question
              </div>
              <div className={`${styles.menuItem} `} onClick={onAddSection}>
                <i className="bi bi-textarea-t"></i>
                Add Text
              </div>
              <div className={`${styles.menuItem} `} onClick={onAddImage}>
                <i className="bi-image"></i>
                Add Image
              </div>
            </div>
          )}
          {copy && (
            <div
              className={`${styles.addBtn} ${styles.paste}`}
              onClick={onPaste}
            >
              Paste
            </div>
          )}
        </div>
      )}
    </>
  );
}

function BlueAddButton({ onAddQ, onAddSection }) {
  const [showAdd, setShowAdd] = useState(false);

  const ref = useRef();

  function clickListener(e) {
    if (!ref.current || !ref.current.contains(e.target)) {
      setShowAdd(false);
      document.removeEventListener("click", clickListener, true);
    }
  }

  function setUp() {
    setShowAdd(true);
    document.addEventListener("click", clickListener, true);
  }

  return (
    <>
      <div className={styles.blueAddButton} ref={ref}>
        <Button
          height="35px"
          onClick={setUp}
          style={{ gap: ".5rem" }}
          blue
          shadow
        >
          <Icon white iconName={"plus"}></Icon> Add
        </Button>
        {showAdd && (
          <div
            className={styles.settingsBox}
            style={{ right: "0", top: "120%", width: "fit-content" }}
          >
            <div className={`${styles.menuItem} `} onClick={onAddQ}>
              <i className="bi bi-question-circle"></i>
              Question
            </div>
            <div className={`${styles.menuItem} `} onClick={onAddSection}>
              <i className="bi bi-textarea-t"></i>
              Text
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export function PageBreak({
  pageNum,
  onDelete,
  canEdit,
  pageCount,
  finishedPage,
  addPage,
  footer,
}) {
  return (
    <>
      <FlexRow
        gap={".5rem"}
        className={styles.pageBreak}
        style={pageNum == 1 ? { paddingBottom: "0px" } : undefined}
      >
        <div className={styles.pageLine}></div>

        {finishedPage ? (
          <FlexRow gap={".5rem"} fit>
            <Icon iconName={"check-circle"}></Icon>

            <div>Finished Page </div>
          </FlexRow>
        ) : footer ? (
          <div>Footer</div>
        ) : (
          <>
            <div>Page {pageNum}</div>
            {canEdit && pageCount > 1 && onDelete && (
              <Icon
                iconName={"trash"}
                onClick={onDelete}
                className={styles.deletePage}
              ></Icon>
            )}
          </>
        )}
        <div
          className={styles.pageLine}
          style={{
            flexGrow: 1,
          }}
        ></div>

        {finishedPage && addPage && (
          <>
            <div className={`${styles.addSomething}`} onClick={addPage}>
              + Page
            </div>
            <div className={styles.pageLine}></div>
          </>
        )}
      </FlexRow>
    </>
  );
}
