import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./DataTypeInput.module.scss";
import { TextFieldSimple } from "components/inputs";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import FlexRow from "../FlexRow/FlexRow";
import FlexCol from "../FlexColumn/FlexCol";
import {
  useCheckUniqueAttribute,
  useGetCustomFieldAttributes,
  useSearchCustomFieldAttributes,
} from "api/resources/contacts/custom_field";
import { formatDateToCustomString } from "assets/functions/DateFunctions";
import AsyncSelect from "react-select/async";

function DataTypeInput({
  dataType,
  value,
  onChange,
  onBlur,
  icon,
  label,
  min,
  max,
  falseValue,
  trueValue,
  placeholder,
  disabled,
  id,
  required,
  multiple,
  unique,
  onValidate,
}) {
  const isUnique = useCheckUniqueAttribute();
  const [invalidMessage, setInvalidMessage] = useState("");
  const [valid, setValid] = useState(undefined);
  const [finalLabel] = useState(
    required && label ? (
      <div>
        <a className={styles.red}>*</a> {label}
      </div>
    ) : (
      label
    )
  );
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  // Initialize date and time if dataType is "date/time"
  useEffect(() => {
    if (dataType === "date/time" && value) {
      const parsedDate = new Date(value);
      setDate(parsedDate.toISOString().split("T")[0]); // Extract date part
      setTime(parsedDate.toTimeString().slice(0, 5)); // Extract time part
    }
  }, [dataType, value]);

  const validateField = (newValue) => {
    if (required && (!newValue || (multiple && newValue.length === 0))) {
      setInvalidMessage("This field is required");
      setValid(false);
      onValidate?.(false);
    } else {
      setInvalidMessage("");
      setValid(true);
      onValidate?.(true);
    }

    if (unique && newValue !== value) {
      isUnique.mutate(
        { id, name: newValue },
        {
          onSuccess: (data) => {
            if (data?.exists) {
              setInvalidMessage("Value already exists");
              setValid(false);
              onValidate?.(false);
            } else {
              setInvalidMessage("");
              setValid(true);
              onValidate?.(true);
            }
          },
          onError: () => {
            setInvalidMessage("Error validating uniqueness");
            onValidate?.(false);
          },
        }
      );
    }
  };

  const handleBlur = (newValue) => {
    validateField(newValue);
    onBlur?.(newValue);
  };

  // Combine date and time into a single ISO string or custom format
  const getCombinedDateTime = (dateValue, timeValue) => {
    if (!dateValue || !timeValue) return "";
    const combinedDate = new Date(`${dateValue}T${timeValue}`);
    return formatDateToCustomString
      ? formatDateToCustomString(combinedDate)
      : combinedDate.toISOString();
  };

  // Handle date change
  const handleDateChange = (newDate) => {
    setDate(newDate);
    const combinedDateTime = getCombinedDateTime(newDate, time);
    onChange?.(combinedDateTime);
  };

  // Handle time change
  const handleTimeChange = (newTime) => {
    setTime(newTime);
    const combinedDateTime = getCombinedDateTime(date, newTime);
    onChange?.(combinedDateTime);
  };

  const commonProps = {
    label: finalLabel,
    value,
    onChange,
    onBlur: handleBlur,
    placeholder,
    icon,
    disable: disabled,
    valid,
    invalidMessage,
    className: styles.input,
    style: { fontSize: ".8rem", padding: ".25rem" },
    autoFocus: true,
  };

  const boolRef = useRef();

  function clickOutside(e) {
    if (boolRef.current && !boolRef.current?.contains(e.target)) {
      onBlur(e);
    }
  }

  useEffect(() => {
    if (dataType === "boolean") {
      document.addEventListener("click", clickOutside, true);
      return () => {
        document.removeEventListener("click", clickOutside, true);
      };
    }
  }, []);

  const renderInput = () => {
    switch (dataType) {
      case "boolean":
        return (
          <div ref={boolRef}>
            <FlexCol className={styles.input}>
              {finalLabel && (
                <div
                  className={styles.label6}
                  style={{ padding: ".2rem .5rem" }}
                >
                  {finalLabel}
                </div>
              )}
              <FlexRow className={styles.bool} onClick={() => onChange(!value)}>
                <div style={{ pointerEvents: "none" }}>
                  <Checkbox checked={value} onChange={() => {}} />
                </div>
                <div className={styles.text_2}>
                  {value ? trueValue || "Yes" : falseValue || "No"}
                </div>
              </FlexRow>
            </FlexCol>
          </div>
        );
      case "email":
        return (
          <TextFieldSimple
            {...commonProps}
            type="email"
            // style={{ fontSize: "." }}
            // validationRules={[...(required ? ["Required"] : [])]}
          />
        );
      case "currency":
        return (
          <TextFieldSimple
            {...commonProps}
            type="text"
            placeholder="0.00"
            icon="currency-dollar"
          />
        );
      case "number":
        return (
          <TextFieldSimple
            {...commonProps}
            type="number"
            min={min}
            max={max}
            icon="123"
          />
        );
      case "date":
      case "time":
        return (
          <TextFieldSimple
            {...commonProps}
            type={dataType}
            value={value ? new Date(value).toISOString().split("T")[0] : ""}
          />
        );
      case "date/time":
        return (
          <div className={styles.input}>
            <FlexRow align="center" gap=".5rem">
              {/* Date Input */}
              <TextFieldSimple
                label={finalLabel}
                value={date}
                onChange={handleDateChange}
                onBlur={() => handleBlur(getCombinedDateTime(date, time))}
                type="date"
                placeholder="Select Date"
                disable={disabled}
                valid={valid}
                invalidMessage={invalidMessage}
                validationRules={required ? ["This field is required"] : []}
              />
              <div className={styles.text_2} style={{ paddingTop: "1.5rem" }}>
                at
              </div>
              {/* Time Input */}
              <TextFieldSimple
                label="Time"
                value={time}
                onChange={handleTimeChange}
                onBlur={() => handleBlur(getCombinedDateTime(date, time))}
                type="time"
                placeholder="Select Time"
                disable={disabled}
                valid={valid}
                invalidMessage={invalidMessage}
                validationRules={required ? ["This field is required"] : []}
              />
            </FlexRow>
          </div>
        );
      case "list":
        return (
          // <ListField
          //   {...commonProps}
          //   id={id}
          //   multiple={multiple}
          //   required={required}
          //   placeholder={placeholder}
          // />
          <ListInput
            {...commonProps}
            multiple={multiple}
            id={id}
            required={required}
            placholder={placeholder}
          />
        );
      default:
        return <TextFieldSimple {...commonProps} />;
    }
  };

  return <div className={styles.input}>{renderInput()}</div>;
}

export default DataTypeInput;

function ListInput({
  onChange,
  value,
  multiple,
  onBlur,
  autoFocus,
  id,
  required,
  placeholder,
}) {
  const skip = 0;
  const perPage = 100;
  const sort = { item: "name", descend: false };

  const fetchOptions = async (inputValue) => {
    const results = await useSearchCustomFieldAttributes(
      inputValue,
      perPage,
      sort,
      id,
      skip
    );
    return results.map((a) => ({
      value: a.id,
      label: a.name,
    }));
  };

  function getValue(value) {
    if (multiple) {
      const values = value?.map((v) => {
        if (typeof v === "string") {
          return { label: v, value: v };
        } else if (v?.id) {
          return { value: v?.id, label: v?.name };
        } else {
          return v;
        }
      });
      return values;
    } else {
      return typeof value === "string" ? { label: value, value: value } : value;
    }
  }

  return (
    <div style={{ height: "34px", display: "flex", flexDirection: "column" }}>
      <AsyncSelect
        className="dataInputSelect" // Apply SCSS module class
        classNamePrefix="dataInputSelect" // Ensure correct className mapping
        cacheOptions
        loadOptions={fetchOptions}
        defaultOptions
        onChange={onChange}
        value={
          getValue(value) // Convert value to correct format
        }
        placeholder={placeholder ? placeholder : "Select"}
        isMulti={multiple}
        onBlur={onBlur}
        autoFocus={autoFocus}
        required={required}
        menuIsOpen={true}
      />
    </div>
  );
}
