import { useState } from "react";
import styles from "./SurveyDesign.module.scss";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { AddColorSimple } from "components/ColorPalette/EditColors/AddColorSimple";
import NumberInputWithSlider from "components/NumberInputWithSlider/NumberInputWithSlider";
import ColorPickerContainer from "components/inputs/input_fields/ColorPicker/ColorPickerContainer";
import { shadows } from "assets/functions/Variables";
import { SelectFieldCustom } from "components/inputs";

export function FinishedSettings({ settings, changeSetting }) {
  function addColor(color) {
    let copy = settings.confettiColors ? [...settings.confettiColors] : [];
    copy.push(color);
    changeSetting("confettiColors", copy);
  }

  function removeColor(i) {
    let copy = [...settings.confettiColors];
    copy.splice(i, 1);
    changeSetting("confettiColors", copy);
  }

  function changeColor(i, val) {
    let copy = [...settings.confettiColors];
    copy[i] = val;
    changeSetting("confettiColors", copy);
  }

  return (
    <div className={styles.body}>
      <div
        className={styles.label5}
        style={{ margin: "0", fontWeight: "500", padding: "0 1rem" }}
      >
        Finished Page
      </div>
      <div className={styles.settingVertical}>
        <div className={styles.label6}>Finish Button</div>

        <div className={styles.settingHorizontal}>
          <ToggleSwitch
            startChecked={settings.finishWithoutFinishing}
            handleCheck={(val) => changeSetting("finishWithoutFinishing", val)}
          ></ToggleSwitch>
          <div className={styles.text_3}>
            {" "}
            Enable 'Finish' button before completion
          </div>
        </div>
        <div className={styles.settingHorizontal}>
          <ToggleSwitch
            startChecked={settings.returnable}
            handleCheck={(val) => changeSetting("returnable", val)}
          ></ToggleSwitch>
          <div className={styles.text_3}> Enable to return to survey</div>
        </div>
      </div>
      <div className={styles.settingVertical}>
        <div className={styles.label6}>Finished Page Confetti</div>

        <div className={styles.settingHorizontal}>
          <ToggleSwitch
            startChecked={settings.confetti}
            handleCheck={(val) => changeSetting("confetti", val)}
          ></ToggleSwitch>
          <div className={styles.text_3}>Drop Confetti</div>
        </div>
        {settings.confetti && (
          <>
            <div className={styles.settingHorizontal}>
              <ToggleSwitch
                startChecked={settings.useOwnColors}
                handleCheck={(val) => changeSetting("useOwnColors", val)}
              ></ToggleSwitch>
              <div className={styles.text_3}>Custom Confetti Colors</div>
            </div>

            {settings.useOwnColors && (
              <div className={styles.confettiColors}>
                {settings.confettiColors &&
                  settings.confettiColors.map((color, i) => (
                    <ColorPicker
                      defaultColor={color}
                      remove={() => removeColor(i)}
                      onChange={(val) => changeColor(i, val)}
                    />
                  ))}
                <AddColorSimple onDone={addColor} />
              </div>
            )}
          </>
        )}
      </div>
      <div className={styles.settingVertical}>
        <div className={styles.label6}>Page Container</div>
        <div className={styles.settingHorizontal}>
          <ToggleSwitch
            startChecked={settings?.finishedBackgroundColor}
            handleCheck={(val) =>
              changeSetting("finishedBackgroundColor", val ? "#FFFFFF" : false)
            }
          ></ToggleSwitch>

          <div className={styles.text_3}>Has Background</div>
        </div>
        {settings?.finishedBackgroundColor && (
          <div className={styles.settingVertical}>
            <ColorPickerContainer
              onChange={(color) =>
                changeSetting("finishedBackgroundColor", color)
              }
              defaultColor={
                settings?.finishedBackgroundColor
                  ? settings?.finishedBackgroundColor
                  : "#FFFFFF"
              }
              title="Container Background Color"
              //   colors={settings.colorBank}
            />
          </div>
        )}
      </div>

      <div className={styles.settingVertical}>
        <SelectFieldCustom
          onChange={(color) =>
            changeSetting("finishedBorderShadow", color.value)
          }
          value={
            settings?.finishedBorderShadow
              ? shadows.find((s) => s.value === settings?.finishedBorderShadow)
              : { value: "", label: "No Shadow" }
          }
          label="Container Border Shadow"
          // icon="bi-chevron-down"
          options={shadows}
          select
          anyWhereOptions
        />
      </div>
      {settings.finishedBorderShadow && (
        <div className={styles.settingVertical}>
          <NumberInputWithSlider
            val={
              settings?.finishedBorderRadius ||
              settings?.finishedBorderRadius === 0
                ? settings?.finishedBorderRadius
                : 0.5
            }
            onChange={(val) => changeSetting("finishedBorderRadius", val)}
            min={0}
            max={4}
            step={0.25}
            color={"#15bcc7"}
            title="Container Border radius"
          ></NumberInputWithSlider>
        </div>
      )}
    </div>
  );
}
