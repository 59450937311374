// External
import React, { useState } from "react";

// Internal
import { ContactRoutes } from "routes";
import styles from "./Contacts.module.scss";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Button from "components/Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { HeaderContainer } from "components/layouts/Header/HeaderContainer";

export const Contacts = ({
  refetchTasks,
  onExport,
  roles,
  user,
  organization,
}) => {
  const [active, setActive] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  function updateActive(id, to) {
    setActive(id);
    navigate(to);
  }

  const menuItems = [];

  if (roles?.canViewContacts) {
    menuItems.push({
      id: 0,
      name: "Contacts",
      to: "/ContactManagement",
      icon: `people`,
    });
  }
  if (roles?.canViewOrgAudiences) {
    menuItems.push({
      id: 1,
      name: "Audiences",
      to: "/ContactManagement/Audiences",
      icon: `person-video3`,
    });
  }
  // if (roles?.canEditContacts) {
  //   menuItems.push({
  //     id: 2,
  //     name: "Uploads",
  //     to: "/ContactManagement/Uploads",
  //     icon: `person-video3`,
  //   });
  // }

  function getPath() {
    return `Contact Management/${menuItems[active]?.name}`;
  }

  return (
    <div className={styles.pages}>
      {!location?.pathname?.includes("/ContactManagement/Audiences/") && (
        <HeaderContainer
          path={getPath()}
          user={user}
          organization={organization}
          roles={roles}
        >
          {" "}
          <FlexRow gap={".5rem"}>
            {menuItems.map((item) => (
              <Button
                key={item?.id}
                active={item.id === active}
                onClick={() => updateActive(item.id, item.to)}
                padding={"0 .5rem"}
                height={2}
              >
                {item.name}
              </Button>
            ))}
          </FlexRow>
        </HeaderContainer>
      )}

      <ContactRoutes
        refetchTasks={refetchTasks}
        onExport={onExport}
        updateRoute={setActive}
        user={user}
        roles={roles}
        organization={organization}
      />
    </div>
  );
};
