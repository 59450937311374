/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2024 Reaction Data Inc. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: brodyspencer

*/
// External
import React, { useEffect, useState } from "react";
import { ChartContainer, EmptyRow } from "./ChartContainer";
import {
  DndContext,
  closestCenter,
  DragOverlay,
  // useSensor,
  // MouseSensor,
  // TouchSensor,
  // KeyboardSensor,
  // useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove as dndKitArrayMove,
  rectSortingStrategy,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  useCopyVizToPage,
  useCreateVisualization,
  useDeleteOneVisualization,
} from "api/resources/projects/visualizations";
import { useUpdateAnalysis } from "api/resources/organization/analysis";

// Internal
import styles from "./Analysis.module.scss";
import { ErrorBanner } from "pages/error_page/ErrorPage";
import { Loading } from "components/Loading/Loading";

export default function Analysis({
  analysis,
  addChart,
  refetch,
  canEdit,
  custom_fields,
  externalFilter,
  preview,
  page,
  pages,
  goToPage,
  reportId,
  currViz,
  setCurrViz,
  editing,
  setEditing,
  setVisibleItems,
  visibleItems,
  setLabels,
  zoom,
  setZoom,
  setShowDrill,
  brandNew,
  setBrandNew,
  onEditChart,
  onQuickSave,
  widescreen,
  dash,
  reloadReport,
}) {
  // Create a top bar with an event listener for on drag over to trigger top ones
  const createViz = useCreateVisualization();
  const updateAnalysis = useUpdateAnalysis();
  const deleteViz = useDeleteOneVisualization();
  const copyVizToPage = useCopyVizToPage();
  const [showBottom, setShowBottom] = useState(false);
  const [kickedOut, setKickedOut] = useState(null);
  const [activeId, setActiveId] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [pause, setPause] = useState(false);
  const [widths, setWidths] = useState(
    analysis?.widths ? JSON.parse(analysis?.widths) : {}
  );
  const [heights, setHeights] = useState(
    analysis?.heights ? JSON.parse(analysis?.heights) : {}
  );
  const [adjustingWidths, setAdjustingWidths] = useState(false);
  const [preMoveWidths, setPreMoveWidths] = useState(null);
  const [preMoveHeight, setPreMoveHeight] = useState(null);
  const [preMoveLayout, setPreMoveLayout] = useState(null);
  const [active, setActive] = useState(false);

  const [dataMap, setDataMap] = useState({});
  const [useDataCounter, setUseDataCounter] = useState(0);

  function setUpOpening() {
    setTimeout(() => setOpening(false), 1000);
    return true;
  }

  const [opening, setOpening] = useState(setUpOpening());
  const [newSpotUp, setNewSpotUp] = useState("");
  const [newSpotDown, setNewSpotDown] = useState("");
  const [deleting, setDeleting] = useState("");
  const [layout, setLayout] = useState(
    analysis?.layout ? JSON.parse(analysis?.layout) : []
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!analysis) {
      // Might happen if somebody publishes, the analysis is deleted
      reloadReport();
      setLoading(true);
      return;
    }

    if (
      !dragging &&
      analysis.layout &&
      analysis.layout !== JSON.stringify(layout)
    ) {
      setLayout(JSON.parse(analysis.layout));
    }

    if (pulling == -1) {
      if (analysis.heights !== JSON.stringify(heights)) {
        setHeights(analysis?.heights ? JSON.parse(analysis?.heights) : {});
      }
    }

    if (!adjustingWidths) {
      if (analysis?.widths !== JSON.stringify(widths)) {
        setWidths(analysis?.widths ? JSON.parse(analysis?.widths) : {});
      }
    }

    if (loading) setLoading(false);
  }, [analysis]);

  function clearEmpties(newLayout) {
    let found = true;
    while (found) {
      found = false;
      for (let i = 0; i < newLayout.length; i++) {
        if (!newLayout[i].length) {
          found = true;
          newLayout.splice(i, 1);
          // TODO decrement heights
          continue;
        }
      }
    }
  }

  const removeAtIndex = (array, index) => {
    return [...array.slice(0, index), ...array.slice(index + 1)];
  };

  const insertAtIndex = (array, index, item) => {
    return [...array.slice(0, index), item, ...array.slice(index)];
  };

  const arrayMove = (array, oldIndex, newIndex) => {
    return dndKitArrayMove(array, oldIndex, newIndex);
  };

  const handleDragStart = ({ active }) => {
    setActiveId(active.id);
    setShowBottom(true);
    setDragging(true);
    setPreMoveWidths({ ...widths });
    setPreMoveLayout(layout.map((row) => [...row]));
    let rowInd = layout.findIndex((row) => row.includes(active.id));
    if (heights[rowInd]) {
      setPreMoveHeight(heights[rowInd]);
    }
    setUseDataCounter(useDataCounter + 1);

    setShowDrill(true);
    setZoom(1);
  };

  const resolveKickedOut = (activeRow, newLayout, suspect) => {
    if (kickedOut && kickedOut.bully === suspect) {
      newLayout[activeRow].push(kickedOut.bullied);
      let at = newLayout[activeRow + 1].indexOf(kickedOut.bullied); // should be the only one in the row, but just in case
      newLayout[activeRow + 1].splice(at, 1);
      setKickedOut(null);
    }
  };

  const fixWidths = (activeRow, overRow, activeInd, overInd) => {
    let widthsCopy = { ...widths };

    // Leaving Actions

    // remove widths you're leaving
    if (widthsCopy[layout[activeRow][activeInd]]) {
      for (let id of layout[activeRow]) {
        delete widthsCopy[id];
      }
    }

    // Leaving a row, not your original, that had widths, that you broke
    if (layout[activeRow].length > 1) {
      let otherViz = layout[activeRow][activeInd ? 0 : 1];
      if (preMoveWidths[otherViz]) {
        let origNeighbors = preMoveLayout.find((row) => row.includes(activeId));
        if (!origNeighbors.includes(otherViz)) {
          let origRow = preMoveLayout.find((row) => row.includes(otherViz));
          for (let viz of origRow) {
            widthsCopy[viz] = preMoveWidths[viz];
          }
        }
      }
    }

    if (layout[activeRow].length == 1 && overRow !== "bottom") {
      // not getting your own row

      // Entering Actions

      // Entering a row with widths
      if (widthsCopy[layout[overRow][0]]) {
        for (let id of layout[overRow]) {
          delete widthsCopy[id];
        }
      }

      // Returning to your old row that had widths
      let otherViz = layout[overRow][0];
      if (preMoveWidths[otherViz]) {
        let origNeighbors = preMoveLayout.find((row) => row.includes(activeId));
        if (origNeighbors.includes(otherViz)) {
          for (let viz of origNeighbors) {
            widthsCopy[viz] = preMoveWidths[viz];
          }
        }
      }
    }

    setWidths(widthsCopy);
  };

  function fixHeights(activeRow, overRow) {
    let shouldBeHeights = layout.map((row, ind) =>
      heights[ind] ? heights[ind] : 0
    );

    if (overRow === "bottom") {
      if (preMoveHeight) {
        shouldBeHeights.push(preMoveHeight);
      }
    } else if (layout[activeRow].length > 1) {
      // getting a new row

      if (layout[activeRow].length === 4 && kickedOut) {
        shouldBeHeights.splice(activeRow + 1, 1);
      }

      let ind = overRow < activeRow ? activeRow : overRow;
      shouldBeHeights.splice(ind, 0, preMoveHeight ? preMoveHeight : 0);
    } else {
      // joining a row
      // Will only ever be in a single row if joining in.
      shouldBeHeights.splice(activeRow, 1);

      if (layout[overRow].length == 4) {
        // kicking one out
        if (overRow < activeRow) {
          // moving up
          shouldBeHeights.splice(overRow + 1, 0, shouldBeHeights[overRow]);
        } else {
          // moving down
          // active row has been removed
          shouldBeHeights.splice(overRow, 0, shouldBeHeights[overRow - 1]);
        }
      }
    }

    let newHeights = {};
    for (let i = 0; i < shouldBeHeights.length; i++) {
      if (shouldBeHeights[i]) {
        newHeights[i] = shouldBeHeights[i];
      }
    }
    setHeights(newHeights);
  }

  function rowsAndIndexes(active, over) {
    let activeRow = active.data.current.sortable.containerId;
    let overRow = over.data.current?.sortable.containerId;

    if (overRow === "Top Row") {
      overRow = 0;
    }
    if (activeRow === "Top Row") {
      activeRow = 0;
    }

    let activeIndex = activeId
      ? layout[activeRow].indexOf(activeId)
      : active.data.current.sortable.index;
    let overIndex = over.data.current.sortable.index;

    return [activeRow, overRow, activeIndex, overIndex];
  }

  const handleDragOver = (event) => {
    let active = event.active;
    let over = event.over;
    if (!over?.id) {
      return;
    }

    if (pause || over.id === active.id) {
      return;
    }

    let newLayout = layout.map((row) => [...row]);

    let [activeRow, overRow, activeIndex, overIndex] = rowsAndIndexes(
      active,
      over
    );

    if (activeRow !== overRow) {
      fixWidths(activeRow, overRow, activeIndex, overIndex);
      fixHeights(activeRow, overRow);

      const vizId = newLayout[activeRow][activeIndex];

      if (overRow === "bottom") {
        setNewSpotDown(vizId);
        setTimeout(() => setNewSpotDown(""), 600);
        // console.log("hit bottom");
        newLayout[activeRow] = removeAtIndex(newLayout[activeRow], activeIndex);
        newLayout.push([active.id]);
        clearEmpties(newLayout);
        setLayout(newLayout);
        setPause(true);
        setTimeout(() => setPause(false), 750);
        return;
      }

      if (newLayout[activeRow].length === 4 && kickedOut) {
        // If it was in a row of four, see if it kicked any out to replace it
        resolveKickedOut(activeRow, newLayout, active.id);
      }

      if (newLayout[activeRow].length > 1) {
        // If you're leaving a row of many, get your new row

        // take it out of its current row
        let id = newLayout[activeRow][activeIndex];
        newLayout[activeRow].splice(activeIndex, 1);

        // find the direction
        let ind = overRow < activeRow ? activeRow : overRow;
        newLayout.splice(ind, 0, [id]);
      } else {
        // Joining another row
        newLayout[activeRow].splice(activeIndex, 1);
        if (newLayout[overRow].length == 1) {
          if (event.delta.x > 0) {
            newLayout[overRow].push(activeId);
          } else {
            newLayout[overRow].splice(overIndex, 0, activeId);
          }
        } else {
          newLayout[overRow].splice(overIndex, 0, activeId);
        }

        if (newLayout[overRow].length === 5) {
          // The last item in the row needs to be kicked to it's own row
          let bullied = newLayout[overRow][4];
          setKickedOut({
            bully: active.id,
            bullied: bullied,
          });

          newLayout[overRow].splice(4, 1);
          newLayout.splice(overRow + 1, 0, [bullied]);
        }
      }

      if (overRow > activeRow) {
        // going down
        setNewSpotDown(vizId);
        setTimeout(() => setNewSpotDown(""), 600);
      } else {
        // going up
        setNewSpotUp(vizId); // Easier to see the transition, otherwise I like down for all else
        setTimeout(() => setNewSpotUp(""), 750);
      }

      setPause(true);
      setTimeout(() => setPause(false), 750);
      // Important for moving between rows, may glitch out otherwise

      clearEmpties(newLayout);
      setLayout(newLayout);
    } else if (activeRow === overRow) {
      // newLayout[activeRow] = arrayMove(
      //   newLayout[activeRow],
      //   activeIndex,
      //   overIndex
      // );
      // console.log("should be", newLayout);
      // setTimeout(
      //   () =>
      //     setLayout((old) => {
      //       return newLayout;
      //     }),
      //   1000
      // );
      // setLayout(newLayout);
    }
  };

  const handleDragEnd = ({ active, over }) => {
    if (!over) {
      return;
    }

    let newLayout = layout.map((row) => [...row]);

    let [activeRow, overRow, activeIndex, overIndex] = rowsAndIndexes(
      active,
      over
    );

    if (activeRow === overRow) {
      newLayout[activeRow] = arrayMove(
        newLayout[activeRow],
        activeIndex,
        overIndex
      );
    }

    clearEmpties(newLayout);
    setLayout(newLayout);
    saveLWH(newLayout);

    setKickedOut(null);
    setShowBottom(false);

    setPreMoveWidths(null);
    setPreMoveLayout(null);
    setPreMoveHeight(null);

    setDragging(false);
    setDataMap({});
    setUseDataCounter(0);

    setShowDrill(false);
  };

  function getVizData(viz) {
    return {
      designSettings: JSON.stringify(viz.designSettings),
      questionIds: viz.question.map((q) => q.id),
      projectIdsArray: JSON.stringify(viz.projectIdsArray),
      tagIdsArray: JSON.stringify(viz.tagIdsArray),
      title: viz.title,
      type: viz.type,
      pivotString: viz.pivotString,
      filters: viz.filters,
      analysisId: analysis.id,
      programIds: viz.programIds ? JSON.stringify(viz.programIds) : null,
      questionTags: viz.questionTags ? JSON.stringify(viz.questionTags) : null,
      comparison: viz.comparison ? JSON.stringify(viz.comparison) : null,
      dataMethod: viz.dataMethod,
      data: viz.data,
    };
  }

  function onCopy(viz) {
    createViz.mutate(
      {
        data: getVizData(viz),
      },
      {
        onSuccess: (data) => {
          let rowInd = layout.findIndex((row) => row.includes(viz.id));
          let heightsArray = layout.map((row, ind) =>
            heights[ind] ? heights[ind] : 0
          );

          let newLayout = layout.map((row) => [...row]);
          newLayout.splice(rowInd + 1, 0, [data.createVisualization.id]);
          heightsArray.splice(rowInd + 1, 0, heightsArray[rowInd]);
          let newHeights = {};
          for (let i = 0; i < heightsArray.length; i++) {
            if (heightsArray[i]) {
              newHeights[i] = heightsArray[i];
            }
          }
          setHeights(newHeights);
          setLayout(newLayout);

          saveLWH(newLayout, undefined, newHeights);

          setBrandNew(data.createVisualization.id);
          setTimeout(() => setBrandNew(""), 1000);
        },
        onError: (data) => {
          console.log("error copying chart");
        },
      }
    );
  }

  function onCopyToPage(viz, page) {
    let data = getVizData(viz);
    data.analysisId = page;
    copyVizToPage.mutate(
      {
        data: data,
        analysisId: page,
        reportId: reportId,
      },
      {
        onSuccess: (data) => {
          if (currViz) setCurrViz(null);
          if (data.page) {
            goToPage(data.page.id);
          }
        },
        onError: (data) => {
          console.log("error adding to user's dash");
        },
      }
    );
  }

  function saveLWH(newLayout, newWidths, newHeights) {
    let stringLayout = JSON.stringify(newLayout ? newLayout : layout);
    let stringWidths = JSON.stringify(newWidths ? newWidths : widths);
    let stringHeights = JSON.stringify(newHeights ? newHeights : heights);

    if (!preview) {
      updateAnalysis.mutate(
        {
          id: analysis.id,
          data: {
            layout: stringLayout,
            widths: stringWidths,
            heights: stringHeights,
          },
        },
        {
          onSuccess: (data) => {
            // console.log("Layouts saved");
            if (stringWidths !== data.updateAnalysis.widths) {
              setWidths(JSON.parse(data.updateAnalysis.widths));
            }
            if (stringLayout !== data.updateAnalysis.layout) {
              setLayout(JSON.parse(data.updateAnalysis.layout));
            }
            if (stringHeights !== data.updateAnalysis.heights) {
              setHeights(JSON.parse(data.updateAnalysis.heights));
            }
          },
          onError: (data) => {},
        }
      );
    }
  }

  function saveLayout(newLayout) {
    if (!preview) {
      updateAnalysis.mutate(
        {
          id: analysis.id,
          data: {
            layout: JSON.stringify(newLayout),
          },
        },
        {
          onSuccess: (data) => {
            // console.log("Updated Layout");
            if (refetch) {
              refetch();
            }
          },
          onError: (data) => {},
        }
      );
    }
  }

  function saveWidths() {
    if (!preview) {
      updateAnalysis.mutate(
        {
          id: analysis.id,
          data: {
            widths: JSON.stringify(widths),
          },
        },
        {
          onSuccess: (data) => {
            // console.log("Updated Widths");
            if (JSON.stringify(widths) !== data.updateAnalysis.widths) {
              setWidths(JSON.parse(data.updateAnalysis.widths));
            }
          },
          onError: (data) => {},
        }
      );
    }
  }

  function saveHeights() {
    if (!preview) {
      updateAnalysis.mutate(
        {
          id: analysis.id,
          data: {
            heights: JSON.stringify(heights),
          },
        },
        {
          onSuccess: (data) => {
            console.log("Updated Heights");
            if (JSON.stringify(heights) !== data.updateAnalysis.heights) {
              setWidths(JSON.parse(data.updateAnalysis.heights));
            }
          },
          onError: (data) => {},
        }
      );
    }
  }

  function getWidth(vizId) {
    if (preview) {
      return widths[vizId] / 3 + "px";
    }
    if (widths[vizId]) {
      return widths[vizId] + "px";
    }

    return "100%";
  }

  function deleteFunction(chartId) {
    if (canEdit) {
      setDeleting(chartId);

      setTimeout(() => {
        deleteViz.mutate(
          {
            id: chartId,
          },
          {
            onSuccess: () => {
              let newWidths = { ...widths };
              if (newWidths[chartId]) {
                let neighbors = layout.find((row) => row.includes(chartId));
                if (neighbors) {
                  for (let viz of neighbors) {
                    delete newWidths[viz];
                  }
                }
              }

              let newLayout = [...layout];
              let row = 0;
              let col = 0;
              for (let i = 0; i < layout.length; i++) {
                for (let k = 0; k < layout[i].length; k++) {
                  if (newLayout[i][k] === chartId) {
                    row = i;
                    col = k;
                    break;
                  }
                }
              }

              newLayout[row].splice(col, 1);
              let newHeights = { ...heights };

              if (newLayout[row].length === 0) {
                newLayout.splice(row, 1);
                if (newHeights[row]) {
                  delete newHeights[row];
                }
                let copy = { ...newHeights };
                for (let num of Object.keys(newHeights)) {
                  let rowNum = parseInt(num);
                  if (rowNum > row) {
                    delete copy[rowNum];
                    copy[rowNum - 1] = newHeights[rowNum];
                  }
                }
                newHeights = copy;
              }

              setLayout(newLayout);
              setWidths(newWidths);
              setHeights(newHeights);

              saveLWH(newLayout, newWidths, newHeights);

              setDeleting("");
            },
          }
        );
      }, 750);

      if (currViz && currViz.id === chartId) {
        setCurrViz(undefined);
      }
    }
  }

  function setUpWidths(rowInd) {
    let copy = { ...widths };
    for (let id of layout[rowInd]) {
      let chart = document.getElementById(id + "Container");
      copy[id] = chart.clientWidth;
    }
    setWidths(copy);
    setAdjustingWidths(true);
  }

  function onPullWidth(rowInd, vizInd, delta, onSuccess) {
    let copy = { ...widths };

    if (
      copy[layout[rowInd][vizInd + 1]] - delta > 100 &&
      copy[layout[rowInd][vizInd]] + delta > 100
    ) {
      copy[layout[rowInd][vizInd + 1]] -= delta;
      copy[layout[rowInd][vizInd]] += delta;
      setWidths(copy);

      onSuccess();
    }
  }

  function onDonePullingWidth() {
    saveWidths();
    setAdjustingWidths(false);
  }

  function getRowHeight(rowInd) {
    let height = heights[rowInd] ? heights[rowInd] : 300;
    return height;
  }

  const [initialY, setInitialY] = useState(0);
  const [pulling, setPulling] = useState(-1);
  const [analHeight, setAnalHeight] = useState();

  const onMouseDown = (e, rowInd) => {
    setPulling(rowInd);
    setInitialY(e.clientY);

    let copy = { ...heights };
    copy[rowInd] = getRowHeight(rowInd);
    setHeights(copy);

    let anal = document.getElementById("analysisContainer");
    if (anal) {
      setAnalHeight(anal.clientHeight);
    }
  };

  function onRowPull(e, rowInd) {
    if (pulling == -1) return;

    let deltaY = e.clientY - initialY;

    let copy = { ...heights };
    copy[rowInd] += deltaY;

    if (copy[rowInd] + deltaY > 50) {
      // 195 for a litle spacer room
      setInitialY(e.clientY);
      setHeights(copy);

      if (deltaY > 0) {
        setAnalHeight((height) => height + deltaY);
      }
    }
  }

  function donePullingHeight() {
    if (pulling == -1) return;

    setPulling(-1);
    setInitialY(0);
    saveHeights();
    setAnalHeight(null);
  }

  function initFilters(visualization) {
    let them = visualization.filters
      ? JSON.stringify(JSON.parse(visualization.filters))
      : "{}";
    return them;
  }

  function updateData(dataset, vizId) {
    if (vizId === activeId) {
      setDataMap((old) => {
        let copy = { ...old };
        copy[vizId] = dataset;
        return copy;
      });
    }
  }

  function getOverlayWidth() {
    // "100%"
    let normal = document.getElementById(activeId + "Container");
    if (normal) {
      return normal.clientWidth;
    } else {
      return getWidth(activeId);
    }
  }

  useEffect(() => {
    const container = document.getElementById(
      "report-container2-" + analysis?.id
    );
    if (container) {
      // const content = document.getElementById("report-content-" + analysis?.id);
      let scale = 1;
      let startDistance = 0;
      let initialScale = scale;

      container.addEventListener("wheel", (e) => {
        if (e.ctrlKey) {
          e.preventDefault();
          scale += e.deltaY * -0.01;
          scale = Math.min(Math.max(0.125, scale), 4);
          // content.style.transform = `scale(${scale})`;
          setZoom(scale);
        }
      });

      container.addEventListener("touchstart", (e) => {
        if (e.touches.length === 2) {
          e.preventDefault();
          const touch1 = e.touches[0];
          const touch2 = e.touches[1];
          startDistance = Math.hypot(
            touch2.pageX - touch1.pageX,
            touch2.pageY - touch1.pageY
          );
          initialScale = scale;
        }
      });

      container.addEventListener("touchmove", (e) => {
        if (e.touches.length === 2) {
          e.preventDefault();
          const touch1 = e.touches[0];
          const touch2 = e.touches[1];
          const currentDistance = Math.hypot(
            touch2.pageX - touch1.pageX,
            touch2.pageY - touch1.pageY
          );
          scale = initialScale * (currentDistance / startDistance);
          scale = Math.min(Math.max(0.125, scale), 4);
          // content.style.transform = `scale(${scale})`;
          setZoom(scale);
        }
      });
    }
  }, []);

  function handleDragMove(e) {
    let container = document.getElementById("scaledContainer" + analysis.id);
    let overlay = document.getElementById(activeId + "Container overlay");
    overlay =
      overlay.parentElement.id !== "analysisContainer"
        ? overlay.parentElement
        : null;
    if (container && overlay) {
      // the overlay is offset to the right and left of what the 'dim's right and left are, so detract those from the transform 3d
      let dim = container.getBoundingClientRect();
      let overlayDim = overlay.getBoundingClientRect();
      let x = e.target.clientX;
      let y = e.target.clientY;
    }
  }

  return (
    <>
      {createViz.isError && (
        <ErrorBanner
          error={createViz.error}
          message="Error creating visualization"
        />
      )}
      {updateAnalysis.isError && (
        <ErrorBanner
          error={updateAnalysis.error}
          message="Error updating page"
        />
      )}
      {deleteViz.isError && (
        <ErrorBanner
          error={deleteViz.error}
          message="Error deleting visualization"
        />
      )}
      {copyVizToPage.isError && (
        <ErrorBanner
          error={copyVizToPage.error}
          message="Error copying visualization"
        />
      )}
      {loading ? (
        <Loading />
      ) : (
        <>
          <div
            className={`${styles.page} ${preview ? styles.zoom : ""} ${
              widescreen ? styles.widescreen : ""
            }`}
            id={analysis?.id}
          >
            {layout.length === 0 && (
              <div className={styles.emptyMessage}>
                It looks like you have no charts!
                {editing && (
                  <>
                    {" "}
                    Click <a onClick={() => addChart("chart")}>+ Add</a> to add
                    one!
                  </>
                )}
                {!editing && (
                  <>
                    {" "}
                    <a onClick={() => setEditing(true)}>{`Edit ${
                      dash ? "dashboard" : "report"
                    }?`}</a>
                  </>
                )}
              </div>
            )}

            {layout.length > 0 && (
              <DndContext
                // sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                // onDragMove={handleDragMove}
              >
                <div
                  className={styles.analysisContainer}
                  id="analysisContainer"
                  style={{
                    height: analHeight ? analHeight + "px" : "",
                    width: active ? "80%" : "",
                  }}
                >
                  {layout.map((row, rowInd) => (
                    <React.Fragment key={rowInd}>
                      <div
                        className={styles.chartSection}
                        key={rowInd}
                        style={{
                          height: getRowHeight(rowInd) + "px",
                          // minHeight: preview ? "0px" : "",
                        }}
                      >
                        <SortableContext
                          id={rowInd === 0 ? "Top Row" : rowInd}
                          items={row}
                          strategy={horizontalListSortingStrategy}
                        >
                          {row.map((vizId, vizInd) => (
                            <ChartContainer
                              key={vizId}
                              vizId={vizId}
                              chart={currViz?.id === vizId ? currViz : null}
                              data={dataMap[vizId]}
                              setData={(dataset) => updateData(dataset, vizId)}
                              setUpDataCounter={useDataCounter}
                              width={getWidth(vizId)}
                              deleteFunction={deleteFunction}
                              canEdit={canEdit}
                              canMove={canEdit}
                              custom_fields={custom_fields}
                              externalFilter={externalFilter}
                              showSpace={vizInd < row.length - 1}
                              onPullWidth={(delta, onSuccess) =>
                                onPullWidth(rowInd, vizInd, delta, onSuccess)
                              }
                              onDonePulling={onDonePullingWidth}
                              setUpWidths={() => setUpWidths(rowInd)}
                              preview={preview}
                              update={!dash && rowInd < 3 && page === 1}
                              height={
                                preview
                                  ? Math.floor(getRowHeight(rowInd) / 3.5) +
                                    "px"
                                  : getRowHeight(rowInd) + "px"
                              }
                              onCopy={onCopy}
                              onCopyToPage={onCopyToPage}
                              pages={pages}
                              opening={opening || brandNew === vizId}
                              newSpotUp={newSpotUp === vizId}
                              newSpotDown={newSpotDown === vizId}
                              deleting={deleting === vizId}
                              setCurrViz={setCurrViz}
                              active={currViz?.id === vizId}
                              setLabels={setLabels}
                              editing={editing}
                              setVisibleItems={setVisibleItems}
                              visibleItems={visibleItems}
                              setShowDrill={setShowDrill}
                              onEditChart={onEditChart}
                              onQuickSave={onQuickSave}
                              inDash={dash}
                            />
                          ))}
                        </SortableContext>
                      </div>
                      <div className={styles.rowSpacer}>
                        <div
                          className={`${!editing && styles.dragBarEmpty} ${
                            editing && styles.dragBar
                          }`}
                          onMouseDown={(e) =>
                            editing ? onMouseDown(e, rowInd) : undefined
                          }
                          onMouseUp={editing ? donePullingHeight : undefined}
                          onMouseMove={(e) =>
                            editing ? onRowPull(e, rowInd) : undefined
                          }
                          onMouseLeave={editing ? donePullingHeight : undefined}
                        >
                          <div className={styles.indicator}>
                            <i className="bi bi-chevron-bar-expand"></i>
                          </div>
                          {pulling == rowInd && (
                            <>
                              <div
                                className={`${styles.pullPadding} ${styles.top}`}
                              ></div>
                              <div
                                className={`${styles.pullPadding} ${styles.bottom}`}
                              ></div>
                            </>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}

                  {showBottom && (
                    <SortableContext
                      id={"bottom"}
                      items={["empty"]}
                      strategy={horizontalListSortingStrategy}
                    >
                      <EmptyRow id={"empty"} />
                    </SortableContext>
                  )}
                </div>
                {activeId && (
                  <DragOverlay
                  // style={getOverlayStyle()}
                  >
                    <ChartContainer
                      vizId={activeId}
                      chart={currViz?.id === activeId ? currViz : null}
                      // width={getOverlayWidth()}
                      data={dataMap[activeId]}
                      dragOverlay
                      custom_fields={custom_fields}
                      externalFilter={externalFilter}
                      // showDrill={showDrill}
                      // setShowDrill={setShowDrill}
                    />
                  </DragOverlay>
                )}
              </DndContext>
            )}

            {/* {window.outerWidth <= 900 && (
          <div
            className={styles.analysisContainer}
            id="analysisContainer"
            style={{
              height: analHeight ? analHeight + "px" : "",
              width: active ? "80%" : "",
            }}
          >
            {layout.map((row, rowInd) => (
              <React.Fragment key={rowInd}>
                {row.map((vizId, vizInd) => (
                  <React.Fragment key={vizInd}>
                    <div
                      className={styles.chartSection}
                      key={vizInd}
                      style={{ height: "300px" }}
                    >
                      <ChartContainer
                        key={vizId}
                        vizId={vizId}
                        chart={currViz?.id === vizId ? currViz : null}
                        width={"100%"}
                        deleteFunction={deleteFunction}
                        canEdit={canEdit}
                        canMove={canEdit}
                        custom_fields={custom_fields}
                        externalFilter={externalFilter}
                        refetch={refetch}
                        showSpace={false}
                        onPullWidth={(delta, onSuccess) =>
                          onPullWidth(rowInd, vizInd, delta, onSuccess)
                        }
                        onDonePulling={onDonePullingWidth}
                        setUpWidths={() => setUpWidths(rowInd)}
                        preview={preview}
                        onCopy={onCopy}
                        onCopyToPage={onCopyToPage}
                        pages={pages}
                        opening={opening || brandNew === vizId}
                        deleting={deleting === vizId}
                      />
                    </div>
                    <div className={styles.rowSpacer}></div>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </div>
        )} */}
          </div>
        </>
      )}
    </>
  );
}
