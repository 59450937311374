import React from "react";
import styles from "./HorizontalBar.module.scss";

/**
 * A customized Label
 * @param props any children contained in the <Label></Label>
 * @returns {React.ReactElement} a Label
 */
export function HorizontalBar({
  width,
  height,
  backgroundColor,
  rotate,
  style,
}) {

  const settings = !rotate
    ? {
        width: width ? width : "100%",
        minHeight: height ? height : "10px",
        backgroundColor: backgroundColor ? backgroundColor : "#d8d9d9",
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: "5px",
      }
    : {
        width: width ? width : "10px",
        minWidth: width ? width : "10px",
        minHeight: height ? height : "100%",
        backgroundColor: backgroundColor ? backgroundColor : "#d8d9d9",
        borderRadius: "5px",
      };

  return <div className={styles.bar} style={{ ...settings, ...style }}></div>;
}
