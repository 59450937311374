import {
  useCreateImage,
  useGetOrgImagesGql,
  useGetSignedURL,
} from "api/resources/projects/images";
import styles from "./Image.module.scss";
import { useState, useRef } from "react";
import axios from "axios";
import { ErrorBanner } from "pages/error_page/ErrorPage";

export function Image({ onImagePick }) {
  const fetchImages = useGetOrgImagesGql();
  const getSignedURL = useGetSignedURL();
  const createImage = useCreateImage();

  const [creating, setCreating] = useState(false);

  const [image, setImage] = useState("");
  const [file, setFile] = useState("");

  const handleChange = (event) => {
    let selectedFile = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
      setFile(selectedFile);
    }
  };

  function onUpload() {
    let img = document.getElementById("uploadedImage");
    let dim = getSignedURL.mutate(
      {
        filename: file.name,
        filetype: file.type,
      },
      {
        onSuccess: async (data) => {
          await uploadToS3(file, data.getSignedURL.signedRequest);
          createImage.mutate(
            {
              url: data.getSignedURL.url,
              name: file.name,
            },
            {
              onSuccess: async (data) => {
                console.log("image created!");
                onImagePick(
                  data.createImage,
                  img?.clientWidth,
                  img?.clientHeight
                );
              },
            }
          );
        },
      }
    );
    setCreating(true);
  }

  async function uploadToS3(file, signedRequest) {
    const options = {
      headers: {
        "Content-Type": file.type,
      },
    };
    const instance = axios.create({
      baseURL: "https://reaction.health/",
      headers: { "Access-Control-Allow-Origin": "*" },
    });
    instance.defaults.withCredentials = true;
    let res = await instance.put(signedRequest, file, options);
  }

  const initX = useRef(0);
  const initY = useRef(0);
  const direction = useRef({
    height: false,
    upGrow: false,
    width: false,
    leftGrow: false,
  });
  const dragging = useRef(false);
  const editRef = useRef();

  const onMouseMove = (e) => {
    if (!dragging.current || !editRef.current) {
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("mousemove", onMouseMove);
      return;
    }

    const deltaY = direction.current.upGrow
      ? initY.current - e.clientY
      : e.clientY - initY.current;
    const deltaX = direction.current.leftGrow
      ? initX.current - e.clientX
      : e.clientX - initX.current;

    let resultY = heightRef.current + deltaY;
    let resultX = widthRef.current + deltaX;

    if (direction.current.height) {
      setHeight((old) => resultY);
      heightRef.current = resultY;
    }
    if (direction.current.width) {
      widthRef.current = resultX;
      setWidth((old) => resultX);
    }

    initY.current = e.clientY;
    initX.current = e.clientX;
  };

  const setUpDrag = (e, dir) => {
    initY.current = e.clientY;
    initX.current = e.clientX;

    heightRef.current = height;
    widthRef.current = width;

    direction.current = dir;
    dragging.current = true;

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);

    const selection = window.getSelection();
    selection.removeAllRanges();
  };

  const onMouseUp = (e) => {
    document.removeEventListener("mouseup", onMouseUp);
    document.removeEventListener("mousemove", onMouseMove);

    e.preventDefault();
    e.stopPropagation();

    // reset all
    dragging.current = false;
    initX.current = 0;
    initY.current = 0;
    direction.current = {
      height: false,
      width: false,
      leftGrow: false,
      upGrow: false,
    };

    // TODO: save
  };

  const [height, setHeight] = useState(60);
  const [width, setWidth] = useState(60);

  const heightRef = useRef();
  const widthRef = useRef();

  function onChooseImage(img) {
    // setImage(img);
    // let container = document.getElementById("image selection container");
    // if (container) {
    //   container.scrollTo({
    //     top: 0,
    //     left: 0,
    //     behavior: "smooth",
    //   });
    // }
    let actual = document.getElementById(img.id);
    onImagePick(img, actual?.clientWidth, actual.clientHeight);
  }

  return (
    <div className={styles.imageImporter} id="image selection container">
      {fetchImages.isError && (
        <ErrorBanner
          error={fetchImages.error}
          message="Error fetching org images"
        />
      )}
      {getSignedURL.isError && (
        <ErrorBanner
          error={getSignedURL.error}
          message="Error creating image"
        />
      )}
      {createImage.isError && (
        <ErrorBanner error={createImage.error} message="Error creating image" />
      )}
      {image && (
        // <div className={styles.editExample}>
        //   <div className={styles.editBox} ref={editRef} style={{}}>
        //     <img
        //       src={image}
        //        style={{}}
        //     ></img>

        //      <div
        //       className={`${styles.adjuster} ${styles.topLeft}`}
        //       // onMouseDown={(e) =>
        //       //   setUpDrag(e, {
        //       //     height: true,
        //       //     upGrow: true,
        //       //     width: true,
        //       //     leftGrow: true,
        //       //   })
        //       // }
        //     ></div>
        //     <div
        //       className={`${styles.adjuster} ${styles.topMiddle}`}
        //       style={{ left: Math.round(width / 2) - 4 + "px" }}
        //       // onMouseDown={(e) => setUpDrag(e, { height: true, upGrow: true })}
        //     ></div>
        //     <div
        //       className={`${styles.adjuster} ${styles.topRight}`}
        //       // onMouseDown={(e) =>
        //       //   setUpDrag(e, {
        //       //     height: true,
        //       //     upGrow: true,
        //       //     width: true,
        //       //   })
        //       // }
        //     ></div>
        //     <div
        //       className={`${styles.adjuster} ${styles.leftMiddle}`}
        //       style={{ top: Math.round(height / 2) - 4 + "px" }}
        //       // onMouseDown={(e) => setUpDrag(e, { width: true, leftGrow: true })}
        //     ></div>
        //     <div
        //       className={`${styles.adjuster} ${styles.rightMiddle}`}
        //       style={{ top: Math.round(height / 2) - 4 + "px" }}
        //       // onMouseDown={(e) => setUpDrag(e, { width: true })}
        //     ></div>

        //     <div
        //       className={`${styles.adjuster} ${styles.bottomLeft}`}
        //       // onMouseDown={(e) =>
        //       //   setUpDrag(e, { height: true, width: true, leftGrow: true })
        //       // }
        //     ></div>
        //     <div
        //       className={`${styles.adjuster} ${styles.bottomMiddle}`}
        //       style={{ left: Math.round(width / 2) - 4 + "px" }}
        //       // onMouseDown={(e) => setUpDrag(e, { height: true })}
        //     ></div>
        //     <div
        //       className={`${styles.adjuster} ${styles.bottomRight}`}
        //       // onMouseDown={(e) => setUpDrag(e, { height: true, width: true })}
        //     ></div>
        //   </div>
        // </div>
        <div className={styles.chosenImage}>
          <img
            style={{ maxHeight: "60px", maxWidth: "60px" }}
            src={image}
            className={styles.uploadedImage}
            id="uploadedImage"
          ></img>
        </div>
      )}
      <div className={styles.imageUpload}>
        <input
          type="file"
          accept="image/*"
          onChange={handleChange}
          style={{ display: "none" }}
          id="imageInput"
        />
        <label htmlFor="imageInput">
          <div className={styles.upload}>
            {image ? "Choose Another" : "Upload Image"}{" "}
            <i className="bi bi-upload"></i>
          </div>
        </label>

        {image && (
          <div className={`${styles.upload} ${styles.use}`} onClick={onUpload}>
            {creating ? (
              <>Creating...</>
            ) : (
              <>
                Confirm <i className="bi bi-check-lg"></i>
              </>
            )}
          </div>
        )}
      </div>
      <div className={styles.imageCatalog}>
        {fetchImages.isSuccess &&
          fetchImages.data.imagesByOrgId.map((img) => (
            <div
              className={styles.catalogPick}
              onClick={() => onChooseImage(img)}
            >
              <img
                src={img.imageURL}
                style={{ maxHeight: "60px", maxWidth: "60px" }}
                id={img.id}
              ></img>
            </div>
          ))}
      </div>
    </div>
  );
}
