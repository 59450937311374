import styles from "./Alerts.module.scss";
import { useRef, useState } from "react";
import { SelectField, TextEntry } from "components/inputs";
import Button from "components/Button/Button";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { useFetchEmailWorthyCustomFields } from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import {
  useGetUserById,
  useSearchUser,
} from "api/resources/organization/users";
import { isValidEmail } from "assets/functions/StringFunctions";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Modal from "components/ReactModal/ReactModal";
import { ErrorBanner } from "pages";
import { AlertEmailBuilder } from "./EmailBuilder/AlertEmailBuilder";
import {
  useCreateAlert,
  useDeleteAlert,
  useUpdateAlert,
} from "api/resources/projects/alerts";
import { Preview } from "./Preview/Preview";
import { Header } from "components/layouts";

export function Alerts({ question, saveQuestion }) {
  const fetchCustomFields = useFetchEmailWorthyCustomFields();
  const updateAlert = useUpdateAlert();
  const deleteAlert = useDeleteAlert();
  const createAlert = useCreateAlert();

  const [creating, setCreating] = useState(false);
  const [activeInd, setActiveInd] = useState(-1);
  const [active, setActive] = useState();

  function onEdit(i) {
    setActive(JSON.parse(JSON.stringify(question.alerts[i])));
    setActiveInd(i);
  }

  function onClose() {
    setActiveInd(-1);
    setActive(null);
  }

  function onCreate() {
    createAlert.mutate(
      {
        questionId: question.id,
      },
      {
        onSuccess: (data) => {
          const alert = data.alert;
          alert.condition = JSON.parse(alert.condition);

          let copy = { ...question };
          copy.alerts.push(alert);

          saveQuestion(copy);

          setActive(alert);
          setActiveInd(copy.alerts.length - 1);
          setCreating(false);

          console.log(alert);
        },
      }
    );

    setCreating(true);
  }

  function onDelete() {
    if (window.confirm("Are you sure you want to delete this alert?")) {
      let copy = { ...question };
      question.alerts.splice(activeInd, 1);
      saveQuestion(copy);

      deleteAlert.mutate(
        { id: active.id },
        {
          onSuccess: () => {
            // console.log("deleted");
          },
        }
      );

      onClose();
    }
  }

  function onUpdate(newAlert) {
    let copy = { ...question };
    if (activeInd > -1) {
      copy.alerts[activeInd] = newAlert;
    } else {
      copy.alerts.push(newAlert);
    }
    saveQuestion(copy);

    updateAlert.mutate(
      {
        id: active.id,
        data: {
          ...newAlert,
          condition: JSON.stringify(newAlert.condition),
        },
      },
      {
        onSuccess: () => {
          console.log("updated");
        },
      }
    );

    setActive(newAlert);
  }

  return (
    <>
      {updateAlert.isError && (
        <ErrorBanner
          error={updateAlert.error}
          message={"Error updating alert"}
        />
      )}
      {deleteAlert.isError && (
        <ErrorBanner
          error={deleteAlert.error}
          message={"Error deleting alert"}
        />
      )}
      {createAlert.isError && (
        <ErrorBanner
          error={createAlert.error}
          message={"Error creating alert"}
        />
      )}
      {fetchCustomFields.isLoading && <Loading></Loading>}
      {fetchCustomFields.isError && (
        <ErrorBanner
          error={fetchCustomFields.error}
          message={"Error loading contact field data"}
        />
      )}
      {fetchCustomFields.isSuccess && (
        <FlexCol gap={"10px"} style={{ padding: "0 5px" }}>
          {question.alerts.map((alert, i) => (
            <Shorthand
              alert={alert}
              question={question}
              onEdit={() => onEdit(i)}
              customFields={fetchCustomFields.data.fields}
            />
          ))}
          <FlexRow gap={0} style={{ paddingTop: "5px" }} justify={"center"}>
            <Button shadow onClick={onCreate}>
              {creating ? <Loading height={30} width={30} /> : "+ Alert"}
            </Button>
          </FlexRow>

          {active && (
            <AlertBuilder
              question={question}
              onDelete={onDelete}
              onClose={onClose}
              alert={active}
              setAlert={onUpdate}
              customFields={fetchCustomFields.data.fields}
            />
          )}
        </FlexCol>
      )}
    </>
  );
}

function Shorthand({ alert, question, onEdit, customFields }) {
  const allOperators = [
    ...genOperators,
    ...scaleOperators,
    ...freeResponseOperators,
  ];

  function getShorthand() {
    const condition = alert.condition;

    const getOperator = () => {
      if (condition.operator !== "answer") {
        if (scaleOperators.some((op) => op.value === condition.operator))
          return condition.operator;

        if (
          condition.operator === "have answered" ||
          condition.operator === "have not answered"
        ) {
          let op = freeResponseOperators.find(
            (op) => op.value === condition.operator
          );
          if (op) return op.label;
        }

        let op = allOperators.find((op) => op.value === condition.operator);
        if (op) return op.label;
      }
      return "";
    };

    const getAnswer = () => {
      if (!condition.answer) return "";
      if (Array.isArray(condition.answer)) {
        let string = "";
        for (let i = 0; i < condition.answer.length; i++) {
          string += condition.answer[i];
          if (i < condition.answer.length - 1) {
            if (i < condition.answer.length - 2) {
              string += ", ";
            } else {
              string += condition.operator === "between" ? " and " : " or ";
            }
          }
        }
        return string;
      }

      if (question.textQuestion) {
        if (
          condition.operator === "is longer than" ||
          condition.operator === "is shorter than"
        ) {
          return condition.answer + " characters";
        }
        if (condition.operator.includes("contain")) {
          return `"${condition.answer}"`;
        }
      }

      return condition.answer;
    };

    return `${getOperator()} ${getAnswer()}`;
  }

  return (
    <>
      <div className={styles.alertShorthand} onClick={onEdit}>
        <FlexRow align={"flex-start"} style={{ overflow: "hidden" }} gap={10}>
          <div className={styles.shorthand} style={{ flexShrink: 0 }}>
            {getShorthand(alert)}
          </div>
          <i className={` ${styles.gray} bi-arrow-right`}></i>
          <FlexCol style={{ overflow: "hidden" }}>
            {alert.toUsers.map((id) => (
              <div className={styles.listItem}>
                <UserName userId={id} />
              </div>
            ))}
            {alert.toEmails.map((email) => (
              <div className={styles.listItem}>{email}</div>
            ))}
            {alert.toUsersWithSame.map((field) => {
              let cField = customFields.find((f) => f.id === field);
              if (cField)
                return (
                  <div className={styles.listItem}>
                    Same {cField.displayName}
                  </div>
                );
              return <></>;
            })}
          </FlexCol>
        </FlexRow>
        {alert.count > 0 && (
          <div className={styles.fired}>
            Happened: <span>{alert.count}</span> times
          </div>
        )}
      </div>
    </>
  );
}

const genOperators = [
  { value: "answer", label: "answer" },
  { value: "do not answer", label: "do not answer" },
];

const scaleOperators = [
  { value: "between", label: "answer between" },
  { value: "below", label: "answer below" },
  { value: "above", label: "answer above" },
];

const freeResponseOperators = [
  { value: "contains", label: "contains" },
  { value: "does not contain", label: "does not contain" },
  { value: "is longer than", label: "is longer than" },
  { value: "is shorter than", label: "is shorter than" },
];

function AlertBuilder({
  question,
  onDelete,
  onClose,
  alert,
  setAlert,
  customFields,
}) {
  const [preview, setPreview] = useState(false);

  // TODO: AutoSave if you can
  function getRelavantOperators() {
    if (question.scaleQuestion) {
      return [...genOperators, ...scaleOperators];
    } else if (question.textQuestion) {
      return freeResponseOperators;
    } else if (question.choiceQuestion) {
      return genOperators;
    }
    return [];
  }

  function getAnswerOptions() {
    if (question.choiceQuestion) {
      let choiceQ = question.choiceQuestion;
      let choices = choiceQ.choices.map((choice) => {
        return { value: choice, label: choice };
      });
      if (choiceQ.hasOtherOption) {
        choices.push({
          value: choiceQ.otherOptionLabel,
          label: choiceQ.otherOptionLabel,
        });
      }
      return choices;
    }

    if (question.scaleQuestion) {
      let scale = question.scaleQuestion;
      let options = [];
      for (let i = scale.min; i < scale.max + 1; i += scale.step) {
        options.push({ value: i, label: i });
      }
      return options;
    }
    return [];
  }

  function changeCondition(condition) {
    let copy = { ...alert };
    copy.condition = condition;
    setAlert(copy);
  }

  function getCondition() {
    return { ...alert.condition };
  }

  function changeOperator(val) {
    let condition = getCondition();
    condition.operator = val;
    condition.answer = "";
    changeCondition(condition);
  }

  function changeOption(val) {
    let condition = getCondition();
    condition.option = val;
    changeCondition(condition);
  }

  function canSelectMultiple() {
    if (alert.condition.operator) {
      let op = alert.condition.operator;
      return op === "answer" || op === "do not answer" || op === "between";
    }
    return false;
  }

  function changeAnswer(chosen) {
    let condition = getCondition();

    if (canSelectMultiple()) {
      if (!chosen.length) {
        condition.answer = null;
      } else {
        if (condition.operator === "between" && chosen.length == 3) {
          let last2 = chosen.slice(1);
          condition.answer = last2.map((c) => c.value);
        } else {
          condition.answer = chosen.map((c) => c.value);
        }
      }
    } else {
      condition.answer = chosen.value;
    }

    changeCondition(condition);
  }

  function changeTextCondition(val) {
    let condition = getCondition();
    condition.answer = val;
    changeCondition(condition);
  }

  function addUser(userId) {
    let copy = { ...alert };
    copy.toUsers.push(userId);
    setAlert(copy);
  }

  function removeUser(userId) {
    let copy = { ...alert };
    let i = copy.toUsers.indexOf(userId);
    if (i > -1) {
      copy.toUsers.splice(i, 1);
      setAlert(copy);
    }
  }

  function addCustomField(id) {
    let copy = { ...alert };
    copy.toUsersWithSame.push(id);
    setAlert(copy);
  }

  function removeCustomField(id) {
    let copy = { ...alert };
    let fieldInd = copy.toUsersWithSame.indexOf(id);
    if (fieldInd > -1) {
      copy.toUsersWithSame.splice(fieldInd, 1);
      setAlert(copy);
    }
  }

  function addEmail(val) {
    let copy = { ...alert };
    copy.toEmails.push(val);
    setAlert(copy);
  }

  function removeEmail(email) {
    let copy = { ...alert };
    let i = copy.toEmails.indexOf(email);
    if (i >= 0) {
      copy.toEmails.splice(i, 1);
      setAlert(copy);
    }
  }

  function toggleEmail(val) {
    let copy = { ...alert };
    copy.sendEmail = val;
    setAlert(copy);
  }

  function getChosenAnswerOptions() {
    const condition = alert.condition;
    if (condition.answer || condition.answer === 0) {
      if (Array.isArray(condition.answer)) {
        return condition.answer.map((val) => {
          return { value: val, label: val };
        });
      } else {
        return { value: condition.answer, label: condition.answer };
      }
    }
    return null;
  }

  function beginEmailToggle(val) {
    // Allows for the smooth transition in the toggle before re-rendering
    setTimeout(() => {
      toggleEmail(val);
    }, 300);
  }

  function changeField(field, val) {
    let copy = { ...alert };
    copy[field] = val;
    setAlert(copy);
  }

  return (
    <>
      <Modal
        show
        onClose={onClose}
        modalStyle={{
          // width: "100%",
          // maxWidth: "600px",
          borderRadius: "1rem",
        }}
        dark
      >
        <div className={styles.settingsContainer}>
          <Header>
            <FlexCol fit>
              <h2>Alert</h2>
              <div className={styles.questionName}>
                "{question?.questionText}"
              </div>
            </FlexCol>
          </Header>

          <div className={styles.alert}>
            {question.type === "Matrix" && (
              <div className={styles.opAndCondition}>
                <div>For</div>
                <div style={{ width: "250px" }}>
                  <SelectField
                    options={question.matrixQuestion.options.map((opt) => {
                      return { value: opt, label: opt };
                    })}
                    value={
                      alert.condition.option
                        ? question.matrixQuestion.options
                            .map((opt) => {
                              return { value: opt, label: opt };
                            })
                            .find((opt) => alert.condition.option === opt.value)
                        : null
                    }
                    onChange={(opt) => changeOption(opt.value)}
                  ></SelectField>
                </div>
              </div>
            )}
            <div className={styles.opAndCondition}>
              <div>{`If ${
                question.textQuestion ? "their answer" : "they"
              }`}</div>
              <div style={{ width: "250px" }}>
                <SelectField
                  options={getRelavantOperators()}
                  value={getRelavantOperators().find(
                    (type) => alert.condition.operator === type.value
                  )}
                  onChange={(option) => changeOperator(option.value)}
                ></SelectField>
              </div>

              {alert.condition.operator && (
                <>
                  {!question.textQuestion && (
                    <div style={{ width: "250px" }}>
                      <SelectField
                        options={getAnswerOptions()}
                        value={getChosenAnswerOptions()}
                        onChange={changeAnswer}
                        selectMultiple={canSelectMultiple(alert)}
                      ></SelectField>
                    </div>
                  )}

                  {question.textQuestion &&
                    (alert.condition.operator === "contains" ||
                      alert.condition.operator === "does not contain") && (
                      <div style={{ width: "300px" }}>
                        <TextEntry
                          placeholder="Enter text"
                          onSave={changeTextCondition}
                          value={alert?.condition.answer}
                        ></TextEntry>
                      </div>
                    )}

                  {question.textQuestion &&
                    (alert.condition.operator === "is longer than" ||
                      alert.condition.operator === "is shorter than") && (
                      <div
                        style={{
                          height: "40px",
                          display: "flex",
                          alignItems: "center",
                          gap: ".5em",
                        }}
                      >
                        <NumberInput
                          startNumber={
                            alert.condition.answer
                              ? alert.condition.answer
                              : 100
                          }
                          min={0}
                          max={2000}
                          onSave={changeTextCondition}
                        />
                        <div>Characters </div>
                      </div>
                    )}
                </>
              )}
            </div>

            <div className={styles.divider}></div>

            <div className={styles.notifyWho}>
              <div>Notify</div>
              <div className={styles.who}>
                <div className={styles.whoRow}>
                  <div className={styles.whoHeader}>Users:</div>
                  <UserList onAdd={addUser} alert={alert} />

                  <div className={styles.list}>
                    {alert.toUsers.map((userId) => (
                      <div className={styles.person}>
                        <UserName userId={userId} />

                        <i
                          className={`bi-x ${styles.remove}`}
                          onClick={() => removeUser(userId)}
                        ></i>
                      </div>
                    ))}
                  </div>
                </div>

                <div className={styles.whoRow}>
                  <div className={styles.whoHeader}>Emails:</div>
                  <EmailEntry onAdd={addEmail} />
                  <div className={styles.list}>
                    {alert.toEmails.map((email) => (
                      <div className={styles.emailHolder}>
                        <div className={styles.email}>{email}</div>

                        <i
                          className={`bi-x ${styles.remove}`}
                          onClick={() => removeEmail(email)}
                        ></i>
                      </div>
                    ))}
                  </div>
                </div>

                <div className={styles.whoRow}>
                  <div className={`${styles.whoHeader} ${styles.matching}`}>
                    Corresponding:
                    <div className={styles.matchingInfo}>
                      <i className="bi bi-info-square"></i>
                      <div className={styles.matchingTooltip}>
                        Users who have the same ___ as participants
                      </div>
                    </div>
                  </div>
                  <AddCorresponding
                    onAdd={addCustomField}
                    alert={alert}
                    customFields={customFields}
                  />

                  <div className={styles.list}>
                    {alert.toUsersWithSame.map((fieldId) => (
                      <div className={styles.customField}>
                        <div className={styles.customFieldName}>
                          {
                            customFields.find((f) => f.id === fieldId)
                              ?.displayName
                          }
                        </div>

                        <i
                          className={`bi-x ${styles.remove}`}
                          onClick={() => removeCustomField(fieldId)}
                        ></i>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.divider}></div>

            <div className={styles.withMessage}>
              <FlexRow justify={"space-between"} align={"flex-end"}>
                <div>Message</div>
                <Button shadow onClick={() => setPreview(true)}>
                  Preview
                </Button>
              </FlexRow>
              <AlertEmailBuilder
                alert={alert}
                changeField={changeField}
                customFields={customFields}
              />
            </div>
          </div>

          <div className={styles.addBox}>
            <Button white onClick={onDelete} shadow>
              <div style={{ display: "flex", gap: "5px" }}>
                <i className="bi-trash"></i> Remove
              </div>
            </Button>
            <Button white onClick={onClose} shadow>
              Done
            </Button>
          </div>
        </div>
      </Modal>

      {preview && <Preview alert={alert} onClose={() => setPreview(false)} />}
    </>
  );
}

function UserName({ userId }) {
  const fetchUser = useGetUserById(userId);

  return (
    <>
      {fetchUser.isSuccess && (
        <>
          {`${
            fetchUser.data.getUserById.prefix
              ? fetchUser.data.getUserById.prefix
              : ""
          } ${fetchUser.data.getUserById.firstName} ${
            fetchUser.data.getUserById.lastName
          }`}
        </>
      )}
    </>
  );
}

function UserList({ onAdd, alert }) {
  const [val, setVal] = useState("");
  const [active, setActive] = useState(false);

  const searchResults = useSearchUser(val);

  function handleAdd(user) {
    onAdd(user.id);
    setVal("");
    setActive(false);
  }

  const ref = useRef(null);

  function onFocus() {
    document.addEventListener("click", onClick);
    setActive(true);
  }

  function onClick(e) {
    if (!ref.current || !ref.current.contains(e.target)) {
      setActive(false);
      document.removeEventListener("click", onClick);
      setVal("");
    }
  }

  return (
    <div className={styles.search} ref={ref}>
      <TextEntry
        value={val}
        onChange={setVal}
        placeholder={"add user"}
        width={200}
        onFocus={onFocus}
      />

      {active && searchResults.isSuccess && (
        <div className={styles.searchResults}>
          {searchResults.data.searchUser.map((user) => {
            if (!alert.toUsers.includes(user.id))
              return (
                <div
                  className={styles.option}
                  onClick={() => handleAdd(user)}
                  key={user?.id}
                >
                  {`${user.prefix ? user.prefix : ""} ${user.firstName} ${
                    user.lastName
                  }`}
                </div>
              );

            return <></>;
          })}
        </div>
      )}
    </div>
  );
}

function EmailEntry({ onAdd }) {
  const [val, setVal] = useState("");
  const [valid, setValid] = useState(true);

  function handleAdd(val) {
    if (val === "") return;

    if (isValidEmail(val)) {
      onAdd(val.toLowerCase());
      setVal("");
      if (!valid) setValid(true);
    } else {
      setValid(false);
      setVal(val);
    }
  }

  function onChange(value) {
    setVal(value);
    if (!valid && !value) setValid(true);
  }

  return (
    <TextEntry
      width={200}
      value={val}
      onChange={onChange}
      onSave={handleAdd}
      placeholder={"add email"}
      valid={valid}
      invalidMessage={"Invalid email"}
    />
  );
}

function AddCorresponding({ onAdd, alert, customFields }) {
  const [val, setVal] = useState("");
  const [active, setActive] = useState(false);

  function handleAdd(id) {
    onAdd(id);
    setVal("");
    setActive(false);
  }

  const ref = useRef(null);

  function onFocus() {
    document.addEventListener("click", onClick);
    setActive(true);
  }

  function onClick(e) {
    if (!ref.current || !ref.current.contains(e.target)) {
      setActive(false);
      document.removeEventListener("click", onClick);
      setVal("");
    }
  }

  function filterFields(c) {
    if (
      c.name !== "firstName" &&
      c.name !== "lastName" &&
      !alert.toUsersWithSame.includes(c.id)
    ) {
      if (val) return c.displayName.toLowerCase().includes(val.toLowerCase());

      return true;
    }

    return false;
  }

  return (
    <div className={styles.search} ref={ref}>
      <TextEntry
        width={200}
        value={val}
        onChange={setVal}
        placeholder={"add field"}
        onFocus={onFocus}
      />

      {active && (
        <div className={styles.searchResults}>
          {customFields.filter(filterFields).map((field) => (
            <div
              className={styles.option}
              onClickCapture={() => handleAdd(field.id)}
            >
              {field.displayName}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
