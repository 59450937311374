import styles from "./SurveyProjects.module.scss";
import SurveyProject from "./SurveyProject";
import { Loading } from "components/Loading/Loading";
import { Pagination } from "../../Pagination/Pagination";

function SurveyProjects({
  surveys,
  onClick,
  roles,
  refetch,
  iterations,
  pageNum,
  setPageNum,
  perPage,
  setPerPage,
  setPageSkip,
  count,
  length,
  search,
  isCreate,
  onClickChoose,
  loading,
  chosen,
}) {
  return (
    <div className={styles.surveys}>
      {loading && <Loading></Loading>}
      {!loading &&
        surveys.map((survey) => (
          <SurveyProject
            key={survey.id}
            survey={survey}
            onClick={onClick}
            roles={roles}
            refetch={refetch}
            iterations={iterations}
            isCreate={isCreate}
            onClickChoose={onClickChoose}
            chosen={chosen}
          />
        ))}{" "}
      <div className={styles.sortBox} style={{ justifyContent: "flex-end" }}>
        <Pagination
          pageNum={pageNum}
          setPageNum={setPageNum}
          maxPage={count / perPage < 1 ? 1 : Math.ceil(count / perPage)}
          length={length}
          searchString={search}
          perPage={perPage}
          setPerPage={setPerPage}
          setPageSkip={setPageSkip}
        ></Pagination>
      </div>
    </div>
  );
}

export default SurveyProjects;
