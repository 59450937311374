import { useState, useRef, useEffect } from "react";
import styles from "./ReminderTo.module.scss";
import { useFetchUsersByOrgId } from "api/resources/organization/users";
import { useFetchOrgRoles } from "api/resources/organization/roles";
import avatar from "assets/images/blank-profile-picture.png";
import { ErrorBanner } from "pages/error_page/ErrorPage";

function ReminderTo({ chosen = [], onChange, user }) {
  const [showOptions, setShowOptions] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [placeholder, setPlaceholder] = useState("+ Add a user or role...");

  const fetchUsers = useFetchUsersByOrgId(
    searchValue,
    0,
    0,
    { descend: false, item: "firstName" },
    user.organizationId
  );

  const fetchRoles = useFetchOrgRoles();

  const selectRef = useRef(null);
  const searchRef = useRef(null);
  const users = fetchUsers.data?.users?.users || [];
  const roles = fetchRoles.data?.roles || [];

  const options = [
    ...users.map((user) => ({
      id: user.id,
      image: user.image?.imageURL,
      name: `${user.firstName} ${user.lastName}`,
    })),
    ...roles.map((role) => ({
      id: role.id,
      name: role.name,
    })),
  ];

  function getOptions() {
    let allOptions = [
      ...users.map((user) => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
      })),
      ...roles.map((role) => ({
        id: role.id,
        name: role.name,
      })),
    ];

    if (searchValue) {
      allOptions = allOptions.filter((option) =>
        option.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    return allOptions;
  }

  useEffect(() => {
    if (showOptions && searchRef.current) {
      searchRef.current.focus();
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showOptions]);

  const handleClickOutside = (event) => {
    if (!selectRef.current || !selectRef.current.contains(event.target)) {
      setShowOptions(false);
      // searchRef.current = false;
      // document.removeEventListener("click", handleClickOutside, true);
      setSearchValue("");
    }
  };

  function handleRemoveOption(c, i) {
    c.stopPropagation();
    let copy = [...chosen];
    copy.splice(i, 1);
    onChange(copy);
  }

  function getHeight() {
    if (options.length <= 6) {
      return (options.length + 1) * 40 + "px";
    } else {
      return "";
    }
  }

  const clearInput = () => {
    setSearchValue("");
    if (searchRef.current) {
      searchRef.current.focus();
    }
  };

  function onOptionClick(c, option) {
    c.stopPropagation();

    console.log(option);
    let ind = chosen.findIndex((a) => a.id === option.id);
    if (ind > -1) {
      let copy = [...chosen.slice(0, ind), ...chosen.slice(ind + 1)];
      if (copy.length) {
        onChange(copy);
      } else {
        onChange([]);
      }
    } else {
      onChange([...chosen, ...[option]]);
    }
    clearInput();
  }

  return (
    <>
      {fetchUsers.isError && (
        <ErrorBanner
          error={fetchUsers.error}
          message="Error pulling user data"
        />
      )}
      {fetchRoles.isError && (
        <ErrorBanner
          error={fetchRoles.error}
          message="Error pulling role data"
        />
      )}

      <div className={styles.container} ref={selectRef}>
        <div className={styles.label6}>Who gets reminders?</div>
        <div className={styles.chosenConainer}>
          {chosen.map((option, i) => (
            <div key={i} className={styles.chosenItem}>
              <span>{option.name}</span>
              <i
                className={`bi-x ${styles.remove}`}
                onClick={(c) => handleRemoveOption(c, i)}
              ></i>
            </div>
          ))}
        </div>
        <input
          className={styles.input}
          placeholder={placeholder}
          onChange={(c) => setSearchValue(c.target.value)}
          value={searchValue}
          ref={searchRef}
          onFocus={() => {
            setShowOptions(true);
            setPlaceholder("Search");
          }}
          onBlur={() => setPlaceholder("+ Add a user or role...")}
        />

        {showOptions && (
          <div
            className={styles.dropdown}
            style={{
              maxHeight: getHeight(),
            }}
          >
            {getOptions().map((option, i) => (
              <div
                key={i}
                className={styles.option}
                onClick={(c) => onOptionClick(c, option)}
              >
                <img
                  src={option?.imageURL ? option?.imageURL : avatar}
                  alt="user profile image"
                  className={styles.avatar}
                />
                <div>{option.name}</div>
                {chosen.some((c) => c.id === option.id) && (
                  <div className={styles.checked}>
                    <i className="bi-check"></i>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default ReminderTo;
