import styles from "../SettingsAccordion.module.scss";
import { useState } from "react";
import { SelectField } from "components/inputs";
import { QData } from "./SurveyData/QData";
import {
  defaultChartSettings,
  MetricType,
  TableType,
  ValueType,
  VertBarType,
} from "../../QChart/QuestionChart";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { defaultTableSettings } from "../../TableViz";
import { defaultMetricSettings } from "../../ScoreChart";
import { SurveyData } from "./SurveyData/SurveyData";
import { blankViz } from "assets/functions/Variables";
import { defaultStatChartSettings } from "../../StatChart/StatChart";

const dataMethods = [
  { value: "question", label: "Get data from survey questions" },
  { value: "survey", label: "Get data from survey stats" },
  // { value: "message", label: "Get data from survey messages" },
  // { value: "manual", label: "Manually add data" },
];

export const DataSettings = ({
  viz,
  updateViz,
  title,
  visible,
  setVisible,
  newChart,
  lockData,
}) => {
  const [dataMethod, setDataMethod] = useState(
    viz.dataMethod
      ? dataMethods.find((m) => m.value === viz.dataMethod)
      : { value: "question", label: "Get data from survey questions" }
  );

  function changeMethod(method) {
    let newViz = {};
    if (viz.type === TableType) {
      newViz = {
        type: TableType,
        designSettings: JSON.parse(JSON.stringify(defaultTableSettings)),
        ...JSON.parse(JSON.stringify(blankViz)),
      };
    } else {
      newViz = {
        type: VertBarType,
        designSettings: JSON.parse(JSON.stringify(defaultChartSettings)),
        ...JSON.parse(JSON.stringify(blankViz)),
      };
    }

    if (method.value === "survey") {
      newViz.designSettings = {
        ...newViz.designSettings,
        ...JSON.parse(JSON.stringify(defaultStatChartSettings)),
      };
      newViz.programIds = [];
    }
    newViz.dataMethod = method.value;
    newViz.newChart = newChart;
    newViz.id = viz.id;
    updateViz(newViz);
    setDataMethod(method);
  }

  function onChangeType(type) {
    if (type !== getCurrType()) {
      let newViz = {};
      if (type === "Chart") {
        newViz = {
          type: VertBarType,
          designSettings: JSON.parse(JSON.stringify(defaultChartSettings)),
          ...JSON.parse(JSON.stringify(blankViz)),
        };
      } else if (type === "Table") {
        newViz = {
          type: TableType,
          designSettings: JSON.parse(JSON.stringify(defaultTableSettings)),
          ...JSON.parse(JSON.stringify(blankViz)),
        };
      } else {
        newViz = {
          type: MetricType,
          designSettings: JSON.parse(JSON.stringify(defaultMetricSettings)),
          ...JSON.parse(JSON.stringify(blankViz)),
        };
        setDataPage(false);
        setVisible("MetricSettings");
      }
      updateViz(newViz);
    }
  }

  const typeVals = ["Chart", "Table", "Metric"];

  const scoreType = viz.type === MetricType || viz.type === ValueType;

  function getCurrType() {
    if (scoreType) return "Metric";
    if (viz.type === TableType) return "Table";
    return "Chart";
  }

  function noEmptyQTagSelections() {
    if (viz.questionTags) {
      for (let tag of viz.questionTags) {
        if (tag.selection && !tag.selection.length) {
          return false;
        }
      }
    }
    return true;
  }

  return (
    <>
      <div
        key={viz.id}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={!visible ? setVisible : undefined}
      >
        <FlexRow
          onClick={visible ? setVisible : undefined}
          style={{ cursor: "pointer" }}
          className={styles.accordionTitle}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
            }}
          >
            {title}{" "}
            <i style={{ fontSize: "1.2em" }} className="bi-receipt-cutoff"></i>
          </div>

          <span className={styles.accordionicon}>
            <i className="bi bi-caret-left-fill"></i>
          </span>
        </FlexRow>
        {visible && (
          <>
            {newChart && (
              <div className={styles.dataHeader}>
                <div style={{ fontSize: ".8em" }}>
                  <SelectField
                    onChange={(data) => changeMethod(data)}
                    options={dataMethods}
                    value={dataMethod}
                    placeholder="Choose how to get your data"
                    height={28}
                    //   disable={!newChart}
                  ></SelectField>
                </div>
              </div>
            )}
            {dataMethod.value === "question" && (
              <>
                <div className={styles.dataSettings}>
                  {!lockData && (
                    <QData // clipboard-data      journal-text
                      viz={viz}
                      updateViz={updateViz}
                      newChart={newChart}
                    ></QData>
                  )}
                </div>
              </>
            )}

            {dataMethod.value === "survey" && (
              <>
                <div className={styles.dataSettings}>
                  {!lockData && (
                    <SurveyData // clipboard-data      journal-text
                      viz={viz}
                      updateViz={updateViz}
                      method={dataMethod.value}
                      newChart={newChart}
                    ></SurveyData>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
