import React, { useState } from "react";
import styles from "./Dashboards.module.scss";
import {
  useCreateOrgDash,
  useFetchOrganization,
  useUpdateOrgSettings,
} from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import { useParams } from "react-router-dom";
import {
  useFetchOrgRoles,
  useRefreshMyRole,
  useToggleRoleDash,
} from "api/resources/organization/roles";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import FlexCol from "components/layouts/FlexColumn/FlexCol";

export default function Dashboards({ role }) {
  const { id } = useParams();
  const MyOrg = useFetchOrganization(id);

  return (
    <div className={styles.page}>
      {MyOrg.isLoading && <Loading></Loading>}
      {MyOrg.isSuccess && (
        <DashboardContent
          org={MyOrg.data.organization}
          orgRefetch={MyOrg.refetch}
          role={role}
        ></DashboardContent>
      )}
    </div>
  );
}

function DashboardContent({ org, role, orgRefetch }) {
  const [loading, setLoading] = useState({});
  const createOrgDash = useCreateOrgDash();
  const toggleRoleDash = useToggleRoleDash();
  const orgRoles = useFetchOrgRoles();
  const editOrg = useUpdateOrgSettings();
  const refreshMyRole = useRefreshMyRole();

  function toggleDash(val) {
    if (val && !org.dashboardId) {
      // create one if not there
      createOrgDash.mutate(
        {
          orgId: org?.id,
        },
        {
          onSuccess: (data) => {
            orgRefetch();
          },
        }
      );
    } else {
      editOrg.mutate(
        {
          data: {
            showDash: val,
          },
          updateOrganizationId: org?.id,
        },

        {
          onSuccess: (data) => {
            // console.log(data);
            orgRefetch();
          },
        }
      );
    }
  }

  function roleDashToggle(r, val) {
    let temp = { ...loading };
    temp[r.id] = true;
    setLoading(temp);
    toggleRoleDash.mutate(
      {
        roleId: r.id,
        val: val,
      },
      {
        onSuccess: (data) => {
          console.log("role toggled");
          let temp = { ...loading };
          delete temp[r.id];
          setLoading(temp);
          if (r.id === role.id) refreshMine();
        },
      }
    );
  }

  function refreshMine() {
    refreshMyRole.mutate(
      {},
      {
        onSuccess: (data) => {
          console.log("refreshed mine");
        },
      }
    );
  }

  return (
    <>
      {orgRoles.isSuccess && (
        <FlexCol gap="1.5rem">
          <div className={styles.editImage}>
            <div className={styles.roleDashControls}>
              <h5>Role Dashboards</h5>
              {orgRoles.data.roles.map((r, i) => (
                <FlexRow key={i} gap={"0rem"}>
                  <div className={styles.dashControl}>
                    <div className={styles.role}>
                      <div
                        className={styles.label}
                        style={r.showDash ? { color: "#2A627C" } : undefined}
                      >
                        {r.name}
                      </div>
                      {r.description && (
                        <div className={styles.roleDescription}>
                          {r.description}
                        </div>
                      )}
                    </div>
                    <ToggleSwitch
                      startChecked={r.showDash}
                      handleCheck={(val) => roleDashToggle(r, val)}
                    />
                  </div>
                  {r?.id in loading && (
                    <Loading height={55} width={55}></Loading>
                  )}
                </FlexRow>
              ))}
            </div>
          </div>

          <div className={styles.editImage}>
            <h5>Organization Dashboards</h5>
            <div className={styles.dashControls}>
              <div className={styles.orgDashControl}>
                <div className={styles.dashControl}>
                  <div className={styles.role}>
                    <div
                      className={styles.label}
                      style={org.showDash ? { color: "#2A627C" } : undefined}
                    >
                      {`Visible Organization Dashboard`}
                    </div>
                    <div className={styles.roleDescription}>
                      An identical dashboard view for everyone
                    </div>
                  </div>
                  <ToggleSwitch
                    startChecked={org.showDash}
                    handleCheck={toggleDash}
                  />
                </div>
              </div>
            </div>
          </div>
        </FlexCol>
      )}
    </>
  );
}
