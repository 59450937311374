import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import { useRef, useState, useEffect } from "react";
import styles from "./TableChart.module.scss";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import { CSVLink } from "react-csv";
import { forEach, getReversed } from "assets/functions/ArrayFunctions";
import "components/inputs/input_fields/reactgrid/styles.scss";
import { Workbook } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css";
import SimpleBar from "simplebar-react";
import { Loading } from "components/Loading/Loading";

export function ScrollPullTable({
  initHeaders,
  data,
  onRowClick,
  threeDots,
  asChart,
  tableTitle,
  titleStyle,
  titleContainerStyle,
  subtitle,
  id,
  downloadCsv,
  setExternalCsvData,
  tableSort,
  onSaveSort,
  inEdit,
  toggleSpreadsheet,
  spreadsheetmode,
  editMode,
  color,
  setOutsideData,
  setUpOutsideDataCounter,
  sortInLast,
  shouldSortInLast,
  showScroll,
  onScrolled,
  setNeedAll,
  moreToPull,
  counter,
  pulling,
}) {
  const [headers, setHeaders] = useState(initHeaders);
  const [dataArray, setDataArray] = useState();
  const [topOfList, setTopOfList] = useState();

  const [loading, setLoading] = useState(true);

  const ref = useRef();

  function pullMore() {
    onScrolled();
  }

  function onScroll(e) {
    if (!loading) {
      if (topOfList) {
        if (topOfList?.length < dataArray?.length) {
          var scrollableContainer = e.target;
          let distanceToBottom =
            scrollableContainer.scrollHeight -
            (scrollableContainer.scrollTop + scrollableContainer.clientHeight);

          if (distanceToBottom <= 100) {
            let nextlength = topOfList?.length + 100;
            setTopOfList(dataArray.slice(0, nextlength));
          }
        }
      } else {
        if (moreToPull && !pulling) {
          var scrollableContainer = e.target;
          let distanceToBottom =
            scrollableContainer.scrollHeight -
            (scrollableContainer.scrollTop + scrollableContainer.clientHeight);

          if (distanceToBottom <= 100) {
            pullMore();
          }
        }
      }
    }

    if (!ref.current) {
      ref.current = e.target;
    }
  }

  const [spreadData, setSpreadData] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [editHeaders, setEditHeaders] = useState(false);
  const [searching, setSearching] = useState(false);
  const [spreadsheet, setSpreadsheet] = useState(false);
  const [sort, setSort] = useState(tableSort ? tableSort : []);
  const [showSaveSort, setShowSaveSort] = useState(false);
  const [savingSort, setSavingSort] = useState(false);

  const [csvData, setCsvData] = useState();
  const [csvName, setCsvName] = useState();

  function getSpreadData() {
    let newData = [];

    newData = [
      {
        name: "Sheet1",
        filter_select: {
          row: [0, data?.length],
          column: [0, initHeaders?.length - 1],
        },
        celldata: [],
      },
    ];
    for (let i = 0; i < initHeaders?.length; i++) {
      let header = initHeaders[i];
      newData[0].celldata.push({
        r: 0,
        c: i,
        v: { ct: { fa: "General", t: "g" }, m: header.name, v: header.name },
      });
    }
    for (let j = 1; j < data?.length + 1; j++) {
      let set = data[j - 1];
      for (let i = 0; i < initHeaders?.length; i++) {
        let header = initHeaders[i];
        newData[0].celldata.push({
          r: j,
          c: i,
          v: {
            ct: { fa: "General", t: "g" },
            m: `${set[header.accessor]}`,
            v: `${set[header.accessor]}`,
          },
        });
      }
    }
    setDataArray(data);
    setHeaders(initHeaders);

    return newData;
  }

  const dotsRef = useRef();

  const handleClickOutsideDots = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setEditHeaders(false);
      document.removeEventListener("click", handleClickOutsideDots, true);
    }
  };

  function setUpDots() {
    setEditHeaders(!editHeaders);
    setTimeout(
      () => document.addEventListener("click", handleClickOutsideDots, true),
      200
    );
  }

  function setUpSpreadSheet() {
    setSpreadData(getSpreadData());
    setSpreadsheet(true);
  }

  const gray110 = "#616565";
  const gray100 = "#d8d9d9";

  function handleEditHeader(head) {
    let tempList = [...headers];
    let ind = tempList.findIndex((h) => h.name === head.name);

    if (ind !== -1) {
      //remove from list
      tempList.splice(ind, 1);
      setHeaders(tempList);
    } else {
      //add to list
      tempList.splice(head.index, 0, head);
      setHeaders(tempList);
    }
  }

  function searchFor(string) {
    if (!data) return [];
    if (string === "") return [...data];

    string = string.toString().toLowerCase();
    let applicable = [];
    let left = [];
    let nonApplicable = [];

    forEach(data, (entry) => {
      let contains = false;
      for (let prop in entry) {
        if (entry[prop] && prop !== "id" && prop !== "anon") {
          let substring = entry[prop]
            .toString()
            .toLowerCase()
            .substring(0, string?.length);
          if (substring === string) {
            contains = true;
            break;
          }
        }
      }
      contains ? applicable.push(entry) : left.push(entry);
    });

    forEach(left, (entry) => {
      let contains = false;
      for (let prop in entry) {
        if (entry[prop] && prop !== "id" && prop !== "anon") {
          if (entry[prop].toString().toLowerCase().includes(string)) {
            contains = true;
            break;
          }
        }
      }
      contains ? applicable.push(entry) : nonApplicable.push(entry);
    });

    return applicable;
  }

  function sortUp(dataCopy, col, sortCopy) {
    if (sortInLast && shouldSortInLast && shouldSortInLast(col)) {
      return sortInLast("up", dataCopy, col, sortCopy);
    }

    dataCopy.sort((a, b) => {
      a = a[col];
      b = b[col];
      if (!a && a !== 0) {
        // For undefined values
        a = "";
      }
      if (!b && b !== 0) {
        b = "";
      }

      if (typeof a === "number" && typeof b === "number") {
        return b - a;
      }
      if (typeof a === "string" && typeof b === "string") {
        if (!a && b) {
          // empty strings at the top
          return -1;
        }
        if (a && !b) {
          // empty strings at the top
          return 1;
        }

        if (a.toLowerCase() < b?.toLowerCase()) {
          return 1;
        }
        if (a.toLowerCase() > b?.toLowerCase()) {
          return -1;
        }
        return 0;
      }

      if (typeof a === "number") {
        // numbers before letters
        return -1;
      }
      if (typeof b === "number") {
        return 1;
      }

      return 0;
    });
  }

  function sortDown(dataCopy, col, sortCopy) {
    if (sortInLast && shouldSortInLast && shouldSortInLast(col)) {
      return sortInLast("down", dataCopy, col, sortCopy);
    }

    dataCopy.sort((a, b) => {
      a = a[col];
      b = b[col];
      if (!a && a !== 0) {
        a = "";
      }
      if (!b && b !== 0) {
        b = "";
      }

      if (typeof a === "number" && typeof b === "number") {
        return a - b;
      }

      if (typeof a === "string" && typeof b === "string") {
        if (!a && b) {
          // empty strings at the bottom
          return 1;
        }
        if (a && !b) {
          // empty strings at the bottom
          return -1;
        }

        if (a.toLowerCase() < b?.toLowerCase()) {
          return -1;
        }
        if (a.toLowerCase() > b?.toLowerCase()) {
          return 1;
        }
        return 0;
      }

      if (typeof a === "number") {
        // numbers before letters
        return -1;
      }
      if (typeof b === "number") {
        return 1;
      }
      return 0;
    });
  }

  function sortBy(col) {
    let sortCopy = [...sort];
    let index = sortCopy.findIndex((s) => s.col === col);
    if (index >= 0) {
      if (sortCopy[index].sort === "up") {
        sortCopy.splice(index, 1);
      } else {
        sortCopy[index].sort = "up";
      }
    } else {
      sortCopy.push({
        col: col,
        sort: "down",
      });
    }

    setSort(sortCopy);
    if (onSaveSort) {
      if (inEdit) {
        onSaveSort(sortCopy);
      } else if (!showSaveSort) {
        setShowSaveSort(true);
      }
    }

    if (!searchValue) {
      checkNeedForAll(sort.length, sortCopy.length);
    }

    if (data) {
      let dataCopy = [];

      if (searchValue) {
        dataCopy = searchFor(searchValue);
      } else {
        dataCopy = [...data];
      }

      for (let sorting of getReversed(sortCopy)) {
        if (sorting.sort === "down") {
          sortDown(dataCopy, sorting.col, sortCopy);
        } else if (sorting.sort === "up") {
          sortUp(dataCopy, sorting.col, sortCopy);
        }
      }

      setDataArray(dataCopy);
    }
  }

  function initSearch(val) {
    setSearchValue(val);

    let dataCopy = searchAndSort(val);
    setDataArray(dataCopy);

    if (!sort.length) {
      checkNeedForAll(searchValue, val);
    }
  }

  function checkNeedForAll(old, now) {
    if (!old && now) {
      setNeedAll(true);
    }

    if (old && !now) {
      setNeedAll(false);
    }
  }

  function cleanSlate() {
    setLoading(true);

    if (topOfList) setTopOfList();

    if (ref.current) {
      ref.current.scrollTop = 0;
      ref.current = undefined; // detach
    }
  }

  function searchAndSort(search) {
    let array = searchFor(search);
    for (let sorting of getReversed(sort)) {
      if (sorting.sort === "down") {
        sortDown(array, sorting.col, sort);
      } else if (sorting.sort === "up") {
        sortUp(array, sorting.col, sort);
      }
    }

    return array;
  }

  useEffect(() => {
    if (data) {
      let theData = searchAndSort(searchValue);
      setDataArray(theData);

      if (searchValue || sort.length) {
        setTopOfList(theData?.slice(0, 100));
      }

      if (loading) setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (!loading) {
      if (downloadCsv) {
        setCsvData(getCsvData());
        setCsvName(getCsvName());
      }
      if (setExternalCsvData) {
        setExternalCsvData(getCsvData());
      }

      if (dataArray) {
        if (topOfList) {
          setTopOfList(
            dataArray.slice(0, topOfList.length > 100 ? topOfList.length : 100)
          );
        } else {
          seeIfHasSpace();
        }
      }
    }
  }, [dataArray]);

  useEffect(() => {
    if (counter) {
      cleanSlate();
    }
  }, [counter]);

  useEffect(() => {
    if (!spreadsheetmode && !spreadsheet) {
      setHeaders(initHeaders);
    }
  }, [initHeaders]);

  useEffect(() => {
    if (tableSort) {
      if (JSON.stringify(tableSort) !== JSON.stringify(sort)) {
        setSort(tableSort);
      }
    } else if (sort) {
      setSort([]);
    }
  }, [tableSort]);

  useEffect(() => {
    if (setUpOutsideDataCounter) {
      if (dataArray && headers) {
        let copy = dataArray;
        if (copy.length > 30) {
          copy = copy.slice(0, 30);
        }
        setOutsideData({
          dataArray: copy,
          headers: headers,
        });
      }
    }
  }, [setUpOutsideDataCounter]);

  const insideRef = useRef(null);
  const outsideRef = useRef(null);

  function seeIfHasSpace() {
    if (
      insideRef.current &&
      outsideRef.current &&
      !searchValue &&
      !sort.length &&
      moreToPull &&
      !pulling
    ) {
      let inside = insideRef.current;
      let outside = outsideRef.current;

      if (inside.clientHeight < outside.clientHeight) {
        pullMore();
      }
    }
  }

  function getSortCaret(col) {
    let index = sort.findIndex((s) => s.col === col.accessor);
    const has = index >= 0;
    let color = has ? gray110 : gray100;
    let icon = has
      ? sort[index].sort === "down"
        ? "bi-caret-down-fill"
        : "bi-caret-up-fill"
      : "bi-dash";

    return (
      <i
        className={`bi ${icon} ${styles.sortTriangle} `}
        style={{ color: color }}
      >
        {has && sort?.length > 1 ? index + 1 : ""}
      </i>
    );
  }

  function saveSort() {
    if (onSaveSort) {
      onSaveSort(sort);
    }

    setTimeout(() => {
      setSavingSort(true);
    }, 200);

    setTimeout(() => {
      setShowSaveSort(false);
      setSavingSort(false);
    }, 900);
  }

  function getCsvName() {
    let name = "";

    if (tableTitle) {
      name += tableTitle;
      if (subtitle) {
        name += "_" + subtitle;
      }
    } else {
      name += "tableData";
    }

    if (searchValue) {
      name += "_search for " + searchValue;
    }

    return name + ".csv";
  }

  function getCsvData() {
    let list = [];
    for (let row of dataArray) {
      let entry = {};
      for (let field of headers) {
        let cell = row[field.accessor];
        if (cell && typeof cell === "object") {
          // for matrix and ranking answers
          let string = "";
          for (let div of cell.props.children) {
            if (div?.props?.children) {
              if (string) {
                string += "\n";
              }
              string += div.props.children;
            }
          }
          cell = string;
        }

        if (field?.project) {
          let newentry = cell;
          newentry = `${newentry}`;
          newentry = newentry.replaceAll(`"`, ``);
          newentry = newentry.replaceAll(`'`, ``);
          entry[field?.project?.name + " - " + field.name] = newentry;
        } else if (field?.accessor === "answer") {
          let newentry = cell;
          newentry = `${newentry}`;
          newentry = newentry.replaceAll(`"`, ``);
          newentry = newentry.replaceAll(`'`, ``);
          entry[field.name] = newentry;
        } else {
          let newentry = cell;
          newentry = `${newentry}`;
          newentry = newentry.replaceAll(`"`, ``);
          newentry = newentry.replaceAll(`'`, ``);
          entry[field.name] = newentry;
        }
      }
      list.push(entry);
    }
    return list;
  }

  function normalizeHeader(header) {
    if (!header) {
      return "";
    }
    const iterator = header[Symbol.iterator]();
    let nextChar = iterator.next();
    let breaks = [];
    for (let i = 0; i < header.length; i++) {
      if (i != 0 && /^[A-Z]+$/.test(nextChar)) {
        // (x >= "A" && x <= "Z")
        breaks.push(i);
      }
      nextChar = iterator.next();
    }
    for (let i = 0; i < breaks.length; i++) {
      let first = header.substring(0, breaks[i] + i);
      let second = header.substring(breaks[i] + i);
      header = first + " " + second;
    }

    header = header.charAt(0).toUpperCase() + header.slice(1);

    //trim it
    if (header.length < 26) {
      return header;
    }
    let text = header.substring(0, 25);
    text += "...";
    return text;
  }

  const [left, setLeft] = useState(false);
  const [right, setRight] = useState(false);

  return (
    <div
      className={styles.container}
      ref={outsideRef}
      style={
        {
          // padding: !spreadsheet && !spreadsheetmode && !asChart ? "1em" : "",
          // maxHeight: height,
          // height: !dataArray.length ? height : "100%",
        }
      }
    >
      {!searching && (
        <div className={styles.searchBtn} onClick={() => setSearching(true)}>
          <i className={`bi bi-search`}></i>
        </div>
      )}

      {searching && (
        <div
          className={styles.searchDiv}
          id={`search ${id}`}
          style={asChart && !searching ? { display: "none" } : undefined}
        >
          <NavTextField
            placeholder="Search..."
            setValue={initSearch}
            value={searchValue}
            className={styles.search}
          ></NavTextField>
          <i
            className={`bi bi-x-lg ${styles.close}`}
            onClick={() => setSearching(false)}
          ></i>
        </div>
      )}
      <Wrapper
        spreadsheet={spreadsheet || spreadsheetmode}
        onScroll={onScroll}
        showScroll={showScroll}
      >
        <div className={styles.inside} ref={insideRef}>
          {right && (
            <div
              className={styles.scrollRight}
              onClick={() => {
                startScroll(true);
              }}
            >
              <i className="bi-chevron-right"></i>
            </div>
          )}
          {left && (
            <div
              className={styles.scrollLeft}
              onClick={() => {
                startScroll(false);
              }}
            >
              <i className="bi-chevron-left"></i>
            </div>
          )}

          {!editMode && (tableTitle || subtitle) && (
            <div className={styles.titleContainer} style={titleContainerStyle}>
              {tableTitle && (
                <div className={styles.title} style={titleStyle}>
                  {tableTitle}
                </div>
              )}
              {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
            </div>
          )}

          {(downloadCsv || toggleSpreadsheet) && (
            <div
              className={styles.tableHeader}
              style={{
                paddingTop: !(tableTitle || subtitle) ? "10px" : "0px",
              }}
            >
              <span>Rows: {dataArray.length}</span>

              {toggleSpreadsheet && !spreadsheetmode && !asChart && (
                <div className={styles.togglespread}>
                  <i className="bi-chevron-down"></i>
                  <select
                    value={spreadsheet ? "spread" : "table"}
                    onChange={(e) => {
                      e.target.value === "spread"
                        ? setUpSpreadSheet()
                        : setSpreadsheet(false);
                    }}
                    className={styles.select}
                  >
                    <option value="spread">Spreadsheet Mode</option>
                    <option value="table">Table Mode</option>
                  </select>
                </div>
              )}
              {downloadCsv && (
                <div className={styles.download}>
                  <CSVLink data={csvData ? csvData : ""} filename={csvName}>
                    <div className={styles.csvDownload}>
                      <i className="bi bi-cloud-download"></i> Download
                    </div>
                  </CSVLink>
                </div>
              )}
            </div>
          )}

          {/* {dataArray && dataArray?.length == 0 && (
            <div className={styles.noDataContainer}>
              <div className={styles.noData}>
                <>
                  {searchValue != "" && <>No data to display</>}
                  {searchValue == "" && !spreadsheet && !spreadsheetmode && (
                    <>No Data</>
                  )}
                </>
              </div>
            </div>
          )} */}
          {(spreadsheet || spreadsheetmode) && spreadData && (
            <div className={styles.spread}>
              <Workbook
                data={spreadData}
                showSheetTabs={false}
                showToolbar={false}
              />
            </div>
          )}

          {!spreadsheet && !spreadsheetmode && (
            <>
              <table className={`${styles.fulltable}`}>
                <thead>
                  <tr>
                    <th style={{ backgroundColor: color ? color : "" }}>row</th>
                    {headers.map((col, ind) => (
                      <th
                        className={`${styles.columnHeader}`}
                        key={ind}
                        onClick={() => sortBy(col.accessor)}
                        style={{ backgroundColor: color ? color : "" }}
                      >
                        <div className={styles.text}>
                          {normalizeHeader(col.name)} {getSortCaret(col)}
                          {col.name && (
                            <div className={styles.overflow}>
                              {col?.project ? col?.project?.name + " - " : ""}
                              {col.name}
                            </div>
                          )}
                        </div>
                      </th>
                    ))}
                    {threeDots && (
                      <th
                        ref={dotsRef}
                        style={{ backgroundColor: color ? color : "" }}
                      >
                        <i
                          className={`bi bi-three-dots-vertical ${styles.threeDots}`}
                          onClick={setUpDots}
                        ></i>
                        {editHeaders && (
                          <div className={styles.editHeaders}>
                            {initHeaders.map((head, ind) => (
                              <div className={styles.editableHeader} key={ind}>
                                <Checkbox
                                  checked={headers.some(
                                    (h) => h.name === head.name
                                  )}
                                  onChange={() => handleEditHeader(head)}
                                ></Checkbox>
                                <div>{head.name}</div>
                              </div>
                            ))}
                          </div>
                        )}
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {loading && (
                    <tr>
                      <td colSpan={headers.length > 4 ? 4 : headers.length + 1}>
                        <Loading></Loading>
                      </td>
                    </tr>
                  )}

                  {!loading && (
                    <>
                      {(topOfList ? topOfList : dataArray)?.map(
                        (rowdata, ind) => (
                          <tr
                            key={ind}
                            className={`${ind % 2 === 1 && styles.gray} ${
                              styles.rowData
                            }`}
                            style={
                              onRowClick
                                ? {
                                    cursor: "pointer",
                                  }
                                : undefined
                            }
                          >
                            <td style={{ textAlign: "center" }}>{ind + 1}</td>
                            {headers.map((column, i) => (
                              <td
                                key={i}
                                onClick={
                                  column.onClick
                                    ? () => column.onClick(rowdata)
                                    : onRowClick
                                    ? () => onRowClick(rowdata)
                                    : undefined
                                }
                              >
                                <div className={styles.text}>
                                  {column.cell_style &&
                                    column.cell_style(rowdata[column.accessor])}
                                  {!column.cell_style &&
                                    rowdata[column.accessor]}
                                  {(rowdata[column.accessor] ||
                                    rowdata[column.accessor] == 0) && (
                                    <div
                                      className={styles.overflow}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        navigator.clipboard.writeText(
                                          `${rowdata[column.accessor]}`
                                        );
                                      }}
                                    >
                                      Copy Cell <i className="bi-layers"></i>
                                    </div>
                                  )}
                                </div>
                              </td>
                            ))}
                            {threeDots && <td></td>}
                          </tr>
                        )
                      )}

                      {dataArray && dataArray.length == 0 && (
                        <tr>
                          <td></td>
                          <td>
                            <div className={styles.empty}>No Data</div>
                          </td>
                        </tr>
                      )}

                      {pulling && (
                        <tr>
                          <td colSpan={2}>
                            <Loading height={40} width={40} />
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </>
          )}
        </div>
      </Wrapper>
      <div
        className={styles.saveSort}
        style={showSaveSort ? {} : { display: "none" }}
      >
        <div className={styles.save} onClick={saveSort}>
          Save Sort
          {savingSort ? (
            <i className="bi bi-check-lg"></i>
          ) : (
            <div className={styles.upDown}>
              <i className="bi bi-caret-down-fill"></i>
              <i className="bi bi-caret-up-fill"></i>
            </div>
          )}
        </div>
        <i
          className={`bi bi-x-lg ${styles.dontSave}`}
          onClick={() => setShowSaveSort(false)}
        ></i>
      </div>
    </div>
  );
}

function Wrapper({ children, spreadsheet, onScroll, showScroll }) {
  // For some reason (wraps a bunch of divs around it) the excel sheet component does not like (shrinks inside of) the Simple Bar component. So switch it if using spreadsheet
  return (
    <>
      {!spreadsheet && (
        <SimpleBar
          className={styles.scrollable}
          onScrollCapture={onScroll}
          autoHide={!showScroll}
        >
          {children}
        </SimpleBar>
      )}
      {spreadsheet && (
        <div className={styles.scrollable} ref={ref}>
          {children}
        </div>
      )}
    </>
  );
}
