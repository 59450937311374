// External
import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation, useGqlInfiniteQuery, fetchGraphQLData } from "api/Api";

export const useCreateCustomFieldGroup = () => {
  const mutation = gql`
    mutation ($data: CustomFieldGroupInput!) {
      CreateCustomFieldGroup(data: $data) {
        id
        name
        position
        enabled
        icon
        customFieldGroupRow {
          id
          position
          customFieldGroupRowField {
            id
            position
            customFieldId
            customField {
              id
              name
              displayName
              generic
            }
          }
        }
      }
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useFetchCustomFieldAttributes = () => {
  const mutation = gql`
    mutation ($id: String!) {
      attributes: getCustomFieldAttributes(id: $id) {
        name
        id
      }
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useGetCustomFieldAttributes = (
  search,
  perPage,
  sort,
  id,
) => {
  const query = gql`
    query Query(
      $search: String!
      $skip: Float!
      $perPage: Float!
      $sort: SortAttribute!
      $id: String!
    ) {
      response: getCustomFieldAttributesPaginated(
        search: $search
        skip: $skip
        perPage: $perPage
        sort: $sort
        id: $id
      ) {
        attributes {
          id
          name
        }
        count
        hasMore
      }
    }
  `;

  return useGqlInfiniteQuery(
    ["AttributesQuery", id],
    query,
    {
      search,
      perPage,
      sort,
      id, 
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalLoaded = allPages.flatMap((page) => page?.response?.attributes).length;
        return lastPage?.response?.hasMore ? totalLoaded : undefined; // Use `totalLoaded` as new `skip`
      },
    }
  );
};

export const  useSearchCustomFieldAttributes = async (
  search,
  perPage,
  sort,
  id,
  skip
) => {
  const query = gql`
    query Query(
      $search: String!
      $skip: Float!
      $perPage: Float!
      $sort: SortAttribute!
      $id: String!
    ) {
      response: getCustomFieldAttributesPaginated(
        search: $search
        skip: $skip
        perPage: $perPage
        sort: $sort
        id: $id
      ) {
        attributes {
          id
          name
        }
        count
        hasMore
      }
    }
  `;

  const variables = {
    search,
    skip,
    perPage,
    sort,
    id,
  };
  try {
    const data = await fetchGraphQLData(query, variables);
    return data?.response?.attributes || []; // Return the fetched audience data
  } catch (error) {
    console.error("❌ Error fetching attributes:", error);
    return [];
  }

};






export const useCheckUniqueAttribute = () => {
    const mutation = gql`
      mutation ($id: String!, $name: String!, $generic: Boolean!) {
        exists: checkUniqueAttribute(id: $id, name: $name, generic: $generic)
      }
    `;
  
    return useGqlMutation(mutation, {});
  };
  


  export const useSaveGroupLayout = () => {
    const mutation = gql`
      mutation ($data: [CustomFieldGroupInput!]!) {
        SaveGroupLayout(data: $data)
      }
    `;
  
    return useGqlMutation(mutation, {});
  };
  
