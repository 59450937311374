/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import { useQueryClient } from "react-query";
import { gql } from "graphql-request";
import { useGqlQuery, useGqlMutation } from "api/Api";

export const alertSelect = `
  id
  condition
  encoding
  from
  subject
  toEmails
  toUsers
  toUsersWithSame
  count
`;

export const useFetchAlerts = (qId) => {
  const query = gql`
    query  {
      alerts: fetchAlerts(questionId: "${qId}") {
        ${alertSelect}
      }
    }
  `;
  return useGqlQuery(["alerts: " + qId], query, {});
};

export const useCreateAlert = () => {
  const mutation = gql`
    mutation CreateAlert($questionId: String!) {
      alert: createAlert(questionId: $questionId) {
        ${alertSelect}
        questionId
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: (data) => {
      if (data.alert.questionId) {
        queryClient.invalidateQueries("alerts: " + data.alert.questionId);
      }
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDeleteAlert = () => {
  const mutation = gql`
    mutation DeleteAlert($id: String!) {
      deleteAlert(id: $id)
    }
  `;

  return useGqlMutation(mutation);
};

export const useUpdateAlert = () => {
  const mutation = gql`
    mutation UpdateAlert($data: AlertInput!, $id: String!) {
      updateAlert(data: $data, id: $id)
    }
  `;

  return useGqlMutation(mutation);
};

export const usePreviewAlert = () => {
  const mutation = gql`
    mutation PreviewAlert($id: String!) {
      html: previewAlert(id: $id)
    }
  `;

  return useGqlMutation(mutation);
};
