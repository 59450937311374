import React, { useEffect, useState } from "react";
import { DndContext, useDraggable, useDroppable } from "@dnd-kit/core";
import styles from "./AudienceFolders.module.scss";
import Icon from "components/Icon/Icon";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import {
  useAddAudienceToFolder,
  useRemoveAudienceFromFolder,
  useSearchAllAudiences,
} from "api/resources/contacts/audiences";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Button from "components/Button/Button";
import { Pagination } from "components/tables/BasicTable/Pagination/Pagination";
import { Loading } from "components/Loading/Loading";
import { trimDate } from "assets/functions/DateFunctions";
import { useNavigate } from "react-router-dom";
import Modal from "components/ReactModal/ReactModal";
import CreateAudience from "./CreateAudience";
import AddToFolder from "./AddToFolder";
import { useSearchContactsCount } from "api/resources/contacts/contacts";

export default function AudienceFolders({
  initialFolders,
  audienceCount,
  refetchFolders,
}) {
  const allId = "allaudiencefolders";
  const [folders, setFolders] = useState(initialFolders);
  const [selectedFolder, setSelectedFolder] = useState({ id: allId });
  const [dragOverFolder, setDragOverFolder] = useState(null);
  const [droppedFile, setDroppedFile] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [createAudience, setCreateAudience] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const addAudience = useAddAudienceToFolder();

  const moveFile = (active, over) => {
    const audienceId = active?.id;
    const folderId = over?.id;
    addAudience.mutate(
      {
        audienceId,
        folderId,
      },
      {
        onSuccess: (data) => {
          let tempFolders = [...folders];
          let index = tempFolders.findIndex((f) => f.id === folderId);
          tempFolders[index].audienceCount += 1;
          if (selectedFolder.id != allId) {
            let oldindex = tempFolders.findIndex(
              (f) => f.id === selectedFolder.id
            );
            tempFolders[oldindex].audienceCount -= 1;
          }
          setFolders(tempFolders);
          if (selectedFolder.id != allId) {
            setRefetch(!refetch);
          }
        },
      }
    );
    setDroppedFile({ audienceId, folderId });
    setTimeout(() => setDroppedFile(null), 2000); // Remove animation after 2s
  };

  const removeAudience = useRemoveAudienceFromFolder();
  function handleRemoveAudience(e, audienceId, folderId, finish) {
    e.stopPropagation();
    removeAudience.mutate(
      {
        audienceId,
      },
      {
        onSuccess: (data) => {
          if (finish) {
            let tempFolders = [...folders];
            let index = tempFolders.findIndex((f) => f.id === folderId);
            tempFolders[index].audienceCount -= 1;
            setFolders(tempFolders);
            finish();
          }
        },
      }
    );
  }

  function handleAddToFolder(e, audience) {
    e.stopPropagation();
    setShowAdd(audience);
  }

  return (
    <>
      {createAudience && (
        <Modal show={createAudience} dark>
          <CreateAudience
            audience={createAudience}
            close={() => {
              setCreateAudience(false);
              refetchFolders();
            }}
            folder
            create={!createAudience?.id}
          ></CreateAudience>
        </Modal>
      )}

      {showAdd && (
        <Modal show={showAdd} dark onClose={() => setShowAdd(false)}>
          <AddToFolder
            audience={showAdd}
            moveFile={moveFile}
            close={() => setShowAdd(false)}
          ></AddToFolder>
        </Modal>
      )}

      <DndContext
        onDragStart={(event) => setDragOverFolder(null)}
        onDragOver={(event) => setDragOverFolder(event.over?.id || null)}
        onDragEnd={(event) => {
          const { active, over } = event;
          if (over && active) moveFile(active, over);
          setDragOverFolder(null);
        }}
      >
        <div className={styles.fileManagerContainer}>
          {/* Sidebar */}
          <div className={styles.sidebar}>
            <FlexRow
              align={"center"}
              style={{ padding: "1rem" }}
              justify={"space-between"}
            >
              <h3>Folders</h3>
              <Button
                height={2}
                width={33}
                onClick={() => setCreateAudience(!createAudience)}
              >
                <i className="bi-plus" style={{ fontSize: "1.3rem" }}></i>
              </Button>
            </FlexRow>
            <HorizontalBar height={1} style={{ margin: "0" }}></HorizontalBar>
            <FolderItem
              id={allId}
              folder={{
                name: "All Audiences",
                id: allId,
                audienceCount: audienceCount,
                icon: "folder-fill",
              }}
              isHighlighted={
                dragOverFolder === allId || selectedFolder?.id === allId
              }
              onClick={() => setSelectedFolder({ id: allId })}
            />
            <HorizontalBar height={1} style={{ margin: "0" }}></HorizontalBar>
            {folders.map((folder) => (
              <React.Fragment key={folder.id}>
                <FolderItem
                  id={folder.id}
                  folder={folder}
                  isHighlighted={
                    dragOverFolder === folder.id ||
                    selectedFolder?.id === folder?.id
                  }
                  onClick={() => setSelectedFolder(folder)}
                  dropped={droppedFile && droppedFile?.folderId === folder?.id}
                />
                <HorizontalBar
                  height={1}
                  style={{ margin: "0" }}
                ></HorizontalBar>
              </React.Fragment>
            ))}
          </div>
          <HorizontalBar rotate width={1}></HorizontalBar>
          {/* File List */}
          {selectedFolder.id != allId && (
            <FolderAudiences
              folder={selectedFolder}
              handleRemoveAudience={handleRemoveAudience}
              refetch={refetch}
              onEdit={setCreateAudience}
              handleAddToFolder={handleAddToFolder}
            />
          )}
          {selectedFolder.id === allId && (
            <FolderAudiences
              folder={{ id: "", name: "All Audiences" }}
              handleAddToFolder={handleAddToFolder}
            />
          )}{" "}
        </div>
      </DndContext>
    </>
  );
}

function FolderItem({ id, isHighlighted, onClick, folder, dropped }) {
  const { setNodeRef } = useDroppable({ id });
  return (
    <div
      ref={setNodeRef}
      className={`${styles.folderItem} ${
        isHighlighted ? styles.highlighted : ""
      }`}
      onClick={onClick}
    >
      <Icon blue iconName={folder?.icon} style={{ fontSize: "1.9rem" }}></Icon>
      <FlexCol>
        <span>{folder?.name}</span>
        <small className={styles.gray}>{folder?.audienceCount} audiences</small>
      </FlexCol>
      {dropped && (
        <div className={styles.dropAnimation}>
          <i className="bi-check-circle"></i> Successfully added!
        </div>
      )}
      {isHighlighted && (
        <HorizontalBar
          rotate
          width={2}
          style={{
            margin: 0,
            position: "absolute",
            zIndex: "1",
            right: "0px",
          }}
          backgroundColor={"#15bcc7"}
        ></HorizontalBar>
      )}
    </div>
  );
}

function FolderAudiences({
  folder,
  handleRemoveAudience,
  refetch,
  onEdit,
  handleAddToFolder,
}) {
  const [sort, setSort] = useState({
    item: "name",
    descend: true,
  });
  const [search, setSearch] = useState("");
  const [skip, setSkip] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [createAudience, setCreateAudience] = useState(false);

  const getAllAudiences = useSearchAllAudiences(
    search,
    skip,
    perPage,
    sort,
    folder?.id
  );

  useEffect(() => {
    getAllAudiences.refetch();
    return () => {
      getAllAudiences.remove();
    };
  }, [search, perPage, skip, sort, refetch]);

  const handleSort = (column) => {
    setSort((prevSort) => ({
      item: column,
      descend: prevSort.item === column ? !prevSort.descend : true,
    }));
  };
  function finishRemove() {
    getAllAudiences.remove();
    getAllAudiences.refetch();
  }

  return (
    <div className={styles.fileListContainer}>
      {createAudience && (
        <Modal show={createAudience} dark>
          <CreateAudience
            close={() => setCreateAudience(false)}
            create
          ></CreateAudience>
        </Modal>
      )}
      <>
        <FlexCol gap="1rem">
          <FlexRow justify={"space-between"}>
            <h3 style={{ paddingLeft: ".2rem" }}>{folder.name}</h3>{" "}
            {folder?.id && (
              <Button onClick={() => onEdit(folder)} style={{ gap: ".5rem" }}>
                <Icon blue iconName={"gear"}></Icon>Folder Settings
              </Button>
            )}
          </FlexRow>
          <FlexRow justify={"space-between"} wrap={"wrap"}>
            <FlexRow fit wrap={"wrap"}>
              <NavTextField
                value={search}
                onDelayedChange={(val) => {
                  setSearch(val);
                }}
                placeholder={"Search audiences..."}
                className={styles.search}
                shadow
              />
              <Button
                shadow
                onClick={() => setCreateAudience(true)}
                style={{ gap: ".5em", whiteSpace: "nowrap" }}
              >
                <Icon blue iconName={"people-fill"}></Icon> New Audience
              </Button>
            </FlexRow>

            <FlexRow fit gap=".5rem" align={"center"}>
              {/* <Button>
                <i className="bi-list"></i>
              </Button> */}
              <Pagination
                pageNum={page}
                setPageNum={setPage}
                maxPage={
                  getAllAudiences?.data?.response?.count / perPage < 1
                    ? 1
                    : Math.ceil(
                        getAllAudiences?.data?.response?.count / perPage
                      )
                }
                length={perPage}
                searchString={search}
                perPage={perPage}
                setPerPage={setPerPage}
                setPageSkip={setSkip}
                dropDown
              ></Pagination>{" "}
            </FlexRow>
          </FlexRow>
        </FlexCol>
        {getAllAudiences.isLoading && <Loading></Loading>}
        {getAllAudiences.isSuccess && (
          <table className={styles.table}>
            <thead>
              <tr>
                <th>
                  {" "}
                  <div className={styles.headerContainer}></div>
                </th>
                <th onClick={() => handleSort("name")}>
                  <div className={styles.headerContainer}>
                    Name{" "}
                    {sort.item === "name" &&
                      (sort.descend ? (
                        <i className="bi-caret-down-fill" />
                      ) : (
                        <i className="bi-caret-up-fill" />
                      ))}
                  </div>
                </th>
                <th>
                  <div className={styles.headerContainer}>
                    Contacts{" "}
                    {sort.item === "contactCount" &&
                      (sort.descend ? (
                        <i className="bi-caret-down-fill" />
                      ) : (
                        <i className="bi-caret-up-fill" />
                      ))}
                  </div>
                </th>
                <th onClick={() => handleSort("updatedAt")}>
                  <div className={styles.headerContainer}>
                    {" "}
                    Last Updated{" "}
                    {sort.item === "updatedAt" &&
                      (sort.descend ? (
                        <i className="bi-caret-down-fill" />
                      ) : (
                        <i className="bi-caret-up-fill" />
                      ))}
                  </div>
                </th>
                <th>
                  {" "}
                  <div className={styles.headerContainer}></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {getAllAudiences.data?.response?.audiences?.map((audience) => (
                <React.Fragment key={audience.id}>
                  {/* <tr>
                    <td style={{ paddingLeft: "0" }}>
                      <HorizontalBar
                        height={1}
                        width={"100%"}
                        style={{ margin: "0" }}
                      ></HorizontalBar>
                    </td>
                    <td>
                      <HorizontalBar
                        height={1}
                        width={"100%"}
                        style={{ margin: "0" }}
                      ></HorizontalBar>
                    </td>
                    <td>
                      <HorizontalBar
                        height={1}
                        width={"100%"}
                        style={{ margin: "0" }}
                      ></HorizontalBar>
                    </td>
                    <td>
                      <HorizontalBar
                        height={1}
                        width={"100%"}
                        style={{ margin: "0" }}
                      ></HorizontalBar>
                    </td>

                    <td>
                      <HorizontalBar
                        height={1}
                        width={"100%"}
                        style={{ margin: "0" }}
                      ></HorizontalBar>
                    </td>
                  </tr> */}
                  <DraggableAudience
                    key={audience.id}
                    id={audience.id}
                    audience={audience}
                    handleRemoveAudience={(e, id) =>
                      handleRemoveAudience(e, id, folder?.id, finishRemove)
                    }
                    folderId={folder?.id}
                    onAdd={handleAddToFolder}
                  />
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}

        <FlexRow justify={"flex-end"}>
          <Pagination
            pageNum={page}
            setPageNum={setPage}
            maxPage={
              getAllAudiences?.data?.response?.count / perPage < 1
                ? 1
                : Math.ceil(getAllAudiences?.data?.response?.count / perPage)
            }
            length={perPage}
            searchString={search}
            perPage={perPage}
            setPerPage={setPerPage}
            setPageSkip={setSkip}
          ></Pagination>
        </FlexRow>
      </>
    </div>
  );
}

function DraggableAudience({
  id,
  audience,
  handleRemoveAudience,
  folderId,
  onAdd,
}) {
  const navigate = useNavigate();
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({ id });
  const [clickTimeout, setClickTimeout] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const style = {
    transform:
      transform && !menuOpen && !clickTimeout
        ? `translate(${transform.x}px, ${transform.y}px)`
        : "none",
    transition: isDragging ? "none" : "transform 0.1s ease-in-out",
    opacity: isDragging ? 0.8 : 1,
    boxShadow:
      !clickTimeout && isDragging && !menuOpen
        ? "rgba(0, 0, 0, 0.12) 0px 1px 5px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
        : "",
  };

  const handlePointerDown = () => {
    const timeout = setTimeout(() => setClickTimeout(null), 500); // 150ms threshold
    setClickTimeout(timeout);
  };

  const handlePointerUp = () => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      if (!menuOpen) {
        navigate("/ContactManagement/Audiences/" + audience?.id);
      }
    }
  };
  const getCount = useSearchContactsCount(
    "",
    0,
    { descend: true, item: "email" },
    audience?.id,
    audience?.filters ? audience?.filters : "",
    "[]"
  );

  return (
    <tr
      ref={setNodeRef}
      className={styles.draggableFile}
      style={style}
      {...listeners}
      {...attributes}
      onMouseDown={handlePointerDown}
      onMouseUp={handlePointerUp}
    >
      <td>
        <i
          className={`bi-${audience?.icon}`}
          style={{ color: audience?.iconColor, fontSize: "1.5rem" }}
        ></i>
      </td>
      <td
        style={{
          maxWidth: "400px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {audience?.name}
      </td>

      <td>
        <small>{getCount?.data?.response?.count} contacts</small>
      </td>
      <td>
        {" "}
        <small>{trimDate(audience?.updatedAt)}</small>
      </td>

      <td>
        <div
          onMouseEnter={() => setMenuOpen(true)}
          onMouseLeave={() => setMenuOpen(false)}
        >
          <Button
            height={2}
            width={35}
            onMouseEnter={() => setMenuOpen(true)}
            onMouseLeave={() => setMenuOpen(false)}
            options={
              folderId
                ? [
                    {
                      onMouseUp: (e) => {
                        onAdd(e, audience);
                      },
                      data: (
                        <>
                          <Icon green iconName={"plus-circle"}></Icon>Move to
                          Folder
                        </>
                      ),
                    },
                    {
                      onMouseUp: (e) => handleRemoveAudience(e, audience?.id),
                      data: (
                        <>
                          <Icon red iconName={"eraser"}></Icon>Remove From
                          Folder
                        </>
                      ),
                    },
                  ]
                : [
                    {
                      onMouseUp: (e) => {
                        onAdd(e, audience);
                      },
                      data: (
                        <>
                          <Icon green iconName={"plus-circle"}></Icon>Add to
                          Folder
                        </>
                      ),
                    },
                  ]
            }
          >
            <i className="bi-three-dots-vertical"></i>
          </Button>
        </div>
      </td>
    </tr>
  );
}
