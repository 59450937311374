import { Sidebar2 } from "components/sidebars";
import React, { useState } from "react";
import styles from "./ReportsPage.module.scss";
// import { useNavigate } from "react-router-dom";
import { AllReportsRoutes } from "routes/AllReportsRoutes";
import { TabBar, TabPill } from "components/layouts";
import Button from "components/Button/Button";
import { useFetchReportByExclusiveId } from "api/resources/organization/reports";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import CreateReport from "./CreateReport";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { useNavigate } from "react-router-dom";
import { ErrorBanner } from "pages/error_page/ErrorPage";
import { HeaderContainer } from "components/layouts/Header/HeaderContainer";
// import { NavBar } from "components/sidebars/NavBar/NavBar";

export function ReportsPage({ roles, user, organization }) {
  const [active, setActive] = useState(0);
  const [condensed, setCondensed] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const menuItems = [
    {
      id: 0,
      name: "Organization",
      to: "home",
      icon: `building`,
    },
    {
      id: 1,
      name: "Favorites ",
      to: "favorites",
      icon: `star`,
    },
    // roles.canViewOrgReports && {
    //   id: 1,
    //   name: `${organization.name}`,
    //   to: "organization",
    //   icon: `building`,
    // },
    {
      id: 3,
      name: "My Reports",
      to: `myreports`,
      icon: "file-post",
    },
    {
      id: 4,
      name: "Shared",
      to: `sharedwithme`,
      icon: "people",
    },
  ];

  function checkCondensed() {
    if (!condensed) {
      setCondensed(true);
    }
  }

  const [searchString, setSearchString] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [pageSkip, setPageSkip] = useState(0);
  const [pageSort, setPageSort] = useState({
    item: "updatedAt",
    descend: true,
  });

  const searchReportQuery = useFetchReportByExclusiveId(
    searchString,
    pageSkip,
    perPage,
    pageSort,
    "Search"
  );

  const navigate = useNavigate();

  return (
    <div className={styles.page} onClick={checkCondensed}>
      <div className={styles.tableHeader}>
        <div className={styles.tabs}>
         
          <HeaderContainer path={"Analyze Data"} user={user} organization={organization} roles={roles}>
          <FlexRow>
            {menuItems.map((item) => (
              <Button
                active={item.id === active}
                onClick={() => {
                  setActive(item.id);
                  navigate(item.to);
                }}
              >
                {item.name}
              </Button>
            ))}
          </FlexRow>
        </HeaderContainer>
        </div>
        <div className={styles.searchHeader}>
          <Button onClick={() => setShowCreate(true)} shadow blue>
            <div style={{ display: "flex", gap: ".5rem" }}>
              <i className="bi-pencil-square"></i> Create Report
            </div>
          </Button>

          <div className={styles.searchBox}>
            <NavTextField
              value={searchString}
              setValue={(val) => {
                setSearchString(val);
              }}
              placeholder={"Search reports..."}
              className={styles.search}
              shadow
            />
            {searchReportQuery.isError && (
              <ErrorBanner
                error={searchReportQuery.error}
                message={"Error pulling up reports"}
              />
            )}
            {searchReportQuery.isSuccess && (
              <div className={styles.items}>
                {searchReportQuery?.data?.reports.map((report) => (
                  <div
                    className={styles.item}
                    onClick={() => onRowClick(report)}
                  >
                    <span className={styles.name}> {report?.name}</span>
                    <span className={styles.type}> {report?.type}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.content}>
        <AllReportsRoutes roles={roles} org={organization}></AllReportsRoutes>
      </div>

      {showCreate && <CreateReport close={() => setShowCreate(false)} />}
    </div>
  );
}
