import { useEffect, useState } from "react";
import styles from "./AnswersFilter.module.scss";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import { useFetchQuestionsMutationQuery } from "api/resources/projects/questions";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { SelectField, SelectFieldCustom, TextEntry } from "components/inputs";
import { Loading } from "components/Loading Rectangle/Loading";
import { getSortedArray } from "assets/functions/ArrayFunctions";
import { useFetchStartedSurveys } from "api/resources/projects/projects";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import { OneOrTheOther } from "components/inputs/input_fields/OneOrTheOther/OneOrTheOther";
import { ErrorBanner } from "pages/error_page/ErrorPage";

export default function AnswersFilter({
  projects,
  updateChosenFilters,
  chosenFilter,
  externalFilter,
}) {
  const [conditions, setConditions] = useState(
    chosenFilter.answers ? JSON.parse(JSON.stringify(chosenFilter.answers)) : []
  );

  const [addNew, setAddNew] = useState(false);

  function onCheck(e, index) {
    let copy = [...conditions];
    copy[index].takenOff = !e.target.checked;
    saveConditions(copy);
  }

  function onApply(condition, index) {
    let copy = [...conditions];
    copy[index] = condition;
    if (copy[index].takenOff) {
      delete copy[index].takenOff;
    }
    saveConditions(copy);
  }

  function onRemove(index) {
    let copy = [...conditions];
    copy.splice(index, 1);
    saveConditions(copy);
  }

  function saveConditions(copy) {
    setConditions(copy);

    let active = copy.filter((cond) => !cond.takenOff);
    let filter = { ...chosenFilter };
    if (!active.length) {
      delete filter.answers;
      delete filter.orAnswers;
    } else {
      filter.answers = active;
    }
    updateChosenFilters(filter);
  }

  const hasExternal = externalFilter && externalFilter?.answers;

  function toggleOr(val) {
    let filter = { ...chosenFilter };
    if (val) {
      filter.orAnswers = true;
    } else {
      delete filter.orAnswers;
    }

    updateChosenFilters(filter);
  }

  function toggleAnd(val) {
    let filter = { ...chosenFilter };
    if (val) {
      delete filter.orAnswers;
    } else {
      filter.orAnswers = true;
    }

    updateChosenFilters(filter);
  }

  function isCleared() {
    return !("answers" in chosenFilter);
  }

  return (
    <div className={styles.container}>
      {(conditions.length > 0 || hasExternal) && (
        <>
          {chosenFilter.answers && chosenFilter.answers.length > 1 && (
            <div className={styles.andOr}>
              <div className={styles.andOrToggle}>
                <ToggleSwitch
                  startChecked={!chosenFilter.orAnswers}
                  handleCheck={toggleAnd}
                />
                And
              </div>
              <div className={styles.andOrToggle}>
                <ToggleSwitch
                  startChecked={chosenFilter.orAnswers ? true : false}
                  handleCheck={toggleOr}
                />
                Or
              </div>
            </div>
          )}
          <div className={styles.conditions}>
            {conditions.map((condition, i) => (
              <Condition
                condition={condition}
                projects={projects}
                onApply={(c) => onApply(c, i)}
                onCheck={(e) => onCheck(e, i)}
                onRemove={() => onRemove(i)}
                cleared={isCleared()}
              />
            ))}

            {hasExternal &&
              externalFilter.answers.map((condition) => (
                <Condition
                  condition={condition}
                  pageFilter
                  // projects={projects}
                  // onApply={(c) => onApply(c, i)}
                  // onCheck={(e) => onCheck(e, i)}
                  // onRemove={() => onRemove(i)}
                />
              ))}
          </div>

          {!addNew && (
            <div className={styles.addNew}>
              <div className={styles.addFilter} onClick={() => setAddNew(true)}>
                + Filter
              </div>
            </div>
          )}
        </>
      )}

      {!(conditions.length || hasExternal) && (
        <EditCondition projects={projects} onApply={(c) => onApply(c, 0)} />
      )}

      {addNew && (
        <div className={styles.addingNew}>
          <EditCondition
            projects={projects}
            onApply={(c) => {
              onApply(c, conditions.length);
              setAddNew(false);
            }}
            onCancel={() => setAddNew(false)}
          />
        </div>
      )}
    </div>
  );
}

function Condition({
  condition,
  projects,
  onApply,
  onCheck,
  onRemove,
  pageFilter,
  cleared,
}) {
  const [edit, setEdit] = useState(false);

  function getAnswer() {
    let op = condition.operator;

    if (!condition.answer) {
      if (op === "promoters" || op === "passives" || op === "detractors") {
        return <div className={styles.answer}>{condition.operator}</div>;
      }
      if (op === "have answered") {
        return <div className={styles.answer}> gave any answer</div>;
      }
      if (op === "have not answered") {
        return <div className={styles.answer}> gave no answer</div>;
      }
    }

    if (Array.isArray(condition.answer)) {
      if (op === "answer" || op === "do not answer" || op === "falls in") {
        let string = "";
        for (let i = 0; i < condition.answer.length; i++) {
          if (i > 0) {
            if (i < condition.answer.length - 1) {
              string += ", ";
            } else if (i == condition.answer.length - 1) {
              string += " or ";
            }
          }
          string += condition.answer[i];
        }

        if (op === "do not answer") {
          string = "not " + string;
        }
        return <div className={styles.answer}>{string}</div>;
      }

      if (op === "between") {
        let them = getSortedArray(condition.answer, (a, b) => a - b);
        return (
          <div className={styles.answer}>
            {"between " + them[0] + " and " + them[1]}
          </div>
        );
      }
    }

    if (op === "below" || op === "above") {
      return (
        <div className={styles.answer}> {op + " " + condition.answer}</div>
      );
    }

    if (op === "does not contain") {
      return (
        <div className={styles.answer}>
          {" "}
          {op + "  '" + condition.answer + "'"}
        </div>
      );
    }

    if (op === "contains") {
      return (
        <div className={styles.answer}> {"'" + condition.answer + "'"}</div>
      );
    }

    if (op === "is longer than" || op === "is shorter than") {
      let string = "";
      if (op === "is longer than") {
        string += "more than ";
      } else {
        string += "less than ";
      }
      return (
        <div className={styles.answer}>
          {string + condition.answer + " characters"}
        </div>
      );
    }

    return <div className={styles.answer}>{condition.answer}</div>;
  }

  return (
    <>
      {!edit && (
        <div className={styles.condition}>
          <Checkbox
            checked={!condition.takenOff && !cleared}
            onChange={onCheck}
            color={pageFilter ? "#7fcfd3" : undefined}
            disabled={pageFilter}
          />
          <div className={styles.display}>
            <div className={styles.info}>
              <div className={styles.surveyName}>{condition.surveyName}</div>

              <div className={styles.qName}>{`${condition.qName}`}</div>
              {getAnswer()}
            </div>
          </div>

          {!pageFilter && (
            <div className={styles.editContainer}>
              <div className={styles.edit} onClick={() => setEdit(true)}>
                <i className={`bi bi-pencil`}></i>

                <i className={`bi bi-pencil-fill ${styles.editFill}`}></i>
              </div>
            </div>
          )}
          {pageFilter && <div className={styles.pageFilter}>Page Filter</div>}
        </div>
      )}

      {edit && (
        <EditCondition
          projects={projects}
          init={condition}
          onApply={(c) => {
            onApply(c);
            setEdit(false);
          }}
          onRemove={onRemove}
          onCancel={() => setEdit(false)}
        />
      )}
    </>
  );
}

function EditCondition({ onApply, onCancel, onRemove, projects, init }) {
  const [question, setQuestion] = useState();
  const [Qs, setQs] = useState();
  const [edited, setEdited] = useState(false);

  const [condition, setCondition] = useState(
    init
      ? JSON.parse(JSON.stringify(init))
      : {
          projectId: null,
          surveyName: "",
          questionId: null,
          qName: "",
          op: null,
          answer: null,
        }
  );

  const getQuestions = useFetchQuestionsMutationQuery();
  const getAllProjects = useFetchStartedSurveys();

  useEffect(() => {
    if (condition.projectId) {
      getQuestions.mutate(
        {
          projectId: condition.projectId,
        },
        {
          onSuccess: (data) => {
            setQs(data.Qs);
            if (!question && condition.questionId) {
              let quest = data.Qs.find((q) => q.id === condition.questionId);
              if (quest) {
                setQuestion(quest);
              }
            }
          },
        }
      );
    }
  }, [condition.projectId]);

  function getProjectOptions() {
    let notIncluded = getAllProjects.data.surveys.filter(
      (p) => !projects.some((proj) => proj.id === p.id)
    );
    let tied = projects.map((p) => {
      return { value: p.id, label: p.name };
    });
    let notTied = notIncluded.map((p) => {
      return { value: p.id, label: p.name };
    });
    return [...tied, ...notTied];
  }

  function onSave() {
    onApply(condition);
  }

  function changeCondition(copy) {
    setCondition(copy);
    setEdited(true);
  }

  function changeProject(projId, name) {
    if (projId !== condition.projectId) {
      changeCondition({
        projectId: projId,
        surveyName: name,
        questionId: null,
        qName: "",
        operator: null,
        answer: null,
      });
      setQuestion(null);
      setQs(null);
    }
  }

  function changeQuestion(qId, name) {
    if (qId !== condition.questionId) {
      let copy = { ...condition };
      copy.questionId = qId;
      copy.qName = name;
      copy.operator = null;
      copy.answer = null;
      delete copy.bucket;
      delete copy.option;
      changeCondition(copy);
      let quest = Qs.find((q) => q.id == qId);
      setQuestion(quest);
    }
  }

  function changeOption(option) {
    let copy = { ...condition };
    copy.option = option;
    changeCondition(copy);
  }

  function changeOperator(op) {
    if (op !== condition.operator) {
      let copy = { ...condition };
      copy.answer = null;
      copy.operator = op;
      delete copy.bucket;
      if (op === "is longer than" || op === "is shorter than") {
        copy.answer = 100;
      }

      changeCondition(copy);
    }
  }

  function changeAnswer(chosen) {
    let copy = { ...condition };

    if (canSelectMultiple(condition)) {
      if (!chosen.length) {
        copy.answer = null;
      } else {
        if (copy.operator === "between" && chosen.length == 3) {
          let last2 = chosen.slice(1);
          copy.answer = last2.map((c) => c.value);
        } else {
          copy.answer = chosen.map((c) => c.value);
        }
      }
    } else {
      copy.answer = chosen.value;
    }

    changeCondition(copy);
  }

  function changeTextAnswer(value) {
    if (
      condition.operator === "is longer than" ||
      condition.operator === "is shorter than"
    ) {
      if (isNaN(value)) {
        return;
      }
    }
    changeCondition({ ...condition, answer: value });
    setEdited(true);
  }

  function canSelectMultiple() {
    if (condition.operator) {
      let op = condition.operator;
      return (
        op === "answer" ||
        op === "do not answer" ||
        op === "between" ||
        op === "falls in"
      );
    }
    return false;
  }

  const genOperators = [
    { value: "answer", label: "answered:" },
    { value: "do not answer", label: "did not answer:" },
    { value: "have answered", label: "gave any answer" },
    { value: "have not answered", label: "gave no answer" },
  ];

  const scaleOperators = [
    { value: "between", label: "answered between" },
    { value: "below", label: "answered below" },
    { value: "above", label: "answered above" },
  ];

  const npsOperators = [
    { value: "promoters", label: "were promoters" },
    { value: "passives", label: "were passives" },
    { value: "detractors", label: "were detractors" },
  ];

  const choiceSingleOperators = [];

  const choiceManyOperators = [];

  const freeResponseOperators = [
    { value: "contains", label: "contains" },
    { value: "does not contain", label: "does not contain" },
    { value: "have answered", label: "is not blank (gave an answer) " },
    { value: "have not answered", label: "is blank (gave no answer)" },
    { value: "is longer than", label: "is longer than" },
    { value: "is shorter than", label: "is shorter than" },
  ];

  function getRelavantOperators() {
    if (question) {
      if (question.scaleQuestion) {
        if (question.isScore) {
          return [...npsOperators, ...genOperators, ...scaleOperators];
        }
        return [...genOperators, ...scaleOperators];
      } else if (question.textQuestion) {
        if (
          question.textQuestion.bucket &&
          question.textQuestion.bucket.length
        ) {
          return [
            { value: "falls in", label: "falls in" },
            ...freeResponseOperators,
          ];
        }

        return freeResponseOperators;
      } else if (question.choiceQuestion) {
        if (question.choiceQuestion.isMultiSelect) {
          return [...genOperators, ...choiceManyOperators];
        } else {
          return [...genOperators, ...choiceSingleOperators];
        }
      }
    }
    return [];
  }

  function getAnswerOptions() {
    if (question.choiceQuestion) {
      let choiceQ = question.choiceQuestion;
      let choices = choiceQ.choices.map((choice) => {
        return { value: choice, label: choice };
      });
      if (choiceQ.hasOtherOption) {
        choices.push({
          value: choiceQ.otherOptionLabel,
          label: choiceQ.otherOptionLabel,
        });
      }
      return choices;
    }

    if (question.scaleQuestion) {
      let scale = question.scaleQuestion;
      let options = [];
      for (let i = scale.min; i < scale.max + 1; i += scale.step) {
        options.push({ label: i, value: i });
      }
      return options;
    }
  }

  function getChosenAnswerOptions() {
    if (condition?.answer) {
      if (Array.isArray(condition.answer)) {
        return condition.answer.map((val) => {
          return { value: val, label: val };
        });
      } else {
        return { value: condition.answer, label: condition.answer };
      }
    }
    return null;
  }

  function showAnswerOptions() {
    if (question && question.textQuestion) {
      return false;
    }

    if (condition?.operator) {
      let op = condition.operator;
      if (
        op === "have answered" ||
        op === "have not answered" ||
        op === "promoters" ||
        op === "passives" ||
        op === "detractors"
      ) {
        return false;
      }
    }
    return true;
  }

  function showTextEntry() {
    if (question && condition?.operator) {
      if (question.textQuestion) {
        let op = condition.operator;
        if (op === "contains" || op === "does not contain") {
          return true;
        }
      }
    }

    return false;
  }

  function showNumberInput() {
    if (question && condition?.operator) {
      if (question.textQuestion) {
        let op = condition.operator;
        if (op === "is longer than" || op === "is shorter than") {
          return true;
        }
      }
    }
    return false;
  }

  function getBucketOptions() {
    return question.textQuestion.bucket.map((bucket) => {
      return {
        value: bucket.id,
        label: bucket.name,
      };
    });
  }

  function changeBucket(bucketId) {
    if (bucketId !== condition.bucket) {
      let copy = { ...condition };
      copy.answer = null;
      copy.bucket = bucketId;
      changeCondition(copy);
    }
  }

  function getPropertyOptions() {
    let theOne = question.textQuestion.bucket.find(
      (b) => b.id === condition.bucket
    );
    if (theOne) {
      return theOne.properties.map((prop) => {
        return {
          value: prop,
          label: prop,
        };
      });
    }
    return [];
  }

  function isComplete() {
    if (condition?.operator) {
      if (
        condition?.operator === "between" &&
        condition?.answer &&
        condition.answer.length !== 2
      ) {
        return false;
      }
      if (condition.answer) {
        return true;
      }
      let op = condition.operator;
      if (
        op === "have answered" ||
        op === "have not answered" ||
        op === "promoters" ||
        op === "passives" ||
        op === "detractors"
      ) {
        return true;
      }
    }
    return false;
  }

  return (
    <div className={styles.editableCondition}>
      {getQuestions.isError && (
        <ErrorBanner
          error={getQuestions.error}
          message="Error fetching questions"
        />
      )}
      {getAllProjects.isError && (
        <ErrorBanner
          error={getAllProjects.error}
          message="Error fetching surveys"
        />
      )}
      <div className={styles.section}>
        <div className={styles.preface}>Those who on</div>

        {getAllProjects.isLoading && (
          <div
            style={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
          >
            <Loading height={60} width={100} />
          </div>
        )}

        {getAllProjects.isSuccess && (
          <Select
            options={getProjectOptions()}
            value={
              condition.projectId
                ? { value: condition.projectId, label: condition.surveyName }
                : undefined
            }
            onChange={(opt) => changeProject(opt.value, opt.label)}
            label="Survey"
          ></Select>
        )}
      </div>

      {condition.projectId && (
        <div className={styles.section}>
          <div className={styles.preface}>for</div>
          {!Qs && (
            <div
              style={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
            >
              <Loading height={60} width={100} />
            </div>
          )}
          {Qs && (
            <Select
              options={Qs.map((q) => {
                return { value: q.id, label: q.questionText };
              })}
              value={
                question
                  ? { value: question.id, label: question.questionText }
                  : undefined
              }
              onChange={(opt) => changeQuestion(opt.value, opt.label)}
              label="Question"
            ></Select>
          )}
        </div>
      )}

      {question && (
        <>
          {question.matrixQuestion && (
            <div className={styles.section}>
              <div className={styles.preface}>on</div>
              <Select
                options={question.matrixQuestion.options.map((option) => {
                  return {
                    label: option,
                    value: option,
                  };
                })}
                value={
                  condition.option
                    ? question.matrixQuestion.options
                        .map((option) => {
                          return {
                            label: option,
                            value: option,
                          };
                        })
                        .find((opt) => opt.value === condition.option)
                    : null
                }
                onChange={(typeOption) => changeOption(typeOption.value)}
                label="Option"
              ></Select>
            </div>
          )}

          {!(question.matrixQuestion && !condition.option) && (
            <>
              <div className={styles.section}>
                <div className={styles.preface}>
                  {" "}
                  {question && question.textQuestion ? "their answer" : "they"}
                </div>
                <Select
                  options={getRelavantOperators()}
                  value={
                    condition.operator
                      ? getRelavantOperators().find(
                          (type) => condition.operator === type.value
                        )
                      : null
                  }
                  onChange={(typeOption) => changeOperator(typeOption.value)}
                  label="Operator"
                ></Select>
              </div>

              {condition.operator && (
                <>
                  {showAnswerOptions() && (
                    <div className={styles.section}>
                      <Select
                        options={getAnswerOptions()}
                        value={getChosenAnswerOptions()}
                        onChange={changeAnswer}
                        label="Choices"
                        selectMultiple={canSelectMultiple()}
                      ></Select>
                    </div>
                  )}

                  {showTextEntry() && (
                    <div className={styles.section}>
                      <TextEntry
                        label={"What to look for"}
                        placeholder="Enter text"
                        onChange={changeTextAnswer}
                        value={condition?.answer}
                      />
                    </div>
                  )}

                  {showNumberInput() && (
                    <div
                      className={styles.section}
                      style={{ paddingLeft: "20px" }}
                    >
                      <div className={styles.numberInput}>
                        <NumberInput
                          startNumber={
                            condition.answer
                              ? condition.answer
                              : 100 > question?.textQuestion?.maxLength
                              ? question?.textQuestion?.maxLength
                              : 100
                          }
                          min={0}
                          max={question?.textQuestion?.maxLength}
                          onSave={changeTextAnswer}
                        />
                      </div>
                      <div className={styles.preface}>characters.</div>
                    </div>
                  )}

                  {condition.operator === "falls in" && (
                    <>
                      <div className={styles.section}>
                        <Select
                          options={getBucketOptions()}
                          value={getBucketOptions().find(
                            (b) => b.value === condition.bucket
                          )}
                          onChange={(b) => changeBucket(b.value)}
                          label="Bucket"
                        ></Select>
                      </div>

                      {condition.bucket && (
                        <div className={styles.section}>
                          <Select
                            options={getPropertyOptions()}
                            value={getChosenAnswerOptions()}
                            onChange={changeAnswer}
                            label="Properties"
                            selectMultiple
                          ></Select>
                        </div>
                      )}

                      {condition.answer && (
                        <OneOrTheOther
                          left={"Overall Bucket"}
                          right={"Any Bucket"}
                          checked={condition?.includeAll}
                          onCheck={(val) =>
                            setCondition((old) => {
                              return { ...old, includeAll: val };
                            })
                          }
                          activeColor="#738c91"
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      <div className={styles.buttons}>
        {onCancel && (
          <>
            {init && question && (
              <>
                <div
                  className={`${styles.btn} ${styles.cancel} `}
                  onClick={onCancel}
                >
                  Cancel
                </div>

                {!edited && (
                  <div
                    className={`${styles.btn} ${styles.cancel} `}
                    onClick={onRemove}
                  >
                    Remove <i className="bi bi-trash"></i>
                  </div>
                )}
              </>
            )}
            {!init && (
              <div
                className={`${styles.btn} ${styles.cancel} `}
                onClick={onCancel}
              >
                Cancel
              </div>
            )}
          </>
        )}
        {isComplete() && (
          <>
            {edited && (
              <div
                className={`${styles.btn} ${styles.apply} `}
                onClick={onSave}
              >
                Apply
              </div>
            )}
            {/* {!edited && init && (
              <div
                className={`${styles.btn} ${styles.okay} `}
                onClick={onCancel}
              >
                Okay
              </div>
            )} */}
          </>
        )}
      </div>
    </div>
  );
}

const Select = ({ value, options, onChange, label, selectMultiple }) => {
  return (
    // <CombinedInput
    //   label={label}
    //   value={value}
    //   options={options}
    //   onChange={onChange}
    //   selectMultiple={selectMultiple}
    //   select
    //   noSearch
    //   icon={"bi bi-chevron-compact-down"}
    //   style={{ backgroundColor: "#ffffff" }}
    // ></CombinedInput>
    <SelectFieldCustom
      label={label}
      value={selectMultiple ? null : value}
      values={selectMultiple ? value : null}
      options={options}
      onChange={onChange}
      selectMultiple={selectMultiple}
      top
      // menuPortalTarget={elem}
      // outside
    />
    // <SelectField
    //   label={label}
    //   value={value}
    //   options={options}
    //   onChange={onChange}
    //   selectMultiple={selectMultiple}
    // />
  );
};
