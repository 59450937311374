/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2024 ReactionData. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson

*/
import React, { useState } from "react";
import styles from "./InteractiveTable.module.scss";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import Button from "components/Button/Button";
import { Loading } from "components/Loading/Loading";
import { BasicTable } from "./BasicTable";
import { PaginationContainer } from "./Pagination/PaginationContainer";
import { Pagination } from "./Pagination/Pagination";

function InteractiveTable({
  headers,
  onRowClick,
  sort,
  setSort,
  currPage,
  setCurrPage,
  perPage,
  setPerPage,
  queryData,
  queryDataAccessor,
  maxDataQuery,
  maxDataQueryAccessor,
  pageSkip,
  setPageSkip,
  searchString,
  setSearchString,
  actionButtonText,
  hideActionButton,
  disableActionButton,
  tableTitle,
  tableDescription,
  widgetType,
  searchPlaceholder,
  onClickActionButton,
  viewing,
  setViewing,
  roles,
  user,
  hideSearchButton,
  emptyMessage,
}) {
  const [widgetView, setWidgetView] = useState(widgetType ? true : false);

  function handlePageChange(integer) {
    if (integer > currPage) {
      setPageSkip((pageSkip) => pageSkip + perPage);
    } else {
      setPageSkip((pageSkip) => pageSkip - perPage);
    }
    setCurrPage(integer - 1);
  }

  function setActive(status, item) {
    let temp = [...viewing];
    temp[item.index].active = status;
    setViewing(temp);
  }

  function onSearch(val) {
    setSearchString(val);
    if (currPage !== 1) {
      setCurrPage(1);
    }
    if (pageSkip) {
      setPageSkip(0);
    }
  }

  return (
    <div className={styles.tableContainer}>
      {/* The Header*/}
      <div className={styles.tableHeader}>
        <div className={styles.titleTop}>
          {tableTitle && <div className={styles.tableTitle}>{tableTitle}</div>}
          {tableDescription && (
            <div className={styles.tableDescription}>{tableDescription}</div>
          )}
        </div>
        <div className={styles.headerInner}>
          {widgetType && (
            <div
              style={{
                display: "flex",
                gap: ".25em",
              }}
            >
              <div
                className={`${styles.icon} ${!widgetView ? styles.active : ""}`}
                onClick={() => setWidgetView(false)}
              >
                <i className="bi bi-list-ul"></i>
              </div>
              <div
                className={`${styles.icon} ${widgetView ? styles.active : ""}`}
                onClick={() => {
                  setWidgetView(true);
                }}
              >
                <i className="bi bi-grid"></i>
              </div>
            </div>
          )}
          {!hideActionButton && (
            <Button
              shadow
              disable={disableActionButton}
              onClick={
                onClickActionButton
                  ? onClickActionButton
                  : () => console.log("click")
              }
            >
              {actionButtonText ? actionButtonText : "Click Here "}
            </Button>
          )}
          {!hideSearchButton && (
            <NavTextField
              value={searchString}
              setValue={onSearch}
              placeholder={searchPlaceholder ? searchPlaceholder : "Search..."}
              className={styles.search}
              shadow
            />
          )}
        </div>
      </div>

      {/* The sTable*/}
      {(maxDataQuery?.isLoading ||
        queryData?.isLoading ||
        queryData?.isRefetching ||
        maxDataQuery?.isRefetching) && <Loading></Loading>}
      {(maxDataQuery?.isError || queryData?.isError) && <p>Error</p>}
      {maxDataQuery?.isSuccess && queryData?.isSuccess && (
        <>
          {/* yo */}
          {!widgetView &&
            !queryData?.isRefetching &&
            !maxDataQuery?.isRefetching && (
              <div className={styles.table}>
                <BasicTable
                  initheaders={headers}
                  data={queryData.data[queryDataAccessor]?.map((c) => {
                    return {
                      ...c,
                      firstName: c.contact.firstName,
                      lastName: c.contact.lastName,
                      email: c.contact.email,
                    };
                  })}
                  // onRowClick={onRowClick}
                  on
                  sort={sort}
                  setSort={setSort}
                  id="ajsdfpoij"
                  emptyMessage={emptyMessage}
                ></BasicTable>
              </div>
            )}

          {!queryData?.isRefetching && !maxDataQuery?.isRefetching && (
            <PaginationContainer
              style={{
                padding: "1em 1em",
                position: "relative",
                maxHeight: "fit-content",
              }}
            >
              <Pagination
                pageNum={currPage}
                setPageNum={setCurrPage}
                maxPage={
                  maxDataQuery.data[maxDataQueryAccessor] / perPage < 1
                    ? 1
                    : Math.ceil(
                        maxDataQuery.data[maxDataQueryAccessor] / perPage
                      )
                }
                length={queryData.data[queryDataAccessor].length}
                searchString={searchString}
                perPage={perPage}
                setPerPage={setPerPage}
                setPageSkip={setPageSkip}
              ></Pagination>
            </PaginationContainer>
          )}
        </>
      )}
    </div>
  );
}

export default InteractiveTable;
