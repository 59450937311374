import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import styles from "./ChoiceSettings.module.scss";
import { Label } from "components/layouts/Label/Label";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { useRemoveAllTagsFromQ } from "api/resources/organization/questiontag";

export function ChoiceSettings({ question, saveQuestion }) {
  const removeTags = useRemoveAllTagsFromQ();

  function changeLimit(val) {
    if (val && val < question.choiceQuestion.choices.length) {
      question.choiceQuestion.limit = val;
    } else {
      question.choiceQuestion.limit = null;
    }
    saveQuestion(question);
  }

  const handleMultiSelectToggle = (isMulti) => {
    question.choiceQuestion.isMultiSelect = isMulti;
    question.choiceQuestion.isRanking = false;
    saveQuestion(question);
  };

  const handleRankingToggle = (isRanking) => {
    // let tagLength = question.questionTag.length;
    // if (
    //   !tagLength ||
    //   window.confirm(
    //     `This question has ${tagLength} question tag${
    //       tagLength > 1 ? "s" : ""
    //     } attatched to it. Making this a ${
    //       isRanking ? "ranking" : "regular multiple choice"
    //     } question will cause ${
    //       tagLength > 1 ? "these" : "it"
    //     } to be removed. Still want to continue?`
    //   )
    // ) {

    question.choiceQuestion.isMultiSelect = false;
    question.choiceQuestion.isRanking = isRanking;
    question.questionTag = [];
    removeTags.mutate(
      { questionId: question.id },
      {
        onSuccess: (data) => {
          // console.log("tags removed ", data.removeAllTagsFromQ),
        },
      }
    );
    saveQuestion(question);
  };

  const handleOtherOptionToggle = (hasOther) => {
    question.choiceQuestion.hasOtherOption = hasOther;
    saveQuestion(question);
  };

  const handleRandomizeOptionsToggle = (isRandomized) => {
    question.choiceQuestion.isRandomized = isRandomized;
    saveQuestion(question);
  };

  return (
    <div className={styles.toggleOptions}>
      <div className={styles.label6} style={{ marginTop: ".5rem" }}>
        Choice Options
      </div>
      <div className={styles.toggleContainer}>
        {" "}
        <ToggleSwitch
          handleCheck={handleMultiSelectToggle}
          startChecked={question.choiceQuestion?.isMultiSelect}
        />
        <div className={styles.text_3}>Select Multiple</div>
      </div>

      {question.choiceQuestion?.isMultiSelect && (
        <div className={styles.numberSetting}>
          <NumberInput
            max={question.choiceQuestion.choices.length}
            startNumber={question.choiceQuestion?.limit}
            onSave={changeLimit}
            placeholder="none"
          />
          <div className={styles.text_3}>Choice Limit</div>
        </div>
      )}

      <div className={styles.toggleContainer}>
        {" "}
        <ToggleSwitch
          handleCheck={handleRankingToggle}
          startChecked={question.choiceQuestion?.isRanking}
        />
        <div className={styles.text_3}>Ranking</div>
      </div>

      {question.choiceQuestion?.isRanking && (
        <div className={styles.numberSetting}>
          {" "}
          <NumberInput
            max={question.choiceQuestion.choices.length}
            startNumber={question.choiceQuestion?.limit}
            onSave={changeLimit}
            placeholder="none"
          />
          <div className={styles.text_3}>Limit</div>
        </div>
      )}

      {question.type !== "Matrix" && (
        <>
          {" "}
          <div className={styles.toggleContainer}>
            {" "}
            <ToggleSwitch
              handleCheck={handleOtherOptionToggle}
              startChecked={question.choiceQuestion?.hasOtherOption}
            />
            <div className={styles.text_3}>
              Include &quot;Other&quot; Option
            </div>
          </div>
          {/* <div className={styles.toggleContainer}>
            {" "}
            <ToggleSwitch
              handleCheck={handleRandomizeOptionsToggle}
              startChecked={question.choiceQuestion?.isRandomized}
            />
           <div className={styles.text_3}>Randomize answers</div>
          </div> */}
        </>
      )}
    </div>
  );
}
