import { useState } from "react";
import styles from "./CalculationWriter.module.scss";
import { useUpdateCalculation } from "api/resources/organization/calculations";
import TextEditorPlain from "components/inputs/input_fields/TextEditorPlain/TextEditorPlain";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { Formula } from "../Formula/Formula";
import { Variables } from "./AccordianItems/Variables/Variables";
import { Operators } from "./AccordianItems/Operators/Operators";
import { Functions } from "./AccordianItems/Functions/Functions";
import { Examples } from "./AccordianItems/Examples/Examples";
import Button from "components/Button/Button";
import { Loading } from "components/Loading/Loading";
import { Calculator } from "./FrontEndCalculator/Calculator";
import { Settings } from "./AccordianItems/Settings";
import { Filters } from "./AccordianItems/Filters";
import { useFetchQTags } from "api/resources/organization/questiontag";
import Icon from "components/Icon/Icon";

export function CalculationWriter({ calculation, onClose }) {
  const [calc, setCalc] = useState(JSON.parse(JSON.stringify(calculation)));
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [setting, setSetting] = useState("");
  const [testing, setTesting] = useState(false);
  const [syntaxErr, setSyntaxErr] = useState("");
  const [filters, setFilters] = useState({});

  // console.log(filters);

  const [result, setResult] = useState("");

  const update = useUpdateCalculation();

  const fetchTags = useFetchQTags();

  function updateCalc(copy) {
    setCalc(copy);
    onSave(copy);
  }

  function onSave(copy) {
    update.mutate(
      {
        id: copy.id,
        data: {
          name: copy.name,
          formula: copy.formula,
          decimals: copy.decimals,
          type: copy.type,
          mostRecentItr: copy.mostRecentItr,
        },
      },
      {
        onSuccess: (data) => {
          setSaved(true);
          setSaving(false);
        },
        onError: (data) => {
          setSaving(false);
          setError(true);
        },
      }
    );
    setSaving(true);
  }

  async function runTest() {
    setResult("");
    setTesting(true);
  }

  function changeField(field, val) {
    let copy = { ...calc };
    copy[field] = val;
    updateCalc(copy);
  }

  function toggle(val) {
    setSetting(setting === val ? "" : val);
  }

  function onDone(val) {
    setResult(val);
    setTesting(false);
    if (syntaxErr) setSyntaxErr("");
  }

  function onError(msg) {
    setSyntaxErr(msg);
    setTesting(false);
  }

  function changeFilters(newFilters) {
    setFilters(newFilters);
  }

  const given = !calc.organizationId;

  return (
    <>
      {fetchTags.isLoading && <Loading />}
      {fetchTags.isSuccess && (
        <FlexCol className={styles.container} gap={"15px"}>
          <FlexRow justify={"space-between"} style={{ minHeight: "30px" }}>
            <Button onClick={onClose} shadow style={{ gap: ".5rem" }}>
              <Icon iconName={"arrow-left"}></Icon>Go Back
            </Button>

            <FlexRow fit>
              {(saving || saved) && (
                <FlexRow className={styles.saving} gap={"5px"}>
                  {saving ? (
                    <>
                      Saving <Loading height={30} width={30} />
                    </>
                  ) : (
                    <>
                      Saved <i className="bi-check2"></i>
                    </>
                  )}
                </FlexRow>
              )}

              <div className={styles.header_4} style={{ margin: "0" }}>
                Calculation
              </div>
            </FlexRow>
          </FlexRow>
          <FlexRow gap="0px" align={"flex-start"} className={styles.bulk}>
            <div className={styles.leftSide}>
              <Variables
                show={setting === "variables"}
                setShow={() => toggle("variables")}
                tags={fetchTags.data.tags}
              />

              <Operators
                show={setting === "operators"}
                setShow={() => toggle("operators")}
              />

              <Functions
                show={setting === "functions"}
                setShow={() => toggle("functions")}
              />

              <Examples
                show={setting === "examples"}
                setShow={() => toggle("examples")}
                isNPS={
                  calculation.name === "NPS" && !calculation.organizationId
                }
                tags={fetchTags.data.tags}
              />

              <Filters
                show={setting === "filters"}
                setShow={() => toggle("filters")}
                filters={filters}
                onChange={changeFilters}
              />

              <Settings
                show={setting === "settings"}
                setShow={() => toggle("settings")}
                decimals={calc.decimals}
                setDecimals={(val) => changeField("decimals", val)}
                given={given}
                restrict={calc.mostRecentItr}
                setRestrict={(val) => changeField("mostRecentItr", val)}
              />
            </div>
            <div className={styles.main}>
              <div className={styles.name}>
                <TextEditorPlain
                  placeholder={"Name"}
                  editable={!given}
                  text={calc.name}
                  onSave={(nm) => changeField("name", nm)}
                  style={{ paddingLeft: "10px" }}
                  placeholderStyle={{ paddingLeft: "10px" }}
                  onEnter={(e) => e.target.blur()}
                />
              </div>
              <Formula
                editable={!given}
                formula={calc.formula}
                onSave={(frm) => changeField("formula", frm)}
                tags={fetchTags.data.tags}
              ></Formula>
            </div>
          </FlexRow>

          <FlexRow justify={"flex-end"} gap={30}>
            {result && (
              <FlexRow className={styles.result} fit>
                <div>Result:</div>
                <div>{result}</div>
              </FlexRow>
            )}

            {syntaxErr && <div className={styles.error}>{syntaxErr}</div>}

            <Button
              blue
              shadow
              onClick={runTest}
              style={{
                gap: "10px",
              }}
            >
              {testing ? (
                <Calculator
                  calculation={calc}
                  contactIds={[]}
                  onDone={onDone}
                  onError={onError}
                  filters={filters}
                />
              ) : (
                <>
                  <i className="bi-calculator"></i>Test
                </>
              )}
            </Button>
          </FlexRow>
        </FlexCol>
      )}
    </>
  );
}
