/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2024 ReactionData. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson

*/
import React, { useState, useEffect } from "react";
import styles from "./ObjectTable.module.scss";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import Button from "components/Button/Button";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import Filters from "pages/contacts/all_contacts/Filters";
import Modal from "components/ReactModal/ReactModal";
import Icon from "components/Icon/Icon";
import Table from "../Table/Table";

function ObjectTable({
  headers,
  data,
  loading,
  tableTitle,
  tableDescription,
  pageNum,
  setPageNum,
  maxItems,
  searchString,
  setSearchString,
  onSearch,
  searchPlaceholder,
  perPage,
  setPerPage,
  emptyMessage,
  sort,
  setSort,
  onRowClick,
  newOptions,
  newOptionsHeight,
  addOptions,
  addOptionsHeight,
  dotOptions,
  dotOptionsHeight,
  settingOptions,
  settingOptionsHeight,
  columnOptions,
  columnOptionsHeight,
  select,
  selected,
  setSelected,
  filters,
  setFilters,
  exportClick,
  onCreateAudienceClick,
  onSaveEditableCell,
  fullLoading,
  onClickEditAction,
  onClickDeleteAction,
  actionCell,
  refetch,
  setDisplayColumns,
}) {
  const [showFilters, setShowFilters] = useState(false);

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableHeader}>
        {tableTitle && (
          <div className={styles.titleTop}>
            {tableTitle && (
              <div className={styles.tableTitle}>{tableTitle}</div>
            )}
            {tableDescription && (
              <div className={styles.tableDescription}>{tableDescription}</div>
            )}
          </div>
        )}

        <FlexRow style={{ flexWrap: "wrap", justifyContent: "space-between" }}>
          <div className={styles.headerInner}>
            {/* {select && (
                <Button
                  white
                  shadow
                  style={{
                    gap: ".75em",
                    fontWeight: "400",
                    color: "black",
                  }}
                  height={2.1}
                  padding={"0 .75rem"}
                  options={selectedOptions}
                  optionHeight={selectedOptionsHeight}
                  optionStyle={{ left: "0", right: "none", width: "180px" }}
                >
                  Selected {selected?.length}
                  <i
                    className="bi-chevron-down"
                    style={{ fontSize: "1.1rem" }}
                  ></i>
                </Button>
              )} */}
            <FlexRow start style={{ width: "fit-content" }}>
              <NavTextField
                value={searchString}
                setValue={setSearchString}
                placeholder={
                  searchPlaceholder ? searchPlaceholder : "Search contact..."
                }
                className={styles.search}
                shadow
                style={{ height: "1.9rem" }}
                // handleFocusOut={() => onSearch(search)}
                handlePressEnter={() =>
                  onSearch ? onSearch(searchString) : undefined
                }
              />

              <Button
                onClick={() => (refetch ? refetch() : undefined)}
                height={2.1}
                width={"2.1rem"}
                padding={"0 .75rem"}
                shadow
              >
                <i
                  className={"bi-arrow-clockwise"}
                  style={{ fontSize: "1rem" }}
                ></i>
              </Button>
              <div className={styles.text_2}>
                Showing {data?.length} of {maxItems} results
              </div>
            </FlexRow>
          </div>
          <div className={styles.headerInner}>
            {/* <Sorting sort={sort} setSort={setSort} fields={headers}></Sorting> */}

            <Button
              white
              shadow
              style={{
                gap: ".75em",
                fontWeight: "400",
                color: "black",
              }}
              height={2.1}
              padding={"0 .75rem"}
              onClick={() => setShowFilters(!showFilters)}
              active={showFilters}
            >
              <i className="bi-funnel" style={{ fontSize: ".9rem" }}></i>{" "}
              Filters ({filters?.length})
            </Button>

            {columnOptions && (
              <Button
                white
                shadow
                style={{
                  gap: ".75em",
                  fontWeight: "400",
                  height: "2.6rem",
                  color: "black",
                }}
                height={2.1}
                padding={"0 .75rem"}
                options={columnOptions}
                optionHeight={columnOptionsHeight}
              >
                <i
                  className="bi-layout-three-columns"
                  style={{ fontSize: ".9rem" }}
                ></i>{" "}
                Columns
              </Button>
            )}

            {exportClick && (
              <Button
                white
                shadow
                style={{
                  gap: ".75em",
                  fontWeight: "400",
                  color: "black",
                }}
                height={2.1}
                padding={"0 .75rem"}
                onClick={exportClick}
              >
                <i
                  className="bi-cloud-download"
                  style={{ fontSize: ".9rem" }}
                ></i>{" "}
                Export
              </Button>
            )}
            {settingOptions && (
              <Button
                white
                shadow
                style={{
                  gap: ".75em",
                  fontWeight: "400",
                  color: "black",
                }}
                height={2.1}
                padding={"0 .75rem"}
                options={settingOptions}
                optionHeight={settingOptionsHeight}
              >
                <i className="bi-gear" style={{ fontSize: ".9rem" }}></i>{" "}
                Settings
              </Button>
            )}
            {newOptions && (
              <Button
                // blue
                shadow
                style={{
                  gap: ".75em",
                  fontWeight: "400",
                }}
                height={2.1}
                padding={"0 .75rem"}
                options={newOptions}
                optionHeight={newOptionsHeight}
              >
                <i className="bi-plus" style={{ fontSize: ".9rem" }}></i> New
              </Button>
            )}
            <HorizontalBar
              height={"2.2rem"}
              width={"3px"}
              rotate
            ></HorizontalBar>
            {addOptions && (
              <Button
                blue
                shadow
                style={{
                  gap: ".75em",
                  fontWeight: "400",
                }}
                height={2.1}
                padding={"0 .75rem"}
                options={addOptions}
                optionHeight={addOptionsHeight}
              >
                <i className="bi-plus" style={{ fontSize: ".9rem" }}></i> Add
              </Button>
            )}

            {dotOptions && (
              <Button
                style={{
                  gap: ".75em",
                  fontWeight: "400",
                }}
                height={2.1}
                options={dotOptions}
                optionHeight={dotOptionsHeight}
              >
                <i
                  className="bi-three-dots-vertical"
                  style={{ fontSize: ".9rem" }}
                ></i>
              </Button>
            )}
          </div>
        </FlexRow>
      </div>
      <div className={styles.thetable}>
        {showFilters && (
          <Modal
            show={showFilters}
            dark
            modalStyle={{ width: "1000px", overflow: "visible" }}
            onClose={() => setShowFilters(false)}
          >
            <Filters
              filters={filters}
              setFilters={setFilters}
              allColumns={headers}
              setShow={setShowFilters}
              show={showFilters}
              onCreateAudienceClick={onCreateAudienceClick}
            />
            {/* <FilterManager></FilterManager> */}
          </Modal>
        )}

        {/* <div
          style={{
            display: first ? "none" : "",
            borderRight: isVisible ? "" : "none",
          }}
          className={`${styles.showFilters} ${
            isVisible ? styles.enter : styles.exit
          }`}
          onAnimationEnd={handleAnimationEnd}
        >
          {showFilters && (
            <FilterSimple
              filters={filters}
              setFilters={setFilters}
              allColumns={headers}
              setShow={setShowFilters}
              show={showFilters}
            ></FilterSimple>
          )}
        </div> */}
        {/* <BasicTable
          initheaders={headers}
          data={data}
          onRowClick={onRowClick}
          sort={sort}
          setSort={setSort}
          actionCell={actionCell}
          emptyMessage={emptyMessage}
          style={{
            margin: "0em",
            boxShadow: "none",
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            transition: ".4s all",
            padding: "0rem 1rem",
            // marginRight: showFilters ? "20rem" : "",
            // marginTop: showFilters ? "15rem" : "",
          }}
          onClickEditAction={onClickEditAction}
          onClickDeleteAction={onClickDeleteAction}
          tablepadding={"0rem 1rem 1rem 0rem"}
          editableFields={true}
          perPage={perPage}
          setPerPage={setPerPage}
          onSaveEditableCell={onSaveEditableCell}
          moveableColumns
          headerSettings
          scrollPage
          rowNumbers
          loading={loading}
          setPageNum={setPageNum}
          pageNum={pageNum}
          maxItems={maxItems}
          fullLoading={fullLoading}
          select={select}
          setSelected={setSelected}
          selected={selected}
          // draggable
        /> */}
        <Table
          rows={data}
          headers={headers}
          sort={sort}
          setSort={setSort}
          rowNumbers
          maxItems={maxItems}
          setPageNum={setPageNum}
          pageNum={pageNum}
          loadingMore={loading}
          onSave={onSaveEditableCell}
          showLoading={loading || maxItems > data?.length}
          selectable={false}
          selectedRows={selected}
          setSelectedRows={setSelected}
          actionable
          blockRefetch={fullLoading}
          setDisplayColumns={setDisplayColumns}
          actionalbleOptions={(row, index) => {
            return [
              {
                onClick: () => onClickEditAction(row),
                data: (
                  <>
                    <Icon blue iconName={"pencil"}></Icon>Edit/View
                  </>
                ),
              },
              {
                onClick: () => onClickDeleteAction(row),
                data: (
                  <>
                    <Icon red iconName={"trash"}></Icon>Delete
                  </>
                ),
              },
            ];
          }}
        />
      </div>
    </div>
  );
}

export default ObjectTable;

const DynamicPopup = ({ parentRef, isOpen, children }) => {
  const popupRef = useRef(null);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const adjustPopupPosition = () => {
      if (parentRef.current && popupRef.current) {
        const parentRect = parentRef.current.getBoundingClientRect();
        const popupRect = popupRef.current.getBoundingClientRect();
        const viewportWidth = window.innerWidth;

        // Calculate initial position below the parent element
        let top = parentRect.bottom + window.scrollY;
        let left = parentRect.left + window.scrollX;

        // Adjust if the popup goes beyond the right edge of the viewport
        if (left + popupRect.width > viewportWidth) {
          left = viewportWidth - popupRect.width - 10; // 10px padding from the edge
        }

        setPopupPosition({ top, left });
      }
    };

    if (isOpen) {
      adjustPopupPosition();
      window.addEventListener("resize", adjustPopupPosition);
      window.addEventListener("scroll", adjustPopupPosition);

      // Cleanup event listeners on unmount
      return () => {
        window.removeEventListener("resize", adjustPopupPosition);
        window.removeEventListener("scroll", adjustPopupPosition);
      };
    }
  }, [isOpen, parentRef]);

  if (!isOpen) return null;

  return (
    <div
      ref={popupRef}
      style={{
        position: "absolute",
        top: `${popupPosition.top}px`,
        left: `${popupPosition.left}px`,
        backgroundColor: "white",
        border: "1px solid #ccc",
        boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
        zIndex: 1000,
      }}
      className={styles.showFilters}
    >
      {children}
    </div>
  );
};
