// External
import React, { useEffect, useState } from "react";

// Internal
import { OrganizationRoutes } from "routes";
import styles from "./Organization.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import {
  useFetchOrganizationLinkedAccounts,
  useGetOrgLogo,
} from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Button from "components/Button/Button";
import { HeaderContainer } from "components/layouts/Header/HeaderContainer";

export const Organization = ({ userRoles, currentUser }) => {
  const { id } = useParams();
  const MyOrg = useFetchOrganizationLinkedAccounts(id);

  return (
    <>
      {MyOrg.isLoading && <Loading></Loading>}
      {MyOrg.isSuccess && (
        <OrgPageContainer
          userRoles={userRoles}
          currentUser={currentUser}
          id={id}
          organization={MyOrg.data.organization}
        ></OrgPageContainer>
      )}
    </>
  );
};

function OrgPageContainer({ userRoles, id, currentUser, organization }) {
  const menuItems = [
    {
      id: 0,
      name: "General",
      to: `/organization/${id}/org-details`,
      icon: "bi bi-card-text",
    },
    // {
    //   id: 1,
    //   name: "Product",
    //   to: `/organization/${id}/billing-product`,
    //   icon: "bi bi-cash",
    // },
    {
      id: 2,
      name: "Email Settings",
      to: `/organization/${id}/email-preferences`,
      icon: "bi bi-envelope",
    },
    {
      id: 4,
      name: "AI & NLP",
      to: `/organization/${id}/nlp`,
      icon: "bi bi-lightbulb",
    },
    {
      id: 5,
      name: "Images",
      to: `/organization/${id}/org-images`,
      icon: "bi bi-images",
    },

    {
      id: 7,
      name: "Calculations",
      to: `/organization/${id}/calculations`,
      icon: "bi bi-calculator",
    },
    {
      id: 6,
      name: "Connected Accounts",
      to: `/organization/${id}/contact-management`,
      icon: "bi bi-plug",
    },
  ];

  const [shownItems, setShownItems] = useState(menuItems);
  // const getOrgLogo = useGetOrgLogo();

  useEffect(() => {
    let newItems = [];
    for (let item of menuItems) {
      if (item.id === 0) {
        newItems.push(item);
      } else if (
        item.id === 1 &&
        (userRoles?.isAdmin || currentUser.isSuperAdmin)
      ) {
        newItems.push(item);
      } else if (
        item.id === 2 &&
        (userRoles?.isAdmin || currentUser.isSuperAdmin)
      ) {
        newItems.push(item);
      } else if (
        item.id === 4 &&
        (userRoles?.isAdmin || currentUser.isSuperAdmin)
      ) {
        newItems.push(item);
      } else if (
        item.id === 5 &&
        (userRoles?.isAdmin || currentUser.isSuperAdmin)
      ) {
        newItems.push(item);
      } else if (
        item.id === 6 &&
        (userRoles?.isAdmin || currentUser.isSuperAdmin)
      ) {
        newItems.push(item);
      } else if (item.id === 7) {
        newItems.push(item);
      }
    }

    if (organization?.linkedAccounts?.length > 0) {
      for (let i = 8; i < organization?.linkedAccounts?.length + 8; i++) {
        newItems.push({
          id: i,
          name: organization?.linkedAccounts[i - 8]?.accountType,
          to: `/organization/${id}/${
            organization?.linkedAccounts[i - 8]?.accountType
          }`,
          icon: (
            <img
              src={require("assets/images/Salesforce.png")}
              style={{ height: "20px" }}
            ></img>
          ),
        });
      }
    }
    setShownItems(newItems);
  }, []);

  const [active, setActive] = useState();

  const handleActiveUpdate = (item) => {
    setActive(item);
  };

  const navigate = useNavigate();

  return (
    <div className={styles.page}>
      <HeaderContainer path={"Organization Settings"} user={currentUser} roles={userRoles} organization={organization}>
        <FlexRow wrap="wrap">
          {shownItems?.map((item, i) => (
            <div key={i}>
              <Button
                active={active === item.id}
                onClick={() => {
                  navigate(item.to);
                  handleActiveUpdate(item.id);
                }}
              >
                {item.name}
              </Button>
            </div>
          ))}
        </FlexRow>
      </HeaderContainer>


      <OrganizationRoutes updateRoute={handleActiveUpdate} />
    </div>
  );
}
