import { useState, useEffect, useRef } from "react";
import styles from "../QChart/QuestionChart.module.scss";
import { useUpdateVisualization } from "api/resources/projects/visualizations";
import { Loading } from "components/Loading/Loading";
import { useFetchStatChartData } from "api/resources/projects/projects";
import { useFetchSurveyTags } from "api/resources/organization/surveytags";
import Chart from "components/Charts/Chart";
import { forEach } from "assets/functions/ArrayFunctions";
import { useFetchColorPaletteById } from "api/resources/organization/colorpalettes";
import {
  getColorsFromGradient,
  getColorsFromPalette,
  getComparisonColors,
} from "assets/functions/ColorFunctions";
import {
  AtoZ,
  High2Low,
  LineType,
  Low2High,
  NoSort,
  ReverseSort,
  ZtoA,
  defaultColors,
} from "../QChart/QuestionChart";
import BackendStatDataTable from "../Tables/StatDataTable/BackendStatDataTable";
import BackendStatDrillTable from "../Tables/StatDrillTable/BackendStatDrillTable";
import {
  getStatChartCompSurveyPullData,
  getStatChartSurveyPullData,
} from "./StatChart";
import { ErrorBanner } from "pages/error_page/ErrorPage";

export default function BackendStatChart({
  viz,
  setOutsideData,
  setUpOutsideDataCounter,
  setLabelInfo,
  thumbnail,
  custom_fields,
  update,
  height,
  reDraw,
  role,
  seeData,
  closeSeeData,
  filters,
  filterSubtitle,
  idAddOn,
  setVisibleItems,
  editing,
  visibleItems,
  active,
  setCurrViz,
  setShowDrill,
}) {
  const colorPalette = useFetchColorPaletteById(
    viz.designSettings.paletteId ? viz.designSettings.paletteId : ""
  );
  const getSurveyTags = useFetchSurveyTags({
    tagIds: viz.tagIdsArray,
  });

  return (
    <>
      {colorPalette.isError && (
        <ErrorBanner
          error={colorPalette.error}
          message="Error loading color palette"
        />
      )}
      {getSurveyTags.isError && (
        <ErrorBanner
          error={getSurveyTags.error}
          message="Error loading survey tags"
        />
      )}

      <div
        className={styles.chartWithTitle}
        id={viz.id + idAddOn}
        style={thumbnail ? { gap: "0" } : undefined}
      >
        {viz.designSettings.hasTitle && (
          <div
            className={styles.titleContainer}
            id={"title for " + viz.id + idAddOn}
            style={{
              minHeight: thumbnail ? "25px" : "",
              alignItems: viz.designSettings.titleAlignment,
              backgroundColor: viz.designSettings.titleBackgroundColor,
              borderRadius: viz.designSettings.titleBorderRadius,
              paddingTop: viz.designSettings.paddingTopTitle,
              paddingBottom: viz.designSettings.paddingBottomTitle,
              paddingLeft: viz.designSettings.paddingLeftTitle,
              paddingRight: viz.designSettings.paddingRightTitle,
            }}
          >
            <div
              className={styles.title}
              style={{
                color: viz.designSettings.titleColor,
                fontSize: viz.designSettings.valueTitleSize,
              }}
            >
              {viz.title}
            </div>
            {filterSubtitle && viz.designSettings.hasSubtitle && (
              <div className={styles.subtitle}>{filterSubtitle}</div>
            )}
          </div>
        )}

        {(getSurveyTags.isLoading || colorPalette.isLoading) && (
          <Loading height={height}></Loading>
        )}

        {colorPalette.isSuccess && getSurveyTags.isSuccess && (
          <StatChart
            viz={viz}
            setOutsideData={setOutsideData}
            setUpOutsideDataCounter={setUpOutsideDataCounter}
            setLabelInfo={setLabelInfo}
            custom_fields={custom_fields}
            thumbnail={thumbnail}
            update={update}
            height={height}
            reDraw={reDraw}
            role={role}
            seeData={seeData}
            closeSeeData={closeSeeData}
            idAddOn={idAddOn}
            filters={filters}
            setVisibleItems={setVisibleItems}
            editing={editing}
            visible={visibleItems}
            active={active}
            setCurrViz={setCurrViz}
            visibleItems={visibleItems}
            setShowDrill={setShowDrill}
            palette={colorPalette.data.palette}
            surveyTags={getSurveyTags.data.tags}
          />
        )}
      </div>
    </>
  );
}

function StatChart({
  viz,
  setOutsideData,
  setUpOutsideDataCounter,
  setLabelInfo,
  thumbnail,
  custom_fields,
  update,
  height,
  reDraw,
  role,
  seeData,
  closeSeeData,
  filters,
  idAddOn,
  setVisibleItems,
  editing,
  visibleItems,
  active,
  setCurrViz,
  setShowDrill,
  palette,
  surveyTags,
}) {
  const [data, setData] = useState(null);
  const [N, setN] = useState();

  const fetchStats = useFetchStatChartData();

  const onSuccess = (data) => {
    let chartData = data.chart.split
      ? compileSplitData(data.chart)
      : compileData(data.chart);

    if (viz.type === LineType) {
      getLineData(chartData);
    }

    setData(chartData);
    setN(data.chart.N);
  };

  function fetchChart() {
    fetchStats.mutate(
      {
        projData: getStatChartSurveyPullData(viz),
        designSettings: JSON.stringify(viz.designSettings),
        filters: filters,
        tagIds: {
          ids: viz.tagIdsArray,
        },
        pivot: viz.pivotString,
        compData: getStatChartCompSurveyPullData(viz),
      },
      {
        onSuccess: onSuccess,
        onError: (err) => {
          console.log(err);
          setData(null);
        },
      }
    );
  }

  const settingsRef = useRef();
  const pivotRef = useRef();
  const filtersRef = useRef();
  const projIdsRef = useRef();
  const compRef = useRef();
  const typeRef = useRef();

  useEffect(() => {
    if (!data) {
      fetchChart();
    } else if (!sameData()) {
      fetchChart();
      setData(null);
    } else if (filtersRef.current && filtersRef.current !== filters) {
      fetchChart();
    } else if (settingsRef.current && !sameSettings()) {
      fetchChart();
    } else if (pivotRef.current !== viz.pivotString) {
      fetchChart();
    } else if (
      fetchStats.isSuccess &&
      settingsRef.current &&
      (!sameFrontEndSettings() || typeRef.current !== viz.type)
    ) {
      recompile();
    }

    filtersRef.current = filters;
    settingsRef.current = JSON.parse(JSON.stringify(viz.designSettings));
    pivotRef.current = viz.pivotString;
    projIdsRef.current = [...viz.projectIdsArray];
    compRef.current = JSON.stringify(viz.comparison);
    typeRef.current = viz.type;
  }, [viz, filters]);

  function sameData() {
    if (
      projIdsRef.current &&
      projIdsRef.current.toString() !== viz.projectIdsArray.toString()
    )
      return false;

    if (settingsRef.current) {
      if (
        JSON.stringify(settingsRef.current.dynamics) !==
        JSON.stringify(viz.designSettings.dynamics)
      )
        return false;

      if (
        JSON.stringify(settingsRef.current.showBars) !==
        JSON.stringify(viz.designSettings.showBars)
      )
        return false;
    }

    if (compRef.current && compRef.current !== JSON.stringify(viz.comparison))
      return false;

    return true;
  }

  function sameSettings() {
    const list = [
      "split",
      "useComparison",
      "byPercent",
      "byPercentOfReceived",
      "showUndefined",
      "undefinedLabel",
      "includeOverall",
      "onlyLinks",
      "showNonParticipating",
      "answerType",
      "chosenLabel",
      "comparisonLabel",
      "hasDataLabels",
    ];

    for (let item of list) {
      if (settingsRef.current[item] !== viz.designSettings[item]) {
        // console.log("on " + item);
        return false;
      }
    }

    const nestedList = [{ first: "dataLabelValue", second: "sigFigs" }];

    for (let item of nestedList) {
      if (
        settingsRef.current[item.first][item.second] !==
        viz.designSettings[item.first][item.second]
      ) {
        // console.log("on " + item);
        return false;
      }
    }

    return true;
  }

  function sameFrontEndSettings() {
    const list = [
      "paletteId",
      "useTagColor",
      "sortData",
      "stacked",
      "useTagColor",
    ];

    for (let item of list) {
      if (settingsRef.current[item] !== viz.designSettings[item]) {
        // console.log("on " + item);
        return false;
      }
    }

    const objList = [
      "segLabelReplacements",
      "axisLabelReplacements",
      "colors",
      "gradient",
    ];

    for (let item of objList) {
      let old = settingsRef.current[item];
      let update = viz.designSettings[item];
      if (JSON.stringify(old) !== JSON.stringify(update)) {
        // console.log("on " + item);
        return false;
      }
    }

    return true;
  }

  function recompile() {
    // console.log("re-compilation");
    onSuccess(fetchStats.data);
  }

  useEffect(() => {
    if (active && data && fetchStats.isSuccess) {
      recompile();
    }
  }, [active]);

  useEffect(() => {
    if (setUpOutsideDataCounter) {
      if (setOutsideData && data) {
        setOutsideData({ ...data });
      }
    }
  }, [setUpOutsideDataCounter]);

  const [drill, setDrill] = useState();
  const [chartData, setChartData] = useState(false);

  const updateViz = useUpdateVisualization();

  function comparing() {
    return (
      viz.designSettings.useComparison &&
      (viz.comparison.projectIds.length || viz.comparison.dynamics.length)
    );
  }

  function sortData(labels, answerTallies, colors) {
    let labelTallyMap = [];
    forEach(labels, (label, ind) =>
      labelTallyMap.push({
        label: label,
        tally: answerTallies[ind],
        color: colors[ind],
        orig: ind,
        sorted: ind,
      })
    );

    if (
      viz.designSettings?.segLabelReplacements &&
      Object.keys(viz.designSettings.segLabelReplacements).length > 0
    ) {
      forEach(labelTallyMap, (l) => {
        if (l.orig in viz.designSettings?.segLabelReplacements) {
          l.label = viz.designSettings.segLabelReplacements[l.orig];
          l.origLabel = labels[l.orig]; // So you can know what it was originally
          labels[l.orig] = l.label;
        }
      });
    }

    if (viz.designSettings.sortData !== NoSort) {
      if (viz.designSettings.sortData === ReverseSort) {
        labelTallyMap.reverse();
      } else {
        labelTallyMap.sort((a, b) => {
          if (viz.designSettings.sortData === High2Low) {
            return b.tally - a.tally;
          }
          if (viz.designSettings.sortData === Low2High) {
            return a.tally - b.tally;
          }

          if (viz.designSettings.sortData === AtoZ) {
            if (typeof a.label === "number" && typeof b.label === "number") {
              return a.label - b.label;
            }
            if (typeof a.label === "string" && typeof b.label === "string") {
              if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return -1;
              }
              if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return 1;
              }
              return 0;
            }
            if (typeof a.label === "number") {
              // numbers before letters
              return -1;
            }
            return 1;
          }
          if (viz.designSettings.sortData === ZtoA) {
            if (typeof a.label === "number" && typeof b.label === "number") {
              return b.label - a.label;
            }
            if (typeof a.label === "string" && typeof b.label === "string") {
              if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return 1;
              }
              if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return -1;
              }
              return 0;
            }
            if (typeof a.label === "number") {
              // numbers before letters
              return -1;
            }
            return 1;
          }
        });
      }

      forEach(labelTallyMap, (choice, ind) => {
        labels[ind] = choice.label;
        answerTallies[ind] = choice.tally;
        if (viz.designSettings.colors && !viz.designSettings?.afterSort) {
          colors[ind] = choice.color;
        } else {
          choice.color = colors[ind];
        }

        choice.sorted = ind; // For Chart Settings
      });
    }

    if (setLabelInfo) {
      let labelInfo = {
        segLabels: labelTallyMap,
        axisLabels: labelTallyMap,
      };
      setLabelInfo(labelInfo);
    }
  }

  function getDefaultColors(length) {
    let colors = [];
    let c = 0;
    for (let i = 0; i < length; i++) {
      colors[i] = defaultColors[c];
      c < defaultColors.length - 1 ? c++ : (c = 0);
    }
    return colors;
  }

  function getColors(num, data) {
    let compSimple = false;
    let colors = [];
    if (viz.designSettings.colors) {
      let c = 0;
      let copy = viz.designSettings.colors;
      for (let i = 0; i < num; i++) {
        colors[i] = copy[c];
        c < copy.length - 1 ? c++ : (c = 0);
      }
    } else if (viz.designSettings.gradient) {
      let gradient = viz.designSettings.gradient;
      colors = getColorsFromGradient(gradient.anchors, gradient.blended, num);
    } else if (palette) {
      if (comparing() && !(num % 2)) {
        if (viz.pivotString || viz.designSettings.split) {
          let half = num / 2;
          let firstSet = getColorsFromPalette(palette, half);
          colors = getComparisonColors(firstSet);
        } else {
          compSimple = true;
          let actual = data.datasets[0].data.length;
          let origSet = getColorsFromPalette(palette, actual);
          colors = getComparisonColors(origSet);
        }
      } else {
        colors = getColorsFromPalette(palette, num);
      }
    } else {
      colors = getDefaultColors(num);
    }
    return [colors, compSimple];
  }

  function compileData(statData) {
    let labels = [...statData.axisLabels];
    let tallies = statData.datasets[0].data;

    let [colors] = getColors(labels.length);

    //Survey Tag Colors Display
    if (viz.pivotString === "survey tag" && viz.designSettings?.useTagColor) {
      for (let i = 0; i < labels.length; i++) {
        let tag = surveyTags.find((t) => t.label === labels[i]);
        if (tag?.displayColor) {
          colors[i] = tag.displayColor;
        }
      }
    }

    sortData(labels, tallies, colors);

    let data = {
      labels: labels,
      datasets: [
        {
          data: tallies,
          backgroundColor: colors,
          borderRadius: viz.designSettings.borderRadius
            ? viz.designSettings.borderRadius
            : 0,
          borderSkipped: viz.designSettings.borderSkipped,
        },
      ],
    };

    return data;
  }

  function sortSplitData(data) {
    let segLabels = [];
    forEach(data.datasets, (dataset, i) =>
      segLabels.push({
        label: dataset.label,
        orig: i,
        sorted: i,
        color: dataset.backgroundColor[0],
      })
    );

    // add origLabel
    for (let l of segLabels) {
      if (
        viz.designSettings?.segLabelReplacements &&
        l.orig in viz.designSettings?.segLabelReplacements
      ) {
        l.label = viz.designSettings.segLabelReplacements[l.orig];
        l.origLabel = data.datasets[l.orig].label; // So you can know what it was originally
        data.datasets[l.orig].label = l.label; // Actually change it
      }
    }

    let axisLabels = [];
    if (data.labels) {
      forEach(data.labels, (label, i) =>
        axisLabels.push({
          label: label,
          orig: i,
          sorted: i,
        })
      );

      for (let l of axisLabels) {
        if (
          viz.designSettings?.axisLabelReplacements &&
          l.orig in viz.designSettings?.axisLabelReplacements
        ) {
          l.label = viz.designSettings.axisLabelReplacements[l.orig];
          l.origLabel = data.labels[l.orig]; // So you can know what it was originally
          data.labels[l.orig] = l.label; // Actually change it
        }
      }
    }
    if (setLabelInfo) {
      let labelsInfo = {
        segLabels: segLabels,
        axisLabels: axisLabels,
      };
      setLabelInfo(labelsInfo);
    }

    let labelMap = {};
    for (let i = 0; i < data.labels.length; i++) {
      labelMap[data.labels[i]] = i;
    }

    if (viz.designSettings.sortData !== NoSort) {
      if (viz.designSettings.sortData === ReverseSort) {
        data.labels.reverse();
      } else {
        data.labels.sort((a, b) => {
          if (viz.designSettings.sortData === AtoZ) {
            if (typeof a === "number" && typeof b === "number") {
              return a - b;
            }
            if (typeof a === "string" && typeof b === "string") {
              if (a.toLowerCase() < b.toLowerCase()) {
                return -1;
              }
              if (a.toLowerCase() > b.toLowerCase()) {
                return 1;
              }
              return 0;
            }
            if (typeof a === "number") {
              // numbers before letters
              return -1;
            }
            return 1;
          }
          if (viz.designSettings.sortData === ZtoA) {
            if (typeof a === "number" && typeof b === "number") {
              return b - a;
            }
            if (typeof a === "string" && typeof b === "string") {
              if (a.toLowerCase() < b.toLowerCase()) {
                return 1;
              }
              if (a.toLowerCase() > b.toLowerCase()) {
                return -1;
              }
              return 0;
            }
            if (typeof a === "number") {
              // numbers before letters
              return -1;
            }
            return 1;
          }
          return 0;
        });
      }

      // axisLabels.forEach((one, i) => (one.sorted = i));

      let order = data.labels.map((label) => labelMap[label]);

      for (let set of data.datasets) {
        let copy = [];
        forEach(order, (num, ind) => {
          copy[ind] = set.data[num];
        });
        set.data = copy;
      }
      // data.labels = axisLabels.map(l => l.label);
    }
  }

  function bindColorsOnSplitData(data) {
    let [colors, compMany] = getColors(data.datasets.length, data);

    //Survey Tag Colors Display
    if (
      viz.designSettings.split === "survey tag" &&
      viz.designSettings?.useTagColor
    ) {
      for (let i = 0; i < data.datasets.length; i++) {
        let tag = surveyTags.find((t) => t.label === data.datasets[i].label);
        if (tag?.displayColor) {
          colors[i] = tag.displayColor;
        }
      }
    }

    if (compMany) {
      let normal = [];
      let comp = [];
      for (let i = 0; i < colors.length; i += 2) {
        normal.push(colors[i]);
        comp.push(colors[i + 1]);
      }
      data.datasets[0].backgroundColor = normal;
      data.datasets[1].backgroundColor = comp;
    } else {
      for (let i = 0; i < data.datasets.length; i++) {
        data.datasets[i].backgroundColor = [colors[i]];
        data.datasets[i].borderWidth = 0;
      }
    }
  }

  function compileSplitData(chartData) {
    let copy = []; // So the real thing doesn't get changed.
    for (let set of chartData.datasets) {
      copy.push({
        data: [...set.data],
        label: set.label,
      });
    }

    let data = {
      labels: [...chartData.axisLabels],
      datasets: copy,
      stacked: viz.designSettings.stacked,
    };

    bindColorsOnSplitData(data);
    sortSplitData(data);
    return data;
  }

  function getLineData(data) {
    let settings = viz.designSettings;
    //so updates to the settings gets recognized by the useEffect in Chart.jsx
    for (let i = 0; i < data.datasets.length; i++) {
      data.datasets[i].pointRadius = settings.pointRadius;
      data.datasets[i].borderWidth = settings.lineGraphWidth;
      data.datasets[i].pointBorderWidth = 0;
      data.datasets[i].borderRadius = settings.borderRadius
        ? settings.borderRadius
        : 0;
      data.datasets[i].borderSkipped = settings.borderSkipped;
    }

    if (data.datasets.length > 1 || data.datasets[0]?.label) {
      // split
      for (let i = 0; i < data.datasets.length; i++) {
        data.datasets[i].borderColor = data.datasets[i].backgroundColor[0];
      }
    } else {
      data.datasets[0].borderColor = settings.lineGraphColor;
      if (settings.hasUniformPointColor) {
        for (let i = 0; i < data.datasets[0].data.length; i++) {
          data.datasets[0].backgroundColor[i] = settings.uniformPointColor;
        }
      }
    }
  }

  function saveData(data) {
    updateViz.mutate({
      id: viz.id,
      data: { data: typeof data != "string" ? JSON.stringify(data) : data },
    });
  }

  function closeDrill() {
    setDrill(null);
    setShowDrill(false);
    setChartData(null);
  }

  function onSegClick(segment, dataset, dataIndex, datasetIndex) {
    if (editing) {
      setVisibleItems("ChartSegments");
      if (!active) {
        setCurrViz(viz);
      }
      return;
    }

    if (!role.canSeeContactInfo) {
      // is a new chart with no data
      return;
    }

    segment = fetchStats.data.chart.axisLabels[dataIndex];
    dataset = fetchStats.data.chart.datasets[datasetIndex].label;

    let setup = {
      segment: segment,
      dataset: dataset,
      dataIndex: dataIndex,
      datasetIndex: datasetIndex,
    };

    setDrill(setup);
    setShowDrill(true);
  }

  return (
    <>
      {fetchStats.isError && (
        <ErrorBanner
          error={fetchStats.error}
          message="Error fetching statistic data"
        />
      )}
      {!drill && !seeData && (
        <>
          {/* {!data && <div className={styles.noData}>No Data</div>} */}

          {data ? (
            <>
              <Chart
                data={data}
                onSegClick={onSegClick}
                viz={viz}
                thumbnail={thumbnail}
                idAddOn={idAddOn}
                reDraw={reDraw}
                update={update}
                saveData={saveData}
                setVisibleItems={setVisibleItems}
                editing={editing}
                visible={visibleItems}
                active={active}
                setCurrViz={setCurrViz}
                visibleItems={visibleItems}
              />
              {viz.designSettings?.showN && (
                <div
                  className={styles.answerCount}
                  style={thumbnail ? { fontSize: ".5em" } : undefined}
                >
                  {viz.designSettings?.NLabel
                    ? viz.designSettings?.NLabel
                    : "N"}{" "}
                  = {N}
                </div>
              )}
            </>
          ) : (
            <Loading />
          )}
        </>
      )}

      {drill && (
        <BackendStatDrillTable
          viz={viz}
          inEdit={editing}
          drill={drill}
          onClose={closeDrill}
          filters={filters}
          toggleSpreadsheet
        />
      )}
      {seeData && (
        <BackendStatDataTable
          viz={viz}
          filters={filters}
          custom_fields={custom_fields}
          // projects={projects}
          onClose={closeSeeData}
          chartData={chartData ? data : ""}
          setChartData={setChartData}
          toggleSpreadsheet
        />
      )}
    </>
  );
}
