import React, { useRef, useEffect, useState } from "react";
import styles from "./Table.module.scss";
import { Loading } from "components/Loading/Loading";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import Button from "components/Button/Button";
import DataTypeInput from "components/layouts/ObjectLayout/DataTypeInput";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import { trimDate } from "assets/functions/DateFunctions";
import { NewLoading } from "components/NewLoading/Loading";

const Table = ({
  rows,
  headers,
  sort,
  setSort,
  rowNumbers,
  pageNum,
  setPageNum,
  maxItems,
  selectable,
  showLoading,
  onSave, // new prop: called when Save is clicked
  actionable,
  actionalbleOptions,
  blockRefetch,
  selectedRows = [],
  setSelectedRows,
  setDisplayColumns,
}) => {
  const tableRef = useRef(null);
  const dropdownRef = useRef(null);
  const [heads, setHeads] = useState(() => headers.filter((h) => h?.enabled));
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [lastSelectedCell, setLastSelectedCell] = useState(null);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!blockRefetch) {
      setData(rows);
    }
  }, [rows]);

  useEffect(() => {
    if (!blockRefetch) {
      setHeads(() => headers.filter((h) => h?.enabled));
    }
  }, [headers]);

  // --- New state for row selection ---
  // selectedRows: array of row indices that are selected.
  // const [selectedRows, setSelectedRows] = useState([]);

  // --- Cell selection state (existing selection code) ---
  // selectedRange = { start: { row, col }, end: { row, col } }
  const [selectedRange, setSelectedRange] = useState(null);
  const [isSelecting, setIsSelecting] = useState(false);

  // --- New state for cell editing ---
  // editingCell: { row, col } of the currently edited cell (if any)
  const [editingCell, setEditingCell] = useState(null);
  // edits: { [rowIndex]: { [header.value]: newValue } }
  const [edits, setEdits] = useState({});

  // Existing resizing state
  const resizingState = useRef({
    resizingIndex: null,
    originalWidth: 0,
    originalX: 0,
  });

  // Toggle dropdown for header options
  const toggleDropdown = (index) => {
    setDropdownVisible(dropdownVisible === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      (!dropdownRef.current.contains(event.target) ||
        !tableRef.current.contains(event.target))
    ) {
      setDropdownVisible(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // --- Column resizing event handlers (existing code) ---
  const handleMouseDown = (index, event) => {
    event.stopPropagation();
    event.preventDefault();
    document.body.classList.add("dragging");
    resizingState.current = {
      resizingIndex: index,
      originalWidth: event.target.offsetParent.clientWidth,
      originalX: event.pageX,
    };
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const { resizingIndex, originalWidth, originalX } = resizingState.current;
    if (resizingIndex !== null) {
      const newWidthUnrestricted = originalWidth + (e.pageX - originalX);
      const maxWidth = 1000;
      const newWidth = Math.min(newWidthUnrestricted, maxWidth);
      let newHeaders = [...heads];
      newHeaders[resizingIndex] = {
        ...newHeaders[resizingIndex],
        width: newWidth,
      };
      setHeads(newHeaders);
    }
  };

  const handleMouseUp = () => {
    resizingState.current.resizingIndex = null;
    document.body.classList.remove("dragging");
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  // Create resizer elements for each header cell
  useEffect(() => {
    const table = tableRef.current;
    const cols = table.querySelectorAll("th");
    let skip = 0;
    cols.forEach((col, index) => {
      if (col?.id === "table-header") {
        const resizer = document.createElement("div");
        const inside = document.createElement("div");
        inside.className = styles.inside;
        resizer.className = styles.resizer;
        resizer.appendChild(inside);
        col.appendChild(resizer);
        resizer.addEventListener("mousedown", (e) =>
          handleMouseDown(index - skip, e)
        );
      } else {
        skip += 1;
      }
    });
    return () => {
      cols.forEach((col) => {
        const resizer = col.querySelector(`.${styles.resizer}`);
        if (resizer) {
          resizer.removeEventListener("mousedown", handleMouseDown);
        }
      });
    };
  }, [heads]);

  // --- Sorting (existing code) ---
  function handleSetSort(name, index, canSort) {
    if (canSort) {
      let newSort = { ...sort };
      newSort.item = name;
      if (name === sort.item) {
        newSort.descend = !sort.descend;
      }
      for (let i = 0; i < headers?.length; i++) {
        headers[i].sort = false;
      }
      headers[index].sort = true;
      setSort(newSort);
    }
  }

  function onScroll(e) {
    if (e.target.id === "table-container") {
      if (maxItems > data?.length) {
        const scrollableContainer = e.target;
        let distanceToBottom =
          scrollableContainer.scrollHeight -
          (scrollableContainer.scrollTop + scrollableContainer.clientHeight);
        if (distanceToBottom <= 1) {
          setPageNum(pageNum + 1);
        }
      }
    }
  }

  // --- Cell selection handlers (existing selection code) ---
  // const handleCellMouseDown = (row, col) => {
  //   setSelectedRange({ start: { row, col }, end: { row, col } });
  //   setIsSelecting(true);
  // };
  const handleCellMouseDown = (row, col, event) => {
    if (event.shiftKey && lastSelectedCell) {
      // If shift key is held and we have a last selected cell,
      // set the selection range from the last cell to the current cell.
      setSelectedRange({
        start: lastSelectedCell,
        end: { row, col },
      });
    } else {
      // Otherwise, start a new selection.
      setSelectedRange({ start: { row, col }, end: { row, col } });
      setLastSelectedCell({ row, col });
    }
    setIsSelecting(true);
  };

  const handleCellMouseEnter = (row, col) => {
    if (isSelecting) {
      setSelectedRange((prev) =>
        prev
          ? { ...prev, end: { row, col } }
          : { start: { row, col }, end: { row, col } }
      );
    }
  };

  const handleCellMouseUp = () => {
    setIsSelecting(false);
  };

  // Utility: Check if a cell at (row, col) is within the selected range.
  const isCellSelected = (row, col) => {
    if (!selectedRange) return false;
    const { start, end } = selectedRange;
    const minRow = Math.min(start.row, end.row);
    const maxRow = Math.max(start.row, end.row);
    const minCol = Math.min(start.col, end.col);
    const maxCol = Math.max(start.col, end.col);
    return row >= minRow && row <= maxRow && col >= minCol && col <= maxCol;
  };

  // Return which borders (top, bottom, left, right) a cell touches in the selected range.
  const getCellBorderSides = (row, col) => {
    if (!isCellSelected(row, col)) return {};
    const { start, end } = selectedRange;
    const minRow = Math.min(start.row, end.row);
    const maxRow = Math.max(start.row, end.row);
    const minCol = Math.min(start.col, end.col);
    const maxCol = Math.max(start.col, end.col);
    const sides = {};
    if (row === minRow) sides.top = true;
    if (row === maxRow) sides.bottom = true;
    if (col === minCol) sides.left = true;
    if (col === maxCol) sides.right = true;
    return sides;
  };

  // --- Copy selected cells to clipboard on Ctrl+C or Command+C ---
  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "c" && selectedRange) {
        e.preventDefault();
        const { start, end } = selectedRange;
        const minRow = Math.min(start.row, end.row);
        const maxRow = Math.max(start.row, end.row);
        const minCol = Math.min(start.col, end.col);
        const maxCol = Math.max(start.col, end.col);
        const rowsToCopy = [];
        for (let r = minRow; r <= maxRow; r++) {
          const rowData = data[r];
          let rowText = [];
          for (let c = minCol; c <= maxCol; c++) {
            const header = heads[c];
            rowText.push(rowData[header.value] || "");
          }
          rowsToCopy.push(rowText.join("\t"));
        }
        const copiedText = rowsToCopy.join("\n");
        navigator.clipboard.writeText(copiedText);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [selectedRange, data, heads]);

  // --- Save / Cancel editing handlers ---
  const handleSave = async () => {
    // Create newData from edits
    const newData = data.map((row, rowIndex) =>
      edits[rowIndex] ? { ...row, ...edits[rowIndex] } : row
    );
    setData(newData);
    // Wait for onSave to finish and return updated rows
    if (onSave) {
      try {
        await onSave(edits, newData);
      } catch (err) {
        console.error("Save failed:", err);
        // Optional: rollback or show error to user
      }
    }
    setEdits({});
    setEditingCell(null);
  };

  const handleCancel = () => {
    setEdits({});
    setEditingCell(null);
  };

  // --- Row selection handlers ---
  const handleSelectRow = (rowIndex) => {
    setSelectedRows((prev) =>
      prev.includes(rowIndex)
        ? prev.filter((i) => i !== rowIndex)
        : [...prev, rowIndex]
    );
  };

  const handleSelectAll = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.map((_, index) => index));
    }
  };

  const handleRowNumberClick = (rowIndex) => {
    // Assumes that the data cells correspond to the 'heads' array
    if (heads && heads.length > 0) {
      setSelectedRange({
        start: { row: rowIndex, col: 0 },
        end: { row: rowIndex, col: heads.length - 1 },
      });
    }
  };

  return (
    <>
      <div
        className={styles.tableContainer}
        onScroll={onScroll}
        id="table-container"
      >
        <table className={styles.table} ref={tableRef}>
          <thead className={styles.header}>
            <tr>
              {rowNumbers && (
                <th
                  style={{ width: data?.length > 9999 ? "50px" : "30px" }}
                  className={styles.staticHeader}
                ></th>
              )}

              {actionable && (
                <th
                  className={styles.checkboxHeader}
                  style={{
                    width: "50px",
                    borderRight: "none",
                  }}
                ></th>
              )}
              {selectable && (
                <th className={styles.checkboxHeader} style={{}}>
                  <Checkbox
                    checked={
                      data &&
                      data.length > 0 &&
                      selectedRows.length === data.length
                    }
                    onChange={handleSelectAll}
                  />
                </th>
              )}
              {heads?.length > 0 &&
                heads.map((header, ind) => (
                  <th
                    key={header?.name}
                    style={{ width: header?.width }}
                    id="table-header"
                  >
                    <div className={styles.thContent}>
                      <span
                        className={styles.label}
                        onClick={() =>
                          handleSetSort(header?.value, ind, header?.canSort)
                        }
                      >
                        {header?.label}{" "}
                        {header.canSort &&
                          (sort?.item === header?.value ? (
                            sort.descend ? (
                              <i
                                className={`bi bi-caret-down-fill ${styles.sort}`}
                              ></i>
                            ) : (
                              <i
                                className={`bi bi-caret-up-fill ${styles.sort}`}
                              ></i>
                            )
                          ) : null)}
                      </span>
                      <i
                        className={`bi-three-dots-vertical ${styles.dots}`}
                        onClick={() => toggleDropdown(ind)}
                      ></i>
                    </div>
                    {dropdownVisible === ind && (
                      <div className={styles.dropdown} ref={dropdownRef}>
                        <ul>
                          {!header?.canSort && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: ".5rem 1rem",
                                fontStyle: "italic",
                                fontWeight: "400",
                              }}
                            >
                              <i className={styles.icon}></i>
                              <span>Sorting not available</span>
                            </div>
                          )}
                          {header?.canSort && (
                            <>
                              {((sort?.item === header?.value &&
                                sort?.descend) ||
                                sort?.item !== header?.value) && (
                                <li
                                  onClick={() => {
                                    handleSetSort(
                                      header?.value,
                                      ind,
                                      header?.canSort
                                    );
                                    setDropdownVisible(null);
                                  }}
                                >
                                  <i className="bi-sort-alpha-up"></i> Sort
                                  Ascending
                                </li>
                              )}
                              {((sort?.item === header?.value &&
                                !sort?.descend) ||
                                sort?.item !== header?.value) && (
                                <li
                                  onClick={() => {
                                    handleSetSort(
                                      header?.value,
                                      ind,
                                      header?.canSort
                                    );
                                    setDropdownVisible(null);
                                  }}
                                >
                                  <i className="bi-sort-alpha-down-alt"></i>{" "}
                                  Sort Descending
                                </li>
                              )}
                            </>
                          )}
                          <HorizontalBar height={1} />
                          {setDisplayColumns && (
                            <li onClick={() => setDisplayColumns(true)}>
                              <i className="bi-columns"></i> Select columns to
                              display
                            </li>
                          )}
                        </ul>
                      </div>
                    )}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody className={styles.body}>
            {data?.length > 0 &&
              data?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {rowNumbers && (
                    <td
                      className={styles.rowNumbers}
                      style={{
                        width: data?.length > 999 ? "50px" : "30px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleRowNumberClick(rowIndex)}
                    >
                      {rowIndex + 1}
                    </td>
                  )}
                  {actionable && (
                    <td
                      className={styles.checkboxHeader}
                      style={{
                        width: "50px",
                        paddingRight: "0rem",
                        paddingLeft: ".75rem",
                        borderRight: "none",
                      }}
                    >
                      <Button
                        width={"27px"}
                        height={"25px"}
                        padding={"0"}
                        style={{ borderRadius: ".25rem" }}
                        options={
                          actionalbleOptions
                            ? actionalbleOptions(row, rowIndex)
                            : undefined
                        }
                        optionStyle={{
                          left: "0",
                          width: "fit-content",
                          fontSize: "1rem",
                          color: "black",
                          top: "1.8rem",
                          borderRadius: ".25rem",
                        }}
                      >
                        <i
                          className="bi-three-dots"
                          style={{ fontSize: "1.05rem" }}
                        ></i>
                      </Button>
                    </td>
                  )}
                  {selectable && (
                    <td
                      className={styles.checkboxHeader}
                      style={{
                        width: "45px",
                        padding: "0 .75rem",
                      }}
                    >
                      <Checkbox
                        checked={selectedRows.includes(rowIndex)}
                        onChange={() => handleSelectRow(rowIndex)}
                      />
                    </td>
                  )}

                  {heads.map((header, colIndex) => {
                    const cellChanged =
                      edits[rowIndex] &&
                      edits[rowIndex][header.value] !== undefined &&
                      edits[rowIndex][header.value] !== row[header.value];
                    return (
                      <td
                        key={colIndex}
                        style={{ width: header?.width }}
                        onMouseDown={(e) =>
                          handleCellMouseDown(rowIndex, colIndex, e)
                        }
                        onMouseEnter={() =>
                          handleCellMouseEnter(rowIndex, colIndex)
                        }
                        onMouseUp={handleCellMouseUp}
                        onDoubleClick={(e) => {
                          e.stopPropagation();
                          setEditingCell({ row: rowIndex, col: colIndex });
                        }}
                      >
                        {editingCell &&
                        editingCell.row === rowIndex &&
                        editingCell.col === colIndex ? (
                          <DataTypeInput
                            label={""}
                            value={
                              edits[rowIndex] &&
                              edits[rowIndex][header.value] != undefined
                                ? edits[rowIndex][header.value]
                                : row[header.value]
                            }
                            shadow
                            onChange={(value) => {
                              const newValue = value;
                              setEdits((prev) => ({
                                ...prev,
                                [rowIndex]: {
                                  ...prev[rowIndex],
                                  [header.value]: newValue,
                                  id: row?.id,
                                },
                              }));
                            }}
                            dataType={header?.dataType}
                            // icon={header?.icon}
                            min={header?.min}
                            max={header?.max}
                            falseValue={header?.falseValue}
                            trueValue={header?.trueValue}
                            disabled={!header?.editable}
                            id={header?.id}
                            required={header?.required}
                            multiple={header?.multiple}
                            unique={header?.unique}
                            generic={header?.generic}
                            // onValidate={setValid}
                            onBlur={() => setEditingCell(null)}
                            // validate={validate}
                            autoFocus
                          />
                        ) : (
                          <Cell
                            value={
                              cellChanged
                                ? edits[rowIndex]
                                  ? edits[rowIndex][header.value]
                                  : row[header.value]
                                : row[header.value]
                            }
                            index={colIndex}
                            header={header}
                            selected={isCellSelected(rowIndex, colIndex)}
                            className={`
                          ${
                            isCellSelected(rowIndex, colIndex)
                              ? styles.selectedCell
                              : ""
                          } 
                          ${
                            getCellBorderSides(rowIndex, colIndex).top
                              ? styles.borderTop
                              : ""
                          } 
                          ${
                            getCellBorderSides(rowIndex, colIndex).bottom
                              ? styles.borderBottom
                              : ""
                          } 
                          ${
                            getCellBorderSides(rowIndex, colIndex).left
                              ? styles.borderLeft
                              : ""
                          } 
                          ${
                            getCellBorderSides(rowIndex, colIndex).right
                              ? styles.borderRight
                              : ""
                          }
                          ${cellChanged ? styles.editedCell : ""}
                        `}
                          />
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            {showLoading && (
              <tr>
                <td
                  colSpan={heads.length + (rowNumbers ? 1 : 0)}
                  style={{ border: "none" }}
                >
                  <div style={{ display: "flex", justifyContent: "flex-start", width: "fit-content" }}>
                    {/* <Loading height={60} /> */}
                    <NewLoading height={30} width={30} />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* Save / Cancel buttons appear if there are edits */}
      {Object.keys(edits).length > 0 && (
        <div className={styles.editButtons}>
          <Button onClick={handleCancel} shadow width={100}>
            Cancel
          </Button>
          <Button onClick={handleSave} blue shadow width={140}>
            Save Changes
          </Button>
        </div>
      )}
    </>
  );
};

export default Table;

function Cell({ value, index, header, className, selected }) {
  function getBooleanValue(value) {
    if (header?.trueValue && value === true) {
      return header?.trueValue;
    } else if (header?.falseValue && !value) {
      return header?.falseValue;
    } else {
      if (value === true) {
        return "True";
      }
      if (value === false) {
        return "False";
      }
    }
    return value;
  }

  function getMultiValue(value) {
    let final = "";
    if (value?.length > 0) {
      let i = 0;
      for (let val of value) {
        if (val?.name) {
          final += val?.name;
        } else {
          final += val?.label;
        }
        if (i < value?.length - 1) {
          final += `${header?.delimiter} `;
        }

        i++;
      }
    }
    return final;
  }
  // Render content based on header data type.
  if (header?.dataType === "text") {
    return (
      <div className={`${styles.value} ${className}`}>
        {value ? String(value) : ""}
      </div>
    );
  } else if (header?.dataType === "list" && !header?.multiple) {
    return (
      <div className={`${styles.value} ${className}`}>
        {!value ? "" : typeof value === "string" ? value : value?.label}
      </div>
    );
  } else if (header?.dataType === "list" && header?.multiple) {
    return (
      <div className={`${styles.value} ${className}`}>
        {getMultiValue(value)}
      </div>
    );
  } else if (header?.dataType === "date/time") {
    return (
      <div className={`${styles.value} ${className}`}>
        {value ? trimDate(value, true) : ""}
      </div>
    );
  } else if (header?.dataType === "number") {
    return (
      <div className={`${styles.value} ${className}`}>
        {value ? String(value) : ""}
      </div>
    );
  } else if (header?.dataType === "date") {
    return (
      <div className={`${styles.value} ${className}`}>
        {value ? trimDate(value) : ""}
      </div>
    );
  } else if (header?.dataType === "email") {
    return (
      <div className={`${styles.value} ${className}`}>
        {value ? String(value) : ""}
      </div>
    );
  } else if (header?.dataType === "boolean") {
    return (
      <div className={`${styles.value} ${className}`}>
        {getBooleanValue(value)}
      </div>
    );
  }
  return (
    <div className={`${styles.value} ${className}`}>
      {value ? String(value) : ""}
    </div>
  );
}
