import React, { useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Pagination } from "components/tables/BasicTable/Pagination/Pagination";
import { PaginationContainer } from "components/tables/BasicTable/Pagination/PaginationContainer";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import { Loading } from "components/Loading/Loading";
import styles from "./PreviousImports.module.scss";
import { trimDate } from "assets/functions/DateFunctions";
import { useSearchUploadByOrg } from "api/resources/contacts/uploads";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import Icon from "components/Icon/Icon";
import Status from "components/Status/Status";
import Button from "components/Button/Button";
import FlexRow from "components/layouts/FlexRow/FlexRow";

export function PreviousImports({ updateRoute }) {
  useMemo(() => {
    if (updateRoute) {
      updateRoute(2);
    }
  }, []);
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [pageSkip, setPageSkip] = useState(0);
  const [active, setActive] = useState(0);
  const [refresher, setRefresher] = useState(false);

  const [sort, setSort] = useState({
    item: "uploadDate",
    descend: true,
  });

  const searchUploads = useSearchUploadByOrg(
    searchString,
    pageSkip,
    perPage,
    sort
  );

  useEffect(() => {
    searchUploads.refetch();
  }, [pageNumber, sort, perPage, active, refresher]);

  return (
    <div className={styles.page}>
      <div className={styles.search}>
        <NavTextField
          value={searchString}
          setValue={(val) => {
            setSearchString(val);
          }}
          placeholder="Search Uploads..."
          shadow
        />
        <PaginationContainer style={{ height: "20px" }}>
          <Pagination
            pageNum={pageNumber}
            setPageNum={setPageNumber}
            maxPage={
              searchUploads?.data?.response?.count / perPage < 1
                ? 1
                : Math.ceil(searchUploads?.data?.response?.count / perPage)
            }
            length={searchUploads?.data?.response?.uploads?.length}
            searchString={searchString}
            perPage={perPage}
            setPerPage={setPerPage}
            setPageSkip={setPageSkip}
          ></Pagination>
        </PaginationContainer>
      </div>

      <table className={styles.table}>
        <colgroup>
          <col style={{ width: "30px" }}></col>
          <col style={{ width: "300px" }}></col>
          <col style={{ width: "" }}></col>
          <col style={{ width: "300px" }}></col>
          <col style={{ width: "300px" }}></col>
          <col style={{ width: "30px" }}></col>
        </colgroup>
        <thead>
          <tr>
            <th> <div className={styles.headerContainer}></div></th>
            <th onClick={() => handleSort("name")}>
              <div className={styles.headerContainer}>
                Name/Date
                {sort.item === "name" &&
                  (sort.descend ? (
                    <i className="bi-caret-down-fill" />
                  ) : (
                    <i className="bi-caret-up-fill" />
                  ))}
              </div>
            </th>
            <th onClick={() => handleSort("name")}>
              <div className={styles.headerContainer}>
                Status
                {sort.item === "name" &&
                  (sort.descend ? (
                    <i className="bi-caret-down-fill" />
                  ) : (
                    <i className="bi-caret-up-fill" />
                  ))}
              </div>
            </th>
            <th onClick={() => handleSort("name")}>
              <div className={styles.headerContainer}>
                Results
                {sort.item === "name" &&
                  (sort.descend ? (
                    <i className="bi-caret-down-fill" />
                  ) : (
                    <i className="bi-caret-up-fill" />
                  ))}
              </div>
            </th>
            <th onClick={() => handleSort("name")}>
              <div className={styles.headerContainer}>
                Progress
                {sort.item === "name" &&
                  (sort.descend ? (
                    <i className="bi-caret-down-fill" />
                  ) : (
                    <i className="bi-caret-up-fill" />
                  ))}
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {searchUploads.isLoading && <Loading></Loading>}
          {searchUploads.isError && <p>{searchUploads.error}</p>}
          {searchUploads.isSuccess && (
            <>
              {searchUploads?.data?.response?.uploads?.length === 0 &&
                active === 0 && (
                  <>
                    <td></td>
                    <td>
                      <div className={styles.header}> No Current Jobs</div>
                    </td>
                  </>
                )}
              {searchUploads?.data?.response?.uploads.map((upload, i) => (
                <tr onClick={() => navigate(upload.id)}>
                  {" "}
                  {upload?.id && (
                    <UploadPreview
                      upload={upload}
                      key={i}
                      active={active}
                      setRefresher={setRefresher}
                      refresher={refresher}
                    ></UploadPreview>
                  )}
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>

      <PaginationContainer>
        <Pagination
          pageNum={pageNumber}
          setPageNum={setPageNumber}
          maxPage={
            searchUploads?.data?.response?.count / perPage < 1
              ? 1
              : Math.ceil(searchUploads?.data?.response?.count / perPage)
          }
          length={searchUploads?.data?.response?.uploads?.length}
          searchString={searchString}
          perPage={perPage}
          setPerPage={setPerPage}
          setPageSkip={setPageSkip}
        ></Pagination>
      </PaginationContainer>
    </div>
  );
}

export function UploadPreview({
  upload,
  active,
  setRefresher,
  refresher,
  audience,
}) {
  // let navigate = useNavigate();
  // const getUpload = useFetchContactImportMut();
  // function downloadFails() {
  //   getUpload.mutate(
  //     {
  //       id: upload?.id,
  //     },
  //     {
  //       onSuccess: (dara) => {
  //         let dataArray = data?.getUploadMut;
  //         let list = [];
  //         for (let row of dataArray) {
  //           let entry = {};
  //           for (let field of headers) {
  //             let cell = row[field.accessor];
  //             if (cell && typeof cell === "object") {
  //               // for matrix and ranking answers
  //               let string = "";
  //               for (let div of cell.props.children) {
  //                 if (div?.props?.children) {
  //                   if (string) {
  //                     string += "\n";
  //                   }
  //                   string += div.props.children;
  //                 }
  //               }
  //               cell = string;
  //             }

  //             if (field?.project) {
  //               let newentry = cell;
  //               newentry = `${newentry}`;
  //               newentry = newentry.replaceAll(`"`, ``);
  //               newentry = newentry.replaceAll(`'`, ``);
  //               entry[field?.project?.name + " - " + field.name] = newentry;
  //             } else if (field?.accessor === "answer") {
  //               let newentry = cell;
  //               newentry = `${newentry}`;
  //               newentry = newentry.replaceAll(`"`, ``);
  //               newentry = newentry.replaceAll(`'`, ``);
  //               entry[field.name] = newentry;
  //             } else {
  //               entry[field.name] = cell;
  //             }
  //           }
  //           list.push(entry);
  //         }
  //         return list;
  //       },
  //     }
  //   );
  // }

  return (
    <>
      <td>
        {" "}
        <GetType type={upload?.type} />
      </td>
      <td>
        <FlexCol>
          <h4>{upload?.name ? upload?.name : "File Upload"}</h4>
          <small>{trimDate(upload?.endDate, true)}</small>
        </FlexCol>
      </td>

      <td>
        {" "}
        <Status
          green={upload?.status === "Finished"}
          yellow={upload?.status === "In Progress"}
          red={upload?.status === "Canceled"}
          style={{ fontSize: ".9rem", width: "fit-content" }}
        >
          {upload?.status ? upload?.status : "Finished"}
        </Status>
      </td>
      <td>
        {upload?.status === "In Progress" && <small>Calculating...</small>}

        {upload?.status != "In Progress" && (
          <FlexCol>
            {upload?.num_updated > 0 && (
              <FlexRow fit gap=".5rem">
                <Icon
                  iconName={"check-circle"}
                  green
                  style={{ fontSize: ".9rem" }}
                ></Icon>
                <small>{upload?.num_updated} Updated</small>
              </FlexRow>
            )}
            {upload?.num_uploaded > 0 && (
              <FlexRow fit gap=".5rem">
                <Icon
                  iconName={"check-circle"}
                  green
                  style={{ fontSize: ".9rem" }}
                ></Icon>
                <small>{upload?.num_uploaded} Created</small>
              </FlexRow>
            )}
            {audience && upload?.num_added_to_audience > 0 && (
              <FlexRow fit gap=".5rem">
                <Icon
                  iconName={"check-circle"}
                  green
                  style={{ fontSize: ".9rem" }}
                ></Icon>
                <small>
                  {upload?.num_added_to_audience} Added to {audience?.name}
                </small>
              </FlexRow>
            )}
            {upload?.status === "Canceled" && (
              <FlexRow fit gap=".5rem">
                <Icon
                  iconName={"dash-circle"}
                  red
                  style={{ fontSize: ".9rem" }}
                ></Icon>
                <small
                  style={{
                    width: "100%",
                    maxWidth: "250px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {upload?.error ? upload?.error : "User canceled task"}
                </small>
              </FlexRow>
            )}
          </FlexCol>
        )}
      </td>
      <td>
        {upload?.status === "In Progress" && (
          <FlexCol gap=".5rem">
            <div className={styles.title}>Progress: {upload?.progress}%</div>
            <div className={styles.runningprocess}>
              <div className={styles.runningbar}></div>
            </div>
          </FlexCol>
        )}
        {upload?.status === "Canceled" && (
          <FlexCol gap=".5rem">
            <div className={styles.title}>{upload?.progress}%</div>
          </FlexCol>
        )}
        {upload?.status === "Finished" && (
          <FlexCol gap=".5rem">
            <div className={styles.title}>{upload?.progress}%</div>
          </FlexCol>
        )}
      </td>
      <td>
        {/* <Button>
          <Icon iconName={"three-dots"} style={{ fontSize: "1.2rem" }}></Icon>
        </Button> */}
      </td>
      {/* <FlexRow justify={"space-between"}>
        <FlexRow fit>
          <FlexCol>
            <h4>
              File Upload{" "}
              {upload?.name ? upload?.name : `${trimDate(upload?.endDate)}`}
            </h4>
            <FlexRow gap=".5rem">
              <div className={styles.title}>Progress:</div>{" "}
              <span>{upload?.progress}%</span>
            </FlexRow>
          </FlexCol>
          <Status
            yellow={upload?.status === "In Progress"}
            red={upload?.status === "Canceled"}
            green
            style={{ fontSize: ".9rem", marginLeft: "1rem" }}
          >
            {upload?.status ? upload?.status : "Finished"}
          </Status>

          <FlexRow className={styles.updates}>
            {audience && <span>{upload?.num_added_to_audience}</span>}
            <span>
              <Button
                tooltip="Created"
                style={{ width: "fit-content", padding: "0", margin: "0" }}
                width={"5px"}
              >
                <Icon blue iconName={"person"} />
              </Button>
              {upload?.num_uploaded}
            </span>
            <span> {upload?.num_updated}</span>

            <span>{upload?.num_duplicates}</span>
            <span>{upload?.num_failed}</span>
          </FlexRow>
        </FlexRow>
        {upload?.progress != 100 && (
          <div className={styles.icon} onClick={() => setRefresher(!refresher)}>
            <i className="bi-arrow-counterclockwise"></i>
            Refresh
          </div>
        )}
      </FlexRow> */}
      {/* <div className={styles.header}>
        <span>
          {upload?.type === "Salesforce"
            ? "Salesforce Import Details"
            : "File Import Details"}
        </span>{" "}
        {active === 0 && (
          <div className={styles.icon} onClick={() => setRefresher(!refresher)}>
            <i className="bi-arrow-counterclockwise"></i>
            Refresh
          </div>
        )}
        {active === 1 && (
          <div className={styles.icon} onClick={() => navigate(upload.id)}>
            <i className="bi-eye"></i>
            View
          </div>
        )}
      </div>
      <div className={styles.uploadContent}>
        <div className={styles.column}>
          <div className={styles.item}>
            <div className={styles.title}>Job Creator:</div>{" "}
            <a>{`${upload?.appUser?.firstName} ${upload?.appUser?.lastName}`}</a>
          </div>
          <div className={styles.item}>
            <div className={styles.title}>Start Time:</div>{" "}
            <span>{trimDate(upload?.uploadDate, true)}</span>
          </div>
          <div className={styles.item}>
            <div className={styles.title}>End Time:</div>{" "}
            {upload?.progress === 100 && (
              <span>{trimDate(upload?.endDate, true)}</span>
            )}
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.item}>
            <div className={styles.title}>Progress:</div>{" "}
            <span>{upload?.progress}%</span>
          </div>
          <div className={styles.item}>
            <div className={styles.title}>Created:</div>{" "}
            <span>{upload?.num_uploaded}</span>{" "}
            <Label
              labelIcon={<i className="bi-question-circle"></i>}
              tooltipText={
                "All the contacts that were imported into Reaction that did not exist before"
              }
              style={{
                width: "17px",
                padding: "0em",
                margin: "0em",
              }}
              tooltipStyle={{
                padding: "2em",
                width: "300px",
                right: "0px",
              }}
            ></Label>
            <i
            // className={`bi-cloud-arrow-down ${styles.download}`}
            ></i>
          </div>
          <div className={styles.item}>
            <div className={styles.title}>Updated:</div>{" "}
            <span>{upload?.num_updated}</span>{" "}
            <Label
              labelIcon={<i className="bi-question-circle"></i>}
              tooltipText={
                "All the contacts that existed in Reaction and were update in some way"
              }
              style={{
                width: "17px",
                padding: "0em",
                margin: "0em",
              }}
              tooltipStyle={{
                padding: "2em",
                width: "300px",
                right: "0px",
              }}
            ></Label>
            <i
            // className={`bi-cloud-arrow-down ${styles.download}`}
            ></i>
          </div>
        </div>
        <div className={styles.column}>
          {audience && (
            <div className={styles.item}>
              <div className={styles.title}>Added:</div>{" "}
              <span>{upload?.num_added_to_audience}</span>{" "}
              <Label
                labelIcon={<i className="bi-question-circle"></i>}
                tooltipText={"Contacts that were added to the audience."}
                style={{
                  width: "17px",
                  padding: "0em",
                  margin: "0em",
                }}
                tooltipStyle={{
                  padding: "2em",
                  width: "300px",
                  right: "0px",
                }}
              ></Label>
              <i
              // className={`bi-cloud-arrow-down ${styles.download}`}
              ></i>
            </div>
          )}
          <div className={styles.item}>
            <div className={styles.title}>Duplicates:</div>{" "}
            <span>{upload?.num_duplicates}</span>{" "}
            <Label
              labelIcon={<i className="bi-question-circle"></i>}
              tooltipText={
                "Contacts that, on import, had more than one contact with the same email."
              }
              style={{
                width: "17px",
                padding: "0em",
                margin: "0em",
              }}
              tooltipStyle={{
                padding: "2em",
                width: "300px",
                right: "0px",
              }}
            ></Label>
            <i className={`bi-cloud-arrow-down ${styles.download}`}></i>
          </div>

          <div className={styles.item}>
            <div className={styles.title}>Failed:</div>{" "}
            <span>{upload?.num_failed}</span>{" "}
            <Label
              labelIcon={<i className="bi-question-circle"></i>}
              tooltipText={
                "All Contacts that had issues or were flagged on import."
              }
              style={{
                width: "17px",
                padding: "0em",
                margin: "0em",
              }}
              tooltipStyle={{
                padding: "2em",
                width: "300px",
                right: "0px",
              }}
            ></Label>
            <i className={`bi-cloud-arrow-down ${styles.download}`}></i>
          </div>
        </div>
      </div> */}
    </>
  );
}

function GetType({ type }) {
  if (type === "Salesforce") {
    return (
      <img src={require("assets/images/Salesforce.png")} height="25px"></img>
    );
  }
  if (type === "csv" || type === "xlsx") {
    return (
      <Icon iconName={`filetype-${type}`} style={{ fontSize: "2rem" }}></Icon>
    );
  }
  return <Icon iconName={`file-earmark`} style={{ fontSize: "2rem" }}></Icon>;
}
