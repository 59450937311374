import React, { useState, useEffect, useRef } from "react";
import styles from "./Filters.module.scss";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import Button from "components/Button/Button";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import {
  booleanOperators,
  fileOperators,
  numberOperators,
  operators,
} from "assets/functions/Variables";
import { useFetchCustomField } from "api/resources/organization/organization";
import DataInput from "components/layouts/ObjectLayout/DataInput";
import { Header } from "components/layouts";

function Filters({
  filters,
  setFilters,
  allColumns,
  title,
  setShow,
  left,
  show,
  onCreateAudienceClick,
}) {
  const [chosenFilters, setChosenFilters] = useState(filters ? filters : []);
  const [changed, setChanged] = useState(false);

  function changeFilter(filter, index) {
    let temp = [...chosenFilters];
    temp[index] = filter;
    setChosenFilters(temp);
  }

  function applyChanges() {
    setFilters([...chosenFilters]);
    setShow(false);
  }

  function removeAll() {
    setChosenFilters([]);
  }

  function addFilter() {
    let temp = [...chosenFilters];

    temp.push({
      id: "",
      label: "",
      value: "",
      operator: 0,
      values: [],
      new: true,
      generic: false,
      dataType: "text",
    });

    setChosenFilters(temp);
  }

  function removeFilter(index) {
    let temp = [...chosenFilters];
    temp.splice(index, 1);
    setChosenFilters(temp);
  }

  return (
    <>
      <div className={styles.page}>
        <Header
          style={{
            borderTopLeftRadius: ".5rem",
            borderTopRightRadius: ".5rem",
          }}
        >
          {" "}
          <h3>Apply Filters</h3>
        </Header>
        <div className={styles.content}>
          {chosenFilters && chosenFilters?.length > 0 && (
            <table className={`${styles.table}`}>
              <colgroup>
                {/* <col
                  style={{ width: "12px", maxWidth: "12px", minWidth: "12px" }}
                ></col> */}
                <col
                  style={{ width: "75px", maxWidth: "75px", minWidth: "75px" }}
                ></col>
                <col
                  style={{ width: "50px", maxWidth: "50px", minWidth: "50px" }}
                ></col>
                <col
                  style={{ width: "75px", maxWidth: "75px", minWidth: "75px" }}
                ></col>
                <col
                  style={{ width: "27px", maxWidth: "27px", minWidth: "27px" }}
                ></col>
              </colgroup>
              <thead>
                {/* <th></th> */}
                <th>Column</th>
                <th>Operator</th>
                <th>Value</th>
                <th></th>
              </thead>
              <tbody>
                {!changed &&
                  chosenFilters &&
                  chosenFilters?.map((f, index) => (
                    <AFilter
                      key={index}
                      filter={f}
                      changeFilter={(filter) => changeFilter(filter, index)}
                      removeFilter={() => removeFilter(index)}
                      allColumns={allColumns}
                      applyChanges={applyChanges}
                      setChanged={setChanged}
                      left={left}
                    ></AFilter>
                  ))}
              </tbody>
            </table>
          )}
          {chosenFilters?.length === 0 && (
            <div
              className={`${styles.filterSimple} ${styles.label5}`}
              style={{ paddingLeft: ".5rem" }}
            >
              No filters added
            </div>
          )}
        </div>
        <Header
          style={{
            borderBottomLeftRadius: ".5rem",
            borderBottomRightRadius: ".5rem",
            borderTop: "1px solid #e0e0e0",
            borderBottom: "none",
          }}
        >
          {" "}
          <FlexRow justify={"space-between"}>
            <FlexRow fit>
              <Button
                shadow
                style={{ gap: ".5rem", fontSize: ".7rem" }}
                onClick={() => addFilter()}
                height={2}
              >
                <Icon green iconName={"plus-circle"}></Icon> Add Filter
              </Button>
              <Button
                shadow
                style={{ gap: ".5rem", fontSize: ".7rem" }}
                height={2}
                onClick={() => removeAll()}
              >
                <Icon red iconName={"dash-circle"}></Icon> Remove All
              </Button>
            </FlexRow>

            <FlexRow fit>
              {/* {filters?.length > 0 && (
                <Button
                  shadow
                  style={{
                    gap: ".75em",
                    fontWeight: "400",
                    color: !onCreateAudienceClick ? "#616565" : "",
                  }}
                  height={2}
                  gray={!onCreateAudienceClick}
                  onClick={onCreateAudienceClick}
                >
                  <Icon sapphire iconName={"person-plus"}></Icon> Create
                  Audience from view
                </Button>
              )} */}
              <Button
                shadow
                style={{ gap: ".5rem", fontSize: ".7rem" }}
                height={2}
                width={100}
                blue
                onClick={applyChanges}
              >
                Apply
              </Button>{" "}
            </FlexRow>
          </FlexRow>
        </Header>
      </div>
    </>
  );
}

export default Filters;

function AFilter({
  filter,
  changeFilter,
  removeFilter,
  allColumns,
  applyChanges,
  setChanged,
  left,
}) {
  const column =
    allColumns && allColumns?.length > 0
      ? allColumns.find((c) => c.id === filter.id)
      : {};
  const [done, setDone] = useState(false);

  const fetchField = useFetchCustomField();
  const [theField, setTheField] = useState();
  function pullField() {
    if (column && column?.id) {
      fetchField.mutate(
        {
          id: column ? column?.id : "",
        },
        {
          onSuccess: (data) => {
            setTheField(data?.field?.attribute);
          },
        }
      );
    }
  }

  useEffect(() => {
    pullField();
  }, [column]);

  useEffect(() => {
    if (done) {
      setDone(false);
    }
  }, [done]);

  function handleChangeProp(props, index) {
    let temp = { ...filter };
    temp.values = props;
    changeFilter(temp);
  }

  function handleChangeOperator(op) {
    let temp = { ...filter };
    temp.operator = op?.value;
    changeFilter(temp);
  }

  function handleChangeLabel(op) {
    let temp = { ...filter };
    temp.label = op.label;
    temp.value = op.value;
    temp.id = op.id;
    temp.generic = op.generic;
    temp.dataType = op.dataType;
    temp.values = [];
    temp.operator = 0;
    changeFilter(temp);
  }

  function handleRemove(e) {
    if (e) {
      e.stopPropagation();
    }
    removeFilter();
  }

  function getOperators(dataType) {
    if (dataType === "boolean") {
      return booleanOperators;
    }
    if (dataType === "file") {
      return fileOperators;
    }
    if (dataType === "number") {
      return numberOperators;
    }
    return operators;
  }

  return (
    <tr>
      <td>
        <SelectFieldCustom
          className={"smallSelect"}
          onChange={handleChangeLabel}
          value={
            filter?.value
              ? {
                  value: filter?.value,
                  label: filter?.label,
                  dataType: filter?.dataType,
                }
              : undefined
          }
          options={allColumns}
          noCreate
          formatOptionLabel={(data) => (
            <FlexRow>
              {data.label} ({data.dataType})
            </FlexRow>
          )}
        ></SelectFieldCustom>
      </td>
      <td>
        <SelectFieldCustom
          className={"smallSelect"}
          onChange={handleChangeOperator}
          value={operators.find((op) => op.value === filter?.operator)}
          options={getOperators(filter?.dataType)}
        ></SelectFieldCustom>
      </td>
      <td>
        {filter?.operator != 8 && filter?.operator != 9 && (
          <ChangeValue
            handleChangeProp={handleChangeProp}
            options={
              theField
                ? theField.map((a) => {
                    return { value: a?.id, label: a?.name };
                  })
                : []
            }
            values={filter?.values}
            column={column}
          />
        )}
        {(filter?.operator === 8 || filter?.operator === 9) && (
          <i
            className={styles.gray}
            style={{ fontSize: ".9rem", paddingLeft: ".5rem" }}
          >
            No value needed.
          </i>
        )}
      </td>
      <td style={{ width: "100%" }}>
        <Button
          shadow
          style={{ gap: ".5rem", fontSize: ".7rem" }}
          onClick={(e) => {
            e.stopPropagation();
            handleRemove();
          }}
          height={2}
        >
          <Icon red iconName={"dash-circle"}></Icon> Remove
        </Button>
      </td>
    </tr>
  );
}

function ChangeValue({ column, options, values, handleChangeProp }) {
  const [ops, setops] = useState(options);
  function getOptions(column, options) {
    if (column?.dataType === "boolean") {
      return [
        { id: "true", value: "true", label: column?.trueValue },
        { id: "false", value: "false", label: column?.falseValue },
      ];
    }
    return options;
  }

  useEffect(() => {
    setops(getOptions(column, options));
  }, [column, options]);

  return (
    <CheckPickType
      column={column}
      handleChangeProp={handleChangeProp}
      values={values}
      options={ops}
    />
  );
}

function CheckPickType({ column, options, values, handleChangeProp }) {
  return (
    <div>
      <SelectFieldCustom
        // label="Value"
        onChange={handleChangeProp}
        options={options}
        values={values}
        noCreate
        selectMultiple
        placeholder={"Add value..."}
        className={"smallSelect"}
        create
      ></SelectFieldCustom>
    </div>
  );
}
