// External
//eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Internal
import { ProjectRoutes } from "routes";
import styles from "./Project.module.scss";
import { Loading } from "components/Loading/Loading";
import { useFetchProjectGql } from "api/resources/projects/projects";
import { ErrorBanner } from "pages";
import { NavBar } from "components/sidebars/NavBar/NavBar";
import SurveySettings from "./survey_details/SurveySettings";
import { AnimatePresence } from "framer-motion";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { HeaderContainer } from "components/layouts/Header/HeaderContainer";
import Button from "components/Button/Button";

export const Project = ({
  resultsOnly,
  roles,
  organization,
  user,
  setBeforeNavAway,
  collapse,
  collapsed,
  onExportDeliveries,
}) => {
  const { id } = useParams();
  const fetchProjectQuery = useFetchProjectGql(id);

  const [showSettings, setShowSettings] = useState(false);
  const [beforeNavAwayInProj, setBeforeNavAwayInProj] = useState();

  function checkBuildRole() {
    if (fetchProjectQuery.data.survey?.organizationId === organization.id) {
      return roles.canCreateEditOrgSurveys;
    } else {
      return true;
    }
  }

  function checkResultsRole() {
    if (fetchProjectQuery.data.survey?.organizationId === organization.id) {
      return roles.canViewOrgSurveys;
    } else {
      return true;
    }
  }

  function checkSendRole() {
    if (fetchProjectQuery.data.survey?.organizationId === organization.id) {
      return roles.canCreateEditOrgSurveys;
    } else {
      return true;
    }
  }

  function getItems(orgId) {
    return [
      {
        id: 0,
        name: "Details",
        to: `details`,
        icon: "bi bi-list-task",
        iconClassName: "bi bi-list-task",
      },
      {
        id: 1,
        name: checkBuildRole(orgId) ? "Build" : "Preview Survey",
        to: checkBuildRole(orgId)
          ? `/surveys/${id}/build`
          : `/surveys/${id}/preview`,
        icon: checkBuildRole(orgId) ? "bi bi-hammer" : "",
        iconClassName: checkBuildRole(orgId) ? "bi bi-hammer" : "",
        noChange: !checkBuildRole(orgId),
      },
      checkSendRole(orgId)
        ? {
            id: 2,
            name: "Send",
            to: fetchProjectQuery.data.survey?.activeLink
              ? `/surveys/${id}/send/survey-link`
              : `/surveys/${id}/send/messages`,
            icon: "bi bi-mailbox",
            iconClassName: "bi bi-mailbox",
          }
        : "",
      checkResultsRole(orgId)
        ? {
            id: 3,
            name: "Results",
            to: `/surveys/${id}/results/${fetchProjectQuery.data.survey?.rawReportId}`,
            icon: "bi bi-bar-chart-fill",
            iconClassName: "bi bi-bar-chart-fill",
          }
        : "",
    ];
  }

  const [active, setActive] = useState();
  const [[page, direction], setPage] = useState([0, 0]);

  const paginate = (item, newDirection) => {
    setPage([page + newDirection, newDirection]);
    setActive(item);
  };
  const handleActiveUpdate = (item) => {
    // console.log(item);
    // if (!fetchProjectQuery.data.survey?.activeLink) {
    //   localStorage.setItem("activedelivertab", 1);
    // } else {
    //   localStorage.removeItem("activedelivertab");
    // }
    // localStorage.removeItem("activesurveybuildtab");
    // localStorage.setItem("activeprojecttab", item);
    let newDirection = item < active ? -1 : 1;
    paginate(item, newDirection);

    // localStorage.setItem("activeprojecttab", );
    if (collapsed) collapse(false);
  };

  const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  let [width, setWidth] = useState(getWidth());

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      let width = getWidth();
      setWidth(width);
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  let navigate = useNavigate();

  useEffect(() => {
    if (resultsOnly) {
      localStorage.setItem("activeprojecttab", 3);
      setActive(3);
      navigate("surveyresults");
    }
  }, []);

  function setUpBothNavAways(obj) {
    setBeforeNavAway(obj);
    setBeforeNavAwayInProj(obj);
  }

  return (
    <>
      {fetchProjectQuery.isLoading && <Loading />}
      {fetchProjectQuery.isError && (
        <ErrorBanner
          error={fetchProjectQuery.error}
          message="Error fetching survey"
        />
      )}
      {fetchProjectQuery.isSuccess && (
        <div className={styles.page}>
          <HeaderContainer
            path={"Surveys/" + fetchProjectQuery.data.survey?.name}
            user={user}
            onNavigate={(path) => navigate("/Surveys")}
          >
            <FlexRow className={styles.settings} justify={"space-between"}>
              <NavBar
                menuItems={getItems()}
                active={active}
                updateActive={handleActiveUpdate}
                beforeNavAway={beforeNavAwayInProj}
                setBeforeNavAway={setUpBothNavAways}
                first
              />

              <div className={styles.left}>
                {checkBuildRole() && (
                  <Button
                    style={{ gap: "7px" }}
                    onClick={() => setShowSettings(true)}
                  >
                    <i className="bi bi-gear"></i>
                    Settings
                  </Button>
                )}
              </div>
            </FlexRow>
          </HeaderContainer>

          <AnimatePresence custom={{ direction, active }}>
            <ProjectRoutes
              reportid={fetchProjectQuery.data.survey?.resultAnalysisId}
              responseCount={fetchProjectQuery.data.survey?.responseCount}
              setBeforeNavAway={setUpBothNavAways}
              survey={fetchProjectQuery.data?.survey}
              direction={direction}
              active={active}
              setShowSettings={setShowSettings}
              collapse={collapse}
              collapsed={collapsed}
              onExportDeliveries={onExportDeliveries}
              user={user}
              roles={roles}
              organization={organization}
              updateRoute={setActive}
            />
          </AnimatePresence>
          {showSettings && (
            <SurveySettings
              survey={fetchProjectQuery.data?.survey}
              userRoles={roles}
              onClose={() => setShowSettings(false)}
              user={user}
            ></SurveySettings>
          )}
        </div>
      )}
    </>
  );
};
