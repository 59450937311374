import { useEffect, useState } from "react";
import Button from "components/Button/Button";
import styles from "./MultipleChoice.module.scss";
import FlexRow from "components/layouts/FlexRow/FlexRow";

export const MultipleChoice = ({
  question,
  active,
  saveQuestion,
  designSettings,
  onAnswer,
  onRetract,
  answerMap,
  dependents,
  setUpLogicResolver,
  disable,
}) => {
  const editChoice = (choice, index) => {
    if (question.choiceQuestion.choices[index] !== choice) {
      question.choiceQuestion.choices[index] = choice;
      saveQuestion(question);
    }
  };

  const createOption = (index) => {
    question.choiceQuestion.choices.splice(index, 0, "");
    saveQuestion(question);

    setTimeout(() => {
      let input = document.getElementById(question.id + "optionInput" + index);
      if (input) {
        input.focus();
      }
    }, 50);
  };

  function onDeleteKey(index) {
    deleteOption(index);
    if (index > 0) {
      setTimeout(() => {
        let nextUp = document.getElementById(
          question.id + "optionInput" + (index - 1)
        );
        if (nextUp) {
          nextUp.focus();
        }
      }, 50);
    }
  }

  const deleteOption = (index) => {
    // check in dependents
    let current = question.choiceQuestion.choices[index];
    if (dependents) {
      for (let dependent of dependents.dependencies) {
        if (dependent?.keep && dependent.keep.includes(current)) {
          setUpLogicResolver("delete this option on", "delete", question, () =>
            reallyDeleteOption(index)
          );
          return;
        }
      }
    }

    reallyDeleteOption(index);
  };

  const reallyDeleteOption = (index) => {
    question.choiceQuestion.choices.splice(index, 1);
    saveQuestion(question);
  };

  const updateOtherText = (text) => {
    question.choiceQuestion.otherOptionLabel = text;
    saveQuestion(question);

    if (selected.includes(otherOption)) {
      let copy = [...selected];
      let ind = copy.indexOf(otherOption);
      copy[ind] = text;
      onAnswer(question.id, copy);
      setSelected(copy);
    }
  };

  function handleAnswer(selected) {
    if (!selected.length) {
      onRetract(question.id);
    } else {
      onAnswer(question.id, selected);
    }
  }

  const [selected, setSelected] = useState(
    answerMap[question.id] ? answerMap[question.id] : []
  );

  const handleCheck = (e) => {
    let select = [...selected];

    if (e.target.checked) {
      if (isMultiSelect || isRanking) {
        if (question.choiceQuestion.limit === select.length) {
          return;
        }
        select.push(e.target.value);
      } else {
        select = [e.target.value];
      }
    } else {
      select = select.filter((item) => item !== e.target.value);
    }
    setSelected(select);

    if (onAnswer) {
      handleAnswer(select);
    }
  };

  const handleCheckBars = (checked, option) => {
    let select = [...selected];

    if (checked) {
      if (isMultiSelect || isRanking) {
        if (question.choiceQuestion.limit === select.length) {
          return;
        }
        select.push(option);
      } else {
        select = [option];
      }
    } else {
      select = select.filter((item) => item !== option);
    }
    setSelected(select);

    if (onAnswer) {
      handleAnswer(select);
    }
  };

  function handleOtherCheck(e) {
    let select = [...selected];

    if (e.target.checked) {
      if (isMultiSelect || isRanking) {
        if (question.choiceQuestion.limit === select.length) {
          return;
        }
        select.push(e.target.value);
      } else {
        select = [e.target.value];
      }
    } else {
      select = select.filter((item) => item !== e.target.value);
    }
    setSelected(select);

    if (onAnswer) {
      handleAnswer(select);
    }

    if (e.target.checked) setUpShow();
  }

  const [showOtherInput, setShowOtherInput] = useState(false);

  function setUpShow() {
    setShowOtherInput(true);
    document.addEventListener("click", closeDownShow, true);
  }

  function closeDownShow() {
    document.removeEventListener("click", closeDownShow, true);
    setShowOtherInput(false);
  }

  const isRanking = question.choiceQuestion?.isRanking;

  const otherOption = question.choiceQuestion?.hasOtherOption
    ? question.choiceQuestion.otherOptionLabel
    : null;

  const [otherVal, setOtherVal] = useState("");

  const isMultiSelect = question.choiceQuestion?.isMultiSelect;

  const color = designSettings.baseColor;

  const [hovered, setHovered] = useState("");

  function getColor(option) {
    if (active) {
      return "";
    }
    if (option === hovered || selected.includes(option)) {
      if (designSettings?.baseColor) {
        return designSettings?.baseColor;
      }
      return "";
    }

    return "transparent";
  }
  function getBorder(option) {
    if (active) {
      return "";
    }
    if (option === hovered || selected.includes(option)) {
      return "2px solid transparent";
    }
    if (designSettings?.baseColor) {
      return "2px solid " + designSettings?.baseColor;
    }
    return "";
  }

  function getTextColor(option) {
    if (active) {
      return "";
    }
    if (option === hovered || selected.includes(option)) {
      return designSettings?.answerColorActive;
    }
    if (designSettings?.answerColor) {
      return designSettings?.answerColor;
    }
    return "";
  }

  const barDesign = designSettings.multipleChoiceBars && !isRanking;

  return (
    <div className={styles.multipleChoice}>
      {question.choiceQuestion?.choices &&
        question.choiceQuestion.choices.map((option, index) => (
          <div
            key={index}
            className={`${styles.option} ${
              barDesign && !active && styles.bar
            }`}
            style={{
              backgroundColor: barDesign
                ? getColor(option)
                : "",
              border: barDesign
                ? getBorder(option)
                : "",

              color: barDesign
                ? getTextColor(option)
                : designSettings?.answerColor
                ? designSettings?.answerColor
                : "",
            }}
            onMouseEnter={() =>
              barDesign
                ? setHovered(option)
                : undefined
            }
            onMouseLeave={() =>
              barDesign ? setHovered("") : undefined
            }
            onClick={() =>
              barDesign && !active
                ? handleCheckBars(!selected.includes(option), option)
                : undefined
            }
          >
            {!isMultiSelect &&
              !isRanking &&
              !barDesign && (
                <label className={`${styles.container}`}>
                  <input
                    id={index}
                    type="checkbox"
                    name={name}
                    value={option}
                    checked={selected.includes(option)}
                    onChange={handleCheck}
                    disabled={disable}
                  />
                  <span
                    className={styles.checkmark}
                    style={
                      selected.includes(option)
                        ? { backgroundColor: color }
                        : {}
                    }
                  ></span>
                </label>
              )}
            {isMultiSelect &&
              !isRanking &&
              !barDesign && (
                <label className={`${styles.container}`}>
                  <input
                    id={index}
                    type="checkbox"
                    name={name}
                    value={option}
                    onChange={handleCheck}
                    checked={selected.includes(option)}
                    disabled={disable}
                  />
                  <span
                    className={`${styles.checkmark} ${styles.multi}`}
                    style={
                      selected.includes(option)
                        ? { backgroundColor: color }
                        : {}
                    }
                  ></span>
                </label>
              )}
            {isRanking && !isMultiSelect && (
              <label className={`${styles.containerRank}`}>
                <input
                  id={index}
                  type="checkbox"
                  name={name}
                  value={option}
                  onChange={handleCheck}
                  checked={selected.includes(option)}
                  disabled={disable}
                />
                <span
                  className={`${styles.checkmarkRank} ${styles.multiRank}`}
                  style={
                    selected.includes(option) ? { backgroundColor: color } : {}
                  }
                >
                  <p className={styles.ranking}>
                    {selected.indexOf(option) + 1}
                  </p>
                </span>
              </label>
            )}
            {!active && <div className={styles.optionText}>{option}</div>}
            {active && (
              <>
                <div className={styles.optionTextEntry}>
                  <OptionInput
                    option={option}
                    index={index}
                    onSave={editChoice}
                    onEnter={() => createOption(index + 1)}
                    onDeleteEmpty={onDeleteKey}
                    dependents={dependents}
                    question={question}
                    setUpLogicResolver={setUpLogicResolver}
                  />
                </div>
                <div
                  className={styles.deleteButton}
                  onClick={() => deleteOption(index)}
                >
                  <i className="bi bi-dash-circle"></i>
                </div>
              </>
            )}
          </div>
        ))}
      {otherOption != null && (
        <>
          {active && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "2px" }}
            >
              <span className={styles.otherLabel}>
                Other Option Placeholder
              </span>

              {barDesign ? (
                <>
                  <OtherLabel
                    onBlur={updateOtherText}
                    otherOption={otherOption}
                  />
                </>
              ) : (
                <div className={styles.option}>
                  {!isMultiSelect && !isRanking && (
                    <label className={styles.container}>
                      <input
                        id={0}
                        type="radio"
                        name={name}
                        value={otherOption}
                        onChange={handleCheck}
                        disabled={disable}
                      />
                      <span
                        className={styles.checkmark}
                        style={
                          selected.includes(otherOption)
                            ? { backgroundColor: color }
                            : {}
                        }
                      ></span>
                    </label>
                  )}
                  {isMultiSelect && !isRanking && (
                    <label className={styles.container}>
                      <input
                        id={0}
                        type="checkbox"
                        name={name}
                        value={otherOption}
                        onChange={handleCheck}
                        disabled={disable}
                      />
                      <span
                        className={`${styles.checkmark} ${styles.multi}`}
                        style={
                          selected.includes(otherOption)
                            ? { backgroundColor: color }
                            : {}
                        }
                      ></span>
                    </label>
                  )}
                  {isRanking && !isMultiSelect && (
                    <label className={styles.containerRank}>
                      <input
                        id={0}
                        type="checkbox"
                        name={name}
                        value={otherOption}
                        onChange={handleCheck}
                        checked={selected.includes(otherOption)}
                        disabled={disable}
                      />
                      <span
                        className={`${styles.checkmarkRank} ${styles.multiRank}`}
                        style={
                          selected.includes(otherOption)
                            ? { backgroundColor: color }
                            : {}
                        }
                      >
                        <p className={styles.ranking}>
                          {selected.indexOf(otherOption) + 1}
                        </p>
                      </span>
                    </label>
                  )}
                  <OtherLabel
                    otherOption={otherOption}
                    onBlur={updateOtherText}
                  />
                </div>
              )}
            </div>
          )}
          {!active && (
            <>
              {barDesign ? (
                <>
                  <div
                    className={`${styles.option} ${styles.bar}`}
                    style={{
                      backgroundColor: getColor(otherOption),

                      border: getBorder(otherOption),
                      color: getTextColor(otherOption),
                    }}
                    onMouseEnter={() => setHovered(otherOption)}
                    onMouseLeave={() => setHovered("")}
                    onClick={() =>
                      handleCheckBars(
                        !selected.includes(otherOption),
                        otherOption
                      )
                    }
                  >
                    {otherOption}
                  </div>
                </>
              ) : (
                <div
                  className={styles.option}
                  style={{
                    color: barDesign
                      ? getTextColor(option)
                      : designSettings?.answerColor
                      ? designSettings?.answerColor
                      : undefined,
                  }}
                >
                  {!isMultiSelect && !isRanking && (
                    <label className={styles.container}>
                      <input
                        type="checkbox"
                        value={otherOption}
                        checked={selected.includes(otherOption)}
                        onChange={handleOtherCheck}
                        disabled={disable}
                      />
                      <span
                        className={styles.checkmark}
                        style={
                          selected.includes(otherOption)
                            ? { backgroundColor: color }
                            : {}
                        }
                      ></span>
                    </label>
                  )}
                  {isMultiSelect && !isRanking && (
                    <label className={styles.container}>
                      <input
                        type="checkbox"
                        value={otherOption}
                        onChange={handleOtherCheck}
                        checked={selected.includes(otherOption)}
                        disabled={disable}
                      />
                      <span
                        className={`${styles.checkmark} ${styles.multi}`}
                        style={
                          selected.includes(otherOption)
                            ? { backgroundColor: color }
                            : {}
                        }
                      ></span>
                    </label>
                  )}
                  {isRanking && !isMultiSelect && (
                    <label className={styles.containerRank}>
                      <input
                        type="checkbox"
                        value={otherOption}
                        onChange={handleOtherCheck}
                        checked={selected.includes(otherOption)}
                        disabled={disable}
                      />
                      <span
                        className={`${styles.checkmarkRank} ${styles.multiRank}`}
                        style={
                          selected.includes(otherOption)
                            ? { backgroundColor: color }
                            : {}
                        }
                      >
                        <p className={styles.ranking}>
                          {selected.indexOf(otherOption) + 1}
                        </p>
                      </span>
                    </label>
                  )}
                  <OtherTextEntry
                    current={otherVal}
                    label={otherOption}
                    onChange={(val) => setOtherVal(val)}
                    show={showOtherInput}
                    disable={disable}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
      <FlexRow style={{ width: "100%", justifyContent: "center" }}>
        {" "}
        {active && (
          <Button
            shadow
            info
            onClick={() =>
              createOption(question?.choiceQuestion?.choices?.length)
            }
          >
            + Answer
          </Button>
        )}
      </FlexRow>
    </div>
  );
};

function OptionInput({
  option,
  index,
  onSave,
  onEnter,
  onDeleteEmpty,
  dependents,
  question,
  setUpLogicResolver,
}) {
  const [val, setVal] = useState(option);

  useEffect(() => {
    if (option !== val) {
      setVal(option);
    }
  }, [option]);

  function handleOnChange(e) {
    if (dependents) {
      if (question.choiceQuestion.choices[index] !== e.target.value) {
        // check in dependents
        let current = question.choiceQuestion.choices[index];
        for (let dependent of dependents.dependencies) {
          if (dependent?.keep && dependent.keep.includes(current)) {
            let input = e.target.value;
            let id = e.target.id;
            setUpLogicResolver(
              "change this option on",
              "edit",
              question,
              () => {
                setVal(input);
                let choice = document.getElementById(id);
                if (choice) {
                  choice.focus();
                }
              }
            );
            return;
          }
        }
      }
    }

    setVal(e.target.value);
  }

  function handleFocusOut(e) {
    onSave(e.target.value, index);
  }

  function handleKeyDown(e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      onEnter();
    }
    if (!val) {
      if (e.key === "Backspace" || e.keyCode === 8) {
        onDeleteEmpty(index);
      }
    }
  }

  return (
    <input
      id={question.id + "optionInput" + index}
      onChange={handleOnChange}
      onBlur={handleFocusOut}
      className={styles.textField}
      value={val}
      onKeyDown={handleKeyDown}
      autoComplete="off"
    ></input>
  );
}

function OtherTextEntry({ current, label, onChange, show, disable }) {
  const [value, setValue] = useState(current);

  return (
    <div className={styles.otherOption}>
      {disable ? (
        <>{current ? current : label}</>
      ) : (
        <>
          <div
            className={styles.other}
            style={show && !disable ? { display: "none" } : undefined}
          >
            {current ? current : label}
          </div>
          <div
            className={styles.otherEntry}
            style={show ? { display: "flex" } : undefined}
          >
            <input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onBlur={(e) => onChange(e.target.value)}
              className={styles.inputLine}
              placeholder={label}
              type="text"
            ></input>
          </div>
        </>
      )}
    </div>
  );
}

function OtherLabel({ onBlur, otherOption }) {
  const [val, setVal] = useState(otherOption);

  return (
    <input
      value={val}
      onChange={(e) => setVal(e.target.value)}
      onBlur={(e) => onBlur(e.target.value)}
      className={styles.textField}
      autoComplete="off"
    ></input>
  );
}
