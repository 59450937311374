import { useState } from "react";
import styles from "./MFA.module.scss";
import { Loading } from "components/Loading/Loading";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import reactionLogo from "assets/images/reactionlogonew.png";
import Button from "components/Button/Button";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import {
  useSendMFACode,
  useVerifyMFACode,
} from "api/resources/authentication/login";
import { TextFieldSimple } from "components/inputs";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import { CodeInput } from "./VerifyEmail";

export function MFA({ user, onCleared }) {
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [byEmail, setByEmail] = useState(false);
  const [error, setError] = useState(false);

  const sendMFACode = useSendMFACode();

  function sendCode(method) {
    sendMFACode.mutate(
      { userId: user.id, method: method },
      {
        onSuccess: () => {
          setSending(false);
          setSent(true);
        },

        onError: () => {
          setSending(false);
          setError(true);
        },
      }
    );

    setByEmail(method === "email");
    setSending(true);
    if (error) setError(false);
  }

  function obscureNumber() {
    let string = "";
    for (let i = 0; i < user.phone.length; i++) {
      if (i < 2) {
        let num = user.phone[user.phone.length - 1 - i];
        string = num + string;
      } else string = "*" + string;
    }
    return string;
  }

  function obscureEmail() {
    let string = "";
    let passedAt = false;
    for (let i = 0; i < user.email.length; i++) {
      if (passedAt) {
        string += user.email[i];
      } else if (user.email[i] === "@") {
        string += user.email[i];
        passedAt = true;
      } else if (i < 1) {
        string += user.email[i];
      } else {
        string += "*";
      }
    }
    return string;
  }

  return (
    <div className={styles.page}>
      <div className={styles.formContainer}>
        <div className={styles.imageContainer}>
          <img src={reactionLogo} />
        </div>

        <FlexCol gap="2rem">
          <h1 style={{ textAlign: "center" }}>Multi-Factor Authentication</h1>

          {!sent && (
            <div className={styles.instruction}>
              <h3>
                Your account is secured with Multi-Factor Authentication (MFA)
              </h3>
              To finish signing in, choose which MFA method to use and then type
              in the code you recieved.
            </div>
          )}

          {!sent && <h3>Multi-Factor Login Options</h3>}
        </FlexCol>

        {!sent && (
          <div className={styles.canSend}>
            {/* {user.phone && (
              <FlexRow gap="20px">
                <div className={styles.sendOption}>Text:</div>

                <div className={styles.obscured}>{obscureNumber()}</div>

                <Button shadow white onClick={() => sendCode("phone")}>
                  {sending && !byEmail ? (
                    <>
                      <Loading height={"30px"} width={"30px"} />
                    </>
                  ) : (
                    <>Send</>
                  )}
                </Button>

                {error && !byEmail && <div className={styles.error}>Error</div>}
              </FlexRow>
            )} */}
            <FlexRow justify={"space-between"}>
              <FlexRow fit gap="20px">
                <h3>Email:</h3>

                <div className={styles.obscured}>{obscureEmail()}</div>
              </FlexRow>
              <FlexRow fit gap="20px">
                <Button
                  shadow
                  white
                  onClick={() => sendCode("email")}
                  gap={"5px"}
                  width={100}
                  blue
                >
                  {sending && byEmail ? (
                    <>
                      <Loading height={"30px"} width={"30px"} />
                    </>
                  ) : (
                    <>Send</>
                  )}
                </Button>

                {error && byEmail && <div className={styles.error}>Error</div>}
              </FlexRow>
            </FlexRow>

            <div className={styles.line}></div>
          </div>
        )}

        {sent && (
          <Verify
            goBack={() => setSent(false)}
            byEmail={byEmail}
            user={user}
            onCleared={onCleared}
          />
        )}
      </div>
    </div>
  );
}

function Verify({ goBack, byEmail, user, onCleared }) {
  const [valid, setValid] = useState(true);
  const [invalidMessage, setInvalidMessage] = useState("");
  const [remember, setRemember] = useState(false);
  const [code, setCode] = useState("");
  const [checking, setChecking] = useState(false);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(false);
  const [resent, setResent] = useState(false);
  const [success, setSuccess] = useState(false);

  const verifyCode = useVerifyMFACode();
  const sendMFACode = useSendMFACode();

  function preCheck() {
    let clean = code.replaceAll(" ", "");
    return clean.length == 6 && !isNaN(clean);
  }

  function verify() {
    if (checking) return;

    if (!preCheck()) {
      setValid(false);
      setInvalidMessage("Invalid Format");
      return;
    }

    verifyCode.mutate(
      {
        userId: user.id,
        code: code.replaceAll(" ", ""),
        remember: remember,
      },
      {
        onSuccess: (data) => {
          setSuccess(true);
          setChecking(false);
          setTimeout(() => {
            onCleared(data.userId);
          }, 500);
        },
        onError: (err) => {
          let msg = err.response.errors[0].message;
          console.log(msg);
          setInvalidMessage("Invalid or expired code");
          setValid(false);
          setChecking(false);
        },
      }
    );

    setChecking(true);
  }

  function resend() {
    sendMFACode.mutate(
      { userId: user.id, method: byEmail ? "email" : "phone" },
      {
        onSuccess: () => {
          setSending(false);
          setResent(true);
          setValid(true);
          setCode("");
          setInvalidMessage("");
        },

        onError: () => {
          setSending(false);
          setError(true);
        },
      }
    );

    setSending(true);
    if (error) setError(false);
  }

  return (
    <>
      <FlexCol gap={"15px"}>
        <div
          className={styles.instruction}
          style={{ textAlign: "left", paddingLeft: "5px" }}
        >
          {`${resent ? "We resent a" : "A"} 6 digit code was sent to your ${
            byEmail ? "email" : "phone"
          }. Please enter that here. (This code has a 10 minute expiration time)`}
        </div>

  
          <div style={{margin: "1rem 0"}}>
            <CodeInput length={6} onComplete={setCode}></CodeInput>
          </div>
          {invalidMessage && <span className={styles.red} style={{textAlign: "center"}}>{invalidMessage}</span>}
 
      </FlexCol>

      <FlexCol gap="10px" style={{ paddingLeft: "5px" }}>
        <FlexRow gap={"15px"}>
          <Checkbox
            checked={remember}
            onChange={(e) => setRemember(e.target.checked)}
          />
          <div
            className={styles.clickable}
            onClick={() => setRemember(!remember)}
          >
            Remember this device 90 days
          </div>
        </FlexRow>

        <FlexRow className={styles.clickable} onClick={goBack} fit>
          <div className={styles.return}>
            <i className="bi-arrow-return-left"></i>
          </div>
          <div>Try another method</div>
        </FlexRow>
      </FlexCol>

      <FlexRow justify="center">
        {!valid && invalidMessage === "Expired Code" ? (
          <>
            <Button shadow onClick={resend} style={{ gap: "5px" }}>
              {sending ? (
                <>
                  <Loading height={"30px"} width={"30px"} />
                </>
              ) : (
                <>Resend Code</>
              )}
            </Button>
            {error && <div className={styles.error}>Error</div>}
          </>
        ) : (
          <Button blue shadow onClick={verify} style={{ gap: "5px" }}>
            {checking ? (
              <>
                <Loading height={"30px"} width={"30px"} /> Verifying
              </>
            ) : success ? (
              <>
                <i className="bi-check2"></i> Verified
              </>
            ) : (
              <>Submit</>
            )}
          </Button>
        )}
      </FlexRow>
    </>
  );
}
