import { useState } from "react";
import styles from "../MetricSettings.module.scss";
import settingStyles from "../../../SettingsAccordion.module.scss";

export default function IconShopper({ onPick, folderView, graphs }) {
  const [showIcons, setShowIcons] = useState(false);

  return (
    <div className={styles.col} style={{ width: "100%" }}>
      {!folderView && (
        <div
          className={`${settingStyles.header} ${
            showIcons ? settingStyles.headervisible : ""
          }`}
          onClick={() => setShowIcons(!showIcons)}
          style={{ backgroundColor: "white", color: "#8dabb2" }}
        >
          Icons
          <span
            className={settingStyles.accordionicon}
            style={{ color: "#a3a4a8" }}
          >
            <i className="bi bi-caret-left-fill"></i>
          </span>
        </div>
      )}
      {(showIcons || folderView) && (
        <div className={styles.iconCatalog}>
          {!graphs &&
            icons.map((i) => (
              <div
                key={i}
                className={`${folderView ? styles.iconDark : styles.icon}`}
                style={{ cursor: "pointer" }}
                onClick={() => onPick(i)}
              >
                <i className={`bi bi-${i}`}></i>
              </div>
            ))}
          {graphs &&
            graphIcons.map((i) => (
              <div
                key={i}
                className={`${folderView ? styles.iconDark : styles.icon}`}
                style={{ cursor: "pointer" }}
                onClick={() => onPick(i)}
              >
                <i className={`bi bi-${i}`}></i>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export const icons = [
  "exclamation-lg",
  "bar-chart",
  "graph-up",
  "graph-down",
  "clipboard-data",
  "envelope",
  "activity",
  "send",
  "asterisk",
  "bell",
  "info-lg",
  "people",
  "people-fill",
  "bookmark",
  "briefcase",
  "patch-exclamation",
  "calendar",
  "check-lg",
  "clipboard2-pulse",
  "speedometer2",
  "heart-pulse",
  "hospital",
  "hand-thumbs-up",
  "hand-thumbs-down",
  "emoji-smile",
  "emoji-angry",
  "emoji-neutral",
  "person-exclamation",
  "exclamation-diamond",
  "house",
  "flag",
  "percent",
  "bucket",
  "question",
  "search",
  "star",
  "journal",
  "cone",
  "brush",
  "clipboard-check",
  "mailbox",
  "arrow-clockwise",
  "building",
  "card-list",
  "check2-square",
  "stopwatch",
  "chat",
  "folder",
  "award",
  "cloud",
  "airplane",
  "bar-chart",
  "arrow-repeat",
  "bag",
  "cart",
  "bank",
];

export const graphIcons = [
  "bar-chart-fill",
  "bar-chart",
  "bar-chart-line",
  "bar-chart-line-fill",
  "bar-chart-steps",
  "clipboard-data",
  "clipboard-data-fill",
  "clipboard2-data",
  "clipboard2-data-fill",
  "diagram-2",
  "diagram-2-fill",
  "diagram-3",
  "diagram-3-fill",
  "file-bar-graph",
  "file-bar-graph-fill",
  "file-earmark-bar-graph",
  "file-earmark-bar-graph-fill",
  "graph-down",
  "graph-down-arrow",
  "graph-up",
  "graph-up-arrow",
  "pie-chart",
  "pie-chart-fill",
];
