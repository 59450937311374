import styles from "./SurveyDesign.module.scss";
import { PageStyle } from "./PageStyle";
import { FinishedSettings } from "./FinishedSettings";
import { Sidebar2 } from "components/sidebars";
import { PageNavigation } from "./PageNavigation";
import Icon from "components/Icon/Icon";
import Themes from "./Themes";
import { QuestionSettings } from "../QuestionSettings/QuestionSettings";
import HeaderSettings from "./HeaderSettings";
import SectionSettings from "../SectionSettings/SectionSettings";
import { QuestionLogic } from "../QuestionLogic/QuestionLogic";

const menuItems = [
  {
    id: "style",
    name: "Global Style",
    icon: "bi bi-brush",
  },

  {
    id: "header",
    name: "Page Header",
    icon: "bi bi-card-heading",
  },

  {
    id: "themes",
    name: "Themes & Background",
    icon: "bi bi-palette",
  },

  {
    id: "nav",
    name: "Page Navigation",
    icon: "bi bi-arrow-right-square",
  },

  {
    id: "finished",
    name: "Finished",
    icon: "bi bi-check-circle",
  },
];

export function SurveyDesign({
  settings,
  setSettings,
  numPages,
  currPage,
  setCurrPage,
  allQuestions,
  onDeletePage,
  addPage,
  switchPages,
  status,
  active,
  setActive,
  onRetract,
  setUpLogicResolver,
  onEditQ,
  onEditQs,
  question,
  dependents,
  setActiveQ,
  setActiveId,
  projId,
  setShowTriggers,
  showLogic,
  setShowLogic,
  allQs,
  saveQs,
  onUpdate,
  upCounter,
}) {

  function changeSettingsField(setting, value) {
    let tempSettings = { ...settings };
    tempSettings[setting] = value;
    if (tempSettings?.theme != "custom") {
      tempSettings.theme = "custom";
    }
    setSettings(tempSettings);
  }

  function updateActive(id) {
    setActive(id);
    setActiveId("");
    setActiveQ("");
    setShowLogic(false);
  }

  return (
    <div className={styles.designContainer}>
      <Sidebar2
        menuItems={menuItems}
        active={active}
        updateActive={updateActive}
        condensed
        style={{ paddingTop: "5rem" }}
      ></Sidebar2>

      <div
        className={styles.designContent}
        style={{
          width: active || (question && showLogic) ? "18rem" : "",
        }}
      >
        {active === "style" && (
          <PageStyle
            settings={settings}
            changeSetting={(field, value) => {
              changeSettingsField(field, value);
            }}
            setSettings={setSettings}
            allQs={allQs}
            saveQs={saveQs}
            upCounter={upCounter}
          ></PageStyle>
        )}

        {active === "header" && (
          <HeaderSettings
            settings={settings}
            changeSetting={changeSettingsField}
            setSettings={setSettings}
            projId={projId}
          ></HeaderSettings>
        )}
        {active === "page" && (
          <HeaderSettings
            settings={settings}
            changeSetting={changeSettingsField}
          ></HeaderSettings>
        )}

        {active === "question" && (
          <QuestionSettings
            question={question}
            onEditQ={onEditQ}
            onEditQs={onEditQs}
            dependents={dependents}
            setUpLogicResolver={setUpLogicResolver}
            onRetract={onRetract}
            allQuestions={allQuestions}
            setShowTriggers={setShowTriggers}
            onUpdate={onUpdate}
          ></QuestionSettings>
        )}

        {active === "section" && (
          <SectionSettings
            section={question}
            onEditQ={onEditQ}
            onEditQs={onEditQs}
            allQuestions={allQuestions}
          ></SectionSettings>
        )}

        {active === "themes" && (
          <Themes
            settings={settings}
            changeSetting={changeSettingsField}
            setSettings={setSettings}
          ></Themes>
        )}
        {active === "finished" && (
          <FinishedSettings
            settings={settings}
            changeSetting={changeSettingsField}
          ></FinishedSettings>
        )}
        {active === "nav" && (
          <>
            <PageNavigation
              settings={settings}
              setSettings={setSettings}
              changeSetting={changeSettingsField}
              numPages={numPages}
              currPage={currPage}
              setCurrPage={setCurrPage}
              allQuestions={allQuestions}
              onDeletePage={onDeletePage}
              addPage={addPage}
              switchPages={switchPages}
              status={status}
            ></PageNavigation>
          </>
        )}
        {showLogic && question && (
          <QuestionLogic
            allQuestions={allQuestions}
            question={question}
            onEditQ={onEditQ}
            onEditQs={onEditQs}
          />
        )}
      </div>
      {active != 0 && active !== "question" && active !== "section" && (
        <Icon
          iconName={"chevron-left"}
          style={{ position: "absolute", top: "1rem", right: "1rem" }}
          onClick={() => (showLogic ? setActiveQ(null) : setActive(0))}
        ></Icon>
      )}
    </div>
  );
}
