import { useSearchProjectsForChart } from "api/resources/projects/projects";
import styles from "./TiedSurveys.module.scss";
import React, { useEffect, useState, useRef } from "react";
import { useUpdateTiedSurveys } from "api/resources/organization/reports";
import { ErrorBanner } from "pages/error_page/ErrorPage";
import Button from "components/Button/Button";
import { TextFieldSimple } from "components/inputs";
import FlexRow from "components/layouts/FlexRow/FlexRow";

export function TiedSurveys({ report, refetch, onViewResults, onUpdated }) {
  const [show, setShow] = useState(false);

  const updateTies = useUpdateTiedSurveys();

  function saveTies(list) {
    updateTies.mutate(
      {
        reportId: report.id,
        projectIds: {
          list: list.map((s) => s.id),
        },
      },
      {
        onSuccess: (data) => {
          console.log("updated:", data);
          refetch();
        },
      }
    );
    onUpdated();
  }

  function untie(survey) {
    let copy = [...report.project];
    let index = copy.findIndex((s) => s.id === survey.id);
    if (index >= 0) {
      copy.splice(index, 1);
    }
    saveTies(copy);
  }

  function tie(survey) {
    saveTies([...report.project, survey]);
    setShow(false);
  }

  return (
    <div className={styles.tiedToContainer}>
      {updateTies.isError && (
        <ErrorBanner error={updateTies.error} message={"Error updating ties"} />
      )}
      <div className={styles.label6}>Tied Surveys:</div>

      <div className={styles.tiedSurveys}>
        {report.project.map((survey, i) => (
          <TiedSurvey
            key={i}
            survey={survey}
            untie={untie}
            onViewResults={onViewResults}
          />
        ))}
        <div className={styles.addSurvey} onClick={() => setShow(true)}>
          + Tied Survey
        </div>
        {show && (
          <AddProject
            included={report.project}
            close={() => setShow(false)}
            addProject={tie}
          />
        )}
      </div>
    </div>
  );
}

function TiedSurvey({ survey, untie, onViewResults }) {
  return (
    <div className={`${styles.tiedSurvey}`}>
      <FlexRow fit gap="0.5rem">
        {survey.name}{" "}
        <span className={styles.link} onClick={() => onViewResults(survey)}>
          View Results
        </span>
      </FlexRow>
      <div className={styles.deleteSurvey} onClick={() => untie(survey)}>
        <i className={`bi bi-trash3`}></i>
      </div>
    </div>
  );
}

function AddProject({ included, addProject, close }) {
  const [searchString, setSearchString] = useState("");
  const [perPage, setPerPage] = useState(20);
  const [pageSkip, setPageSkip] = useState(0);
  const [searchSort, setSearchSort] = useState({
    item: "startedAt",
    descend: true,
  });

  useEffect(() => {
    fetchProjects.refetch();
  }, [searchString]);

  const fetchProjects = useSearchProjectsForChart(
    searchString,
    pageSkip,
    perPage,
    searchSort
  );

  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={styles.addProjectAnchor}>
      {fetchProjects.isError && (
        <ErrorBanner
          error={fetchProjects.error}
          message={"Error fetching surveys"}
        />
      )}
      <div className={styles.addProjects} ref={ref}>
        <div className={styles.search}>
          <TextFieldSimple
            value={searchString}
            placeholder="Search Surveys..."
            onChange={(val) => setSearchString(val)}
            customStyles={styles.searchSurveys}
          ></TextFieldSimple>
        </div>

        {fetchProjects.isSuccess && (
          <div className={styles.projectOptions}>
            {fetchProjects.data?.surveys.map((project, index) => (
              <React.Fragment key={index}>
                {!included.some((p) => p.id === project.id) && (
                  <div
                    key={index}
                    className={styles.projectOption}
                    onClick={() => {
                      addProject(project);
                    }}
                  >
                    {project.name}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
