import React, { useEffect, useState } from "react";
import styles from "./InteractiveReportTable.module.scss";
import { BasicTable } from "./BasicTable";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import { Loading } from "components/Loading/Loading";
import Button from "components/Button/Button";
import ReportWidgets from "./SurveyWidgets/ReportWidgets/ReportWidgets";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import {
  useFetchReportByExclusiveId,
  useFetchReportByExclusiveIdCount,
} from "api/resources/organization/reports";
import InteractiveTable from "./InteractiveTable";
import { Sorting } from "./InteractiveSurveysTable";

function InteractiveReportTable({
  headers,
  onRowClick,
  sort,
  setSort,
  currPage,
  setCurrPage,
  maxItems,
  queryData,
  queryDataAccessor,
  maxDataQuery,
  maxDataQueryAccessor,
  actionButtonText,
  hideActionButton,
  disableActionButton,
  tableTitle,
  tableDescription,
  widgetType,
  searchPlaceholder,
  onClickActionButton,
  viewing,
  setViewing,
  roles,
  user,
  organization,
  type,
  icon,
}) {
  const [widgetView, setWidgetView] = useState(widgetType ? true : false);
  // const [seeMore, setSeeMore] = useState(false);
  // const [chosenStatus, setChosenStatus] = useState("");

  // function handlePageChange(integer) {
  //   if (integer > currPage) {
  //     setPageSkip((pageSkip) => pageSkip + perPage);
  //   } else {
  //     setPageSkip((pageSkip) => pageSkip - perPage);
  //   }
  //   setCurrPage(integer - 1);
  // }

  // function setActive(status, item) {
  //   let temp = [...viewing];
  //   temp[item.index].active = status;
  //   setViewing(temp);
  // }

  // const [searchString, setSearchString] = useState("");
  // const [perPage, setPerPage] = useState(10);
  // const [pageSkip, setPageSkip] = useState(0);
  // const [pageSort, setPageSort] = useState({
  //   item: "updatedAt",
  //   descend: true,
  // });

  // const searchReportQuery = useFetchReportByExclusiveId(
  //   searchString,
  //   pageSkip,
  //   perPage,
  //   pageSort,
  //   "Search"
  // );

  // const reportFavorites = useFetchReportByExclusiveId(
  //   "",
  //   pageSkip,
  //   perPage,
  //   pageSort,
  //   "Favorites"
  // );

  return (
    <div className={styles.tableContainer}>
      {/* The Header*/}

      {/* The sTable*/}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "1em",
          width: "100%",
          alignItems: "center",
        }}
      >
        {/* {viewing &&
            !queryData?.isRefetching &&
            !maxDataQuery?.isRefetching && (
              <div className={styles.viewingContainer}>
                Viewing:{" "}
                {viewing.map((item) => (
                  <>
                    {item && (
                      <div
                        className={styles.viewPill}
                        style={{
                          backgroundColor: item.active
                            ? item.color2
                            : "lightgray",
                        }}
                        onClick={() => setActive(!item.active, item)}
                      >
                        <i
                          className={item.icon}
                          style={{ color: item.active ? item.color1 : "gray" }}
                        ></i>{" "}
                        <span
                          style={{ color: item.active ? item.color1 : "gray" }}
                        >
                          {item.name}
                        </span>
                        {item.active && (
                          <i className={`bi bi-x ${styles.x}`}></i>
                        )}
                        {!item.active && (
                          <i className={`bi bi-plus ${styles.addView}`}></i>
                        )}
                      </div>
                    )}
                  </>
                ))}
              </div>
            )} */}
        {/* {reportFavorites.isLoading && <Loading></Loading>}
          {reportFavorites.isSuccess &&
            reportFavorites.data.reports.length > 0 && (
              <div className={styles.section}>
                <div className={styles.tableTitleStar}>
                  Favorites <i className="bi bi-star-fill"></i>
                </div>

                <>
                  {widgetView && (
                    <ReportWidgets
                      reports={reportFavorites.data.reports}
                      onClick={onRowClick}
                      roles={roles}
                      user={user}
                      refetch={() => reportFavorites.refetch()}
                      onClickSeeMore={() => {
                        setChosenStatus("Favorites");
                        setSeeMore(true);
                      }}
                    />
                  )}
                  {!widgetView && (
                    <BasicTable
                      initheaders={headers}
                      data={reportFavorites.data.reports}
                      onRowClick={onRowClick}
                      sort={sort}
                      setSort={setSort}
                      id="reportfavs"
                    ></BasicTable>
                  )}
                </>
              </div>
            )} */}

        {(maxDataQuery?.isSuccess || !maxDataQuery) && queryData?.isSuccess && (
          <div className={styles.section} style={{ paddingBottom: "2em" }}>
            <div
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              {type === "Home" && (
                <div
                  className={styles.header_3}
                  style={{ margin: "1.5rem 0rem" }}
                >
                  {" "}
                  {organization?.name} Reports
                </div>
              )}
              {type === "Favorites" && (
                <div
                  className={styles.header_3}
                  style={{ margin: "1.5rem 0rem" }}
                >
                  Favorites
                </div>
              )}
              {type === "Shared" && (
                <div
                  className={styles.header_3}
                  style={{ margin: "1.5rem 0rem" }}
                >
                  Shared
                </div>
              )}
              {type === "Me" && (
                <div
                  className={styles.header_3}
                  style={{ margin: "1.5rem 0rem" }}
                >
                  My Reports
                </div>
              )}
              <div style={{ width: "fit-content" }}>
                <Sorting sort={sort} setSort={setSort}></Sorting>
              </div>
            </div>

            <>
              {widgetView && (
                <ReportWidgets
                  reports={queryData?.data[queryDataAccessor]}
                  onClick={onRowClick}
                  roles={roles}
                  user={user}
                  // isOrg
                  type={type}
                  refetch={() => queryData.refetch()}
                  organization={organization}
                  // onClickSeeMore={() => {
                  //   setChosenStatus("Organization");
                  //   setSeeMore(true);
                  // }}
                />
              )}
              {!widgetView && (
                <BasicTable
                  initheaders={headers}
                  data={queryData?.data[queryDataAccessor]}
                  onRowClick={onRowClick}
                  sort={sort}
                  setSort={setSort}
                  id="reportorg"
                ></BasicTable>
              )}
            </>
          </div>
        )}
        {/* {type === "Home" && (
          <SeeRecents
            widgetView={widgetType}
            sort={sort}
            setSort={setSort}
            user={user}
            roles={roles}
            onRowClick={onRowClick}
            organization={organization}
          ></SeeRecents>
        )} */}
      </div>
    </div>
  );
}

export default InteractiveReportTable;

function SeeRecents({
  widgetView,
  roles,
  user,
  sort,
  setSort,
  onRowClick,
  organization,
}) {
  const [searchString, setSearchString] = useState("");
  const [perPage, setPerPage] = useState(8);
  const [pageSkip, setPageSkip] = useState(0);
  const [pageSort, setPageSort] = useState({
    item: "updatedAt",
    descend: true,
  });

  const RecentsQuery = useFetchReportByExclusiveId(
    searchString,
    pageSkip,
    perPage,
    pageSort,
    "Recents"
  );

  // console.log(RecentsQuery.data);

  return (
    <>
      {RecentsQuery.isLoading && <Loading></Loading>}
      {RecentsQuery.isSuccess && RecentsQuery.data?.reports.length > 0 && (
        <div className={styles.section} style={{ paddingBottom: "5em" }}>
          <div className={styles.tableTitle}>Recently Viewed</div>

          {widgetView && (
            <ReportWidgets
              reports={RecentsQuery.data?.reports}
              onClick={onRowClick}
              roles={roles}
              user={user}
              // isOrg
              refetch={() => queryData.refetch()}
              // onClickSeeMore={() => {
              //   setChosenStatus("Organization");
              //   setSeeMore(true);
              // }}
              organization={organization}
            />
          )}
          {!widgetView && (
            <BasicTable
              initheaders={headers}
              data={RecentsQuery.data?.reports}
              onRowClick={onRowClick}
              sort={sort}
              setSort={setSort}
              id="reportorg"
            ></BasicTable>
          )}
        </div>
      )}
    </>
  );
}
