import { useRef, useState } from "react";
import styles from "./Clock.module.scss";
import {
  Global_Holidays,
  matchesHoliday,
  matchesNearby,
  US_Holidays,
} from "../Scheduler";

export function Clock({ time, onChange, b2b, US }) {
  const [hour, setHour] = useState(new Date(time).getHours() % 12);
  const [minute, setMinute] = useState(new Date(time).getMinutes());
  const [am, setAm] = useState(new Date(time).getHours() < 12);
  const [angle, setAngle] = useState(30 * hour + (minute / 59) * 30);

  function saveTime(h, m, am) {
    let newTime = new Date(time);
    if (!am) {
      h += 12;
    }
    newTime.setHours(h, m, 0, 0);
    onChange(newTime);
  }

  function toggleAm() {
    setAm((old) => !old);
    saveTime(hour, minute, !am);
  }

  const angleRef = useRef();
  const amRef = useRef();

  const circleRef = useRef(null);

  function normalize(angle) {
    // returns an angle within the 0-360 range
    return ((angle % 360) + 360) % 360;
  }

  const handleMouseDown = (e) => {
    const circle = circleRef.current;
    if (!circle) return;

    const rect = circle.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;

    function switchAmPm() {
      setAm(!amRef.current);
      amRef.current = !amRef.current;
    }

    function calcAngle(e) {
      const dx = e.clientX - centerX;
      const dy = e.clientY - centerY;

      const rad = Math.atan2(dy, dx);
      let deg = rad * (180 / Math.PI) + 90;

      if (deg < 0) {
        deg += 360;
      }

      const old = normalize(angleRef.current);
      const oppositeEnd = old >= 180 ? old - 180 : old + 180;

      let dir = 0;
      if (old < 180) {
        dir = deg < old || deg > oppositeEnd ? -1 : 1;
      } else {
        dir = deg >= old || deg <= oppositeEnd ? 1 : -1;
      }

      let delta = deg - old;
      if (dir > 0 && delta < 0) {
        // Forward Cross
        delta = 360 - old + deg;
        switchAmPm();
      } else if (dir < 0 && delta > 0) {
        // Backward Cross
        delta = -(360 - deg + old);
        switchAmPm();
      }

      const final = delta + angleRef.current;

      let hours = (normalize(final) * 12) / 360;
      let hour = Math.floor(hours);
      let minutes = (hours - hour) * 60;
      minutes = Math.floor((minutes * 10 + 5) / 10); // Rounding
      if (minutes == 60) {
        minutes = 0;
        hour == 12 ? (hour = 1) : hour++;
      }

      return [hour, minutes, final];
    }

    const handleMouseMove = (event) => {
      const [h, m, a] = calcAngle(event);

      setHour(h);
      setMinute(m);

      angleRef.current = a;
      setAngle(a);

      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        selection.removeAllRanges();
      }
    };

    angleRef.current = angle;
    amRef.current = am;

    const handleMouseUp = (event) => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);

      let [h, m, a] = calcAngle(event);

      let r = m % 15;
      if (r) {
        // Snap to closest 15 minutes
        if (r < 8) {
          m -= r;
        } else {
          m += 15 - r;
        }

        let cross = false;

        if (m == 60) {
          m = 0;

          if (h == 11) {
            cross = true;
            h = 0;
            switchAmPm();
          } else if (h == 12) {
            h = 1;
          } else {
            h++;
          }
        }

        let deg = 30 * h + (m / 59) * 30;
        let delta = (cross ? 360 : deg) - normalize(a);
        a += delta;
      }

      setHour(h);
      setMinute(m);
      setAngle(a);

      saveTime(h, m, amRef.current);
    };

    handleMouseMove(e);

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const numberSets = [
    ["12", "6"],
    ["1", "7"],
    ["2", "8"],
    ["3", "9"],
    ["4", "10"],
    ["5", "11"],
  ];

  function getClockBorder() {
    if (am) {
      return hour >= 6 || (hour == 5 && minute >= 30)
        ? styles.morning
        : styles.night;
    }

    return hour >= 8
      ? styles.night
      : hour >= 6
      ? styles.evening
      : styles.afternoon;
  }

  function dec() {
    let amPm = amRef.current;
    let h = hourRef.current;
    let min = minRef.current;

    if (min) {
      min--;
    } else {
      min = 59;

      if (!h) {
        amPm = !amPm;
        setAm(amPm);
      }

      h = h ? h - 1 : 11;
      setHour(h);
    }

    setMinute(min);
    saveTime(h, min, amPm);

    setAngle((old) => old - 0.5);

    saveRefs(h, min, amPm);
  }

  function inc() {
    let amPm = amRef.current;
    let h = hourRef.current;
    let min = minRef.current;

    min++;
    if (min == 60) {
      min = 0;

      if (h == 11) {
        amPm = !amPm;
        setAm(amPm);
      }

      h = h < 11 ? h + 1 : 0;
      setHour(h);
    }

    setMinute(min);
    saveTime(h, min, amPm);

    setAngle((old) => old + 0.5);

    saveRefs(h, min, amPm);
  }

  function saveRefs(h, m, amPm) {
    amRef.current = amPm;
    hourRef.current = h;
    minRef.current = m;
    itrRef.current++;

    if (itrRef.current > 1) {
      delayRef.current = Math.max(minDelay, delayRef.current * speedUp);
    }
  }

  const minDelay = 15;
  const speedUp = 0.8;
  const intervalRef = useRef(null);
  const delayRef = useRef(0);
  const minRef = useRef(null);
  const hourRef = useRef(null);
  const itrRef = useRef(0);

  const startAdjust = (func) => {
    if (!intervalRef.current) {
      minRef.current = minute;
      hourRef.current = hour;
      amRef.current = am;

      function run() {
        func();
        intervalRef.current = setTimeout(run, delayRef.current);
      }
      intervalRef.current = setTimeout(run, delayRef.current); // Recursive timeout

      delayRef.current = 500;
    }
  };

  const stopAdjust = () => {
    clearTimeout(intervalRef.current);
    intervalRef.current = null;
    delayRef.current = 0;
    itrRef.current = 0;
  };

  function changeHour(e) {
    let str = e.target.value;
    if (!isNaN(str)) {
      let num = parseInt(str);
      if (num >= 1) {
        if (num > 12) num %= 10;
        if (num == 12) num = 0;

        let deg = (num / 12) * 360;
        let currHour = (hour / 12) * 360;
        let delta = deg - currHour;
        let rslt = angle + delta;
        setAngle(rslt);
        setHour(num);

        saveTime(num, minute, am);
      }
    }
  }

  function changeMin(e) {
    let str = e.target.value;
    if (!isNaN(str)) {
      let num = parseInt(str);
      if (num >= 0) {
        if (num >= 100) num %= 100;
        if (num >= 60) num %= 10;

        let deg = num * 0.5;
        let minPart = angle % 30;
        let delta = deg - minPart;
        let rslt = angle + delta;
        setAngle(rslt);
        setMinute(num);

        saveTime(hour, num, am);
      }
    }
  }

  const holidays = [...Global_Holidays, ...(US ? US_Holidays : [])];

  function isHoliday() {
    for (let h of holidays) {
      if (matchesHoliday(h, time) || matchesNearby(h, time)) return true;
    }
    return false;
  }

  function getSuggestion() {
    if (b2b) {
      if (
        am &&
        (hour == 6 || hour == 5 || (hour == 7 && !minute)) // 5-7am
      ) {
        if (day == 2 || day == 3 || day == 4) {
          // Tue Wed Thu
          return (
            <span>
              <span className={styles.great}>Great</span> time
            </span>
          );
        }
        if (day == 1 || day == 5)
          // Mon Fri
          return (
            <span>
              <span className={styles.okay}>Decent</span> time
            </span>
          );
      }

      if (
        !am &&
        (day == 1 || day == 2 || day == 3 || day == 4) &&
        (hour == 0 || (hour == 1 && !minute)) // Mon Tue Wed or Thu 12-1pm
      ) {
        return (
          <span>
            <span className={styles.great}>Great</span> time
          </span>
        );
      }

      if (
        !am &&
        day == 0 &&
        (hour == 0 || hour == 1 || hour == 2 || (hour == 3 && !minute)) // Sun 12-2pm
      )
        return (
          <span>
            <span className={styles.okay}>Decent </span>time
          </span>
        );

      if (
        !am &&
        (day == 2 || day == 3 || day == 4) &&
        (hour == 3 || (hour == 4 && !minute)) // Tue Wed Thu 3-4pm
      )
        return (
          <span>
            <span className={styles.okay}>Decent </span>time
          </span>
        );
    } else {
      if (
        am &&
        ((hour == 5 && minute >= 30) || // 5:30-7:30
          hour == 6 ||
          (hour == 7 && minute <= 30))
      )
        return (
          <span>
            <span className={styles.great}>Great</span> time
          </span>
        );

      if (!am && (hour == 0 || (hour == 1 && !minute)))
        return (
          <span>
            <span className={styles.okay}>Decent </span>time
          </span>
        );
    }

    return "";
  }

  function okayDay() {
    if (b2b) {
      if (day == 6) return false;
    } else if (day == 5 || day == 6) return false;

    return !isHoliday();
  }

  const day = new Date(time).getDay();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.timeDisplay}>
          <div className={styles.time}>
            <input
              className={`${styles.timeInput} ${styles.hour}`}
              value={hour ? hour : 12}
              onChange={changeHour}
              type="text"
            />
            <span className={styles.dots}>:</span>
            <input
              className={`${styles.timeInput} ${styles.minute}`}
              value={minute < 10 ? "0" + minute : minute}
              onChange={changeMin}
              type="text"
            />

            <div className={styles.adjuster}>
              <div
                className={styles.adjustArrow}
                onMouseDown={() => startAdjust(inc)}
                onMouseUp={stopAdjust}
                onMouseLeave={stopAdjust}
              >
                <i className="bi-caret-up-fill"></i>
              </div>

              <div
                className={styles.adjustArrow}
                onMouseDown={() => startAdjust(dec)}
                onMouseUp={stopAdjust}
                onMouseLeave={stopAdjust}
              >
                <i className="bi-caret-down-fill"></i>
              </div>
            </div>
          </div>

          <div className={styles.amPm}>
            <div
              className={`${styles.toggle} ${
                am ? styles.chosen : styles.notChosen
              }`}
              onClick={toggleAm}
            >
              AM
            </div>
            <div className={styles.divider}></div>
            <div
              className={` ${styles.toggle} ${
                am ? styles.notChosen : styles.chosen
              }`}
              onClick={toggleAm}
            >
              PM
            </div>
          </div>
        </div>

        <div
          className={styles.suggestion}
          style={
            okayDay() && getSuggestion() ? undefined : { visibility: "hidden" }
          }
        >
          <li>{getSuggestion() ? getSuggestion() : <span>filler</span>}</li>
        </div>

        <div
          className={`${styles.clock} ${getClockBorder()}`}
          ref={circleRef}
          onMouseDown={handleMouseDown}
        >
          {!isHoliday(time) && (
            <>
              {b2b ? (
                <>
                  <div
                    className={`${styles.piePiece} ${styles.twoHours} ${
                      styles.fiveToSeven
                    } ${day == 1 || day == 5 ? styles.yellow : styles.green} ${
                      am &&
                      (day == 1 || day == 2 || day == 3 || day == 4 || day == 5) // 5-7am Mon Tue Wed Thu Fri
                        ? styles.show
                        : ""
                    }`}
                  ></div>

                  <div
                    className={`${styles.piePiece} ${styles.oneHour} ${
                      styles.green
                    } ${
                      (day == 1 || day == 2 || day == 3 || day == 4) &&
                      ((am && hour >= 5) || (!am && hour < 8)) // 12-1pm Mon Tue Wed Thu
                        ? styles.show
                        : ""
                    }`}
                  ></div>

                  <div
                    className={`${styles.piePiece} ${styles.oneHour} ${
                      styles.yellow
                    } ${styles.threeToFour} ${
                      (day == 2 || day == 3 || day == 4) &&
                      ((am && hour >= 6) || (!am && hour < 8)) // 12-1pm Mon Tue Wed Thu
                        ? styles.show
                        : ""
                    }`}
                  ></div>

                  <div
                    className={`${styles.piePiece} ${styles.threeHours} ${
                      styles.yellow
                    } ${
                      day == 0 && ((am && hour >= 5) || (!am && hour < 8))
                        ? styles.show
                        : ""
                    }`}
                  ></div>
                </>
              ) : (
                <>
                  {day != 5 && day != 6 && (
                    <>
                      <div
                        className={`${styles.piePiece} ${styles.twoHours} ${
                          styles.green
                        } ${styles.fiveThirtySevenThirty} ${
                          am ? styles.show : ""
                        }`}
                      ></div>

                      <div
                        className={`${styles.piePiece} ${styles.oneHour} ${
                          styles.yellow
                        } ${
                          (am && hour >= 5) || (!am && hour < 8)
                            ? styles.show
                            : ""
                        }`}
                      ></div>
                    </>
                  )}
                </>
              )}
            </>
          )}

          {/* <div className={`${styles.piePiece} ${styles.fifteen}`}></div>
          <div className={`${styles.piePiece} ${styles.thirty}`}></div>
          <div className={`${styles.piePiece} ${styles.oneHour}`}></div>
          <div className={`${styles.piePiece} ${styles.twoHours}`}></div> */}

          {/* <div className={`${styles.crossBar} ${styles.twelveSix}`}></div>
          <div className={`${styles.crossBar} ${styles.oneSeven}`}></div>
          <div className={`${styles.crossBar} ${styles.twoEight}`}></div>
          <div className={`${styles.crossBar} ${styles.threeNine}`}></div>
          <div className={`${styles.crossBar} ${styles.fourTen}`}></div>
          <div className={`${styles.crossBar} ${styles.fiveEleven}`}></div> */}

          {numberSets.map((nums, i) => (
            <div
              className={`${styles.numAngle} ${styles["angle" + i]}`}
              style={{ transform: `rotate(${i * 30}deg)` }}
            >
              {nums.map((num) => (
                <div
                  className={`${styles.number}`}
                  style={{ transform: `rotate(${i * -30}deg)` }}
                >
                  {num}
                </div>
              ))}
            </div>
          ))}

          <div
            className={`${styles.hand}`}
            style={{
              transform: `rotate(${angle}deg)`,
            }}
          ></div>
        </div>
      </div>
    </>
  );
}
