import { useState, useEffect, useRef } from "react";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import styles from "./QuestionLogic.module.scss";
import { shortId } from "components/tables/EditableTable/utils";
import Button from "components/Button/Button";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import {
  appliedConditionDescription,
  conditionDescription,
  trim,
} from "assets/functions/StringFunctions";
import { isConditionComplete } from "assets/functions/ObjectFunctions";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";

export const genOperators = [
  { value: "answer", label: "answer" },
  { value: "do not answer", label: "do not answer" },
  { value: "have answered", label: "have answered at all" },
  { value: "have not answered", label: "have not answered at all" },
  // { value: "have not answered", label: "is blank (no answer)" },
  // { value: "have answered", label: "is not blank (they've answered)" },
  // { value: "is showing", label: "is showing" },
];

export const scaleOperators = [
  { value: "between", label: "answer between" },
  { value: "below", label: "answer below" },
  { value: "above", label: "answer above" },
];

export const freeResponseOperators = [
  { value: "contains", label: "contains" },
  { value: "does not contain", label: "does not contain" },
  { value: "have not answered", label: "is blank (no answer)" },
  { value: "have answered", label: "is not blank (they've answered)" },
  { value: "is longer than", label: "is longer than" },
  { value: "is shorter than", label: "is shorter than" },
];

export const QuestionLogic = ({
  question,
  onEditQ,
  allQuestions,
  onEditQs,
}) => {
  const onlyQs = [];
  const upstream = []; // onlyQs
  const downstream = []; // Qs + Sections

  const thisInd = allQuestions.findIndex((q) => q.id === question.id);
  for (let i = 0; i < allQuestions.length; i++) {
    if (i !== thisInd) {
      let q = allQuestions[i];

      if ("questionText" in q) {
        onlyQs.push(q);
        if (i < thisInd) {
          upstream.push(q);
        }
      }

      if (i > thisInd) {
        downstream.push(q);
      }
    }
  }

  return (
    <div className={styles.container}>
      {"questionText" in question && downstream.length > 0 && (
        <Downstream
          question={question}
          downstream={downstream}
          upstream={upstream}
          onEditQ={onEditQ}
          onEditQs={onEditQs}
        />
      )}

      {upstream.length > 0 && (
        <Upstream
          question={question}
          allQuestions={allQuestions}
          upstream={upstream}
          onEditQ={onEditQ}
        />
      )}
    </div>
  );
};

function Downstream({ question, downstream, upstream, onEditQ, onEditQs }) {
  const [condition, setCondition] = useState();

  useEffect(() => {
    if (condition) setCondition(null);
  }, [question]);

  function addCondition() {
    setCondition({
      questionId: question.id,
      operator: "",
      answer: null,
    });
  }

  function onEdit(copy) {
    setCondition(copy);
  }

  function addConditionTo(q) {
    if (!q.conditionGroups) q.conditionGroups = [];

    let whenAll = q.conditionGroups.find(
      (g) => g.operator === "When all conditions match"
    );
    if (whenAll) {
      whenAll.conditions.push({
        ...condition,
        id: shortId(),
      });
    } else {
      q.conditionGroups.push({
        id: shortId(),
        operator: "When all conditions match",
        conditions: [{ ...condition, id: shortId() }],
      });
    }

    onEditQ(q);
  }

  function removeConditionFrom(q) {
    let strung = JSON.stringify(condition);
    for (let g of [...q.conditionGroups]) {
      for (let c of [...g.conditions]) {
        let copy = { ...c };
        delete copy.id;
        if (JSON.stringify(copy) === strung) {
          let ind = g.conditions.findIndex((con) => con.id === c.id);
          if (ind > -1) g.conditions.splice(ind, 1);
        }
      }
      if (!g.conditions.length) {
        let ind = q.conditionGroups.findIndex((group) => group.id === g.id);
        if (ind > -1) q.conditionGroups.splice(ind, 1);
      }
    }
  }

  function onChangeQs(chosen) {
    let had = haveIt();
    if (had.length > 1 && !chosen.length) {
      // remove From All
      let affected = [];
      for (let h of had) {
        let q = downstream.find((d) => d.id === h.value);
        if (q) {
          removeConditionFrom(q);
          affected.push(q);
        }
      }
      onEditQs(affected);
    } else {
      for (let opt of chosen) {
        if (!had.some((h) => h.value === opt.value)) {
          // add it
          let q = downstream.find((d) => d.id === opt.value);
          if (q) addConditionTo(q);
        }
      }

      for (let old of had) {
        if (!chosen.some((c) => c.value === old.value)) {
          let q = downstream.find((d) => d.id === old.value);
          if (q) {
            removeConditionFrom(q);
            onEditQ(q);
          }
        }
      }
    }
  }

  function haveIt() {
    let have = [];
    let strung = JSON.stringify(condition);
    let qInd = upstream.length + 1;
    for (let comp of downstream) {
      if ("questionText" in comp) qInd++;
      if (comp.conditionGroups) {
        for (let g of comp.conditionGroups) {
          for (let c of g.conditions) {
            let copy = { ...c };
            delete copy.id;
            if (
              JSON.stringify(copy) === strung &&
              !have.some((q) => q.value === comp.id)
            ) {
              have.push(addOption(comp, qInd));
              break;
            }
          }
        }
      }
    }
    return have;
  }

  function getQOptions() {
    let qInd = upstream.length + 1;
    let options = [];
    for (let component of downstream) {
      if ("questionText" in component) qInd++;

      let option = addOption(component, qInd);
      if (component.conditionGroups?.length) {
        option.label = (
          <FlexRow gap={"5px"} style={{ width: "fit-content" }}>
            {option.label}{" "}
            <div className={styles.logicIcon}>
              <i className="bi-signpost-split"></i>
            </div>
          </FlexRow>
        );
      }

      options.push(option);
    }
    return options;
  }

  function addOption(component, Qi) {
    if ("questionText" in component) {
      return {
        label: `Q${Qi}`,
        value: component.id,
      };
    } else {
      return {
        label: trim(component.bodyText),
        value: component.id,
      };
    }
  }

  const onThisQ = [];
  let qInd = upstream.length + 1;
  for (let comp of downstream) {
    if ("questionText" in comp) qInd++;
    if (comp.conditionGroups) {
      for (let g of comp.conditionGroups) {
        for (let c of g.conditions) {
          if (c.questionId === question.id) {
            let copy = { ...c };
            delete copy.id;
            let stringVersion = JSON.stringify(copy);
            let obj = onThisQ.find((o) => o.key === stringVersion);
            let name =
              "questionText" in comp ? `Q${qInd}` : trim(comp.bodyText);

            if (!obj) {
              onThisQ.push({
                key: stringVersion,
                c: copy,
                dependents: [name],
              });
            } else {
              obj.dependents.push(name);
            }
          }
        }
      }
    }
  }

  function editOld(condition) {
    setCondition(condition);
  }

  function onDelete() {
    if (isConditionComplete(condition)) {
      let had = haveIt();
      if (had.length) {
        onChangeQs([]);
      }
    }

    setCondition(null);
  }

  return (
    <>
      {" "}
      <div
        className={styles.logic}
        style={upstream.length > 0 ? { paddingBottom: "25px" } : undefined}
      >
        <div className={styles.label55}>
          Downstream Logic <i className={"bi-arrow-down"}></i>
        </div>

        {onThisQ.length > 0 && (
          <div
            className={styles.shortHandList}
            style={{ paddingBottom: "10px" }}
          >
            <div className={styles.listTitle}>{`On Q${
              upstream.length + 1
            }`}</div>
            {onThisQ.map((o) => (
              <div
                className={`${styles.shortHand} ${styles.downstream}`}
                onClick={() => editOld(o.c)}
                key={o.c.id}
              >
                {appliedConditionDescription(o.c, question, o.dependents)}
              </div>
            ))}
          </div>
        )}

        {condition && (
          <>
            <div className={styles.questionName}>
              {`If on ${`Q${upstream.length + 1}`}: `}
            </div>

            <ConditionShell onDelete={onDelete}>
              <Condition Q={question} condition={condition} onEdit={onEdit} />
              {isConditionComplete(condition) && (
                <div className={styles.row}>
                  <span>show</span>
                  <SelectField
                    options={getQOptions()}
                    value={haveIt()}
                    onChange={onChangeQs}
                    label="Questions"
                    selectMultiple
                  ></SelectField>
                </div>
              )}
            </ConditionShell>
          </>
        )}

        <div className={styles.addBox}>
          {(!condition || isConditionComplete(condition)) && (
            <Button white shadow onClick={addCondition}>
              <div style={{ display: "flex", gap: "5px" }}>
                <i className="bi bi-plus-lg"></i> Condition
              </div>
            </Button>
          )}
          {condition && (
            <Button white shadow onClick={() => setCondition(null)}>
              <div style={{ display: "flex", gap: "5px" }}>Done</div>
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

function Upstream({ question, upstream, onEditQ, allQuestions }) {
  const [groups, setGroups] = useState(
    question?.conditionGroups
      ? JSON.parse(JSON.stringify(question.conditionGroups))
      : []
  );
  const [edit, setEdit] = useState("");

  const qIdRef = useRef(question.id);

  useEffect(() => {
    setGroups(
      question?.conditionGroups
        ? JSON.parse(JSON.stringify(question.conditionGroups))
        : []
    );

    if (question.id !== qIdRef.current) {
      setEdit("");
    }

    qIdRef.current = question.id;
  }, [question]);

  useEffect(() => {
    for (let gi = 0; gi < groups.length; gi++) {
      for (let ci = 0; ci < groups[gi].conditions.length; ci++) {
        let c = groups[gi].conditions[ci];
        if (c.new && edit !== c.id) {
          onDeleteCondition(gi, ci);
          return;
        }
      }
    }
  }, [edit]);

  function updateGroups(newGroups) {
    setGroups(newGroups);

    let complete = true;
    for (let group of newGroups) {
      for (let c of group.conditions) {
        if (!isConditionComplete(c)) {
          complete = false;
          break;
        }
      }
    }

    if (
      complete &&
      JSON.stringify(question.conditionGroups) !== JSON.stringify(newGroups)
    ) {
      save(newGroups);
    }
  }

  function save(newConditions) {
    let tempQ = question;
    tempQ.conditionGroups = JSON.parse(JSON.stringify(newConditions));
    onEditQ(tempQ);
  }

  function newCondition() {
    const id = shortId();
    setEdit(id);
    return {
      id: id,
      questionId: null,
      operator: null,
      answer: null,
      new: true,
    };
  }

  function addGroup() {
    let temp = [...groups];

    temp.push({
      id: shortId(),
      operator: "When all conditions match",
      conditions: [newCondition()],
    });
    updateGroups(temp);
  }

  function changeGroupOperator(groupIndex, value) {
    let temp = [...groups];
    temp[groupIndex].operator = value;
    updateGroups(temp);
  }

  const groupOperators = [
    {
      value: "When any condition matches",
      label: "When any condition is met",
      shortHand: "Any",
    },
    {
      value: "When any condition does not match",
      label: "When any condition is not met",
      shortHand: "Any not",
    },
    {
      value: "When all conditions match",
      label: "When all conditions are met",
      shortHand: "All",
    },
    {
      value: "When no condition matches",
      label: "When no condition are met",
      shortHand: "All not",
    },
  ];

  function onDeleteGroup(gi) {
    let temp = [...groups];
    temp.splice(gi, 1);
    updateGroups(temp);
    setEdit("");
  }

  function onDeleteCondition(gi, ci) {
    let temp = [...groups];
    temp[gi].conditions.splice(ci, 1);
    if (temp[gi].conditions.length === 0) {
      temp.splice(gi, 1);
    }
    updateGroups(temp);
    setEdit("");
  }

  function onAddCondition(gi, ci) {
    let temp = [...groups];
    temp[gi].conditions.splice(ci, 0, newCondition());
    updateGroups(temp);
  }

  function addOnCondition() {
    if (groups.length) {
      let temp = [...groups];
      temp[groups.length - 1].conditions.push(newCondition());
      updateGroups(temp);
    } else {
      addGroup();
    }
  }

  function onDone(gi, ci, condition) {
    if (!isConditionComplete(condition)) {
      onDeleteCondition(gi, ci);
    }
    setEdit("");
  }

  function onSaveCondition(gi, ci, condition) {
    if (condition.new) delete condition.new;
    let temp = [...groups];
    temp[gi].conditions[ci] = { ...condition };
    updateGroups(temp);
  }

  return (
    <div className={styles.logic}>
      <div className={styles.label55}>
        Upstream Logic <i className={"bi-arrow-up"}></i>
      </div>

      <div className={styles.shortHandList}>
        <div className={styles.listTitle}>
          {`Show ${
            "questionText" in question
              ? `Q${upstream.length + 1}`
              : trim(question.bodyText)
          } if:`}
        </div>

        {groups.map((g, gi) => (
          <>
            {(groups.length > 1 ||
              g.conditions.length > 1 ||
              g.operator !== "When all conditions match") && (
              <>
                {edit === g.id ? (
                  <ConditionShell onDelete={() => onDeleteGroup(gi)}>
                    <div
                      className={styles.selectGroupOpHolder}
                      style={gi ? { paddingTop: "10px" } : undefined}
                    >
                      <SelectField
                        options={groupOperators}
                        value={groupOperators.find(
                          (type) => g.operator === type.value
                        )}
                        onChange={(typeOption) =>
                          changeGroupOperator(gi, typeOption.value)
                        }
                        label="Condition Group"
                      ></SelectField>
                    </div>
                  </ConditionShell>
                ) : (
                  <div
                    className={`${styles.shortHand} ${styles.groupShortHand}`}
                    onClick={() => setEdit(g.id)}
                  >
                    {
                      groupOperators.find((o) => o.value === g.operator)
                        ?.shortHand
                    }
                  </div>
                )}
              </>
            )}
            {g.conditions.length > 0 && (
              <div className={styles.shortHandList}>
                {g.conditions.map((c, ci) => (
                  <>
                    {edit === c.id ? (
                      <UpstreamCondition
                        init={c}
                        onDelete={() => onDeleteCondition(gi, ci)}
                        upstream={upstream}
                        question={question}
                        allQuestions={allQuestions}
                        onAdd={() => onAddCondition(gi, ci + 1)}
                        onDone={(condition) => onDone(gi, ci, condition)}
                        onSave={(condition) =>
                          onSaveCondition(gi, ci, condition)
                        }
                      />
                    ) : (
                      <>
                        <div
                          className={`${styles.shortHand} ${styles.upstream}`}
                          onClick={() => setEdit(c.id)}
                        >
                          {conditionDescription(
                            c,
                            upstream.find((q) => q.id === c.questionId),
                            upstream.findIndex((q) => q.id === c.questionId)
                          )}
                        </div>
                      </>
                    )}
                  </>
                ))}
              </div>
            )}
            {edit === g.id && (
              <div className={styles.addBox}>
                <Button
                  white
                  shadow
                  onClick={() => onAddCondition(gi, g.conditions.length)}
                >
                  <div style={{ display: "flex", gap: "5px" }}>
                    <i className="bi bi-plus-lg"></i> Condition
                  </div>
                </Button>

                <Button white shadow onClick={() => setEdit("")}>
                  Done
                </Button>
              </div>
            )}
          </>
        ))}
      </div>

      {!edit && (
        <div className={styles.addBox}>
          {groups.length > 0 && (
            <Button white shadow onClick={addGroup}>
              <div style={{ display: "flex", gap: "5px" }}>
                <i className="bi bi-plus-lg"></i> Group
              </div>
            </Button>
          )}
          <Button white shadow onClick={addOnCondition}>
            <div style={{ display: "flex", gap: "5px" }}>
              <i className="bi bi-plus-lg"></i> Condition
            </div>
          </Button>
        </div>
      )}
    </div>
  );
}

function UpstreamCondition({
  init,
  onDelete,
  upstream,
  question,
  allQuestions,
  onAdd,
  onDone,
  onSave,
}) {
  const [condition, setCondition] = useState({ ...init });

  function updateCondition(copy) {
    setCondition(copy);
    if (isConditionComplete(copy)) {
      onSave(copy);
    }
  }

  function changeQuestion(value) {
    let copy = { ...condition };
    copy.questionId = value;
    copy.answer = null;
    copy.operator = "";

    let newDepQ = upstream.find((q) => q.id === value);
    if (newDepQ.type === "Matrix") {
      copy.option = "";
    } else {
      delete copy.option;
    }

    updateCondition(copy);
  }

  function onUsePast(past) {
    let recycled = { ...past, id: condition.id };
    updateCondition(recycled);
    onDone(recycled);
  }

  const dependentQ = condition.questionId
    ? upstream.find((q) => q.id === condition.questionId)
    : undefined;

  return (
    <>
      <ConditionShell onDelete={onDelete} style={{ paddingTop: "5px" }}>
        <div className={styles.row}>
          <span>on</span>
          <SelectField
            options={upstream.map((q, i) => {
              return { value: q.id, label: `Q${i + 1}: ${q.questionText}` };
            })}
            value={upstream
              .map((q, i) => {
                return { value: q.id, label: `Q${i + 1}: ${q.questionText}` };
              })
              .find((opt) => condition.questionId === opt.value)}
            onChange={(typeOption) => changeQuestion(typeOption.value)}
            label="Question"
          ></SelectField>
        </div>

        {condition.questionId && dependentQ && (
          <Condition
            condition={condition}
            Q={dependentQ}
            onEdit={updateCondition}
          />
        )}

        {!condition.questionId && (
          <PastLogic
            allQuestions={allQuestions}
            onUse={onUsePast}
            question={question}
          />
        )}
      </ConditionShell>
      <div className={styles.addBox} style={{ paddingBottom: "10px" }}>
        {isConditionComplete(condition) && (
          <Button white shadow onClick={onAdd}>
            <div style={{ display: "flex", gap: "5px" }}>
              <i className="bi bi-plus-lg"></i> Condition
            </div>
          </Button>
        )}
        {condition.questionId && (
          <Button white shadow onClick={() => onDone(condition)}>
            Done
          </Button>
        )}
      </div>
    </>
  );
}

function PastLogic({ allQuestions, onUse, question }) {
  const pastLogic = [];
  let strung = [];

  const thisInd = allQuestions.findIndex((q) => q.id === question.id);

  let ind = 0;
  for (let q of allQuestions) {
    if (q.conditionGroups) {
      for (let g of q.conditionGroups) {
        for (let c of g.conditions) {
          let copy = { ...c };
          delete copy.id;
          let stringVersion = JSON.stringify(copy);
          if (!strung.includes(stringVersion)) {
            let i = allQuestions.findIndex((qst) => qst.id === c.questionId); // The dependend on index
            if (i < thisInd) {
              if (i > -1) {
                strung.push(stringVersion);
                pastLogic.push({
                  c: c,
                  q: allQuestions[i],
                  i: i,
                });
              }
            }
          }
        }
      }
    }

    ind++;
  }

  pastLogic.sort((a, b) => a.i - b.i);

  return (
    <>
      {pastLogic.length > 0 && (
        <div className={styles.shortHandList} style={{ paddingTop: "7px" }}>
          <div className={styles.listTitle}>Or Reuse Logic</div>
          {pastLogic.map((p) => (
            <div
              className={styles.shortHand}
              onClick={() => onUse(p.c)}
              key={p.c.id}
            >
              {conditionDescription(p.c, p.q, p.i)}
            </div>
          ))}
        </div>
      )}
      {/* {upstream.length > 0 && (
        <div
          className={`${styles.shortHand}`}
          style={pastLogic.length > 0 ? { marginTop: "10px" } : undefined}
        >
          {`Preceeding Question (Q${upstream.length}) is showing`}
        </div>
      )} */}
    </>
  );
}

function ConditionShell({ children, onDelete, style }) {
  return (
    <div className={styles.conditionShell} style={style}>
      <div className={styles.trash}>
        <i className="bi bi-trash" onClick={onDelete}></i>
      </div>

      <div className={styles.bar}></div>

      <div className={styles.condition}>{children}</div>
    </div>
  );
}

function Condition({ condition, onEdit, Q }) {
  function changeOption(value) {
    let copy = { ...condition };
    copy.option = value;
    onEdit(copy);
  }

  function changeOperator(value) {
    let copy = { ...condition };
    copy.operator = value;
    copy.answer = null;
    onEdit(copy);
  }

  function changeAnswer(value) {
    let copy = { ...condition };
    copy.answer = value;
    onEdit(copy);
  }

  return (
    <>
      {Q.type === "Matrix" && (
        <MatrixOptions
          Q={Q}
          condition={condition}
          changeOption={changeOption}
        />
      )}
      {(Q.type !== "Matrix" || condition.option) && (
        <>
          <Operator
            Q={Q}
            condition={condition}
            changeOperator={changeOperator}
          />

          {condition.operator && (
            <>
              <Answer Q={Q} condition={condition} changeAnswer={changeAnswer} />
            </>
          )}
        </>
      )}
    </>
  );
}

function MatrixOptions({ Q, condition, changeOption }) {
  function getChosenMatrixOption() {
    let val = Q.matrixQuestion.options
      .map((opt) => {
        return { value: opt, label: opt };
      })
      .find((opt) => condition.option === opt.value);
    return val ? val : null;
  }

  return (
    <>
      {Q && Q.matrixQuestion && (
        <div className={styles.row}>
          <span>for</span>
          <SelectField
            options={Q.matrixQuestion.options.map((opt) => {
              return { value: opt, label: opt };
            })}
            value={getChosenMatrixOption()}
            onChange={(opt) => changeOption(opt.value)}
            label="Option"
          ></SelectField>
        </div>
      )}
    </>
  );
}

function Operator({ Q, condition, changeOperator }) {
  function getRelavantOperators() {
    if (Q.scaleQuestion) {
      return [...genOperators, ...scaleOperators];
    } else if (Q.textQuestion) {
      return freeResponseOperators;
    } else if (Q.choiceQuestion) {
      return genOperators;
    }
    return [];
  }

  function getChosenOperator() {
    let op = getRelavantOperators().find(
      (type) => condition.operator === type.value
    );
    return op ? op : null;
  }

  return (
    <>
      <div className={styles.row}>
        <span>{Q?.textQuestion ? "their answer" : "they"}</span>
        <SelectField
          options={getRelavantOperators()}
          value={getChosenOperator()}
          onChange={(typeOption) => changeOperator(typeOption.value)}
          label="Action"
        ></SelectField>
      </div>
    </>
  );
}

function Answer({ Q, condition, changeAnswer }) {
  function showAnswerOptions() {
    if (Q.textQuestion) {
      return false;
    }
    let op = condition.operator;
    if (op === "have answered" || op === "have not answered") {
      return false;
    }
    return true;
  }

  function showTextEntry() {
    let op = condition.operator;
    if (op === "contains" || op === "does not contain") {
      if (Q.textQuestion) {
        return true;
      }
    }

    return false;
  }

  function showNumberInput() {
    let op = condition.operator;
    if (op === "is longer than" || op === "is shorter than") {
      if (Q.textQuestion) {
        return true;
      }
    }

    return false;
  }

  function getAnswerOptions() {
    if (Q.choiceQuestion) {
      let choiceQ = Q.choiceQuestion;
      let choices = choiceQ.choices.map((choice) => {
        return { value: choice, label: choice };
      });
      if (choiceQ.hasOtherOption) {
        choices.push({
          value: choiceQ.otherOptionLabel,
          label: choiceQ.otherOptionLabel,
        });
      }
      return choices;
    }

    if (Q.scaleQuestion) {
      let scale = Q.scaleQuestion;
      let options = [];
      for (let i = scale.min; i < scale.max + 1; i += scale.step) {
        options.push({ value: i, label: i });
      }
      return options;
    }
  }

  function getChosenAnswerOptions() {
    if (condition?.answer) {
      if (Array.isArray(condition.answer)) {
        return condition.answer.map((val) => {
          return { value: val, label: val };
        });
      } else {
        return { value: condition.answer, label: condition.answer };
      }
    }
    return null;
  }

  function canSelectMultiple() {
    if (condition.operator) {
      let op = condition.operator;
      return op === "answer" || op === "do not answer" || op === "between";
    }
    return false;
  }

  function handleChange(chosen) {
    let endVal = null;
    if (canSelectMultiple()) {
      if (chosen.length) {
        endVal = chosen.map((c) => c.value);
        if (condition.operator === "between" && endVal.length == 3) {
          endVal = endVal.slice(1);
        }
      } else {
        endVal = null;
      }
    } else {
      endVal = chosen.value;
    }

    changeAnswer(endVal);
  }

  return (
    <>
      {showAnswerOptions() && (
        <SelectField
          options={condition.questionId ? getAnswerOptions() : []}
          value={getChosenAnswerOptions()}
          onChange={handleChange}
          label="Choices"
          selectMultiple={canSelectMultiple()}
        ></SelectField>
      )}

      {showTextEntry() && (
        <TextFieldSimple
          style={{ height: "40px" }}
          label={"What to look for"}
          placeholder="Enter text"
          onChange={changeAnswer}
          onEnter={changeAnswer}
          value={condition?.answer}
        />
      )}

      {showNumberInput() && (
        <div
          style={{
            height: "40px",
            display: "flex",
            alignItems: "center",
            gap: ".5em",
          }}
        >
          <NumberInput
            startNumber={
              condition.answer
                ? condition.answer
                : 100 > Q?.textQuestion?.maxLength
                ? Q?.textQuestion?.maxLength
                : 100
            }
            min={0}
            max={Q?.textQuestion?.maxLength}
            onSave={changeAnswer}
          />
          <div className={styles.label}>Characters </div>
        </div>
      )}
    </>
  );
}

function SelectField({ options, value, onChange, label, selectMultiple }) {
  return (
    <SelectFieldCustom
      label={label}
      options={options}
      value={selectMultiple ? undefined : value}
      values={selectMultiple ? value : undefined}
      onChange={onChange}
      selectMultiple={selectMultiple}
      outside
      style={{ fontSize: ".8rem" }}
    ></SelectFieldCustom>
  );
}
