// External
import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation } from "api/Api";

export const useCreateUpload = () => {
  const mutation = gql`
    mutation CreateUpload($data: uploadInput!) {
      createUpload(data: $data) {
        id
        status
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("contacts");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useFetchContactImportGql = (id) => {
  const query = gql`
      query {
          getUpload(id: "${id}") {
            id
            organizationId
            totalWarnings
            status
            uploadDate
            audienceId
            type
            appUserId
            appUser {
              firstName
              lastName
            }
            tempcontact {
              id
              firstName
              lastName
              state
              flags
              customField
              email
            }
            num_uploaded
            num_failed
            num_updated
            num_ignored
            num_duplicates
            num_added_to_audience
            progress
            endDate
            name
        }
      }
    `;
  return useGqlQuery(["contactimport", id], query, {});
};

export const useFetchUploadByAudience = (id) => {
  const query = gql`
      query {
        upload: uploadByAudience(id: "${id}") {
            id
            organizationId
            totalWarnings
            status
            uploadDate
            audienceId
            type
            appUserId
            
        }
      }
    `;
  return useGqlQuery(["contactuploadbyaud", id], query, {});
};

export const useSearchUploadByOrg = (
  searchString,
  page,
  perPage,
  sort,
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortSelectionUpload!
    ) {
      response: searchUploadsByOrganization(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
      ) {
        uploads {
          id
          organizationId
          badContacts
          duplicateContacts
          totalWarnings
          status
          uploadDate
          audienceId
          type
          appUserId
          appUser {
            id
            firstName
            lastName
            email
          }
          num_uploaded
          num_failed
          num_updated
          num_ignored
          num_duplicates
          progress
          endDate
          name
          error
        }
        count
        hasMore
      }
    }
  `;

  return useGqlQuery(
    ["searchuploads" + searchString + page + perPage],
    query,
    {
      search: searchString,
      page: page,
      perPage: perPage,
      sort: sort,
    }
  );
};

export const useCountSearchUploadByOrg = (
  searchString,
  page,
  perPage,
  type
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $type: String!
    ) {
      count: countSearchUploadsByOrganization(
        search: $search
        page: $page
        perPage: $perPage
        type: $type
      )
    }
  `;

  return useGqlQuery(["searchuploadsCount", searchString + type], query, {
    search: searchString,
    page: page,
    perPage: perPage,
    type: type,
  });
};
