//External
import React from "react";
import { Link } from "react-router-dom";

//Internal
import styles from "./Header.module.scss";

/**
 * A header component that has a back button option
 * @param {String} title string to be displayed
 * @param {String} backPath the path to go back to
 * @returns {React.ReactElement} a header component
 */


export function Header ({ style, children}) {
  return (
    <div className={styles.header} style={style}>
      {children}
    </div>
  );
};
