import styles from "./CreateReport.module.scss";
import Button from "components/Button/Button";
import { useEffect, useRef, useState } from "react";
import { useSearchProjectsForChart } from "api/resources/projects/projects";
import { useNavigate } from "react-router-dom";
import { Loading } from "components/Loading/Loading";
import { useFetchMyOrg } from "api/resources/organization/organization";
import { Label } from "components/layouts/Label/Label";
import { TextFieldSimple } from "components/inputs";
import Stat from "components/tables/BasicTable/SurveyWidgets/Stat";
import Modal from "components/ReactModal/ReactModal";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import {
  useCopyReport,
  useCreateReport,
  useFetchReportByOrg,
} from "api/resources/organization/reports";
import { ErrorBanner } from "pages/error_page/ErrorPage";

export default function CreateReport({ close, org }) {
  const [format, setFormat] = useState("scratch");
  const [template, setTemplate] = useState("");
  const [name, setName] = useState("");
  const [creating, setCreating] = useState(false);
  const [load, setLoad] = useState(false);
  const [mode, setMode] = useState("main");
  const [showAddProj, setShowAddProj] = useState(false);
  const [report, setReport] = useState({
    // programIds: [],
    projects: [],
    breakouts: [],
    nps: false,
    copyId: "",
  });

  const copyReport = useCopyReport();
  const createReport = useCreateReport();

  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 100);
  }, [template]);

  function changeName(val) {
    setName(val);
  }

  function onCreate() {
    if (!creating) {
      if (format === "copy") {
        copyReport.mutate(
          {
            id: report.copyId + "123",
            name: name,
          },
          {
            onSuccess: (data) => {
              navigate("/report/" + data.copyReport.id);
            },
            onError: (data) => {
              console.log("error");
              setCreating(false);
            },
          }
        );
      }

      if (format === "scratch") {
        createReport.mutate(
          {
            name: name,
            projectIds: {
              list: report.projects.map((p) => p.id),
            },
          },
          {
            onSuccess: (data) => {
              navigate("/report/" + data.createReport.id);
            },
            onError: (data) => {
              console.log("error");
              setCreating(false);
            },
          }
        );
      }

      setFormat("");
      setCreating(true);
    }
  }

  const navigate = useNavigate();

  function addProject(survey) {
    let copy = { ...report };
    copy.projects.push(survey);
    if (!copy.nps && survey.type === "NPS") {
      copy.nps = true;
    }
    setReport(copy);
    setShowAddProj(false);
  }

  function removeProject(survey) {
    let copy = { ...report };
    let index = copy.projects.findIndex((p) => p.id === survey.id);
    copy.projects.splice(index, 1);

    if (!copy.projects.length) {
      copy.breakouts = [];
      copy.nps = false;
    }
    if (copy.nps) {
      copy.nps = copy.projects.some((p) => p.type === "NPS");
    }

    setReport(copy);
  }

  function onBreakoutCheck(name) {
    let copy = { ...report };
    let index = copy.breakouts.indexOf(name);
    if (index > -1) {
      copy.breakouts.splice(index, 1);
    } else {
      copy.breakouts.push(name);
    }
    setReport(copy);
  }

  function onCopySelect(rep) {
    let copy = { ...report };
    if (copy.copyId === rep.id) {
      copy.copyId = "";
    } else {
      copy.copyId = rep.id;
    }
    setReport(copy);
  }

  return (
    <>
      <Modal
        show={true}
        onClose={close}
        modalStyle={{
          borderRadius: "1em",
          overflow: "visible",
          width: mode === "create" ? "800px" : "",
        }}
      >
        {copyReport.isError && (
          <ErrorBanner
            error={copyReport.error}
            message={"Error copying report"}
          ></ErrorBanner>
        )}
        {createReport.isError && (
          <ErrorBanner
            error={createReport.error}
            message={"Error creating report"}
          ></ErrorBanner>
        )}
        <div
          className={styles.page}
          style={{
            overflow: window.innerWidth < 700 ? "auto" : "",
            right: mode === "create" ? "50em" : "0em",
            position: mode === "create" ? "absolute" : "",
            minWidth: mode === "create" ? "800px" : "",
          }}
        >
          {!creating && (
            <>
              <div className={styles.chooseContainer}>
                <div className={styles.chooseFrom}>
                  <div
                    className={`${styles.box} ${
                      format === "scratch" && styles.boxfill
                    }`}
                    onClick={() => setFormat("scratch")}
                  >
                    <h1>Create From Scratch</h1>
                    <span>Its just you and the drawing board</span>
                    <i className="bi-pencil-square"></i>
                  </div>
                  <div
                    className={`${styles.box} ${
                      format === "copy" && styles.boxfill
                    }`}
                    onClick={() => setFormat("copy")}
                  >
                    <h1>Copy Another Report</h1>
                    <span>No need to reinvent the wheel</span>
                    <i className="bi-layers"></i>
                  </div>
                  {/* <div
                  className={`${styles.box} ${
                    format === "template" && styles.boxfill
                  }`}
                  onClick={() => {
                    setFormat(format === "template" ? "" : "template");
                    setTemplate(null);
                  }}
                >
                  <h1>Use a Template</h1>
                  <span>The best styles for any kind of suvey</span>
                  <i className="bi-clipboard2-check"></i>
                </div> */}
                </div>

                {/* {format === "template" && (
                <div className={styles.fromTemplate}>
                  {fetchTemplates.isLoading && <Loading />}
                  {fetchTemplates.isError && (
                    <ErrorPage
                      error={fetchTemplates.error}
                      backup={"fetchTemplates"}
                    />
                  )}
                  <div className={styles.templates}>
                    {fetchTemplates.isSuccess && (
                      <Templates
                        temps={fetchTemplates.data.getSurveyTemplatesByOrg}
                        setSurveyName={setName}
                        setLoad={setLoad}
                        setTemplate={setTemplate}
                        template={template}
                      ></Templates>
                    )}
                  </div>
                </div>
              )} */}
              </div>

              <div className={styles.bar}></div>

              <div className={styles.fields}>
                {format === "scratch" && (
                  <>
                    <div
                      className={styles.setting}
                      style={{ paddingBottom: "20px" }}
                    >
                      <Label
                      // tooltipText={
                      //   "Tying surveys to a report enables you to auto generate charts"
                      // }
                      // labelIcon={<i className="bi-info-circle"></i>}
                      >
                        Tied Surveys
                      </Label>

                      <div className={styles.tiedSurveys}>
                        {report.projects.map((survey) => (
                          <TiedSurvey
                            survey={survey}
                            removeProject={removeProject}
                          />
                        ))}

                        <div
                          className={styles.addSurvey}
                          onClick={() => setShowAddProj(true)}
                        >
                          <div className={styles.plus}>+</div>
                        </div>
                        {showAddProj && (
                          <AddProject
                            included={report.projects}
                            close={() => setShowAddProj(false)}
                            addProject={addProject}
                          />
                        )}
                      </div>
                    </div>
                    {/* <div className={styles.setting}>
                      <Label
                        tooltipText={`${
                          !report.projects.length
                            ? "IF there are tied surveys, auto"
                            : "Auto"
                        } generate pages for each field selected`}
                        labelIcon={<i className="bi-info-circle"></i>}
                      >
                        Breakouts by
                      </Label>

                      {report.projects.some((p) => p.type === "NPS") && (
                        <div
                          className={styles.breakouts}
                          style={{ padding: "5px 0px 10px 60px" }}
                        >
                          <div className={styles.breakoutSetting}>
                            <div
                              className={
                                report.nps
                                  ? styles.breakoutChecked
                                  : styles.breakoutName
                              }
                              style={{ fontSize: ".9em" }}
                            >
                              <span style={{ color: "#A4C6D0" }}>
                                Type:&nbsp;
                              </span>{" "}
                              NPS
                            </div>

                            <Checkbox
                              onChange={() =>
                                setReport((old) => {
                                  return { ...old, nps: !old.nps };
                                })
                              }
                              checked={report.nps}
                              color={"#7fcfd3"}
                              disabled={!report.projects.length}
                            />
                          </div>
                        </div>
                      )}

                      {fetchCustomFields.isSuccess && (
                        <div className={styles.breakouts}>
                          {fetchCustomFields.data.getCurrOrgFields.map(
                            (cField) => (
                              <div className={styles.breakoutSetting}>
                                <Checkbox
                                  onChange={() => onBreakoutCheck(cField.name)}
                                  checked={report.breakouts.includes(
                                    cField.name
                                  )}
                                  color={"#7fcfd3"}
                                  disabled={!report.projects.length}
                                />
                                <div
                                  className={
                                    report.breakouts.includes(cField.name)
                                      ? styles.breakoutChecked
                                      : styles.breakoutName
                                  }
                                >
                                  {cField.displayName}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div> */}
                  </>
                )}
                <div className={styles.setting}>
                  <Label
                  // tooltipText={
                  //   "This the name of your report. You will be able to identify your report from this name"
                  // }
                  // labelIcon={<i className="bi-info-circle"></i>}
                  >
                    Name
                  </Label>
                  <TextFieldSimple
                    value={name}
                    placeholder="Give your report a name..."
                    onChange={changeName}
                  ></TextFieldSimple>
                </div>

                <div className={styles.buttons}>
                  <Button red shadow onClick={close}>
                    Cancel
                  </Button>
                  <Button
                    shadow
                    disable={
                      format === "copy" ? (report.copyId ? false : true) : false
                    }
                    onClick={onCreate}
                  >
                    Create Report
                  </Button>
                </div>
              </div>

              {format === "copy" && (
                <CopyHandler onClick={onCopySelect} chosenId={report.copyId} />
              )}
            </>
          )}

          {creating && (
            <div className={styles.creating}>
              <Loading></Loading>
              <span>
                Your report is being created. This might take a minute...
              </span>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

function TiedSurvey({ survey, removeProject }) {
  const [over, setOver] = useState(false);

  return (
    <div className={`${styles.tiedSurvey} ${over ? styles.careful : ""}`}>
      {survey.name}
      <div
        className={styles.deleteSurvey}
        onClick={() => removeProject(survey)}
        onMouseEnter={() => setOver(true)}
        onMouseLeave={() => setOver(false)}
      >
        <i className={`bi bi-${over ? "trash3-fill" : "trash3"}`}></i>
      </div>
    </div>
  );
}

function AddProject({ included, addProject, close }) {
  const [searchString, setSearchString] = useState("");
  const [perPage, setPerPage] = useState(20);
  const [pageSkip, setPageSkip] = useState(0);
  const [searchSort, setSearchSort] = useState({
    item: "startedAt",
    descend: true,
  });

  useEffect(() => {
    fetchProjects.refetch();
  }, [searchString]);

  const fetchProjects = useSearchProjectsForChart(
    searchString,
    pageSkip,
    perPage,
    searchSort
  );

  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={styles.addProjectAnchor}>
      <div className={styles.addProjects} ref={ref}>
        <div className={styles.search}>
          <TextFieldSimple
            value={searchString}
            placeholder="Search Surveys..."
            onChange={(val) => setSearchString(val)}
            customStyles={styles.searchSurveys}
          ></TextFieldSimple>
        </div>
        {fetchProjects.isError && (
          <ErrorBanner
            error={fetchProjects.error}
            message="Error fetching surveys"
          />
        )}
        {fetchProjects.isSuccess && (
          <div className={styles.projectOptions}>
            {fetchProjects.data?.surveys.map((project, index) => (
              <>
                {!included.some((p) => p.id === project.id) && (
                  <div
                    key={index}
                    className={styles.projectOption}
                    onClick={() => {
                      addProject(project);
                    }}
                  >
                    {project.name}
                  </div>
                )}
              </>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

function CopyHandler({ onClick, chosenId }) {
  const [searchString, setSearchString] = useState("");
  const [perPage, setPerpage] = useState(0);
  const [pageSkip, setPageSkip] = useState(0);
  const [sort, setSort] = useState({
    item: "name",
    descend: true,
  });
  const fetchUserReports = useFetchReportByOrg(
    searchString,
    pageSkip,
    perPage,
    sort,
    ["org", "user"]
  );

  const fetchOrg = useFetchMyOrg();

  function splitReports() {
    let categories = [
      { header: fetchOrg.data.organization.name + " Reports", reports: [] },
      { header: "My Reports", reports: [] },
    ];

    for (let report of fetchUserReports.data.reports) {
      let ind = report.organizationId ? 0 : 1;

      let reportInfo = {
        name: report.name,
        id: report.id,
        numPages: report.analysis.length,
        icon: report.icon,
      };
      let numCharts = 0;
      for (let anal of report.analysis) {
        numCharts += anal.visualization.length;
      }
      reportInfo.numCharts = numCharts;
      categories[ind].reports.push(reportInfo);
    }

    return categories;
  }

  return (
    <>
      {fetchUserReports.isError && (
        <ErrorBanner
          error={fetchUserReports.error}
          message={"Error fetching reports"}
        ></ErrorBanner>
      )}
      {fetchOrg.isError && (
        <ErrorBanner
          error={fetchOrg.error}
          message={"Error fetching organization"}
        ></ErrorBanner>
      )}
      {(fetchUserReports.isLoading || fetchOrg.isLoading) && <Loading />}
      {fetchUserReports.isSuccess && fetchOrg.isSuccess && (
        <div className={styles.copyReportContainer}>
          {splitReports().map((category, i) => (
            <div key={category.header} className={styles.copyCategory}>
              <div
                className={styles.header}
                style={{ marginTop: i === 0 ? "0em" : "" }}
              >
                {" "}
                {category?.header}
              </div>
              <div className={styles.copies}>
                {category.reports.map((rep) => (
                  <div
                    className={`${styles.copy} ${
                      chosenId === rep.id ? styles.copyChosen : ""
                    }`}
                    onClick={() => onClick(rep)}
                  >
                    {rep.icon && (
                      <div className={styles.icon}>
                        <span className={`bi bi-${rep.icon}`}></span>
                      </div>
                    )}
                    <div className={styles.copyName}>{rep.name}</div>
                    <div className={styles.copyStats}>
                      <Stat
                        small
                        value={rep.numPages}
                        title={"Pages"}
                        titleStyle={{ fontWeight: "300" }}
                      ></Stat>
                      <Stat
                        small
                        value={rep.numCharts}
                        title={"Charts"}
                        titleStyle={{ fontWeight: "300" }}
                      ></Stat>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

function ReportCopies({
  template,
  setTemplate,
  setSurveyDescription,
  setLoad,
  setSurveyName,
  recent,
  open,
  closed,
  draft,
}) {
  // const [templates, setTemplates] = useState(
  //   setTemps(recent, open, closed, draft)
  // );

  // function setTemps(rec, op, cl, dr) {
  //   let finaltemps = [
  //     { category: "Org", array: rec },
  //     { category: "", array: op },
  //     { category: "Closed", array: cl },
  //     { category: "Draft", array: dr },
  //   ];

  //   return finaltemps;
  // }

  return (
    <div className={styles.alltemps}>
      {templates.map((category, i) => (
        <div key={i}>
          {category.array.length > 0 && (
            <div
              className={styles.header}
              style={{ marginTop: i === 0 ? "0em" : "" }}
            >
              {" "}
              {category?.category}
            </div>
          )}
          <div className={styles.chooseItemTemplate}>
            {category?.array.map((temp, i) => (
              <div
                className={`${styles.box} ${
                  template?.id === temp.id && styles.boxfill
                }`}
                onClick={() => {
                  setTemplate(template === temp ? "" : temp);
                  // if (!surveyName) {
                  setSurveyName(temp.name + " (COPY)");
                  // }
                  // if (!surveyDescription) {
                  setSurveyDescription(temp.description);
                  setLoad(true);
                  // }
                }}
                style={{
                  height: "120px",
                  width: "250px",
                  gap: "2em",
                  justifyContent: "space-between",
                  position: "relative",
                }}
                key={i}
              >
                <div className={styles.boxheader}>
                  <h1>{temp.name}</h1>
                  <span>{temp.description}</span>
                </div>
                {/* <img src={temp.imageURL}></img> */}
                <div
                  style={{
                    display: "flex",
                    gap: "1em",
                    fontSize: "1.1em",
                    position: "absolute",
                    bottom: ".8em",
                    left: "20px",
                  }}
                >
                  <Stat
                    small
                    value={temp?.question?.length}
                    title={
                      temp?.question?.length === 1 ? "Question" : "Questions"
                    }
                    titleStyle={{ fontWeight: "300" }}
                  ></Stat>
                  <Stat
                    small
                    value={temp?.numPages}
                    title={temp?.numPages === 1 ? "Page" : "Pages"}
                    titleStyle={{ fontWeight: "300" }}
                  ></Stat>
                  <Stat
                    small
                    value={temp?.distribution?.length}
                    title={
                      temp?.distribution?.length === 1 ? "Message" : "Messages"
                    }
                    titleStyle={{ fontWeight: "300" }}
                  ></Stat>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
