import {
  useDeleteAudienceGql,
  useUpdateAudienceGql,
} from "api/resources/contacts/audiences";
import React, { useState } from "react";
import styles from "./AudienceSettings.module.scss";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import Button from "components/Button/Button";
import { Loading } from "components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { maintimezones } from "assets/functions/Variables";
import { Label } from "components/layouts/Label/Label";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import IconShopperModal from "pages/results/Charts/Settings/AccordianItems/MetricSettings/IconShopper/IconShopperModal";
import FlexRow from "components/layouts/FlexRow/FlexRow";

function AudienceSettings({ audience, setSettings }) {
  const deleteAudienceQuery = useDeleteAudienceGql();
  const updateAudience = useUpdateAudienceGql();

  const [name, setName] = useState(audience?.name);
  const [icon, setIcon] = useState(
    audience?.icon ? audience?.icon : "people-fill"
  );
  const [iconColor, setIconColor] = useState(
    audience?.iconColor ? audience?.iconColor : "#15bcc7"
  );
  const [changeIcon, setChangeIcon] = useState(false);
  const [description, setDescription] = useState(audience?.description);
  const [saveState, setSaveState] = useState("Save Changes");
  const [timezone, setTimezone] = useState(
    maintimezones.find((t) => t.value === audience?.timeZone)
  );

  let navigate = useNavigate();

  function deleteAudience() {
    if (window.confirm("Are you sure you want to delete this audience?")) {
      deleteAudienceQuery.mutate(
        {
          deleteAudienceId: audience?.id,
        },
        {
          onSuccess: () => {
            navigate("contacts/audiences/");
            location.reload();
          },
        }
      );
    }
  }

  function saveChanges() {
    setSaveState(
      <div style={{ display: "flex", gap: ".5em", width: "fit-content" }}>
        Saving... <Loading height={20} width={20}></Loading>
      </div>
    );
    updateAudience.mutate(
      {
        data: {
          name: name,
          description: description,
          timeZone: timezone?.timezone,
          icon: icon,
          iconColor: iconColor,
        },
        id: audience?.id,
      },
      {
        onSuccess: () => {
          setSaveState(
            <div>
              Saved <i className="bi bi-check"></i>
            </div>
          );
          setTimeout(() => {
            setSaveState("Save Changes");
          }, 2000);
        },
      }
    );
  }

  return (
    <div className={styles.settingsPage}>
      <FlexRow justify={"space-between"} wrap={"wrap"}>
        <h2>Audience Settings</h2>
        <Button shadow red onClick={deleteAudience}>
          Delete Audience
        </Button>
      </FlexRow>
      <div className={styles.settingsSection}>
        <TextFieldSimple
          value={name}
          label="Audience Name"
          onChange={(val) => setName(val)}
          placeholder="Name"
        ></TextFieldSimple>
        <TextFieldSimple
          value={description}
          label="Audience Description"
          onChange={(val) => setDescription(val)}
          placeholder="Description"
        ></TextFieldSimple>
        <div>
          <SelectFieldCustom
            value={timezone}
            label="Time Zone"
            options={maintimezones}
            onChange={(val) => setTimezone(val)}
          />
        </div>

        <FlexRow fit>
          <div className={styles.icon}>
            <Label>Icon: </Label>
            <i
              className={"bi-" + icon}
              style={{ color: iconColor }}
              onClick={() => setChangeIcon(true)}
            ></i>
            <ColorPicker
              defaultColor={iconColor}
              onChange={setIconColor}
            ></ColorPicker>
          </div>
          {changeIcon && (
            <IconShopperModal
              onPick={(i) => {
                setIcon(i);
              }}
              onClose={() => setChangeIcon(false)}
            ></IconShopperModal>
          )}
        </FlexRow>

        <FlexRow justify={"center"}>
          <Button shadow onClick={saveChanges} blue>
            {saveState}
          </Button>
        </FlexRow>
      </div>
    </div>
  );
}

export default AudienceSettings;
