// External
import React, { useEffect, useMemo, useRef, useState } from "react";

// Internal
import styles from "./Audiences.module.scss";
import { useNavigate } from "react-router-dom";
import {
  useGetAudienceFolders,
  useSearchAudienceGql,
} from "api/resources/contacts/audiences";
import { Loading } from "components/Loading/Loading";
import AudienceFolders from "./AudienceFolders";
import { Footer } from "App";

export const Audiences = ({ user, organization, roles, updateRoute }) => {
  useMemo(() => {
    if (updateRoute) {
      updateRoute(1);
    }
  }, []);
  
  const [sort, setSort] = useState({
    item: "name",
    descend: true,
  });

  const getFolders = useGetAudienceFolders(sort);

  function refetch() {
    // getFolders.remove();
    // getFolders.refetch();
    location.reload();
  }

  return (
    <div className={styles.page}>
      {getFolders.isLoading && <Loading></Loading>}
      {getFolders.isSuccess && (
        <AudienceFolders
          initialFolders={getFolders?.data?.response?.folders}
          audienceCount={getFolders?.data?.response?.count}
          refetchFolders={refetch}
        ></AudienceFolders>
      )}
    </div>
  );
};
