//External
import React, { useEffect, useState } from "react";

//Internal
import styles from "./Section.module.scss";

import TextEditorTry from "components/inputs/input_fields/TextEditorTry/TextEditorTry";
import { Image } from "./Image/Image";

/**
 * A component that displays the current section
 * @param {function} section the section being used to display
 * @returns {React.ReactElement} a sectionConstruction component
 */
const Section = ({ section, active, saveSection }) => {
  useEffect(() => {
    if (typeof section.sectionSettings === "string") {
      section.sectionSettings = JSON.parse(section.sectionSettings);
    }
  }, []);
  function handleSave(text, code) {
    section.bodyText = text;
    section.encoding = code;
    saveSection(section);
  }

  return (
    <>
      {section.type === "Description" && (
        <TextEditorTry
          editable
          encoding={section.encoding}
          placeholder={"Enter text..."}
          defaultStyle={{ fontSize: "12pt" }}
          onSave={handleSave}
          height={active ? "100px" : ""}
          showToolbar={active}
          autoFocus={active}
          id={"text for " + section.id}
        />
      )}
      {section.type === "Image" && (
        <>
          <Image section={section} active={active} saveSection={saveSection} />
        </>
      )}
    </>
  );
};

export default Section;
