//External
import React, { useEffect, useState } from "react";

//Internal
import styles from "./Section.module.scss";
import TextEditorTry from "components/inputs/input_fields/TextEditorTry/TextEditorTry";

/**
 * A component that displays the current section
 * @param {function} section the section being used to display
 * @returns {React.ReactElement} a sectionConstruction component
 */
const Section = ({ section }) => {
  useEffect(() => {
    if (typeof section.sectionSettings === "string") {
      section.sectionSettings = JSON.parse(section.sectionSettings);
    }
  }, []);

  const settings = section.sectionSettings;

  return (
    <div
      className={styles.sectionContainer}
      style={{
        boxShadow: settings?.sectionShadow,
        backgroundColor: settings?.hasBackgroundColor
          ? settings?.backgroundColor
          : "transparent",
      }}
    >
      {section.type === "Description" && section.bodyText != "" && (
        <TextEditorTry encoding={section.encoding} linkable />
      )}

      {section.type === "Image" && section.image && (
        <div
          className={styles.imageHolder}
          style={{
            alignItems:
              settings.align == "left"
                ? "flex-start"
                : settings.align == "right"
                ? "flex-end"
                : "center",
          }}
        >
          <img
            src={section.image.imageURL}
            width={settings.fill ? undefined : settings.width}
          />
        </div>
      )}
    </div>
  );
};

export default Section;
