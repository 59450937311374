import { useState } from "react";
import styles from "./UserProfile.module.scss";
import {
  useFetchUser,
  useGetCurrentUser,
  useRemoveUserFromOrg,
  useUpdateUserById,
} from "api/resources/organization/users";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import Button from "components/Button/Button";
import { Loading } from "components/Loading/Loading";
import ImageEditor from "components/Image Editor/ImageEditor";
import {
  useChangeUserRole,
  useFetchOrgRoles,
} from "api/resources/organization/roles";
import { ChangePassword } from "pages/account/accountDetails/AccountDetails";
import { useFetchCustomFields } from "api/resources/organization/organization";
import UserActivity from "./UserActivity";
import { SigBuilder } from "pages/organization/SigBuilder/SigBuilder";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { useFetchUserSigs } from "api/resources/projects/distributions";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import EditField from "components/inputs/input_fields/EditField/EditField";
import Icon from "components/Icon/Icon";
import { SigPreview } from "pages/organization/organization_details/OrganizationDetails";
import { Header } from "components/layouts";
import { HeaderContainer } from "components/layouts/Header/HeaderContainer";
import Status from "components/Status/Status";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { trimDate } from "assets/functions/DateFunctions";
import { ErrorBanner } from "pages";
import DataInput from "components/layouts/ObjectLayout/DataInput";

const menuItems = [
  {
    id: 0,
    name: "General",
    // to: `/organization/${id}/org-details`, d
    icon: "bi bi-card-text",
  },
  // {
  //   id: 1,
  //   name: "Security",
  //   // to: `/organization/${id}/dashboards`,
  //   icon: "bi bi-key",
  // },
  {
    id: 2,
    name: "Email Preferences",
    // to: `/organization/${id}/email-preferences`,
    icon: "bi bi-envelope",
  },

  {
    id: 3,
    name: "Activity",
    // to: `/organization/${id}/nlp`,
    icon: "bi bi-lightbulb",
  },
  // {
  //   id: 4,
  //   name: "Permissions",
  //   // to: `/organization/${id}/nlp`,
  //   icon: "bi bi-person-lock",
  // },
];

export default function UserProfile({
  userId,
  onClose,
  modal,
  roles,
  canEdit,
  account,
  organization,
  refetch,
}) {
  const getUser = useFetchUser(userId);
  const fetchCustomFields = useFetchCustomFields();
  const [activeSig, setActiveSig] = useState();

  function onRefetch() {
    if (refetch) refetch();
    getUser.refetch();
  }

  function mainDisplay() {
    return (
      <>
        {(getUser.isLoading || fetchCustomFields.isLoading) && (
          <Loading></Loading>
        )}
        {getUser.isSuccess && fetchCustomFields.isSuccess && (
          <UserDisplay
            onClose={onClose}
            organization={organization}
            canEdit={canEdit}
            roles={roles}
            account={account}
            user={getUser?.data?.user}
            refetch={onRefetch}
            customFields={fetchCustomFields.data.fields}
          ></UserDisplay>
        )}
      </>
    );
  }

  return (
    <>
      {getUser.isError && (
        <ErrorBanner error={getUser.error} message="Error pulling up user" />
      )}
      {fetchCustomFields.isError && (
        <ErrorBanner
          error={fetchCustomFields.error}
          message="Error pulling up org fields"
        />
      )}
      {modal && (
        <ReactModal
          show
          onClose={onClose}
          modalStyle={{
            borderRadius: "1em",
            height: "fit-content",
            width: "95%",
            height: "100%",
            maxWidth: "1500px",
            backgroundColor: "#f9fbfc",
          }}
          dark
        >
          {mainDisplay()}
        </ReactModal>
      )}
      {activeSig && (
        <SigBuilder
          orig={activeSig}
          onClose={() => setActiveSig(null)}
          refetch={getUser.refetch}
          userId={userId}
        ></SigBuilder>
      )}
      {!modal && <>{mainDisplay()}</>}
    </>
  );
}

function UserDisplay({
  onClose,
  roles,
  canEdit,
  account,
  organization,
  user,
  setActiveSig,
  refetch,
  customFields,
}) {
  const [userState, setUserState] = useState(user);
  const [editImage, setEditImage] = useState(false);
  const [currRole, setCurrRole] = useState(getRole());
  const [editPswd, setEditPswd] = useState(false);
  const [changedRole, setChangedRole] = useState(false);
  const [active, setActive] = useState(0);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);

  const fetchMe = useGetCurrentUser();

  const orgRoles = useFetchOrgRoles();
  const changeRole = useChangeUserRole();
  const removeUser = useRemoveUserFromOrg();
  const updateUser = useUpdateUserById();

  function getOptions(options) {
    let response = [];
    if (options) {
      for (let option of options) {
        if (currRole?.id != option?.id) {
          response.push({
            value: option?.id,
            label: option?.name,
            color: option?.color,
          });
        }
      }
    }
    return response;
  }

  function getRole() {
    let role = roles?.find((role) => role?.organizationId === organization?.id);
    return role
      ? { value: role?.id, label: role?.name, color: role?.color }
      : { value: "", label: "" };
  }

  function handleRemoveUser() {
    if (
      window.confirm(
        `Are you sure you want to DELETE ${user.firstName} ${user.lastName} from your organization?`
      )
    ) {
      removeUser.mutate(
        {
          orgId: organization?.id,
          userId: user.id,
        },
        {
          onSuccess: () => {
            refetch();
            onClose();
          },
        }
      );
    }
  }

  function save() {
    if (!saving || saved) {
      updateUser.mutate(
        {
          data: {
            firstName: userState?.firstName,
            lastName: userState?.lastName,
            email: userState?.email,
          },
          id: user.id,
          attributeData: {
            attributes: userState.attribute,
          },
        },
        {
          onSuccess: (data) => {
            refetch();
            setSaving(false);
            setSaved(true);
            setTimeout(() => setSaved(false), 1000);
          },
        }
      );

      if (changedRole) {
        console.log("saving role");
        changeRole.mutate(
          {
            orgId: organization?.id,
            userId: user?.id,
            roleId: currRole?.value,
          },
          {
            onSuccess: (data) => {
              setChangedRole(false);
            },
          }
        );
      }

      setSaving(true);
    }
  }

  function handleAttributeChange(field, value, multiple, remove) {
    let copy = { ...userState };
    let attributes = [...copy.attribute];

    if (remove) {
      if (multiple) {
        let index = attributes.findIndex(
          (a) => a.customFieldId === field && a?.name === value?.name
        );
        if (index >= 0) attributes.splice(index, 1);
      } else {
        let index = attributes.findIndex((a) => a?.customFieldId === field);
        if (index >= 0) attributes.splice(index, 1);
      }
    } else {
      const newAtt = {
        id: "new",
        name: value,
        customFieldId: field,
      };
      if (multiple) {
        let hasItem = attributes.find(
          (a) => a.customFieldId === field && a?.name === value
        );
        if (!hasItem) {
          attributes.push(newAtt);
        }
      } else {
        let index = attributes.findIndex((a) => a?.customFieldId === field);
        if (index >= 0) {
          attributes[index] = newAtt;
        } else {
          attributes.push(newAtt);
        }
      }
    }

    copy.attribute = attributes;
    setUserState(copy);
  }

  function updateUserState(field, val) {
    let temp = { ...userState };
    temp[field] = val;
    setUserState(temp);
  }

  return (
    <>
      {orgRoles.isError && (
        <ErrorBanner
          error={orgRoles.error}
          message="Error pulling up org roles"
        />
      )}
      {changeRole.isError && (
        <ErrorBanner
          error={changeRole.error}
          message="Error pulling up org roles"
        />
      )}
      {removeUser.isError && (
        <ErrorBanner error={removeUser.error} message="Error removing user" />
      )}
      {updateUser.isError && (
        <ErrorBanner error={updateUser.error} message="Error updating user" />
      )}
      {fetchMe.isError && (
        <ErrorBanner
          error={fetchMe.error}
          message="Error loading your information"
        />
      )}
      {editPswd && (
        <ReactModal
          show={editPswd}
          onClose={() => setEditPswd(false)}
          modalStyle={{ borderRadius: "1em" }}
          dark
          // rightStyle
        >
          <ChangePassword currUser={user} close={() => setEditPswd(false)} />
        </ReactModal>
      )}

      <Top
        account={account}
        user={user}
        organization={organization}
        roles={roles}
      >
        <FlexRow justify={"space-between"}>
          <FlexRow wrap="wrap">
            {menuItems?.map((item) => (
              <div>
                <Button
                  active={active === item.id}
                  onClick={() => {
                    setActive(item.id);
                  }}
                >
                  {item.name}
                </Button>
              </div>
            ))}
          </FlexRow>
        </FlexRow>
      </Top>

      <div className={styles.page}>
        {active === 0 && (
          <div className={styles.pageSection}>
            <FlexCol
              style={{
                flex: "1",
                minWidth: "300px",
                height: "fit-content",
                maxWidth: "fit-content",
              }}
              gap="1rem"
            >
              <div className={styles.section}>
                <h4>Personal Information</h4>
                <HorizontalBar height={1}></HorizontalBar>

                <FlexRow
                  wrap="wrap"
                  style={{ marginTop: "1rem" }}
                  align={"flex-start"}
                  gap="1.5rem"
                >
                  <FlexCol fit gap="1rem">
                    <FlexRow style={{ flex: 1 }}>
                      <ImageEditor
                        src={user?.image?.imageURL}
                        canEdit={canEdit}
                        isUser={true}
                        userId={user?.id}
                        position={
                          user?.image?.position &&
                          typeof user?.image?.position === "string"
                            ? JSON.parse(user?.image?.position)
                            : user?.image?.position
                        }
                        image={user?.image}
                        // refetch={getUser.refetch}
                        setShow={setEditImage}
                        show={editImage}
                        height={80}
                        width={80}
                      ></ImageEditor>
                      <FlexCol fit>
                        <h5 style={{ whiteSpace: "nowrap" }}>
                          Profile Picture
                        </h5>
                        <small style={{ whiteSpace: "nowrap" }}>
                          PNG or JPG, under 15MB
                        </small>
                        <Button
                          onClick={() => setEditImage(true)}
                          style={{
                            gap: ".5rem",
                            whiteSpace: "nowrap",
                            flexWrap: "none",
                            display: "flex",
                          }}
                          shadow
                          width={"100px"}
                          link
                        >
                          Edit Picture <Icon blue className="bi-image"></Icon>
                        </Button>
                      </FlexCol>
                    </FlexRow>
                  </FlexCol>

                  <FlexCol style={{ flex: 1 }} gap="1rem">
                    <FlexRow wrap="wrap">
                      <TextFieldSimple
                        label="First Name"
                        placeholder={"First Name"}
                        value={userState?.firstName}
                        onChange={(val) => updateUserState("firstName", val)}
                        containerStyle={{ flex: 1, minWidth: "200px" }}
                        style={{ fontSize: ".8rem", fontWeight: "500" }}
                        shadow
                      />
                      <TextFieldSimple
                        label="Last Name"
                        placeholder={"Last Name"}
                        value={userState?.lastName}
                        onChange={(val) => updateUserState("lastName", val)}
                        style={{ fontSize: ".8rem", fontWeight: "500" }}
                        containerStyle={{ flex: 1, minWidth: "200px" }}
                        shadow
                      />
                    </FlexRow>
                    <TextFieldSimple
                      label="Email"
                      placeholder={"Email"}
                      value={userState?.email}
                      onChange={(val) => updateUserState("emailName", val)}
                      style={{ fontSize: ".8rem", fontWeight: "500" }}
                      shadow
                    />
                  </FlexCol>
                </FlexRow>
              </div>
              <div className={styles.section}>
                <FlexRow justify={"space-between"} wrap={"wrap"}>
                  {" "}
                  <FlexCol fit>
                    <h4>Fields</h4>
                    <div className={styles.label}>
                      {`Can map which contacts are in connection with ${
                        account ? "you" : "this user"
                      }.`}
                    </div>
                    {/* <div className={styles.label}>Useful in </div> */}
                  </FlexCol>
                </FlexRow>
                <HorizontalBar height={1}></HorizontalBar>
                {customFields
                  .filter((cf) => !cf.generic && cf.dataType === "list")
                  .map((cf) => (
                    <DataInput
                      label={cf.displayName}
                      value={
                        cf.multiple
                          ? userState?.attribute?.filter(
                              (attr) => attr?.customFieldId === cf.id
                            ) || []
                          : userState?.attribute?.find(
                              (attr) => attr.customFieldId === cf.id
                            )?.name || ""
                      }
                      onChange={(value, remove) =>
                        handleAttributeChange(cf.id, value, cf.multiple, remove)
                      }
                      shadow
                      dataType={cf.dataType}
                      icon={cf.icon}
                      id={cf.id}
                      multiple={cf.multiple}
                      generic={cf.generic}
                    ></DataInput>
                  ))}
              </div>
            </FlexCol>
            <FlexCol
              style={{ flex: "1", minWidth: "300px", height: "fit-content" }}
              gap="1rem"
            >
              {account && (
                <div className={styles.section}>
                  <FlexRow>
                    <h4>Account Security</h4>{" "}
                  </FlexRow>
                  <HorizontalBar height={1}></HorizontalBar>
                  <FlexCol gap="2rem">
                    <EditField
                      label="Change Password"
                      placeholder={"Password"}
                      value={"*************"}
                      onClickEdit={() => setEditPswd(true)}
                      disableInputOnly
                      shadow
                      anyWhereOptions
                    />
                    <FlexCol gap=".5rem">
                      <h4 style={{ marginLeft: ".2rem" }}>
                        Multi-Factor Authentication options
                      </h4>
                      <FlexCol className={styles.authsection}>
                        <FlexCol style={{ padding: "1rem 1rem" }}>
                          <FlexRow justify={"space-between"}>
                            <h4>Email</h4>
                            <ToggleSwitch
                              startChecked={userState?.requireMFA}
                              handleCheck={(val) =>
                                updateUserState("requireMFA", val)
                              }
                              disable={organization?.requireMFA}
                            />
                          </FlexRow>
                          <span>
                            {" "}
                            User your email to recieve security codes
                          </span>
                        </FlexCol>
                        <div className={styles.bottom}>
                          <FlexRow fit>
                            <Icon green iconName={"check-circle-fill"}></Icon>
                            <span>
                              Verified {trimDate(userState?.createdAt)}
                            </span>
                          </FlexRow>
                          {userState?.email}
                        </div>
                      </FlexCol>
                    </FlexCol>
                  </FlexCol>
                </div>
              )}
              <div className={styles.section}>
                <h4>System Access</h4>
                <HorizontalBar height={1}></HorizontalBar>
                <FlexRow style={{ pointerEvents: account ? "none" : "" }}>
                  <SelectFieldCustom
                    label="Current User Role"
                    placeholder={"Role"}
                    value={currRole}
                    select
                    options={
                      orgRoles.isSuccess ? getOptions(orgRoles.data.roles) : []
                    }
                    optionHelperText="Change"
                    onChange={(val) => {
                      setCurrRole(val);
                      setChangedRole(true);
                    }}
                    formatOptionLabel={(data) => (
                      <FlexRow justify={"space-between"}>
                        {data?.label}
                        <Status color={data?.color}>{data?.label}</Status>
                      </FlexRow>
                    )}
                    shadow
                  />
                </FlexRow>
              </div>

              <FlexRow gap={15} style={{ flexDirection: "row-reverse" }}>
                <Button width={100} shadow blue onClick={save}>
                  {saving ? (
                    <>
                      Saving
                      <span className={styles.dots}>
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                      </span>
                    </>
                  ) : saved ? (
                    <FlexRow fit gap={10}>
                      Saved <i className="bi-check"></i>
                    </FlexRow>
                  ) : (
                    <FlexRow fit gap={10}>
                      Save <i className="bi-floppy"></i>
                    </FlexRow>
                  )}
                </Button>
                {onClose && (
                  <Button shadow onClick={onClose} style={{ gap: "5px" }}>
                    Cancel <i className={"bi-x-lg"}></i>
                  </Button>
                )}
                {!account &&
                  fetchMe.isSuccess &&
                  fetchMe.data.me.id !== user.id && (
                    <Button
                      shadow
                      red
                      onClick={handleRemoveUser}
                      style={{ gap: "5px" }}
                    >
                      Remove User <i className={"bi-trash"}></i>
                    </Button>
                  )}
              </FlexRow>
            </FlexCol>
          </div>
        )}

        {active === 2 && (
          <div
            className={styles.pageSection}
            style={{ width: "100%", maxWidth: "664px" }}
          >
            <div
              className={styles.editImage}
              style={{
                width: "100%",
                maxWidth: "664px",
                height: "100vh",
              }}
            >
              <div className={styles.header_4}>Email Signatures</div>
              <UserSigs user={user} setActiveSig={setActiveSig}></UserSigs>
            </div>
          </div>
        )}
        {active === 3 && (
          <UserActivity user={user} orgId={organization?.id}></UserActivity>
        )}
      </div>
    </>
  );
}

function Top({ account, user, organization, roles, children }) {
  return (
    <>
      {account ? (
        <HeaderContainer
          path={"/" + user?.firstName + " " + user?.lastName}
          user={user}
          organization={organization}
          roles={roles}
        >
          {children}
        </HeaderContainer>
      ) : (
        <Header>
          <FlexCol gap={".75rem"}>
            <h3>{user?.firstName + " " + user?.lastName}</h3>
            {children}
          </FlexCol>
        </Header>
      )}
    </>
  );
}

function UserSigs({ user, setActiveSig }) {
  const fetchSigs = useFetchUserSigs(user.id);

  return (
    <>
      {fetchSigs.isLoading && <Loading />}
      {fetchSigs.isSuccess && (
        <>
          <div className={styles.sigs}>
            {fetchSigs.isSuccess &&
              fetchSigs.data.sigs.map((sig, i) => (
                <div className={styles.orgSig} key={sig.id}>
                  <div className={styles.sigTitle}>
                    <div className={styles.sigName}>
                      {`<${sig?.aliasName}> ${sig?.fromAddress}`}
                    </div>

                    {sig.signature && <div className={styles.html}>HTML</div>}
                    <Button
                      height={2.1}
                      options={[
                        {
                          data: (
                            <>
                              <Icon sapphire iconName={"pencil"}></Icon> Edit
                            </>
                          ),
                          onClick: () => setActiveSig(sig),
                        },
                        {
                          data: (
                            <>
                              <Icon red iconName={"trash"}></Icon> Delete
                            </>
                          ),
                          onClick: () => undefined,
                        },
                      ]}
                      optionHeight={40}
                    >
                      <Icon iconName={"three-dots-vertical"}></Icon>
                    </Button>
                  </div>

                  <SigPreview sig={sig} />
                </div>
              ))}
          </div>
          <Button
            className={styles.link}
            onClick={() => {
              setActiveSig({ id: "" });
            }}
            shadow
          >
            <i className="bi-plus"></i>
            Add Signature
          </Button>
        </>
      )}
    </>
  );
}
{
  /* <div className={styles.settingSection}>
  <div className={styles.headerHolder2}>
    <div style={{ maxWidth: "450px" }}>
      <div className={styles.header2}>Email Signatures</div>
      <div className={styles.description2}>
        This users email signatures. A user can have an infinite amount of
        signatures, but can only have one active at a time.
      </div>
    </div>
    <Button
      shadow
      onClick={() => {
        setActiveSig({
          signature: "",
          aliasName: "",
          fromAddress: "",
          type: "signature",
        });
        setShowSig(true);
      }}
    >
      Create Signature
    </Button>
  </div>

  <UserSignatures
    userId={user.id}
    show={showSig}
    setShow={setShowSig}
    sigs={user.distConfig}
    active={activeSig}
    setActive={setActiveSig}
    activeDistConfigId={user.activeDistConfigId}
  ></UserSignatures>
</div>; */
}
