/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import { useQueryClient } from "react-query";
import { gql } from "graphql-request";
import { useGqlQuery, useGqlMutation } from "api/Api";

const roleQuery = `
  id
  name
  description
  isAdmin
  organizationId
  dashboardId
  createdAt
  showDash
  color

  #Contacts
  canViewContacts
  canEditContacts
  canSeeContactInfo
  canViewOrgAudiences
  canCreateEditOrgAudiences

  #Surveys
  canCreateEditOrgSurveys
  canViewOrgSurveys

  #Dashboard
  canEditPersonalDashboard
  canViewPersonalDashboard
  canViewOrgDashboard

  #Analyze
  canViewOrgReports
  canCreateEditOrgReports
  canShareOrgReports    
  
  #Users
`;

export const useFetchOrgRoles = () => {
  const query = gql`
    query {
      roles: orgRoles {
        ${roleQuery}
      }
    }
  `;

  return useGqlQuery(["roles"], query, {});
};

export const useFetchRole = (key = "CurrentUserRole") => {
  const query = gql`
    query Query {
      role: getRole {
        ${roleQuery}
      }
    }
  `;

  return useGqlQuery([key], query, {});
};

export const useFetchQChartRoles = () => {
  const query = gql`
    query Query {
      role: getRole {
        ${roleQuery}
      }
    }
  `;

  return useGqlQuery(["qChartRoles"], query, {});
};

export const useCreateRoleGql = () => {
  const mutation = gql`
    mutation CreateRole($data: roleInput!) {
      role: createRole(data: $data) {
        ${roleQuery}
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("roles");
    },
  };

  return useGqlMutation(mutation, [], options);
};

export const useRefreshMyRole = () => {
  const mutation = gql`
    mutation Mutation {
      refreshMyRole
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("GetCurrentUserRoles");
      queryClient.invalidateQueries("CurrentUserRole");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useToggleRoleDash = () => {
  const mutation = gql`
    mutation ToggleRoleDash($roleId: String!, $val: Boolean!) {
      toggleRoleDash(roleId: $roleId, val: $val)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("roles");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateRoleGql = () => {
  const mutation = gql`
    mutation Mutation($data: roleInput!, $updateRoleId: String!) {
      role: updateRole(data: $data, id: $updateRoleId) {
        ${roleQuery}
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["roles"]);
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDeleteRoleGql = () => {
  const mutation = gql`
    mutation Mutation($deleteRoleId: String!) {
      deleteRole(id: $deleteRoleId)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries(["roles"]);
    },
  };

  return useGqlMutation(mutation, options);
};

export const useChangeUserRole = () => {
  const mutation = gql`
    mutation Mutation($orgId: String!, $roleId: String!, $userId: String!) {
      success: changeUserRole(orgId: $orgId, roleId: $roleId, userId: $userId)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries(["roles"]);
    },
  };

  return useGqlMutation(mutation, options);
};
