import React, { useCallback, useEffect, useState } from "react";
import styles from "./ConnectObjects.module.scss";
import {
  SelectField,
  SelectFieldCustom,
  TextFieldSimple,
} from "components/inputs";
import {
  linkedAccountStatusOptions,
  statusOptions,
} from "./SalesforceSettings";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import Icon from "components/Icon/Icon";
import Button from "components/Button/Button";
import axios from "axios";
import { Loading } from "components/Loading/Loading";
import {
  fileOperators,
  objectData,
  operators,
} from "assets/functions/Variables";
import {
  useCreateObjectConnection,
  useDeleteObjectConnection,
  useFetchContactCount,
  useFetchCurrOrgsFieldsMut,
  useFetchObjectConnection,
  useFetchOrganizationLinkedAccounts,
  useUpdateObjectConnection,
} from "api/resources/organization/organization";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchCalculations } from "api/resources/organization/calculations";
import { ErrorBanner } from "pages/error_page/ErrorPage";
import DataInput from "components/layouts/ObjectLayout/DataInput";
import { trimDate } from "assets/functions/DateFunctions";
import { useSearchAudiences } from "api/resources/contacts/audiences";
import AsyncSelect from "react-select/async";
import Select from "react-select/creatable";
import { useSearchSurveysStatic } from "api/resources/projects/projects";
import {
  useSearchQuestionsStatic,
  useSearchQuestionTagsStatic,
} from "api/resources/projects/questions";
import DoYouWantToSave from "components/Popout/DoYouWantToSave";
import { useSearchContactsCount } from "api/resources/contacts/contacts";

const reactionObjects = [
  { label: "Contact", value: "Contact" },
  // { label: "Survey", value: "Survey" },
  // { label: "Question", value: "Question" },
  // { label: "Answer", value: "Answer" },
  // { label: "Participation", value: "Participation" },
];

function ConnectObjects({ user, organization }) {
  const { id } = useParams();
  const MyOrg = useFetchOrganizationLinkedAccounts(organization.id);
  const object = useFetchObjectConnection(id ? id : "new");
  const fetchCalculations = useFetchCalculations();

  function refetch() {
    // object.remove();
    object.refetch();
  }
  return (
    <>
      {(MyOrg.isLoading || object.isLoading) && (
        <div style={{ width: "100%", height: "100vh" }}>
          <Loading></Loading>
        </div>
      )}
      {MyOrg.isSuccess && fetchCalculations.isSuccess && object.isSuccess && (
        <ConnectObjectsContent
          user={user}
          organization={organization}
          linkedAccountId={
            MyOrg?.data?.organization?.linkedAccounts?.find(
              (a) => a.accountType === "Salesforce"
            )?.id
          }
          object={object?.data?.object}
          calculations={fetchCalculations.data.calculations}
          refetch={refetch}
        ></ConnectObjectsContent>
      )}
    </>
  );
}

function ConnectObjectsContent({
  user,
  organization,
  linkedAccountId,
  object,
  calculations,
  refetch,
}) {
  const [objectSettings, setObjectSettings] = useState({
    matchFields: object?.matchFields ? JSON.parse(object.matchFields) : [{}],
    id: object?.id,
    connectionStatus: object?.connectionStatus,
    updateType: object?.updateType,
    updateFields: object?.updateFields
      ? JSON.parse(object.updateFields)
      : [{ calculation: "None" }],
    sendExistingData: object?.sendExistingData,
    createNewData: object?.createNewData,
    linkedAccountId: linkedAccountId,
    description: object?.description,
    updater: false,
    internalField: object?.internalField,
    externalField: object?.externalField,
    internalName: object?.internalName,
    externalName: object?.externalName,
    filterFields: object?.filterFields ? JSON.parse(object.filterFields) : [],
    portion: object?.portion,
    answersOnly: object?.answersOnly,
    updateBlank: object?.updateBlank,
    searchReferenceName: object?.searchReferenceName,
  });
  const [loading, setLoading] = useState(true);
  const [salesforceObjects, setSalesforceObjects] = useState([]);
  const [objectFields, setObjectFields] = useState([]);
  const [objectFieldsReaction, setObjectFieldsReaction] = useState([]);
  const [loadingFields, setLoadingFields] = useState(false);
  const [error, setError] = useState("");
  const [saving, setSaving] = useState(false);
  const [change, setChange] = useState(false);
  const [confirmPush, setConfirmPush] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [calculationOptions] = useState(() => {
    let calcs = [{ label: "None", value: "None" }];
    let temp = calculations.map((c) => {
      return { value: c.id, label: c.name + ` (${c.type})` };
    });
    return [...calcs, ...temp];
  });

  const getCount = useSearchContactsCount(
    "",
    0,
    { descend: true, item: "email" },
    "",
    "[]",
    JSON.stringify(objectSettings?.filterFields)
  );
  console.log(getCount?.data?.response);

  const handleLoginSalesforce = async () => {
    try {
      const url = `${process.env.REACT_APP_SALESFORCE_KEY2}&userID=${user?.id}`;
      const response = await axios.get(url, { withCredentials: true });
      window.location.href = response.data.loginUrl;
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  useEffect(() => {
    if (objectSettings?.internalName) {
      setReactionObjectFields(objectSettings.internalName);
    }
    if (objectSettings?.externalName) {
      getObjectFields(objectSettings?.externalName);
    }
  }, []);

  useEffect(() => {
    setLoading(true);

    let url = process.env.REACT_APP_SALESFORCE_OBJECTS;
    const config = {
      withCredentials: true,
      headers: {
        accept: "application/json",
      },
      params: {
        organization_id: organization.id,
        user_id: user.id,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.objects && response?.data?.objects?.length > 0) {
          setSalesforceObjects(response?.data?.objects);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.status === 401) {
          setSessionExpired(true);
          setTimeout(() => {
            handleLoginSalesforce();
          }, 1000);
        }
      });
  }, []);

  function getObjectFields(name) {
    setLoadingFields(true);
    let url = process.env.REACT_APP_SALESFORCE_OBJECT_FIELDS;
    const config = {
      withCredentials: true,
      headers: {
        accept: "application/json",
      },
      params: {
        organization_id: organization.id,
        user_id: user.id,
        name: name,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.fields && response?.data?.fields?.length > 0) {
          setObjectFields(response?.data?.fields);
        }
        setLoadingFields(false);
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.status === 401) {
          setSessionExpired(true);
          setTimeout(() => {
            handleLoginSalesforce();
          }, 1000);
        } else {
          setError(error?.response?.message);
        }
      });
  }

  const getContactFields = useFetchCurrOrgsFieldsMut();

  function setReactionObjectFields(name) {
    if (name === "Contact") {
      getContactFields.mutate(
        {},
        {
          onSuccess: (data) => {
            if (data?.fields && data?.fields?.length > 0) {
              const transformedArray = data?.fields.map((item) => ({
                value: item.name,
                label: item.displayName + ` (${item.dataType})`,
                id: item.id,
                dataType: item.dataType,
              }));
              setObjectFieldsReaction(transformedArray);
            }
          },
        }
      );
      return;
    }
    setObjectFieldsReaction(objectData[name]);
  }

  const updateObjectMutation = useUpdateObjectConnection();
  const createObjectMutation = useCreateObjectConnection();
  const deleteObjectMutation = useDeleteObjectConnection();

  function saveObjectSettings() {
    setSaving(true);
    let finalSettings = {
      ...objectSettings,
    };

    if (objectSettings?.id != "new") {
      finalSettings.updateFields = JSON.stringify(finalSettings.updateFields);
      finalSettings.matchFields = JSON.stringify(finalSettings.matchFields);
      finalSettings.filterFields = JSON.stringify(finalSettings.filterFields);
      const id = `${finalSettings.id}`;
      delete finalSettings.id;

      updateObjectMutation.mutate(
        {
          data: finalSettings,
          id: id,
        },
        {
          onSuccess: () => {
            setSaving(false);
            setChange(false);
            refetch();
          },
        }
      );
    } else {
      finalSettings.updateFields = JSON.stringify(finalSettings.updateFields);
      finalSettings.matchFields = JSON.stringify(finalSettings.matchFields);
      finalSettings.filterFields = JSON.stringify(finalSettings.filterFields);
      delete finalSettings.id;

      createObjectMutation.mutate(
        {
          data: finalSettings,
        },
        {
          onSuccess: (data) => {
            setSaving(false);
            navigate(
              "/organization/" +
                organization?.id +
                "/Salesforce/ConnectObjects/" +
                data?.response?.id
            );
            refetch();
          },
        }
      );
    }
  }

  function deleteObject() {
    deleteObjectMutation.mutate(
      {
        id: objectSettings?.id,
      },
      {
        onSuccess: () => {
          navigate("/organization/" + organization?.id + "/Salesforce");
        },
      }
    );
  }

  const navigate = useNavigate();

  function pushData(object) {
    if (objectSettings?.connectionStatus === "active") {
      setObjectSettings({
        ...objectSettings,
        connectionStatus: "pushing",
        portion: "Starting data push.",
      });
      let url = process.env.REACT_APP_SALESFORCE_SYNC_QUERY;
      const config = {
        withCredentials: true,
        headers: {
          accept: "application/json",
        },
        params: {
          organization_id: organization.id,
          user_id: user.id,
        },
      };

      let finalData = { ...object };
      finalData.matchFields = finalData.matchFields
        ? finalData.matchFields
        : [];
      finalData.updateFields = finalData.updateFields
        ? finalData.updateFields
        : [];
      const formData = new FormData();
      formData.append("objectSettings", JSON.stringify(finalData));

      axios
        .post(url, formData, config)
        .then((response) => {
          if (
            response?.status === 200 &&
            response?.data?.message === "success"
          ) {
            setObjectSettings({
              ...objectSettings,
              connectionStatus: "active",
            });
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            setSessionExpired(true);
            setTimeout(() => {
              handleLoginSalesforce();
            }, 1000);
          } else {
            setError(error);
            refetch();
          }
        });
    }
  }

  // console.log(objectFields)

  return (
    <div className={styles.page}>
      {confirmPush && (
        <DoYouWantToSave
          navAway={() => setConfirmPush(false)}
          onSave={() => pushData(objectSettings)}
          confirmText={"Push Data"}
          cancelText={"Cancel"}
          header={"Initiate Data Push"}
          prompt={
            "Are you sure you want to push data from Reaction over to Salesforce? Only the data specified in your connection settings will attempt to push."
          }
          showX
          style={{ maxWidth: "550px" }}
        ></DoYouWantToSave>
      )}
      {confirmDelete && (
        <DoYouWantToSave
          navAway={() => setConfirmDelete(false)}
          onSave={() => deleteObject()}
          confirmText={"Delete"}
          cancelText={"Cancel"}
          header={"Delete Connection"}
          prompt={
            "Are you sure you want to delete this connection? This action cannot be undone."
          }
          showX
          style={{ maxWidth: "550px" }}
        ></DoYouWantToSave>
      )}

      {error && (
        <ErrorBanner
          error={error}
          message={error?.response?.data?.detail}
        ></ErrorBanner>
      )}
      {loading && (
        <div style={{ height: "100vh" }}>
          <Loading></Loading>
        </div>
      )}
      {!loading && (
        <>
          <FlexCol gap="1rem">
            <div
              className={`${styles.header_3} `}
              style={{ fontWeight: "400", color: "black", margin: "0" }}
            >
              {object?.id === "new"
                ? "New Object Connection Setup"
                : "Object Connection Setup"}
            </div>
            <FlexRow justify={"space-between"} style={{ marginBottom: "1rem" }}>
              <FlexRow>
                <Button
                  shadow
                  style={{ gap: ".5rem" }}
                  onClick={() =>
                    navigate(
                      "/organization/" + organization?.id + "/Salesforce"
                    )
                  }
                  width={100}
                >
                  <Icon green iconName={"arrow-left-circle"}></Icon>Go Back
                </Button>{" "}
                {object?.id && (
                  <Button
                    shadow
                    style={{ gap: ".5rem", whiteSpace: "nowrap" }}
                    onClick={() => setConfirmPush(true)}
                    disable={
                      objectSettings?.connectionStatus === "pushing" ||
                      object?.id === "new"
                    }
                    width={120}
                  >
                    <Icon sapphire iconName={"box-arrow-right"}></Icon>Push Data
                  </Button>
                )}
              </FlexRow>
              <Button
                blue
                shadow
                width={100}
                disable={
                  !objectSettings?.externalName ||
                  !objectSettings?.internalName ||
                  !objectSettings?.matchFields[0]?.externalField ||
                  !objectSettings?.matchFields[0]?.internalField ||
                  // !objectSettings?.updateFields[0]?.internalField ||
                  !objectSettings?.updateFields[0]?.externalField ||
                  objectSettings?.connectionStatus === "pushing" ||
                  !change
                }
                onClick={() => saveObjectSettings()}
              >
                {saving ? "Saving..." : "Save"}
              </Button>
            </FlexRow>
            {objectSettings?.portion === "Finished" &&
              objectSettings?.connectionStatus === "active" && (
                <FlexRow>
                  Last Push: {trimDate(object?.lastPush, true)}; Updated:{" "}
                  {object?.updatedCount}; Created:{" "}
                  {!object?.createdCount ? 0 : object?.createdCount}
                </FlexRow>
              )}
            {objectSettings?.portion === "Canceled" &&
              objectSettings?.connectionStatus === "active" && (
                <FlexRow>Last Push: Canceled</FlexRow>
              )}
            {objectSettings?.connectionStatus === "pushing" && (
              <FlexCol gap=".5rem">
                {objectSettings?.portion}{" "}
                <div className={styles.runningprocess}>
                  <div className={styles.runningbar}></div>
                </div>
              </FlexCol>
            )}
            <HorizontalBar width={"100%"} height={2}></HorizontalBar>
          </FlexCol>
          <FlexCol gap={"1rem"} style={{ maxWidth: "500px" }}>
            <FlexCol>
              <div className={`${styles.header_4} `} style={{ margin: "0" }}>
                Settings
              </div>
              <div
                className={`${styles.text_3} `}
                style={{ marginBottom: "1rem" }}
              >
                These settings apply to both matching details and field updates
                in this connection
              </div>
            </FlexCol>
            <SelectFieldCustom
              label={
                objectSettings?.id === "new"
                  ? "Initial Object Connection Status"
                  : "Connection Status"
              }
              options={
                objectSettings?.connectionStatus != "pushing"
                  ? linkedAccountStatusOptions
                  : []
              }
              style={{ maxWidth: "250px" }}
              value={statusOptions.find(
                (v) => v.value === objectSettings?.connectionStatus
              )}
              onChange={(v) => {
                if (objectSettings?.connectionStatus != "pushing") {
                  setObjectSettings(() => {
                    return { ...objectSettings, connectionStatus: v.value };
                  });
                  setChange(true);
                }
              }}
              noCreate
            ></SelectFieldCustom>
            <div>
              <SelectFieldCustom
                options={salesforceObjects}
                label={
                  <span>
                    Which <span style={{ fontWeight: "600" }}>Salesforce</span>{" "}
                    Object do you want to connect?
                  </span>
                }
                searchable
                value={salesforceObjects.find(
                  (v) => v.value === objectSettings?.externalName
                )}
                onChange={(v) => {
                  setObjectSettings(() => {
                    return { ...objectSettings, externalName: v.value };
                  });
                  getObjectFields(v.value);
                  setChange(true);
                }}
                noCreate
                dropdownStyle={{ top: "4.3rem" }}
              ></SelectFieldCustom>
            </div>
            <div>
              <SelectFieldCustom
                options={reactionObjects}
                label={
                  <span>
                    Which <span style={{ fontWeight: "600" }}>Reaction</span>{" "}
                    Object do you want to connect?
                  </span>
                }
                searchable
                value={reactionObjects.find(
                  (v) => v.value === objectSettings?.internalName
                )}
                onChange={(v) => {
                  setObjectSettings(() => {
                    return { ...objectSettings, internalName: v.value };
                  });
                  setReactionObjectFields(v.value);
                  setChange(true);
                }}
                dropdownStyle={{ top: "4.3rem" }}
                noCreate
              ></SelectFieldCustom>
            </div>
            <div>
              <TextFieldSimple
                label={"Updates Name Description"}
                className={styles.textarea}
                onChange={(v) => {
                  setObjectSettings(() => {
                    return { ...objectSettings, description: v };
                  });
                  setChange(true);
                }}
                value={objectSettings?.description}
                placeholder="Updates Name Description"
                style={{ fontSize: ".8rem" }}
              ></TextFieldSimple>
            </div>

            {/* {objectSettings?.id === "new" && (
              <FlexRow style={{ marginTop: "1rem" }}>
                <Checkbox
                  checked={objectSettings?.sendExistingData}
                  onChange={(e) =>
                    setObjectSettings(() => {
                      return {
                        ...objectSettings,
                        sendExistingData: e.target.checked,
                      };
                    })
                  }
                ></Checkbox>
                <div className={styles.text_2}>
                  Send Existing Reaction Object Data into Salesforce
                </div>
              </FlexRow>
            )} */}

            {/* {objectSettings?.internalName === "Contact" && (
              <FlexRow>
                <Checkbox
                  checked={objectSettings?.answersOnly}
                  onChange={(e) =>
                    setObjectSettings(() => {
                      return {
                        ...objectSettings,
                        answersOnly: e.target.checked,
                      };
                    })
                  }
                ></Checkbox>{" "}
                <div className={styles.text_2}>
                  Only attempt to update contacts with answers
                </div>
              </FlexRow>
            )} */}
          </FlexCol>
          <HorizontalBar width={"100%"} height={2}></HorizontalBar>
          <FlexCol gap={"1rem"}>
            <div
              className={`${styles.header_3} `}
              style={{ fontWeight: "400", color: "black", margin: "0" }}
            >
              Matching Details
            </div>
            <div className={`${styles.text_2} `}>
              Choose which Reaction and Salesforce fields are used to match the
              objects together. This allows the system to know what to update.
            </div>
            <FlexRow>
              <Checkbox
                checked={objectSettings?.createNewData}
                onChange={(e) => {
                  setObjectSettings(() => {
                    return {
                      ...objectSettings,
                      createNewData: e.target.checked,
                    };
                  });
                  setChange(true);
                }}
              ></Checkbox>{" "}
              <div className={styles.text_2}>
                Create New record if no matches found
              </div>
            </FlexRow>
            {loadingFields && <Loading></Loading>}
            {!loadingFields && (
              <ObjectMatching
                sfObjectFields={objectFields}
                reactionObjectFields={objectFieldsReaction}
                objectSettings={objectSettings}
                setObjectSettings={(set) => {
                  setObjectSettings(set);
                  setChange(true);
                }}
              ></ObjectMatching>
            )}
          </FlexCol>
          <HorizontalBar width={"100%"} height={2}></HorizontalBar>
          <FlexCol gap={"1rem"}>
            <div
              className={`${styles.header_3} `}
              style={{ fontWeight: "400", color: "black", margin: "0" }}
            >
              Choose Updates
            </div>
            <div className={`${styles.text_2} `}>
              Choose which Reaction and Salesforce fields are going to be
              updated and map them accordingly. The fields mapped are the only
              fields that will be updated.
            </div>
            <FlexRow>
              <Checkbox
                checked={objectSettings?.updateBlank}
                onChange={(e) => {
                  setObjectSettings(() => {
                    return {
                      ...objectSettings,
                      updateBlank: e.target.checked,
                    };
                  });
                  setChange(true);
                }}
              ></Checkbox>{" "}
              <div className={styles.text_2}>
                Update mapped field to blank when no data found. (boolean to
                false)
              </div>
            </FlexRow>
            <FlexRow>
              <Checkbox
                checked={objectSettings?.searchReferenceName}
                onChange={(e) => {
                  setObjectSettings(() => {
                    return {
                      ...objectSettings,
                      searchReferenceName: e.target.checked,
                    };
                  });
                  setChange(true);
                }}
              ></Checkbox>{" "}
              <div className={styles.text_2}>
                Search (reference) field Name instead of ID.
              </div>
            </FlexRow>
            {loadingFields && <Loading></Loading>}
            {!loadingFields && (
              <FieldMatching
                sfObjectFields={objectFields}
                reactionObjectFields={objectFieldsReaction}
                objectSettings={objectSettings}
                setObjectSettings={(set) => {
                  setObjectSettings(set);
                  setChange(true);
                }}
                calculationOptions={calculationOptions}
              ></FieldMatching>
            )}
            <HorizontalBar width={"100%"} height={2}></HorizontalBar>
            <FlexCol gap={"1rem"}>
              <div
                className={`${styles.header_3} `}
                style={{ fontWeight: "400", color: "black", margin: "0" }}
              >
                Filter Updates
              </div>
              <div className={`${styles.text_2} `}>
                Filter out records that you do not want the system to attempt to
                update. This can speed up the updating process. Reaction will
                only attempt to update contacts that fulfill this filter.
              </div>
              {loadingFields && <Loading></Loading>}
              {!loadingFields && (
                <FieldFiltering
                  reactionObjectFields={objectFieldsReaction}
                  objectSettings={objectSettings}
                  setObjectSettings={(set) => {
                    setObjectSettings(set);
                    setChange(true);
                  }}
                  reactionObjects={reactionObjects}
                ></FieldFiltering>
              )}
            </FlexCol>
            <HorizontalBar width={"100%"} height={2}></HorizontalBar>
            <div
              className={`${styles.header_4} `}
              style={{
                fontWeight: "400",
                color: "black",
                margin: "0",
                marginTop: "2rem",
                padding: "0 1rem",
              }}
            >
              How often do you want Reaction to send field updates?
            </div>
            <FlexRow
              style={{
                flexWrap: "wrap",
                marginTop: "2rem",
                gap: "2rem",
              }}
            >
              <div
                className={`${styles.box} ${
                  objectSettings?.updateType === "manually" && styles.boxfill
                }`}
                onClick={() => {
                  setObjectSettings(() => {
                    return { ...objectSettings, updateType: "manually" };
                  });
                  setChange(true);
                }}
              >
                <div className={`${styles.square}`}>
                  <i className={"bi-caret-right-square"}></i>
                </div>
                <div
                  className={styles.text_2}
                  style={{ margin: "0", fontWeight: "400" }}
                >
                  Manually
                </div>
                <div
                  className={styles.text_3}
                  style={{ margin: "0", fontWeight: "400" }}
                >
                  On button click
                </div>
              </div>
              <div
                className={`${styles.box} ${
                  objectSettings?.updateType === "automatically" &&
                  styles.boxfill
                }`}
                onClick={() => {
                  // setObjectSettings(() => {
                  //   return { ...objectSettings, updateType: "automatically" };
                  // });
                }}
              >
                <div
                  className={`${styles.square}`}
                  // style={{ border: "4px dotted #a3a4a8" }}
                >
                  <i
                    className={"bi-arrow-repeat"}
                    style={{ fontSize: "4.8rem" }}
                  ></i>
                </div>
                <div
                  className={styles.text_2}
                  style={{ margin: "0", fontWeight: "400" }}
                >
                  Automatically
                </div>
                <div
                  className={styles.text_3}
                  style={{ margin: "0", fontWeight: "400" }}
                >
                  (Coming Soon)
                </div>
              </div>

              <div
                className={`${styles.box} ${
                  objectSettings?.updateType === "schedule" && styles.boxfill
                }`}
                onClick={() => {
                  // setObjectSettings(() => {
                  //     return { ...objectSettings, updateType: "ma" };
                  //   });
                }}
              >
                <div className={`${styles.square}`}>
                  <i className={"bi-calendar"}></i>
                </div>
                <div
                  className={styles.text_2}
                  style={{ margin: "0", fontWeight: "400" }}
                >
                  Schedule
                </div>
                <div
                  className={styles.text_3}
                  style={{ margin: "0", fontWeight: "400" }}
                >
                  (Coming Soon)
                </div>
              </div>
            </FlexRow>
          </FlexCol>
          <HorizontalBar width={"100%"} height={2}></HorizontalBar>
          <FlexRow justify={"space-between"}>
            <FlexRow fit>
              <Button
                shadow
                style={{ gap: ".5rem" }}
                onClick={() =>
                  navigate("/organization/" + organization?.id + "/Salesforce")
                }
                width={100}
              >
                <Icon green iconName={"arrow-left-circle"}></Icon>Go Back
              </Button>{" "}
              <Button
                blue
                shadow
                width={100}
                disable={
                  !objectSettings?.externalName ||
                  !objectSettings?.internalName ||
                  !objectSettings?.matchFields[0]?.externalField ||
                  !objectSettings?.matchFields[0]?.internalField ||
                  // !objectSettings?.updateFields[0]?.internalField ||
                  !objectSettings?.updateFields[0]?.externalField ||
                  objectSettings?.connectionStatus === "pushing" ||
                  !change
                }
                onClick={() => saveObjectSettings()}
              >
                {saving ? "Saving..." : "Save"}
              </Button>
            </FlexRow>
            {objectSettings?.id != "new" && (
              <Button
                shadow
                style={{ gap: ".5rem", whiteSpace: "nowrap" }}
                onClick={() => setConfirmDelete(true)}
                disable={objectSettings?.connectionStatus === "pushing"}
              >
                <Icon red iconName={"trash"}></Icon> Delete Connection
              </Button>
            )}
          </FlexRow>
        </>
      )}
    </div>
  );
}

export default ConnectObjects;

function ObjectMatching({
  sfObjectFields,
  reactionObjectFields,
  objectSettings,
  setObjectSettings,
}) {
  return (
    <>
      {" "}
      <table
        className={styles.maptable}
        style={{ marginBottom: "1rem", marginTop: "1rem" }}
      >
        <thead>
          <tr>
            <th>Reaction Field</th>
            <th></th>
            <th>Salesforce Field</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {objectSettings?.matchFields?.map((match, index) => (
            <tr key={index}>
              <td style={{ width: "300px" }} key={index}>
                <div>
                  <SelectFieldCustom
                    value={
                      match?.internalField
                        ? reactionObjectFields.find(
                            (f) => f.value === match?.internalField
                          )
                        : undefined
                    }
                    onChange={(val) => {
                      let objectSetTemp = { ...objectSettings };
                      let matchField = { ...objectSetTemp.matchFields[index] };
                      matchField.internalField = val.value;
                      objectSetTemp.matchFields[index] = matchField;
                      setObjectSettings(objectSetTemp);
                    }}
                    searchable
                    icon={<Icon blue iconName={"database"}></Icon>}
                    noCreate
                    options={reactionObjectFields}
                    dropdownStyle={{ top: "2.9rem" }}
                  ></SelectFieldCustom>
                </div>
              </td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i
                  className="bi-arrow-right"
                  style={{ fontSize: "1.1rem" }}
                ></i>
              </td>
              <td style={{ width: "300px" }}>
                <div>
                  {" "}
                  <SelectFieldCustom
                    value={
                      match?.externalField
                        ? sfObjectFields.find(
                            (f) => f.value === match?.externalField
                          )
                        : undefined
                    }
                    onChange={(val) => {
                      let objectSetTemp = { ...objectSettings };
                      let matchField = { ...objectSetTemp.matchFields[index] };
                      matchField.externalField = val.value;
                      objectSetTemp.matchFields[index] = matchField;
                      setObjectSettings(objectSetTemp);
                    }}
                    icon={<Icon blue iconName={"database"}></Icon>}
                    noCreate
                    options={sfObjectFields}
                    searchable
                    dropdownStyle={{ top: "2.9rem" }}
                  ></SelectFieldCustom>
                </div>
              </td>
              <td
                style={{
                  width: "fit-content",
                }}
              >
                <Button
                  shadow
                  onClick={() => {
                    let objectSetTemp = { ...objectSettings };
                    objectSetTemp?.matchFields?.splice(index, 1);
                    setObjectSettings(objectSetTemp);
                  }}
                >
                  Remove
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Button
        shadow
        onClick={() => {
          let objectSetTemp = { ...objectSettings };
          objectSetTemp?.matchFields?.push({});
          setObjectSettings(objectSetTemp);
        }}
      >
        + Add Field
      </Button>
    </>
  );
}

function FieldMatching({
  sfObjectFields,
  reactionObjectFields,
  objectSettings,
  setObjectSettings,
  calculationOptions,
}) {
  function handleChangeAnswerType(val, updateRow, index) {
    let objectSetTemp = { ...objectSettings };
    let updateField = { ...updateRow };
    updateField.answerType = val;
    updateField.answer = "";
    delete updateField?.survey;
    delete updateField?.question;
    delete updateField?.questionTag;
    delete updateField?.date;
    objectSetTemp.updateFields[index] = updateField;
    setObjectSettings(objectSetTemp);
  }
  return (
    <>
      <table
        className={styles.maptable}
        style={{ marginBottom: "1rem", marginTop: "1rem" }}
      >
        <thead>
          <tr>
            <th>Reaction Field</th>
            <th></th>
            <th>Salesforce Field</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {objectSettings?.updateFields?.map((updateRow, index) => (
            <tr key={index}>
              <td style={{ width: "300px" }}>
                <div>
                  {!("calculation" in updateRow) &&
                    !("answer" in updateRow) && (
                      <SelectFieldCustom
                        value={
                          updateRow?.internalField
                            ? reactionObjectFields.find(
                                (f) => f.value === updateRow?.internalField
                              )
                            : undefined
                        }
                        onChange={(val) => {
                          let objectSetTemp = { ...objectSettings };
                          let updateField = { ...updateRow };
                          updateField.internalField = val.value;
                          objectSetTemp.updateFields[index] = updateField;
                          setObjectSettings(objectSetTemp);
                        }}
                        icon={<Icon blue iconName={"database"}></Icon>}
                        options={reactionObjectFields}
                      />
                    )}
                  {"calculation" in updateRow && (
                    <SelectFieldCustom
                      value={
                        updateRow?.internalField
                          ? calculationOptions.find(
                              (f) => f.value === updateRow?.internalField
                            )
                          : undefined
                      }
                      onChange={(val) => {
                        let objectSetTemp = { ...objectSettings };
                        let updateField = { ...updateRow };
                        updateField.internalField = val.value;
                        updateField.calculation = val.value;
                        objectSetTemp.updateFields[index] = updateField;
                        setObjectSettings(objectSetTemp);
                      }}
                      icon={<Icon sapphire iconName={"calculator"}></Icon>}
                      options={calculationOptions}
                    />
                  )}
                  {"answer" in updateRow && (
                    <FlexCol gap=".5rem">
                      {updateRow.answerType != "QuestionTag" && (
                        <SurveyInput
                          value={updateRow?.survey}
                          onChange={(val) => {
                            let objectSetTemp = { ...objectSettings };
                            let updateField = { ...updateRow };
                            updateField.survey = val;
                            updateField.question = "";
                            objectSetTemp.updateFields[index] = updateField;
                            setObjectSettings(objectSetTemp);
                          }}
                        ></SurveyInput>
                      )}

                      <FlexRow gap={".5rem"}>
                        <Button
                          shadow
                          options={[
                            {
                              onClick: () => {
                                handleChangeAnswerType(
                                  "QuestionAnswer",
                                  updateRow,
                                  index
                                );
                              },
                              data: (
                                <>
                                  {" "}
                                  <Icon green iconName={"check-circle"}></Icon>
                                  Question Answer
                                </>
                              ),
                            },
                            {
                              onClick: () => {
                                handleChangeAnswerType(
                                  "ParticipationStats",
                                  updateRow,
                                  index
                                );
                              },
                              data: (
                                <>
                                  {" "}
                                  <Icon
                                    seafoam
                                    iconName={"person-video2"}
                                  ></Icon>
                                  Participation Stats
                                </>
                              ),
                            },
                            {
                              onClick: () => {
                                handleChangeAnswerType(
                                  "QuestionTag",
                                  updateRow,
                                  index
                                );
                              },
                              data: (
                                <>
                                  {" "}
                                  <Icon iconName={"tags"}></Icon>Question Tag
                                </>
                              ),
                            },
                          ]}
                          height={2.4}
                          width={"2.4rem"}
                          optionStyle={{ left: "0", width: "fit-content" }}
                        >
                          <Icon iconName={"gear"}></Icon>
                        </Button>

                        {updateRow.answerType === "QuestionAnswer" && (
                          <>
                            <QuestionInput
                              value={updateRow?.question}
                              onChange={(val) => {
                                let objectSetTemp = { ...objectSettings };
                                let updateField = { ...updateRow };
                                updateField.question = val;
                                objectSetTemp.updateFields[index] = updateField;
                                setObjectSettings(objectSetTemp);
                              }}
                              surveyId={
                                updateRow?.survey?.value
                                  ? updateRow?.survey?.value
                                  : ""
                              }
                              placeholder={"Question..."}
                            />
                            <SelectFieldCustom
                              value={updateRow?.answer}
                              onChange={(val) => {
                                let objectSetTemp = { ...objectSettings };
                                let updateField = { ...updateRow };
                                updateField.answer = val;
                                objectSetTemp.updateFields[index] = updateField;
                                setObjectSettings(objectSetTemp);
                              }}
                              icon={
                                <Icon green iconName={"check-circle"}></Icon>
                              }
                              options={[
                                { value: "createdAt", label: "Answer Date" },
                                { value: "answer", label: "Answer" },
                              ]}
                              placeholder={"Answer..."}
                            />
                          </>
                        )}
                        {updateRow.answerType === "ParticipationStats" && (
                          <>
                            <SelectFieldCustom
                              value={updateRow?.answer}
                              onChange={(val) => {
                                let objectSetTemp = { ...objectSettings };
                                let updateField = { ...updateRow };
                                updateField.answer = val;
                                objectSetTemp.updateFields[index] = updateField;
                                setObjectSettings(objectSetTemp);
                              }}
                              icon={
                                <Icon seafoam iconName={"person-video2"}></Icon>
                              }
                              options={[
                                {
                                  value: "finishedAt",
                                  label: "Date Finished (date/time)",
                                },
                                {
                                  value: "startedAt",
                                  label: "Date Started (date/time)",
                                },
                                { value: "link", label: "Survey Link (text)" },
                                {
                                  value: "started",
                                  label: "Started (boolean)",
                                },
                                {
                                  value: "completed",
                                  label: "Completed (boolean)",
                                },
                                {
                                  value: "abandoned",
                                  label: "Abandoned (boolean)",
                                },
                              ]}
                              placeholder={"Participation Survey Stat..."}
                            />
                            {(updateRow?.answer?.value === "startedAt" ||
                              updateRow?.answer?.value === "finishedAt") && (
                              <SelectFieldCustom
                                value={updateRow?.date}
                                onChange={(val) => {
                                  let objectSetTemp = { ...objectSettings };
                                  let updateField = { ...updateRow };
                                  updateField.date = val;
                                  objectSetTemp.updateFields[index] =
                                    updateField;
                                  setObjectSettings(objectSetTemp);
                                }}
                                icon={<Icon seafoam iconName={"clock"}></Icon>}
                                options={[
                                  { value: "MM/DD/YYYY", label: "MM/DD/YYYY" },
                                  { value: "DD/MM/YYYY", label: "DD/MM/YYYY" },
                                  {
                                    value: "Day MM DD, YYYY",
                                    label: "Day MM DD, YYYY",
                                  },
                                  {
                                    value: "Day MM DD, YYYY at TT:TThh",
                                    label: "Day MM DD, YYYY at TT:TThh",
                                  },
                                ]}
                                placeholder={"Date Display..."}
                              />
                            )}
                          </>
                        )}
                        {updateRow.answerType === "QuestionTag" && (
                          <>
                            <QuestionTagInput
                              value={updateRow?.questionTag}
                              onChange={(val) => {
                                let objectSetTemp = { ...objectSettings };
                                let updateField = { ...updateRow };
                                updateField.questionTag = val;
                                objectSetTemp.updateFields[index] = updateField;
                                setObjectSettings(objectSetTemp);
                              }}
                            />
                            <SelectFieldCustom
                              value={updateRow?.answer}
                              onChange={(val) => {
                                let objectSetTemp = { ...objectSettings };
                                let updateField = { ...updateRow };
                                updateField.answer = val;
                                objectSetTemp.updateFields[index] = updateField;
                                setObjectSettings(objectSetTemp);
                              }}
                              options={[
                                { value: "MostRecent", label: "Most Recent" },
                                {
                                  value: "MostRecentAnswered",
                                  label: "Most Recent Answered",
                                },
                              ]}
                              placeholder={"Tag Specifics"}
                              icon={<Icon iconName={"tags"}></Icon>}
                            />
                          </>
                        )}
                      </FlexRow>
                    </FlexCol>
                  )}
                </div>
              </td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height:
                    "answer" in updateRow &&
                    updateRow?.answerType != "QuestionTag"
                      ? "100px"
                      : "",
                  maxHeight:
                    "answer" in updateRow &&
                    updateRow?.answerType != "QuestionTag"
                      ? "100px"
                      : "",
                }}
              >
                <i
                  className="bi-arrow-right"
                  style={{ fontSize: "1.1rem" }}
                ></i>
              </td>
              <td style={{ width: "300px" }}>
                <div>
                  <SelectFieldCustom
                    value={
                      typeof updateRow?.externalField === "string"
                        ? sfObjectFields.find(
                            (f) => f.value === updateRow?.externalField
                          )
                        : updateRow?.externalField
                    }
                    onChange={(val) => {
                      let objectSetTemp = { ...objectSettings };
                      let updateField = { ...updateRow };
                      updateField.externalField = val;
                      objectSetTemp.updateFields[index] = updateField;
                      setObjectSettings(objectSetTemp);
                    }}
                    icon={<Icon blue iconName={"database"}></Icon>}
                    noCreate
                    options={sfObjectFields}
                    searchable
                    dropdownStyle={{ top: "2.9rem" }}
                  ></SelectFieldCustom>
                </div>
              </td>

              <td
                style={{
                  width: "fit-content",
                }}
              >
                <Button
                  shadow
                  onClick={() => {
                    let objectSetTemp = { ...objectSettings };
                    objectSetTemp?.updateFields?.splice(index, 1);
                    setObjectSettings(objectSetTemp);
                  }}
                >
                  Remove
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>{" "}
      <FlexRow>
        <Button
          shadow
          onClick={() => {
            let objectSetTemp = { ...objectSettings };
            objectSetTemp?.updateFields?.push({});
            setObjectSettings(objectSetTemp);
          }}
        >
          + Add Field
        </Button>
        <Button
          shadow
          onClick={() => {
            let objectSetTemp = { ...objectSettings };
            objectSetTemp?.updateFields?.push({ calculation: "" });
            setObjectSettings(objectSetTemp);
          }}
        >
          + Add Calculation
        </Button>
        {objectSettings?.internalName === "Contact" && (
          <Button
            shadow
            onClick={() => {
              let objectSetTemp = { ...objectSettings };
              objectSetTemp?.updateFields?.push({
                answer: "",
                answerType: "QuestionAnswer",
              });
              setObjectSettings(objectSetTemp);
            }}
          >
            + Add Answer
          </Button>
        )}
      </FlexRow>
    </>
  );
}

function FieldFiltering({
  reactionObjectFields,
  objectSettings,
  setObjectSettings,
  reactionObjects,
}) {
  return (
    <>
      <table
        className={styles.maptable}
        style={{ marginBottom: "1rem", marginTop: "1rem" }}
      >
        <thead>
          <tr>
            {objectSettings?.filterFields.length > 0 && (
              <>
                <th>Reaction Field</th>
                <th>Operator</th>
                <th>Value</th>
                <th></th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {objectSettings?.filterFields?.map((updateRow, index) => (
            <tr key={index}>
              <td style={{ width: "300px" }}>
                <div>
                  {updateRow?.audience && (
                    <SelectFieldCustom
                      className="audienceSelect" // Apply SCSS module class
                      classNamePrefix="audienceSelect" // Ensure correct className mapping
                      value={reactionObjects.find(
                        (f) => f.value === objectSettings?.internalName
                      )}
                      searchable
                      icon={<Icon blue iconName={"people"}></Icon>}
                      noCreate
                      isDisabled
                      dropdownStyle={{ top: "2.9rem" }}
                      disable
                    />
                  )}
                  {!updateRow?.audience && (
                    <SelectFieldCustom
                      value={reactionObjectFields.find(
                        (f) => f.value === updateRow?.internalField
                      )}
                      onChange={(val) => {
                        let objectSetTemp = { ...objectSettings };
                        let updateField = { ...updateRow };
                        updateField.internalField = val.value;
                        if (val?.dataType === "boolean") {
                          updateField.value = false;
                        } else {
                          updateField.value = "";
                        }
                        objectSetTemp.filterFields[index] = updateField;
                        setObjectSettings(objectSetTemp);
                      }}
                      searchable
                      icon={<Icon blue iconName={"database"}></Icon>}
                      noCreate
                      options={reactionObjectFields}
                      dropdownStyle={{ top: "2.9rem" }}
                    ></SelectFieldCustom>
                  )}
                </div>
              </td>
              <td style={{ width: "200px" }}>
                <div>
                  {!updateRow?.audience && (
                    <SelectFieldCustom
                      value={operators.find(
                        (f) => f.value === updateRow?.operator
                      )}
                      onChange={(val) => {
                        let objectSetTemp = { ...objectSettings };
                        let updateField = { ...updateRow };
                        updateField.operator = val.value;
                        objectSetTemp.filterFields[index] = updateField;
                        setObjectSettings(objectSetTemp);
                      }}
                      searchable
                      noCreate
                      options={operators}
                      dropdownStyle={{ top: "2.9rem" }}
                    ></SelectFieldCustom>
                  )}
                  {updateRow?.audience && (
                    <Select
                      className="audienceSelect" // Apply SCSS module class
                      classNamePrefix="audienceSelect" // Ensure correct className mapping
                      value={fileOperators.find(
                        (f) => f.value === updateRow?.operator
                      )}
                      onChange={(val) => {
                        let objectSetTemp = { ...objectSettings };
                        let updateField = { ...updateRow };
                        updateField.operator = val.value;
                        objectSetTemp.filterFields[index] = updateField;
                        setObjectSettings(objectSetTemp);
                      }}
                      searchable
                      noCreate
                      options={fileOperators}
                      dropdownStyle={{ top: "2.9rem" }}
                    ></Select>
                  )}
                </div>
              </td>
              <td style={{ width: "300px" }}>
                {!updateRow?.audience && (
                  <DataInputContainer
                    field={reactionObjectFields.find(
                      (f) => f.value === updateRow?.internalField
                    )}
                    value={updateRow.value}
                    onChange={(val) => {
                      let objectSetTemp = { ...objectSettings };
                      let updateField = { ...updateRow };
                      updateField.value = val;
                      objectSetTemp.filterFields[index] = updateField;
                      setObjectSettings(objectSetTemp);
                    }}
                  />
                )}
                {updateRow?.audience && (
                  <AudiencesInput
                    onChange={(val) => {
                      let objectSetTemp = { ...objectSettings };
                      let updateField = { ...updateRow };
                      updateField.value = val;
                      objectSetTemp.filterFields[index] = updateField;
                      setObjectSettings(objectSetTemp);
                    }}
                    value={updateRow.value}
                  />
                )}
              </td>

              <td
                style={{
                  width: "fit-content",
                }}
              >
                <Button
                  shadow
                  onClick={() => {
                    let objectSetTemp = { ...objectSettings };
                    objectSetTemp?.filterFields?.splice(index, 1);
                    setObjectSettings(objectSetTemp);
                  }}
                >
                  Remove
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>{" "}
      <FlexRow>
        <Button
          shadow
          onClick={() => {
            let objectSetTemp = { ...objectSettings };
            objectSetTemp?.filterFields?.push({
              internalField: "",
              operator: 0,
              value: "",
            });
            setObjectSettings(objectSetTemp);
          }}
        >
          + Add Field
        </Button>
        {objectSettings?.internalName === "Contact" && (
          <Button
            shadow
            onClick={() => {
              let objectSetTemp = { ...objectSettings };
              objectSetTemp?.filterFields?.push({
                internalField: "",
                operator: 0,
                value: "",
                audience: true,
              });
              setObjectSettings(objectSetTemp);
            }}
          >
            + Add Audience
          </Button>
        )}
      </FlexRow>
    </>
  );
}

function DataInputContainer({ field, onChange, value }) {
  return (
    <DataInput
      dataType={field?.dataType}
      min={field?.min}
      max={field?.max}
      falseValue={field?.falseValue}
      trueValue={field?.trueValue}
      id={field?.id}
      value={value}
      onChange={onChange}
    ></DataInput>
  );
}

function AudiencesInput({ onChange, value }) {
  const skip = 0;
  const perPage = 20;
  const sort = { item: "name", descend: true };

  const fetchOptions = async (inputValue) => {
    const results = await useSearchAudiences(
      inputValue,
      skip,
      perPage,
      sort,
      ""
    );
    return results.map((a) => ({
      value: a.id,
      label: a.name,
    }));
  };

  return (
    <div>
      <AsyncSelect
        className="audienceSelect" // Apply SCSS module class
        classNamePrefix="audienceSelect" // Ensure correct className mapping
        cacheOptions
        loadOptions={fetchOptions}
        defaultOptions
        onChange={onChange}
        value={value}
        placeholder="Search Audiences..."
      />
    </div>
  );
}

function SurveyInput({ onChange, value }) {
  const skip = 0;
  const perPage = 20;
  const sort = { item: "name", descend: true };

  const fetchOptions = async (inputValue) => {
    const results = await useSearchSurveysStatic(
      inputValue,
      skip,
      perPage,
      sort,
      ""
    );

    return results.map((a) => ({
      value: a.id,
      label: a.name,
    }));
  };

  return (
    <AsyncSelect
      className="audienceSelect" // Apply SCSS module class
      classNamePrefix="audienceSelect" // Ensure correct className mapping
      cacheOptions
      loadOptions={fetchOptions}
      defaultOptions
      onChange={onChange}
      value={value}
      // icon={<Icon green iconName={"check-circle"}></Icon>}
      // options={calculationOptions}
      placeholder={"Survey..."}
    />
  );
}

function QuestionInput({ onChange, value, surveyId }) {
  const skip = 0;
  const perPage = 50;
  const sort = { item: "questionText", descend: true };

  // Use useCallback to prevent unnecessary re-renders
  const fetchOptions = useCallback(
    async (inputValue) => {
      const results = await useSearchQuestionsStatic(
        inputValue,
        skip,
        perPage,
        sort,
        surveyId
      );

      return results.map((a) => ({
        value: a.id,
        label: a.questionText,
      }));
    },
    [surveyId] // Recreate the function when surveyId changes
  );

  return (
    <AsyncSelect
      key={surveyId} // Forces a re-render when surveyId changes
      className="audienceSelect"
      classNamePrefix="audienceSelect"
      loadOptions={fetchOptions} // Directly pass the function
      defaultOptions
      onChange={onChange}
      value={value}
      placeholder={"Question..."}
    />
  );
}

function QuestionTagInput({ onChange, value }) {
  const skip = 0;
  const perPage = 50;
  const sort = { item: "name", descend: true };

  const fetchOptions = async (inputValue) => {
    const results = await useSearchQuestionTagsStatic(
      inputValue,
      skip,
      perPage,
      sort
    );

    return results.map((a) => ({
      value: a.id,
      label: a.name,
      color: a.color,
    }));
  };

  return (
    <AsyncSelect
      className="audienceSelect" // Apply SCSS module class
      classNamePrefix="audienceSelect" // Ensure correct className mapping
      cacheOptions
      loadOptions={fetchOptions}
      defaultOptions
      onChange={onChange}
      value={value}
      placeholder={"Question Tag..."}
      formatOptionLabel={(data) => {
        return (
          <FlexRow gap={".5rem"}>
            <i style={{ color: data.color }} className="bi-tag-fill"></i>{" "}
            {data.label}
          </FlexRow>
        );
      }}
    />
  );
}
