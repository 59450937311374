import styles from "./PickDash.module.scss";
import {
  useFetchUsersByOrgIdCount,
  useGetCurrentUser,
  useSearchUsersPages,
} from "api/resources/organization/users";
import { useEffect, useRef, useState } from "react";
import { useFetchMyOrg } from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import { useFetchOrgRoles } from "api/resources/organization/roles";
import { useNavigate } from "react-router-dom";
import Icon from "components/Icon/Icon";
import Button from "components/Button/Button";
import Modal from "components/ReactModal/ReactModal";
import {
  useAddLinkToDashIds,
  useAddLinkToUserDashboards,
  useCopyVizToDashIds,
  useCopyVizToUserDashboards,
} from "api/resources/account/dashboard";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import { Pagination } from "components/tables/BasicTable/Pagination/Pagination";
import { PaginationContainer } from "components/tables/BasicTable/Pagination/PaginationContainer";
import { ErrorBanner } from "pages/error_page/ErrorPage";

export function PickDash({ onPick, roles, users, setShowDashes, orgId }) {
  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.topButtons}>
          <Button
            onClick={() => setShowDashes(true)}
            style={{
              display: "flex",
              gap: "7px",
            }}
            shadow
          >
            <Icon iconName={"arrow-right-square"} info></Icon>
            <div>Change Dashboard</div>
          </Button>
        </div>
      </div>

      <div className={styles.container}>
        {users && <PickUser onPick={onPick} orgId={orgId} />}
        {roles && <PickRole onPick={onPick} orgId={orgId} />}
      </div>
    </>
  );
}

export function CopyToDashes({ roles, users, onClose, vizId, link }) {
  const getCurrUser = useGetCurrentUser();
  const copyToDashIds = useCopyVizToDashIds();
  const copyToUsers = useCopyVizToUserDashboards();
  const addLinkToDashIds = useAddLinkToDashIds();
  const addLinkToUsers = useAddLinkToUserDashboards();

  const [chosen, setChosen] = useState([]);
  const [copying, setCopying] = useState(false);
  const [coppied, setCoppied] = useState(false);
  const [error, setError] = useState(null);

  function onPick(something) {
    let id = roles ? something.dashboardId : something.id;

    let index = chosen.indexOf(id);
    if (index > -1) {
      setChosen([...chosen.slice(0, index), ...chosen.slice(index + 1)]);
    } else {
      setChosen([...chosen, ...[id]]);
    }
  }

  function onCopy() {
    const options = {
      onSuccess: (data) => {
        if (data.coppied) {
          setCoppied(true);
          setTimeout(() => {
            setCoppied(false);
            onClose();
          }, 1500);
        }
        setCopying(false);
      },
      onError: (err) => {
        setCopying(false);
        setError(err);
      },
    };

    if (roles) {
      if (vizId) {
        copyToDashIds.mutate(
          {
            vizId: vizId,
            dashIds: {
              ids: chosen,
            },
          },
          options
        );
      } else if (link) {
        addLinkToDashIds.mutate(
          {
            dashIds: {
              ids: chosen,
            },
            link: link,
          },
          options
        );
      }
    }
    if (users) {
      if (vizId) {
        copyToUsers.mutate(
          {
            vizId: vizId,
            userIds: {
              ids: chosen,
            },
          },
          options
        );
      } else if (link) {
        addLinkToUsers.mutate(
          {
            link: link,
            userIds: {
              ids: chosen,
            },
          },
          options
        );
      }
    }
    setCopying(true);
  }

  return (
    <>
      {getCurrUser.isError && (
        <ErrorBanner
          error={getCurrUser.error}
          message={"Error pulling user data"}
        />
      )}

      <Modal
        show
        modalStyle={{ borderRadius: "1rem", padding: "1rem", height: "95vh" }}
        onClose={onClose}
      >
        <div className={styles.copyContainer}>
          {getCurrUser.isLoading && <Loading />}
          {error && (
            <ErrorBanner error={error} message={"Error copying to dashboard"} />
          )}
          {getCurrUser.isSuccess && (
            <>
              {users && (
                <PickUser
                  onPick={onPick}
                  orgId={getCurrUser.data.me.organizationId}
                  chosen={chosen}
                />
              )}
              {roles && (
                <PickRole
                  onPick={onPick}
                  orgId={getCurrUser.data.me.organizationId}
                  chosen={chosen}
                />
              )}
            </>
          )}

          <div
            className={styles.buttons}
            style={!chosen.length ? { visibility: "hidden" } : undefined}
          >
            <Button shadow onClick={onClose}>
              <div className={styles.button}>
                <i className="bi-x-lg"></i> Cancel
              </div>
            </Button>
            <Button shadow blue onClick={onCopy}>
              <div className={styles.button}>
                {!coppied && !copying && (
                  <>
                    <i className="bi-printer"></i> Copy to Dashboards
                  </>
                )}
                {copying && (
                  <>
                    <Loading height={20} width={20} /> Copying
                  </>
                )}
                {coppied && (
                  <>
                    <i className="bi-check2"></i>
                    Success
                  </>
                )}
              </div>
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

function PickUser({ onPick, orgId, chosen }) {
  const myOrg = useFetchMyOrg();

  const [pageNumber, setPageNumber] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [perPage, setPerpage] = useState(20);
  const [pageSkip, setPageSkip] = useState(0);

  const tableRef = useRef();

  const [sort, setSort] = useState({
    descend: false,
    item: "firstName",
  });

  const getUsers = useSearchUsersPages(searchString, pageSkip, perPage, sort);

  const getUsersCount = useFetchUsersByOrgIdCount(
    searchString,
    pageSkip,
    perPage,
    sort,
    orgId
  );

  const headers = [
    {
      id: 0,
      name: "First Name",
      accessor: "firstName",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: null,
    },
    {
      id: 1,
      name: "Last Name",
      accessor: "lastName",
      enabled: true,
      sort: true,
      canSort: true,
      cell_style: null,
    },
    {
      id: 2,
      name: "Role",
      // accessor: "role",
      // objectAccessor: "name",
      enabled: true,
      sort: false,
      canSort: false,
      cell_style: (u) => <div>{getRoles(u)}</div>,
    },
    {
      id: 3,
      name: "",
      enabled: chosen ? true : false,
      sort: false,
      canSort: false,
      cell_style: (u) => (
        <>
          <i
            className={`bi-check-lg ${styles.check}`}
            style={{
              opacity: chosen.includes(u?.id) ? "1" : "0",
            }}
          ></i>
        </>
      ),
    },
  ];

  function getRoles(u) {
    if (u?.roles) {
      let role = u?.roles.find((role) => role?.organizationId === orgId);
      return role?.name;
    }
    return "";
  }

  useEffect(() => {
    getUsers.refetch();
    getUsersCount.refetch();
  }, [pageNumber, sort, perPage]);

  function onSearch(val) {
    setSearchString(val);
    if (pageNumber !== 1) {
      setPageNumber(1);
    }
    if (pageSkip) {
      setPageSkip(0);
    }
  }

  return (
    <>
      {myOrg.isLoading && <Loading />}
      {myOrg.isError && (
        <ErrorBanner
          error={myOrg.error}
          message={"Error pulling organization data"}
        />
      )}
      {myOrg.isSuccess && (
        <div className={styles.holder}>
          <div
            className={styles.roleTableHolder}
            style={chosen ? undefined : { width: "50%" }}
          >
            <div className={styles.usersTableTop}>
              <div className={styles.roleTableTitle}>
                {`${myOrg.data.organization.name}'s Users`}
              </div>

              <NavTextField
                value={searchString}
                setValue={onSearch}
                placeholder={"Search..."}
                className={styles.search}
                shadow
              />
            </div>

            {/* <div className={styles.tableContainer}> */}
            {/* <div className={styles.tableHeader}>
              <div className={styles.titleTop}>
                <div
                  className={styles.tableTitle}
                >{`${myOrg.data.organization.name}'s Users`}</div>
              </div>
              <div className={styles.headerInner}>
                <NavTextField
                  value={searchString}
                  setValue={onSearch}
                  placeholder={"Search..."}
                  className={styles.search}
                  shadow
                />
              </div>
            </div> */}

            <div className={styles.tableWrapper}>
              <table
                className={styles.roleTable}
                style={chosen ? { width: "50vw" } : undefined}
                ref={tableRef}
              >
                <thead className={styles.tableHeader}>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Role</th>
                    {chosen && <th></th>}
                  </tr>
                </thead>
                <tbody>
                  {getUsers.isLoading && (
                    <tr>
                      <td colSpan={chosen ? 4 : 3}>
                        <Loading></Loading>
                      </td>
                    </tr>
                  )}
                  {getUsers.isError && (
                    <ErrorBanner
                      error={getUsers.error}
                      message={"Error pulling users"}
                    />
                  )}
                  {getUsersCount.isError && (
                    <ErrorBanner
                      error={getUsersCount.error}
                      message={"Error pulling users' count"}
                    />
                  )}
                  {getUsers.isSuccess && getUsersCount.isSuccess && (
                    <>
                      {getUsers.data.users.map((user, i) => (
                        <tr
                          key={user.id}
                          className={i % 2 == 0 ? styles.gray : ""}
                          onClick={() => onPick(user)}
                        >
                          <td>{user.firstName}</td>
                          <td>{user.lastName}</td>
                          <td>{getRoles(user)}</td>
                          {chosen && (
                            <td>
                              <i
                                className={`bi-check-lg ${styles.check}`}
                                style={{
                                  opacity: chosen.includes(user.id) ? "1" : "0",
                                }}
                              ></i>
                            </td>
                          )}
                        </tr>
                      ))}
                      {!getUsers.data.users.length && (
                        <tr className={styles.noHover}>
                          <td className={styles.sorry} colSpan={3}>
                            Sorry, no data
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>

            {getUsers.isSuccess && getUsersCount.isSuccess && (
              <PaginationContainer
                style={{
                  padding: "1em 1em",
                  position: "relative",
                  maxHeight: "fit-content",
                }}
              >
                <Pagination
                  pageNum={pageNumber}
                  setPageNum={setPageNumber}
                  maxPage={
                    getUsersCount.data.count / perPage < 1
                      ? 1
                      : Math.ceil(getUsersCount.data.count / perPage)
                  }
                  length={getUsers.data.users.length}
                  searchString={searchString}
                  perPage={perPage}
                  setPerPage={setPerpage}
                  setPageSkip={setPageSkip}
                ></Pagination>
              </PaginationContainer>
            )}
          </div>

          {/* <InteractiveTable
            headers={headers}
            queryData={getUsers}
            queryDataAccessor={"users"}
            maxDataQuery={getUsersCount}
            maxDataQueryAccessor={"count"}
            currPage={pageNumber}
            setCurrPage={setPageNumber}
            onRowClick={onPick}
            sort={sort}
            setSort={setSort}
            pageSkip={pageSkip}
            setPageSkip={setPageSkip}
            perPage={perPage}
            setPerPage={setPerpage}
            searchString={searchString}
            setSearchString={setSearchString}
            tableTitle={`${myOrg.data.organization.name}'s Users`}
            searchPlaceholder={"Search Users..."}
            hideActionButton={true}
          ></InteractiveTable> */}
        </div>
      )}
    </>
  );
}

export function BackToTable({ onClick }) {
  return (
    <>
      <div className={styles.backToTable} onClick={onClick}>
        <div className={styles.tableIcon}>
          <i className="bi bi-list"></i>{" "}
        </div>
        <div className={styles.backArrow}>
          <i className="bi bi-arrow-return-left"></i>
        </div>
        <div className={styles.descriptionAnchor}>
          <div className={styles.description}>Back to Table</div>
        </div>
      </div>
    </>
  );
}

function PickRole({ onPick, orgId, chosen }) {
  const myOrg = useFetchMyOrg();
  const orgRoles = useFetchOrgRoles(orgId);

  function scanRoles() {
    let keeping = [];
    for (let role of orgRoles.data.roles) {
      if (role?.dashboardId) {
        // Show even if not showing, so that someone can make edits without having it available until ready
        keeping.push(role);
      }
    }
    return keeping;
  }

  const navigate = useNavigate();
  function manage() {
    localStorage.setItem("activepage", 8);
    localStorage.setItem("activeorgtab", 3);
    navigate("../organization/" + orgId + "/dashboards");
  }

  return (
    <>
      {(myOrg.isLoading || orgRoles.isLoading) && <Loading />}
      {myOrg.isError && (
        <ErrorBanner
          error={myOrg.error}
          message={"Error pulling organization data"}
        />
      )}
      {orgRoles.isError && (
        <ErrorBanner
          error={orgRoles.error}
          message={"Error pulling org role data"}
        />
      )}
      {myOrg.isSuccess && orgRoles.isSuccess && (
        <div
          className={styles.roleTableHolder}
          style={chosen ? undefined : { width: "50%" }}
        >
          <div className={styles.roleTableTitle}>
            {`${myOrg.data.organization.name}'s Roles`}
          </div>
          <div className={styles.roleHelp}>
            Only showing roles <em>with a dashboard</em>.{" "}
            <span className={styles.manageRoles} onClick={manage}>
              Manage Roles <i className="bi bi-gear"></i>
            </span>
          </div>

          <div className={styles.tableWrapper}>
            <table
              className={styles.roleTable}
              style={chosen ? { width: "50vw" } : undefined}
            >
              <thead className={styles.tableHeader}>
                <tr>
                  <th>Role</th>
                  <th>Description</th>
                  {chosen && <th></th>}
                </tr>
              </thead>
              <tbody>
                {scanRoles().map((r, i) => (
                  <tr
                    key={r.id}
                    className={i % 2 == 0 ? styles.gray : ""}
                    onClick={() => onPick(r)}
                  >
                    <td>{r.name}</td>
                    <td>{r.description}</td>
                    {chosen && (
                      <td>
                        <i
                          className={`bi-check-lg ${styles.check}`}
                          style={{
                            opacity: chosen.includes(r.dashboardId) ? "1" : "0",
                          }}
                        ></i>
                      </td>
                    )}
                  </tr>
                ))}
                {!scanRoles().length && (
                  <tr className={styles.noHover}>
                    <td className={styles.sorry} colSpan={2}>
                      Sorry, no roles with dashboards.{" "}
                      <span
                        className={styles.manageRoles}
                        style={{ fontSize: ".75rem" }}
                        onClick={manage}
                      >
                        Manage Roles <i className="bi bi-gear"></i>
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}
