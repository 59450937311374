// External
import React, { useEffect, useRef, useState } from "react";
import styles from "./Users.module.scss";

// Internal
import {
  useFetchUsersByOrgId,
  useInviteUser,
  useRemoveUserFromOrg,
  useRevokeInvite,
} from "api/resources/organization/users";
import { useParams } from "react-router-dom";
import UserProfile from "components/Popout/UserProfile";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import Button from "components/Button/Button";
import { Loading } from "components/Loading/Loading";
import ImageEditor from "components/Image Editor/ImageEditor";
import Icon from "components/Icon/Icon";
import DoYouWantToSave from "components/Popout/DoYouWantToSave";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import {
  useChangeUserRole,
  useFetchOrgRoles,
} from "api/resources/organization/roles";
import Modal from "components/ReactModal/ReactModal";
import { Player } from "@lottiefiles/react-lottie-player";
import { CreateUser } from "./CreateUser";
import CreateJoinCode from "./CreateJoinCode";
import { HeaderContainer } from "components/layouts/Header/HeaderContainer";
import { isMoreThan48HoursAgo, trimDate } from "assets/functions/DateFunctions";
import Status from "components/Status/Status";
import { Header } from "components/layouts";
import { isValidEmail } from "assets/functions/StringFunctions";
import { Pagination } from "components/tables/BasicTable/Pagination/Pagination";

export const Users = ({ organization, roles, user }) => {
  const { id } = useParams();
  const removeUser = useRemoveUserFromOrg();
  const orgRoles = useFetchOrgRoles();
  const [showUser, setShowUser] = useState(false);
  const [currUser, setCurrUser] = useState(null);
  const [invite, setInvite] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [perPage, setPerpage] = useState(15);
  const [pageSkip, setPageSkip] = useState(0);
  const [checkRemove, setCheckRemove] = useState(false);
  const [confirmRoleChange, setConfirmRoleChange] = useState();
  const [createNew, setCreateNew] = useState(false);
  const [createCode, setCreateCode] = useState(false);
  const [currInvite, setCurrInvite] = useState();

  const [sort, setSort] = useState({
    descend: true,
    item: "createdAt",
  });

  const getUsers = useFetchUsersByOrgId(
    searchString,
    pageSkip,
    perPage,
    sort,
    id
  );

  const selectUser = (user) => {
    if (roles?.isAdmin) {
      delete user.organizationId;
      setCurrUser(user);
      setShowUser(true);
    }
  };

  useEffect(() => {
    getUsers.remove();
    getUsers.refetch();
  }, [pageNumber, sort, perPage, searchString]);

  function handleRemoveUser(id) {
    if (id) {
      removeUser.mutate(
        {
          orgId: organization.id,
          userId: id,
        },
        {
          onSuccess: () => {
            setCheckRemove(false);
            getUsers.refetch();
          },
        }
      );
    }
  }

  function getRoles(roles) {
    let role = roles?.find((role) => role?.organizationId === id);
    return role
      ? { value: role?.id, label: role?.name, color: role?.color }
      : { value: "", label: "" };
  }

  const newOptions = [
    {
      onClick: () => setInvite(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"envelope-fill"}></Icon> Invite a new user
          </FlexRow>{" "}
          <div className={styles.description2}>
            Invite someone to join your org by email.
          </div>
        </div>
      ),
    },
    {
      onClick: () => setCreateNew(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"person-plus-fill"}></Icon> Create a new user
          </FlexRow>{" "}
          <div className={styles.description2}>
            Manually create a new user with password info.
          </div>
        </div>
      ),
    },
    {
      onClick: () => setCreateCode(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"123"}></Icon> Generate a Join Code
          </FlexRow>{" "}
          <div className={styles.description2}>
            Create a join code to share in any way you want.
          </div>
        </div>
      ),
    },
  ];

  const InviteUserMutation = useRevokeInvite();

  function RevokeInvite(user) {
    InviteUserMutation.mutate(
      {
        email: user?.email,
      },
      {
        onSuccess: (data) => {
          getUsers.remove();
          getUsers.refetch();
        },
      }
    );
  }

  return (
    <div className={styles.page}>
      <HeaderContainer
        path={"User Management"}
        user={user}
        roles={roles}
        organization={organization}
      ></HeaderContainer>
      <div className={styles.content}>
        <FlexCol gap={"1rem"} className={styles.users}>
          {checkRemove && (
            <DoYouWantToSave
              cancelText={"No, do not remove"}
              confirmText={"Yes"}
              prompt={
                "Are you sure you want to remove this user from your organization?"
              }
              navAway={() => setCheckRemove(false)}
              onSave={() => handleRemoveUser(checkRemove)}
            ></DoYouWantToSave>
          )}

          <FlexRow
            justify={"space-between"}
            style={{ flexWrap: "wrap", padding: "0rem" }}
          >
            <FlexRow style={{ width: "fit-content", flexWrap: "wrap" }}>
              <NavTextField
                value={searchString}
                setValue={setSearchString}
                placeholder={"Search user..."}
                shadow
              />
              <div className={styles.text_2}>
                {getUsers.data?.users?.count}{" "}
                {getUsers.data?.users?.count === 1 ? "user" : "users"}
              </div>
              {/* <Sorting sort={sort} setSort={setSort} person></Sorting> */}
            </FlexRow>
            <Button
              blue
              shadow
              height={2.1}
              optionHeight={100}
              options={newOptions}
            >
              + Add User
            </Button>
          </FlexRow>
          {getUsers.isError && <p>Error</p>}
          {(getUsers.isLoading || orgRoles.isLoading) && (
            <div style={{ height: "100vh" }}>
              <Loading></Loading>
            </div>
          )}

          <table className={styles.table}>
            <thead>
              <tr>
                <th>
                  <div
                    className={styles.headerContainer}
                    onClick={() => {
                      setSort({ item: "firstName", descend: !sort.descend });
                    }}
                  >
                    User name
                    {sort.item === "firstName" &&
                      (sort.descend ? (
                        <i className="bi-caret-down-fill" />
                      ) : (
                        <i className="bi-caret-up-fill" />
                      ))}
                    {sort.item != "firstName" && <span>-</span>}
                  </div>
                </th>
                <th>
                  <div className={styles.headerContainer}>Access</div>
                </th>
                <th>
                  <div
                    className={styles.headerContainer}
                    onClick={() => {
                      setSort({ item: "updatedAt", descend: !sort.descend });
                    }}
                  >
                    Last active{" "}
                    {sort.item === "updatedAt" &&
                      (sort.descend ? (
                        <i className="bi-caret-down-fill" />
                      ) : (
                        <i className="bi-caret-up-fill" />
                      ))}
                    {sort.item != "updatedAt" && <span>-</span>}
                  </div>
                </th>
                <th>
                  <div
                    className={styles.headerContainer}
                    onClick={() => {
                      setSort({ item: "createdAt", descend: !sort.descend });
                    }}
                  >
                    Date added{" "}
                    {sort.item === "createdAt" &&
                      (sort.descend ? (
                        <i className="bi-caret-down-fill" />
                      ) : (
                        <i className="bi-caret-up-fill" />
                      ))}
                    {sort.item != "createdAt" && <span>-</span>}
                  </div>
                </th>
                <th>
                  <div className={styles.headerContainer}></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {getUsers.data?.users?.users?.map((user, index) => (
                <User
                  key={index}
                  user={user}
                  index={index}
                  onClick={() => {
                    user?.source === "app_user"
                      ? selectUser(user)
                      : setCurrInvite(user);
                  }}
                  orgId={organization?.id}
                  setCheckRemove={setCheckRemove}
                  roles={orgRoles.isSuccess ? orgRoles.data.rolesByOrgId : []}
                  currRole={roles}
                  setConfirmRoleChange={setConfirmRoleChange}
                  revoke={RevokeInvite}
                ></User>
              ))}
            </tbody>
          </table>
          <FlexRow
            justify={"flex-end"}
            style={{ padding: "0rem", marginTop: "1rem" }}
          >
            <Pagination
              pageNum={pageNumber}
              setPageNum={setPageNumber}
              maxPage={
                getUsers.data?.users?.count / perPage < 1
                  ? 1
                  : Math.ceil(getUsers.data?.users?.count / perPage)
              }
              length={getUsers.data?.users?.users?.length}
              searchString={searchString}
              perPage={perPage}
              setPerPage={setPerpage}
              setPageSkip={setPageSkip}
            ></Pagination>
          </FlexRow>

          {getUsers.isSuccess && orgRoles.isSuccess && (
            <>
              {/* <FlexCol gap={"1rem"} style={{}}>
              {getUsers.isRefetching && (
                <div style={{ height: "100vh" }}>
                  <Loading></Loading>
                </div>
              )}

              {getUsers.isSuccess && (
                <div className={styles.users}>
                  {getUsers.data?.users?.users?.map((user, index) => (
                    <User
                      key={index}
                      user={user}
                      index={index}
                      onClick={() => selectUser(user)}
                      orgId={organization?.id}
                      setCheckRemove={setCheckRemove}
                      roles={
                        orgRoles.isSuccess ? orgRoles.data.roles : []
                      }
                      currRole={user?.roles ? getRoles(user?.roles) : {}}
                      setConfirmRoleChange={setConfirmRoleChange}
                    ></User>
                  ))}
                </div>
              )}
              
            </FlexCol> */}

              {confirmRoleChange && (
                <ConfrimChangeRole
                  roleData={confirmRoleChange}
                  setShow={setConfirmRoleChange}
                  orgId={id}
                />
              )}
              {invite && (
                <InviteUser
                  show={invite}
                  setShow={() => {
                    setInvite();
                    getUsers.remove();
                    getUsers.refetch();
                  }}
                  orgId={id}
                  roles={orgRoles.isSuccess ? orgRoles.data.roles : []}
                  refetch={() => {
                    getUsers.remove();
                    getUsers.refetch();
                  }}
                />
              )}
              {createNew && (
                <CreateUser
                  onClose={() => setCreateNew(false)}
                  onDone={getUsers.refetch}
                  orgId={id}
                />
              )}

              {createCode && (
                <CreateJoinCode
                  onClose={() => setCreateCode(false)}
                  orgid={id}
                />
              )}
              {showUser && (
                <UserProfile
                  userId={currUser?.id}
                  onClose={() => setShowUser(false)}
                  roles={currUser?.roles}
                  organization={organization}
                  canEdit
                  modal
                  refetch={getUsers.refetch}
                />
              )}

              {currInvite && (
                <Modal
                  show={currInvite}
                  onClose={() => setCurrInvite(false)}
                  dark
                  modalStyle={{
                    width: "100%",
                    height: "100%",
                    maxWidth: "700px",
                    maxHeight: "500px",
                  }}
                >
                  <UserInviteView
                    user={currInvite}
                    cancel={() => setCurrInvite(null)}
                    refetch={() => {
                      getUsers.remove();
                      getUsers.refetch();
                    }}
                    revoke={RevokeInvite}
                  ></UserInviteView>
                </Modal>
              )}
            </>
          )}
        </FlexCol>
      </div>
    </div>
  );
};

function User({
  user,
  onClick,
  orgId,
  setCheckRemove,
  currRole,
  revoke,
  roles,
  setConfirmRoleChange,
}) {
  return (
    <tr>
      <td
        onClick={
          currRole?.isAdmin
            ? (e) => {
                e.stopPropagation();
                onClick(user.id);
              }
            : () => undefined
        }
        style={{ cursor: currRole?.isAdmin ? "cursor" : "default" }}
      >
        <FlexRow>
          <div>
            <ImageEditor
              src={user?.image?.imageURL}
              canEdit={false}
              isUser={true}
              userId={user?.id}
              position={
                user?.image?.position &&
                typeof user?.image?.position === "string"
                  ? JSON.parse(user?.image?.position)
                  : user?.image?.position
              }
              image={user?.image}
              // refetch={getUser.refetch}
              height={35}
              width={35}
              noShadow
            ></ImageEditor>
          </div>
          <FlexCol>
            <h5>
              {user?.firstName} {user?.lastName}
            </h5>
            <small>{user?.email}</small>
          </FlexCol>
        </FlexRow>
      </td>
      <td
        onClick={
          currRole?.isAdmin
            ? (e) => {
                e.stopPropagation();
                onClick(user.id);
              }
            : () => undefined
        }
        style={{ cursor: currRole?.isAdmin ? "cursor" : "default" }}
      >
        {currRole && currRole.value && user?.source === "app_user" && (
          <Status style={{ width: "fit-content" }} color={currRole?.color}>
            {currRole?.label}{" "}
          </Status>
        )}
        {(!currRole || !currRole?.value) && user?.source === "app_user" && (
          <Status style={{ width: "fit-content" }}>No Role</Status>
        )}
        {user?.source === "user_invite" && (
          <>
            {" "}
            {user?.expired ? (
              <Status style={{ width: "fit-content" }} red>
                Invite Expired <i className="bi-envelope-x"></i>
              </Status>
            ) : (
              <Status style={{ width: "fit-content" }} yellow>
                Invite Sent <i className="bi-envelope"></i>
              </Status>
            )}
          </>
        )}
      </td>
      <td
        onClick={
          currRole?.isAdmin
            ? (e) => {
                e.stopPropagation();
                onClick(user.id);
              }
            : () => undefined
        }
        style={{ cursor: currRole?.isAdmin ? "cursor" : "default" }}
      >
        <h5 className={styles.date}>
          {user?.source === "app_user" ? trimDate(user?.updatedAt) : ""}
        </h5>
      </td>
      <td
        onClick={
          currRole?.isAdmin
            ? (e) => {
                e.stopPropagation();
                onClick(user.id);
              }
            : () => undefined
        }
        style={{ cursor: currRole?.isAdmin ? "cursor" : "default" }}
      >
        <h5 className={styles.date}>
          {user?.source === "app_user" ? trimDate(user?.createdAt) : ""}
        </h5>
      </td>
      {currRole?.isAdmin && (
        <td>
          <Button
            height={2.1}
            options={
              user?.source === "app_user"
                ? [
                    {
                      onClick: (e) => {
                        e.stopPropagation();
                        onClick(user.id);
                      },
                      data: (
                        <>
                          {" "}
                          <i
                            className="bi-layout-text-sidebar-reverse"
                            style={{ color: "#A7D5C5" }}
                          ></i>{" "}
                          View Details
                        </>
                      ),
                    },
                    {
                      onClick: (e) => {
                        e.stopPropagation();
                        setCheckRemove(user.id);
                      },
                      data: (
                        <>
                          {" "}
                          <i
                            className="bi-trash"
                            style={{ color: "#FF8878" }}
                          ></i>{" "}
                          Remove User
                        </>
                      ),
                    },
                  ]
                : [
                    {
                      onClick: (e) => {
                        e.stopPropagation();
                        onClick(user);
                      },
                      data: (
                        <>
                          {" "}
                          <i
                            className="bi-envelope-paper"
                            style={{ color: "#A7D5C5" }}
                          ></i>{" "}
                          View Invite
                        </>
                      ),
                    },
                    {
                      onClick: (e) => {
                        e.stopPropagation();
                        revoke(user);
                      },
                      data: (
                        <>
                          {" "}
                          <i
                            className="bi-envelope-dash"
                            style={{ color: "#FF8878" }}
                          ></i>{" "}
                          Revoke Invite
                        </>
                      ),
                    },
                  ]
            }
          >
            <i
              className={"bi-three-dots-vertical"}
              style={{ fontSize: "1.1rem" }}
            ></i>
          </Button>
        </td>
      )}
    </tr>
  );
}

function UserInviteView({ user, cancel, refetch, revoke }) {
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [joinCode, setJoinCode] = useState();
  const email = user?.email;
  const InviteUserMutation = useInviteUser();
  const ref = useRef(null);

  function SendInvite() {
    setSending(true);
    InviteUserMutation.mutate(
      {
        email,
        roleId: "",
      },
      {
        onSuccess: (data) => {
          setSuccess(true);
          setJoinCode(data?.InviteUser?.code);
          refetch();
          user.updatedAt = new Date();
        },
      }
    );
  }

  return (
    <FlexCol className={styles.page}>
      <Header>
        <FlexRow>
          <div>
            <ImageEditor
              src={user?.image?.imageURL}
              canEdit={false}
              isUser={true}
              userId={user?.id}
              position={
                user?.image?.position &&
                typeof user?.image?.position === "string"
                  ? JSON.parse(currInvite?.image?.position)
                  : user?.image?.position
              }
              image={user?.image}
              // refetch={getUser.refetch}
              // setShow={setEditImage}
              // show={editImage}
              height={70}
              width={70}
            ></ImageEditor>
          </div>
          <FlexCol gap=".5rem">
            <h3>{user?.email}</h3>
            {user?.expired ? (
              <Status style={{ width: "fit-content" }} red>
                Invite Expired <i className="bi-envelope-x"></i>
              </Status>
            ) : (
              <Status style={{ width: "fit-content" }} yellow>
                Invite Sent <i className="bi-envelope"></i>
              </Status>
            )}
          </FlexCol>
        </FlexRow>
      </Header>
      <FlexCol style={{ padding: "2rem" }} gap="1rem">
        {!sending && !success && (
          <>
            <h4>Invite Sent: {trimDate(user?.updatedAt, true)}</h4>
            <FlexRow>
              <Button style={{ gap: ".5rem" }} shadow onClick={SendInvite}>
                <Icon blue iconName={"send"}></Icon>Resend Invite
              </Button>
              <Button
                style={{ gap: ".5rem" }}
                shadow
                onClick={() => {
                  revoke(user);
                  cancel();
                }}
              >
                <Icon red iconName={"envelope-dash"}></Icon>Revoke Invite
              </Button>
            </FlexRow>
          </>
        )}

        {sending && success && (
          <>
            <FlexRow
              gap={".5rem"}
              style={{ marginBottom: "1rem" }}
              justify={"center"}
            >
              <div className={styles.text_1} style={{ textAlign: "center" }}>
                Invite Sent
              </div>
              <Player
                autoplay={true}
                loop={false}
                ref={ref}
                onEvent={(e) => {
                  if (e === "play") {
                    setTimeout(() => {
                      ref?.current?.pause();
                    }, 2000);
                  }
                }}
                src={require("assets/animations/checkmarkgreen.json")}
                style={{
                  height: "40px",
                  // width: "50px",
                }}
              />
            </FlexRow>
            <div className={styles.text_3} style={{ margin: "0" }}>
              Your invite was successfully sent to {email}. If they claim to
              have not recieved the email after 10 minutes, have them check
              their junk/spam folder.
              <br /> <br />
              If the email is still not found, Use the following join code and
              send the email externally or try the invite again:{" "}
              <span className={styles.blue}>{joinCode}</span>
            </div>
            <div
              className={styles.text_1}
              style={{ margin: "1rem 0", textAlign: "center" }}
            ></div>
            <div
              className={styles.text_3}
              style={{ margin: "0rem 0", textAlign: "center" }}
            >
              Get help from{" "}
              <span className={styles.link}>
                Reaction Support <i className="bi-box-arrow-up-right"></i>
              </span>
            </div>
            <FlexRow justify={"flex-end"}>
              <Button shadow onClick={cancel}>
                Close
              </Button>
            </FlexRow>
          </>
        )}
        {sending && !success && (
          <>
            <FlexCol gap={"0"}>
              <Player
                autoplay
                loop
                src={require("assets/animations/EmailSending.json")}
                style={{
                  height: "150px",
                  // width: "100px",
                }}
              />
              <div className={styles.text_2} style={{ textAlign: "center" }}>
                Sending Invite
                <span className={styles.dots}>
                  <span>.</span>
                  <span>.</span>
                  <span>.</span>
                </span>
              </div>
            </FlexCol>
          </>
        )}
      </FlexCol>
    </FlexCol>
  );
}

function InviteUser({ show, setShow, roles, refetch }) {
  const [currRole, setCurrRole] = useState({
    value: roles[0]?.id,
    label: roles[0]?.name,
  });
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [joinCode, setJoinCode] = useState("");
  const InviteUserMutation = useInviteUser();
  const ref = useRef(null);

  function getOptions(options) {
    let response = [];
    for (let option of options) {
      if (option?.id) {
        response.push({
          value: option?.id,
          label: option?.name,
          color: option?.color,
        });
      }
    }
    return response;
  }

  function SendInvite() {
    setSending(true);
    InviteUserMutation.mutate(
      {
        email,
        roleId: currRole?.value,
      },
      {
        onSuccess: (data) => {
          setSuccess(true);
          setJoinCode(data?.InviteUser?.code);

          // setShow(false);
        },
      }
    );
  }

  function cancel() {
    setJoinCode("");
    setEmail("");
    setSending(false);
    setSuccess(false);
    setShow(undefined);
  }

  return (
    <Modal
      show={show}
      onClose={() => setShow(undefined)}
      modalStyle={{
        borderRadius: ".5rem",
        width: "600px",
        overflow: "visible",
      }}
      // hideX
      dark
    >
      <FlexCol className={styles.inviteUser}>
        <Header
          style={{
            borderTopLeftRadius: ".5rem",
            borderTopRightRadius: ".5rem",
          }}
        >
          <FlexRow justify={"space-between"}>
            <h2>Invite a user</h2>{" "}
          </FlexRow>
        </Header>
        <FlexCol style={{ padding: "2rem" }}>
          {sending && success && (
            <>
              <FlexRow
                gap={".5rem"}
                style={{ marginBottom: "1rem" }}
                justify={"center"}
              >
                <div className={styles.text_1} style={{ textAlign: "center" }}>
                  Invite Sent
                </div>
                <Player
                  autoplay={true}
                  loop={false}
                  ref={ref}
                  onEvent={(e) => {
                    if (e === "play") {
                      setTimeout(() => {
                        ref?.current?.pause();
                      }, 2000);
                    }
                  }}
                  src={require("assets/animations/checkmarkgreen.json")}
                  style={{
                    height: "40px",
                  }}
                />
              </FlexRow>
              <div className={styles.text_3} style={{ margin: "0" }}>
                Your invite was successfully sent to {email}. If they claim to
                have not recieved the email after 10 minutes, have them check
                their junk/spam folder.
                <br /> <br />
                If the email is still not found, Use the following join code and
                send the email externally or try the invite again:{" "}
                <span className={styles.blue}>{joinCode}</span>
              </div>
              <div
                className={styles.text_1}
                style={{ margin: "1rem 0", textAlign: "center" }}
              ></div>
              <div
                className={styles.text_3}
                style={{ margin: "0rem 0", textAlign: "center" }}
              >
                Get help from{" "}
                <span className={styles.link}>
                  Reaction Support <i className="bi-box-arrow-up-right"></i>
                </span>
              </div>
              <FlexRow justify={"flex-end"}>
                <Button shadow onClick={cancel}>
                  Close
                </Button>
              </FlexRow>
            </>
          )}
          {sending && !success && (
            <>
              <FlexCol gap={"0"}>
                <Player
                  autoplay
                  loop
                  src={require("assets/animations/EmailSending.json")}
                  style={{
                    height: "150px",
                    // width: "100px",
                  }}
                />
                <div className={styles.text_2} style={{ textAlign: "center" }}>
                  Sending Invite
                  <span className={styles.dots}>
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                  </span>
                </div>
              </FlexCol>
            </>
          )}
          {!sending && (
            <>
              Send an invite link to an email. Their role can be changed later.
              This invite expires after 48 hours.
              <FlexCol
                style={{ marginTop: "1rem", marginBottom: "0" }}
                gap="1rem"
              >
                <TextFieldSimple
                  type={"email"}
                  icon="at"
                  value={email}
                  onChange={setEmail}
                  label="Email Address*"
                  placeholder="jdoe@example.com"
                ></TextFieldSimple>
                <SelectFieldCustom
                  options={getOptions(roles)}
                  value={currRole}
                  onChange={(v) => setCurrRole(v)}
                  label="Role*"
                  formatOptionLabel={(data) => (
                    <FlexRow justify={"space-between"}>
                      {data?.label}{" "}
                      <Status color={data?.color}>{data?.label}</Status>
                    </FlexRow>
                  )}
                ></SelectFieldCustom>
              </FlexCol>
              <div className={styles.text_3} style={{ margin: "1.5rem 0" }}>
                Learn more about{" "}
                <span className={styles.link}>
                  User Roles <i className="bi-box-arrow-up-right"></i>
                </span>
              </div>
              <FlexRow justify={"center"}>
                <Button onClick={cancel} width={100}>
                  Cancel
                </Button>{" "}
                <Button
                  blue
                  shadow
                  onClick={SendInvite}
                  width={120}
                  disable={!currRole || !isValidEmail(email)}
                >
                  Send Invite
                </Button>
              </FlexRow>
            </>
          )}
        </FlexCol>
      </FlexCol>
    </Modal>
  );
}

function ConfrimChangeRole({ roleData, setShow, orgId }) {
  const changeRole = useChangeUserRole();
  const [saveWords, setSaveWords] = useState("Submit");

  function handleChangeRole() {
    setSaveWords(
      <>
        Saving <Loading width={20} height={20}></Loading>
      </>
    );
    changeRole.mutate(
      {
        orgId: orgId,
        userId: roleData?.user?.id,
        roleId: roleData?.newRole?.value,
      },
      {
        onSuccess: (data) => {
          setSaveWords("Saved");
          location.reload();
        },
      }
    );
  }

  return (
    <Modal
      show={roleData}
      onClose={() => setShow(undefined)}
      modalStyle={{ padding: "2rem", borderRadius: ".5rem", maxWidth: "470px" }}
      dark
    >
      <div className={styles.header_3} style={{ margin: "0" }}>
        Change User Permissions
      </div>
      <div className={styles.text_3} style={{ margin: "0" }}>
        Changing user's permissions will change their access throughout the
        website.
      </div>
      <FlexRow style={{ marginTop: "1.5rem", marginBottom: "0" }}>
        <ImageEditor
          src={roleData?.user?.image?.imageURL}
          canEdit={false}
          isUser={true}
          userId={roleData?.user?.id}
          position={
            roleData?.user?.image?.position &&
            typeof roleData?.user?.image?.position === "string"
              ? JSON.parse(roleData?.user?.image?.position)
              : roleData?.user?.image?.position
          }
          image={roleData?.user?.image}
          // refetch={getUser.refetch}
          height={35}
          width={35}
          noShadow
        ></ImageEditor>
        <span
          className={styles.text_2}
          style={{ display: "flex", gap: "0rem", flexDirection: "column" }}
        >
          <span style={{ fontWeight: "500" }}>
            {roleData?.user.firstName} {roleData?.user.lastName}
          </span>
          <FlexRow>
            <span style={{ textDecoration: "line-through" }}>
              {roleData?.prevRole?.label}
            </span>
            <i className="bi-arrow-right"></i>
            <span className={styles.blue}> {roleData?.newRole?.label} </span>
          </FlexRow>
        </span>
      </FlexRow>
      <div className={styles.text_3} style={{ margin: "1.5rem 0" }}>
        Learn more about{" "}
        <span className={styles.link}>
          User Permissions <i className="bi-box-arrow-up-right"></i>
        </span>
      </div>
      <FlexRow justify={"space-between"}>
        <Button shadow>Cancel</Button>{" "}
        <Button blue shadow onClick={() => handleChangeRole()}>
          {saveWords}
        </Button>
      </FlexRow>
    </Modal>
  );
}
