import React, { useState, useEffect } from "react";
import styles from "./Upload.module.scss";
import { trimDate } from "assets/functions/DateFunctions";
import { ImportedCleaner } from "./ImportedCleaner/ImportedCleaner";
import {
  useFetchContactByUploadId,
  useFetchContactByUploadIdCount,
} from "api/resources/contacts/contacts";
import ContactTable from "pages/contacts/ContactTable";
import { useParams } from "react-router-dom";
import { UploadPreview } from "pages/contacts/previous_imports/PreviousImports";
import { useFetchContactImportGql } from "api/resources/contacts/uploads";
import Icon from "components/Icon/Icon";
import Button from "components/Button/Button";

function UnifinishedUpload({ upload, setUpload, audience }) {
  return (
    <>
      <FetchUploadContainer
        audience={audience}
        uploadId={upload?.id}
        setUpload={setUpload}
      ></FetchUploadContainer>
    </>
  );
}

export default UnifinishedUpload;

function FetchUploadContainer({ audience, uploadId, setUpload }) {
  const { id } = useParams();
  const getUpload = useFetchContactImportGql(audience ? uploadId : id);

  const [refresher, setRefresher] = useState(false);
  useEffect(() => {
    getUpload.refetch();
  }, [refresher]);

  return (
    <>
      {getUpload.isSuccess && (
        <UploadContainer
          upload={getUpload?.data?.getUpload}
          setUpload={() => (audience ? setUpload(null) : history.back())}
          refresher={refresher}
          setRefresher={setRefresher}
          audience={audience}
        ></UploadContainer>
      )}
    </>
  );
}

const tabBarItems = [
  {
    id: 0,
    name: "Failed",
  },
  {
    id: 1,
    name: "Succeeded",
  },
];

function UploadContainer({
  upload,
  setUpload,
  audience,
  refresher,
  setRefresher,
}) {
  const [active, setActive] = useState(0);

  return (
    
      <div className={styles.uploadContainer}>
        <h3>
          {" "}
          {audience && <>"{audience?.name}"</>} Import Attempt:{" "}
          {upload?.uploadDate ? trimDate(upload?.uploadDate, true) : ""}{" "}
        </h3>
        <Button onClick={() => setUpload(null)} shadow style={{ gap: ".5rem" }}>
          <Icon green iconName={"arrow-left"}></Icon> Go Back
        </Button>
        <div className={styles.preview}>
          <UploadPreview
            upload={upload}
            active={0}
            refresher={refresher}
            setRefresher={setRefresher}
            audience={upload?.audienceId}
          ></UploadPreview>
        </div>

        {/* Contacts with errors */}
        {active === 0 && <ImportedCleaner upload={upload} />}

        {active === 1 && (
          <UploadedContacts upload={upload} orgId={upload.organizationId} />
        )}

        {/* Contacts Uploaded */}
      </div>
    
  );
}

function UploadedContacts({ upload, orgId }) {
  const [perPage, setPerPage] = useState(15);
  const [pageSkip, setPageSkip] = useState(0);
  const [sort, setSort] = useState({
    descend: true,
    item: "email",
  });

  const searchContact = useFetchContactByUploadId(
    "",
    pageSkip,
    perPage,
    sort,
    upload.id
  );

  const searchContactCount = useFetchContactByUploadIdCount(
    "",
    pageSkip,
    perPage,
    sort,
    upload.id
  );

  return (
    <div className={styles.tableContainer}>
      {searchContact.isSuccess && searchContactCount.isSuccess && (
        <ContactTable
          contacts={searchContact.data.contactsObject.contacts}
          orgId={orgId}
          sort={sort}
          setSort={setSort}
          perPage={perPage}
          setPerPage={setPerPage}
          setPageSkip={setPageSkip}
          count={searchContactCount.data.count}
        ></ContactTable>
      )}
    </div>
  );
}
