import styles from "./MetricSettings.module.scss";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useRef, useState } from "react";
import { TextEntry } from "components/inputs";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { NumSlider } from "components/inputs/input_fields/NumSlider/NumSlider";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import IconShopperModal from "./IconShopper/IconShopperModal";
import { useParams } from "react-router-dom";
import { useFetchReport } from "api/resources/organization/reports";
import AddToSingleMetric from "./AddMetric/AddToMetric";
import { DynamicOption } from "../SurveyData/DynamicOption/DynamicOption";
import { SmallCheckbox } from "components/inputs/input_fields/SmallCheckbox/SmallCheckbox";
import { Loading } from "components/Loading/Loading";

export default function SingleMetric({
  metric,
  ind,
  onDelete,
  updateSingle,
  settings,
  open,
  toggle,
  viz,
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: metric.id ? metric.id : metric.title });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.9 : 1,
    zIndex: isDragging ? 10 : 0,
    // justifyContent: "space-between",
    gap: "10px",
  };

  // const [open, setOpen] = useState(false);

  const active = open == ind;

  return (
    <div
      {...attributes}
      style={style}
      ref={setNodeRef}
      className={`${styles.singleMetric} ${active ? styles.active : ""}`}
    >
      <div
        className={`${styles.header} ${
          active ? styles.openHeader : styles.inactive
        } `}
        onClick={toggle}
      >
        <div className={styles.metricTitle}>{metric.title}</div>
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <span className={styles.metricSettingToggle}>
            <i className={`bi bi-caret-left-fill`}></i>
          </span>

          <i
            className={`${styles.grip} bi bi-list`}
            style={
              isDragging
                ? { cursor: "grabbing" }
                : open > -1
                ? { visibility: "hidden", pointerEvents: "none" }
                : undefined
            }
            {...listeners}
          ></i>
        </div>
      </div>

      {active && (
        <Setting
          metric={metric}
          settings={settings}
          onDelete={onDelete}
          updateSingle={updateSingle}
          viz={viz}
        />
      )}
    </div>
  );
}

function Setting({ metric, settings, onDelete, updateSingle, viz }) {
  const ref = useRef();
  const colorRef = useRef();

  const [spin, setSpin] = useState(false);

  const [showShopper, setShowShopper] = useState(false);
  const [showFor, setShowFor] = useState(false);

  function changeSingle(field, val) {
    let copy = { ...metric };
    copy[field] = val;
    updateSingle(copy);
  }

  function onSpinClick() {
    setSpin(!spin);

    let curr = setting("iconOrder", "row");
    let update;
    if (curr === "row") {
      update = "row-reverse";
    } else {
      update = "row";
    }
    changeSingle("iconOrder", update);
  }

  function getQOptions() {
    let category;
    if (metric.code === "npsScore") {
      category = QOptions.nps;
    }
    if (metric.code === "numberOfComments") {
      category = QOptions.comments;
    }

    let options = category?.map((q) => {
      return {
        label: (
          <div className={styles.col} style={{ alignItems: "center" }}>
            {q.questionText}{" "}
            <div className={styles.customizerQProjName}>{q.project.name}</div>
          </div>
        ),
        value: q.id,
      };
    });

    return options;
  }

  function getChosen() {
    let total = getQOptions();
    let current = [];
    for (let one of total) {
      if (metric.question.includes(one.value)) {
        current.push(one);
      }
    }
    return current;
  }

  function onQChange(options) {
    let qs = options.map((opt) => opt.value);
    changeSingle("question", qs);
  }

  function setting(field, backup) {
    if (field in metric) {
      return metric[field];
    }
    if (field + "s" in settings) {
      return settings[field + "s"];
    }

    return backup;
  }

  const alignmentOptions = ["left", "center", "right"];

  const stackOptions = [
    { icon: "text-left", value: "start" },
    { icon: "text-center", value: "center" },
    { icon: "text-right", value: "end" },
  ];

  const { reportid } = useParams();

  function onAddedTo(copy) {
    updateSingle(copy);
    setShowFor(false);
  }

  function removeProject(proj) {
    let copy = { ...metric };
    let ind = copy.projects.findIndex((p) => p.id === proj.id);
    copy.projects.splice(ind, 1);
    if (copy.questions) {
      let ind = copy.questions.findIndex((q) => q.projectId == proj.id);
      while (ind > -1) {
        copy.questions.splice(ind, 1);
        ind = copy.questions.findIndex((q) => q.projectId == proj.id);
      }
    }
    updateSingle(copy);

    setShowFor(
      !(copy.projects.length || (copy.dynamic && copy.dynamic.length))
    );
  }

  function removeDynamic(index) {
    let copy = { ...metric };
    copy.dynamic.splice(index, 1);
    updateSingle(copy);
    setShowFor(!(copy.projects.length || copy.dynamic.length));
  }

  function changeTimeFrame(i, tf) {
    let copy = { ...metric };
    if (copy.dynamic[i].timeFrame === "selection") {
      delete copy.dynamic[i].selection;
    }
    copy.dynamic[i].timeFrame = tf;
    if (copy.dynamic[i].timeFrame === "selection") {
      copy.dynamic[i].selection = [];
    }
    updateSingle(copy);
  }

  function changeSelection(i, project) {
    let copy = { ...metric };
    let index = copy.dynamic[i].selection.indexOf(project.id);
    if (index > -1) {
      copy.dynamic[i].selection.splice(index, 1);
    } else {
      copy.dynamic[i].selection.push(project.id);
    }

    updateSingle(copy);
  }

  function hasManyQs() {
    return (
      metric.questions.length > 1 || (metric.dynamic && metric.dynamic.length)
    );
  }

  return (
    <div ref={ref} className={styles.singleSettings}>
      {metric.code !== "custom" && (
        <div
          className={`${styles.microSetting} ${styles.forWhatSurveys}`}
          style={{ gap: "0px" }}
        >
          <div
            className={styles.microSettingTitle}
            style={{ paddingBottom: "5px" }}
          >
            For
          </div>

          <div
            style={{ width: "fit-content", gap: "10px" }}
            className={styles.microSetting}
          >
            {metric.dynamic &&
              metric.dynamic.map((dynamic, i) => (
                <DynamicOption
                  dynamic={dynamic}
                  onRemove={() => removeDynamic(i)}
                  changeTimeFrame={(tf) => changeTimeFrame(i, tf)}
                  changeSelection={(p) => changeSelection(i, p)}
                />
              ))}

            {metric.projects.length > 0 && (
              <div className={styles.microSetting}>
                {metric.projects.map((p) => (
                  <div className={styles.metricProject}>
                    {p.name}{" "}
                    <div
                      className={styles.removeSurvey}
                      onClick={() => removeProject(p)}
                    >
                      <i className="bi bi-x"></i>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {!showFor && (
              <div className={styles.addContainer}>
                <div
                  className={styles.addProj}
                  onClick={() => setShowFor(true)}
                >
                  + Add
                </div>
              </div>
            )}
          </div>

          {showFor && (
            <>
              {reportid && (
                <MiddleMan
                  onSave={onAddedTo}
                  viz={viz}
                  metric={metric}
                  reportId={reportid}
                  close={() => setShowFor(false)}
                />
              )}
              {!reportid && (
                <AddToSingleMetric
                  onSave={onAddedTo}
                  viz={viz}
                  metric={metric}
                  close={() => setShowFor(false)}
                />
              )}
            </>
          )}
        </div>
      )}

      {(metric.code === "npsAll" || (metric.code === "nps" && hasManyQs())) && (
        <div className={styles.microSetting} style={{ fontWeight: "100" }}>
          <div className={styles.subHeader}>NPS</div>
          {/* <OneOrTheOther
            left="Each Person's Most Recent Score"
            right="Count Every Score"
            style={{ fontWeight: "200" }}
            checked={metric.code === "npsAll"}
            onCheck={onChangeNps}
            activeColor={"#6793a5"}
            inactiveColor={"#a3a4a8"}
          /> */}

          <div
            className={styles.row}
            style={{
              gap: "10px",
              color: metric.code === "nps" ? "#6793a5" : "",
              paddingLeft: "5px",
            }}
          >
            <SmallCheckbox
              checked={metric.code === "nps"}
              onChange={() => changeSingle("code", "nps")}
            />{" "}
            <span>Take each person's most recent score</span>
          </div>
          <div
            className={styles.row}
            style={{
              gap: "10px",
              color: metric.code === "npsAll" ? "#6793a5" : "",
              paddingLeft: "5px",
            }}
          >
            <SmallCheckbox
              checked={metric.code === "npsAll"}
              onChange={() => changeSingle("code", "npsAll")}
            />{" "}
            Count every score
          </div>
        </div>
      )}

      <div className={styles.microSetting}>
        <div className={styles.subHeader}>Title</div>
        <TextEntry
          value={metric.title}
          onChange={(val) => changeSingle("title", val)}
        />

        <div className={styles.row} style={{ gap: "15px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ padding: "0px 5px" }}
          >
            Size
          </div>
          <NumSlider
            value={setting("titleSize", 12)}
            min={8}
            max={40}
            step={1}
            onChange={(val) => changeSingle("titleSize", val)}
            color={"#2A627C"}
          ></NumSlider>
          <NumberInput
            startNumber={setting("titleSize", 12)}
            min={8}
            max={40}
            step={1}
            handleNumberChange={(val) => changeSingle("titleSize", val)}
            color={"#2A627C"}
          ></NumberInput>
        </div>

        <div className={styles.row} style={{ gap: "10px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ paddingLeft: "5px" }}
          >
            Color
            <i className="bi bi-fonts"></i>
          </div>

          <ColorPicker
            onChange={(color) => changeSingle("titleColor", color)}
            defaultColor={setting("titleColor", "#050606")}
            colorRef={colorRef}
            textColors
          />
        </div>

        <div className={styles.row} style={{ gap: "10px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ paddingLeft: "5px" }}
          >
            Background Color <i className="bi bi-paint-bucket"></i>
          </div>

          <ColorPicker
            onChange={(color) => changeSingle("titleBackgroundColor", color)}
            defaultColor={setting("titleBackgroundColor", "#ffffff")}
            colorRef={colorRef}
          />
        </div>

        <div className={styles.row} style={{ gap: "15px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ padding: "0px 5px", fontWeight: 600 }}
          >
            Bold
          </div>
          <NumSlider
            value={setting("titleBold", 0)}
            min={0}
            max={4}
            step={1}
            onChange={(val) => changeSingle("titleBold", val)}
            color={"#2A627C"}
          ></NumSlider>
          <NumberInput
            startNumber={setting("titleBold", 0)}
            min={0}
            max={4}
            step={1}
            handleNumberChange={(val) => changeSingle("titleBold", val)}
            color={"#2A627C"}
          ></NumberInput>
        </div>

        {setting("titleBackgroundColor", "#ffffff") !== "#ffffff" && (
          <div className={styles.row} style={{ gap: "20px" }}>
            <div
              className={styles.microSettingTitle}
              style={{ paddingLeft: "5px" }}
            >
              Border Curve <div className={styles.mockCurve}></div>
            </div>

            <NumSlider
              value={setting("titleBorderRadius", null)}
              min={0}
              max={20}
              step={1}
              onChange={(val) => changeSingle("titleBorderRadius", val)}
              color={"#2A627C"}
            ></NumSlider>
          </div>
        )}

        <div className={styles.row} style={{ gap: "12px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ paddingLeft: "5px" }}
          >
            Align
          </div>

          {alignmentOptions.map((a) => (
            <div
              className={`${styles.alignmentBtn} ${
                metric.titleAlignment === a ? styles.chosenAlignment : ""
              }`}
              onClick={() => changeSingle("titleAlignment", a)}
            >
              <i className={`bi bi-text-${a}`}></i>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.microSetting}>
        <div className={styles.subHeader}>Value</div>
        {metric.code === "custom" && (
          <TextEntry
            value={metric.value}
            onChange={(val) => changeSingle("value", val)}
          />
        )}

        <div className={styles.row} style={{ gap: "15px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ padding: "0px 5px" }}
          >
            Size
          </div>
          <NumSlider
            value={setting("valueSize", 12)}
            min={8}
            max={50}
            step={1}
            onChange={(val) => changeSingle("valueSize", val)}
            color={"#2A627C"}
          ></NumSlider>
          <NumberInput
            startNumber={setting("valueSize", 12)}
            min={8}
            max={50}
            step={1}
            handleNumberChange={(val) => changeSingle("valueSize", val)}
            color={"#2A627C"}
          ></NumberInput>
        </div>

        <div className={styles.row} style={{ gap: "10px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ paddingLeft: "5px" }}
          >
            Color
            <i className="bi bi-fonts"></i>
          </div>

          <ColorPicker
            onChange={(color) => changeSingle("valueColor", color)}
            defaultColor={setting("valueColor", "#050606")}
            colorRef={colorRef}
            textColors
          />
        </div>

        <div className={styles.row} style={{ gap: "15px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ padding: "0px 5px", fontWeight: 600 }}
          >
            Bold
          </div>
          <NumSlider
            value={setting("valueBold", 0)}
            min={0}
            max={4}
            step={1}
            onChange={(val) => changeSingle("valueBold", val)}
            color={"#2A627C"}
          ></NumSlider>
          <NumberInput
            startNumber={setting("valueBold", 0)}
            min={0}
            max={4}
            step={1}
            handleNumberChange={(val) => changeSingle("valueBold", val)}
            color={"#2A627C"}
          ></NumberInput>
        </div>
        {/* <div className={styles.row} style={{ gap: "10px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ paddingLeft: "5px" }}
          >
            Background Color <i className="bi bi-paint-bucket"></i>
          </div>

          <ColorPicker
            onChange={(color) => changeSingle("valueBackgroundColor", color)}
            defaultColor={setting("valueBackgroundColor", "#ffffff")}
            colorRef={colorRef}
          />
        </div>

        {setting("valueBackgroundColor", "#ffffff") !== "#ffffff" && (
          <div className={styles.row} style={{ gap: "20px" }}>
            <div
              className={styles.microSettingTitle}
              style={{ paddingLeft: "5px" }}
            >
              Border Curve <div className={styles.mockCurve}></div>
            </div>

            <NumSlider
              value={setting("valueBorderRadius", null)}
              min={0}
              max={20}
              step={1}
              onChange={(val) => changeSingle("valueBorderRadius", val)}
              color={"#2A627C"}
            ></NumSlider>
          </div>
        )} */}

        <div className={styles.row} style={{ gap: "12px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ paddingLeft: "5px" }}
          >
            Align
          </div>

          {alignmentOptions.map((a) => (
            <div
              className={`${styles.alignmentBtn} ${
                metric.valueAlignment === a ? styles.chosenAlignment : ""
              }`}
              onClick={() => changeSingle("valueAlignment", a)}
            >
              <i className={`bi bi-text-${a}`}></i>
            </div>
          ))}
        </div>

        {(metric.code === "nps" ||
          metric.code === "flywheel" ||
          metric.code === "average" ||
          metric.code === "responseRate") && (
          <div
            className={styles.row}
            style={{ gap: "15px", paddingTop: "5px" }}
          >
            <div
              className={styles.microSettingTitle}
              style={{ padding: "0px 5px" }}
            >
              Decimal Digits
            </div>

            <NumberInput
              startNumber={setting("sigFigs", 0)}
              min={0}
              max={3}
              step={1}
              handleNumberChange={(val) => changeSingle("sigFigs", val)}
              color={"#2A627C"}
            ></NumberInput>
          </div>
        )}
      </div>

      {settings.display === "column" && (
        <div className={styles.microSetting}>
          <div className={styles.subHeader}>Stack</div>{" "}
          <div
            className={styles.row}
            style={{ paddingLeft: "20px", gap: "15px" }}
          >
            {stackOptions.map((p) => (
              <div
                className={`${styles.alignmentBtn} ${
                  metric.stack === p.value ? styles.chosenAlignment : ""
                }`}
                onClick={() => changeSingle("stack", p.value)}
              >
                <i className={`bi bi-${p.icon}`}></i>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className={styles.microSetting}>
        <div className={styles.row} style={{ gap: "25px" }}>
          <div className={styles.microSettingTitle}>Show Icon</div>
          <ToggleSwitch
            startChecked={setting("showIcon", false)}
            handleCheck={(val) => changeSingle("showIcon", val)}
          />
        </div>

        {setting("showIcon", false) && (
          <>
            <div
              className={styles.row}
              style={{ gap: "20px", padding: "7px 0px 10px 20px" }}
            >
              <div className={styles.orderButton} onClick={onSpinClick}>
                <div
                  className={styles.microSettingTitle}
                  style={{ fontSize: ".9em" }}
                >
                  Order
                </div>
                <i
                  className={`bi bi-arrow-repeat ${styles.spinIcon} ${
                    spin ? styles.spin : ""
                  }`}
                ></i>
              </div>
              <div
                className={styles.smallMetric}
                style={{ flexDirection: setting("iconOrder", "row") }}
              >
                <div className={styles.smallIcon}>
                  <i className={`bi bi-check`}></i>
                </div>
                <div
                  className={styles.text}
                  style={{
                    fontSize: "9pt",
                    ...(setting("iconOrder", "row") === "row-reverse"
                      ? { textAlign: "right" }
                      : {}),
                  }}
                >
                  <div className={styles.mTitle}>Title</div>
                  <div className={styles.mmetric}>Value</div>
                </div>
              </div>
            </div>

            <div
              className={styles.col}
              style={{ paddingLeft: "15px", gap: "5px", paddingBottom: "5px" }}
            >
              <div className={styles.row} style={{ gap: "10px" }}>
                <div className={styles.microSettingTitle}>
                  Color
                  <i className="bi bi-info-lg"></i>
                </div>

                <ColorPicker
                  defaultColor={setting("iconColor", "#ffffff")}
                  onChange={(color) => changeSingle("iconColor", color)}
                  colorRef={colorRef}
                  textColors
                />
              </div>

              <div className={styles.row} style={{ gap: "10px" }}>
                <div className={styles.microSettingTitle}>
                  Background Color <i className="bi bi-paint-bucket"></i>
                </div>

                <ColorPicker
                  defaultColor={setting("iconBGColor", "#A4C6D0")}
                  onChange={(color) => changeSingle("iconBGColor", color)}
                  colorRef={colorRef}
                />
              </div>
            </div>

            <div
              className={styles.row}
              style={{ gap: "15px", paddingLeft: "15px", width: "fit-content" }}
            >
              <div className={styles.microSettingTitle}>Icon:</div>
              <div className={styles.col}>
                <div
                  className={styles.changeableIcon}
                  style={{
                    color: setting("iconColor", "#ffffff"),
                    backgroundColor: setting("iconBGColor", "#A4C6D0"),
                  }}
                  onClick={() => setShowShopper(true)}
                >
                  <i className={`bi bi-${metric.icon}`}></i>
                </div>
              </div>

              {showShopper && (
                <IconShopperModal
                  onPick={(icon) => changeSingle("icon", icon)}
                  onClose={() => setShowShopper(false)}
                />
              )}
            </div>

            <div className={styles.row} style={{ gap: "15px" }}>
              <div
                className={styles.microSettingTitle}
                style={{ paddingRight: "10px" }}
              >
                Icon Size
              </div>
              <NumSlider
                value={setting("iconSizes", 12)}
                min={10}
                max={30}
                step={1}
                onChange={(val) => changeSingle("iconSize", val)}
                color={"#2A627C"}
              ></NumSlider>
              <NumberInput
                startNumber={setting("iconSize", 12)}
                min={10}
                max={30}
                step={1}
                handleNumberChange={(val) => changeSingle("iconSize", val)}
                color={"#2A627C"}
              ></NumberInput>
            </div>

            <div className={styles.row} style={{ gap: "15px" }}>
              <div
                className={styles.microSettingTitle}
                style={{ paddingRight: "10px" }}
              >
                Text / Icon Gap
              </div>
              <NumSlider
                value={setting("textIconGap", 5)}
                min={0}
                max={55}
                step={1}
                onChange={(val) => changeSingle("textIconGap", val)}
                color={"#2A627C"}
              ></NumSlider>
              <NumberInput
                startNumber={setting("textIconGap", 5)}
                min={0}
                max={55}
                step={1}
                handleNumberChange={(val) => changeSingle("textIconGap", val)}
                color={"#2A627C"}
              ></NumberInput>
            </div>
          </>
        )}
        <div className={styles.row} style={{ gap: "15px" }}>
          <div
            className={styles.microSettingTitle}
            style={{ paddingRight: "10px" }}
          >
            Title / Value Gap
          </div>
          <NumSlider
            value={setting("gap", 0)}
            min={0}
            max={50}
            step={1}
            onChange={(val) => changeSingle("gap", val)}
            color={"#2A627C"}
          ></NumSlider>
          <NumberInput
            startNumber={setting("gap", 0)}
            min={0}
            max={50}
            step={1}
            handleNumberChange={(val) => changeSingle("gap", val)}
            color={"#2A627C"}
          ></NumberInput>
        </div>
      </div>

      <div className={styles.delete} onClick={onDelete}>
        Delete <i className="bi bi-trash3"></i>
      </div>

      {/* {metric?.custom && (
        <>
          <div className={styles.row} style={{ gap: "15px" }}>
            <div className={styles.col} style={{ gap: "10px" }}>
              <Label
                style={{
                  fontWeight: "500",
                  color: "#8dabb2",
                  width: "fit-content",
                }}
              >
                Title
              </Label>
              <Label
                style={{
                  fontWeight: "500",
                  color: "#8dabb2",
                  width: "fit-content",
                }}
              >
                Value
              </Label>
            </div>
            <div className={styles.col} style={{ gap: "10px" }}>
              <div className={styles.mTitle}>
                <TextFieldSimple
                  value={metric.title}
                  onSave={(val) => changeSingle("title", val)}
                  onEnter={(val) => changeSingle("title", val)}
                  // onChange={(val) => metric.title = val}
                  style={{ fontWeight: "400", color: "#a3a4a8" }}
                  placeholder="Title"
                />
              </div>
              <div className={styles.mmetric}>
                <TextFieldSimple
                  value={metric.metric}
                  onSave={(val) => changeSingle("metric", val)}
                  onEnter={(val) => changeSingle("metric", val)}
                  // onChange={(val) => metric.metric = val}
                  style={{ color: "#050606" }}
                  placeholder="value"
                />
              </div>
            </div>
          </div>
          {metric.showIcon && (
            <div className={styles.row} style={{ justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <IconShopper onPick={(val) => changeSingle("icon", val)} />
              </div>
            </div>
          )}
        </>
      )} */}

      {/* {metric?.question && QOptions && (
        <div
          className={styles.row}
          style={{
            gap: "20px",
            alignItems: "center",
            width: "100%",
            zIndex: 1000,
          }}
        >
          <Label
            style={{
              fontWeight: "600",
              color: "#8dabb2",
              padding: 0,
              width: "fit-content",
            }}
          >
            Question
          </Label>

          <SelectField
            onChange={onQChange}
            value={getChosen()}
            options={getQOptions()}
            selectMultiple
          ></SelectField>
        </div>
      )}

      {(metric?.custom || metric?.question) && (
        <div className={styles.metricSettingBorder}></div>
      )} */}
    </div>
  );
}

function MiddleMan({ onSave, viz, metric, reportId, close }) {
  const getReport = useFetchReport(reportId);

  return (
    <>
      {getReport.isLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loading height={120} width={120} />
        </div>
      )}
      {getReport.isSuccess && (
        <AddToSingleMetric
          onSave={onSave}
          viz={viz}
          tiedIds={getReport.data.report.project.map((p) => p.id)}
          metric={metric}
          close={close}
        />
      )}
    </>
  );
}
