/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

import { useGqlQuery, useGqlMutation } from "api/Api";

export const useFetchColorPalettes = () => {
  const query = gql`
    query {
      colorPalettes: fetchOrgColorPallets {
        id
        colors
        name
        fill
        blended
        anchors
        isDefault
      }
    }
  `;

  return useGqlQuery(["color palettes"], query, {});
};

export const useFetchColorPaletteById = (id) => {
  const query = gql`
    query {
      palette: fetchPalletById(id: "${id}") {
        id
        colors
        name
        fill
        isDefault
        blended
        anchors
      }
    }
  `;

  return useGqlQuery(["color palette: " + id, "color palettes"], query, {});
};

export const useFetchReactionPalette = () => {
  const query = gql`
    query {
      palette: fetchReactionPalette {
        id
        colors
        name
        fill
        blended
        anchors
      }
    }
  `;

  return useGqlQuery(["reaction default palette"], query, {});
};

export const useCreateColorPalette = () => {
  const mutation = gql`
    mutation CreateColorPallet($data: ColorPalletInput!) {
      createColorPallet(data: $data) {
        id
        colors
        name
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("color palettes");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateColorPalette = () => {
  const mutation = gql`
    mutation UpdateColorPallet($data: ColorPalletInput!, $id: String!) {
      updateColorPallet(data: $data, id: $id) {
        id
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: (data) => {
      queryClient.invalidateQueries("color palettes");
      queryClient.invalidateQueries(
        "color palette: " + data?.updateColorPallet?.id
      );
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDeleteColorPalette = () => {
  const mutation = gql`
    mutation Mutation($id: String!) {
      deleteColorPalette(id: $id)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("color palettes");
    },
  };

  return useGqlMutation(mutation, options);
};
