/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

// External
import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation, useAsyncQuery, fetchGraphQLData } from "api/Api";

// GRAPHQL API

export const useFetchAudiencesSimple = (page, perPage, pageSort) => {
  class sortAudience {
    name;
    members;
    modifiedDate;
    createdDate;
    descend;
  }
  const sort = new sortAudience();
  sort.name = pageSort.name;
  sort.members = pageSort.members;
  sort.modifiedDate = pageSort.modifiedDate;
  sort.createdDate = pageSort.createdDate;
  sort.descend = pageSort.descend;
  const query = gql`
    query InfoForAudiences(
      $page: Float!
      $perPage: Float!
      $sort: sortAudience!
    ) {
      audiences: infoForAudiences(page: $page, perPage: $perPage, sort: $sort) {
        id
        name
        description
        members
        updatedAt
      }
    }
  `;

  return useGqlQuery(["audience simple"], query, {
    page: page,
    perPage: perPage,
    sort: sort,
  });
};

export const useFetchAudiencesForAContact = (id) => {
  const query = gql`
    query {
      getAContactsAudiences(id: "${id}") {
        updatedAt
        createdAt
        description
        id
        name
      }
    }
  `;

  return useGqlQuery(["audiences for " + id], query, {});
};

export const useSearchAllAudiences = (search, skip, perPage, sort, id) => {
  const query = gql`
    query (
      $search: String!
      $skip: Float!
      $perPage: Float!
      $sort: SortInput!
      $id: String!
    ) {
      response: searchAllAudiences(
        search: $search
        skip: $skip
        perPage: $perPage
        sort: $sort
        id: $id
      ) {
        audiences {
          id
          name
          icon
          iconColor
          createdAt
          updatedAt
          contactCount
          filters
        }
        count
        hasMore
      }
    }
  `;

  return useGqlQuery(["getAllAudiences:", id], query, {
    search,
    skip,
    perPage,
    sort,
    id,
  });
};

export const useSearchAudiences = async (search, skip, perPage, sort, id) => {
  const query = gql`
    query (
      $search: String!
      $skip: Float!
      $perPage: Float!
      $sort: SortInput!
      $id: String!
    ) {
      response: searchAllAudiences(
        search: $search
        skip: $skip
        perPage: $perPage
        sort: $sort
        id: $id
      ) {
        audiences {
          id
          name
          icon
          contactCount
        }
      }
    }
  `;
  const variables = {
    search,
    skip,
    perPage,
    sort,
    id,
  };
  try {
    const data = await fetchGraphQLData(query, variables);
    return data?.response?.audiences || []; // Return the fetched audience data
  } catch (error) {
    console.error("❌ Error fetching audiences:", error);
    return [];
  }
};

export const useAddAudienceToFolder = () => {
  const mutation = gql`
    mutation Mutation($audienceId: String!, $folderId: String!) {
      response: addAudienceToFolder(
        audienceId: $audienceId
        folderId: $folderId
      ) {
        success
      }
    }
  `;
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
  };

  return useGqlMutation(mutation, options);
};

export const useRemoveAudienceFromFolder = () => {
  const mutation = gql`
    mutation Mutation($audienceId: String!) {
      response: removeAudienceFromFolder(audienceId: $audienceId) {
        success
      }
    }
  `;
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
  };

  return useGqlMutation(mutation, options);
};

export const useAudienceQuery = (id) => {
  const query = gql`
    query {
      audience: audienceQuery(id: "${id}") {
        id
        createdAt
        description
        members
        name
        updatedAt
        timeZone
        dynamic
        filters
        icon
        iconColor
        columnOrder
      }
    }
  `;

  return useGqlQuery(["audience:" + id], query, {});
};

export const useCreateAudienceGql = () => {
  const mutation = gql`
    mutation CreateAudience($data: AudienceInput!) {
      audience: createAudience(data: $data) {
        id
        name
        description
        createdAt
        updatedAt
        icon
        iconColor
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("audiences");
    },
  };
  return useGqlMutation(mutation, options);
};

export const useCreateAudienceFolder = () => {
  const mutation = gql`
    mutation CreateAudience($data: FolderInput!) {
      folder: createAudienceFolder(data: $data) {
        id
        name
        audienceFolderParentId
        icon
        iconColor
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("audiences");
    },
  };
  return useGqlMutation(mutation, options);
};

export const useAddAudienceContactsViaUploadGql = () => {
  const mutation = gql`
    mutation addAudienceContactsViaUpload(
      $contactsList: contactArrayInput!
      $addAudienceContactsViaUploadId: String!
    ) {
      addAudienceContactsViaUpload(
        contactsList: $contactsList
        id: $addAudienceContactsViaUploadId
      ) {
        id
        contacts {
          email
          firstName
          lastName
          id
          phone
          position
          prefix
        }
        description
        members
        name
      }
    }
  `;
  // const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateAudienceGql = () => {
  const mutation = gql`
    mutation UpdateAudience(
      $data: UpdateAudienceInput!
      $id: String!
      $contactArray: contactIDS
    ) {
      audience: updateAudience(
        data: $data
        id: $id
        contactArray: $contactArray
      ) {
        updatedAt
        id
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("audience:" + data?.audience?.id);
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDeleteAudienceGql = () => {
  const mutation = gql`
    mutation DeleteAudience($deleteAudienceId: String!) {
      deleteAudience(id: $deleteAudienceId)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries(["audience"]);
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDeleteAudienceFolder = () => {
  const mutation = gql`
    mutation deleteAudienceFolder($id: String!) {
      deleteAudienceFolder(id: $id)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries(["audience"]);
    },
  };

  return useGqlMutation(mutation, options);
};

export const useSearchAudienceGql = (
  searchString,
  page,
  perPage,
  sort,
  identifier
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortAudience!
    ) {
      audiences: searchAudience(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
      ) {
        id
        createdAt
        description
        members
        name
        updatedAt
        timeZone
        dynamic
        filters
        adding
      }
    }
  `;

  return useGqlQuery(
    [
      "audiences:" + searchString + page + perPage + identifier
        ? identifier
        : "",
    ],
    query,
    {
      search: searchString,
      page: page,
      perPage: perPage,
      sort: sort,
    }
  );
};

export const useGetAudienceFolders = (sort) => {
  const query = gql`
    query Query($sort: SortInput!) {
      response: getOrganizationAudienceFolders(sort: $sort) {
        folders {
          id
          name
          icon
          organizationId
          audienceCount
        }
        count
      }
    }
  `;

  return useGqlQuery(["getUserAudienceFolders"], query, { sort: sort });
};

export const useUpdateAudienceAndFolders = () => {
  const mutation = gql`
    mutation Mutation($data: FolderInput!) {
      updateAudienceAndFolders(data: $data)
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useUpdateAudienceFolder = () => {
  const mutation = gql`
    mutation Mutation($data: FolderInput!) {
      folder: updateAudienceFolder(data: $data)
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useGetAudienceAndFolders = (identifier) => {
  const query = gql`
    query {
      audiences: getAudienceAndFolders {
        id
        name
        members
        folder
        changed
        collapsed
        icon
        iconColor
        filters
        dynamic
        children {
          id
          name
          members
          folder
          changed
          collapsed
          icon
          iconColor
          filters
          dynamic
          children {
            id
            name
            members
            folder
            changed
            collapsed
            icon
            iconColor
            filters
            dynamic
            children {
              id
              name
              members
              folder
              changed
              collapsed
              icon
              iconColor
              filters
              dynamic
              children {
                id
                name
                members
                folder
                changed
                collapsed
                icon
                iconColor
                filters
                dynamic
                children {
                  id
                  name
                  members
                  folder
                  changed
                  collapsed
                  icon
                  iconColor
                  filters
                  dynamic
                  children {
                    id
                    name
                    members
                    folder
                    changed
                    collapsed
                    icon
                    iconColor
                    filters
                    dynamic
                    children {
                      id
                      name
                      members
                      folder
                      changed
                      collapsed
                      icon
                      iconColor
                      filters
                      dynamic
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  return useGqlQuery(["audiencers:" + identifier ? identifier : ""], query, {});
};

export const useFetchAudienceCount = (searchString, page, perPage, sort) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortAudience!
    ) {
      count: searchAudienceCount(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
      )
    }
  `;

  return useGqlQuery(["AudienceCount"], query, {
    search: searchString,
    page: page,
    perPage: perPage,
    sort: sort,
  });
};

export const useFetchAllAudiences = () => {
  const query = gql`
    query AllAudiences {
      allAudiences {
        id
        name
        members
        filters
      }
    }
  `;

  return useGqlQuery(["all audiences"], query);
};

export const useAddContactToAudiences = () => {
  const mutation = gql`
    mutation Mutation($audienceIds: AudienceIds!, $contactId: String!) {
      addContactToAudiences(audienceIds: $audienceIds, contactId: $contactId)
    }
  `;
  return useGqlMutation(mutation);
};

export const useRemoveContactFromAudience = () => {
  const mutation = gql`
    mutation Mutation($audienceId: String!, $contactId: String!) {
      removeContactFromAudience(audienceId: $audienceId, contactId: $contactId)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries(["searchContacts"]);
    },
  };
  return useGqlMutation(mutation, options);
};

export const useRemoveContactsFromAudience = () => {
  const mutation = gql`
    mutation Mutation($id: String!, $contactArray: contactIDS!) {
      removeContactsFromAudience(id: $id, contactArray: $contactArray)
    }
  `;

  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
  };
  return useGqlMutation(mutation, options);
};

export const useAddContactsToAudience = () => {
  const mutation = gql`
    mutation AddContactsToAudience(
      $contactIds: ContactIds!
      $audienceId: String!
    ) {
      addContactsToAudience(contactIds: $contactIds, audienceId: $audienceId)
    }
  `;

  return useGqlMutation(mutation, {});
};
