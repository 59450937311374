import React, { useEffect, useState } from "react";
import styles from "./Organization.module.scss";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import { Loading } from "components/Loading/Loading";
import CreateOrganization from "./organization_details/CreateOrganization";
import {
  useFetchOrganizations,
  useGetOrgLogoUnique,
  useJoinOrgainization,
} from "api/resources/organization/organization";
import { useLogoutRequest } from "api/resources/authentication/logout";
import { useNewLoginRequest } from "api/resources/authentication/login";
import { useNavigate } from "react-router-dom";
import { Header } from "components/layouts";
import ReactModal from "../../components/ReactModal/ReactModal.jsx";
import Button from "components/Button/Button";
import { TextFieldSimple } from "components/inputs";
import ImageEditor from "components/Image Editor/ImageEditor";

function SwitchOrg({ show, currentUser, userRoles, setShow, setShowAddOrg }) {
  const [searchString, setSearchString] = useState("");

  const getOrganizations = useFetchOrganizations(searchString);
  const logoutRequest = useLogoutRequest();
  const loginRequest = useNewLoginRequest();
  let navigate = useNavigate();

  function handleLogout() {
    logoutRequest.mutate(
      {},
      {
        onSuccess: () => {
          // setToken(undefined);
          navigate("/login");
          localStorage.removeItem("reaction_token");
          window.location.reload();
        },
      }
    );
  }

  function handleLogin(orgId) {
    loginRequest.mutate(
      {
        orgid: orgId,
      },
      {
        onSuccess: () => {
          changeOrg(orgId);
        },
      }
    );
  }

  useEffect(() => {
    getOrganizations.refetch();
  }, [searchString]);

  //   const menuItems = [
  //     {
  //       id: 0,
  //       name: "Details",
  //       to: `org-details`,
  //       iconClassName: "bi bi-card-text",
  //     },
  //     {
  //       id: 1,
  //       name: "Users",
  //       to: `users`,
  //       iconClassName: "bi bi-person",
  //     },
  //     {
  //       id: 2,
  //       name: "Roles",
  //       to: `user-groups`,
  //       iconClassName: "bi bi-diagram-3",
  //     },
  //     {
  //       id: 3,
  //       name: "Images",
  //       to: `org-images`,
  //       iconClassName: "bi bi-images",
  //     },
  //   ];

  function changeOrg(id) {
    localStorage.setItem("activeorg", id);
    localStorage.setItem("activepage", 1);
    localStorage.removeItem("activeprojecttab");
    localStorage.removeItem("activecontactstab");
    localStorage.removeItem("activeorgtab");
    localStorage.removeItem("activeaccounttab");
    localStorage.removeItem("activeresultstab");
    localStorage.removeItem("activeanalysistab");
    localStorage.removeItem("activesurveystab");
    localStorage.removeItem("reportspageitem");
    localStorage.removeItem("activeaudiencetab");
    window.localStorage.removeItem("page1");
    window.localStorage.removeItem("page2");
    window.localStorage.removeItem("page3");
    window.localStorage.removeItem("page");
    navigate("/surveys");
    window.location.reload(false);
  }

  return (
    <div
      className={styles.organizations}
      style={{
        border: window.innerWidth < 700 ? "2px solid #E9E9E9" : "",
        position: "relative",
        minWidth: !show ? "0px" : "",
        padding: !show ? "0px" : "",
        border: !show ? "0px" : "",
        borderRight: !show ? "0px" : "",
      }}
    >
      <div className={styles.organizations2}>
        <div className={styles.header_3}>My Organizations</div>
        <div
          style={
            {
              // height: "75px",
              // maxHeight: "75px",
            }
          }
        >
          <NavTextField
            setValue={(val) => {
              setSearchString(val);
            }}
            value={searchString}
            placeholder="Search..."
            shadow
          ></NavTextField>
        </div>

        {getOrganizations.isLoading && <Loading></Loading>}
        {getOrganizations.isSuccess && (
          <div className={styles.orgs}>
            {getOrganizations.data.organizations?.map((org, i) => (
              <div className={styles.org} key={org?.id}>
                <OrgLogo
                  className={styles.avatar}
                  alt="user profile image"
                  orgId={org?.id}
                  index={i}
                  name={org.name}
                ></OrgLogo>

                <div className={styles.orgContent}>
                  <div className={styles.orgName}>{org.name}</div>
                  <div className={styles.orgBottom}>
                    <div style={{ display: "flex", gap: ".5em" }}>
                      {currentUser.organizationId === org?.id ? (
                        <a onClick={handleLogout}>Sign Out</a>
                      ) : (
                        <a onClick={() => handleLogin(org.id)}>Sign In</a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <div style={{ position: "absolute", bottom: "1rem" }}>
          <Button
            shadow
            onClick={() => {
              setShowAddOrg(true);
              setShow(false);
            }}
            style={{
              gap: ".75em",
              fontWeight: "400",
            }}
            height={2.4}
            padding={"0 1rem"}
            blue
          >
            Join Another Organization
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SwitchOrg;

function OrgLogo({ className, index, alt, orgId, name }) {
  const fetchLogo = useGetOrgLogoUnique(orgId, index);
  return (
    <>
      {fetchLogo.isSuccess && (
        <>
          <ImageEditor
            src={fetchLogo.data.getLogoLinkForOrg?.imageURL}
            alt={name}
            position={
              fetchLogo.data.getLogoLinkForOrg?.position &&
              typeof fetchLogo.data.getLogoLinkForOrg?.position === "string"
                ? JSON.parse(fetchLogo.data.getLogoLinkForOrg?.position)
                : fetchLogo.data.getLogoLinkForOrg?.position
            }
            image={fetchLogo.data.getLogoLinkForOrg}
            refetch={fetchLogo.refetch}
            height={50}
            width={150}
            noShadow
            isLogo
            scale
          ></ImageEditor>
        </>
      )}
    </>
  );
}

export function AddOrg({ show, setShow, create, join }) {
  const [addOrg, setAddOrg] = useState(false);
  const [joinOrg, setJoinOrg] = useState(false);

  return (
    <ReactModal
      show={show}
      onClose={() => setShow(false)}
      modalStyle={{
        borderRadius: ".5rem",
        width: "fit-content",
        maxWidth: "100%",
        overflow: "visible",
      }}
      dark
    >
      {(addOrg || joinOrg) && !create && !join && (
        <div
          className={styles.backarrow}
          onClick={() => {
            setAddOrg(false);
            setJoinOrg(false);
          }}
        >
          <i className="bi bi-arrow-left"></i>
        </div>
      )}
      {!addOrg && !joinOrg && !create && !join && (
        <div className={styles.createModal}>
          <div className={styles.fromTemplate} onClick={() => setJoinOrg(true)}>
            Join Organization
            <span>Use a Join Code to join an organization</span>
          </div>
          <div className={styles.fromScratch} onClick={() => setAddOrg(true)}>
            Create Organization <span>Create a brand new organization</span>
          </div>
        </div>
      )}
      {(addOrg || create) && (
        <CreateOrganization setShow={setShow}></CreateOrganization>
      )}
      {(joinOrg || join) && (
        <JoinOrganization setShow={setShow}></JoinOrganization>
      )}
    </ReactModal>
  );
}

function JoinOrganization({ setShow }) {
  const [joincode, setjoincode] = useState("");
  const joinOrg = useJoinOrgainization();

  function join(code) {
    joinOrg.mutate(
      {
        joinCode: code,
      },
      {
        onSuccess: () => {
          setShow(false);
        },
      }
    );
  }

  return (
    <div className={styles.joinOrgContainer}>
      <div
        style={{
          width: "100%",
          padding: "1em",
          display: "flex",
          flexDirection: "column",
          gap: "1em",
        }}
      >
        <TextFieldSimple
          label="Enter Join Code"
          value={joincode}
          onChange={(val) => setjoincode(val)}
          placeholder={"Join Code..."}
        ></TextFieldSimple>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button onClick={() => join(joincode)} shadow>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}
