import { useState } from "react";

//Internal
import styles from "./CreateJoinCode.module.scss";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import { useCreateUserJoinCode } from "api/resources/authentication/register";
import { Loading } from "components/Loading/Loading";
import Button from "components/Button/Button";
import { SelectFieldCustom } from "components/inputs";
import { useFetchOrgRoles } from "api/resources/organization/roles";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import Icon from "components/Icon/Icon";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { Header } from "components/layouts";
import Status from "components/Status/Status";

export default function CreateJoinCode({ onClose, }) {
  const fetchRoles = useFetchOrgRoles();
  const createJoinCode = useCreateUserJoinCode();
  const [role, setRole] = useState({
    value: "",
    label: "",
    id: "",
  });
  const [codeCreated, setCodeCreated] = useState(false);
  const [code, setCode] = useState();

  const [copied, setCopied] = useState(false);

  const handleCreateCode = () => {
    if (!createJoinCode.isLoading) {
      createJoinCode.mutate(
        {
          data: {
            forOrganization: true,
            forUser: true,
            roleId: role.id,
          },
        },
        {
          onSuccess: (data) => {
            setTimeout(() => {
              setCode(data.createCode.code);
              setCodeCreated(true);
            }, 500);
          },
        }
      );
    }
  };

  function getOptions() {
    let roles = fetchRoles.data.roles.map((role, index) => ({
      id: role.id,
      value: role.id,
      label: role.name,
      color: role.color,
    }));
    return roles;
  }

  return (
    <>
      {fetchRoles.isLoading && <p>Loading...</p>}
      {fetchRoles.isError && <p>Error</p>}
      {fetchRoles.isSuccess && (
        <ReactModal
          show
          onClose={onClose}
          modalStyle={{
            overflow: "visible",
          }}
          dark
        >
          <FlexCol className={styles.page}>
            <Header
              style={{
                borderTopLeftRadius: ".5rem",
                borderTopRightRadius: ".5rem",
              }}
            >
              <FlexRow align={"flex-end"}>
                <Icon
                  blue
                  iconName={"house-lock-fill"}
                  style={{ fontSize: "1.4rem" }}
                ></Icon>

                <h2>
                  {codeCreated
                    ? "Join Code Created!"
                    : "Create a new join code"}{" "}
                </h2>
              </FlexRow>
            </Header>

            <div className={styles.content}>
              {!codeCreated && (
                <div className={styles.fields}>
                  This one-time use code will allow someone to join your
                  organization with the assigned role. This code expires 30
                  minutes after creation.
                  <SelectFieldCustom
                    options={getOptions()}
                    value={role}
                    onChange={(val) => setRole(val)}
                    label="For Role"
                    formatOptionLabel={(data) => (
                      <FlexRow justify={"space-between"}>
                        {data?.label}{" "}
                        <Status color={data?.color}>{data?.label}</Status>
                      </FlexRow>
                    )}
                  ></SelectFieldCustom>
                  <div className={styles.saveBtn}>
                    <Button
                      onClick={handleCreateCode}
                      disable={!role.id}
                      shadow
                    >
                      {!createJoinCode.isLoading &&
                        !createJoinCode.isSuccess &&
                        !createJoinCode.isError &&
                        "Create Code"}
                      {createJoinCode.isLoading && (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Loading height="20" width="20"></Loading>Working
                        </div>
                      )}
                      {createJoinCode.isSuccess && (
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          <i className="bi bi-check-lg"></i>Created
                        </div>
                      )}
                      {createJoinCode.isError && "Error creating one"}
                    </Button>
                  </div>
                </div>
              )}
              {codeCreated && (
                <>
                Copy this code to give to an intended user. This is what they will use to register with the system.
              
                  <div className={styles.codeCreatedDiv}>
                    <div className={styles.code}>
                      {code}{" "}
                      <i
                        className={`${"bi bi-clipboard"} ${styles.clipboard}`}
                        onClick={() => {
                          navigator.clipboard.writeText(code);
                          setCopied(true);
                        }}
                      >
                        {copied && (
                          <i
                            className={`${"bi bi-check"} ${styles.checkmark}`}
                          ></i>
                        )}
                      </i>
                    </div>
                  </div>
                </>
              )}
            </div>
          </FlexCol>
        </ReactModal>
      )}
    </>
  );
}
