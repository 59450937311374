import ReactModal from "components/ReactModal/ReactModal.jsx";
import Button from "components/Button/Button";
import {
  getLocalTime,
  getNowInTimeZone,
  getScheduledDate,
  isInSameZone,
  trimDate,
} from "assets/functions/DateFunctions";
import { Label } from "components/layouts/Label/Label";
import styles from "./Scheduler.module.scss";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { defaultTimeZone, maintimezones } from "assets/functions/Variables";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { Calendar } from "./Calendar/Calendar";
import { Clock } from "./Clock/Clock";
import { useLayoutEffect, useState } from "react";
import { OneOrTheOther } from "components/inputs/input_fields/OneOrTheOther/OneOrTheOther";
import { Drip } from "../Drip/Drip";
import { SelectFieldCustom } from "components/inputs";
import { PrioritySwitchBoard } from "../PrioritySwitchboard/Switchboard";
import { PreTakeoffTest } from "../Test/PreTakeoffTest";

export default function Scheduler({
  email,
  changeField,
  onNext,
  onClose,
  origDate,
  origZone,
  status,
}) {
  const [b2b, setB2b] = useState(true);
  const [US, setUS] = useState(true);
  const [settingsHeight, setSettingsHeight] = useState("");

  useLayoutEffect(() => {
    // let el = document.getElementById("scheduler");
    let el = document.getElementById("calendarBox");
    if (el) {
      setSettingsHeight(el.clientHeight + "px");
    }
  }, []);

  function initAudienceSchedule() {
    if (email.audience && email.audience.length > 0) {
      let audSched = {};
      for (let aud of email.audience) {
        audSched[aud.id] = {
          id: aud?.id,
          name: aud?.name,
          timezone: aud?.timeZone
            ? aud?.timeZone
            : "Mountain Time (US) MST UTC-7",
          date: email.scheduledDate ? email.scheduledDate : new Date(),
        };
      }
      return audSched;
    }
  }

  function handleNext() {
    if (!email.scheduledDate) {
      changeField("scheduledDate", getNextBest());
    }

    onNext();
  }

  function getNextBest() {
    // Defaults to next Tue-Thursday day at 6:00 am
    let today = new Date();

    let moreDays = 0;
    let day = today.getDay();
    if (day == 0) {
      moreDays = 2; // Sunday + 2 = Tuesday
    } else if (day == 1 || day == 2 || day == 3) {
      moreDays = 1; // Mon or Tue - do next day
    } else if (day == 4) {
      moreDays += 5;
    } else if (day == 5) {
      moreDays += 4;
    } else if (day == 6) {
      moreDays += 3;
    }

    let nextBest = new Date();
    nextBest.setDate(today.getDate() + moreDays);
    nextBest.setHours(5, 30, 0, 0);
    return nextBest;
  }

  const zone = email.timeZone
    ? maintimezones.find((t) => t.timezone === email.timeZone)
    : defaultTimeZone;

  const date = email.scheduledDate
    ? getScheduledDate(email.scheduledDate)
    : getNextBest();

  // console.log(date);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const holidays = [...Global_Holidays, ...(US ? US_Holidays : [])];

  function byAHoliday() {
    for (let h of holidays) {
      if (matchesNearby(h, date)) return true;
    }
    return false;
  }

  function isHoliday() {
    for (let h of holidays) {
      if (matchesHoliday(h, date)) return true;
    }
    return false;
  }

  function getSuggestion() {
    if (isHoliday()) return <span className={styles.red}>Holiday</span>;
    if (byAHoliday())
      return (
        <span>
          Too close to a <span className={styles.red}> holiday</span>
        </span>
      );

    const weekday = date.getDay();

    if (b2b) {
      if (weekday == 6)
        return (
          <span>
            <span className={styles.red}>Not a great </span> day to send it
          </span>
        );
      if (weekday == 0 || weekday == 1 || weekday == 5)
        return (
          <span>
            <span className={styles.yellow}>Decent </span> day to send it
          </span>
        );
    } else if (weekday == 6) {
      return (
        <span>
          <span className={styles.red}>Not a great </span> day to send it
        </span>
      );
    }

    return (
      <span>
        <span className={styles.green}>Great </span>day to send it
      </span>
    );
  }

  function changeTZ(tz) {
    changeField("timeZone", tz.timezone);
  }

  function timesChanged() {
    if (origDate && origDate.getTime() !== date.getTime()) {
      return true;
    }
    if (!origZone) {
      if (zone.timezone !== defaultTimeZone.timezone) return true;
    } else if (origZone != zone.timezone) {
      return true;
    }
    return false;
  }

  const tzOptions = maintimezones.map((tz) => {
    if (tz.timezone === defaultTimeZone.timezone) {
      return {
        ...tz,
        label: (
          <FlexRow fit>
            {tz.label} <span className={styles.green}>*Default</span>
          </FlexRow>
        ),
      };
    }

    return tz;
  });

  function onSendNow() {
    let now = getNowInTimeZone(email.timeZone);
    changeField("scheduledDate", now);
    onNext();
  }

  return (
    <ReactModal
      show
      onClose={onClose}
      modalStyle={{
        borderRadius: "1em",
        padding: ".5rem 1rem 1rem",
        gap: "1em",
        // overflow: "visible",
        width: "fit-content",
      }}
      dark
    >
      <div className={styles.container}>
        <div className={styles.topHeader}>
          <Label style={{ paddingLeft: "0px" }}>
            {status === "Scheduled"
              ? "Email Is Scheduled for " + trimDate(email.scheduledDate)
              : `When would you like to start sending "${email.name}"?`}
          </Label>
        </div>
        <FlexRow
          gap={"30px"}
          align={"flex-start"}
          id="scheduler"
          style={{ paddingBottom: "10px", flexWrap: "wrap" }}
        >
          <div className={styles.settings}>
            <FlexCol gap={"5px"} fit>
              <div className={styles.label}>Showing Preferences For</div>
              <FlexRow gap={"5px"}>
                <div className={styles.label}>Market: </div>

                <OneOrTheOther
                  left="B2B"
                  right="B2C"
                  checked={!b2b}
                  onCheck={(val) => setB2b(!val)}
                  activeColor={"#6793a5"}
                  style={{ fontSize: ".85rem", paddingLeft: "5px" }}
                ></OneOrTheOther>
              </FlexRow>

              <FlexRow style={{ paddingLeft: "5px" }}>
                <ToggleSwitch startChecked={US} handleCheck={setUS} />{" "}
                <div className={styles.label}>U.S. Holidays</div>
              </FlexRow>
            </FlexCol>

            <Drip email={email} onChange={changeField} />

            <div>
              <SelectFieldCustom
                label={
                  <FlexRow justify={"space-between"}>
                    Time Zone{" "}
                    {zone.timezone === defaultTimeZone.timezone && (
                      <span>
                        <span className={styles.green}>Great </span>
                        timezone
                      </span>
                    )}
                  </FlexRow>
                }
                options={tzOptions}
                onChange={changeTZ}
                value={zone}
                style={{ fontSize: ".8rem" }}
              />
            </div>

            {!isInSameZone(zone.timezone) && (
              <div className={`${styles.label} ${styles.localTime}`}>
                <>{getLocalTime(date, zone.timezone)}</>
              </div>
            )}

            <PreTakeoffTest email={email} changeField={changeField} />

            {/* <SelectFieldCustom
              label="Cadence"
              options={maintimezones}
              onChange={changeTZ}
              value={zone}
              style={{ fontSize: ".8rem" }}
            /> */}

            {email.encoding && email.textMessageEncoding && (
              <PrioritySwitchBoard email={email} />
            )}
          </div>

          <FlexCol
            align={"center"}
            gap={"10px"}
            style={{ paddingLeft: "5px" }}
            fit
            id={"calendarBox"}
          >
            <div className={styles.bpSuggestion}>
              <li>{getSuggestion()}</li>
            </div>

            <Calendar
              date={date}
              onChange={(date) => changeField("scheduledDate", date)}
              b2b={b2b}
              US={US}
            ></Calendar>
          </FlexCol>

          <FlexCol align={"center"} gap={"20px"} fit>
            <div className={styles.dateDisplay}>
              <div className={styles.month}>{months[date.getMonth()]}</div>
              <div className={styles.day}>{date.getDate()}</div>
            </div>

            <div className={`${styles.fancy} ${styles.at}`}>At</div>

            <FlexCol align={"center"} gap={"0px"} fit>
              <Clock
                time={date}
                onChange={(time) => changeField("scheduledDate", time)}
                b2b={b2b}
                US={US}
              ></Clock>
            </FlexCol>
          </FlexCol>
        </FlexRow>
        <FlexRow className={styles.buttons}>
          <div style={{ visibility: "hidden" }}>
            <Button blue shadow>
              Send Now
            </Button>
          </div>

          <FlexRow gap={20} fit>
            {status == "Scheduled" && timesChanged() && (
              <div className={styles.red}>
                *Requires a reschedule to take affect
              </div>
            )}
            <Button shadow onClick={onClose} style={{ gap: "5px" }}>
              <i className="bi-x"></i>
              Cancel
            </Button>

            <Button blue onClick={handleNext} shadow style={{ gap: "10px" }}>
              <i className="bi-arrow-right"></i>
              Next
            </Button>
          </FlexRow>

          <Button blue shadow onClick={onSendNow} style={{ gap: "10px" }}>
            Send Now <i className="bi-watch"></i>
          </Button>
        </FlexRow>
      </div>
    </ReactModal>
  );
}

export const Global_Holidays = [
  {
    month: 0,
    date: 1,
    name: "New Year's Day",
    bufferBefore: 1,
    bufferAfter: 1,
  },
  {
    month: 11,
    date: 25,
    name: "Christmas Day",
    bufferBefore: 1,
    bufferAfter: 1,
  },
];

export const US_Holidays = [
  { month: 0, day: 1, num: 3, name: "Martin Luther King Jr. Day" },
  { month: 1, day: 1, num: 3, name: "President's Day" },
  { month: 4, day: 1, last: true, name: "Memorial Day" },
  { month: 6, date: 4, name: "July 4th" },
  { month: 8, day: 1, num: 1, name: "Labor Day" },
  { month: 10, day: 4, num: 4, name: "Thanksgiving Day", bufferAfter: 1 },
];

export function matchesHoliday(h, day) {
  if (h.month === day?.getMonth()) {
    if (h.date === day?.getDate()) return true;
    if (h.day === day?.getDay()) {
      if (h.num) {
        if (getWeekdayInMonth(day) === h.num) return true;
      } else if (h.last && isLastInMonth(day)) return true;
    }
  }
  return false;
}

export function matchesNearby(h, day) {
  if (h.bufferBefore) {
    for (let i = 0; i < h.bufferBefore; i++) {
      let copy = new Date(day);
      copy.setDate(day.getDate() + (i + 1));
      if (matchesHoliday(h, copy)) return true;
    }
  }

  if (h.bufferAfter) {
    for (let i = 0; i < h.bufferAfter; i++) {
      let copy = new Date(day);
      copy.setDate(day.getDate() - (i + 1));
      if (matchesHoliday(h, copy)) return true;
    }
  }

  return false;
}

function getWeekdayInMonth(day) {
  const month = day.getMonth();
  let count = 1;
  let done = false;
  let weekBefore = new Date(day);
  while (!done) {
    weekBefore.setDate(weekBefore.getDate() - 7);
    weekBefore.getMonth() == month ? count++ : (done = true);
  }

  return count;
}

function isLastInMonth(day) {
  const month = day.getMonth();
  let nextWeek = new Date(day);
  nextWeek.setDate(day.getDate() + 7);
  return nextWeek.getMonth() != month;
}
