import { useRef, useState } from "react";
import styles from "./From.module.scss";
import { useFetchEmailAddresses } from "api/resources/organization/users";
import { ErrorBanner } from "pages";
import { useFetchDomains } from "api/resources/organization/domains";
import { Loading } from "components/Loading/Loading";
import FlexRow from "components/layouts/FlexRow/FlexRow";

export function From({ alert, changeField }) {
  const [from, setFrom] = useState(alert.from ? parseFrom() : "");
  const [domain, setDomain] = useState(alert.from ? parseDomain() : "");
  const [showEmails, setShowEmails] = useState(false);
  const [showDomains, setShowDomains] = useState(false);

  const fetchEmails = useFetchEmailAddresses();
  const fetchDomains = useFetchDomains();

  function parseFrom() {
    const parts = alert.from.split("@");
    if (parts.length === 2) {
      return parts[0];
    }
    return "";
  }

  function parseDomain() {
    const parts = alert.from.split("@");
    if (parts.length === 2) {
      return parts[1];
    }
    return "";
  }

  function update(frm, dmn) {
    changeField("from", frm + "@" + dmn);
  }

  function updateDomain(d) {
    update(from, d.name);
    setDomain(d.name);
  }

  function splitEmail(email) {
    let split = email.split("@");
    if (split.length === 2) {
      return split[0];
    }
    return email;
  }

  function filterEmail(front) {
    if (from) {
      if (front == from) return false;
      return front.toLowerCase().includes(from.toLowerCase());
    }
    return true;
  }

  const ref = useRef(null);

  function setUpDomains() {
    setShowDomains(true);
    document.addEventListener("click", clickOut);
  }

  function clickOut(e) {
    if (!ref.current || !ref.current.contains(e.target)) {
      setShowDomains(false);
      document.removeEventListener("click", clickOut);
    }
    console.log("hit");
  }

  return (
    <>
      {fetchEmails.isError && (
        <ErrorBanner
          error={fetchEmails.error}
          message={"Error grabbing email addresses"}
        />
      )}
      {fetchDomains.isError && (
        <ErrorBanner
          error={fetchDomains.error}
          message={"Error grabbing domains"}
        />
      )}
      {(fetchEmails.isLoading || fetchDomains.isLoading) && (
        <Loading dim={25}></Loading>
      )}
      {fetchEmails.isSuccess && fetchDomains.isSuccess && (
        <FlexRow className={styles.from} fit>
          <div className={styles.nameContainer}>
            <input
              className={styles.fromInput}
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              onBlur={(e) => {
                update(e.target.value, domain);
                setShowEmails(false);
              }}
              onFocus={() => setShowEmails(true)}
              placeholder={"example"}
            />
            {showEmails && (
              <div className={styles.dropDown}>
                {fetchEmails.data.emails.map((email) => {
                  const front = splitEmail(email);
                  if (filterEmail(front)) {
                    return (
                      <div
                        key={email}
                        onMouseDown={() => {
                          update(front, domain);
                          setFrom(front);
                        }}
                        className={styles.option}
                      >
                        {front}
                      </div>
                    );
                  }
                  return <></>;
                })}
              </div>
            )}
          </div>

          <div className={styles.at}>@</div>
          <div className={styles.domainContainer} ref={ref}>
            <div
              className={styles.domain}
              onClick={showDomains ? null : setUpDomains}
            >
              {domain}
            </div>
            {showDomains && (
              <div className={styles.dropDown} style={{width: 'fit-content'}}>
                {fetchDomains.data.domains.map((d) => {
                  if (d.name == domain) return <></>;
                  return (
                    <div
                      key={d.id}
                      onClick={() => updateDomain(d)}
                      className={styles.option}
                    >
                      {d.name}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </FlexRow>
      )}
    </>
  );
}
