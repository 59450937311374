import { useMemo, useState } from "react";
import { motion } from "framer-motion";
import { DeliveryRoutes } from "routes";
import styles from "./Delivery.module.scss";
import { routeVariants } from "assets/functions/Variables";
import { TabBar } from "components/layouts";
import { Footer } from "App";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import Button from "components/Button/Button";
import { useLocation, useNavigate } from "react-router-dom";

export const Delivery = ({ survey, direction, onBuildEmail, updateRoute }) => {
  useMemo(() => {
    updateRoute(2);
  }, []);

  const location = useLocation();
  const path = location.pathname.split("/").slice(-1)[0];

  const menuItems = [
    {
      name: "Survey Link",
      to: "survey-link",
      icon: `link-45deg`,
    },
    {
      name: "Messages",
      to: "messages",
      icon: "send",
    },
    {
      name: "Personal Links",
      to: `personal-links`,
      icon: "person-lock",
    },
  ];

  function getAnimate() {
    if (!survey.activeLink) {
      return path == "messages";
    } else {
      return path == "survey-link";
    }
  }

  const navigate = useNavigate();

  function onItemClick(item) {
    navigate(item.to);
  }

  return (
    <div className={styles.page}>
      <div className={styles.pillContainer}>
        {menuItems.map((item) => (
          <Button
            style={{ gap: "7px" }}
            active={path == item.to}
            onClick={() => onItemClick(item)}
          >
            <i className={`bi bi-${item.icon}`}></i>
            {item.name}
          </Button>
        ))}
      </div>

      <motion.div
        variants={routeVariants}
        custom={{
          direction,
          animate: getAnimate(),
        }}
        initial="enter"
        animate="center"
        exit="exit"
        style={{ zIndex: 0 }}
        id="projectpage"
        className={styles.content}
      >
        <DeliveryRoutes survey={survey} onBuildEmail={onBuildEmail} />
      </motion.div>
    </div>
  );
};
