// interface Node {
//   value;
//   left?;
//   right?;
// }

import {
  useFindTagQsByName,
  useGetQuestion,
  usePullCalcAnswers,
  useTestCalculation,
} from "api/resources/organization/calculations";
import { Loading } from "components/Loading/Loading";
import { useEffect } from "react";

const access = ["?"];

const multDiv = ["/", "*", "%"];

const plusMinus = ["+", "-"];

const comparing = ["<", "<=", ">", ">=", "==", "!=", "or", "and"];

const assignment = ["="];

export const operators = [
  ...access,
  ...multDiv,
  ...plusMinus,
  ...comparing,
  ...assignment,
];

const orderOfOps = [access, multDiv, plusMinus, comparing, assignment];

export const functions = ["not", "avg", "max", "min", "sum", "count", "every"];

export function Calculator({
  calculation,
  contactIds,
  onDone,
  onError,
  filters,
}) {
  const findQs = useFindTagQsByName();
  const pullQuestion = useGetQuestion();
  const pullCalcAnswers = usePullCalcAnswers();

  const testCalcBackend = useTestCalculation();

  async function doCalculation() {
    try {
      function tokenize() {
        let lines = [];
        let line = [];
        let token = "";
        let literal = false;

        let chars = formula.split("");
        while (chars.length) {
          let char = chars.shift();
          if (!char) break;

          if (char == '"' && (literal || !token)) {
            if (literal) {
              // Literal is mainly for user friendly, it groups words together in one token by default
              if (token) line.push(token);
              token = "";
              literal = false;
            } else {
              literal = true;
            }
          } else if (literal) {
            token += char;
          } else if (char == " ") {
            if (token) line.push(token);
            token = "";
          } else if (char === "\n") {
            if (token) line.push(token);
            if (line.length) lines.push(line);
            line = [];
            token = "";
          } else if (char == "(" || char == ")" || operators.includes(char)) {
            if (token) line.push(token);
            line.push(char);
            token = "";
          } else {
            token += char;
          }
        }

        if (token) {
          line.push(token);
        }

        if (line.length) {
          lines.push(line);
        }

        return putStringsTogether(lines);
      }

      function putStringsTogether(lines) {
        let finalLines = [];
        for (let line of lines) {
          let slew = "";
          let final = [];

          for (let token of line) {
            if (
              operators.includes(token) ||
              functions.includes(token) ||
              token === ")" ||
              token === "("
            ) {
              if (slew) {
                final.push(slew);
                slew = "";
              }
              final.push(token);
            } else {
              if (slew) slew += " ";
              slew += token;
            }
          }

          if (slew) final.push(slew);
          finalLines.push(final);
        }

        return finalLines;
      }

      function isNumber(value) {
        if (typeof value === "number") return true;
        const num = parseFloat(value);
        if (!isNaN(num) && isFinite(num)) {
          if (num == value) {
            return true;
          }
        }
        return false;
      }

      async function findTagQs(name) {
        return await new Promise((res, rej) => {
          findQs.mutate(
            {
              tagName: name,
              mostRecent: calculation.mostRecentItr,
            },
            {
              onSuccess: (data) => {
                res(data.Qs);
              },
              onError: (err) => {
                rej(err);
              },
            }
          );
        });
      }

      async function pullQ(qId) {
        return await new Promise((res, rej) => {
          pullQuestion.mutate(
            {
              qId: qId,
            },
            {
              onSuccess: (data) => {
                res(data.question);
              },
              onError: (err) => {
                rej(err);
              },
            }
          );
        });
      }

      async function pullAnswers(qIds) {
        return await new Promise((res, rej) => {
          pullCalcAnswers.mutate(
            {
              qIds: {
                ids: qIds,
              },
              filters: JSON.stringify(filters),
            },
            {
              onSuccess: (data) => res(data.answers),
              onError: (err) => rej(err),
            }
          );
        });
      }

      function higherOpOrder(curr, other) {
        let thisInd = orderOfOps.findIndex((ops) => ops.includes(curr));
        let otherInd = orderOfOps.findIndex((ops) => ops.includes(other));
        return thisInd < otherInd;
      }

      function buildTree(lines) {
        let list = [];

        let lastNode = null;
        let rootNode = null;
        for (let tokens of lines) {
          while (tokens.length) {
            lastNode = structure(tokens);
          }

          function inside(tokens) {
            let tick = 1;
            while (tick) {
              let nextUp = tokens[0];
              if (!nextUp) throw new Error("Invalid Syntax: Incomplete Code");
              if (nextUp === ")") {
                tick--;
              } else {
                structure(tokens);
              }
            }

            if (rootNode) {
              lastNode = rootNode;
              rootNode = null;
            }

            return lastNode;
          }

          function structure(tokens) {
            let token = tokens.shift();

            if (!token) throw new Error("Invalid Syntax");

            let node;

            if (operators.includes(token)) {
              if (!lastNode) throw new Error("Invalid Syntax");

              const left = lastNode;

              node = {
                value: token,
                left: left,
                right: structure(tokens),
              };

              if (
                left.right &&
                operators.includes(left.value) &&
                !left.parenths &&
                higherOpOrder(token, left.value)
              ) {
                node.left = left.right;
                left.right = node;
                node.parent = left;
                rootNode = left;
              } else if (left.parent) {
                node.parent = left.parent;
                left.parent.right = node;
              }
            } else if (functions.includes(token)) {
              tokens.shift(); // consume the "("
              node = {
                value: token,
                right: inside(tokens),
                parenths: true,
              };
              tokens.shift(); // consume the ")";
            } else if (token === "(") {
              node = inside(tokens);
              node.parenths = true;
              tokens.shift(); // consume the ")"
            } else {
              // Leaf node
              node = {
                value: token,
              };
            }

            if (node.right) node.right.parent = node;
            if (node.left) node.left.parent = node;

            lastNode = node;
            return node;
          }

          if (rootNode) {
            list.push(rootNode);
          } else if (lastNode) {
            list.push(lastNode);
          }

          lastNode = null;
          rootNode = null;
        }

        return list;
      }

      async function compute(node) {
        if (typeof node.value === "string") {
          if (functions.includes(node.value)) {
            return await doFunction(node);
          }
          if (operators.includes(node.value)) {
            return await binaryOp(node);
          }

          return {
            value: await getVariable(node),
          };
        } else {
          return node;
        }
      }

      async function doFunction(node) {
        if (!node.right) throw new Error("Invalid Syntax");

        switch (node.value) {
          case "not":
            return await not(node);
          case "avg":
            return await avg(node);
          case "max":
            return await max(node);
          case "min":
            return await min(node);
          case "sum":
            return await sum(node);
          case "count":
            return await count(node);
          case "every":
            return await every(node);

          default:
            throw new Error("Invalid Syntax");
        }
      }

      async function not(node) {
        // Boolean 0 or 1
        let inside = await compute(node.right);
        return { value: inside.value ? 0 : 1 };
      }

      async function avg(node) {
        let total = 0;
        let count = 0;

        await iterate(node.right, (val) => {
          total += val;
          count++;
        });

        return { value: count ? total / count : 0 };
      }

      async function max(node) {
        let max = null;

        function compare(val) {
          if (max === null || val > max) max = val;
        }

        await iterate(node.right, compare);

        return { value: max ? max : 0 };
      }

      async function min(node) {
        let min = null;

        function compare(val) {
          if (min === null || val < min) min = val;
        }

        await iterate(node.right, compare);

        return { value: min ? min : 0 };
      }

      async function sum(node) {
        let total = 0;

        await iterate(node.right, (val) => (total += val));

        return { value: total };
      }

      async function count(node) {
        let count = 0;
        await iterate(node.right, (val) => {
          if (val) count++;
        });

        return { value: count };
      }

      async function every(node) {
        inEvery = true;
        return await compute(node.right);
      }

      async function iterate(inside, whatNow) {
        const { contactIds, anonIds } = await scanPopulateGather(inside); // scan for all q variables, pull all data, gather all contact ids and anon part id

        async function doIteration() {
          let clone = createClone(inside);
          let val = (await compute(clone)).value;
          if (typeof val === "string") {
            if (isNumber(val)) {
              whatNow(parseFloat(val));
            } else {
              whatNow(val ? 1 : 0);
              // throw new Error("Can't convert text to a number");
            }
          } else {
            whatNow(val);
          }
        }

        let before = inEvery;

        for (let id of contactIds) {
          contactId = id;
          await doIteration();

          if (inEvery) {
            function more() {
              for (let key in doneEvery) {
                if (!doneEvery[key]) return true;
              }
              return false;
            }

            while (more()) {
              everyInd++;
              await doIteration();
            }
            everyInd = 0;
            doneEvery = {};
          }
        }
        contactId = "";

        for (let id of anonIds) {
          anonId = id;
          await doIteration();
        }
        anonId = "";

        inEvery = before;
      }

      function createClone(node) {
        let root = { ...node };

        if (node.left) {
          root.left = createClone(node.left);
        }
        if (node.right) {
          root.right = createClone(node.right);
        }

        return root;
      }

      async function scanPopulateGather(node) {
        let variableNodes = [];

        function collect(node) {
          if (
            typeof node.value === "string" &&
            !variableNodes.some((n) => n.value == node.value) &&
            !functions.includes(node.value) &&
            !operators.includes(node.value)
          ) {
            variableNodes.push({ value: node.value });
          }

          if (node.left) {
            collect(node.left);
          }
          if (node.right) {
            collect(node.right);
          }
        }

        collect(node);

        for (let v of variableNodes) {
          await getVariable(v);
        }

        let contacts = {};
        let anonIds = [];
        for (let v of variableNodes) {
          if (answerMap[v.value]) {
            let obj = answerMap[v.value];
            let contactKeys = Object.keys(obj);
            if (obj.anon) {
              contactKeys = contactKeys.filter((key) => key !== "anon");
              anonIds = [...anonIds, ...Object.keys(obj.anon)];
            }

            for (let cId of contactKeys) {
              contacts[cId] = true;
            }
          }
        }

        let contactIds = Object.keys(contacts);

        return {
          contactIds,
          anonIds,
        };
      }

      async function binaryOp(node) {
        if (!node.right || !node.left) throw new Error("Invalid Syntaxt");

        // Short circuit logic
        if (node.value === "and") {
          return await and(node);
        }
        if (node.value === "or") {
          return await or(node);
        }

        // All others
        const left = await compute(node.left);
        const right = await compute(node.right);

        switch (node.value) {
          case "+":
            return add(left, right);
          case "-":
            return subtract(left, right);
          case "/":
            return divide(left, right);
          case "*":
            return multiply(left, right);
          case "%":
            return modulo(left, right);
          case "?":
            return answered(left, right);
          case "<":
            return lessThan(left, right);
          case "<=":
            return lessThanOrEqualTo(left, right);
          case ">":
            return greaterThan(left, right);
          case ">=":
            return greaterThanOrEqualTo(left, right);
          case "==":
            return equals(left, right);
          case "!=":
            return notEquals(left, right);
          case "=":
            return assign(left, right);

          default:
            throw new Error("Invalid Syntaxt: no matching operator");
        }
      }

      function convertToNum(node) {
        let val = node.value;
        if (isNumber(val)) {
          if (typeof val === "string") {
            val = parseFloat(val);
          }
          return val;
        }

        if (!node.value) return 0;

        throw new Error("Can't convert " + node.value + " to a number");
      }

      function getNumbers(left, right) {
        const l = convertToNum(left);
        const r = convertToNum(right);
        return { l, r };
      }

      function add(left, right) {
        const { l, r } = getNumbers(left, right);
        return {
          value: l + r,
        };
      }

      function subtract(left, right) {
        const { l, r } = getNumbers(left, right);
        return {
          value: l - r,
        };
      }

      function divide(left, right) {
        const { l, r } = getNumbers(left, right);
        return {
          value: l / r,
        };
      }

      function multiply(left, right) {
        const { l, r } = getNumbers(left, right);
        return {
          value: l * r,
        };
      }

      function modulo(left, right) {
        const { l, r } = getNumbers(left, right);
        return {
          value: l % r,
        };
      }

      function equals(left, right) {
        if (isNumber(left.value)) {
          if (isNumber(right.value)) {
            const { l, r } = getNumbers(left, right);
            return { value: l === r ? 1 : 0 };
          }
        } else if (!isNumber(right.value)) {
          // both strings
          return { value: left.value === right.value ? 1 : 0 };
        }

        throw new Error(
          `Invalid Syntaxt: Can't compare '${left.value}' with '${right.value}'`
        );
      }

      function notEquals(left, right) {
        let equal = equals(left, right);
        return { value: equal.value ? 0 : 1 };
      }

      function lessThan(left, right) {
        const { l, r } = getNumbers(left, right);
        return {
          value: l < r ? 1 : 0,
        };
      }

      function lessThanOrEqualTo(left, right) {
        const { l, r } = getNumbers(left, right);
        return {
          value: l <= r ? 1 : 0,
        };
      }

      function greaterThan(left, right) {
        const { l, r } = getNumbers(left, right);
        return {
          value: l > r ? 1 : 0,
        };
      }

      function greaterThanOrEqualTo(left, right) {
        const { l, r } = getNumbers(left, right);
        return {
          value: l >= r ? 1 : 0,
        };
      }

      function bool(node) {
        if (isNumber(node.value)) {
          let num = node.value;
          if (typeof node.value === "string") {
            num = parseFloat(node.value);
          }
          return num ? true : false;
        }
        return node.value ? true : false;
      }

      async function or(node) {
        const left = await compute(node.left);
        if (bool(left)) {
          return { value: 1 };
        }
        const right = await compute(node.right);
        return {
          value: bool(right) ? 1 : 0,
        };
      }

      async function and(node) {
        const left = await compute(node.left);
        if (bool(left)) {
          const right = await compute(node.right);
          return {
            value: bool(right) ? 1 : 0,
          };
        }
        return { value: 0 };
      }

      // function ternary(clause, left: Variable, right: Variable): Variable {
      //   let val = compute(clause);
      //   return val ? left : right;
      // }

      function answered(left, right) {
        // Boolean: 1 or 0;
        let check;
        if (right.value === "string") {
          check = isNumber(right.value) ? parseFloat(right.value) : right.value;
        } else {
          check = right.value;
        }

        let answer = getAnswer(left);
        if (Array.isArray(answer) && typeof check == "string") {
          return { value: answer.includes(check) ? 1 : 0 };
        }
        return { value: answer === check ? 1 : 0 };
      }

      function assign(left, right) {
        if (typeof left.value === "string" && !isNumber(left.value)) {
          variables[left.value] = right.value;
          return left;
        }
        throw new Error("Invalid Variable Assignment");
      }

      async function getVariable(node) {
        if (node.left || node.right)
          throw new Error("Invalid Syntax: Invalid variable: " + node.value); // Should only be leaf nodes here

        if (node.value in variables) {
          return variables[node.value];
        }

        if (typeof node.value === "string") {
          if (isNumber(node.value)) {
            return convertToNum(node);
          }

          if (node.value in answerMap) {
            if (node.parent?.value === "?") {
              return node.value;
            }
            let val = getAnswer(node);
            return Array.isArray(val) ? val.toString() : val;
          }

          await populateVariable(node);
          return getVariable(node);
        }

        throw new Error("Invalid syntax");
      }

      function getAnswer(node) {
        if (typeof node.value === "string") {
          if (contactId && contactId in answerMap[node.value]) {
            const answers = answerMap[node.value][contactId];

            if (inEvery) {
              let answer = answers[everyInd];

              if (!answer) {
                doneEvery[node.value] = true;
                return "";
              }

              doneEvery[node.value] = !(everyInd + 1 < answers.length);

              return thinOutAnswer(answer, node.value);
            }

            return thinOutAnswer(answers[0], node.value);
          }

          if (
            anonId &&
            answerMap[node.value].anon &&
            anonId in answerMap[node.value].anon
          ) {
            let anonAnswer = answerMap[node.value].anon[anonId];
            return thinOutAnswer(anonAnswer, node.value);
          }
        }

        if (
          answerMap[node.value] &&
          Object.keys(answerMap[node.value]).length == 1 &&
          root.value === node.value
        ) {
          for (let key in answerMap[node.value]) {
            let answers = answerMap[node.value][key];
            let answer = answers[0];
            if (answer) return thinOutAnswer(answer, node.value);
          }
        }

        return "";
      }

      function thinOutAnswer(answer, variableName) {
        
        if (answer.choiceAnswer !== null) {
          return answer.choiceAnswer;
        }
        if (answer.scaleAnswer !== null) {
          return answer.scaleAnswer;
        }
        if (answer.textAnswer !== null) {
          return answer.textAnswer;
        }
        if (answer.matrixAnswer !== null) {
          let values = variableName.split(".");
          trimPieces(values);
          if (values.length > 1) {
            // needs a .option
            let option = values.pop();
            let matrixAnswer = JSON.parse(answer.matrixAnswer);
            if (option in matrixAnswer) {
              return matrixAnswer[option];
            }
            return "";
          } else {
            return 1; // To count for a boolean
          }
        }

        throw new Error(
          "Can't grab the answer of " + variableName + " for " + contactId
        );
      }

      function trimPieces(pieces) {
        for (let i = 0; i < pieces.length; i++) {
          pieces[i] = pieces[i].trim();
        }
      }

      async function populateVariable(node) {
        if (typeof node.value != "string")
          throw new Error("Invalid variable name");
        let pieces = node.value.split(".");
        trimPieces(pieces);
        if (pieces.length == 1 || pieces.length == 2) {
          let tagQs = await findTagQs(pieces[0]);
          if (tagQs.length) {
            await putAnswersInUnder(node, tagQs);
            return;
          }
        }

        if (pieces.length >= 4 && pieces[0] == "P" && pieces[2] == "Q") {
          let qId = pieces[3];
          debugger;
          const Q = await pullQ(qId);
          if (Q) {
            await putAnswersInUnder(node, [Q]);
            return;
          }
        }

        variables[node.value] = node.value;
        // throw new Error("Unknown variable name: " + node.value);
      }

      async function putAnswersInUnder(node, Qs) {
        const answersObj = {};
        let answers = await pullAnswers(Qs.map((q) => q.id)); // Pull all data from those Q Ids (filtering if contactIds
        for (let a of answers) {
          if (a.participation.contactId) {
            if (!answersObj[a.participation.contactId]) {
              answersObj[a.participation.contactId] = [];
            }
            answersObj[a.participation.contactId].push(a);
          } else if (a.participation.id && !contactIds.length) {
            if (!answersObj.anon) answersObj.anon = {};
            answersObj.anon[a.participation.id] = a;
          }
        }

        for (let key in answersObj) {
          if (key !== "anon") {
            answersObj[key].sort((a, b) => {
              return (
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
              );
            });
          }
        }

        answerMap[node.value] = answersObj;
      }

      const formula = calculation.formula;
      const lines = tokenize();
      // console.log([...lines[0]]);
      const rootNodes = buildTree(lines);
      const variables = {};
      const answerMap = {};

      let contactId = "";
      let anonId = "";

      let inEvery = false;
      let doneEvery = {};
      let everyInd = 0;

      let last = null;
      let root = null;
      while (rootNodes.length) {
        root = rootNodes.shift();
        if (root) {
          last = await compute(root);
        }
      }

      if (!last) {
        throw new Error("Invalid Syntax");
      }

      let result = last.value in variables ? variables[last.value] : last.value;

      if (typeof result === "number") {
        const decimals = calculation.decimals ? calculation.decimals : 0;
        let rounded = result.toFixed(decimals);
        onDone(rounded);
      } else {
        onDone(result);
      }
    } catch (err) {
      onError(err.message);
    }
  }

  function pullCalc() {
    testCalcBackend.mutate(
      {
        formula: calculation.formula,
        decimals: calculation.decimals,
        filters: JSON.stringify(filters),
        mostRecent: calculation.mostRecentItr,
      },
      {
        onSuccess: (data) => onDone(data.result),
        onError: (err) => {
          let msg = err?.response?.errors[0]?.message;
          onError(msg);
        },
      }
    );
  }

  useEffect(() => {
    // doCalculation();
    pullCalc();
  }, []);

  return (
    <>
      Running <Loading height={30} width={30} />
    </>
  );
}
