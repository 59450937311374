import Modal from "components/ReactModal/ReactModal";
import styles from "./CreateUser.module.scss";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import { useState } from "react";
import { useFetchOrgRoles } from "api/resources/organization/roles";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Button from "components/Button/Button";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { Loading } from "components/Loading/Loading";
import { useCreateUser } from "api/resources/organization/organization";
import { Header } from "components/layouts";
import Icon from "components/Icon/Icon";
import { useValidateEmail } from "api/resources/authentication";
import Status from "components/Status/Status";

export function CreateUser({ onClose, onDone }) {
  const fetchRoles = useFetchOrgRoles();
  const create = useCreateUser();
  const [error, setError] = useState("");
  const [validConfirmPassword, setValidConfirmPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validPassword, setValidPassword] = useState();
  const [validEmail, setValidEmail] = useState([]);
  const [passwordType, setPasswordType] = useState({
    icon: "eye-slash",
    type: "password",
  });
  const [validationRules, setValidationRules] = useState([]);
  const [confirmPasswordType, setConfirmPasswordType] = useState({
    icon: "eye-slash",
    type: "password",
  });
  const [invalidPasswordMessage, setInvalidPasswordMessage] = useState(
    "Password must be at least 8 characters. Password must contain at least 1 uppercase letter, 1 lowercase letter, and 1 special character."
  );

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    roleId: "",
  });
  const [pswdCopy, setPswdCopy] = useState("");
  const [creating, setCreating] = useState(false);

  function validateUserPassword(p) {
    //   check if meets length requirement
    //   check if contains number
    //   check if contains capital letter
    //   check if contains lower case letter
    //   check if contains special character
    //   If doesn't meet requirments display message with what is missing
    let message = "";
    let valid = false;
    const rules = [
      {
        rule: "Password must be at least 8 characters and less than 64 characters.",
        valid: p.length >= 8 && p.length <= 64,
      },
      {
        rule: "Password must contain at least 1 uppercase letter.",
        valid: /[A-Z]/.test(p),
      },
      {
        rule: "Password must contain at least 1 lowercase letter.",
        valid: /[a-z]/.test(p),
      },
      {
        rule: "Password must contain at least 1 number.",
        valid: /[0-9]/.test(p),
      },
      {
        rule: "Password must contain at least 1 special character.",
        valid: /[" !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~"]/.test(p),
      },
    ];

    valid = rules.every((rule) => rule.valid);
    setValidationRules(rules);

    setInvalidPasswordMessage(message);
    setValidPassword(valid);
  }

  function handleChangePassword(password) {
    setUser({ ...user, password: password });
    validateUserPassword(password);
  }

  function handleChangeValidatePassword(p) {
    if (p === user?.password) {
      setValidConfirmPassword(true);
    } else {
      setValidConfirmPassword(false);
    }
    setConfirmPassword(p);
  }

  function changeField(field, val) {
    let copy = { ...user };
    copy[field] = val;
    setUser(copy);
  }

  function getRole() {
    if (user.roleId) {
      let role = fetchRoles.data.roles.find((r) => r.id === user.roleId);
      if (role) {
        return {
          value: role.id,
          label: role.name,
          color: role.color
        };
      }
    }
    return null;
  }

  function filled() {
    return (
      user.firstName &&
      user.lastName &&
      user.email &&
      user.roleId &&
      user.password &&
      user.password === confirmPassword &&
      validEmail
    );
  }

  function onCreate() {
    if (!creating) {
      create.mutate(
        {
          data: user,
        },
        {
          onSuccess: (data) => {
            setCreating(false);
            onDone();
            onClose();
          },
          onError: (data) => {
            setError(data?.response?.errors[0]?.message);
            setCreating(false);
          },
        }
      );

      setCreating(true);
      if (error) setError("");
    }
  }
  function handleChangeEmail(email) {
    setUser({ ...user, email: email.toLowerCase() });
    validateUserEmail(email);
  }
  const validateEmail = useValidateEmail();
  function validateUserEmail(email) {
    let rules = [
      {
        rule: "Email must not be empty.",
        valid: email.trim().length > 0,
      },
      {
        rule: "Email must contain '@' symbol.",
        valid: email.includes("@"),
      },
      {
        rule: "Email must have a domain name after '@'.",
        valid: email.split("@")[1]?.length > 0,
      },
      {
        rule: "Email must contain a top-level domain (e.g., .com, .org).",
        valid: /\.[a-zA-Z]{2,}$/.test(email),
      },
      {
        rule: "Email must not contain invalid characters.",
        valid: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email),
      },
    ];
    let valid = rules.every((rule) => rule.valid);
    if (!valid) {
      setValidEmail(rules);
      return;
    }
    validateEmail.mutate(
      {
        email: email,
      },
      {
        onSuccess: (data) => {
          if (!data.validateEmail) {
            setValidEmail([
              {
                ...{
                  rule: "Email is already assigned to an account",
                  valid: false,
                },
                ...rules,
              },
            ]);
          } else {
            setValidEmail([]);
          }
        },
      }
    );
  }

  return (
    <Modal
      show
      onClose={onClose}
      modalStyle={{
        // padding: "1.5rem",
        // borderRadius: ".5rem",
        // minWidth: "350px",
        width: "100%",
        maxWidth: "700px",
        overflow: "visible",
      }}
      dark
    >
      <div className={styles.container}>
        <Header
          style={{
            borderTopLeftRadius: ".5rem",
            borderTopRightRadius: ".5rem",
          }}
        >
          <FlexRow align={"flex-end"}>
            <Icon
              blue
              iconName={"person-circle"}
              style={{ fontSize: "1.5rem" }}
            ></Icon>

            <h2>Create User</h2>
          </FlexRow>
        </Header>
        {/* <FlexRow justify={"flex-start"} gap={20}>
          <img src={profPic} className={styles.prof}></img>
          <div className={styles.title}>Create User</div>
        </FlexRow> */}

        <FlexCol style={{ padding: "2rem" }} gap=".5rem">
          <FlexRow wrap={"wrap"}>
            <TextFieldSimple
              value={user.firstName}
              onChange={(val) => changeField("firstName", val)}
              label={"First Name"}
              blue
              containerStyle={{ flex: 1 }}
            />

            <TextFieldSimple
              value={user.lastName}
              onChange={(val) => changeField("lastName", val)}
              label={"Last Name"}
              blue
              containerStyle={{ flex: 1 }}
            />
          </FlexRow>
          <TextFieldSimple
            id="emailField"
            type="text"
            onChange={handleChangeEmail}
            value={user?.email}
            valid={validEmail?.length === 0 || !validEmail}
            // invalidMessage={"Invalid Email"}
            label="Email*"
            validationRules={validEmail}
          />

          {fetchRoles.isSuccess && (
            <SelectFieldCustom
              options={fetchRoles.data.roles.map((role, index) => ({
                value: role.id,
                label: role.name,
                color: role.color,
              }))}
              value={getRole()}
              onChange={(role) => changeField("roleId", role.value)}
              label="Role"
              formatOptionLabel={(data) => (
                <FlexRow justify={"space-between"}>
                  {data?.label}{" "}
                  <Status color={data?.color}>{data?.label}</Status>
                </FlexRow>
              )}
            ></SelectFieldCustom>
          )}

          <HorizontalBar
            height={1}
            style={{ marginTop: "1.5rem" }}
          ></HorizontalBar>

          <TextFieldSimple
            type={passwordType?.type}
            onChange={handleChangePassword}
            value={user?.password}
            valid={user?.password && validPassword}
            finalIcon={passwordType?.icon}
            onClickFinalIcon={() => {
              if (passwordType?.type === "password") {
                setPasswordType({
                  icon: "eye",
                  type: "text",
                });
              } else {
                setPasswordType({
                  icon: "eye-slash",
                  type: "password",
                });
              }
            }}
            invalidMessage={invalidPasswordMessage}
            password
            label="Password*"
            validationRules={validationRules}
            validationRulesTitle="Password Requirements"
          />
          <TextFieldSimple
            type={confirmPasswordType?.type}
            onChange={handleChangeValidatePassword}
            value={confirmPassword}
            finalIcon={confirmPasswordType?.icon}
            onClickFinalIcon={() => {
              if (confirmPasswordType?.type === "password") {
                setConfirmPasswordType({
                  icon: "eye",
                  type: "text",
                });
              } else {
                setConfirmPasswordType({
                  icon: "eye-slash",
                  type: "password",
                });
              }
            }}
            // placeholder="Confirm Password"
            valid={validConfirmPassword}
            invalidMessage={"Passwords must match"}
            password
            label="Confirm Password*"
          />

          {error && <div className={styles.error}>{error}</div>}

          <FlexRow justify={"center"} style={{ paddingTop: "15px" }}>
            <Button
              shadow
              blue
              disable={!filled()}
              onClick={onCreate}
              style={{ gap: "7px" }}
            >
              {creating ? (
                <>
                  <Loading height={25} width={25}></Loading> Creating
                </>
              ) : (
                <>+ Create</>
              )}
            </Button>
          </FlexRow>
        </FlexCol>
      </div>
    </Modal>
  );
}
