import Button from "components/Button/Button";
import styles from "./DoYouWantToSave.module.scss";
import { useState } from "react";
import { Loading } from "components/Loading/Loading";
import { Header } from "components/layouts";
import Modal from "components/ReactModal/ReactModal";

export default function DoYouWantToSave({
  navAway,
  onSave,
  prompt,
  cancelText,
  confirmText,
  about,
  header,
  showX,
  modalStyle,
  style
}) {
  const [saving, setSaving] = useState();

  function yes() {
    onSave();
    setSaving(true);
    setTimeout(() => {
      navAway();
    }, 1000);
  }

  return (
    <Modal show={true} dark onClose={navAway} hideX={!showX} modalStyle={modalStyle}>
      {header && <Header><h3>{header}</h3></Header>}
      <div className={styles.modal} style={style}>
        <div className={styles.prompt}>
          {prompt ? (
            prompt
          ) : (
            <>
              <div>
                You have <strong>unsaved changes</strong> in this{" "}
                {about ? about : "survey"}.
              </div>
              <div>Would you like to save before leaving?</div>
            </>
          )}
        </div>
        <div className={styles.buttons}>
          <Button shadow onClick={navAway}>
            {cancelText ? cancelText : "No, don't save"}
          </Button>
          <Button onClick={yes} shadow blue>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              {confirmText ? confirmText : "Save"}
              {saving && <Loading height="20" width="20"></Loading>}
            </div>
          </Button>
        </div>
      </div>
    </Modal>
  );
}
