import React, { useEffect, useState, useRef } from "react";

import styles from "./Notifications.module.scss";
import {
  useUpdateNotification,
  useDeleteNotification,
  useSearchNotifications,
} from "api/resources/account/notification";
import { Loading } from "components/Loading/Loading";
import { TabPill } from "components/layouts";
import user from "assets/images/blank-profile-picture.png";
import uparrow from "assets/images/UpArrow.png";
import reactionlogo from "assets/images/ReactionCircleLogo.png";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import { shortId } from "components/tables/EditableTable/utils";
import Button from "components/Button/Button";
import { useNavigate } from "react-router-dom";
import { ErrorBanner } from "pages/error_page/ErrorPage";

const days = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};

const months = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

export function getTime(date) {
  date = new Date(date);
  let hr = date.getHours();
  let ext = "am";
  if (hr > 12) {
    hr -= 12;
    ext = "pm";
  }
  let min = date.getMinutes();
  if (min < 10) {
    min = "0" + min;
  }
  let day = date.getDay();
  day = days[day];
  let month = date.getMonth();
  month = months[month];
  let dayofmonth = date.getDate();

  return (
    day + ", " + month + " " + dayofmonth + " at " + hr + ":" + min + " " + ext
  );
}

export function Notifications({ initUnread, user }) {
  const [readChecked, setReadChecked] = useState(false);
  const [unreadChecked, setUnreadChecked] = useState(false);
  const [showAllChecked, setShowAllChecked] = useState(true);
  const [readFlip, setReadFlip] = useState(false);
  const [unreadFlip, setUnreadFlip] = useState(false);
  const [showAllFlip, setShowAllFlip] = useState(false);
  const [descending, setDescending] = useState(true);
  const [showImportant, setShowImportant] = useState(false);
  const [unreadCount, setUnreadCount] = useState(false);
  const [active, setActive] = useState(0);
  const [notifs, setNotifs] = useState(0);
  const [selected, setSelected] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState("");
  const [perPage, setPerpage] = useState(0);
  const [pageSkip, setPageSkip] = useState(0);
  const [sort, setSort] = useState({
    item: "createdAt",
    descend: true,
  });

  const deleteNotification = useDeleteNotification();
  const fetchNotifications = useSearchNotifications(
    sort,
    pageSkip,
    perPage,
    search,
    active
  );
  // const fetchNotificationsCount = useSearchNotificationsCount(
  //   active === 0 ? true : false,
  //   search,
  //   "fjapfoiej"
  // );

  useEffect(() => {
    fetchNotifications.refetch();
    setNotifs(false);
  }, [active, perPage, search]);

  // function handleButtonClick() {
  //   markAllRead.mutate(
  //     {},
  //     {
  //       onSuccess: (data) => {
  //         console.log("Return Value: ", data);
  //         fetchNotifications.refetch({
  //           sort: {
  //             descending: descending,
  //             all: showAllChecked,
  //             unread: unreadChecked,
  //             read: readChecked,
  //             important: showImportant,
  //           },
  //         });
  //         setUnreadCount(0);
  //       },
  //     }
  //   );
  // }

  // useEffect(() => {
  //   fetchUnreadNotification.mutate(
  //     {},
  //     {
  //       onSuccess: (data) => {
  //         console.log("Return Value: ", data);
  //         setUnreadCount(data.getUnreadNotificationCount);
  //       },
  //     }
  //   );
  // }, []);

  // console.log(fetchNotifications);
  // useEffect(() => {
  //   fetchNotifications.refetch();
  // }, [descending, showAllChecked, unreadChecked, readChecked, showImportant]);

  function handleSortByDate() {
    setDescending(!descending);
  }

  function handleShowImportant() {
    setShowImportant(!showImportant);
  }

  function handleSortByRead() {
    setReadChecked(!readChecked);
    if (showAllChecked === true && unreadChecked === false) {
      setShowAllChecked(!showAllChecked);
      setShowAllFlip(!showAllFlip);
    } else {
      setUnreadChecked(!unreadChecked);
      setUnreadFlip(!unreadFlip);
    }
  }

  function handleSortByUnread() {
    setUnreadChecked(!unreadChecked);
    if (showAllChecked === true && readChecked === false) {
      setShowAllChecked(!showAllChecked);
      setShowAllFlip(!showAllFlip);
    } else {
      setReadChecked(!readChecked);
      setReadFlip(!readFlip);
    }
  }

  function handleSortByReadAll() {
    if (showAllChecked === false) {
      setShowAllChecked(!showAllChecked);
      if (unreadChecked === true && readChecked === false) {
        setUnreadChecked(!unreadChecked);
        setUnreadFlip(!unreadFlip);
      } else if (unreadChecked === false && readChecked === true) {
        setReadChecked(!readChecked);
        setReadFlip(!readFlip);
      }
    } else if (showAllChecked === true) {
      setShowAllChecked(!showAllChecked);
      setUnreadChecked(!unreadChecked);
      setUnreadFlip(!unreadFlip);
    }
  }

  function trimDate(date) {
    let d = new Date(date);
    let niceString = d.toDateString();
    let month = niceString.substring(4, 7);
    let day = niceString.substring(8, 10);
    //eslint-disable-next-line no-unused-expressions
    day.charAt(0) === "0" ? (day = day.charAt(1)) : null;
    let year = niceString.substring(11);
    return month + " " + day + ", " + year;
  }

  function onDelete(id) {
    deleteNotification.mutate(
      {
        deleteNotificationId: id,
      },
      {
        onSuccess: () => {
          fetchNotifications.refetch();
          setNotifs(false);
        },
      }
    );
  }

  // const tabBarItems = [
  //   {
  //     id: 0,
  //     name: "Not Viewed",
  //     // to: `questions`,
  //   },
  //   {
  //     id: 1,
  //     name: `Viewed`,
  //     // to: `design`,
  //   },
  // ];
  function getDayOfWeek(date) {
    date = new Date(date);
    let day = date.getDay();
    day = days[day];
    return day;
  }

  function sortNotifications() {
    let notifications = {
      today: [],
      week: [],
      month: [],
      older: [],
    };

    const today = new Date();
    const timezoneOffset = today.getTimezoneOffset();
    today.setMinutes(today.getMinutes() + timezoneOffset);

    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);

    const lastMonth = new Date(today);
    lastMonth.setMonth(lastMonth.getMonth() - 1);

    for (let notif of fetchNotifications.data.response) {
      let date = new Date(notif.createdAt);
      date.setMinutes(date.getMinutes() + timezoneOffset);
      if (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      ) {
        notif.sent = getTime(notif.createdAt);
        notifications.today.push(notif);
      } else if (
        date.getDate() === yesterday.getDate() &&
        date.getMonth() === yesterday.getMonth() &&
        date.getFullYear() === yesterday.getFullYear()
      ) {
        notif.sent = "Yesterday";
        notifications.week.push(notif);
      } else if (date > lastWeek) {
        notif.sent = getDayOfWeek(notif.createdAt);
        notifications.week.push(notif);
      } else if (date > lastMonth) {
        notif.sent = trimDate(notif.createdAt);
        notifications.month.push(notif);
      } else {
        notif.sent = trimDate(notif.createdAt);
        notifications.older.push(notif);
      }
    }

    return notifications;
  }

  function getNotifications() {
    if (notifs) {
      return notifs;
    }
    let frames = [];
    let frameSettings = {
      today: true,
      withinWeek: true,
      withinMonth: true,
      monthPlus: true,
    };

    let all = sortNotifications();

    let bucket = [];
    if (frameSettings.today) {
      let today = { title: "Today", notifications: all.today };
      frames.push(today);
    } else {
      if (!frameSettings.today) {
        bucket = [...all.today];
      }
    }

    if (frameSettings.withinWeek) {
      let week = {
        title: "Last 7 days",
        notifications: [...bucket, ...all.week],
      };
      frames.push(week);
      bucket = [];
    } else {
      bucket = [...bucket, ...all.week];
    }

    if (frameSettings.withinMonth) {
      let month = {
        title: "This month",
        notifications: [...bucket, ...all.month],
      };
      frames.push(month);
      bucket = [];
    } else {
      bucket = [...bucket, ...all.month];
    }

    if (frameSettings.monthPlus) {
      let older = {
        title: "Month +",
        notifications: [...bucket, ...all.older],
      };
      frames.push(older);
    }

    setNotifs(frames);
    return frames;
  }

  const navigate = useNavigate();

  return (
    <div className={styles.page}>
      <div className={styles.navContainer}>
        <div className={styles.pageHeader}>Notifications</div>
        {/* <TabPill
          tabBarItems={tabBarItems}
          active={active}
          updateActive={setActive}
          wings
          style={{ marginRight: "2em" }}
        ></TabPill> */}
      </div>

      {fetchNotifications.isError && (
        <ErrorBanner
          error={fetchNotifications.error}
          message="Error pulling notifications"
        />
      )}
      {deleteNotification.isError && (
        <ErrorBanner
          error={deleteNotification.error}
          message="Error deleting notification"
        />
      )}

      <>
        <div className={styles.notifsPage}>
          <div>
            <div className={styles.section}>
              <div className={styles.header}>
                Edit your notification settings
              </div>
              <Button
                link
                onClick={() => {
                  navigate("/notifications");
                }}
              >
                Edit Settings
              </Button>
              <div className={styles.description}>
                Click on a notification to mark it as read
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.header}>
                Configure your account and user properties
              </div>
              <Button
                link
                onClick={() => {
                  navigate("/Account");
                  localStorage.setItem("activepage", 7);
                }}
              >
                Edit Account
              </Button>
              <div className={styles.description}>
                No one else can view your notifications
              </div>
            </div>
          </div>
          <div className={styles.pageNotifContainer}>
            <NavTextField
              value={search}
              setValue={setSearch}
              placeholder="Search Notifications..."
              className={styles.search}
            ></NavTextField>
            {(fetchNotifications.isRefetching ||
              fetchNotifications.isLoading) && (
              <div className={styles.pageNotifContainer}>
                <Loading></Loading>
              </div>
            )}
            {!fetchNotifications.isRefetching &&
              fetchNotifications.isSuccess &&
              getNotifications().map((frame, i) => (
                <React.Fragment key={i}>
                  {frame.notifications.length != 0 && (
                    <>
                      <div className={styles.wordDivider}>{frame.title}</div>
                      <div className={styles.notifBlock}>
                        {frame.notifications.map((notification) => (
                          <Notification
                            key={notification.id}
                            notification={notification}
                            read={notification.read}
                            important={notification.important}
                            updateFunc={function (unread) {
                              if (unread) {
                                setUnreadCount((count) => count + 1);
                              } else {
                                setUnreadCount((count) => count - 1);
                              }
                            }}
                            click={function (notification) {
                              setSelected(notification);
                            }}
                            onDelete={onDelete}
                            getTime={getTime}
                          ></Notification>
                        ))}
                        {frame.notifications.length === 0 && (
                          <div className={styles.none}>None</div>
                        )}
                      </div>
                    </>
                  )}
                </React.Fragment>
              ))}
            {/* {fetchNotificationsCount.data.count >
                  fetchNotifications.data.notifications.length && (
                  <div className={styles.seeMore}>
                    <a onClick={() => setPerpage(perPage + 50)}>See More</a>
                  </div>
                )} */}
            {fetchNotifications.isSuccess &&
              fetchNotifications.data.response.length === 0 && (
                <div className={styles.none}>No notifications found</div>
              )}
          </div>
          <div>
            <div className={styles.section}>
              <div className={styles.description}>
                Expecting a notification that didn’t arrive? Go to the Finished
                Jobs page and see if your task has finished
              </div>
              <Button
                link
                onClick={() => {
                  navigate("/tasks-finished");
                  localStorage.setItem("activepage", 13);
                }}
              >
                Finished Jobs
              </Button>
            </div>
            <div className={styles.section}>
              <div className={styles.description}>
                Notifications are deleted after 30 days
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

const Notification = ({
  notification,
  read,
  important,
  updateFunc,
  onDelete,
  getTime,
}) => {
  const [readNotif, setReadNotif] = useState(read);
  const [importantNotif, setImportantNotif] = useState(important);
  const [deleted, setDeleted] = useState(false);
  const updateNotification = useUpdateNotification();
  const [options, setOptions] = useState(false);
  function getType(notif) {
    if (notif.type === "Import") {
      return uparrow;
    } else if (notif.type === "Survey Created") {
      return reactionlogo;
    } else if (notif.type === "Scheduler") {
      return require("assets/images/Calendar.png");
    } else {
      return user;
    }
  }

  const onNotifClick = (id) => {
    markRead();
    // localStorage.setItem("activeaccounttab", 1);
    // localStorage.setItem("activepage", 6);
    // navigate('account/notifications/' + id);
  };

  function markRead() {
    if (readNotif === false) {
      setReadNotif(true);
      updateNotification.mutate(
        {
          data: {
            read: true,
          },
          updateNotificationId: notification.id,
        },
        {
          onSuccess: () => {
            // setReadNotif(false);
            notification.read = true;
          },
        }
      );
      updateFunc(false);
    }
  }

  const ref2 = useRef(null);

  function clickOutside(e) {
    if (ref2.current && !ref2.current?.contains(e.target)) {
      setOptions(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const navigate = useNavigate();

  function handleClick(notif) {
    markRead();
    if (notif.type === "Import" && notif?.itemId) {
      navigate(`/previous-imports/${notif?.itemId}`);
    } else if (notif.type === "Scheduler" && notif?.itemId2) {
      navigate(`/project/${notif?.itemId2}/delivery/messages/${notif?.itemId}`);
    } else if (notif.type === "Results" && notif?.itemId && notif?.itemId2) {
      navigate(`/project/${notif?.itemId2}/surveyresults/${notif?.itemId}`);
    } else {
      navigate(`/previous-imports/${notif?.itemId}`);
    }
  }

  // function markUnread() {
  //   setReadNotif(false);
  //   updateNotification.mutate({
  //     data: {
  //       read: false,
  //     },
  //     updateNotificationId: notification.id,
  //   });
  //   updateFunc(true);
  // }

  // function markImportant() {
  //   setImportantNotif(!importantNotif);
  //   updateNotification.mutate({
  //     data: {
  //       important: !importantNotif,
  //     },
  //     updateNotificationId: notification.id,
  //   });
  // }

  // function hideDeleteNotification() {
  //   setDeleted(true);
  //   deleteNotification.mutate({
  //     deleteNotificationId: notification.id,
  //   });
  // }

  if (deleted === false) {
    return (
      <div
        className={styles.notificationContainer}
        onClick={() => onNotifClick(notification.id)}
      >
        <div
          className={!notification.read ? styles.notread : styles.read}
        ></div>
        <img
          src={getType(notification)}
          className={styles.avatar}
          alt="user profile image"
        />
        <div className={styles.notification}>
          <div className={styles.header}>{notification.body}</div>
          <div
            className={styles.navContainer}
            style={{ minHeight: "auto", padding: "0px", alignItems: "center" }}
          >
            {" "}
            {/* <div className={styles.notifHeader}>{notification.body}</div> */}
            <div className={styles.notifBody}>
              {getTime(notification.createdAt)}
            </div>
          </div>
        </div>
        <i
          className={`bi-three-dots-vertical ${styles.threeDots}`}
          onClick={(e) => {
            e.stopPropagation();
            setOptions(true);
          }}
        ></i>
        {options && (
          <div className={styles.settingsBox} ref={ref2}>
            {/* <div
              className={styles.menuItem}
              onClick={(e) => {
                e.stopPropagation();
                handleClick(notification);
              }}
            >
              <i
                className="bi-layout-text-sidebar-reverse"
                style={{ color: "#A7D5C5" }}
              ></i>{" "}
              View Details
            </div> */}

            <div
              className={styles.menuItem}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(notification.id);
              }}
            >
              <i className="bi-trash" style={{ color: "#FF8878" }}></i> Delete
            </div>

            {!notification.read && (
              <div
                className={styles.menuItem}
                onClick={(e) => {
                  e.stopPropagation();
                  onNotifClick(notification.id);
                }}
              >
                <i className="bi-check-circle" style={{ color: "#15BCC7" }}></i>{" "}
                Mark as read
              </div>
            )}
            {/* <div
              className={styles.menuItem}
              onClick={(e) => {
                e.stopPropagation();
                // handleCancel(notification);
              }}
            >
              <i className="bi-person-dash" style={{ color: "#FBD575" }}></i>{" "}
              unsubscribe
            </div> */}
          </div>
        )}
      </div>
    );
  } else {
    return;
  }
};
