import React, { useState, useEffect } from "react";
import styles from "./ImportHow.module.scss";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";
import Button from "components/Button/Button";
import { csv2json } from "csv42";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import Stat from "components/tables/BasicTable/SurveyWidgets/Stat";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import { useGetContactUpdates } from "api/resources/contacts/contacts";
import jschardet from "jschardet";
import { Loading } from "components/Loading Rectangle/Loading";
import { Loading as LoadingUpload } from "components/LoadingUpload/Loading";
import axios from "axios";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { formatByteSize } from "assets/functions/StringFunctions";
import { read, utils } from "xlsx";
// import { useNavigate } from "react-router-dom";

function ImportHow({ cancel, user, organization, objectColumns, audienceId }) {
  const [page, setPage] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [headers, setHeaders] = useState([]);
  const [headerMap, setHeaderMap] = useState({});
  const [fileName, setFileName] = useState("");
  const [checkPrefix, setCheckPrefix] = useState(true);
  const [checkSuffix, setCheckSuffix] = useState(true);
  const [checkSingle, setCheckSingle] = useState(true);
  const [checkEmailSyntax, setCheckEmailSyntax] = useState(true);
  const [emailme, setEmailme] = useState(true);
  const [data, setData] = useState([]);
  const [identityFields, setIdentityFields] = useState([]);
  const [checked, setChecked] = useState("add-update");
  const [numRows, setNumRows] = useState(0);
  const [numColumns, setNumColumns] = useState(0);
  const [fileSize, setFileSize] = useState("0B");
  const [importing, setImporting] = useState();
  const [failure, setFailure] = useState();
  const [success, setSuccess] = useState();
  // const [uploadId, setUploadId] = useState();
  // const navigate = useNavigate();

  function startImport() {
    setImporting(true);
    setSuccess("Import Started");
    const url = process.env.REACT_APP_UPLOADER_API_KEY;
    const formData = new FormData();

    formData.append("file", selectedFile);
    formData.append("columnMap", JSON.stringify(headerMap));

    const config = {
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      params: {
        audienceId: audienceId ? audienceId : "",
        organizationId: organization.id,
        appUserId: user.id,
        singleCharacterOrLetter: checkSingle,
        verifyDomain: false,
        removeDuplicatesByEmail: false,
        checkForPrefixes: checkPrefix,
        checkForSuffixes: checkSuffix,
        verifyEmailSyntax: checkEmailSyntax,
        removeWhitespace: true,
        pNonEmptyColumns: true,
        importType: checked,
        columnMap: "",
        sendEmail: emailme,
        sendNotification: true,
        type: "",
        upload_id: "",
        uploadName: fileName,
      },
    };

    axios
      .post(url, formData, config)
      .then((response) => {
        // setWaitingForFileUpload(false);
        // setUploadId(response.data?.upload_id);
        // console.log(response);
        setSuccess("Import Finished!");
        // setTimeout(() => {
        //   cancel();
        //   setImporting(false);
        // }, 2000)
      })
      .catch((error) => {
        console.error(error);
        // setWaitingForFileUpload(false);
        setFailure(error?.message);
      });
  }
  // function closeOut() {
  //   history.back();
  // }

  return (
    <div className={styles.page}>
      <div className={styles.headerContainer}>
        <h2>Upload Contacts</h2>
        {!importing && (
          <FlexRow justify={"center"} fit>
            <Button
              onClick={cancel}
              height={2.4}
              padding={"0 1rem"}
              width={100}
            >
              Cancel
            </Button>
            {page < 2 && (
              <Button
                blue
                shadow
                style={{
                  gap: ".75em",
                  fontWeight: "400",
                }}
                width={120}
                height={2.4}
                padding={"0 1rem"}
                disable={page === 0 && !selectedFile}
                onClick={() => setPage((page) => (page += 1))}
              >
                Continue
              </Button>
            )}
            {page >= 2 && (
              <Button
                blue
                shadow
                style={{
                  gap: ".75em",
                  fontWeight: "400",
                }}
                width={140}
                height={2.4}
                padding={"0 1rem"}
                disable={page === 0 && !selectedFile}
                onClick={startImport}
              >
                Start Import
              </Button>
            )}
          </FlexRow>
        )}

        {importing && (
          <>
            <Button
              shadow
              onClick={cancel}
              height={2.4}
              padding={"0 1rem"}
              width={100}
            >
              Close
            </Button>
          </>
        )}
      </div>
      {importing && (
        <div className={styles.uploading}>
          {failure}
          {!failure && (
            <>
              {" "}
              Import process initiated. Feel free to leave this page. <br /> You
              will be notified when the process is complete.
              <LoadingUpload height={300} width={300} />
              {success}
              {/* <Button
                link
                onClick={() => navigate("/previous-imports/" + uploadId)}
              >
                Click here to view upload progress
              </Button> */}
              {/* <Button
                red
                shadow
                onClick={cancel}
                height={2.4}
                padding={"0 1rem"}
                
              >
                Cancel Import
              </Button> */}
            </>
          )}
        </div>
      )}
      {!importing && (
        <>
          {page === 0 && (
            <ChooseFile
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              setHeaderMap={setHeaderMap}
              setHeaders={setHeaders}
              objectColumns={objectColumns}
              setFileName={setFileName}
              setData={setData}
              setIdentityFields={setIdentityFields}
              identityFields={identityFields}
              fileSize={fileSize}
              setFileSize={setFileSize}
              numColumns={numColumns}
              setNumColumns={setNumColumns}
              numRows={numRows}
              setNumRows={setNumRows}
            ></ChooseFile>
          )}
          {page === 1 && (
            <ChooseMap
              headerMap={headerMap}
              headers={headers}
              setHeaderMap={setHeaderMap}
              setHeaders={setHeaders}
              objectColumns={objectColumns}
            ></ChooseMap>
          )}

          {page === 2 && (
            <ChooseIdentification
              headerMap={headerMap}
              headers={headers}
              setHeaderMap={setHeaderMap}
              setHeaders={setHeaders}
              objectColumns={objectColumns}
              checkPrefix={checkPrefix}
              checkEmailSyntax={checkEmailSyntax}
              checkSuffix={checkSuffix}
              checkSingle={checkSingle}
              setCheckEmailSyntax={setCheckEmailSyntax}
              setCheckPrefix={setCheckPrefix}
              setCheckSuffix={setCheckSuffix}
              setCheckSingle={setCheckSingle}
              checked={checked}
              setChecked={setChecked}
              fileName={fileName}
              setFileName={setFileName}
              emailme={emailme}
              setEmailme={setEmailme}
              data={data}
              organizationId={organization.id}
              fileSize={fileSize}
              numRows={numRows}
              numColumns={numColumns}
            ></ChooseIdentification>
          )}
          <FlexRow justify={"center"} className={styles.footer}>
            {page > 0 && (
              <Button
                shadow
                style={{
                  gap: ".75em",
                  fontWeight: "400",
                }}
                width={120}
                height={2.4}
                padding={"0 1rem"}
                onClick={() => setPage((page) => (page -= 1))}
              >
                Previous
              </Button>
            )}
            {page < 2 && (
              <Button
                blue
                shadow
                style={{
                  gap: ".75em",
                  fontWeight: "400",
                }}
                width={120}
                height={2.4}
                padding={"0 1rem"}
                disable={page === 0 && !selectedFile}
                onClick={() => setPage((page) => (page += 1))}
              >
                Continue
              </Button>
            )}
            {page >= 2 && (
              <Button
                blue
                shadow
                style={{
                  gap: ".75em",
                  fontWeight: "400",
                }}
                width={140}
                height={2.4}
                padding={"0 1rem"}
                disable={page === 0 && !selectedFile}
                onClick={startImport}
              >
                Start Import
              </Button>
            )}
          </FlexRow>
        </>
      )}
    </div>
  );
}

export default ImportHow;

function ChooseIdentification({
  headers,
  headerMap,
  setHeaderMap,
  objectColumns,
  setCheckEmailSyntax,
  setCheckPrefix,
  setCheckSuffix,
  setCheckSingle,
  checkPrefix,
  checkEmailSyntax,
  checkSuffix,
  checkSingle,
  checked,
  setChecked,
  fileName,
  setFileName,
  emailme,
  setEmailme,
  data,
  organizationId,
  numColumns,
  numRows,
  fileSize,
}) {
  const [loading, setLoading] = useState(false);
  const [numbers, setNumbers] = useState({});
  const getNumbers = useGetContactUpdates();

  useEffect(() => {
    let idField = findKeyWithEmailColumn(headerMap, "email");
    checkImport(idField);
  }, []);

  function checkImport(field) {
    let contactEmails = [];

    setLoading(true);
    for (let c of data) {
      if (field?.id in c && c[field?.id]) {
        contactEmails.push(c[field?.id]);
      }
    }

    getNumbers.mutate(
      {
        contacts: contactEmails,
        checked: checked,
        orgId: organizationId,
      },
      {
        onSuccess: (data) => {
          setNumbers(data.values);
          setLoading(false);
        },
      }
    );
  }

  function updateMap(vals, header) {
    let temp = { ...headerMap };
    let key = findKeyWithEmailColumn(temp, "email");
    if (key) {
      temp[key?.id].column = {};
    }

    temp[header?.name].column = vals;
    setHeaderMap(temp);
  }
  return (
    <div className={styles.content}>
      <FlexRow>
        {" "}
        <FlexCol fit gap=".5rem">
          <h2>Import Settings</h2>
          <div>Finalize settings to be used on import</div>
        </FlexCol>
        {/* <img
          src={require("assets/images/contacts.png")}
          style={{ margin: "0 auto" }}
          width={150}
        ></img> */}
      </FlexRow>
      <div className={styles.information}>
        {!loading && (
          <Stat title={"Contacts to be updated"} value={numbers?.update}></Stat>
        )}
        {loading && (
          <div className={styles.loadingContainer}>
            <Loading height={100}></Loading>
          </div>
        )}
        {!loading && (
          <Stat title={"Contacts to be created"} value={numbers?.create}></Stat>
        )}
        {loading && (
          <div className={styles.loadingContainer}>
            <Loading height={100}></Loading>
          </div>
        )}

        {/* <Stat title={"Number of Rows"} value={numRows}></Stat>
        <Stat title={"Number of Columns"} value={numColumns}></Stat>
        <Stat title={"File Size"} value={fileSize}></Stat> */}
      </div>
      <FlexCol style={{ maxWidth: "550px" }}>
        {" "}
        <span
          className={styles.header_5}
          style={{ margin: "0", color: "black" }}
        >
          Name your Import
        </span>
        <span className={styles.label5} style={{ paddingBottom: ".5rem" }}>
          This is the name that would show up when filtering contacts by
          "Import"
        </span>
        <TextFieldSimple
          value={fileName}
          onChange={setFileName}
        ></TextFieldSimple>
        <FlexRow start style={{ paddingTop: "1rem", marginLeft: "1.25rem" }}>
          <Checkbox
            checked={emailme}
            onChange={(e) => setEmailme(e.target.checked)}
          ></Checkbox>{" "}
          <span className={styles.label5}>Send me an email when finished</span>
        </FlexRow>
      </FlexCol>
      <FlexCol gap="2rem" align={"center"} fit style={{ marginLeft: ".5rem" }}>
        <FlexCol gap=".5rem" style={{ width: "300px" }}>
          {" "}
          <span className={styles.header_5} style={{ margin: "0" }}>
            Update Behavior
          </span>
          <div className={styles.radioLabel}>
            <div
              style={{
                width: "41px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <input
                id="sortByOg"
                type="radio"
                name="sortByOg"
                value="none"
                onChange={(e) => setChecked("add-update")}
                checked={checked === "add-update"}
                style={{ borderRadius: "50%" }}
              />
            </div>
            <div className={styles.label5}>Add new and Update existing </div>
          </div>
          <div className={styles.radioLabel}>
            <div
              style={{
                width: "42px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <input
                id="sortByOg"
                type="radio"
                name="sortByOg"
                value="none"
                onChange={(e) => setChecked("add")}
                checked={checked === "add"}
                style={{ borderRadius: "50%" }}
              />
            </div>
            <div className={styles.label5}>Add new contacts only</div>
          </div>
          <div className={styles.radioLabel}>
            <div
              style={{
                width: "41px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <input
                id="sortByOg"
                type="radio"
                name="sortByOg"
                value="none"
                onChange={(e) => setChecked("update")}
                checked={checked === "update"}
                style={{ borderRadius: "50%" }}
              />
            </div>
            <div className={styles.label5}>Update existing contacts only</div>
          </div>
        </FlexCol>

        <FlexCol gap=".5rem" style={{ width: "300px" }}>
          <span className={styles.header_5} style={{ margin: "0" }}>
            Check Contact Fields for...
          </span>
          {/* <FlexRow start style={{ paddingTop: "1rem" }}>
              <Checkbox
                checked={checkSingle}
                onChange={(e) => setCheckSingle(e.target.checked)}
              ></Checkbox>{" "}
              <span className={styles.label5}>Single character or letter</span>
            </FlexRow>
            <FlexRow start style={{ paddingTop: "1rem" }}>
              <Checkbox
                checked={checkPrefix}
                onChange={(e) => setCheckPrefix(e.target.checked)}
              ></Checkbox>{" "}
              <span className={styles.label5}>Prefixes</span>
            </FlexRow>
            <FlexRow start style={{ paddingTop: "1rem" }}>
              <Checkbox
                checked={checkSuffix}
                onChange={(e) => setCheckSuffix(e.target.checked)}
              ></Checkbox>{" "}
              <span className={styles.label5}>Suffixes</span>
            </FlexRow> */}
          <FlexRow start style={{ marginLeft: ".75rem" }}>
            <Checkbox
              checked={checkEmailSyntax}
              onChange={(e) => setCheckEmailSyntax(e.target.checked)}
            ></Checkbox>{" "}
            <span className={styles.label5}>Wrong Email Syntax</span>
          </FlexRow>
        </FlexCol>
      </FlexCol>
      {/* <div className={styles.fileContent} style={{ gap: "2rem" }}>
        <FlexCol gap=".5rem">
          <h4 style={{ marginLeft: ".5rem" }}>
            Choose the column that will identify your contact
          </h4>
          <table className={styles.maptable} style={{ marginBottom: "1rem" }}>
            <thead>
              <tr>
                <th>Column from file</th>
                <th></th>
                <th>Identity Column in Reaction</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <SelectFieldCustom
                    value={findKeyWithEmailColumn(headerMap, "email")}
                    onChange={(vals) => {
                      updateMap(
                        {
                          id: "email",
                          name: "Email",
                          label: "Email",
                          name: "email",
                          value: "email",
                          icon: "at",
                        },
                        vals
                      );
                    }}
                    icon={<Icon blue iconName={"layout-three-columns"}></Icon>}
                    options={headers}
                  ></SelectFieldCustom>
                </td>
                <td
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    className="bi-arrow-right"
                    style={{ fontSize: "1.1rem" }}
                  ></i>
                </td>

                <td>
                  <div className={styles.columninfile}>
                    <Icon iconName={"envelope"}></Icon>
                    Email
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </FlexCol>
      </div> */}
    </div>
  );
}

// Function to find the key with column: "email"
const findKeyWithEmailColumn = (obj, column) => {
  for (const key in obj) {
    if (obj[key]?.column?.name === column) {
      return {
        name: obj[key]?.column.name,
        label: obj[key]?.column.name,
        id: key,
      }; // Return the first key that matches
    }
  }
  return null; // Return null if no key is found
};

function ChooseMap({
  headers,
  headerMap,
  setHeaderMap,
  setHeaders,
  objectColumns,
}) {
  function getFieldOptions() {
    let custom = [];

    custom.push({
      id: "no-import",
      value: "no-import",
      icon: "eye-slash",
      label: "Don't Import",
    });
    // custom.push({
    //   id: "new-column",
    //   value: "new-column",
    //   icon: "plus",
    //   label: "Create New Column",
    // });
    for (let f of objectColumns) {
      if (f?.id && f?.editable) {
        if (headerMap && !findKeyWithEmailColumn(headerMap, f?.name)) {
          custom.push({
            id: f?.id,
            value: f?.value,
            icon: f?.icon ? f?.icon : "person",
            label: f?.label ? f?.label : f?.name,
            multiple: f?.multiple,
            delimiter: f?.delimiter,
          });
        } else if (!headerMap) {
          custom.push({
            id: f.id,
            value: f?.value,
            icon: f?.icon ? f?.icon : "person",
            label: f?.label ? f?.label : f?.name,
            multiple: f?.multiple,
            delimiter: f?.delimiter,
          });
        }
      }
    }

    // let custom = fetchCustomFields.data?.getCurrOrgFields?.map((f) => {
    //   return { id: f.id, value: f.name, label: f.displayName };
    // });
    // return [
    //   {
    //     id: "firstName",
    //     name: "firstName",
    //     displayName: "First Name",
    //     label: "First Name",
    //     value: "firstName",
    //   },
    //   {
    //     id: "lastName",
    //     name: "lastName",
    //     displayName: "Last Name",
    //     label: "Last Name",
    //     value: "lastName",
    //   },
    //   {
    //     id: "email",
    //     name: "email",
    //     displayName: "Email",
    //     label: "Email",
    //     value: "email",
    //   },
    //   ...custom,
    // ];
    return custom;
  }

  const columnOptions = getFieldOptions();

  function updateMap(vals, header) {
    let temp = { ...headerMap };
    if (vals.id === "no-import") {
      temp[header?.name] = {};
    } else {
      if (vals.id === "new-column") {
        temp[header?.name].new = true;
      }
      if (vals.id != "email") {
        temp[header?.name].identity = false;
      } else {
        temp[header?.name].identity = true;
      }
      temp[header?.name].column = objectColumns.find((o) => o?.id === vals?.id);
    }
    setHeaderMap(temp);
  }
  return (
    <div className={styles.content}>
      <FlexCol fit gap=".5rem">
        <h2>File fields mapping</h2>
        <div>
          Map columns from the uploaded file to the fields in Reaction. Fields
          that are not required can be skipped.
        </div>
      </FlexCol>
      <FlexCol style={{ marginTop: "-1rem", gap: ".5rem" }}>
        <span>Columns: {headers?.length}</span>
        <div className={styles.fileContent}>
          <table className={styles.maptable}>
            <colgroup>
              <col style={{ width: "350px" }} />
              <col
                style={{
                  width: "50px",
                }}
              />
              <col style={{ width: "350px" }} />
              <col
                style={{
                  width: "350px",
                }}
              />
            </colgroup>
            <thead>
              <tr>
                <th>Columns in file</th>
                <th></th>
                <th>Columns in Reaction</th>
                <th>Example Data</th>
              </tr>
            </thead>
            <tbody>
              {headers &&
                headers?.length > 0 &&
                headers?.map((header, i) => (
                  <tr key={i}>
                    <td>
                      <div className={styles.columninfile}>
                        <Icon blue iconName={"layout-three-columns"}></Icon>
                        {header?.name}
                      </div>
                    </td>
                    <td>
                      {!headerMap[header?.name]?.column ||
                      headerMap[header?.name]?.column?.id === "no-import" ? (
                        <Icon
                          red
                          iconName="x"
                          style={{ fontSize: "1.5rem", margin: "0rem" }}
                        ></Icon>
                      ) : (
                        <Icon
                          green
                          iconName="check"
                          style={{ fontSize: "1.5rem", margin: "0rem" }}
                        ></Icon>
                      )}
                    </td>
                    <td>
                      <div>
                        <SelectFieldCustom
                          value={
                            headerMap[header?.name]?.column
                              ? headerMap[header?.name]?.column
                              : {
                                  id: "no-import",
                                  value: "no-import",
                                  icon: "eye-slash",
                                  label: "Don't Import",
                                }
                          }
                          onChange={(vals) => {
                            updateMap(vals, header);
                          }}
                          options={columnOptions}
                          formatOptionLabel={(data) => (
                            <FlexRow gap=".5rem">
                              <i className={`bi-${data?.icon}`}></i>{" "}
                              {data.label}
                            </FlexRow>
                          )}
                          // create
                        ></SelectFieldCustom>
                      </div>
                    </td>
                    <td>
                      {header?.exampledata && (
                        <FlexRow
                          style={{
                            overflow: "hidden",
                            width: "100%",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {header?.exampledata?.map((item, i) => (
                            <span className={styles.exampledata} key={i}>
                              {item}
                              {i === header?.exampledata - 1 || !item
                                ? ""
                                : ";"}{" "}
                            </span>
                          ))}{" "}
                        </FlexRow>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </FlexCol>
    </div>
  );
}

const firstname = /first[\s_.]?name/;
const lastname = /last[\s_.]?name/;
export function checkMap(field, headerMap, customFields, index) {
  let fields = {};
  let identityField = false;
  if (customFields && customFields?.length > 0) {
    for (let f of customFields) {
      if (
        (f.name === "lastName" && lastname.test(field?.name?.toLowerCase())) ||
        (f.name === "firstName" &&
          firstname.test(field?.name?.toLowerCase())) ||
        f?.name === field?.name?.toLowerCase() ||
        f?.name?.toLowerCase() === field?.name?.toLowerCase() ||
        f?.name?.toLowerCase() === field?.label?.toLowerCase() ||
        f?.displayName?.toLowerCase() === field?.name?.toLowerCase() ||
        f?.name?.toLowerCase() ===
          field?.name?.toLowerCase() + " " + field?.objectname?.toLowerCase() ||
        f?.displayName?.toLowerCase() ===
          field?.name?.toLowerCase() + " " + field?.objectname?.toLowerCase() ||
        f?.salesforceColumn === field?.name
      ) {
        fields = {
          ...f,
          label: f?.label ? f?.label : f?.displayName,
          value: f.name,
          salesforceColumn: f?.salesforceColumn,
        };
      }
    }
  }

  if (!fields?.name) {
    fields = {
      id: "no-import",
      value: "no-import",
      icon: "eye-slash",
      label: "Don't Import",
    };
  }

  if (field?.objectname) {
    headerMap[field?.name] = {
      column: fields,
      sfName: field.sfName,
      new: false,
      index: index,
      identity: identityField,
    };
  } else {
    headerMap[field?.name] = {
      column: fields,
      sfName: field.sfName,
      new: false,
      index: index,
      identity: identityField,
    };
  }

  return headerMap;
}

function ChooseFile({
  selectedFile,
  setSelectedFile,
  setHeaders,
  setHeaderMap,
  setData,
  objectColumns,
  setFileName,
  setIdentityFields,
  numRows,
  setNumRows,
  numColumns,
  setNumColumns,
  fileSize,
  setFileSize,
}) {
  const [isDragging, setIsDragging] = useState(false);

  const [error, setError] = useState();

  function exportSampleCSV() {
    // Define header and sample data arrays
    const headers = ["First Name", "Last Name", "Email"];
    const firstNames = [
      "John",
      "Jane",
      "Alice",
      "Bob",
      "Charlie",
      "Dave",
      "Eve",
      "Frank",
      "Grace",
      "Hank",
    ];
    const lastNames = [
      "Smith",
      "Doe",
      "Johnson",
      "Williams",
      "Brown",
      "Jones",
      "Garcia",
      "Miller",
      "Davis",
      "Wilson",
    ];

    // Utility to get a random element from an array
    function getRandomElement(arr) {
      return arr[Math.floor(Math.random() * arr.length)];
    }

    // Create an array to hold all rows (starting with headers)
    const rows = [];
    rows.push(headers);

    // Generate 20 fake contacts
    for (let i = 0; i < 20; i++) {
      const firstName = getRandomElement(firstNames);
      const lastName = getRandomElement(lastNames);
      const email = `${firstName}.${lastName}@example.com`.toLowerCase();
      rows.push([firstName, lastName, email]);
    }

    // Convert the rows array into a CSV string
    const csvContent = rows.map((row) => row.join(",")).join("\n");

    // Create a Blob from the CSV string
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a temporary link to trigger the download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "sample_contacts.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function detectFileEncoding(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        // Get the file's data as an ArrayBuffer
        const arrayBuffer = e.target.result;
        // Create a Uint8Array view of the ArrayBuffer
        const uint8Array = new Uint8Array(arrayBuffer);
        // Use jschardet to guess the encoding
        const detection = jschardet.detect(uint8Array);
        console.log("Detection result:", detection);
        // detection.encoding holds the guessed encoding (e.g., "windows-1252")
        resolve(detection.encoding);
      };

      reader.onerror = function (err) {
        reject(err);
      };

      // Read the file as an ArrayBuffer
      reader.readAsArrayBuffer(file);
    });
  }

  //handle onChange when you upload file
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size > 10485760) {
        setError("File is too large");
        return;
      }
      const size = formatByteSize(file.size);
      setFileSize(size);
      setSelectedFile(file);
      setFileName(file?.name);
    }

    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const arrayBuffer = e.target.result;

        // Ensure the result is an ArrayBuffer
        if (!(arrayBuffer instanceof ArrayBuffer)) {
          console.error("Expected an ArrayBuffer, but got:", arrayBuffer);
          setError("File is the wrong format");
          setFileSize("0B");
          setSelectedFile(undefined);
          setFileName("");
          return;
        }

        let data = null;
        if (file.name.endsWith(".csv")) {
          const uint8Array = new Uint8Array(arrayBuffer);
          const binaryString = Array.from(uint8Array)
            .map((byte) => String.fromCharCode(byte))
            .join("");

          const detection = jschardet.detect(binaryString);
          let detectedEncoding =
            detection && detection.encoding
              ? detection.encoding.toLowerCase()
              : "utf-8";
          if (detectedEncoding === "ascii") detectedEncoding = "utf-8";
          const decoder = new TextDecoder(detectedEncoding); // Replace with your encoding if different
          const text = decoder.decode(arrayBuffer);

          let csvString = text.replace(/\r\n/g, "\n").replace(/\r/g, "\n");
          data = csv2json(csvString);
        } else if (file.name.endsWith(".xlsx")) {
          const workbook = read(arrayBuffer, { type: "array" });
          const sheetName = workbook.SheetNames[0]; // Get first sheet
          const sheet = workbook.Sheets[sheetName];
          data = utils.sheet_to_json(sheet); // Convert to JSON
        } else {
          setError("File is the wrong format");
          setFileSize("0B");
          setSelectedFile(undefined);
          setFileName("");
          return;
        }

        let heads = Object.keys(data[0]);
        setNumColumns(heads?.length);
        setNumRows(data?.length);

        let headMap = {};
        let final = [];
        let i = 0;
        for (let field of heads) {
          headMap[field] = [];
          let exampledata = [];
          if (data?.length >= 3) {
            for (let j = 0; j <= data?.length; j++) {
              if (j > 2) {
                break;
              }
              exampledata.push(data[j][field]);
            }
          }
          let newfield = {
            id: i,
            name: field,
            name: field,
            enabled: true,
            cell_style: null,
            canSort: false,
            value: field,
            label: field,
            exampledata,
          };
          final.push(newfield);
          checkMap(newfield, headMap, objectColumns, i, setIdentityFields);
          i++;
        }

        setHeaders(final);
        setData(data);
        setHeaderMap(headMap);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  // Handle the drag over event
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  // Handle the drag leave event
  const handleDragLeave = () => {
    setIsDragging(false);
  };

  // Handle the drop event
  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file && (file.name.endsWith("csv") || file.name.endsWith("xlsx"))) {
      setSelectedFile(file);
    } else {
      alert("Please upload a CSV file");
    }
  };

  // Handle clicking the area to open the file dialog
  const handleBrowseClick = () => {
    document.getElementById("fileInput").click();
  };

  return (
    <div className={styles.content}>
      <FlexRow wrap="wrap">
        <FlexCol fit gap=".5rem">
          <h2>Choose a file to upload</h2>
          <div>
            First Name, Last Name, and Email columns are required in upload.
          </div>
        </FlexCol>
        {/* <img
          src={require("assets/images/contacts.png")}
          style={{ margin: "0 auto" }}
          width={200}
        ></img> */}
      </FlexRow>
      <FlexRow
        style={{ marginTop: "-1rem", marginBottom: "-1.5rem" }}
        wrap="wrap"
      >
        <div>Columns: {numColumns}</div> <div>Rows: {numRows}</div>
        <div>File Size: {fileSize}</div> <span>{error}</span>
      </FlexRow>
      <FlexRow align={"center"} gap="2rem" wrap="wrap">
        <div className={styles.fileContent} style={{ border: "none" }}>
          <div
            className={`${styles.dropArea} ${isDragging ? "dragging" : ""}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleBrowseClick}
          >
            {selectedFile ? (
              <div className={styles.label5}>{selectedFile.name}</div>
            ) : (
              <div className={styles.drop}>
                <Icon
                  iconName={"cloud-upload"}
                  style={{ fontSize: "2rem" }}
                ></Icon>
                <div className={styles.header_5}>
                  Drag & Drop a CSV file here, or click to browse
                </div>
                <div className={styles.label5}>
                  Max 10,000 rows or max file size 10MB
                </div>
              </div>
            )}
          </div>

          <input
            type="file"
            id="fileInput"
            accept={[".csv", ".xlsx"]}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
      </FlexRow>
      <FlexRow align={"center"} gap="2rem">
        <span>Only .csv or .xlsx files.</span>
        <FlexRow start fit gap={".5rem"}>
          <i className="bi-filetype-csv"></i>
          <div
            className={`${styles.link} ${styles.black}`}
            onClick={exportSampleCSV}
          >
            Download a sample file
          </div>
        </FlexRow>
      </FlexRow>
    </div>
  );
}
