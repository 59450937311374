import { useState, useEffect, useRef } from "react";
import accordianStyles from "../../SettingsAccordion.module.scss";
import styles from "./Data.module.scss";
import {
  AnswerCount,
  DoughnutType,
  ParticipationCount,
  TableType,
  VertBarType,
  defaultChartSettings,
} from "../../../QChart/QuestionChart";
import { useFetchQuestionsGql } from "api/resources/projects/questions";
import { Loading } from "components/Loading/Loading";
import {
  useFetchSurveyTags,
  useFetchSurveyTagsGql,
} from "api/resources/organization/surveytags";
import {
  useFetchAllPrograms,
  useFetchPrograms,
  useFetchProjectsByIdsChart,
  useSearchProjectsForChart,
} from "api/resources/projects/projects";
import { forEach } from "assets/functions/ArrayFunctions";
import { useFetchReport } from "api/resources/organization/reports";
import { useParams } from "react-router-dom";
import { QuestionTag } from "./QuestionTag/QuestionTag";
import { combinedQs } from "pages/results/Charts/Visualization";
import { useFetchQTags } from "api/resources/organization/questiontag";
import { addQToTable, removeQFromTable } from "pages/results/Charts/TableViz";
import { ChartLabel } from "../../Tools";
import FlexCol from "components/layouts/FlexColumn/FlexCol";

export const Scale = "NumberScale";
export const MultChoice = "MultipleChoice";
export const Text = "Text";
export const Matrix = "Matrix";

function genTitle(Qs) {
  let titles = [];
  let title = "";
  for (let q of Qs) {
    if (!titles.includes(q.questionText)) {
      if (title.length > 0) {
        title += " | ";
      }
      title += q.questionText;
      titles.push(q.questionText);
    }
  }
  return title;
}

export function getQuestionType(question) {
  // !! Question Tags use the type returned from this function - so don't alter the return values unless you alter the type in the question tags
  let it = "";
  if (question.matrixQuestion) {
    it = "Matrix | ";
  }
  if (question.scaleQuestion) {
    it += "Number Scale";
  } else if (question.textQuestion) {
    it += "Free Response";
  } else if (question.choiceQuestion) {
    if (question.choiceQuestion.isRanking) {
      it += "Ranking";
    } else {
      it += "Multiple Choice";
    }
  }
  return it;
}

export const QData = ({ viz, updateViz, newChart }) => {
  const { reportid } = useParams();

  return (
    <>
      <div className={styles.body}>
        {reportid && newChart && (
          <MiddleMan viz={viz} updateViz={updateViz} reportId={reportid} />
        )}
        {(!reportid || !newChart) && (
          <PickData viz={viz} updateViz={updateViz} />
        )}
      </div>
    </>
  );
};

function MiddleMan({ viz, updateViz, reportId }) {
  const getReport = useFetchReport(reportId);

  return (
    <>
      {getReport.isLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loading height={120} width={120} />
        </div>
      )}
      {getReport.isSuccess && (
        <PickData
          viz={viz}
          updateViz={updateViz}
          tiedIds={getReport.data.report.project.map((p) => p.id)}
        />
      )}
    </>
  );
}

function PickData({ viz, updateViz, tiedIds }) {
  const getInitQType = () => {
    let combined = combinedQs(viz);

    if (viz.type === TableType || !combined.length) {
      return null;
    }

    return getQuestionType(combined[0]);
  };

  function getIds() {
    let copy = [...viz.projectIdsArray];
    if (tiedIds) {
      for (let id of tiedIds) {
        if (!copy.includes(id)) {
          copy.push(id);
        }
      }
    }
    return copy;
  }

  const [QType, setQType] = useState(getInitQType());
  const [potentialProjects, setPotentialProjects] = useState(getIds());
  const [addSurvey, setAddSurvey] = useState(false);
  const [potentialTags, setPotentialTags] = useState([...viz.tagIdsArray]);
  const [potentialPrograms, setPotentialPrograms] = useState(
    viz.programIds ? [...viz.programIds] : []
  );
  const getChosenTags = useFetchSurveyTags({ tagIds: potentialTags });
  const getChosenPrograms = useFetchPrograms(potentialPrograms);

  function addSurveyTag(tag) {
    setPotentialTags((old) => [...old, tag.id]);
    let potentialsCopy = [...potentialProjects];

    for (let project of tag.project) {
      if (!potentialsCopy.includes(project.id)) {
        potentialsCopy.push(project.id);
      }
    }
    setPotentialProjects(potentialsCopy);

    // check if any chosen projects are in the tag, if so include the tag immediately
    for (let project of tag.project) {
      if (viz.projectIdsArray.includes(project.id)) {
        let newViz = { ...viz };
        newViz.tagIdsArray = [...newViz.tagIdsArray, tag.id];
        updateViz(newViz);
        break;
      }
    }

    setAddSurvey(false);
  }

  function removeSurveyTag(tag) {
    let index = potentialTags.indexOf(tag.id);
    let copy = [...potentialTags];
    copy.splice(index, 1);
    setPotentialTags(copy);
    let potentialsCopy = [...potentialProjects];

    //remove associated projects
    let newViz = { ...viz };
    const otherTags = getChosenTags.data?.tags.filter((t) => t.id !== tag.id);
    for (let proj of tag.project) {
      // if there are no more tags or projects associated with it
      if (
        !otherTags.some((otherTag) =>
          otherTag.project.some((p) => p.id === proj.id)
        ) &&
        !getChosenPrograms.data.programs.some((program) =>
          program.project.some((p) => p.id === proj.id)
        )
      ) {
        if (newViz.projectIdsArray.includes(proj.id)) {
          takeOutProject(newViz, proj.id);
        }
        index = potentialsCopy.indexOf(proj.id);
        if (index >= 0) {
          potentialsCopy.splice(index, 1);
        }
      }
    }

    setPotentialProjects(potentialsCopy);
    updateViz(newViz);
  }

  function addProgram(program) {
    setPotentialPrograms((old) => [...old, program.id]);

    let potentialsCopy = [...potentialProjects];
    for (let project of program.project) {
      if (!potentialsCopy.includes(project.id)) {
        potentialsCopy.push(project.id);
      }
    }
    setPotentialProjects(potentialsCopy);

    // check if any chosen projects are in the tag, if so include the program immediately
    for (let project of program.project) {
      if (viz.projectIdsArray.includes(project.id)) {
        let newViz = { ...viz };
        if (!newViz.programIds) {
          newViz.programIds = [program.id];
        } else {
          newViz.programIds.push(program.id);
        }
        updateViz(newViz);
        break;
      }
    }

    setAddSurvey(false);
  }

  function removeProgram(program) {
    let index = potentialPrograms.indexOf(program.id);
    let copy = [...potentialPrograms];
    copy.splice(index, 1);
    setPotentialPrograms(copy);
    let potentialsCopy = [...potentialProjects];

    //remove associated projects
    let newViz = { ...viz };
    const otherPrograms = getChosenPrograms.data.programs.filter(
      (p) => p.id !== program.id
    );
    for (let proj of program.project) {
      // check if its in other tags or programs
      if (
        !getChosenTags.data.tags.some((otherTag) =>
          otherTag.project.some((p) => p.id === proj.id)
        ) &&
        !otherPrograms.some((program) =>
          program.project.some((p) => p.id === proj.id)
        )
      ) {
        if (newViz.projectIdsArray.includes(proj.id)) {
          takeOutProject(newViz, proj.id);
        }
        let index = potentialsCopy.indexOf(proj.id);
        if (index >= 0) {
          potentialsCopy.splice(index, 1);
        }
      }
    }

    setPotentialProjects(potentialsCopy);
    updateViz(newViz);
  }

  function addProject(id) {
    let surveys = [...potentialProjects];
    surveys.push(id);
    setPotentialProjects(surveys);
    setAddSurvey(false);
  }

  function removeProject(id) {
    let surveyIds = [...potentialProjects];
    let indexOf = surveyIds.indexOf(id);
    surveyIds.splice(indexOf, 1);
    setPotentialProjects(surveyIds);

    let newViz = { ...viz };
    takeOutProject(newViz, id);

    updateViz(newViz);
  }

  function takeOutProject(newViz, id) {
    let Qs = [...newViz.question];
    for (let question of Qs) {
      if (question.projectId === id) {
        takeOutQ(newViz, question.id);
      }
    }
  }

  function removeQuestion(id) {
    let newViz = { ...viz };
    takeOutQ(newViz, id);
    updateViz(newViz);
  }

  function takeOutQ(newViz, id) {
    //remove it from the project from the ids array if it is the last one with the same project
    let index = newViz.question.findIndex((q) => q.id === id);
    let removedQ = newViz.question[index];
    newViz.question.splice(index, 1);

    // Check if there are more questions selected under the same project
    if (!newViz.question.some((q) => q.projectId === removedQ.projectId)) {
      // There are no more questions selected from the same project, so we can delete it from the IdsArray
      index = newViz.projectIdsArray.indexOf(removedQ.projectId);
      newViz.projectIdsArray.splice(index, 1);

      //Check if it falls under a survey tag
      for (let tag of getChosenTags?.data.tags) {
        if (tag.project.some((p) => p.id === removedQ.projectId)) {
          // Check if there are any more questions that also fall under that tag
          let keep = false;
          for (let proj of tag.project) {
            if (newViz.question.some((q) => q.projectId === proj.id)) {
              keep = true;
              break;
            }
          }
          //If not, delete that tag from the IdsArray.
          if (!keep) {
            index = newViz.tagIdsArray.indexOf(tag.id);
            newViz.tagIdsArray.splice(index, 1);
          }
        }
      }

      if (newViz.programIds) {
        //Check if it falls under a program id
        for (let prog of getChosenPrograms?.data.programs) {
          if (prog.project.some((p) => p.id === removedQ.projectId)) {
            // Check if there are any more questions that also fall under that program
            let keep = false;
            for (let proj of prog.project) {
              if (newViz.question.some((q) => q.projectId === proj.id)) {
                keep = true;
                break;
              }
            }
            //If not, delete that tag from the IdsArray.
            if (!keep) {
              index = newViz.programIds.indexOf(prog.id);
              newViz.programIds.splice(index, 1);
            }
          }
        }
      }
    }

    let all = combinedQs(newViz);

    if (newViz.type === TableType) {
      removeQFromTable(newViz, removedQ);
    } else {
      if (!all.length) {
        setQType(null);
        newViz.designSettings = JSON.parse(
          JSON.stringify(defaultChartSettings)
        );
      }
    }

    if (all.length && viz.designSettings.artificialTitle) {
      newViz.title = genTitle(all);
      newViz.titleValue = newViz.title;
    }
  }

  /**
   *
   * @param {*} newViz
   * @param {*} question
   * @param {*} reset Boolean - If this is taking place during a tag removal or a selection removal, not a time frame switch - may reset the chart if there are no questions left
   */
  function takeOutDynamicQ(newViz, question, reset) {
    let index = newViz.dynamicQs.findIndex((q) => q.id === question.id);
    newViz.dynamicQs.splice(index, 1);

    let all = combinedQs(newViz);

    if (newViz.type === TableType) {
      removeQFromTable(newViz, question);
    } else {
      if (reset && !all.length) {
        setQType(null);
        newViz.designSettings = JSON.parse(
          JSON.stringify(defaultChartSettings)
        );
      }
    }

    if (all.length && viz.designSettings.artificialTitle) {
      newViz.title = genTitle(all);
      newViz.titleValue = newViz.title;
    }
  }

  function removeQuestionTag(i, oldQs) {
    let newViz = { ...viz };
    newViz.questionTags.splice(i, 1);

    for (let q of oldQs) {
      takeOutDynamicQ(newViz, q, true);
    }

    updateViz(newViz);
  }

  function onQTagTimeFrameChange(i, newTagCopy, oldQs, newQs) {
    let newViz = { ...viz };
    newViz.questionTags[i] = newTagCopy;
    for (let q of oldQs) {
      takeOutDynamicQ(newViz, q, false);
    }

    for (let q of newQs) {
      addDynamicQuestion(newViz, q, false);
    }

    updateViz(newViz);
  }

  function addToSelection(i, questions) {
    let newViz = { ...viz };
    for (let q of questions) {
      newViz.questionTags[i].selection.push(q.id);
      addDynamicQuestion(newViz, q, false);
    }

    updateViz(newViz);
  }

  function removeFromSelection(i, questions) {
    let newViz = { ...viz };
    for (let q of questions) {
      let ind = newViz.questionTags[i].selection.indexOf(q.id);
      newViz.questionTags[i].selection.splice(ind, 1);
      takeOutDynamicQ(newViz, q, false);
    }
    updateViz(newViz);
  }

  function addQuestionTag(tag) {
    let newViz = { ...viz };
    if (!newViz.questionTags) {
      newViz.questionTags = [];
    }
    newViz.questionTags.push({
      id: tag.id,
      name: tag.name,
      timeFrame: "All",
      color: tag.color,
    });

    for (let q of tag.question) {
      addDynamicQuestion(newViz, q, true);
    }

    updateViz(newViz);
  }

  function addDynamicQuestion(newViz, question, setUpAble) {
    if (!newViz.dynamicQs) {
      newViz.dynamicQs = [];
    }
    if (newViz.dynamicQs.some((q) => q.id === question.id)) {
      // all ready included;
      return;
    }
    newViz.dynamicQs.push(question);

    let all = combinedQs(newViz);

    if (viz.designSettings.artificialTitle) {
      newViz.title = genTitle(all);
      newViz.titleValue = newViz.title;
    }

    if (newViz.type !== TableType && all.length == 1 && setUpAble) {
      setUpChart(newViz, question);
    }
    if (newViz.type === TableType) {
      addQToTable(newViz, question);
    }
  }

  function addQuestion(question) {
    let newViz = { ...viz };
    newViz.question.push(question);

    if (!newViz.projectIdsArray.includes(question.projectId)) {
      newViz.projectIdsArray.push(question.projectId);
    }

    // add survey tags
    for (let tag of getChosenTags?.data.tags) {
      if (!newViz.tagIdsArray.includes(tag.id)) {
        if (tag.project.some((p) => p.id === question.projectId)) {
          newViz.tagIdsArray.push(tag.id);
        }
      }
    }

    // add programs
    for (let prog of getChosenPrograms?.data.programs) {
      if (prog.project.some((p) => p.id === question.projectId)) {
        if (newViz.programIds) {
          if (!newViz.programIds.includes(prog.id)) {
            newViz.programIds.push(tag.id);
          }
        } else {
          newViz.programIds = [prog.id];
        }
      }
    }

    let all = combinedQs(newViz);

    if (viz.designSettings.artificialTitle) {
      newViz.title = genTitle(all);
      newViz.titleValue = newViz.title;
    }

    if (newViz.type !== TableType && all.length == 1) {
      setUpChart(newViz, question);
    }
    if (newViz.type === TableType) {
      addQToTable(newViz, question);
    }
    updateViz(newViz);
  }

  function setUpChart(newViz, firstQ) {
    // if (firstQ.type === Matrix) {
    //   newViz.type = VertBarType;
    //   newViz.designSettings.dataLabelValue.position = "end";
    //   newViz.designSettings.dataLabelValue.alignment = "end";
    //   newViz.designSettings.hasLegend = true;
    //   if (firstQ.choiceQuestion && firstQ.choiceQuestion.isRanking) {
    //     newViz.designSettings.asRanking = true;
    //   }
    // } else if (firstQ.type === Scale) {
    //   newViz.type = VertBarType;
    //   newViz.designSettings.dataLabelValue.position = "end";
    //   newViz.designSettings.dataLabelValue.alignment = "end";
    // } else if (firstQ.type === MultChoice) {
    //   if (firstQ.choiceQuestion.isRanking) {
    //     newViz.designSettings.asRanking = true;
    //     newViz.designSettings.dataLabelValue.position = "end";
    //     newViz.designSettings.dataLabelValue.alignment = "end";
    //     newViz.designSettings.legendTitle = "Rank";
    //     newViz.designSettings.hasLegendTitle = true;
    //     newViz.type = VertBarType;
    //   } else {
    //     newViz.type = DoughnutType;
    //     newViz.designSettings.legendPosition = "right";
    //     newViz.designSettings.dataLabelValue.position = "center";
    //     newViz.designSettings.dataLabelValue.alignment = "center";
    //   }
    //   newViz.designSettings.hasLegend = true;
    // } else if (firstQ.type === Text) {
    //   // check if it has buckets - if so maybe do those automatically? If not - do a pivot ??
    //   newViz.type = VertBarType;
    //   newViz.designSettings.dataLabelValue.position = "end";
    //   newViz.designSettings.dataLabelValue.alignment = "end";
    // }

    // You can do more with Bar charts, so give them that? Else they might not
    newViz.type = VertBarType;
    newViz.designSettings.dataLabelValue.position = "end";
    newViz.designSettings.dataLabelValue.alignment = "end";

    if (firstQ.type === Matrix) {
      newViz.designSettings.hasLegend = true;
      if (firstQ.choiceQuestion && firstQ.choiceQuestion.isRanking) {
        newViz.designSettings.asRanking = true;
      }
    } else if (firstQ.type === MultChoice && firstQ.choiceQuestion.isRanking) {
      newViz.designSettings.hasLegend = true;
      newViz.designSettings.asRanking = true;
      newViz.designSettings.legendTitle = "Rank";
      newViz.designSettings.hasLegendTitle = true;
    }

    let type = getQuestionType(firstQ);
    setQType(type);
  }

  return (
    <>
      {/* <div
        key={viz.id}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          className={`${accordianStyles.title}`}
          style={{ paddingBottom: "0" }}
        >
          Surveys & Questions{" "}
          <i style={{ fontSize: "1.2em" }} className="bi bi-journal-text"></i>
        </div>
      </div> */}

      {getChosenTags.isError && (
        <ErrorBanner
          error={getChosenTags.error}
          message="Error fetching survey tags in chart"
        />
      )}
      {getChosenPrograms.isError && (
        <ErrorBanner
          error={getChosenPrograms.error}
          message="Error fetching programs for settings"
        />
      )}

      {QType && viz.type !== TableType && potentialProjects.length > 0 && (
        <div className={styles.questionTypeConstraint}>
          <div className={accordianStyles.title} style={{ paddingBottom: "0" }}>
            Question Type:
          </div>{" "}
          <span>{QType}</span>
        </div>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TagsAndPrograms
          viz={viz}
          QType={QType}
          chosenSurveyTags={
            getChosenTags.isSuccess ? getChosenTags?.data.tags : null
          }
          chosenPrograms={
            getChosenPrograms.isSuccess
              ? getChosenPrograms?.data.programs
              : null
          }
          removeProgram={removeProgram}
          removeSurveyTag={removeSurveyTag}
          addQuestionTag={addQuestionTag}
          removeQuestionTag={removeQuestionTag}
          onQTagTimeFrameChange={onQTagTimeFrameChange}
          addToSelection={addToSelection}
          removeFromSelection={removeFromSelection}
        />

        <div
          style={{
            // marginTop: "15px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            paddingTop: "10px",
          }}
        >
          <div
            className={`${accordianStyles.title}`}
            style={{ paddingBottom: "5px" }}
          >
            Surveys
            <i style={{ fontSize: "1.2em" }} className="bi bi-clipboard"></i>
          </div>

          {potentialProjects.length > 0 && (
            <PickQuestions
              viz={viz}
              questionType={viz.type === TableType ? undefined : QType}
              potentialProjects={potentialProjects}
              removeProject={removeProject}
              removeQuestion={removeQuestion}
              addQuestion={addQuestion}
            ></PickQuestions>
          )}
        </div>

        <div className={styles.addSurvey}>
          {/* <div
            className={`${accordianStyles.title}`}
            style={{ paddingBottom: "5px" }}
          >
            Surveys
            <i style={{ fontSize: "1.2em" }} className="bi bi-clipboard"></i>
          </div> */}
          <div
            className={styles.add}
            style={addSurvey ? { visibility: "hidden" } : undefined}
            onClick={() => setAddSurvey(true)}
          >
            + Add Survey
          </div>

          {getChosenTags.isSuccess && getChosenPrograms.isSuccess && (
            <AddSurvey
              addProject={addProject}
              potentialProjects={potentialProjects}
              setPotentialProjects={setPotentialProjects}
              chosenTags={getChosenTags?.data.tags}
              chosenPrograms={getChosenPrograms?.data.programs}
              addSurveyTag={addSurveyTag}
              addProgram={addProgram}
              close={() => setAddSurvey(false)}
              show={addSurvey}
            />
          )}
        </div>
      </div>
    </>
  );
}

function TagsAndPrograms({
  viz,
  QType,
  chosenSurveyTags,
  chosenPrograms,
  removeProgram,
  removeSurveyTag,
  addQuestionTag,
  removeQuestionTag,
  onQTagTimeFrameChange,
  addToSelection,
  removeFromSelection,
}) {
  const getAllTags = useFetchQTags();

  const [searchString, setSearchString] = useState("");
  const [active, setActive] = useState(false);

  return (
    <>
      {getAllTags.isError && (
        <ErrorBanner
          error={getAllTags.error}
          message="Error fetching question tags"
        />
      )}
      <div className={accordianStyles.title}>
        Question Tags <i className="bi bi-tags"></i>
      </div>
      <div className={styles.searchFor}>
        <input
          type="text"
          onChange={(e) => setSearchString(e.target.value)}
          className={styles.search}
          value={searchString}
          // placeholder="search"
          onFocus={() => setActive(true)}
          onBlur={() => setTimeout(() => setActive(false), 250)}
        ></input>
        <div className={styles.tagsAnchor}>
          {active && getAllTags.isSuccess && (
            <SearchQTags
              viz={viz}
              QType={QType}
              searchString={searchString}
              addQuestionTag={addQuestionTag}
              allTags={getAllTags.data.tags}
            />
          )}
        </div>
      </div>

      {viz.questionTags && viz.questionTags.length > 0 && (
        <div className={styles.qTags}>
          {viz.questionTags.map((t, i) => (
            <QuestionTag
              tag={t}
              viz={viz}
              onRemove={(oldQs) => removeQuestionTag(i, oldQs)}
              onTimeFrameChange={(copy, oldQs, newQs) =>
                onQTagTimeFrameChange(i, copy, oldQs, newQs)
              }
              addToSelection={(Qs) => addToSelection(i, Qs)}
              removeFromSelection={(Qs) => removeFromSelection(i, Qs)}
            />
          ))}
        </div>
      )}

      {chosenPrograms && chosenPrograms.length > 0 && (
        <div className={styles.potentialSurveyTags}>
          <div className={accordianStyles.title}>
            Programs <i className="bi bi-calendar-week"></i>
          </div>
          <div className={styles.tags}>
            {chosenPrograms.map((p) => (
              <Program program={p} removeProgram={removeProgram} />
            ))}
          </div>
        </div>
      )}
      {chosenSurveyTags && chosenSurveyTags.length > 0 && (
        <div className={styles.potentialSurveyTags}>
          <div className={accordianStyles.title}>
            Survey Tags <i className="bi bi-tags"></i>
          </div>
          <div className={styles.tags}>
            {chosenSurveyTags.map((tag) => (
              <SurveyTag tag={tag} removeSurveyTag={removeSurveyTag} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

function Program({ program, removeProgram }) {
  return (
    <div className={styles.tag}>
      {program?.mainProject?.name}{" "}
      <i
        className={`bi bi-x ${styles.deleteTag}`}
        onClick={() => removeProgram(program)}
      ></i>
    </div>
  );
}

function SurveyTag({ tag, removeSurveyTag }) {
  return (
    <div className={styles.tag}>
      {tag.label}
      <i
        className={`bi bi-x ${styles.deleteTag}`}
        onClick={() => removeSurveyTag(tag)}
      ></i>
    </div>
  );
}

function SearchQTags({ viz, QType, searchString, addQuestionTag, allTags }) {
  function getQuestionTags() {
    let tags = [...allTags];

    if (viz.type !== TableType) {
      if (QType) {
        tags = tags.filter((t) => t.type === QType);
      }
    }

    if (viz.questionTags) {
      tags = tags.filter(
        (t) => !viz.questionTags.some((includedTag) => includedTag.id === t.id)
      );
    }
    if (searchString) {
      tags = tags.filter((t) =>
        t.name.toLowerCase().includes(searchString.toLowerCase())
      );
    }
    return tags;
  }

  return (
    <div className={styles.tagOptions}>
      {getQuestionTags().map((t) => (
        <div className={styles.tagOption} onClick={() => addQuestionTag(t)}>
          <div className={styles.qTagName}>
            {t.name}{" "}
            {t.color && (
              <i className={`bi bi-tag-fill`} style={{ color: t.color }}></i>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

function AddSurvey({
  addProject,
  potentialProjects,
  setPotentialProjects,
  chosenTags,
  chosenPrograms,
  addSurveyTag,
  addProgram,
  close,
  show,
}) {
  useEffect(() => {
    //Add to potential projects all projects from the survey tag
    let surveys = [...potentialProjects];
    let changed = false;
    for (let tag of chosenTags) {
      for (let proj of tag.project) {
        if (!surveys.includes(proj.id)) {
          surveys.push(proj.id);
          changed = true;
        }
      }
    }

    for (let prog of chosenPrograms) {
      for (let proj of prog.project) {
        if (!surveys.includes(proj.id)) {
          surveys.push(proj.id);
          changed = true;
        }
      }
    }

    if (changed) {
      setPotentialProjects(surveys);
    }
  }, []);

  return (
    <>
      {show && (
        <AddSurveyModal
          addProject={addProject}
          potentialProjects={potentialProjects}
          chosenTags={chosenTags}
          chosenPrograms={chosenPrograms}
          addSurveyTag={addSurveyTag}
          addProgram={addProgram}
          close={close}
        />
      )}
    </>
  );
}

function AddSurveyModal({
  chosenTags,
  chosenPrograms,
  addSurveyTag,
  addProgram,
  addProject,
  potentialProjects,
  close,
}) {
  function clickOutListener(e) {
    if (ref.current && !ref.current.contains(e.target)) {
      close();
      document.removeEventListener("click", clickOutListener, true);
    }
  }

  const ref = useRef();

  useEffect(() => {
    document.addEventListener("click", clickOutListener, true);
    return () => {
      document.removeEventListener("click", clickOutListener, true);
    };
  }, []);

  return (
    <>
      <div className={styles.addSettings} ref={ref}>
        <i className="bi-x-lg" onClick={close}></i>
        <FlexCol gap={"5px"}>
          <ChartLabel
            style={{
              paddingLeft: "5px",
            }}
          >
            Add Survey
          </ChartLabel>
          <SearchSurvey
            addProject={addProject}
            exceptForIds={potentialProjects}
          ></SearchSurvey>
        </FlexCol>
        {chosenTags && chosenPrograms && (
          <AddTagsAndPrograms
            chosenTags={chosenTags}
            chosenPrograms={chosenPrograms}
            addSurveyTag={addSurveyTag}
            addProgram={addProgram}
          ></AddTagsAndPrograms>
        )}
      </div>
    </>
  );
}

export function SearchSurvey({ addProject, exceptForIds }) {
  const [searchString, setSearchString] = useState("");
  const [searchVisible, setSearchVisible] = useState(false);
  function changeSearch(e) {
    setSearchString(e.target.value);
  }
  const [perPage, setPerPage] = useState(0);
  const [pageSkip, setPageSkip] = useState(0);
  const [searchSort, setSearchSort] = useState({
    item: "startedAt",
    descend: true,
  });

  useEffect(() => {
    searchOptions.refetch();
  }, [searchString]);

  const searchOptions = useSearchProjectsForChart(
    searchString,
    pageSkip,
    perPage,
    searchSort
  );

  const ref = useRef(null);
  //handling the clicking outside of elements
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setSearchVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={styles.searchQuestionBox} ref={ref}>
      <input
        className={styles.searchQuestionInput}
        type="text"
        placeholder="search"
        onChange={changeSearch}
        onFocus={() => {
          setSearchVisible(true);
        }}
        // onBlur={() => {
        //   setSearchVisible(false);
        // }}
      ></input>
      {searchOptions.isError && (
        <ErrorBanner
          error={searchOptions.error}
          message="Error fetching surveys"
        />
      )}
      {searchVisible &&
        searchOptions.isSuccess &&
        !searchOptions.isRefetching && (
          <div className={styles.optionsBox}>
            <div className={styles.options}>
              {searchOptions.data?.surveys.map((project, index) => (
                <>
                  {!exceptForIds.includes(project.id) && (
                    <div
                      key={index}
                      className={styles.option}
                      onClick={() => {
                        addProject(project.id);
                        setSearchVisible(false);
                      }}
                    >
                      {project.name}
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        )}
      {/* <Button onClick={() => {searchOptions.refetch()}}>refetch</Button> */}
    </div>
  );
}

function AddTagsAndPrograms({
  chosenTags,
  chosenPrograms,
  addSurveyTag,
  addProgram,
}) {
  const [active, setActive] = useState(false);
  const [searchString, setSearchString] = useState("");

  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current) {
      if (!ref.current.contains(event.target)) {
        document.removeEventListener("click", handleClickOutside, true);
        setActive(false);
      }
    } else {
      document.removeEventListener("click", handleClickOutside, true);
    }
  };

  const setUpActive = () => {
    document.addEventListener("click", handleClickOutside, true);
    setActive(true);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {};
  }, []);

  return (
    <>
      <FlexCol gap={"5px"}>
        <ChartLabel
          style={{
            paddingLeft: "5px",
          }}
        >
          Add Surveys By Program or Survey Tag
        </ChartLabel>

        <div className={styles.searchQuestionBox} ref={ref}>
          <input
            className={styles.searchQuestionInput}
            type="text"
            placeholder="search"
            onChange={(e) => setSearchString(e.target.value)}
            onFocus={setUpActive}
          ></input>
          {active && (
            <SearchTagsAndPrograms
              searchString={searchString}
              addProgram={addProgram}
              addSurveyTag={addSurveyTag}
              chosenTags={chosenTags}
              chosenPrograms={chosenPrograms}
            />
          )}
        </div>
      </FlexCol>
    </>
  );
}

function SearchTagsAndPrograms({
  searchString,
  addProgram,
  addSurveyTag,
  chosenTags,
  chosenPrograms,
}) {
  const getSurveyTags = useFetchSurveyTagsGql();
  const getPrograms = useFetchAllPrograms();

  function programs() {
    let programs = getPrograms.data.programs.filter(
      (p) => !chosenPrograms.some((prog) => prog.id === p.id)
    );
    if (searchString) {
      programs = programs.filter((p) =>
        p.mainProject.name.toLowerCase().includes(searchString.toLowerCase())
      );
    }
    return programs;
  }

  function tags() {
    let tags = getSurveyTags.data.surveyTagByOrgId.filter(
      (t) => !chosenTags.some((tag) => tag.id === t.id)
    );
    if (searchString) {
      tags = tags.filter((t) =>
        t.label.toLowerCase().includes(searchString.toLowerCase())
      );
    }
    return tags;
  }

  return (
    <div className={styles.optionsBox}>
      <div className={styles.options}>
        {getSurveyTags.isError && (
          <ErrorBanner
            error={getSurveyTags.error}
            message="Error fetching survey tags"
          />
        )}
        {getPrograms.isError && (
          <ErrorBanner
            error={getPrograms.error}
            message="Error fetching programs"
          />
        )}
        {getSurveyTags.isSuccess && getPrograms.isSuccess && (
          <>
            {programs().map((p) => (
              <div className={styles.option} onClick={() => addProgram(p)}>
                {p?.mainProject?.name}
                <div
                  className={`${styles.indicator} ${styles.programIndicator}`}
                >
                  Program
                </div>
              </div>
            ))}
            {tags().map((t) => (
              <div className={styles.option} onClick={() => addSurveyTag(t)}>
                {t?.label}
                <div
                  className={`${styles.indicator} ${styles.surveyTagIndicator}`}
                >
                  Tag
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

function PickQuestions({
  viz,
  questionType,
  potentialProjects,
  removeProject,
  removeQuestion,
  addQuestion,
}) {
  const getProjects = useFetchProjectsByIdsChart({
    projectIds: potentialProjects,
  });

  function getProjectsInOrder() {
    let surveys = [...getProjects.data.survey];

    surveys.sort((a, b) => {
      //Sort the projects by survey date
      if (a.startedAt === null || b.startedAt === null) {
        if (a.startedAt) return -1;
        if (b.startedAt) return 1;
        return 0;
      }
      let aDate = new Date(a.startedAt);
      let bDate = new Date(b.startedAt);
      return bDate - aDate;
    });
    return surveys;
  }

  return (
    <>
      {getProjects.isError && (
        <ErrorBanner
          error={getProjects.error}
          message="Error fetching projects for chart"
        />
      )}
      {getProjects.isSuccess && (
        <div className={styles.currentData}>
          {getProjectsInOrder().map((project, index) => (
            <div key={index} className={styles.projRow}>
              <Project
                viz={viz}
                project={project}
                questionType={questionType}
                removeProject={removeProject}
                addQuestion={addQuestion}
                removeQuestion={removeQuestion}
              ></Project>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

function Project({
  viz,
  project,
  questionType,
  removeProject,
  addQuestion,
  removeQuestion,
}) {
  const [showQuestions, setShowQuestions] = useState(false);
  const [addAll, setAddAll] = useState(false);

  function handleAddQ(q) {
    addQuestion(q);
    setShowQuestions(false);
  }

  return (
    <>
      <div className={styles.chosenSurvey}>
        <div className={styles.projectName}> {project.name} </div>
        <i className="bi bi-x-lg" onClick={() => removeProject(project.id)}></i>
      </div>

      <div className={styles.questionContainer}>
        {viz.question
          .filter((q) => q.projectId === project.id)
          .map((question) => (
            <div key={question.id} className={styles.mid}>
              <div className={styles.midItem}>
                <>{question?.questionText}</>
              </div>

              <div
                className={styles.xicon}
                onClick={() => {
                  removeQuestion(question.id);
                }}
              >
                <i className="bi bi-x-lg"></i>
              </div>
              {/* {question.projectId === project.id && (
              <div className={styles.currDataQuestionBoxDark}>
                <div className={styles.currDataQuestion}>
                  {question?.questionText}
                </div>
                <div
                  className={styles.xicon}
                  onClick={() => {
                    removeQuestion(question.id);
                  }}
                >
                  <i className="bi bi-x-lg"></i>
                </div>
              </div>
            )} */}
            </div>
          ))}

        <div
          className={styles.plusicon}
          onClick={() => setShowQuestions(true)}
          style={
            viz.question.some((q) => q.projectId === project.id)
              ? { alignSelf: "center" }
              : undefined
          }
        >
          <i className="bi bi-plus"></i>
          {!viz.question.some((q) => q.projectId === project.id) && (
            <span>Question</span>
          )}
        </div>
      </div>

      {showQuestions && (
        <Questions
          projectId={project.id}
          setShowQuestions={setShowQuestions}
          currQuestions={viz.question}
          questionType={questionType}
          addQuestion={handleAddQ}
          addAll={addAll}
          viz={viz}
        ></Questions>
      )}
    </>
  );
}

function Questions({
  projectId,
  setShowQuestions,
  currQuestions,
  questionType,
  addQuestion,
  addAll,
  viz,
}) {
  const getQuestions = useFetchQuestionsGql(projectId);

  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowQuestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  function getUnpickedQs() {
    if (addAll) {
      forEach(getQuestions.data.QuestionByProjectID, (question) => {
        if (!currQuestions.some((q) => q.id === question.id)) {
          addQuestion(question);
        }
      });
      setShowQuestions(false);
      return [];
    } else {
      let unPicked = [];
      forEach(getQuestions.data.QuestionByProjectID, (question) => {
        if (!currQuestions.some((q) => q.id === question.id)) {
          unPicked.push(question);
        }
      });
      unPicked.sort((a, b) => {
        if (a.pageNumber != b.pageNumber) {
          return a.pageNumber - b.pageNumber;
        } else {
          return a.pageOrderIndex - b.pageOrderIndex;
        }
      });
      return unPicked;
    }
  }

  return (
    <div className={styles.optionsBox2} ref={ref}>
      {getQuestions.isLoading && <Loading></Loading>}
      {getQuestions.isError && (
        <ErrorBanner
          error={getQuestions.error}
          message="Error fetching questions in survey"
        />
      )}
      {getQuestions.isSuccess && (
        <div className={styles.options}>
          {getUnpickedQs().map((q) => (
            <Q
              q={q}
              questionType={questionType}
              addQuestion={addQuestion}
              viz={viz}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function Q({ q, questionType, addQuestion, viz }) {
  const [prompt, setPrompt] = useState();

  const thisQsType = getQuestionType(q);

  function onNonClickableClick() {
    if (!prompt) {
      // if (lockedOut) {
      //   setPrompt(
      //     <div>
      //       Not of type:
      //       <span
      //         style={{
      //           fontSize: ".9em",
      //           color: "#a3a4a8",
      //           fontWeight: 500,
      //           paddingLeft: "4px",
      //         }}
      //       >
      //         Matrix
      //       </span>
      //     </div>
      //   );
      // } else {
      setPrompt(
        <div>
          Not of type:{" "}
          <span
            style={{
              fontSize: ".9em",
              color: "#a3a4a8",
              fontWeight: 500,
              paddingLeft: "4px",
            }}
          >
            {questionType}
          </span>
        </div>
      );
      // }

      setTimeout(() => setPrompt(null), 1500);
    } else {
      setPrompt(null);
    }
  }

  // const lockedOut = viz.designSettings.pivotOptions && q.type !== "Matrix";
  const goodToGo = thisQsType === questionType || !questionType;

  // q.textQuestion && viz.type !== TableType
  //   ? false
  //   : !questionType || thisQsType === questionType;

  return (
    <div
      className={goodToGo ? styles.option : styles.nullOption}
      onClick={goodToGo ? () => addQuestion(q) : onNonClickableClick}
    >
      {q.questionText}
      <span className={styles.qType}>{thisQsType}</span>
      {prompt && <div className={styles.blur}>{prompt}</div>}
    </div>
  );
}
