// External
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Internal
import {
  AllContacts,
  Manual,
  ImportedCleaner,
  Audiences,
  PreviousImports,
} from "pages";

import Audience from "pages/contacts/audiences/Audience";
import UnifinishedUpload from "pages/contacts/all_contacts/NewContacts/upload/UnifinishedUpload";

export const ContactRoutes = ({
  onExport,
  updateRoute,
  user,
  roles,
  organization,
}) => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="Contacts" />} />
      {roles?.canViewContacts && (
        <Route
          path="Contacts"
          element={
            <AllContacts
              user={user}
              organization={organization}
              roles={roles}
              onExport={onExport}
              updateRoute={updateRoute}
            />
          }
        />
      )}

      {roles?.canViewOrgAudiences && (
        <Route
          path="/Audiences/*"
          element={
            <Audiences
              user={user}
              organization={organization}
              roles={roles}
              updateRoute={updateRoute}
            />
          }
        />
      )}

      {roles?.canViewOrgAudiences && (
        <Route
          path="Audiences/:id"
          element={
            <Audience
              user={user}
              organization={organization}
              roles={roles}
              onExport={onExport}
              updateRoute={updateRoute}
            />
          }
        />
      )}
      {(roles?.canViewOrgAudiences || roles?.canViewContacts) && (
        <>
          <Route
            path="Uploads/*"
            element={
              <PreviousImports
                user={user}
                organization={organization}
                roles={roles}
                updateRoute={updateRoute}
              />
            }
          />
          <Route
            path="Uploads/:id"
            element={
              <UnifinishedUpload
                user={user}
                organization={organization}
                roles={roles}
                updateRoute={updateRoute}
              />
            }
          />
        </>
      )}
    </Routes>
  );
};

export const AddContactRoutes = () => {
  // const getCurrUser = useGetCurrentUser();
  return (
    <Routes>
      <Route path="/" element={<Navigate to="updloadcontacts" />} />
      <Route path="manual" element={<Manual />} />
      <Route path=":id" element={<ImportedCleaner />} />
    </Routes>
  );
};
