import React, { useEffect, useRef, useState } from "react";
import styles from "./VerifyEmail.module.scss";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { TextFieldSimple } from "components/inputs";
import Button from "components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCheckUserToken,
  useResendCreateAccountCode,
  useValidateJoinCodeToken,
} from "api/resources/authentication/register";
import TokenExpired from "pages/error_page/TokenExpired";
import { Loading } from "components/Loading/Loading";

function VerifyEmail({ setActiveStep, join, setType }) {
  const { token } = useParams();
  const checkTokenService = useCheckUserToken();
  const navigate = useNavigate();
  const [valid, setValid] = useState(true);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkTokenService.mutate(
      {
        token: token,
      },
      {
        onSuccess: (data) => {
          setLoading(false);
          if (data?.status?.join && !join) {
            setValid(false);
            setMessage("Page not valid");
          } else if (data?.status?.status === 2 && !join) {
            navigate(`/register/your-organization/${token}`);
          } else if (data?.status?.status === 2 && join) {
            navigate(
              `/register/join-organization/welcome-to-reaction/${token}`
            );
          } else if (data?.status?.status === 1) {
            setEmail(data?.status?.email);
          } else {
            setValid(false);
            setMessage(data?.status?.description);
          }
        },
      }
    );
  }, []);

  return valid ? (
    <>
      {loading && <Loading></Loading>}
      {!loading && (
        <VerifyEmailContent
          setActiveStep={setActiveStep}
          email={email}
          token={token}
          join={join}
          setType={setType}
        />
      )}
    </>
  ) : (
    <TokenExpired message={message}>
      <Button blue width={200} shadow onClick={() => navigate("/register")}>
        Create new account
      </Button>
    </TokenExpired>
  );
}

export default VerifyEmail;

function VerifyEmailContent({ setActiveStep, email, token, join, setType }) {
  const [resend, setResend] = useState(true);
  const [resendCount, setResendCount] = useState(0);
  const [working, setWorking] = useState(false);
  const [verifyCode, setVerifyCode] = useState();
  const [error, setError] = useState("");

  const validateCode = useValidateJoinCodeToken();
  const resendCode = useResendCreateAccountCode();
  const navigate = useNavigate();

  useEffect(() => {
    // Stop countdown when it reaches 0
    if (resendCount <= 0) {
      if (!resend) {
        setResend(true);
      }

      return;
    }

    // Decrease the countdown every second
    const timer = setInterval(() => {
      setResendCount((prevTime) => prevTime - 1);
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, [resendCount]); // Re-run the effect when timeLeft changes

  function resendJoinCode() {
    resendCode.mutate(
      {
        token: token,
      },
      {
        onSuccess: (data) => {},
        onError: (data) => {
          setError(data);
        },
      }
    );
  }

  function validateVerifyCode(code) {
    setWorking(true);
    validateCode.mutate(
      {
        code: code,
        token: token,
      },
      {
        onSuccess: (data) => {
          setWorking(false);
          if (data?.response?.success && !join) {
            navigate(`/register/your-organization/${token}`);
          }
          if (data?.response?.success && join) {
            navigate(`/register/welcome-to-reaction/${token}`);
          }
        },
        onError: (data) => {
          setError("Eror: Not a valid code");
          setWorking(false);
        },
      }
    );
  }

  return (
    <div className={styles.content}>
      <FlexCol align={"center"} justify={"center"} className={styles.inner}>
        <img
          src={require("assets/images/EmailImage.png")}
          width={"300px"}
          style={{ marginBottom: "3rem" }}
        ></img>
        <FlexCol fit gap=".5rem" align={"center"}>
          <h2>Verify your email</h2>
          <span style={{ maxWidth: "300px", textAlign: "center" }}>
            Please enter the 5 digit code sent to {email}
          </span>
        </FlexCol>
        <FlexCol style={{ marginTop: "2rem" }} align={"center"} gap="1rem">
          <CodeInput onComplete={setVerifyCode}></CodeInput>
          <div className={styles.red}>{error}</div>

          <FlexRow gap={"2rem"} justify={"center"}>
            <Button
              blue
              shadow
              width={150}
              height={2.5}
              style={{ fontSize: "1.1rem" }}
              onClick={(e) => validateVerifyCode(verifyCode)}
            >
              {!working ? (
                "Confirm"
              ) : (
                <>
                  Working
                  <span className={styles.dots}>
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                  </span>
                </>
              )}
            </Button>
          </FlexRow>
        </FlexCol>

        <div
          className={styles.text_2}
          style={{ marginTop: "1rem", textAlign: "center" }}
        >
          Didn't get a code?{" "}
          {resend ? (
            <a
              className={styles.link}
              onClick={() => {
                setResend(false);
                setResendCount(30);
                resendJoinCode();
              }}
            >
              Click to resend code
            </a>
          ) : (
            <span>Can resend code in {resendCount} seconds</span>
          )}
        </div>
      </FlexCol>{" "}
      {window.innerWidth <= 800 && (
        <>
          <div
            style={{
              display: "flex",
              gap: ".5rem",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              marginTop: "4rem",
            }}
          >
            Already have an account?
            <div
              style={{
                display: "flex",
                gap: ".5rem",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                onClick={() => navigate("/login")}
                type="submit"
                shadow
                blue
              >
                Sign In
              </Button>
              or{" "}
              <Button
                onClick={() => {
                  navigate("/register/join-organization"), location.reload();
                }}
                type="submit"
                shadow
                blue
              >
                Join Organization
              </Button>
            </div>
          </div>
          <div className={styles.register}>
            Request a demo or learn more at{" "}
            <a href="https://reactiondata.com" className={styles.aLink}>
              {" "}
              reactiondata.com
            </a>
            .
          </div>
        </>
      )}
    </div>
  );
}

export const CodeInput = ({ length = 5, onComplete }) => {
  const [values, setValues] = useState(Array(length).fill(""));
  const inputRefs = useRef([]);

  const handleChange = (index, event) => {
    const newValues = [...values];
    const inputValue = event.target.value; // Convert to uppercase

    // Allow only alphanumeric characters
    if (!/^[a-zA-Z0-9]?$/.test(inputValue)) return;

    newValues[index] = inputValue;
    setValues(newValues);

    // Move to the next input if there's a value
    if (inputValue && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }

    // Call onComplete if all fields are filled
    if (newValues.every((val) => val !== "")) {
      onComplete?.(newValues.join(""));
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && !values[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData
      .getData("text")
      .slice(0, length)
      .replace(/[^A-Z0-9]/gi, "");

    const newValues = pasteData
      .split("")
      .concat(Array(length).fill(""))
      .slice(0, length);
    setValues(newValues);

    // Auto focus next empty field
    newValues.forEach((val, idx) => {
      if (val && inputRefs.current[idx]) {
        inputRefs.current[idx].focus();
      }
    });

    if (newValues.every((val) => val !== "")) {
      onComplete?.(newValues.join(""));
    }
  };

  return (
    <div className={styles["code-input-container"]}>
      {values.map((val, index) => (
        <input
          key={index}
          ref={(el) => (inputRefs.current[index] = el)}
          type="text"
          inputMode="text"
          maxLength="1"
          value={val}
          onChange={(e) => handleChange(index, e)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          onPaste={handlePaste}
          className={styles["code-input"]}
        />
      ))}
    </div>
  );
};
