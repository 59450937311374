import React, { useEffect, useMemo, useState } from "react";
import styles from "./EmailPref.module.scss";
import { useFetchOrganization } from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import { useParams } from "react-router-dom";
import { APISettings, OrgSignatures } from "./OrganizationDetails";
import EditField from "components/inputs/input_fields/EditField/EditField";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { DomainNames } from "./DomainNames/DomainNames";

function EmailPref({ roles, user, updateRoute }) {
  const { id } = useParams();
  const MyOrg = useFetchOrganization(id);

  useMemo(() => {
    if (updateRoute) {
      updateRoute(2);
    }
  }, []);

  return (
    <div className={styles.page}>
      {MyOrg.isLoading && (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Loading></Loading>
        </div>
      )}
      {MyOrg.isSuccess && (
        <EmailPrefContent
          organization={MyOrg.data.organization}
          role={roles}
          user={user}
        />
      )}
    </div>
  );
}

export default EmailPref;

const providers = [
  {
    value: "Sendgrid",
    label: (
      <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
        <img
          src={require("assets/images/sendgrid.webp")}
          style={{ width: "50px" }}
        ></img>
        Sendgrid
      </div>
    ),
  },
  // {
  //   value: "Amazons SES",
  //   label: (
  //     <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
  //       <img
  //         src={require("assets/images/amazonses.png")}
  //         style={{ width: "50px"}}
  //       ></img>
  //       Amazon SES
  //     </div>
  //   ),
  // },
  // {
  //   value: "MailChimp",
  //   label: (
  //     <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
  //       <img
  //         src={require("assets/images/mailchimp.png")}
  //         style={{ width: "50px" }}
  //       ></img>
  //       MailChimp
  //     </div>
  //   ),
  // },
];

function EmailPrefContent({ organization, role, user }) {
  return (
    <div className={styles.page}>
      <FlexCol style={{ flex: 1, padding: "2rem" }}>
        <FlexCol gap="1rem">
          <FlexCol>
            <h3>Email Settings</h3>
            <span>
              These settings are only applicable if you will be sending emails
              within the platform
            </span>
            <HorizontalBar
              height={2}
              style={{ marginBottom: "1rem" }}
            ></HorizontalBar>
          </FlexCol>

          <EditField
            value={
              <div
                style={{ display: "flex", gap: ".5rem", alignItems: "center" }}
              >
                <img
                  src={require("assets/images/sendgrid.webp")}
                  style={{ height: "40px" }}
                ></img>
                Sendgrid
              </div>
            }
            label="Email Provider"
            onClickEdit={() => undefined}
          />

          <APISettings organization={organization} role={role}></APISettings>

          <DomainNames></DomainNames>
        </FlexCol>
        {/* <div className={styles.section} style={{ border: "none" }}>
          <div
            className={`${styles.header_4} `}
            style={{ fontWeight: "400", color: "black", margin: "0" }}
          >
            Domain Authentication
          </div>
          <FlexRow
            gap={"1rem"}
            justify={"space-between"}
            style={{ flexWrap: "wrap" }}
          >
            <div className={styles.text_2} style={{ width: "370px" }}>
              Improve deliverability by proving to inbox providers that you own
              the domain you're sending from.
            </div>{" "}
            <Button blue shadow style={{ whiteSpace: "nowrap" }}>
              Authenticate New Domain
            </Button>
          </FlexRow>
        </div> */}
      </FlexCol>
      <div
        className={styles.section}
        style={{ minHeight: "100vh", width: "500px", overflow: "auto" }}
      >
        <OrgSignatures onClose={() => undefined}></OrgSignatures>
      </div>
    </div>
  );
}
