// External
import React, { useEffect, useMemo, useRef, useState } from "react";

// Internal
import {
  useDeleteContactGql,
  useSearchContacts,
  useUpdateContacts,
} from "api/resources/contacts/contacts";
import styles from "./AllContacts.module.scss";
import Modal from "components/ReactModal/ReactModal.jsx";
import ContactDetails from "components/Popout/ContactProfile";
import { useFetchOrganizationLinkedAccounts } from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import Icon from "components/Icon/Icon";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import ObjectTable from "components/tables/BasicTable/ObjectTable";
import { parseCustomFields } from "assets/functions/ObjectFunctions";
import FieldAdjuster from "./FieldAdjuster";
import ColumnsToDisplay from "./ColumnsToDisplay";
import ImportHow from "./ImportHow";
import { useNavigate } from "react-router-dom";
import EditSingleCustomField from "components/CustomFieldsEditor/EditSingle";
import { AddContact } from "./NewContacts/AddContact/AddContact";
import CreateAudience from "../audiences/CreateAudience";
import { Export } from "./export/Export";
import ObjectLayout from "components/layouts/ObjectLayout/ObjectLayout";

export const AllContacts = ({
  user,
  organization,
  roles,
  onExport,
  updateRoute,
}) => {
  const MyOrg = useFetchOrganizationLinkedAccounts(organization?.id);
  useMemo(() => {
    if (updateRoute) {
      updateRoute(0);
    }
  }, []);
  return (
    <div className={styles.pager}>
      {MyOrg.isLoading && <Loading></Loading>}
      {MyOrg.isSuccess && (
        <AllContactsContent
          user={user}
          organization={MyOrg.data?.organization}
          roles={roles}
          customFields={MyOrg.data?.organization?.custom_field}
          onExport={onExport}
        ></AllContactsContent>
      )}
    </div>
  );
};

const AllContactsContent = ({
  user,
  organization,
  roles,
  customFields,
  onExport,
}) => {
  const [activeContact, setActiveContact] = useState();

  const seeContact = (contact) => {
    setActiveContact(contact);
  };

  const obscure = !roles?.canSeeContactInfo;
  const [selected, setSelected] = useState([]);
  const [headers, setHeaders] = useState(initHeaders());
  function initHeaders() {
    let heads = [];

    for (let field of customFields) {
      if (field?.dataType != "lookup") {
        heads.push({
          // width: 200,
          id: field?.id,
          value: field?.generic ? field?.name : field?.id,
          label: field?.displayName ? field?.displayName : field?.name,
          name: field?.name,
          dataType: field?.dataType,
          enabled: field?.enabled,
          editable: field?.editable,
          custom: true,
          sort: false,
          canSort: field?.generic,
          obscure: obscure,
          salesforceColumn: field?.salesforceColumn,
          icon: field?.icon ? field?.icon : "person",
          multiple: field?.multiple,
          delimiter: field?.delimiter,
          generic: field?.generic,
          index: field?.index,
          min: field?.min ? field?.min : 0,
          max: field?.max ? field?.max : 100,
          trueValue: field?.trueValue,
          falseValue: field?.falseValue,
          unique: field?.unique ? true : false,
          required: field?.required ? true : false,
          defaultValue: field?.defaultValue,
          cell_style: (name, id, i, contact) =>
            checkStyle(name, id, i, contact, field),
        });
      }
    }
    return heads;
  }

  function checkStyle(name, id, i, contact, field) {
    if (field?.name === "contactName" && contact) {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
          <div
            className={`${styles.link} ${styles.blueDark}`}
            style={{ fontWeight: "500" }}
            onClick={() => {
              seeContact(contact);
            }}
          >
            {contact && `${contact?.firstName} ${contact?.lastName}`}
          </div>
        </div>
      );
    }

    if (field?.dataType === "boolean") {
      if (name === true) {
        return field?.trueValue ? field?.trueValue : "true";
      }
      if (!name || name === false) {
        return field?.falseValue ? field?.falseValue : "false";
      }
    }

    return name;
  }

  const [sort, setSort] = useState({
    item: "email",
    descend: false,
  });

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSkip, setPageSkip] = useState(0);

  const [perPage, setPerpage] = useState(50);
  const [chosenFilters, setChosenFilters] = useState([]);

  const [saving, setSaving] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [search, setSearch] = useState("");
  const [adjustFields, setAdjustFields] = useState(false);
  const [displayColumns, setDisplayColumns] = useState(false);
  const [importer, setImporter] = useState(false);
  const [newColumn, setNewColumn] = useState(false);
  const [addContact, setAddContact] = useState(false);
  const [createAudience, setCreateAudience] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [showLayout, setShowLayout] = useState(false);

  const newOptions = [
    {
      onClick: () => setAddContact(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"person-fill"}></Icon> Create a new contact
          </FlexRow>{" "}
          <div className={styles.description2}>
            Create a new contact from scratch with columns.
          </div>
        </div>
      ),
    },
    {
      onClick: () => setImporter(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"people-fill"}></Icon> Upload Contacts
          </FlexRow>{" "}
          <div className={styles.description2}>Import in bulk with a file.</div>
        </div>
      ),
    },
  ];
  const dotOptions = [
    {
      onClick: () => setShowLayout(true),
      data: (
        <>
          <Icon sapphire iconName={"layout-text-window-reverse"}></Icon> Contact
          Layout
        </>
      ),
    },
  ];

  const columnOptions = [
    {
      onClick: () =>
        roles?.canEditContacts ? setAdjustFields(!adjustFields) : undefined,
      data: (
        <>
          <Icon sapphire={roles?.canEditContacts} iconName={"pencil"}></Icon>{" "}
          Edit columns
        </>
      ),
      style: { backgroundColor: !roles?.canEditContacts ? "lightgray" : "" },
    },
    {
      onClick: () => setDisplayColumns(true),
      data: (
        <>
          <Icon sapphire iconName={"columns"}></Icon> Select columns to display
        </>
      ),
    },
    {
      onClick: () => (roles?.canEditContacts ? setNewColumn(true) : undefined),
      data: (
        <>
          <Icon sapphire={roles?.canEditContacts} iconName={"plus"}></Icon>{" "}
          Create a new column
        </>
      ),
      style: { backgroundColor: !roles?.canEditContacts ? "lightgray" : "" },
    },
  ];

  function handleSelectPage() {
    let toadd = [];
    let firstIndex = 100;
    for (let c of data) {
      let index = selected.findIndex((i) => i.id === c.id);
      if (index < 0) {
        toadd.push(c);
      } else if (index < firstIndex) {
        firstIndex = index;
      }
    }
    let items = [...selected, ...toadd];
    if (toadd.length === 0) {
      items.splice(firstIndex, data.length);
    }

    setSelected(items);
  }

  const selectedOptions = [
    { onClick: () => handleSelectPage(), data: "Select Page" },
    { onClick: () => setSelected([]), data: "Remove Selected" },
  ];

  const contactsQuery = useSearchContacts(
    search,
    perPage,
    sort,
    "",
    "",
    JSON.stringify(chosenFilters)
  );
  let data = parseCustomFields(
    contactsQuery?.data?.pages.flatMap((page) => page?.response?.contacts) ||
      [],
    headers
  );

  const count =
    contactsQuery?.data?.pages?.length > 0
      ? contactsQuery?.data?.pages[0]?.response?.count
      : 0;

  function handleFilterChange(val) {
    setChosenFilters(val);
  }

  function handleSetHeaders(vals) {
    setHeaders(vals);
    contactsQuery.remove();
    contactsQuery.refetch();
  }

  function handleSearch(val) {
    setSearch(searchString);
    contactsQuery?.remove();
    contactsQuery?.refetch();
  }

  function handleColumns(val) {
    setDisplayColumns(val);
  }

  function handlePageNum(val) {
    if (contactsQuery?.hasNextPage) {
      contactsQuery?.fetchNextPage();
      setPageNumber(val);
    }
  }

  useEffect(() => {
    contactsQuery.remove();
    contactsQuery.refetch();
  }, [chosenFilters]);

  const navigate = useNavigate();

  const updateQuery = useUpdateContacts();
  function saveContact(edits, newData) {
    // optimistically update local table UI immediately
    setSaving(true);

    edits = JSON.stringify(edits);

    updateQuery.mutate(
      { data: edits },
      {
        onSuccess: async () => {
          // Delay slightly to avoid flicker if needed
          await contactsQuery.refetch();
          setSaving(false);
        },
        onError: (err) => {
          console.error("Failed to save contacts:", err);
          // optionally show error and revert local data
          setSaving(false);
        },
      }
    );
  }

  const deletePerson = useDeleteContactGql();

  const deleteContact = (data) => {
    if (window.confirm("Are you sure you want to delete this contact?")) {
      deletePerson.mutate(
        {
          deleteContactId: data?.id,
        },
        {
          onSuccess: (data) => {
            contactsQuery.refetch();
          },
        }
      );
    }
  };

  const timeRef = useRef(0);

  function editSearch(val) {
    setSearchString(val);
    if (timeRef.current) clearTimeout(timeRef.current);
    timeRef.current = setTimeout(() => {
      setSearch(val);
      timeRef.current = 0;
    }, 400);
  }

  return (
    <>
      <ObjectTable
        data={data}
        headers={headers}
        setHeaders={setHeaders}
        maxPage={count / perPage < 1 ? 1 : Math.ceil(count / perPage)}
        maxItems={count}
        pageNum={pageNumber}
        setPageNum={handlePageNum}
        perPage={perPage}
        setPerPage={setPerpage}
        sort={sort}
        setSort={setSort}
        pageSkip={pageSkip}
        setPageSkip={setPageSkip}
        customFields={customFields}
        user={user}
        roles={roles}
        searchString={searchString}
        setSearchString={editSearch}
        onSearch={handleSearch}
        searchPlaceholder={"Search Contacts..."}
        emptyMessage={"No Contacts"}
        addOptions={newOptions}
        addOptionsHeight={100}
        settingOptions={dotOptions}
        settingOptionsHeight={50}
        filters={chosenFilters}
        setFilters={handleFilterChange}
        loading={contactsQuery?.isLoading}
        fullLoading={saving}
        // select
        selected={selected}
        setSelected={setSelected}
        selectedOptions={selectedOptions}
        selectedOptionsHeight={50}
        allColumns={customFields}
        columnOptions={columnOptions}
        columnOptionsHeight={50}
        exportClick={
          roles?.canSeeContactInfo ? () => setShowExport(true) : undefined
        }
        bottomButton={
          <div
            className={styles.link}
            onClick={() => {
              navigate("/previous-imports");
            }}
          >
            View Import Jobs
          </div>
        }
        onCreateAudienceClick={
          roles?.canCreateEditOrgAudiences
            ? () => setCreateAudience(true)
            : undefined
        }
        onSaveEditableCell={saveContact}
        onClickEditAction={seeContact}
        onClickDeleteAction={roles?.canEditContacts ? deleteContact : undefined}
        actionCell
        refetch={() => {
          contactsQuery.remove();
          contactsQuery.refetch();
        }}
        setDisplayColumns={setDisplayColumns}
      ></ObjectTable>

      <Modal
        show={showLayout}
        onClose={() => {
          setShowLayout(false);
        }}
        modalStyle={{
          borderRadius: "1em",
          height: "100%",
          width: "100%",
          // maxWidth: "1200px",
          overflow: "hidden",
        }}
        dark
      >
        {showLayout && (
          <ObjectLayout organizationId={organization?.id}></ObjectLayout>
        )}
      </Modal>

      {activeContact && (
        <ContactDetails
          onClose={() => {
            setActiveContact(null);
          }}
          contact={activeContact}
          refetch={contactsQuery.refetch}
        ></ContactDetails>
      )}

      {adjustFields && (
        <FieldAdjuster
          show={adjustFields}
          setShow={setAdjustFields}
          salesforce={organization?.linkedAccounts?.find(
            (a) => a?.accountType === "Salesforce"
          )}
        ></FieldAdjuster>
      )}

      {displayColumns && (
        <ColumnsToDisplay
          show={displayColumns}
          setShow={handleColumns}
          columns={headers}
          setColumns={handleSetHeaders}
        />
      )}

      {createAudience && (
        <Modal show={createAudience} dark>
          <CreateAudience
            audience={{}}
            create
            filters={chosenFilters}
            close={() => setCreateAudience(false)}
          ></CreateAudience>
        </Modal>
      )}

      {importer && (
        <Modal
          show={importer}
          dark
          modalStyle={{ width: "1000px" }}
          hideX
          onClose={() => setImporter(false)}
        >
          <ImportHow
            cancel={() => setImporter(false)}
            user={user}
            organization={organization}
            objectColumns={headers}
          ></ImportHow>
        </Modal>
      )}
      {newColumn && (
        <Modal
          dark
          show={newColumn}
          modalStyle={{ width: "800px" }}
          onClose={() => setNewColumn(undefined)}
        >
          <EditSingleCustomField
            onClose={() => setNewColumn(false)}
            field={{}}
            // onSaved={handleSaved}

            brandNew={true}
            // noModal
            // deleteField={handleDeleteField}
          />
        </Modal>
      )}
      {addContact && (
        <AddContact
          onClose={() => setAddContact(false)}
          onSave={() => {
            contactsQuery.refetch();
            setAddContact(false);
          }}
        />
      )}

      {showExport && (
        <Export
          onClose={() => setShowExport(false)}
          org={organization}
          filters={chosenFilters}
          search={searchString}
          sort={sort}
          headers={headers}
          onExport={onExport}
        />
      )}
    </>
  );
};
