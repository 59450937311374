import React, { useState } from "react";
import styles from "./AddToFolder.module.scss";
import { Header } from "components/layouts";
import { useGetAudienceFolders } from "api/resources/contacts/audiences";
import Icon from "components/Icon/Icon";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Button from "components/Button/Button";

function AddToFolder({ audience, moveFile, close }) {
  const [sort, setSort] = useState({
    item: "name",
    descend: true,
  });
  const [selected, setSelected] = useState();

  const getFolders = useGetAudienceFolders(sort);

  return (
    <div className={styles.page}>
      <Header>
        <h4>Choose a folder to move/add "{audience?.name}" audience</h4>
      </Header>

      {getFolders?.data?.response?.folders?.map((folder) => (
        <div
          className={`${styles.folderItem} ${
            selected?.id === folder?.id && styles.selected
          }`}
          onClick={() => setSelected(folder)}
        >
          <FlexRow>
            <Icon
              blue
              iconName={folder?.icon}
              style={{ fontSize: "1.9rem" }}
            ></Icon>
            <FlexCol>
              <span>{folder?.name}</span>
              <small className={styles.gray}>
                {folder?.audienceCount} audiences
              </small>
            </FlexCol>
          </FlexRow>
          {selected?.id === folder?.id && (
            <Icon
              green
              iconName={"check-circle"}
              style={{ fontSize: "1.3rem" }}
            ></Icon>
          )}
        </div>
      ))}

      {selected && (
        <div className={styles.footer}>
          <Button
            blue
            shadow
            width={100}
            onClick={() => {
              moveFile(audience, selected);
              close();
            }}
          >
            Submit
          </Button>
        </div>
      )}
    </div>
  );
}

export default AddToFolder;
