import { useState } from "react";
import styles from "./OrgColorPalettes.module.scss";
import {
  useCreateColorPalette,
  useDeleteColorPalette,
  useFetchColorPalettes,
  useFetchReactionPalette,
  useUpdateColorPalette,
} from "api/resources/organization/colorpalettes";
import PaletteEditor from "components/ColorPalette/PaletteEditor";
import EditField from "components/inputs/input_fields/EditField/EditField";
import { ErrorBanner } from "pages/error_page/ErrorPage";

export default function OrgColorPalettes({ }) {
  const palettes = useFetchColorPalettes();
  const reaction = useFetchReactionPalette();

  const [editPalette, setEditPalette] = useState();
  const [createNew, setCreateNew] = useState(false);

  const savePalette = useUpdateColorPalette();
  const deletePalette = useDeleteColorPalette();
  const createPalette = useCreateColorPalette();

  function onSave(palette) {
    savePalette.mutate(
      { data: palette, id: editPalette.id },
      {
        onSuccess: (data) => {
          console.log("palette saved");
          // location.reload();
        },
      }
    );
    setEditPalette(null);
  }

  function onDelete() {
    deletePalette.mutate(
      {
        id: editPalette.id,
      },
      {
        onSuccess: (data) => {
          console.log("palette deleted");
        },
      }
    );
    setEditPalette(null);
  }

  function onCreate(palette) {
    createPalette.mutate(
      {
        data: palette,
      },
      {
        onSuccess: (data) => {
          console.log("palette deleted");
        },
      }
    );
    setCreateNew(false);
  }

  function showReactionDefault() {
    if (palettes.data.colorPalettes.find((p) => p.isDefault)) {
      return false;
    }
    return true;
  }

  return (
    <>
      {palettes.isError && (
        <ErrorBanner
          error={palettes.error}
          message={"Error loading color palettes"}
        />
      )}
      {reaction.isError && (
        <ErrorBanner
          error={reaction.error}
          message={"Error loading reaction palette"}
        />
      )}

      {savePalette.isError && (
        <ErrorBanner
          error={savePalette.error}
          message={"Error saving color palette"}
        />
      )}
      {deletePalette.isError && (
        <ErrorBanner
          error={deletePalette.error}
          message={"Error deleting color palette"}
        />
      )}
      {createPalette.isError && (
        <ErrorBanner
          error={createPalette.error}
          message={"Error creating color palette"}
        />
      )}
      {palettes.isSuccess && (
        <div className={styles.palettes}>
          {/* {palettes.data.colorPalettes.map((palette) => (
            <div className={styles.palette}>
              <div className={styles.editBox}>
                <div
                  className={styles.edit}
                  onClick={() => setEditPalette(palette)}
                >
                  Edit
                </div>
              </div>
              <div className={styles.name}>
                {palette.name}
                {palette.isDefault && (
                  <div className={styles.defaultAnchor}>
                    <div className={styles.default}>Default</div>
                  </div>
                )}
              </div>
              <div className={styles.colors}>
                {JSON.parse(palette.colors).map((color) => (
                  <div
                    className={styles.color}
                    style={{ backgroundColor: color }}
                  ></div>
                ))}
              </div>
            </div>
          ))} */}

          {palettes.data.colorPalettes.map((palette, i) => (
            <EditField
              key={i}
              value={
                <div className={styles.colors}>
                  {JSON.parse(palette.colors).map((color, i) => (
                    <div
                      key={i}
                      className={styles.color}
                      style={{ backgroundColor: color }}
                    ></div>
                  ))}
                </div>
              }
              label={
                <div className={styles.name}>
                  {palette.name}
                  {palette.isDefault && (
                    <div className={styles.defaultAnchor}>
                      <div className={styles.default}>Default</div>
                    </div>
                  )}
                </div>
              }
              onClickEdit={() => setEditPalette(palette)}
            />
          ))}

          {reaction.isSuccess && (
            <EditField
              value={
                <div className={styles.colors}>
                  {JSON.parse(reaction.data.palette.colors).map((color, i) => (
                    <div
                      key={i}
                      className={styles.color}
                      style={{ backgroundColor: color }}
                    ></div>
                  ))}
                </div>
              }
              label={
                <div className={styles.name}>
                  {reaction.data.palette.name}
                  {showReactionDefault() && (
                    <div className={styles.defaultAnchor}>
                      <div className={styles.default}>Default</div>
                    </div>
                  )}
                </div>
              }
            />
          )}

          <div className={styles.createNewContainer}>
            <div className={styles.link} onClick={() => setCreateNew(true)}>
              + <a>Add Palette</a>
            </div>
          </div>
        </div>
      )}
      {editPalette && (
        <PaletteEditor
          onCancel={() => setEditPalette(null)}
          initPalette={editPalette}
          onSave={onSave}
          onDelete={onDelete}
        />
      )}
      {createNew && (
        <PaletteEditor
          onCancel={() => setCreateNew(false)}
          onCreate={onCreate}
        />
      )}
    </>
  );
}
