import styles from "./ToFrom.module.scss";

function ReplyTo({ label, value, onChange, placeholder }) {
    return (
        <div className={`${styles.replyContainer}`}>
            <div className={styles.label55}>{label}</div>
            <input
                className={styles.input}
                value={value}
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    );
}

export default ReplyTo;
