import { useGetProjectsFromProgramInTimeFrame } from "api/resources/projects/projects";
import styles from "./DynamicOption.module.scss";
import { useGetProjectsFromSurveyTagInTimeFrame } from "api/resources/organization/surveytags";
import { ErrorBanner } from "pages/error_page/ErrorPage";

export function DynamicOption({
  dynamic,
  onRemove,
  changeTimeFrame,
  changeSelection,
}) {
  const timeFrames = ["Most Recent", "Past Year"];

  return (
    <div className={styles.included}>
      <div className={styles.name}>
        {dynamic.name}{" "}
        {dynamic.type === "surveyTag" && (
          <div className={`${styles.indicator} ${styles.tagIndicator}`}>
            Tag
          </div>
        )}
        {dynamic.type === "program" && (
          <div className={`${styles.indicator} ${styles.programIndicator}`}>
            Progam
          </div>
        )}
        <div className={styles.remove} onClick={onRemove}>
          <i className="bi bi-x"></i>
        </div>
      </div>

      <div className={styles.selections}>
        <div className={styles.selection}>
          <input
            type="radio"
            value={"All"}
            onChange={() => changeTimeFrame("All")}
            checked={dynamic.timeFrame === "All"}
            className={styles.timeFrameInput}
          ></input>
          All
        </div>

        <div className={styles.selection}>
          <input
            type="radio"
            value={"selection"}
            onChange={() => changeTimeFrame("selection")}
            checked={dynamic.timeFrame === "selection"}
            className={styles.timeFrameInput}
          ></input>
          A Selection
        </div>

        {dynamic.timeFrame === "selection" && (
          <>
            {dynamic.type === "program" && (
              <ProgramProjectsSelection
                dynamic={dynamic}
                changeSelection={changeSelection}
              />
            )}
            {dynamic.type === "surveyTag" && (
              <SurveyTagProjectsSelection
                dynamic={dynamic}
                changeSelection={changeSelection}
              />
            )}
          </>
        )}
      </div>

      <div className={styles.timeFrames}>
        <div className={styles.timeFrameHeading}>Time Frame</div>
        {timeFrames.reverse().map((tf) => (
          <>
            <div className={styles.timeFrame}>
              <input
                type="radio"
                value={tf}
                onChange={() => changeTimeFrame(tf)}
                checked={dynamic.timeFrame === tf}
                className={styles.timeFrameInput}
              ></input>
              {tf}
            </div>
            {tf !== "All" && dynamic.timeFrame === tf && (
              <>
                {dynamic.type === "program" && (
                  <ProgramProjects dynamic={dynamic} />
                )}
                {dynamic.type === "surveyTag" && (
                  <SurveyTagProjects dynamic={dynamic} />
                )}
              </>
            )}
          </>
        ))}
      </div>
    </div>
  );
}

function ProgramProjects({ dynamic }) {
  const getProjects = useGetProjectsFromProgramInTimeFrame(
    dynamic.id,
    dynamic.timeFrame
  );

  return (
    <>
      {getProjects.isError && (
        <ErrorBanner
          error={getProjects.error}
          message="Error fetching surveys for program"
        />
      )}
      {getProjects.isSuccess && (
        <div className={styles.inTimeFrameProjects}>
          {getProjects.data.projects.map((project) => (
            <div className={styles.inTimeFrameProj}>{project.name}</div>
          ))}
        </div>
      )}
    </>
  );
}

function SurveyTagProjects({ dynamic }) {
  const getProjects = useGetProjectsFromSurveyTagInTimeFrame(
    dynamic.id,
    dynamic.timeFrame
  );

  return (
    <>
      {getProjects.isError && (
        <ErrorBanner
          error={getProjects.error}
          message="Error fetching surveys for tag"
        />
      )}
      {getProjects.isSuccess && (
        <div className={styles.inTimeFrameProjects}>
          {getProjects.data.projects.map((project) => (
            <div className={styles.inTimeFrameProj}>{project.name}</div>
          ))}
        </div>
      )}
    </>
  );
}

function ProgramProjectsSelection({ dynamic, changeSelection }) {
  const getProjects = useGetProjectsFromProgramInTimeFrame(dynamic.id, "All");

  return (
    <>
      {getProjects.isError && (
        <ErrorBanner
          error={getProjects.error}
          message="Error fetching surveys for program"
        />
      )}
      {getProjects.isSuccess && (
        <div className={styles.selectionOptions}>
          {getProjects.data.projects.map((project) => (
            <div className={styles.selectionOption}>
              <input
                type="checkbox"
                value={project.id}
                onChange={() => changeSelection(project)}
                checked={dynamic.selection.includes(project.id)}
                className={styles.timeFrameInput}
              ></input>
              {project.name}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

function SurveyTagProjectsSelection({ dynamic, changeSelection }) {
  const getProjects = useGetProjectsFromSurveyTagInTimeFrame(dynamic.id, "All");

  return (
    <>
      {getProjects.isError && (
        <ErrorBanner
          error={getProjects.error}
          message="Error fetching surveys for tag"
        />
      )}
      {getProjects.isSuccess && (
        <div className={styles.selectionOptions}>
          {getProjects.data.projects.map((project) => (
            <div className={styles.selectionOption}>
              <input
                type="checkbox"
                value={project.id}
                onChange={() => changeSelection(project)}
                checked={dynamic.selection.includes(project.id)}
                className={styles.timeFrameInput}
              ></input>
              {project.name}
            </div>
          ))}
        </div>
      )}
    </>
  );
}
