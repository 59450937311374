import React, { useMemo, useState } from "react";
import styles from "./SalesforceSettings.module.scss";
import {
  useFetchObjectConnections,
  useFetchOrganizationLinkedAccounts,
  useUpdateLinkedAccounts,
  useUpdateOrgSettings,
} from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Button from "components/Button/Button";
import Modal from "components/ReactModal/ReactModal";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import Icon from "components/Icon/Icon";
import { PushResultsSetup } from "../PushSurveyResults/PushResultsSetup";
import { SyncContacts } from "pages/contacts/all_contacts/SyncContacts";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import axios from "axios";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { deleteLinkedAccount } from "api/resources/organization/linked_account";
import DoYouWantToSave from "components/Popout/DoYouWantToSave";
export const statusOptions = [
  {
    label: (
      <FlexRow>
        Active <div className={styles.statusGreen}></div>
      </FlexRow>
    ),
    value: "active",
  },
  {
    label: (
      <FlexRow>
        Pushing Data <Loading height={30} width={30}></Loading>
      </FlexRow>
    ),
    value: "pushing",
  },
  {
    label: (
      <FlexRow>
        Inactive <div className={styles.statusRed}></div>
      </FlexRow>
    ),
    value: "inactive",
  },
];

export const linkedAccountStatusOptions = [
  {
    label: (
      <FlexRow>
        Active <div className={styles.statusGreen}></div>
      </FlexRow>
    ),
    value: "active",
  },
  {
    label: (
      <FlexRow>
        Inactive <div className={styles.statusRed}></div>
      </FlexRow>
    ),
    value: "inactive",
  },
];

function SalesforceSettings({ roles, user, updateRoute }) {
  const { id } = useParams();
  const MyOrg = useFetchOrganizationLinkedAccounts(id);

  useMemo(() => {
    if (updateRoute) {
      updateRoute(8);
    }
  }, []);

  return (
    <div className={styles.page}>
      {MyOrg.isLoading && <Loading></Loading>}
      {MyOrg.isSuccess && (
        <SalesforceSettingsContent
          org={MyOrg.data.organization}
          role={roles}
          user={user}
          refetch={MyOrg.refetch}
          link={MyOrg?.data?.organization?.linkedAccounts?.find(
            (a) => a.accountType === "Salesforce"
          )}
        />
      )}
    </div>
  );
}

export default SalesforceSettings;

const providers = [
  {
    value: "Salesforce",
    label: (
      <div
        style={{
          display: "flex",
          gap: ".5rem",
          alignItems: "center",
          width: "150px",
        }}
      >
        <img
          src={require("assets/images/Salesforce.png")}
          style={{ width: "50px" }}
        ></img>
        Salesforce
      </div>
    ),
  },
  // {
  //   value: "HubSpot",
  //   label: (
  //     <div
  //       style={{
  //         display: "flex",
  //         gap: ".5rem",
  //         alignItems: "center",
  //         width: "150px",
  //       }}
  //     >
  //       <img
  //         src={require("assets/images/hubspot.webp")}
  //         style={{ width: "50px" }}
  //       ></img>
  //       HubSpot
  //     </div>
  //   ),
  // },
];

function SalesforceSettingsContent({ org, role, user, link }) {
  const objectConnections = useFetchObjectConnections(link?.id, false);
  const objectUpdateConnections = useFetchObjectConnections(link?.id, true);
  const [organization, setOrganization] = useState({
    ...org,
    connectionStatus: "active",
  });
  const [linkChanges, setLinkChanges] = useState({});
  const [linkedAccount, setLinkedAccount] = useState(link);
  const [BtnMsg, setBtnMsg] = useState("Save");
  const [syncType, setSyncType] = useState(organization.sfSyncType);
  const [setupPushResults, setSetupPushResults] = useState(false);
  const [showLinking, setShowLinking] = useState("");
  const [showSync, setShowSync] = useState(false);
  const [confirmDisconnect, setConfirmDisconnect] = useState(false);

  // const objects = [
  //   { label: "Contacts", value: "contacts" },
  //   { label: "Accounts", value: "Accounts" },
  // ];

  const objects = [
    { label: "One Way Reaction", value: "reaction" },
    { label: "One Way Salesforce", value: "salesforce" },
    { label: "Two Way Synchronization", value: "two-way" },
  ];

  const priority = [
    { label: "Reaction Contacts", value: "reaction" },
    { label: "Salesforce Contacts", value: "salesforce" },
  ];

  const deleteLink = deleteLinkedAccount();
  const navigate = useNavigate();
  function removeLinkedAccount() {
    deleteLink.mutate(
      {
        id: linkedAccount?.id,
        orgId: organization?.id,
      },
      {
        onSuccess: () => {
          navigate("/organization/" + organization.id + "/contact-management");
          location.reload();
        },
      }
    );
  }

  const updateOrg = useUpdateOrgSettings();

  function handleSave() {
    if (role?.canUpdateOrganization) {
      setBtnMsg(
        <div style={{ display: "flex", gap: "5px" }}>
          Saving <Loading height={20} width={20}></Loading>
        </div>
      );
      updateOrg.mutate(
        {
          updateOrganizationId: organization.id,
          data: {
            sfClientId: organization.sfClientId,
            sfClientSecret: organization.sfClientSecret,
          },
        },
        {
          onSuccess: (data) => {
            // console.log("Updated Org: ", data);
            setBtnMsg(
              <div style={{ display: "flex", gap: "5px" }}>
                Saved <i className="bi bi-check-lg"></i>
              </div>
            );
            setTimeout(() => {
              location.reload();
            }, 750);
          },
        }
      );
    }
  }

  const updateLinkedAccount = useUpdateLinkedAccounts();

  function saveLinkedAccount() {
    setBtnMsg(
      <div style={{ display: "flex", gap: "5px" }}>
        Saving <Loading height={20} width={20}></Loading>
      </div>
    );

    updateLinkedAccount.mutate(
      { data: linkChanges, id: linkedAccount?.id },
      {
        onSuccess: () => {
          setBtnMsg(
            <div style={{ display: "flex", gap: "5px" }}>
              Saved <i className="bi bi-check-lg"></i>
            </div>
          );
          setTimeout(() => {
            setLinkChanges({});
            setBtnMsg("Save");
          }, 750);
        },
      }
    );
  }

  function pushData(object) {
    object.connectionStatus = "pushing";
    let url = process.env.REACT_APP_SALESFORCE_SYNC_QUERY;
    const config = {
      withCredentials: true,
      headers: {
        accept: "application/json",
      },
      params: {
        organization_id: organization.id,
        user_id: user.id,
      },
    };

    let finalData = { ...object };
    finalData.matchFields = finalData.matchFields
      ? JSON.parse(finalData.matchFields)
      : [];
    finalData.updateFields = finalData.updateFields
      ? JSON.parse(finalData.updateFields)
      : [];
    const formData = new FormData();
    formData.append("objectSettings", JSON.stringify(finalData));

    axios
      .post(url, formData, config)
      .then((response) => {
        //
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.status === 401) {
          setSessionExpired(true);
          setTimeout(() => {
            handleLoginSalesforce();
          }, 1000);
        }
      });
  }

  function getActionOptions(route, object) {
    return [
      {
        onClick: () => navigate(route + object?.id),
        data: (
          <>
            <Icon sapphire iconName={"pencil"}></Icon> Edit
          </>
        ),
      },
      {
        onClick: () => undefined,
        data: (
          <>
            <Icon red iconName={"trash"}></Icon> Remove
          </>
        ),
      },
    ];
  }

  // const fetchCustomFields = useFetchOrgFieldsNoProps(organization?.id);

  return (
    <>
      {confirmDisconnect && (
            <DoYouWantToSave
              navAway={() => setConfirmDisconnect(false)}
              onSave={removeLinkedAccount}
              confirmText={"Disconnect"}
              cancelText={"Cancel"}
              header={"Disconnect Account"}
              prompt={"Are you sure you want to disconnect your Salesforce account? All Connected Object Settings and Filters will be deleted."}
              showX
              style={{ maxWidth: "550px" }}
            ></DoYouWantToSave>
          )}
    
      <div className={styles.section} style={{ marginTop: "1rem" }}>
        <div
          className={`${styles.header_3} `}
          style={{ fontWeight: "400", color: "black", margin: "0" }}
        >
          Salesforce Account (Beta)
        </div>
        <FlexRow>
          {" "}
          <Button
            shadow
            onClick={() => setConfirmDisconnect(true)}
            style={{ gap: " .5rem" }}
          >
            <Icon red iconName={"database-slash"}></Icon>Disconnect Account
          </Button>{" "}
          {/* <Button shadow style={{ gap: " .5rem" }}>
            <Icon blue iconName={"person-add"}></Icon>Change Account
          </Button> */}
        </FlexRow>
      </div>
      <HorizontalBar width={"100%"} height={2}></HorizontalBar>
      <div className={styles.section} style={{ marginTop: "1rem" }}>
        <div
          className={`${styles.header_4} `}
          style={{ fontWeight: "400", color: "black", margin: "0" }}
        >
          Account Details
        </div>

        <TextFieldSimple
          label="Salesforce Username"
          disable={true}
          value={linkedAccount?.username}
        ></TextFieldSimple>
        <div className={styles.text_2} style={{ marginTop: ".5rem" }}>
          Environment:{" "}
          <span style={{ fontWeight: "600" }}>
            {linkedAccount?.instanceUrl}
          </span>
        </div>
        <div className={styles.text_2}>
          API Version: <span style={{ fontWeight: "600" }}>62.0</span>
        </div>
      </div>
      <HorizontalBar width={"100%"} height={2}></HorizontalBar>
      <div className={styles.section}>
        <FlexCol>
          <div
            className={`${styles.header_4} `}
            style={{ fontWeight: "400", color: "black", margin: "0" }}
          >
            Connection Status
          </div>
          <div className={styles.text_3}>
            If status is inactive, no updates will be made into Salesforce.
          </div>
        </FlexCol>
        <SelectFieldCustom
          options={linkedAccountStatusOptions}
          // label="Connection Status"
          style={{ maxWidth: "250px" }}
          value={
            linkedAccount?.active
              ? linkedAccountStatusOptions[0]
              : linkedAccountStatusOptions[1]
          }
          onChange={(v) => {
            setLinkedAccount(() => {
              return { ...linkedAccount, active: v.value === "active" };
            });
            setLinkChanges(() => {
              return { active: v.value === "active" };
            });
          }}
          noCreate
        ></SelectFieldCustom>
        <FlexRow>
          <Button
            width={100}
            shadow
            disable={Object.keys(linkChanges)?.length === 0}
          >
            Cancel
          </Button>
          <Button
            blue
            shadow
            width={100}
            disable={Object.keys(linkChanges)?.length === 0}
            onClick={() =>
              BtnMsg === "Save" ? saveLinkedAccount() : undefined
            }
          >
            {BtnMsg}
          </Button>
        </FlexRow>
      </div>
      <HorizontalBar width={"100%"} height={2}></HorizontalBar>
      <div className={styles.section}>
        <FlexCol>
          <div
            className={`${styles.header_4} `}
            style={{ fontWeight: "400", color: "black", margin: "0" }}
          >
            Connected Objects
          </div>
          <div className={styles.text_3}>
            Connect Objects and map their data between your Salesforce account
            and Reaction
          </div>
        </FlexCol>
        {objectConnections.data?.objects?.length > 0 && (
          <table
            className={styles.maptable}
            style={{ marginBottom: "1rem", marginTop: "1rem" }}
          >
            <thead>
              <tr>
                <th>Description</th>
                <th>Reaction Field</th>
                <th></th>
                <th>Salesforce Field</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {objectConnections.data?.objects?.map((object) => (
                <tr>
                  <td
                    style={{
                      width: "700px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <div className={styles.object}>{object?.description}</div>
                  </td>
                  <td style={{ width: "400px" }}>
                    <div className={styles.object}>{object?.internalName}</div>
                  </td>
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="bi-arrow-right"
                      style={{ fontSize: "1.1rem" }}
                    ></i>
                  </td>

                  <td style={{ width: "400px" }}>
                    <div className={styles.object}>{object?.externalName}</div>
                  </td>

                  <td
                    style={{
                      width: "fit-content",
                    }}
                  >
                    <Button
                      shadow
                      options={getActionOptions("ConnectObjects/", object)}
                      style={{ gap: ".5rem" }}
                      height={2.1}
                    >
                      Actions <i className="bi-chevron-down"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <Button shadow onClick={() => navigate("ConnectObjects/" + "new")} blue>
          + Connect Object
        </Button>
      </div>
      <HorizontalBar width={"100%"} height={2}></HorizontalBar>
      <div className={styles.section} style={{ maxWidth: "100%" }}>
        <FlexCol>
          <div
            className={`${styles.header_4} `}
            style={{ fontWeight: "400", color: "black", margin: "0" }}
          >
            Push Calculations Filters
          </div>
          <div className={styles.text_3}>
            Add a custom update from calculations to an object field in
            salesforce
          </div>
        </FlexCol>
        <div>
          {objectUpdateConnections.data?.objects?.length > 0 && (
            <table
              className={styles.maptable}
              style={{
                marginBottom: "1rem",
                marginTop: "1rem",
                maxWidth: "1000px",
              }}
            >
              <thead>
                <tr>
                  <th>Push Name</th>
                  {/* <th>Push Counter <i className="bi-info-circle"></i></th> */}
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {objectUpdateConnections.data?.objects?.map((object) => (
                  <tr>
                    <td style={{ minWidth: "250px" }}>
                      <div className={styles.object}>{object?.description}</div>
                    </td>

                    {/* <td style={{ minWidth: "150px" }}>
                      <div className={styles.object}>1</div>
                    </td> */}
                    <td style={{ minWidth: "200px" }}>
                      <div className={styles.object}>
                        {
                          statusOptions?.find(
                            (s) => s.value === object?.connectionStatus
                          )?.label
                        }
                      </div>
                    </td>

                    <td
                      style={{
                        width: "fit-content",
                      }}
                    >
                      <Button
                        shadow
                        options={getActionOptions("CustomUpdates/", object)}
                        style={{ gap: ".5rem" }}
                        height={2.1}
                      >
                        Actions <i className="bi-chevron-down"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <Button shadow onClick={() => navigate("CustomUpdates/" + "new")} blue>
          + Custom Update
        </Button>
      </div>
      {/* <div className={styles.section}>
        <FlexRow>
          <div
            className={`${styles.header_3} `}
            style={{ fontWeight: "400", color: "black" }}
          >
            Salesforce Settings
          </div>
          <Button shadow style={{ gap: ".5rem" }} onClick={() => setEdit(true)}>
            <i className="bi-pencil"></i> Edit
          </Button>
        </FlexRow>
        <div className={styles.infoTable}>
          <div className={`${styles.infoRow} ${styles.dark} ${styles.top}`}>
            <span>Connected Account</span>
            <span>{organization.sfConnectedAccount}</span>
          </div>
          <div className={`${styles.infoRow} ${styles.gray}`}>
            <span>Consumer Key</span>
            <span>
              *****
              {organization.sfClientSecret && organization.sfClientId.slice(-4)}
            </span>
          </div>
          <div className={`${styles.infoRow} ${styles.dark} ${styles.bottom}`}>
            <span>Consumer Secret</span>
            <span>
              *****
              {organization.sfClientSecret &&
                organization.sfClientSecret.slice(-4)}
            </span>
          </div>
        </div>
      </div> */}
      {/* <FunctionalityOption
        title={"Sync Contact Object"}
        description={
          "Keep your Salesforce Contact data in sync with Reaction Contacts"
        }
        imageSource={require("assets/images/cloud.png")}
        linkText={"Set up now"}
        iconName={"box-arrow-right"}
        imageStyle={{ width: 200, padding: "0rem 1rem" }}
        onLinkClick={() => setShowSync(true)}
      />
      <FunctionalityOption
        title={"Push Results"}
        description={
          "Manually or Automatically push your survey results to your salesforce account."
        }
        imageSource={require("assets/images/laptop.png")}
        linkText={"Set up now"}
        iconName={"box-arrow-right"}
        imageStyle={{ width: 200 }}
        onLinkClick={() => setSetupPushResults(true)}
      /> */}
      {/* <span className={styles.text_2} style={{ textAlign: "center" }}>
        You have <CheckSyncContacts organizationId={organization.id} /> Contacts
        not synced with Salesforce.
        <br />
        Click{" "}
        <a
          className={styles.link}
          onClick={() => navigate("/import-contacts/salesforce")}
        >
          Here
        </a>{" "}
        to begin the process.
      </span> */}
      {showSync && (
        <Modal
          show={showSync}
          onClose={() => setShowSync(false)}
          dark
          modalStyle={{
            gap: "1rem",
            borderRadius: "1rem",
            height: "100%",
            width: "100%",
          }}
        >
          <SyncContacts
            organizationId={organization.id}
            objectColumns={organization.custom_field}
            user={user}
          ></SyncContacts>
        </Modal>
      )}
      {setupPushResults && (
        <Modal
          show={setupPushResults}
          onClose={() => setSetupPushResults(false)}
          dark
          modalStyle={{
            gap: "1rem",
            borderRadius: "1rem",
            height: "100%",
            width: "100%",
          }}
        >
          <PushResultsSetup></PushResultsSetup>
        </Modal>
      )}
      <Modal
        show={showLinking}
        onClose={() => setShowLinking(false)}
        // rightStyle
        dark
        modalStyle={{
          // width: "500px",
          // height: "100%",
          // padding: "1.5rem 1rem",
          gap: "1rem",
          borderRadius: "1rem",
        }}
      >
        <div className={styles.page1}>
          {" "}
          <div className={styles.header_3} style={{ margin: "0" }}>
            How would you like to sync your contacts?
          </div>
          <div
            className={`${styles.option} ${
              syncType === "reaction" && styles.active2
            }`}
            onClick={() => setSyncType("reaction")}
          >
            <div className={styles.header_4} style={{ margin: "0" }}>
              One Way Reaction
            </div>
            <img
              src={require("assets/images/reactiontosalesforce.png")}
              width={"200px"}
            ></img>
            <span className={styles.text_2}>
              Make Reaction the source of truth. Changes in Reaction are updated
              to Salesforce, but updates in Salesforce are not pushed to
              Reaction.
            </span>
            {syncType === "reaction" && (
              <div style={{ position: "absolute", right: "1rem", top: "40%" }}>
                <Icon iconName="check" blue style={{ fontSize: "1.5rem" }} />
              </div>
            )}
          </div>
          <div
            className={`${styles.option} ${
              syncType === "salesforce" && styles.active2
            }`}
            onClick={() => setSyncType("salesforce")}
          >
            <div className={styles.header_4} style={{ margin: "0" }}>
              One Way Salesforce
            </div>
            <img
              src={require("assets/images/salesforcetoreaction.png")}
              width={"200px"}
            ></img>
            <span className={styles.text_2}>
              Make Salesforce the source of truth. Changes in Salesforce are
              updated to Reaction, but updates in Reaction are not pushed to
              Salesforce.
            </span>
            {syncType === "salesforce" && (
              <div style={{ position: "absolute", right: "1rem", top: "40%" }}>
                <Icon iconName="check" blue style={{ fontSize: "1.5rem" }} />
              </div>
            )}
          </div>
          <div
            className={`${styles.option} ${
              syncType === "two-way" && styles.active2
            }`}
            onClick={() => setSyncType("two-way")}
          >
            <div className={styles.header_4} style={{ margin: "0" }}>
              Two Way Synchronization
            </div>
            <img
              // src={}
              width={"200px"}
            ></img>
            <span className={styles.text_2}>
              Both Salesforce and Reaction Contacts will both stay up to date at
              the same time. Changes in Salesforce are updated to Reaction and
              changes in Reaction are updated to Salesforce.
            </span>
            {syncType === "two-way" && (
              <div style={{ position: "absolute", right: "1rem", top: "40%" }}>
                <Icon iconName="check" blue style={{ fontSize: "1.5rem" }} />
              </div>
            )}
          </div>
          {/* {syncType === "two-way" && (
            <SelectFieldCustom
              value={priority.find(
                (o) => o.value === organization.sfSyncPriority
              )}
              label="Contact Priority"
              shadow
              options={priority}
              select
              onChange={(val) => onChange(val.value, "sfSyncPriority")}
              style={{ backgroundColor: "white" }}
            />
          )} */}
          {/* <span className={styles.text_2}>
          Click "Start Sync Process" to start the initial merge of your Reaction
          & Salesforce contacts.
        </span> */}
        </div>
      </Modal>
    </>
  );
}
