import React, { useEffect, useMemo, useState } from "react";
import styles from "./SurveyDetails2.module.scss";
import {
  useFetchParticipationDates,
  useFetchProjectGql,
  useGetEngagementStats,
  useGetSurveyStats,
  useUpdateProjectStatus,
} from "api/resources/projects/projects";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button/Button";
import { BasicTable } from "components/tables/BasicTable/BasicTable";
import SurveySettings from "./SurveySettings";
import {
  useFetchDistributionsCountGql,
  useFetchDistributionsSimpleGql,
} from "api/resources/projects/distributions";
import { useFetchQuestionsGql } from "api/resources/projects/questions";
import { Pie } from "components/inputs/input_fields/CircleProgress/Circle";
import {
  engagementsviz,
  routeVariants,
  surveydetailsviz,
} from "assets/functions/Variables";
import { motion } from "framer-motion";
import Section from "components/layouts/Section/Section";
import { Loading } from "components/Loading Rectangle/Loading";
import { Loading as LoadingCircle } from "components/Loading/Loading";
import SurveyPath from "components/SurveyPath/SurveyPath";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { BarChartVertical } from "components/Charts/BarChartVertical/BarChartVertical";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { DoughnutChart } from "components/Charts/DoughnutChart/DoughnutChart";
import CustomLegend from "components/Charts/Legend";
import { DownloadTable } from "pages/results/Charts/Tables/DownloadTable/DownloadTable";
import { ErrorBanner } from "pages/error_page/ErrorPage";

const headersDraft = [
  {
    id: 2,
    index: 2,
    name: "Question",
    label: "Question",
    value: "questionText",
    enabled: true,
    sort: false,
    canSort: false,
    width: 200,
  },
  {
    id: 3,
    index: 3,
    name: "Type",
    label: "Type",
    value: "type",
    enabled: true,
    sort: false,
    descend: false,
    canSort: false,
    cell_style: null,
  },
  {
    id: 4,
    index: 4,
    name: "When Added",
    label: "When Added",
    value: "createdAt",
    dataType: "date/time",
    enabled: true,
    sort: true,
    canSort: false,
    cell_style: null,
  },
  {
    id: 5,
    index: 4,
    name: "Last Update",
    label: "Last Update",
    value: "updatedAt",
    dataType: "date/time",
    enabled: true,
    sort: true,
    canSort: false,
    cell_style: null,
  },
  {
    id: 5,
    index: 4,
    name: "",
    label: "",
    value: "conditionGroups",
    enabled: true,
    sort: true,
    canSort: false,
    cell_style: (value) => (
      <div className={styles.tableIcon}>
        {value && <i className="bi bi-signpost-2-fill"></i>}
      </div>
    ),
  },
];

const headersOpen = [
  {
    id: 2,
    index: 2,
    name: "Question",
    label: "Question",
    value: "questionText",
    enabled: true,
    sort: false,
    canSort: false,
    width: 200,
  },
  {
    id: 3,
    index: 3,
    name: "Type",
    label: "Type",
    value: "type",
    enabled: true,
    sort: false,
    descend: false,
    canSort: false,
    cell_style: null,
  },
  {
    id: 3,
    index: 3,
    name: "",
    value: "conditionGroups",
    enabled: true,
    sort: true,
    canSort: false,
    cell_style: (value) => (
      <div className={styles.tableIcon}>
        {value && <i className="bi bi-signpost-2-fill"></i>}
      </div>
    ),
  },
  {
    id: 3,
    index: 3,
    name: "Responses",
    label: "Responses",
    value: "responses",
    // objectAccessor: "length",
    enabled: true,
    sort: true,
    canSort: false,
    // cell_style: (value) => (
    //   <div className={styles.tableIcon}>
    //     {value && <i className="bi bi-signpost-2-fill"></i>}
    //   </div>
    // ),
  },
];

function SurveyDetails2({ userRoles, currUser, direction, updateRoute }) {
  const { id } = useParams();
  const fetchProjectQuery = useFetchProjectGql(id);
  const getQuestions = useFetchQuestionsGql(id);
  const [showSettings, setShowSettings] = useState(false);
  const [showChange, setShowChange] = useState(false);
  const [changingStatus, setChangingStatus] = useState(false);
  const [changeStatusErr, setChangeStatusErr] = useState(false);

  useMemo(() => {
    updateRoute(0);
  }, []);

  const emailHeaders = [
    {
      id: 1,
      index: 1,
      name: "Type",
      label: "Type",
      value: "messageType",
      enabled: true,
      sort: false,
      canSort: false,
      cell_style: null,
      cell_style: (value) => (
        <div style={{ display: "flex", gap: ".5em", alignItems: "center" }}>
          {value === "email" ? (
            <>
              <i className={`bi bi-envelope`}></i> Email
            </>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      id: 2,
      index: 2,
      name: "Name",
      label: "Name",
      value: "name",
      enabled: true,
      sort: false,
      canSort: false,
      cell_style: null,
      // cell_style: (value) => (
      //   <div style={{ display: "flex", gap: ".5em", alignItems: "center" }}>
      //     <i className={`bi bi-${value?.icon}`}></i> {value?.name}
      //   </div>
      // ),
    },
    {
      id: 3,
      index: 3,
      name: "Subject",
      label: "Subject",
      value: "subject",
      enabled: true,
      sort: false,
      descend: false,
      canSort: false,
      cell_style: null,
    },
    {
      id: 4,
      index: 4,
      name: "Status",
      label: "Status",
      value: "status",
      enabled: true,
      sort: true,
      canSort: false,
      // cell_style: (value) => (
      //   <span
      //     className={`${styles.statusStyle}
      //   ${value === "Sent" ? `${styles.delivered}` : ""}
      //   ${value === "Draft" ? `${styles.draft}` : ""}
      //   ${value === "Scheduled" ? `${styles.scheduled}` : ""}
      //   ${value === "Template" ? `${styles.active}` : ""}
      //   ${value === "Active" ? `${styles.active}` : ""}
      //   ${value === "Inactive" ? `${styles.inactive}` : ""}`}
      //   >
      //     {value}{" "}
      //   </span>
      // ),
    },
    {
      id: 5,
      index: 5,
      name: "Date",
      label: "Date",
      value: "scheduledDate",
      dataType: "date/time",
      enabled: true,
      sort: true,
      canSort: false,
      // cell_style: (value) => <span>{trimDate(value, true)}</span>,
    },
  ];

  const navigate = useNavigate();

  const [pageSortDist, setPageSort] = useState({
    item: "index",
    descend: false,
  });
  const [perPageDist, setPerpage] = useState(10);
  const [pageSkipDist, setPageSkip] = useState(0);
  const [searchStringDist, setSearchString] = useState("");
  const [showIterations, setShowIterations] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [resultsPast, setResultsPast] = useState(100);
  const [resultsDate, setResultsDate] = useState(getDate(resultsPast));

  const getDistributions = useFetchDistributionsSimpleGql(
    id,
    pageSortDist,
    pageSkipDist,
    perPageDist,
    searchStringDist
  );
  const getDistributionsCount = useFetchDistributionsCountGql(
    id,
    pageSortDist,
    pageSkipDist,
    perPageDist,
    searchStringDist
  );

  const surveyStats = useGetSurveyStats(id, resultsDate);
  const updateStatus = useUpdateProjectStatus();

  useEffect(() => {
    surveyStats.refetch();
  }, [resultsDate]);

  function getDate(past) {
    let date = new Date();
    date.setMonth(date.getMonth() - past);
    return date;
  }

  useEffect(() => {
    getDistributions.refetch();
    getDistributionsCount.refetch();
  }, [pageSkipDist, pageSortDist, searchStringDist]);

  // useEffect(() => {
  //   if (collapsed) {
  //     collapse(false);
  //   }
  // }, []);

  // function checkMethods(dists) {
  //   if (!dists) {
  //     return false;
  //   }
  //   let methods = [];

  //   for (let dist of dists) {
  //     if (methods?.length === 0) {
  //       methods.push(dist.messageType);
  //     } else if (methods.indexOf(dist.messageType) < 0) {
  //       methods.push(dist.messageType);
  //     }
  //   }

  //   return (
  //     <div style={{ display: "flex", gap: "7px" }}>
  //       {methods.map((method, i) => (
  //         <div
  //           className={`${styles.method} ${
  //             method === "email" && styles.email
  //           } ${method === "sms" && styles.sms}
  //           ${method === "link" && styles.link}
  //           ${method === "personal-link" && styles.personalLink}`}
  //           key={i}
  //         >
  //           {method === "email" && <i className="bi-envelope"></i>}
  //           {method === "sms" && <i className="bi-sms"></i>}
  //           {method === "link" && <i className="bi-link-45deg"></i>}
  //           {method === "personal-link" && <i className="bi-person"></i>}
  //           {method}
  //         </div>
  //       ))}
  //       {methods.length === 0 && "None"}
  //     </div>
  //   );
  // }

  function getPath() {
    return [
      {
        id: 1,
        name: "Build your survey",
        to: navigateToBuild,
        icon: "bi-hammer",
        done: getQuestions.data?.QuestionByProjectID?.length > 0,
        todo: [
          "Add questions and Logic",
          "Design the survey style",
          "Preview Survey",
        ],
      },
      {
        id: 2,
        name: "Send your survey",
        to: navigateToDelivery,
        icon: "bi-mailbox",
        done:
          (getQuestions.data?.QuestionByProjectID?.length > 0 &&
            getDistributionsCount.data.count > 0) ||
          (getQuestions.data?.QuestionByProjectID?.length > 0 &&
            fetchProjectQuery?.data?.survey?.activeLink),
        todo: [
          "Choose an audience",
          "Design link / messages",
          "Preview and send to audience",
        ],
      },
      {
        id: 3,
        name: "Analyze Results",
        to: navigateToResults,
        icon: "bi-bar-chart",
        done: false,
        todo: [
          "View realtime results",
          "View send statistics",
          "Export results",
          "View Reaction feedback",
        ],
      },
    ];
  }

  function navigateToBuild() {
    localStorage.setItem("activeprojecttab", 1);
    if (fetchProjectQuery.data.survey.isProgram) {
      navigate(`/program/${id}/survey-build`);
    } else {
      navigate(`/project/${id}/survey-build`);
    }
  }

  function navigateToResults() {
    localStorage.setItem("activeprojecttab", 3);
    if (fetchProjectQuery.data.survey.isProgram) {
      navigate(
        `/program/${id}/surveyresults/${fetchProjectQuery.data.survey.rawReportId}`
      );
    } else {
      navigate(
        `/project/${id}/surveyresults/${fetchProjectQuery.data.survey.rawReportId}`
      );
    }
  }

  function navigateToDelivery() {
    localStorage.setItem("activeprojecttab", 2);
    localStorage.setItem("activedelivertab", 1);
    if (fetchProjectQuery.data.survey.isProgram) {
      navigate(`/program/${id}/delivery/messages`);
    } else {
      navigate(`/project/${id}/delivery/messages`);
    }
  }

  const resultsDatOptions = [
    { label: "All Time", value: 100 },
    { label: "3m", value: 3 },
    { label: "6m", value: 6 },
    { label: "9m", value: 9 },
    { label: "12m", value: 12 },
    { label: "18m", value: 18 },
    { label: "24m", value: 24 },
  ];

  function setUpChange() {
    setTimeout(
      () => document.addEventListener("click", changeListener, false),
      10
    );
    setShowChange(true);
  }

  function changeListener() {
    document.removeEventListener("click", changeListener, false);
    setShowChange(false);
  }

  function changeStatus() {
    const newStatus =
      fetchProjectQuery.data.survey.status === "Open" ? "Closed" : "Open";

    updateStatus.mutate(
      {
        projectId: id,
        status: newStatus,
      },
      {
        onSuccess: (data) => {
          fetchProjectQuery.refetch();
          setChangingStatus(false);
        },
        onError: (data) => {
          console.log(data);
          setChangeStatusErr(true);
          setChangingStatus(false);
        },
      }
    );

    setChangingStatus(true);
    if (changeStatusErr) setChangeStatusErr(false);
  }

  return (
    <>
      <motion.div
        variants={routeVariants}
        custom={{ direction, animate: true }}
        initial="enter"
        animate="center"
        exit="exit"
        className={styles.page}
      >
        {fetchProjectQuery.isError && (
          <ErrorBanner
            error={fetchProjectQuery.error}
            message="Error loading survey"
          />
        )}
        {getQuestions.isError && (
          <ErrorBanner
            error={getQuestions.error}
            message="Error loading questions"
          />
        )}
        {getDistributionsCount.isError && (
          <ErrorBanner
            error={getDistributionsCount.error}
            message="Error loading email counts"
          />
        )}
        {getDistributions.isError && (
          <ErrorBanner
            error={getDistributions.error}
            message="Error loading emails"
          />
        )}
        {surveyStats.isError && (
          <ErrorBanner
            error={surveyStats.error}
            message="Error loading survey stats"
          />
        )}
        {updateStatus.isError && (
          <ErrorBanner
            error={updateStatus.error}
            message="Error changing survey status"
          />
        )}
        {(fetchProjectQuery.isLoading ||
          getDistributionsCount.isLoading ||
          getQuestions.isLoading ||
          getDistributions.isLoading ||
          surveyStats.isLoading) && (
          <div style={{ height: "100vh" }}>
            <LoadingCircle></LoadingCircle>
          </div>
        )}

        {!showIterations &&
          fetchProjectQuery.isSuccess &&
          getDistributions.isSuccess &&
          getQuestions.isSuccess &&
          getDistributionsCount.isSuccess &&
          surveyStats.isSuccess && (
            <>
              <div className={styles.detailsContainer}>
                {showResults && (
                  <DownloadTable
                    surveyId={fetchProjectQuery.data.survey.id}
                    onClose={() => setShowResults(false)}
                  ></DownloadTable>
                )}
                <FlexRow justify={"space-between"} wrap={"wrap"}>
                  <FlexRow fit>
                    {userRoles.canCreateEditOrgSurveys && (
                      <div
                        className={`${styles.isEdit} ${styles.changeStatus}`}
                      >
                        <span
                          onClick={changingStatus ? null : setUpChange}
                          className={styles.row}
                          style={{ alignItems: "center", gap: "7px" }}
                        >
                          {changeStatusErr ? (
                            <div className={styles.err}>Error</div>
                          ) : changingStatus ? (
                            <>
                              {fetchProjectQuery.data.survey.status === "Open"
                                ? "Closing"
                                : "Opening"}
                              <LoadingCircle
                                height={20}
                                width={20}
                              ></LoadingCircle>
                            </>
                          ) : (
                            <>Change Status</>
                          )}
                        </span>
                        {showChange && (
                          <div
                            className={`${styles.bubble} ${styles.changeBox}  ${
                              fetchProjectQuery.data.survey.status === "Open"
                                ? styles.red
                                : styles.green
                            }`}
                            onClick={changeStatus}
                          >
                            {fetchProjectQuery.data.survey.status === "Open"
                              ? "Close Survey"
                              : "Open Survey"}
                          </div>
                        )}
                      </div>
                    )}
                    <div
                      className={`${styles.bubble} ${
                        fetchProjectQuery.data.survey.status === "Draft" &&
                        styles.draft
                      } ${
                        fetchProjectQuery.data.survey.status === "Open" &&
                        styles.open
                      } ${
                        fetchProjectQuery.data.survey.status === "Closed" &&
                        styles.closed
                      }`}
                    >
                      {fetchProjectQuery.data.survey.status}
                    </div>
                    <div className={`${styles.bubble} ${styles.neutral} `}>
                      {fetchProjectQuery.data.survey.type} Survey
                    </div>
                  </FlexRow>
                  {(fetchProjectQuery.data.survey.status === "Open" ||
                    fetchProjectQuery.data.survey.status === "Closed") && (
                    <Button
                      blue
                      shadow
                      style={{ whiteSpace: "nowrap" }}
                      width={170}
                      onClick={() => setShowResults(true)}
                    >
                      Download Results
                    </Button>
                  )}
                </FlexRow>
                {!fetchProjectQuery.data.survey.openedResults &&
                  getDistributionsCount.isSuccess &&
                  getQuestions.isSuccess &&
                  fetchProjectQuery.data.survey.responseCount === 0 && (
                    <Section>
                      <SurveyPath paths={getPath()}></SurveyPath>
                    </Section>
                  )}

                {fetchProjectQuery.isSuccess &&
                  surveyStats.isSuccess &&
                  (fetchProjectQuery.data.survey.status === "Open" ||
                    fetchProjectQuery.data.survey.status === "Closed") && (
                    <>
                      <FlexRow
                        style={{ height: "310px", alignItems: "flex-start" }}
                      >
                        <Section
                          childrenStyle={{
                            height: "100%",
                            paddingBottom: "2.5rem",
                          }}
                        >
                          <FlexRow gap={".5rem"} justify={"space-between"}>
                            <FlexRow
                              gap={".5rem"}
                              fit
                              align={"flex-end"}
                              style={{ height: "fit-content" }}
                            >
                              <div
                                className={`${styles.header_2} ${styles.blue}`}
                                style={{ margin: "0" }}
                              >
                                {surveyStats.data.stats.responses}
                              </div>
                              <div
                                className={`${styles.header_5}`}
                                style={{
                                  margin: "0",
                                  fontWeight: "400",
                                  paddingBottom: ".5rem",
                                }}
                              >
                                Responses
                              </div>
                            </FlexRow>{" "}
                            <Button style={{ backgroundColor: "#F5FCFC" }}>
                              <i className="bi-bar-chart-fill"></i>
                            </Button>
                          </FlexRow>
                          <HourChart
                            viz={surveydetailsviz}
                            filters={"[]"}
                            role={userRoles}
                            id={fetchProjectQuery.data?.survey?.id}
                          />
                        </Section>
                      </FlexRow>
                      <FlexRow
                        style={{ flexWrap: "wrap" }}
                        justify={"space-between"}
                      >
                        <Section
                          childrenStyle={{
                            height: "140px",
                            flex: "1",
                            minWidth: "200px",
                            boxSizing: "border-box",
                          }}
                        >
                          <FlexRow>
                            <FlexCol>
                              <div
                                className={`${styles.header_2} ${styles.blue}`}
                                style={{
                                  margin: "0",
                                  marginBottom: "-.25rem",
                                }}
                              >
                                {(
                                  (surveyStats.data.stats.responses /
                                    surveyStats.data.stats.participationCount) *
                                  100
                                ).toFixed(2)}
                                %
                              </div>
                              <div className={styles.text_2}>Response Rate</div>
                              <div className={styles.text_3}>
                                {surveyStats.data.stats.responses} /{" "}
                                {surveyStats.data.stats.participationCount}
                              </div>
                            </FlexCol>
                            <div>
                              <Pie
                                percentage={(
                                  (surveyStats.data.stats.responses /
                                    surveyStats.data.stats.participationCount) *
                                  100
                                ).toFixed(2)}
                                backColor={"lightgray"}
                                progress={(
                                  (surveyStats.data.stats.responses /
                                    surveyStats.data.stats.participationCount) *
                                  100
                                ).toFixed(2)}
                                strokeWidth={".75rem"}
                                colour={"#15bcc7"}
                                // hideText
                              ></Pie>
                            </div>
                          </FlexRow>
                        </Section>
                        <Section
                          childrenStyle={{
                            height: "140px",
                            flex: "1",
                            minWidth: "200px",
                            boxSizing: "border-box",
                          }}
                        >
                          <EngagementChart
                            viz={engagementsviz}
                            filters={"[]"}
                            role={userRoles}
                            id={fetchProjectQuery.data?.survey?.id}
                          />
                        </Section>
                        <Section
                          childrenStyle={{
                            height: "140px",
                            flex: "1",
                            minWidth: "200px",
                            boxSizing: "border-box",
                          }}
                        >
                          <FlexRow
                            align={"flex-start"}
                            style={{ height: "100%" }}
                          >
                            <FlexCol
                              justify={"space-between"}
                              style={{
                                height: "100%",
                              }}
                            >
                              <FlexCol>
                                <div
                                  className={`${styles.header_2} ${styles.blue}`}
                                  style={{
                                    margin: "0",
                                    marginBottom: "-.25rem",
                                  }}
                                >
                                  {surveyStats.data.stats.participationCount}
                                </div>
                                <div className={styles.text_2}>
                                  Audience Size
                                </div>
                              </FlexCol>
                              <div className={styles.text_3}>
                                Those who were sent the survey
                              </div>
                            </FlexCol>
                            <div>
                              {/* <Button
                                blue
                                shadow
                                style={{ whiteSpace: "nowrap" }}
                              >
                                View Audience
                              </Button> */}
                            </div>
                          </FlexRow>
                        </Section>
                      </FlexRow>
                    </>
                  )}

                {getQuestions.data?.QuestionByProjectID.length > 0 && (
                  <>
                    <Section
                      childrenStyle={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      <FlexRow justify={"space-between"} align={"center"}>
                        <div className={styles.link2}>
                          <span onClick={() => navigateToBuild()}>
                            {" "}
                            Survey Questions
                          </span>
                        </div>
                        <div className="link" onClick={() => navigateToBuild()}>
                          Edit Questions
                        </div>
                      </FlexRow>
                      {fetchProjectQuery.isSuccess && (
                        <BasicTable
                          initheaders={
                            fetchProjectQuery.data.survey.status === "Draft"
                              ? headersDraft
                              : headersOpen
                          }
                          data={getQuestions.data?.QuestionByProjectID}
                          onRowClick={() => console.log("Click!")}
                          rowNumbers
                          emptyMessage={
                            <span>
                              You have no questions:{" "}
                              <a className={styles.highlight}>
                                Create Questions
                              </a>
                            </span>
                          }
                          id="details"
                          moveableColumns
                          noRowAction
                        ></BasicTable>
                      )}
                    </Section>
                  </>
                )}

                {getQuestions.data?.QuestionByProjectID.length === 0 && (
                  <Section
                    childrenStyle={{
                      marginTop: "5px",
                      overflow: "hidden",
                      gap: "1rem",
                      display: "flex",
                      flexDirection: "column",
                      height: "300px",
                      alignItems: "center",
                    }}
                  >
                    <FlexRow>
                      {" "}
                      <div className={styles.link2}>
                        <span onClick={() => navigateToBuild()}>
                          {" "}
                          Survey Questions
                        </span>
                      </div>
                    </FlexRow>
                    <FlexCol
                      fit
                      style={{
                        height: "100%",
                        justifyContent: "center",
                        marginBottom: "3rem",
                      }}
                      gap={"1rem"}
                    >
                      <div className={styles.text_1}>
                        You have no questions yet.
                      </div>
                      <Button
                        blue
                        shadow
                        width={200}
                        onClick={() => navigateToBuild()}
                      >
                        Add Questions
                      </Button>
                    </FlexCol>
                  </Section>
                )}

                {getDistributions.isLoading && <Loading></Loading>}
                {getDistributions.isSuccess &&
                  getDistributions?.data?.distributions.length > 0 && (
                    <>
                      {" "}
                      <Section
                        icon="bi-send"
                        childrenStyle={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <FlexRow justify={"space-between"} align={"center"}>
                          <div className={styles.link2}>
                            <span onClick={() => navigateToDelivery()}>
                              Messages
                            </span>
                          </div>
                          <div
                            className="link"
                            onClick={() => navigateToDelivery()}
                          >
                            Edit Messages
                          </div>
                        </FlexRow>
                        <BasicTable
                          initheaders={emailHeaders}
                          data={getDistributions?.data?.distributions}
                          // onRowClick={() => console.log("Click!")}
                          noRowAction
                          rowNumbers
                          emptyMessage={
                            "No Distribution Methods. Any distributions or messages created will go here "
                          }
                        ></BasicTable>
                      </Section>
                    </>
                  )}
                {getDistributions.isSuccess &&
                  getDistributions?.data?.distributions.length === 0 && (
                    <Section
                      childrenStyle={{
                        // padding: "0px",
                        marginTop: "5px",
                        overflow: "hidden",
                        gap: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        height: "300px",
                        alignItems: "center",
                        // boxShadow: "none",
                        // backgroundColor: "transparent",
                      }}
                    >
                      <FlexRow>
                        {" "}
                        <div className={styles.link2}>
                          <span onClick={() => navigateToBuild()}>
                            {" "}
                            Messages
                          </span>
                        </div>
                      </FlexRow>
                      <FlexCol
                        fit
                        style={{
                          height: "100%",
                          justifyContent: "center",
                          marginBottom: "3rem",
                        }}
                        gap={"1rem"}
                      >
                        <div className={styles.text_1}>
                          You have no messages yet.
                        </div>
                        <Button
                          blue
                          shadow
                          width={200}
                          onClick={() => navigateToDelivery()}
                        >
                          Create Messages
                        </Button>
                      </FlexCol>
                    </Section>
                  )}

                {/* <SurveySettings
                  survey={fetchProjectQuery.data?.survey}
                  userRoles={userRoles}
                  show={showSettings}
                  setShow={setShowSettings}
                  user={currUser}
                ></SurveySettings> */}
              </div>
            </>
          )}
      </motion.div>
    </>
  );
}

export default SurveyDetails2;

function HourChart({ viz, role, filters, id }) {
  const getDates = useFetchParticipationDates(id);
  return (
    <>
      {getDates.isError && (
        <ErrorBanner
          error={getDates.error}
          message={"Error loading participation times"}
        />
      )}
      {getDates.isSuccess && (
        <BarChartVertical
          settings={viz.designSettings}
          filters={filters}
          role={role}
          data={getDates.data.datasets}
          onSegClick={() => undefined}
        />
      )}
    </>
  );
}

function EngagementChart({ viz, role, filters, id }) {
  const getDates = useGetEngagementStats(id);
  return (
    <FlexRow justify={"space-around"} style={{}}>
      <div style={{ width: "140px", height: "90px" }}>
        {getDates.isError && (
          <ErrorBanner
            error={getDates.error}
            message={"Error loading engagement stats"}
          />
        )}
        {getDates.isSuccess && (
          <DoughnutChart
            settings={viz.designSettings}
            filters={filters}
            role={role}
            data={getDates.data.datasets}
            onSegClick={() => undefined}
            responsiveFonts={() => undefined}
          />
        )}
      </div>
      <div>
        {getDates.isSuccess && (
          <CustomLegend
            viz={viz}
            data={getDates.data?.datasets}
            settings={viz.designSettings}
            visible={[true, true, true, true]}
            onClick={() => undefined}
          ></CustomLegend>
        )}
      </div>
    </FlexRow>
  );
}
